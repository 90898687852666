import { FC } from 'react';
import styles from './ClassicTableHeader.module.scss';
import TablePageSettings from './TableHeaderButton/TablePageSettings';
import { TablePageSettingsProps } from './TableHeaderButton/TablePageSettings/TablePageSettings';
import { Checkbox } from '@consta/uikit/Checkbox';
import HorizontalContainer from '../../HorizontalContainer';
import { DisplayType, displayTypeValue } from '../../../pages/App';
import classNames from 'classnames';

type WithPageSetting = TablePageSettingsProps & {
  /**
   * Есть настройка страниц на таблице
   */
  withPageSetting: true;
};

type WithoutPageSetting = {
  /**
   * Нет настройки страниц
   */
  withPageSetting?: false;
};

type PageSettingUnionType = WithPageSetting | WithoutPageSetting;

type WithActions = {
  withHeaderActions: true;
  renderTableHeadActions: () => React.ReactNode;
};

type WithoutActions = {
  withHeaderActions?: false;
};

type ActionsUnion = WithActions | WithoutActions;

export type ClassicTableHeaderProps = PageSettingUnionType &
  ActionsUnion & {
    withCheckbox?: boolean | undefined;
  };

/**
 * Шапка таблицы
 */
const ClassicTableHeader: FC<ClassicTableHeaderProps> = (props) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  return (
    <div className={classNames(styles.ClassicTableHeader, isLaptop && styles.laptop)}>
      <HorizontalContainer space="m">
        {props.withCheckbox && (
          <div className={styles.checkboxContainer}>
            <Checkbox checked={false} />
          </div>
        )}
        {props.withHeaderActions ? props.renderTableHeadActions() : <div />}
      </HorizontalContainer>
      {props.withPageSetting && <TablePageSettings {...props} />}
    </div>
  );
};

export default ClassicTableHeader;
