import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MachineFilling from './MachineFilling';
import MachinePrices from './MachinePrices';
import MachineHomePage from './MachineHomePage';
import MachineSettings from './MachineSettings';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getMachineBaseInfoAction } from '../../../state/machineControl/actions';
import { selectMachineBaseInfo } from '../../../state/machineControl/selectors';
import MachineSales from './MachineSales';
import { TabProps } from '../../../components/TabsBadge/TabBadge/TabBadge';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import TabsBadge from '../../../components/TabsBadge';
import { IconShakerTag } from '../../../assets/icon/iconShakerTag';
import { IconCategory } from '../../../assets/icon/iconCategory';
import { IconDollarCircle } from '../../../assets/icon/iconDollarCircle';
import { IconHome } from '../../../assets/icon/iconHome';
import { IconSettingsFilled } from '../../../assets/icon/iconSettingsFilled';
import { DisplayType, displayTypeValue } from '../../App';
import { MachineStoragePageType } from './MachineFilling/MachineFilling';

/**
 * Табы страницы управления автоматом
 */
enum MachineTabs {
  HOME = 'HOME',
  SALES = 'SALES',
  FILLING = 'FILLING',
  REFILL = 'REFILL',
  FILLING_SETTINGS = 'FILLING_SETTINGS',
  PRICES = 'PRICES',
  SETTINGS = 'SETTINGS',
}

/**
 * Страница управления автоматом
 */
const Machine: FC = () => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const { machineId } = useParams<{ machineId: string }>();
  const machineIdFormatted = Number(machineId);

  // TODO: в идеале в будущем перейти на url
  const [selectedTab, setSelectedTab] = useState<MachineTabs>(MachineTabs.HOME);
  const [isShowTab, setIsShowTab] = useState(true);

  useEffect(() => {
    machineIdFormatted && dispatch(getMachineBaseInfoAction(machineIdFormatted));

    const interval = setInterval(() => {
      machineIdFormatted && dispatch(getMachineBaseInfoAction(machineIdFormatted));
    }, 10000);

    return () => clearInterval(interval);
  }, [dispatch, machineIdFormatted]);

  const tabsList = useMemo(
    (): TabProps[] => [
      {
        label: t(`machineControl.machine.tabs.${MachineTabs.HOME}`),
        renderIcon: () => <IconHome size={isLaptop ? 'xs' : 's'} {...defaultIconProps} />,
        isSelect: selectedTab === MachineTabs.HOME,
        onClick: () => {
          setSelectedTab(MachineTabs.HOME);
        },
      },
      {
        label: t(`machineControl.machine.tabs.${MachineTabs.SALES}`),
        renderIcon: () => <IconDollarCircle size={isLaptop ? 'xs' : 's'} {...defaultIconProps} />,
        isSelect: selectedTab === MachineTabs.SALES,
        onClick: () => {
          setSelectedTab(MachineTabs.SALES);
        },
      },
      {
        label: t(`machineControl.machine.tabs.${MachineTabs.REFILL}`),
        renderIcon: () => <IconCategory size={isLaptop ? 'xs' : 's'} {...defaultIconProps} />,
        isSelect: selectedTab === MachineTabs.REFILL,
        onClick: () => {
          setSelectedTab(MachineTabs.REFILL);
        },
      },
      {
        label: t(`machineControl.machine.tabs.${MachineTabs.FILLING_SETTINGS}`),
        renderIcon: () => <IconCategory size={isLaptop ? 'xs' : 's'} {...defaultIconProps} />,
        isSelect: selectedTab === MachineTabs.FILLING_SETTINGS,
        onClick: () => {
          setSelectedTab(MachineTabs.FILLING_SETTINGS);
        },
      },
      {
        label: t(`machineControl.machine.tabs.${MachineTabs.PRICES}`),
        renderIcon: () => <IconShakerTag size={isLaptop ? 'xs' : 's'} {...defaultIconProps} />,
        isSelect: selectedTab === MachineTabs.PRICES,
        onClick: () => {
          setSelectedTab(MachineTabs.PRICES);
        },
      },
      {
        label: t(`machineControl.machine.tabs.${MachineTabs.SETTINGS}`),
        renderIcon: () => <IconSettingsFilled size={isLaptop ? 'xs' : 's'} {...defaultIconProps} />,
        isSelect: selectedTab === MachineTabs.SETTINGS,
        onClick: () => {
          setSelectedTab(MachineTabs.SETTINGS);
        },
      },
    ],
    [selectedTab, t, isLaptop],
  );

  // Обработчики
  const editStorageNextStep = () => {
    setSelectedTab(MachineTabs.PRICES);
  };

  const cancelEditStorageNextStep = () => {
    setSelectedTab(MachineTabs.FILLING_SETTINGS);
    setIsShowTab(true);
  };

  const handleIsShowTabsChange = (isShow: boolean) => {
    setIsShowTab(isShow);
  };

  // render методы
  const renderHome = () => <MachineHomePage machineId={machineIdFormatted} />;

  const renderSales = () =>
    machineBaseInfo && <MachineSales serialNumber={machineBaseInfo.machine.serialNumber} />;

  // const renderFilling = () => (
  //   <MachineFilling
  //     machineId={machineIdFormatted}
  //     editStorageNextStep={editStorageNextStep}
  //     onIsShowTabsChange={handleIsShowTabsChange}
  //   />
  // );

  const renderPrices = () => (
    <MachinePrices
      machineId={machineIdFormatted}
      onIsShowTabsChange={handleIsShowTabsChange}
      cancelEditStorageNextStep={cancelEditStorageNextStep}
    />
  );

  const renderSettings = () => <MachineSettings machineId={machineIdFormatted} />;

  const renderFillingSettings = () => (
    <MachineFilling
      pageType={MachineStoragePageType.EDIT}
      machineId={machineIdFormatted}
      editStorageNextStep={editStorageNextStep}
      onIsShowTabsChange={handleIsShowTabsChange}
    />
  );

  const renderRefill = () => (
    <MachineFilling
      pageType={MachineStoragePageType.REFILL}
      machineId={machineIdFormatted}
      editStorageNextStep={editStorageNextStep}
      onIsShowTabsChange={handleIsShowTabsChange}
    />
  );

  const renderContent = () => {
    switch (selectedTab) {
      case MachineTabs.HOME:
        return renderHome();
      case MachineTabs.SALES:
        return renderSales();
      // case MachineTabs.FILLING:
      //   return renderFilling();
      case MachineTabs.PRICES:
        return renderPrices();
      case MachineTabs.SETTINGS:
        return renderSettings();
      case MachineTabs.FILLING_SETTINGS:
        return renderFillingSettings();
      case MachineTabs.REFILL:
        return renderRefill();
    }
  };

  if (!machineIdFormatted) return null;

  return (
    <>
      {isShowTab && <TabsBadge tabsList={tabsList} size={isLaptop ? 's' : 'm'} />}
      {/*<MachineOfflineBlockModal*/}
      {/*  isOpen*/}
      {/*  onCancel={() => {*/}
      {/*    console.log('cancel');*/}
      {/*  }}*/}
      {/*  onRepeatClick={() => {*/}
      {/*    console.log('onRepeatClick');*/}
      {/*  }}*/}
      {/*/>*/}
      <>{renderContent()}</>
    </>
  );
};

export default Machine;
