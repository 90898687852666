import { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { Navigate, Outlet } from 'react-router';

export interface PrivateRouteGuardProps {
  needAuth: boolean;
}

const getRedirectPath = (authStatus: boolean) => (authStatus ? '/auth' : '/') as string;

const PrivateRouteGuard: FC<PrivateRouteGuardProps> = memo(function PrivateRouteGuard({
  needAuth,
}) {
  const authStatus = useSelector<RootState>((state) => state.auth.isAuth);
  const isCheckedKey = useSelector<RootState>((state) => state.auth.isCheckedKey);

  if (isCheckedKey && needAuth !== authStatus) return <Navigate to={getRedirectPath(needAuth)} />;
  return <Outlet />;
});

PrivateRouteGuard.displayName = 'PrivateRouteGuard';

export default PrivateRouteGuard;
