import { FC } from 'react';
import { ProductDetailsDTO } from '../../../../../types/serverInterface/productDTO';
import DefaultInfo from '../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ProductCharacteristicsInfo
 */
type ProductCharacteristicsInfoProps = {
  /**
   * Продукт
   */
  product: ProductDetailsDTO;
};

/**
 * Информация о характеристиках продукта
 */
const ProductCharacteristicsInfo: FC<ProductCharacteristicsInfoProps> = ({ product }) => {
  const { t } = useTranslation();

  const { cellCategory, cellPurpose, taste, view } = product.characteristics;

  return (
    <DefaultInfo>
      {cellCategory?.name && (
        <DefaultInfoItem
          item={{
            name: t('productBase.product.info.characteristic.category.infoItem.label'),
            value: String(product.characteristics.cellCategory?.name),
          }}
        />
      )}
      {cellPurpose?.name && (
        <DefaultInfoItem
          item={{
            name: t('productBase.product.info.characteristic.purpose.infoItem.label'),
            value: String(product.characteristics.cellPurpose?.name),
          }}
        />
      )}
      {taste?.name && (
        <DefaultInfoItem
          item={{
            name: t('productBase.product.info.characteristic.taste.infoItem.label'),
            value: String(product.characteristics.taste?.name),
          }}
        />
      )}
      {view?.name && (
        <DefaultInfoItem
          item={{
            name: t('productBase.product.info.characteristic.sportPit.infoItem.label'),
            value: String(product.characteristics.view?.name),
          }}
        />
      )}
    </DefaultInfo>
  );
};

export default ProductCharacteristicsInfo;
