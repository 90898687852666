import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';
import { IconWater } from '../../../../../assets/icon/iconWater';
import StorageProductForm from './StorageProductForm';
import StorageWaterForm from './StorageWaterForm';
import StorageCupForm from './StorageCupForm';
import StorageDisposableForm from './StorageDisposableForm';
import { useTranslation } from 'react-i18next';
import { DefaultComboboxItem } from '../../../../../types/types';
import { getCell, getViewStorageItemClassName } from '../../helpers';
import styles from './StorageCard.module.scss';
import { MachineStoragePageTypeOld, StorageFormCardError } from '../../MachineStorage';
import { useAppSelector } from '../../../../../app/hooks/store';
import { selectCellCategoryUnit } from '../../../../../state/productBase/selectors';
import { IconLock } from '@consta/uikit/IconLock';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import {
  MachineStorageCupCellType,
  MachineStorageDisposableCellType,
  MachineStorageProductCellType,
  MachineStorageWaterCellType,
  SeparatedMachineCellUnionType,
  StorageUnionType,
} from '../../types';
import {
  MachineCellGroup,
  MachineCellGroupUnion,
} from '../../../../../types/serverInterface/machineDTO';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';

/**
 * Свойства компонента StorageCard
 */
type StorageCardProps = {
  /**
   * Тип страницы
   */
  pageType: MachineStoragePageTypeOld;
  group: MachineCellGroupUnion;
  /**
   * Ячейка склада
   */
  storage: SeparatedMachineCellUnionType;
  /**
   * Ошибки
   */
  error: StorageFormCardError<StorageUnionType>;
  /**
   * Обработчик нажатия на ячейку
   */
  onClick: () => void;
  /**
   * Обработчик события hover на ячейке
   */
  onHover: () => void;
  /**
   * Обработчик события hoverLeave на ячейке
   */
  onHoverLeave: () => void;
  /**
   * Обработчик изменений текстового поля
   *
   * @param key ключ поля
   * @param value значение
   */
  onInputChange: (key: keyof StorageUnionType) => (value: string | null) => void;
  /**
   * Обработчик изменений выпадающего списка
   *
   * @param key ключ поля
   * @param id выбранный элемент списка
   */
  onSelectChange: (key: keyof StorageUnionType) => (id: number | null) => void;
  /**
   * Обработчик изменений boolean поля
   *
   * @param key ключ поля
   * @param checked значение
   */
  onBooleanChange: (key: keyof StorageUnionType) => (checked: boolean) => void;
};

/**
 * Карточка ячейки склада
 */
const StorageCard: FC<StorageCardProps> = ({
  pageType,
  storage,
  group,
  error,
  onClick,
  onHover,
  onHoverLeave,
  onInputChange,
  onSelectChange,
  onBooleanChange,
}) => {
  const { t } = useTranslation();

  const cellCategoryMap = useAppSelector(selectCellCategoryUnit());

  const {
    cupVolume,
    cellCategoryId,
    cellNumber,
    view,
    name,
    isActive,
    volume,
    maxVolume,
    isCount,
  } = useMemo(() => getCell(storage, group), [storage, group]);

  const { isActiveStatus, isHoverStatus } = storage;

  // Вспомогательные методы
  const getItemLabel = () => {
    switch (group) {
      case MachineCellGroup.WATER:
        return t('machineControl.storage.water');
      case MachineCellGroup.DISPOSABLE:
        return name || 'Пустой расходник';
      case MachineCellGroup.CUP:
        return t('machineControl.storage.cup', { cupVolume });
      default:
        return name && (name.includes('undefined') || name.trim() === '') ? 'Пустая строка' : name;
    }
  };

  const hasError = (): boolean => {
    for (const key in error) {
      if (error[key as keyof StorageUnionType]?.status === 'alert') {
        return true;
      }
    }
    return false;
  };

  const getUnit = () => {
    let unit: string;

    switch (group) {
      case MachineCellGroup.CUP: {
        unit = 'UNIT';
        break;
      }
      case MachineCellGroup.DISPOSABLE: {
        unit = 'UNIT';
        break;
      }
      case MachineCellGroup.WATER: {
        unit = 'ML';
        break;
      }
      default: {
        unit = (cellCategoryId && cellCategoryMap?.[cellCategoryId]) || '';
        break;
      }
    }

    return unit ? t(`productBase.units.${unit}`) : '';
  };

  // Обработчики
  const handleSelectChange =
    (key: keyof StorageUnionType) =>
    ({ value }: { value: DefaultComboboxItem | null }) => {
      onSelectChange(key)(value?.id || null);
    };

  const handleInputChange =
    (key: keyof StorageUnionType) =>
    ({ value }: { value: string | null }) => {
      onInputChange(key)(value);
    };

  const handleBooleanChange =
    (key: keyof StorageUnionType) =>
    ({ checked }: { checked: boolean }) => {
      onBooleanChange(key)(checked);
    };

  const handleValueChange = ({ value }: { value: string | null }) => {
    handleInputChange('volume')({
      value: maxVolume ? (Number(value) > maxVolume ? String(maxVolume) : value) : value,
    });
  };

  // render методы
  const renderLeftSide = () => {
    if (group !== MachineCellGroup.CUP && group !== MachineCellGroup.WATER) {
      return isActive === false ? (
        <div className={styles.text}>
          <IconLock size="s" {...defaultIconProps} />
        </div>
      ) : (
        <Text weight="semibold" className={styles.text}>
          {cellNumber}
        </Text>
      );
    }

    if (group === MachineCellGroup.CUP || group === MachineCellGroup.WATER) {
      switch (group) {
        case MachineCellGroup.CUP:
          return <IconShakerCup className={styles.icon} size="s" {...defaultIconProps} />;
        case MachineCellGroup.WATER:
          return <IconWater className={styles.icon} size="s" {...defaultIconProps} />;
      }
    }
  };

  const renderRightSide = () => {
    switch (pageType) {
      case MachineStoragePageTypeOld.INFO:
        return (
          <div
            className={classNames(
              styles.card,
              styles.rightSide,
              getViewStorageItemClassName(group, view),
              isHoverStatus && styles.hoverCard,
              isActiveStatus && styles.activeCard,
            )}
          >
            <Text weight="semibold" className={styles.text}>
              {!isCount ? '∞' : volume ? `${volume} ${getUnit()}` : '0'}
            </Text>
          </div>
        );
      case MachineStoragePageTypeOld.REFILL:
        // TODO: сюда внедрить опциональность в зависимости от isCount
        return (
          <div className={classNames(styles.rightSide, styles.refillFieldWrapper)}>
            {isCount && (
              <>
                <TextField
                  className={styles.field}
                  size="s"
                  type="number"
                  width="full"
                  value={getInputNumberValue(volume)}
                  incrementButtons={false}
                  onChange={handleValueChange}
                />
                <Text weight="semibold" className={styles.text}>
                  {getUnit()}
                </Text>
              </>
            )}
          </div>
        );
      default:
        null;
    }
  };

  const renderProductForm = (storageInfo: MachineStorageProductCellType) => (
    <StorageProductForm
      isActiveStatus={isActiveStatus}
      storageInfo={storageInfo}
      error={error}
      hasError={hasError()}
      onInputChange={handleInputChange as any}
      onSelectChange={handleSelectChange as any}
      onBooleanChange={handleBooleanChange as any}
    />
  );

  const renderWaterForm = (storageInfo: MachineStorageWaterCellType) => (
    <StorageWaterForm
      storageInfo={storageInfo}
      error={error}
      onInputChange={handleInputChange as any}
      onBooleanChange={handleBooleanChange as any}
    />
  );

  const renderCupForm = (storageInfo: MachineStorageCupCellType) => (
    <StorageCupForm
      storageInfo={storageInfo}
      error={error}
      onInputChange={handleInputChange as any}
      onBooleanChange={handleBooleanChange as any}
    />
  );

  const renderDisposableForm = (storageInfo: MachineStorageDisposableCellType) => (
    <StorageDisposableForm
      storageInfo={storageInfo}
      error={error}
      onInputChange={handleInputChange as any}
      onBooleanChange={handleBooleanChange as any}
    />
  );

  const renderForm = () => {
    switch (group) {
      case MachineCellGroup.WATER:
        // TODO: тут обратить внимание на воду
        return renderWaterForm(storage as MachineStorageWaterCellType);
      case MachineCellGroup.CUP:
        return renderCupForm(storage as MachineStorageCupCellType);
      case MachineCellGroup.DISPOSABLE:
        return renderDisposableForm(storage as MachineStorageDisposableCellType);
      default:
        return renderProductForm(storage as MachineStorageProductCellType);
    }
  };

  return (
    <div
      className={classNames(
        styles.storageCard,
        isActive === false && styles.blocked,
        hasError() && styles.alert,
      )}
    >
      <div
        className={styles.cardInfo}
        onClick={() => pageType !== MachineStoragePageTypeOld.REFILL && onClick()}
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}
      >
        <div
          className={classNames(
            styles.card,
            styles.leftSide,
            getViewStorageItemClassName(group, view),
            isHoverStatus && styles.hoverCard,
            isActiveStatus && styles.activeCard,
          )}
        >
          {renderLeftSide()}
        </div>
        <div
          className={classNames(
            styles.card,
            getViewStorageItemClassName(group, view),
            isHoverStatus && styles.hoverCard,
            isActiveStatus && styles.activeCard,
          )}
        >
          <Text weight="semibold" className={styles.text}>
            {getItemLabel()}
          </Text>
        </div>
        {renderRightSide()}
      </div>
      {isActiveStatus && pageType === MachineStoragePageTypeOld.EDIT && (
        <div className={classNames(styles.cardForm, getViewStorageItemClassName(group, view))}>
          {renderForm()}
        </div>
      )}
    </div>
  );
};

export default StorageCard;
