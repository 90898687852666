import { FC } from 'react';
import { CreateDisposableModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import ModelDisposableCellFormItem from './ModelDisposableCellFormItem';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { DisposableCellsFieldError } from '../../types';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ModelDisposableCellForm
 */
type ModelDisposableCellFormProps = {
  /**
   * Список ячеек расходников
   */
  cellList: CreateDisposableModelCell[];
  /**
   * Ошибки формы ячеек расходников
   */
  errors: Record<number, DisposableCellsFieldError>;
  /**
   * Обработчик изменения ячейки расходника
   *
   * @param index порядковый номер ячейки в списке ячеек расходников модели автомата
   * @param key ключ поля в форме изменения ячейки расходников модели автомата
   * @param value новое значение поля
   */
  onChange: (
    index: number,
  ) => (key: keyof CreateDisposableModelCell) => (value: string | null) => void;
  /**
   * Обработчик добавления ячейки расходников
   */
  onAddClick: () => void;
  /**
   * Обработчик удаления ячейки расходника
   *
   * @param index порядковый номер ячейки в списке ячеек расходника модели автомата
   */
  onDeleteClick: (index: number) => () => void;
  /**
   * Обработчик копирования ячейки расходника
   *
   * @param index порядковый номер ячейки в списке ячеек расходника модели автомата
   */
  onCopyClick: (index: number) => () => void;
};

/**
 * Форма ячеек расходников модели автомата
 */
const ModelDisposableCellForm: FC<ModelDisposableCellFormProps> = ({
  cellList,
  errors,
  onChange,
  onAddClick,
  onDeleteClick,
  onCopyClick,
}) => {
  const { t } = useTranslation();

  // Обработчики
  const handleAddClick = onAddClick;

  const handleDeleteClick = onDeleteClick;

  const handleCopyClick = onCopyClick;

  //render методы
  const renderCellItemForm = (cell: CreateDisposableModelCell, index: number) => (
    <ModelDisposableCellFormItem
      key={index}
      cell={cell}
      error={errors?.[index]}
      onChange={onChange(index)}
      onDeleteClick={handleDeleteClick(index)}
      onCopyClick={handleCopyClick(index)}
    />
  );

  return (
    <VerticalContainer>
      {cellList.map(renderCellItemForm)}
      <Button
        label={t('machineControl.model.create.cells.disposable.add.button.label')}
        iconLeft={IconAdd as any}
        view="ghost"
        onClick={handleAddClick}
      />
    </VerticalContainer>
  );
};

export default ModelDisposableCellForm;
