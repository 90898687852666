import { FC, useEffect, useRef, useState } from 'react';
import { SnackBrandListInfo } from '../../../../../types/serverInterface/SnackProductBaseDTO';
import styles from './SnackBrandListItem.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { selectSnackProductListByBrandId } from '../../../../../state/productBase/selectors';
import { getSnackProductListAction } from '../../../../../state/productBase/actions';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { IconArrowDown } from '../../../../../assets/icon/iconArrowDown';
import { IconArrowRight } from '../../../../../assets/icon/iconArrowRight';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { SkeletonBrick } from '@consta/uikit/Skeleton';
import { useComponentSize } from '@consta/uikit/useComponentSize';
import classNames from 'classnames';
import SnackProductListItem from './SnackProductListItem/SnackProductListItem';

const getMaxHeight = (height: number, maxHeight?: number | string) => {
  if (maxHeight) {
    return typeof maxHeight === 'string' ? maxHeight : `${maxHeight}px`;
  }
  return `${height}px`;
};

/**
 * Свойства компонента SnackBrandListItem
 */
type SnackBrandListItemProps = {
  /**
   * Бренд
   */
  brand: SnackBrandListInfo;
};

/**
 * Элемент списка брендов базы товаров
 */
const SnackBrandListItem: FC<SnackBrandListItemProps> = ({ brand }) => {
  const dispatch = useAppDispatch();

  const state = useAppSelector(selectSnackProductListByBrandId(brand.id));

  const [isOpenProductList, setIsOpenProductList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const { height: contentHeight } = useComponentSize(contentRef);

  const { id: brandId } = brand;

  const maxContentHeight = brand.productCount * 30;

  const { productList } = state || { productList: null };

  useEffect(() => {
    if (isOpenProductList && !productList?.length) {
      setIsLoading(true);

      dispatch(getSnackProductListAction(brandId)).then(() => {
        setIsLoading(false);
      });
    }
  }, [isOpenProductList, productList?.length, dispatch, brandId]);

  // Обработчики
  const handleBrandClick = () => {
    setIsOpenProductList((prevState) => !prevState);
  };

  // render методы
  const renderProductListLoader = () =>
    Array.from({ length: brand.productCount }, (_, rowIndex) => (
      <SkeletonBrick className={styles.loader} key={rowIndex} height={24} />
    ));

  const renderProductList = () =>
    productList &&
    productList.map((product) => <SnackProductListItem key={product.id} product={product} />);

  const renderCollapseIcon = () =>
    isOpenProductList ? (
      <IconArrowDown size="m" {...defaultIconProps} />
    ) : (
      <IconArrowRight size="m" {...defaultIconProps} />
    );

  const renderBrand = () => (
    <HorizontalContainer className={styles.brandCard} space="xs" onClick={handleBrandClick}>
      {renderCollapseIcon()}
      <Text size="s">{brand.name}</Text>
    </HorizontalContainer>
  );

  const renderCollapseBody = () => (isLoading ? renderProductListLoader() : renderProductList());

  return (
    <VerticalContainer space="xs" align="start" className={styles.SnackBrandListItem}>
      {renderBrand()}
      <div
        style={{
          ['--collapse-body-max-height' as string]: getMaxHeight(contentHeight, maxContentHeight),
        }}
        className={classNames(styles.body, isOpenProductList && styles.body_isOpen)}
      >
        {renderCollapseBody()}
      </div>
    </VerticalContainer>
  );
};

export default SnackBrandListItem;
