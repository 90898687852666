import { useExportFromExcel } from './useExportFromExcel';
import { PromoCodeListFiltersReq } from '../../../../types/serverInterface/promoCodeDTO';
import { useExportFromClickBoard } from './useExportFromClickBoard';
import { Pagination } from '../../../../types/types';

export const useExportPromoCode = (filters: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
}) => {
  const { handleExcelExportClick } = useExportFromExcel(filters);
  const { handleExportFromClickBoardClick } = useExportFromClickBoard(filters);

  return { handleExcelExportClick, handleExportFromClickBoardClick };
};
