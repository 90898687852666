import {
  CreatePromoCodeDTO,
  Discount,
  GenerationSetting,
  TypeDiscountEnum,
} from '../../../types/serverInterface/promoCodeDTO';
import { FieldError } from '../../../types/types';
import { initialFieldError } from '../../../helpers/validateHelpers';

/**
 * Ошибки формы создания промокода
 */
export type CreatePromoCodeErrors = Record<
  keyof (Omit<CreatePromoCodeDTO, 'isGenerateSeveral' | 'discount'> &
    Omit<GenerationSetting, 'qty'> & { qtyGeneral: number | null } & Discount),
  FieldError
>;

/**
 * Дефолтное значение формы создания промокода
 */
export const createPromoCodeInitialValue: CreatePromoCodeDTO = {
  code: null,
  description: null,
  qty: null,
  isUnlimitedTimeUse: false,
  isUnlimitedCountUse: false,
  discount: {
    type: TypeDiscountEnum.PERCENT,
    amount: null,
  },
  periodUse: {
    to: null,
    from: null,
  },
  groupId: null,
  organizationId: null,
  machineIds: [],
  isGenerateSeveral: false,
  isFullMachine: true,
  product: {
    brands: [
      {
        id: null,
        ingredientLines: null,
      },
    ],
    categoryIds: [],
    viewIds: [],
  },
  schedules: [],
  generationSetting: {
    qty: 1,
    prefix: null,
    qtyLetter: 6,
  },
};

/**
 * Дефолтное состояние ошибок
 */
export const createPromoCodeInitialErrors: CreatePromoCodeErrors = {
  code: initialFieldError,
  isFullMachine: initialFieldError,
  machineIds: initialFieldError,
  qty: initialFieldError,
  qtyGeneral: initialFieldError,
  groupId: initialFieldError,
  schedules: initialFieldError,
  organizationId: initialFieldError,
  description: initialFieldError,
  isUnlimitedTimeUse: initialFieldError,
  generationSetting: initialFieldError,
  product: initialFieldError,
  isUnlimitedCountUse: initialFieldError,
  periodUse: initialFieldError,
  prefix: initialFieldError,
  qtyLetter: initialFieldError,
  amount: initialFieldError,
  type: initialFieldError,
};

/**
 * Получение ошибки для поля формы создания промокода
 *
 * @param error ошибка формы создания из которой достаётся конкретное поле
 * @param key ключ поля
 */
export const getCreatePromoCodeFieldError =
  (error: CreatePromoCodeErrors) => (key: keyof CreatePromoCodeErrors) => {
    return error?.[key] || initialFieldError;
  };
