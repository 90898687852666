import { FC } from 'react';
import { ProductDetailsDTO } from '../../../../../types/serverInterface/productDTO';
import DefaultInfo from '../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ProductBasicInfo
 */
type ProductBasicInfoProps = {
  /**
   * Продукт
   */
  product: ProductDetailsDTO;
};

/**
 * Базовая информация о продукте
 */
const ProductBasicInfo: FC<ProductBasicInfoProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <DefaultInfo>
      <DefaultInfoItem
        item={{
          name: t('productBase.product.info.basic.name.infoItem.label'),
          value: product.name,
        }}
      />
      <DefaultInfoItem
        item={{
          name: t('productBase.product.info.basic.mediaKey.infoItem.label'),
          value: product.mediaKey,
        }}
      />
      <DefaultInfoItem
        item={{
          name: t('productBase.product.info.basic.description.infoItem.label'),
          value: product.description,
        }}
        isBig
      />
    </DefaultInfo>
  );
};

export default ProductBasicInfo;
