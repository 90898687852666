import { FC } from 'react';
import { useAppSelector } from '../../../../app/hooks/store';
import { selectComponentById } from '../../../../state/productBase/selectors';
import ComponentForm from './ComponentForm';

/**
 * Свойства компонента EditComponent
 */
type EditComponentProps = {
  /**
   * id возможного компонента состава
   */
  componentId: number;
  /**
   * Обработчки закрытия формы
   */
  onClose: () => void;
};

/**
 * Компонент изменения возможного компонента состава
 */
const EditComponent: FC<EditComponentProps> = ({ componentId, onClose }) => {
  const component = useAppSelector(selectComponentById(componentId));

  return (
    component && (
      <ComponentForm
        type="edit"
        isOpen={!!componentId}
        componentId={componentId}
        component={component}
        onClose={onClose}
      />
    )
  );
};

export default EditComponent;
