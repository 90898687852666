import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconShakerCupSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.57076 1.62274C1.47417 1.74176 1.4763 1.83913 1.57817 1.96676C1.60918 2.00563 1.7621 2.0266 2.01487 2.0266C2.38511 2.0266 2.40393 2.03181 2.40472 2.13431C2.40521 2.19353 2.42659 2.4359 2.45232 2.67287C2.478 2.90984 2.55527 3.6746 2.62406 4.37234C2.69284 5.07008 2.84913 6.65346 2.97144 7.89096C3.0937 9.12846 3.19379 10.1786 3.19384 10.2247C3.19389 10.2708 3.23838 10.3516 3.2927 10.4043C3.38898 10.4976 3.45727 10.5 6.00853 10.5C8.55978 10.5 8.62807 10.4976 8.72436 10.4043C8.77867 10.3516 8.82326 10.2708 8.82346 10.2247C8.82381 10.1481 9.00098 8.30773 9.19094 6.40691C9.43759 3.93938 9.61318 2.14369 9.61318 2.08912C9.61318 2.04302 9.7143 2.0266 9.99833 2.0266C10.232 2.0266 10.4068 2.004 10.4427 1.96915C10.5313 1.88331 10.5142 1.66918 10.4117 1.57923C10.3291 1.5068 9.95152 1.5 5.99589 1.5H1.67036L1.57076 1.62274Z"
    />
  </svg>
);

const IconShakerCupSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.60221 0.704574C1.4627 0.902925 1.46576 1.06521 1.61291 1.27793C1.6577 1.34271 1.87859 1.37766 2.2437 1.37766C2.7785 1.37766 2.80567 1.38636 2.80681 1.55718C2.80753 1.65588 2.83841 2.05984 2.87557 2.45479C2.91266 2.84973 3.02428 4.12433 3.12364 5.28723C3.22299 6.45013 3.44874 9.0891 3.62541 11.1516C3.80201 13.2141 3.94659 14.9644 3.94666 15.0412C3.94673 15.118 4.01099 15.2527 4.08945 15.3404C4.22853 15.496 4.32717 15.5 8.01232 15.5C11.6975 15.5 11.7961 15.496 11.9352 15.3404C12.0136 15.2527 12.078 15.118 12.0783 15.0412C12.0788 14.9136 12.3347 11.8462 12.6091 8.67819C12.9654 4.56564 13.219 1.57282 13.219 1.48186C13.219 1.40503 13.3651 1.37766 13.7754 1.37766C14.1129 1.37766 14.3654 1.34 14.4173 1.28191C14.5452 1.13886 14.5206 0.781968 14.3724 0.632048C14.2532 0.51133 13.7077 0.5 7.99406 0.5H1.74607L1.60221 0.704574Z"
    />
  </svg>
);

const IconShakerCupSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.14152 2.27277C2.94835 2.53723 2.9526 2.75362 3.15633 3.03723C3.21835 3.12362 3.5242 3.17021 4.02974 3.17021C4.77023 3.17021 4.80785 3.18181 4.80943 3.40957C4.81042 3.54117 4.85318 4.07979 4.90464 4.60638C4.95599 5.13298 5.11055 6.83245 5.24812 8.38298C5.38568 9.93351 5.69825 13.4521 5.94287 16.2021C6.1874 18.9521 6.38758 21.2859 6.38768 21.3883C6.38778 21.4906 6.47676 21.6702 6.58539 21.7872C6.77797 21.9947 6.91455 22 12.0171 22C17.1196 22 17.2561 21.9947 17.4487 21.7872C17.5573 21.6702 17.6465 21.4906 17.6469 21.3883C17.6476 21.2181 18.002 17.1283 18.3819 12.9043C18.8752 7.42085 19.2264 3.43043 19.2264 3.30915C19.2264 3.2067 19.4286 3.17021 19.9967 3.17021C20.4641 3.17021 20.8136 3.12 20.8855 3.04255C21.0625 2.85181 21.0285 2.37596 20.8234 2.17606C20.6582 2.01511 19.903 2 11.9918 2H3.34071L3.14152 2.27277Z"
    />
  </svg>
);

export const IconShakerCup = createIcon({
  name: 'IconShakerCup',
  xs: IconShakerCupSizeXS,
  s: IconShakerCupSizeS,
  m: IconShakerCupSizeM,
});
