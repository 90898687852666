import { FC, useState } from 'react';
import { LocaleNameDescription, LocaleNameDescriptionList } from '../types';
import { Combobox } from '@consta/uikit/Combobox';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { languagesArray } from '../consts';
import styles from './LocaleNameDescriptionForm.module.scss';
import NoContent from '../../../NoContent';
import { useTranslation } from 'react-i18next';

type LocaleNameDescriptionFormProps = {
  locale: LocaleNameDescriptionList;
  onLangChange: (index: number) => ({ value }: { value: string | null }) => void;
  onNameChange: (index: number) => ({ value }: { value: string | null }) => void;
  onDescriptionChange: (index: number) => ({ value }: { value: string | null }) => void;
  onDeleteLang: (index: number) => () => void;
  onAddClick: () => void;
};

const LocaleNameDescriptionForm: FC<LocaleNameDescriptionFormProps> = ({
  locale,
  onLangChange,
  onNameChange,
  onDescriptionChange,
  onDeleteLang,
  onAddClick,
}) => {
  const { t } = useTranslation();

  const [focusedDescription, setFocusedDescription] = useState<number | null>(null);

  const isNoContent = !locale.length;

  const handleDescriptionFocus = (index: number) => () => setFocusedDescription(index);

  const handleDescriptionBlur = () => setFocusedDescription(null);

  // render методы
  const renderLocaleItem = (locale: LocaleNameDescription, index: number) =>
    (locale?.name !== null || locale?.description !== null) && (
      <div className={styles.rowContent} key={locale.lang}>
        <div className={styles.contentItem}>
          <div className={styles.firstBlock}>
            <Combobox
              label={t('defaultLocale.form.lang.select.label')}
              name="lang"
              value={locale.lang}
              items={languagesArray}
              getItemKey={(lang) => lang}
              getItemLabel={(lang) => t(`lang.${lang}`)}
              onChange={onLangChange(index)}
            />
            <TextField
              label={t('defaultLocale.form.name.input.label')}
              name="name"
              value={locale?.name}
              width="full"
              onChange={onNameChange(index)}
            />
            <Button
              view="ghost"
              iconLeft={IconTrash as any}
              onlyIcon
              onClick={onDeleteLang(index)}
            />
          </div>
          <TextField
            label={t('defaultLocale.form.description.input.label')}
            name="description"
            value={locale?.description}
            width="full"
            type="textarea"
            minRows={2}
            maxRows={index === focusedDescription ? 1000 : 2}
            onChange={onDescriptionChange(index)}
            onFocus={handleDescriptionFocus(index)}
            onBlur={handleDescriptionBlur}
          />
        </div>
      </div>
    );

  const renderContent = () => (isNoContent ? <NoContent /> : locale?.map(renderLocaleItem));

  return (
    <>
      {renderContent()}
      <Button
        label={t('defaultLocale.form.add.button.label')}
        view="ghost"
        width="full"
        onClick={onAddClick}
      />
    </>
  );
};

export default LocaleNameDescriptionForm;
