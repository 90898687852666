import { FC } from 'react';
import { Schedule } from '../../../../../types/serverInterface/promoCodeDTO';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './ScheduleCardByInfo.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ScheduleCardByInfo
 */
type ScheduleCardByInfoProps = {
  /**
   * Элемент расписания
   */
  item: Schedule;
};

/**
 * Карточка расписания для страницы информации о промокоде
 */
const ScheduleCardByInfo: FC<ScheduleCardByInfoProps> = ({ item }) => {
  const { t } = useTranslation();
  const { to, from, name, weekday } = item;

  return (
    <div className={styles.ScheduleCardByInfo}>
      <VerticalContainer space={0}>
        <Text weight="semibold">{name}</Text>
        <Text view="brand">
          {`${from}-${to}, ${weekday.map((item) => t(`weekDay.small.${item}`)).join(' ')}`}
        </Text>
      </VerticalContainer>
    </div>
  );
};

export default ScheduleCardByInfo;
