import { FC } from 'react';
import DefaultModal from '../../../../../../../components/DefaultModal';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import { Switch } from '@consta/uikit/Switch';
import { MachineStorageInfoProductCellFormatted } from '../../../../../../../types/serverInterface/machineDTO';
import { useFormik } from 'formik';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { editCellSettingValidationScheme } from './editCellSettingValidationScheme';

type MachineFillingEditListItemSettingsProps = {
  isOpen: boolean;
  isActive: boolean;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  onCloseCellSettings: () => void;
  /**
   * Обработчик изменений текстового поля
   *
   * @param key ключ поля
   * @param value значение
   */
  onInputChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ value }: { value: string | null }) => void;
  /**
   * Обработчик изменений boolean поля
   *
   * @param key ключ поля
   * @param checked значение
   */
  onBooleanChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ checked }: { checked: boolean }) => void;
};

export type CellSettingForm = {
  isActive: boolean;
  minVolume: number;
  maxVolume: number;
};

const MachineFillingEditListItemSettings: FC<MachineFillingEditListItemSettingsProps> = ({
  isOpen,
  isActive,
  cellNumber,
  minVolume,
  maxVolume,
  onCloseCellSettings,
  onInputChange,
  onBooleanChange,
}) => {
  const formik = useFormik({
    validationSchema: editCellSettingValidationScheme,
    initialValues: {
      isActive: isActive === false ? false : true,
      minVolume,
      maxVolume,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: CellSettingForm) {
    onInputChange('maxVolume')({ value: String(data.maxVolume) });
    onInputChange('minVolume')({ value: String(data.minVolume) });
    onBooleanChange('isActive')({ checked: data.isActive });

    onCloseCellSettings();
  }

  // render методы
  const renderActions = () => (
    <>
      <Button label="Назад" view="clear" onClick={onCloseCellSettings} />
      <Button
        label="Изменить"
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={`Настройки контейнера №${cellNumber}`}
      onClose={onCloseCellSettings}
      renderActions={renderActions}
    >
      <HorizontalContainer>
        <TextField
          label="Объём контейнера"
          name="maxVolume"
          type="number"
          width="full"
          incrementButtons={false}
          value={getInputNumberValue(formik.values.maxVolume)}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          label="Мин. запас"
          name="minVolume"
          type="number"
          width="full"
          incrementButtons={false}
          value={getInputNumberValue(formik.values.minVolume)}
          onChange={({ e }) => formik.handleChange(e)}
        />
      </HorizontalContainer>
      <Switch
        label="Отключить контейнер"
        checked={!formik.values.isActive}
        onChange={({ checked }) =>
          formik.setValues((prevState) => ({ ...prevState, isActive: !checked }))
        }
      />
    </DefaultModal>
  );
};

export default MachineFillingEditListItemSettings;
