/**
 * Функция, определяющая порядок сортировки по номеру ячейки
 */
export const cellSortFunc = (a: { cellNumber: number }, b: { cellNumber: number }) => {
  if (a.cellNumber < b.cellNumber) {
    return -1;
  } else if (a.cellNumber > b.cellNumber) {
    return 1;
  }
  return 0;
};
