import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import SnackProductBaseList from './SnackProductBaseList';

/**
 * Роут базы товаров
 *
 * Внешние роли производитель / владелец / пользователь
 */
const SnackProductBasePage: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<SnackProductBaseList />} />
    </Routes>
  );
};

export default SnackProductBasePage;
