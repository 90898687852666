import React, { FC, useEffect, useState } from 'react';
import { AddDocumentLink, EditDocumentLink } from '../../types/serverInterface/documentLink';
import { Modal } from '@consta/uikit/Modal';
import { TextField } from '@consta/uikit/TextField';
import styles from './DocumentForm.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useTranslation } from 'react-i18next';

type DocumentFormProps = {
  isOpen: boolean;
  index: number | null;
  value: EditDocumentLink | null;
  onClose: () => void;
  onAddSubmit: (data: AddDocumentLink) => void;
  onEditSubmit: (data: EditDocumentLink) => void;
};

const initialFormValue = { name: '', path: '' };

const DocumentForm: FC<DocumentFormProps> = ({
  isOpen,
  index,
  value,
  onClose,
  onAddSubmit,
  onEditSubmit,
}) => {
  const { t } = useTranslation();

  const [form, setForm] = useState<AddDocumentLink | EditDocumentLink>(value || initialFormValue);

  useEffect(() => {
    setForm(value ? value : initialFormValue);
  }, [value]);

  // Обработчики
  const handleClickOutside = () => onClose();

  const handleEscClick = () => onClose();

  const handleCloseButtonClick = () => onClose();

  const handleCancelButtonClick = () => onClose();

  const handleSubmit = () => {
    if (index === null) {
      onAddSubmit(form);
    } else {
      onEditSubmit(form);
    }

    setForm(initialFormValue);
  };

  return (
    <Modal
      className={styles.documentForm}
      isOpen={isOpen}
      onClickOutside={handleClickOutside}
      onEsc={handleEscClick}
    >
      <div className={styles.header}>
        <Text size="2xl">
          {t(index === null ? 'documents.form.add.title' : 'documents.form.edit.title')}
        </Text>
        <Button
          onlyIcon
          view="clear"
          iconLeft={IconClose as any}
          onClick={handleCloseButtonClick}
        />
      </div>
      <div className={styles.content}>
        <TextField
          label={t('documents.form.name.input.label')}
          value={form.name}
          onChange={({ value }) => {
            value && setForm((prevState) => ({ ...prevState, name: value }));
          }}
        />
        <TextField
          label={t('documents.form.path.input.label')}
          value={form.path}
          onChange={({ value }) => {
            value && setForm((prevState) => ({ ...prevState, path: value }));
          }}
        />
      </div>
      <div className={styles.actions}>
        <Button
          label={t('documents.form.cancel.button.label')}
          view="clear"
          size="l"
          onClick={handleCancelButtonClick}
        />
        <Button
          label={t('documents.form.save.button.label')}
          size="l"
          disabled={!(form.path && form.name)}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

export default DocumentForm;
