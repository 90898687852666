import { FC, useState } from 'react';
import styles from './SnackProductListItem.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import SnackProductInfo from './SnackProductInfo';
import { SnackProductListItemProps } from './types';

/**
 * Элемент списка товар бренда базы товаров
 *
 * @param product товар
 */
const SnackProductListItem: FC<SnackProductListItemProps> = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Обработчики
  const handleClickSnackProduct = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // render методы
  const renderInformation = () => {
    return (
      isModalOpen && <SnackProductInfo productId={product.id} handleCloseModal={handleCloseModal} />
    );
  };

  return (
    <>
      <div className={styles.SnackProductListItem} onClick={handleClickSnackProduct}>
        <Text size="s">{product.name}</Text>
      </div>
      {renderInformation()}
    </>
  );
};

export default SnackProductListItem;
