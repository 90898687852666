import React, { FC } from 'react';
import VerticalContainer from '../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './OutletTable.module.scss';
import OutletTableRow from './OutletTableRow';
import { OutletListItem } from '../../../../types/serverInterface/outletDTO';
import { useTranslation } from 'react-i18next';
import { MachineListItem } from '../../../../types/serverInterface/machineListDTO';

/**
 * Свойства компонента OutletTable
 */
type OutletTableProps = {
  /**
   * Список торговых точек
   */
  list: OutletListItem[];
  /**
   * Мапа автоматов по id торговой точки
   */
  machineOutletMap: Record<number, MachineListItem[]>;
  /**
   * Обработчик открытия изменения
   *
   * @param outlet торговая точка
   */
  onEditClick: (outlet: OutletListItem) => void;
  /**
   * Обработчик открытия удаления
   *
   * @param outlet торговая точка
   */
  onDeleteClick: (outlet: OutletListItem) => void;
};

/**
 * Таблица торговых точек
 */
const OutletTable: FC<OutletTableProps> = ({
  list,
  machineOutletMap,
  onEditClick,
  onDeleteClick,
}) => {
  const { t } = useTranslation();

  // render методы
  const renderTableHeader = () => (
    <HorizontalContainer className={styles.header} align="center" justify="start">
      <Text className={styles.nameCell} size="l" weight="semibold">
        {t('outlet.list.header.name.column.title')}
      </Text>
      <Text className={styles.salesCell} size="l" weight="semibold">
        {t('outlet.list.header.sales.column.title')}
      </Text>
    </HorizontalContainer>
  );

  const renderGroupRow = (group: OutletListItem) => (
    <OutletTableRow
      outlet={group}
      machineList={machineOutletMap[group.id] || []}
      onDeleteClick={onDeleteClick}
      onEditClick={onEditClick}
    />
  );

  return (
    <VerticalContainer space="l" className={styles.OutletTable}>
      {renderTableHeader()}
      {list?.map(renderGroupRow)}
    </VerticalContainer>
  );
};

export default OutletTable;
