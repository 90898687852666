import { FC } from 'react';
import { SeparatedMachineStorageType } from '../../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../../types/serverInterface/storageDTO';
import { MachineStoragePageTypeOld } from '../../../../../../components/Machine/MachineStorage/MachineStorage';
import MachineStorageScheme from '../../../../../../components/Machine/MachineStorage/MachineStorageScheme';
import styles from './MachineFillingInfoScheme.module.scss';
import { MachineCellGroupUnion } from '../../../../../../types/serverInterface/machineDTO';
import classNames from 'classnames';

/**
 * Свойства комопннента MachineFillingInfoScheme
 */
type MachineFillingInfoSchemeProps = {
  /**
   * Внешний className
   */
  className: string;
  /**
   * Склад автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  /**
   * Обработчик hover на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHover: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчки hover leave на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHoverLeave: (group: MachineCellGroupUnion) => (index: number) => void;
};

/**
 * Схема остатков страницы информации наполнения
 */
const MachineFillingInfoScheme: FC<MachineFillingInfoSchemeProps> = ({
  className,
  sortedGroup,
  storage,
  onHover,
  onHoverLeave,
}) => {
  return (
    <div className={classNames(styles.MachineFillingInfoScheme, className)}>
      <MachineStorageScheme
        pageType={MachineStoragePageTypeOld.INFO}
        storage={storage}
        sortedGroup={sortedGroup}
        onHover={onHover}
        onHoverLeave={onHoverLeave}
        onClick={() => () => {
          null;
        }}
        onValueChange={() => () => () => {
          null;
        }}
      />
    </div>
  );
};

export default MachineFillingInfoScheme;
