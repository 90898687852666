/**
 * Свойства комопнента CreateProductForm
 */
export type CreateProductFormProps = {
  /**
   * Флаг открытия формы создания продукта
   */
  isOpen: boolean;
  /**
   * id линейки продукта
   */
  productLineId: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Обработчик закрытия формы создания продукта
   */
  onClose: () => void;
};

/**
 * Шаги в форме
 */
export enum ProductFormSteps {
  /**
   * Базовая информация
   */
  BASE = 'BASE',
  /**
   * Характеристики
   */
  CHARACTERISTICS = 'CHARACTERISTICS',
}
