import { LanguageEnum } from './locales';

/**
 * Тип поля в кастомной форме
 */
export enum CustomFormItemType {
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  TIME = 'TIME',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  ARRAY = 'ARRAY',
}

/**
 * Единицы измерения числового поля в кастомной форме
 */
export enum CustomFormItemNumberUnits {
  ML = 'ML',
  G = 'G',
  MG = 'MG',
  G_IN_SEC = 'G_IN_SEC',
  ML_IN_SEC = 'ML_IN_SEC',
  SEC = 'SEC',
  MIN = 'MIN',
  CELSIUS = 'CELSIUS',
}

/**
 * Тип формы null и единицы измерения
 */
export type CustomFormItemTypeNullable = {
  /**
   * Тип поля number
   */
  type: null;
  /**
   * Единицы измерения для поля типа number
   */
  unit?: undefined;
  /**
   * Минимальное значение
   */
  minValue?: undefined;
  /**
   * Максимальное значение
   */
  maxValue?: undefined;
  /**
   * Дефолтное значение поля с типом number
   */
  value: null;
};

/**
 * Тип формы number и единицы измерения
 */
export type CustomFormItemTypeNumber = {
  /**
   * Тип поля number
   */
  type: CustomFormItemType.NUMBER;
  /**
   * Единицы измерения для поля типа number
   */
  unit: CustomFormItemNumberUnits;
  /**
   * Дефолтное значение поля с типом number
   */
  value: number | null;
  /**
   * Минимальное значение
   */
  minValue: number | null;
  /**
   * Максимальное значение
   */
  maxValue: number | null;
};

/**
 * Тип формы integer и единицы измерения
 */
export type CustomFormItemTypeInteger = {
  /**
   * Тип поля integer
   */
  type: CustomFormItemType.INTEGER;
  /**
   * Единицы измерения для поля типа integer
   */
  unit: CustomFormItemNumberUnits | null;
  /**
   * Дефолтное значение поля с типом integer
   */
  value: number | null;
  /**
   * Минимальное значение
   */
  minValue: number | null;
  /**
   * Максимальное значение
   */
  maxValue: number | null;
};

/**
 * Тип формы time и единицы измерения
 */
export type CustomFormItemTypeTime = {
  /**
   * Тип поля time
   */
  type: CustomFormItemType.TIME;
  /**
   * Единицы измерения для поля типа
   */
  unit?: undefined;
  /**
   * Минимальное значение
   */
  minValue?: undefined;
  /**
   * Максимальное значение
   */
  maxValue?: undefined;
  /**
   * Дефолтное значение поля с типом time
   */
  value: Date | null;
};

/**
 * Локализация элемента выпадающего списка в поле кастомной формы типа array
 */
export type CustomFormItemTypeArrayItemLocale = {
  /**
   * Язык локализации
   */
  lang: LanguageEnum;
  /**
   * Значение локализации
   */
  label: string;
};

/**
 * Элемент выпадающего списка в поле кастомной формы типа array
 */
export type CustomFormItemTypeArrayItem = {
  /**
   * label значения
   */
  label: string;
  /**
   * Ключ значения, для автомата
   */
  key: string;
  /**
   * Локализация значения
   */
  locale: CustomFormItemTypeArrayItemLocale[];
};

/**
 * Тип форма array(выпадающий список)
 */
export type CustomFormItemTypeArray = {
  /**
   * Тип поля array
   */
  type: CustomFormItemType.ARRAY;
  /**
   * Значение поле с типом array (должно быть именно значение key из items)
   */
  value: string | null;
  /**
   * Единицы измерения для поля типа
   */
  unit?: undefined;
  /**
   * Минимальное значение
   */
  minValue?: undefined;
  /**
   * Максимальное значение
   */
  maxValue?: undefined;
  /**
   * Значение на выбор
   */
  items: CustomFormItemTypeArrayItem[];
};

/**
 * Тип формы string
 */
export type CustomFormItemTypeString = {
  /**
   * Тип поля string
   */
  type: CustomFormItemType.STRING;
  /**
   * Единицы измерения для поля типа
   */
  unit?: undefined;
  /**
   * Минимальное значение
   */
  minValue?: undefined;
  /**
   * Максимальное значение
   */
  maxValue?: undefined;
  /**
   * Дефолтное значение поля с типом string
   */
  value: string | null;
};

/**
 * Тип формы boolean
 */
export type CustomFormItemTypeBoolean = {
  /**
   * Тип поля boolean
   */
  type: CustomFormItemType.BOOLEAN;
  /**
   * Единицы измерения для поля типа
   */
  unit?: undefined;
  /**
   * Минимальное значение
   */
  minValue?: undefined;
  /**
   * Максимальное значение
   */
  maxValue?: undefined;
  /**
   * Дефолтное значение поля с типом boolean
   */
  value: boolean | null;
};

/**
 * Объёдиняющий тип всех тип форм
 */
export type UnionCustomFormItemType =
  | CustomFormItemTypeNullable
  | CustomFormItemTypeNumber
  | CustomFormItemTypeString
  | CustomFormItemTypeBoolean
  | CustomFormItemTypeInteger
  | CustomFormItemTypeArray
  | CustomFormItemTypeTime;

/**
 * Локализация
 */
export type CustomFormItemLocale = {
  /**
   * Выбранный язык
   */
  lang: LanguageEnum;
  /**
   * Локализованное значение поля name
   */
  name: string | null;
  /**
   * Локализованное значение поля description
   */
  description: string | null;
};

/**
 * Список языков с локализованными значениями полей для поля в кастомной форме
 */
export type CustomFormItemLocaleList = CustomFormItemLocale[];

/**
 * Поле кастомной формы
 */
export type CustomFormItem = UnionCustomFormItemType & {
  /**
   * Название
   */
  name: string;
  /**
   * Описание
   */
  description: string;
  /**
   * Ключ поля для автомата
   */
  key: string;
  /**
   * Локализация поля
   */
  locale: CustomFormItemLocaleList;
};

/**
 * Кастомная форма
 */
export type CustomFormType = CustomFormItem[];
