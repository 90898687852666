import { FC, useMemo } from 'react';
import styles from './LeftMenu.module.scss';
import { IconLogoShaker } from '../../assets/icon/iconLogo';
import { defaultIconProps } from '../../consts/defaultIconProps';
import { leftMenuGroupList } from './consts';
import LeftMenuItemGroup from './LeftMenuItemGroup/LeftMenuItemGroup';
import VerticalContainer from '../VerticalContainer';
import classNames from 'classnames';
import { isLaptop } from '../../pages/App';
import { useAppSelector } from '../../app/hooks/store';
import { selectOrganizationId } from '../../state/organization/selectors';
import { selectRole } from '../../state/user/selectors';

const LeftMenu: FC = () => {
  const organizationId = useAppSelector(selectOrganizationId());
  const role = useAppSelector(selectRole());

  const menuItemsList = useMemo(() => {
    return leftMenuGroupList.map((group) => ({
      ...group,
      listItems:
        group.listItems.filter((item) => {
          return (
            !!item.role.find((roleItem) => roleItem === role) &&
            (!item.requiredOrganization || organizationId)
          );
        }) || [],
    }));
  }, [role, organizationId]);

  return (
    <div className={classNames(styles.LeftMenu, isLaptop && styles.isLaptop)}>
      <VerticalContainer space={0}>
        <div className={styles.logoWrapper}>
          <IconLogoShaker className={styles.logo} size="s" {...defaultIconProps} />
        </div>
        <div className={styles.groupList}>
          {menuItemsList.map((group) => (
            <LeftMenuItemGroup group={group} key={group.label} />
          ))}
        </div>
      </VerticalContainer>
    </div>
  );
};

export default LeftMenu;
