import { FC } from 'react';
import { useFormik } from 'formik';
import { createBrandValidationScheme } from './createBrandValidationScheme';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { TextField } from '@consta/uikit/TextField';
import styles from './BrandForm.module.scss';
import { editBrandValidationScheme } from './editBrandValidationScheme';
import { useAppDispatch } from '../../../../app/hooks/store';
import { CreateBrandDTO, EditBrandDTO } from '../../../../types/serverInterface/brandDTO';
import { createBrandAction, editBrandAction } from '../../../../state/productBase/actions';
import DefaultModal from '../../../../components/DefaultModal';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение для формы создания бренда
 */
const createInitialValue: CreateBrandDTO = {
  name: '',
  mediaKey: '',
  organizationId: null,
};

/**
 * Дополнительные свойства для формы создания
 */
type CreateBrandFormProps = {
  /**
   * Тип формы
   */
  type: 'create';
};

/**
 * Дополнительные свойства для формы изменения
 */
type EditBrandFormProps = {
  /**
   * Тип формы
   */
  type: 'edit';
  /**
   * Бренд
   */
  brand: EditBrandDTO;
};

/**
 * Свойства компонента BrandForm
 */
type BrandFormProps = (CreateBrandFormProps | EditBrandFormProps) & {
  /**
   * Флаг открытия формы
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия формы
   */
  onClose: () => void;
};

/**
 * Форма бренда
 */
export const BrandForm: FC<BrandFormProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, type, onClose } = props;

  const isCreate = type === 'create';

  const initialValue = isCreate ? createInitialValue : props.brand;

  const formik = useFormik({
    validationSchema: isCreate ? createBrandValidationScheme : editBrandValidationScheme,
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: CreateBrandDTO | EditBrandDTO) {
    if (isCreate) {
      dispatch(createBrandAction(data)).then(() => {
        onClose();
        formik.setValues(createInitialValue);
      });
    }

    if (!isCreate) {
      dispatch(editBrandAction(data as EditBrandDTO)).then(() => {
        onClose();
        formik.setValues(createInitialValue);
      });
    }
  }

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBase.brand.form.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button
        label={
          isCreate
            ? t('productBase.brand.form.add.button.label')
            : t('productBase.brand.form.edit.button.label')
        }
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={
        isCreate
          ? t('productBase.brand.form.add.modal.title')
          : t('productBase.brand.form.edit.modal.title')
      }
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      <form className={styles.brandForm} onSubmit={formik.handleSubmit}>
        <TextField
          label={t('productBase.brand.form.name.input.label')}
          name="name"
          width="full"
          value={formik.values.name}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          label={t('productBase.brand.form.mediaKey.input.label')}
          name="mediaKey"
          width="full"
          value={formik.values.mediaKey}
          onChange={({ e }) => formik.handleChange(e)}
        />
      </form>
    </DefaultModal>
  );
};

export default BrandForm;
