import { FC, useMemo } from 'react';
import DefaultModal from '../../DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { LocaleNameList } from './types';
import { LanguageEnum } from '../../../types/serverInterface/locales';
import { useFormik } from 'formik';
import { editNameLocaleValidationScheme } from './LocaleNameForm/editNameLocaleValidationScheme';
import { LocaleDTO } from '../../../types/serverInterface/localeDTO';
import LocaleNameForm from './LocaleNameForm';
import LocaleNameInfo from './LocaleNameInfo';
import { useTranslation } from 'react-i18next';

type NameLocaleProps = {
  isEdit: boolean;
  isOpen: boolean;
  locale: LocaleDTO;
  modalTitle: string;
  onSubmit: (data: LocaleNameList) => void;
  onEditClick: () => void;
  onEditClose: () => void;
  onClose: () => void;
};

const NameLocale: FC<NameLocaleProps> = ({
  isOpen,
  isEdit,
  locale,
  modalTitle,
  onSubmit,
  onEditClick,
  onEditClose,
  onClose,
}) => {
  const { t } = useTranslation();

  const localeList: LocaleNameList = useMemo(() => {
    return locale.fields
      ?.flatMap(({ localeValues }) => {
        return localeValues.map(({ value, language }) => {
          return {
            lang: language,
            name: value,
          };
        });
      })
      .filter(({ name }) => !!name);
  }, [locale]);

  const formik = useFormik({
    validationSchema: editNameLocaleValidationScheme,
    initialValues: localeList || [],
    enableReinitialize: true,
    onSubmit,
  });

  // Обработчики
  const handleLangChange =
    (index: number) =>
    ({ value }: { value: string | null }) => {
      const langList = [...formik.values];

      if (langList.length > index && value) {
        langList[index] = { ...langList[index], lang: value as LanguageEnum };
      }

      formik.setValues(langList);
    };

  const handleNameChange =
    (index: number) =>
    ({ value }: { value: string | null }) => {
      const langList = [...formik.values];

      if (langList.length > index && value) {
        langList[index] = { ...langList[index], name: value };
      }

      formik.setValues(langList);
    };

  const handleLangDelete = (index: number) => () => {
    const langList = [...formik.values];

    if (index < langList.length) {
      formik.setValues([...langList.slice(0, index), ...langList.slice(index + 1)]);
    }
  };

  const handleAddClick = () => {
    const langList = formik.values;
    const initialLang = {
      lang: '' as LanguageEnum,
      name: '',
    };
    formik.setValues([...langList, initialLang]);
  };

  const handleClose = () => onClose();

  const handleEditClick = onEditClick;

  const handleEditClose = onEditClose;

  const handleCancelClick = () => {
    isEdit && handleEditClose();
    !isEdit && handleClose();
  };

  const handlePrimaryClick = () => {
    isEdit && formik.handleSubmit();
    !isEdit && handleEditClick();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={
          isEdit ? t('defaultLocale.cancel.button.label') : t('defaultLocale.back.button.label')
        }
        view="clear"
        onClick={handleCancelClick}
      />
      <Button
        label={isEdit ? t('defaultLocale.save.button.label') : t('defaultLocale.edit.button.label')}
        onClick={handlePrimaryClick}
      />
    </>
  );

  const renderContent = () =>
    isEdit ? (
      <LocaleNameForm
        locale={formik.values}
        onNameChange={handleNameChange}
        onLangChange={handleLangChange}
        onDeleteLang={handleLangDelete}
        onAddClick={handleAddClick}
      />
    ) : (
      <LocaleNameInfo locale={localeList} />
    );

  return (
    <DefaultModal
      modalTitle={modalTitle}
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default NameLocale;
