import { enumToArray } from '../../../types/enums';
import {
  ActivePromoCodeFilterEnum,
  DiscountTypePromoCodeFilterEnum,
  SelectedPromoCodeFilterEnum,
} from './types';

/**
 * Список фильтров избранных промокодов
 */
export const selectedPromoCodeFiltersList = enumToArray(SelectedPromoCodeFilterEnum);

/**
 * Список фильтров активных промокодов
 */
export const activePromoCodeFiltersList = enumToArray(ActivePromoCodeFilterEnum);

/**
 * Список фильтров типов скидок промокодов
 */
export const discountTypePromoCodeFiltersList = enumToArray(DiscountTypePromoCodeFilterEnum);
