import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconCategorySizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M2 3.5C2 3.03406 2 2.80109 2.07612 2.61732C2.17761 2.37229 2.37229 2.17761 2.61732 2.07612C2.80109 2 3.03406 2 3.5 2C3.96594 2 4.19891 2 4.38268 2.07612C4.62771 2.17761 4.82239 2.37229 4.92388 2.61732C5 2.80109 5 3.03406 5 3.5C5 3.96594 5 4.19891 4.92388 4.38268C4.82239 4.62771 4.62771 4.82239 4.38268 4.92388C4.19891 5 3.96594 5 3.5 5C3.03406 5 2.80109 5 2.61732 4.92388C2.37229 4.82239 2.17761 4.62771 2.07612 4.38268C2 4.19891 2 3.96594 2 3.5Z" />
    <path d="M7 3.5C7 3.03406 7 2.80109 7.07612 2.61732C7.17761 2.37229 7.37229 2.17761 7.61732 2.07612C7.80109 2 8.03406 2 8.5 2C8.96594 2 9.19891 2 9.38268 2.07612C9.62771 2.17761 9.82239 2.37229 9.92388 2.61732C10 2.80109 10 3.03406 10 3.5C10 3.96594 10 4.19891 9.92388 4.38268C9.82239 4.62771 9.62771 4.82239 9.38268 4.92388C9.19891 5 8.96594 5 8.5 5C8.03406 5 7.80109 5 7.61732 4.92388C7.37229 4.82239 7.17761 4.62771 7.07612 4.38268C7 4.19891 7 3.96594 7 3.5Z" />
    <path d="M2 8.5C2 8.03406 2 7.80109 2.07612 7.61732C2.17761 7.37229 2.37229 7.17761 2.61732 7.07612C2.80109 7 3.03406 7 3.5 7C3.96594 7 4.19891 7 4.38268 7.07612C4.62771 7.17761 4.82239 7.37229 4.92388 7.61732C5 7.80109 5 8.03406 5 8.5C5 8.96594 5 9.19891 4.92388 9.38268C4.82239 9.62771 4.62771 9.82239 4.38268 9.92388C4.19891 10 3.96594 10 3.5 10C3.03406 10 2.80109 10 2.61732 9.92388C2.37229 9.82239 2.17761 9.62771 2.07612 9.38268C2 9.19891 2 8.96594 2 8.5Z" />
    <path d="M7 8.5C7 8.03406 7 7.80109 7.07612 7.61732C7.17761 7.37229 7.37229 7.17761 7.61732 7.07612C7.80109 7 8.03406 7 8.5 7C8.96594 7 9.19891 7 9.38268 7.07612C9.62771 7.17761 9.82239 7.37229 9.92388 7.61732C10 7.80109 10 8.03406 10 8.5C10 8.96594 10 9.19891 9.92388 9.38268C9.82239 9.62771 9.62771 9.82239 9.38268 9.92388C9.19891 10 8.96594 10 8.5 10C8.03406 10 7.80109 10 7.61732 9.92388C7.37229 9.82239 7.17761 9.62771 7.07612 9.38268C7 9.19891 7 8.96594 7 8.5Z" />
  </svg>
);

const IconCategorySizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M2.66675 4.66663C2.66675 4.04537 2.66675 3.73474 2.76824 3.48971C2.90357 3.16301 3.16313 2.90345 3.48984 2.76812C3.73486 2.66663 4.04549 2.66663 4.66675 2.66663C5.288 2.66663 5.59863 2.66663 5.84366 2.76812C6.17036 2.90345 6.42993 3.16301 6.56525 3.48971C6.66675 3.73474 6.66675 4.04537 6.66675 4.66663C6.66675 5.28788 6.66675 5.59851 6.56525 5.84354C6.42993 6.17024 6.17036 6.42981 5.84366 6.56513C5.59863 6.66663 5.288 6.66663 4.66675 6.66663C4.04549 6.66663 3.73486 6.66663 3.48984 6.56513C3.16313 6.42981 2.90357 6.17024 2.76824 5.84354C2.66675 5.59851 2.66675 5.28788 2.66675 4.66663Z" />
    <path d="M9.33341 4.66663C9.33341 4.04537 9.33341 3.73474 9.43491 3.48971C9.57023 3.16301 9.8298 2.90345 10.1565 2.76812C10.4015 2.66663 10.7122 2.66663 11.3334 2.66663C11.9547 2.66663 12.2653 2.66663 12.5103 2.76812C12.837 2.90345 13.0966 3.16301 13.2319 3.48971C13.3334 3.73474 13.3334 4.04537 13.3334 4.66663C13.3334 5.28788 13.3334 5.59851 13.2319 5.84354C13.0966 6.17024 12.837 6.42981 12.5103 6.56513C12.2653 6.66663 11.9547 6.66663 11.3334 6.66663C10.7122 6.66663 10.4015 6.66663 10.1565 6.56513C9.8298 6.42981 9.57023 6.17024 9.43491 5.84354C9.33341 5.59851 9.33341 5.28788 9.33341 4.66663Z" />
    <path d="M2.66675 11.3333C2.66675 10.712 2.66675 10.4014 2.76824 10.1564C2.90357 9.82968 3.16313 9.57011 3.48984 9.43479C3.73486 9.33329 4.04549 9.33329 4.66675 9.33329C5.288 9.33329 5.59863 9.33329 5.84366 9.43479C6.17036 9.57011 6.42993 9.82968 6.56525 10.1564C6.66675 10.4014 6.66675 10.712 6.66675 11.3333C6.66675 11.9545 6.66675 12.2652 6.56525 12.5102C6.42993 12.8369 6.17036 13.0965 5.84366 13.2318C5.59863 13.3333 5.288 13.3333 4.66675 13.3333C4.04549 13.3333 3.73486 13.3333 3.48984 13.2318C3.16313 13.0965 2.90357 12.8369 2.76824 12.5102C2.66675 12.2652 2.66675 11.9545 2.66675 11.3333Z" />
    <path d="M9.33341 11.3333C9.33341 10.712 9.33341 10.4014 9.43491 10.1564C9.57023 9.82968 9.8298 9.57011 10.1565 9.43479C10.4015 9.33329 10.7122 9.33329 11.3334 9.33329C11.9547 9.33329 12.2653 9.33329 12.5103 9.43479C12.837 9.57011 13.0966 9.82968 13.2319 10.1564C13.3334 10.4014 13.3334 10.712 13.3334 11.3333C13.3334 11.9545 13.3334 12.2652 13.2319 12.5102C13.0966 12.8369 12.837 13.0965 12.5103 13.2318C12.2653 13.3333 11.9547 13.3333 11.3334 13.3333C10.7122 13.3333 10.4015 13.3333 10.1565 13.2318C9.8298 13.0965 9.57023 12.8369 9.43491 12.5102C9.33341 12.2652 9.33341 11.9545 9.33341 11.3333Z" />
  </svg>
);

const IconCategorySizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M4 7C4 6.06812 4 5.60218 4.15224 5.23463C4.35523 4.74458 4.74458 4.35523 5.23463 4.15224C5.60218 4 6.06812 4 7 4C7.93188 4 8.39782 4 8.76537 4.15224C9.25542 4.35523 9.64477 4.74458 9.84776 5.23463C10 5.60218 10 6.06812 10 7C10 7.93188 10 8.39782 9.84776 8.76537C9.64477 9.25542 9.25542 9.64477 8.76537 9.84776C8.39782 10 7.93188 10 7 10C6.06812 10 5.60218 10 5.23463 9.84776C4.74458 9.64477 4.35523 9.25542 4.15224 8.76537C4 8.39782 4 7.93188 4 7Z" />
    <path d="M14 7C14 6.06812 14 5.60218 14.1522 5.23463C14.3552 4.74458 14.7446 4.35523 15.2346 4.15224C15.6022 4 16.0681 4 17 4C17.9319 4 18.3978 4 18.7654 4.15224C19.2554 4.35523 19.6448 4.74458 19.8478 5.23463C20 5.60218 20 6.06812 20 7C20 7.93188 20 8.39782 19.8478 8.76537C19.6448 9.25542 19.2554 9.64477 18.7654 9.84776C18.3978 10 17.9319 10 17 10C16.0681 10 15.6022 10 15.2346 9.84776C14.7446 9.64477 14.3552 9.25542 14.1522 8.76537C14 8.39782 14 7.93188 14 7Z" />
    <path d="M4 17C4 16.0681 4 15.6022 4.15224 15.2346C4.35523 14.7446 4.74458 14.3552 5.23463 14.1522C5.60218 14 6.06812 14 7 14C7.93188 14 8.39782 14 8.76537 14.1522C9.25542 14.3552 9.64477 14.7446 9.84776 15.2346C10 15.6022 10 16.0681 10 17C10 17.9319 10 18.3978 9.84776 18.7654C9.64477 19.2554 9.25542 19.6448 8.76537 19.8478C8.39782 20 7.93188 20 7 20C6.06812 20 5.60218 20 5.23463 19.8478C4.74458 19.6448 4.35523 19.2554 4.15224 18.7654C4 18.3978 4 17.9319 4 17Z" />
    <path d="M14 17C14 16.0681 14 15.6022 14.1522 15.2346C14.3552 14.7446 14.7446 14.3552 15.2346 14.1522C15.6022 14 16.0681 14 17 14C17.9319 14 18.3978 14 18.7654 14.1522C19.2554 14.3552 19.6448 14.7446 19.8478 15.2346C20 15.6022 20 16.0681 20 17C20 17.9319 20 18.3978 19.8478 18.7654C19.6448 19.2554 19.2554 19.6448 18.7654 19.8478C18.3978 20 17.9319 20 17 20C16.0681 20 15.6022 20 15.2346 19.8478C14.7446 19.6448 14.3552 19.2554 14.1522 18.7654C14 18.3978 14 17.9319 14 17Z" />
  </svg>
);

export const IconCategory = createIcon({
  name: 'IconCategory',
  xs: IconCategorySizeXS,
  s: IconCategorySizeS,
  m: IconCategorySizeM,
});
