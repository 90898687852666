import { FC } from 'react';
import DefaultInfo from '../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';
import { MatrixModelInfoProps } from './types';

/**
 * Информация о модели автомата со снеками
 */
const MatrixModelInfo: FC<MatrixModelInfoProps> = ({ modelMatrix }) => {
  const { t } = useTranslation();

  return (
    <DefaultInfo>
      <DefaultInfoItem
        item={{
          name: t('machineControl.model.info.matrix.maxCells.item.label'),
          value: String(modelMatrix.maxCells),
        }}
      />
      <DefaultInfoItem
        item={{
          name: t('machineControl.model.info.matrix.maxRows.item.label'),
          value: String(modelMatrix.maxRows),
        }}
      />
      <DefaultInfoItem
        item={{
          name: t('machineControl.model.info.matrix.maxVolume.item.label'),
          value: String(modelMatrix.maxVolume),
        }}
      />
      <DefaultInfoItem
        item={{
          name: t('machineControl.model.info.matrix.minVolume.item.label'),
          value: String(modelMatrix.minVolume),
        }}
      />
    </DefaultInfo>
  );
};

export default MatrixModelInfo;
