/**
 * Адрес, где развёрнут фронт
 */
// export const baseUrlFront = 'https://localhost:3000';
export const baseUrlFront = process.env.REACT_APP_URL_FRONT;

/**
 * Базовый url Keycloak
 */
// export const authKKBaseUrl = 'https://kk.ishaker.ru:4437';
export const authKKBaseUrl = process.env.REACT_APP_URL_AUTH;

/**
 * Базовый url модуля организации
 */
// export const organizationBaseUrl = 'https://dev.ishaker.ru/api/telemetry-organization';
export const organizationBaseUrl = `${process.env.REACT_APP_BASE_URL}/telemetry-organization`;

/**
 * Базовый url модуля "Управление автоматами"
 */
// export const machineControlBaseUrl = 'https://dev.ishaker.ru/api/telemetry-machine-control';
export const machineControlBaseUrl = `${process.env.REACT_APP_BASE_URL}/telemetry-machine-control`;

/**
 * Базовый url модуля "База продуктов"
 */
// export const productBaseUrl = 'https://dev.ishaker.ru/api/telemetry-product-base';
export const productBaseUrl = `${process.env.REACT_APP_BASE_URL}/telemetry-product-base`;

/**
 * Базовый url модуля "Продажи"
 */
// export const salesBaseUrl = 'https://dev.ishaker.ru/api/telemetry-sale';
export const salesBaseUrl = `${process.env.REACT_APP_BASE_URL}/telemetry-sale`;
