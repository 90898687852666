import { FC } from 'react';
import DeleteModal from '../../../components/DeleteModal';
import { useAppSelector } from '../../../app/hooks/store';
import { selectCellCategoryById } from '../../../state/productBase/selectors';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DeleteCellCategory
 */
type DeleteCellCategoryProps = {
  /**
   * id категории контейнера
   */
  cellCategoryId: number;
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

/**
 * Компонент удаления категории контейнера
 */
const DeleteCellCategory: FC<DeleteCellCategoryProps> = ({
  cellCategoryId,
  isDeleteLoading,
  onDelete,
  onClose,
}) => {
  const { t } = useTranslation();

  const cellCategory = useAppSelector(selectCellCategoryById(cellCategoryId));

  if (!cellCategory) return null;

  const { name } = cellCategory;

  return (
    <DeleteModal
      isOpen={!!cellCategoryId}
      isDeleteLoading={isDeleteLoading}
      title={t('productBaseConfig.cellCategory.deleteModal.title', { name })}
      description={t('productBaseConfig.cellCategory.deleteModal.description', { name })}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteCellCategory;
