import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { MachineHomeSalePeriod } from '../../../../../types/serverInterface/machineDTO';
import styles from './MachineHomaPageSales.module.scss';
import classNames from 'classnames';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { IconWatchStroked } from '../../../../../assets/icon/IconWatchStroked';
import { IconCalendar } from '../../../../../assets/icon/iconCalendar';
import { DisplayType, displayTypeValue } from '../../../../App';

/**
 * Свойства компонента MachineHomaPageSales
 */
type MachineHomaPageSalesProps = {
  /**
   * Информация о продажах автомата
   */
  salePeriod: MachineHomeSalePeriod;
};

/**
 * Информация о продажах автомата
 */
const MachineHomaPageSales: FC<MachineHomaPageSalesProps> = ({ salePeriod }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { day, month, week, dateLastSale } = { ...salePeriod };

  const [date, time] = (dateLastSale || ' ').split(' ');

  return (
    <ContentCard className={classNames(styles.MachineHomaPageSales, isLaptop && styles.laptop)}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        Продажи
      </Text>
      <HorizontalContainer align="center" space={isLaptop ? 's' : 'l'}>
        <VerticalContainer
          className={styles.salesCard}
          space={isLaptop ? '2xs' : 's'}
          align="center"
        >
          <Text size={isLaptop ? 'm' : 'l'} weight="semibold" view="secondary">
            Сутки
          </Text>
          <HorizontalContainer justify="center" align="center" space="xs">
            <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
              {day}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer
          className={styles.salesCard}
          space={isLaptop ? '2xs' : 's'}
          align="center"
        >
          <Text size={isLaptop ? 'm' : 'l'} weight="semibold" view="secondary">
            Неделя
          </Text>
          <HorizontalContainer justify="center" align="center" space="xs">
            <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
              {week}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer
          className={styles.salesCard}
          space={isLaptop ? '2xs' : 's'}
          align="center"
        >
          <Text size={isLaptop ? 'm' : 'l'} weight="semibold" view="secondary">
            Месяц
          </Text>
          <HorizontalContainer justify="center" align="center" space="xs">
            <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
              {month}
            </Text>
            <IconShakerCup {...defaultIconProps} size="s" />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer
          className={classNames(styles.salesCard, styles.lastSaleDate)}
          space={isLaptop ? '2xs' : 's'}
          align="center"
        >
          <Text size={isLaptop ? 'm' : 'l'} weight="semibold" view="secondary">
            Последняя продажа
          </Text>
          <HorizontalContainer justify="center" align="center" space="m">
            <HorizontalContainer space="2xs" align="center">
              <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
                {time}
              </Text>
              <IconWatchStroked {...defaultIconProps} size="s" />
            </HorizontalContainer>
            <HorizontalContainer space="2xs" align="center">
              <Text size={isLaptop ? 'm' : 'xl'} view="primary" weight="semibold">
                {date}
              </Text>
              <IconCalendar className={styles.iconCalendar} {...defaultIconProps} size="s" />
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineHomaPageSales;
