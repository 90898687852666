import { FC } from 'react';
import styles from './MachineListStatusBar.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { StatusColor } from '../../../../types/serverInterface/machineDTO';
import { useTranslation } from 'react-i18next';

type MachineListStatusBarProps = {
  /**
   * Количество автоматов
   */
  listCount: number;
  /**
   * Количество автоматов в статусе success
   */
  successCount: number;
  /**
   * Количестов автоматов в статусе warning
   */
  warningCount: number;
  /**
   * Количество автоматов в статусе alert
   */
  alertCount: number;
  /**
   * Выбранный статус
   */
  selectedStatus: StatusColor | null;
  /**
   * Обработчик выбора статуса
   *
   * @param status выбранный статус
   */
  onSelectedStatus: (status: StatusColor | null) => void;
};

/**
 * Статусы автоматов на странице списка автоматов
 */
const MachineListStatusBar: FC<MachineListStatusBarProps> = ({
  listCount,
  successCount,
  warningCount,
  alertCount,
  onSelectedStatus,
  selectedStatus,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.MachineListStatusBar}>
      <div className={classNames(styles.card)} onClick={() => onSelectedStatus(null)}>
        <Text size="3xl" weight="semibold">
          {listCount}
        </Text>
        <Text className={styles.text} weight="semibold">
          {t('machineControl.machine.list.statusBar.all')}
        </Text>
      </div>
      <div
        className={classNames(styles.card)}
        onClick={() => onSelectedStatus(StatusColor.SUCCESS)}
      >
        <Text size="3xl" view="success" weight="semibold">
          {successCount}
        </Text>
        <Text className={styles.text} view="success" weight="semibold">
          {t('machineControl.machine.list.statusBar.success')}
        </Text>
      </div>
      <div
        className={classNames(styles.card)}
        onClick={() => onSelectedStatus(StatusColor.WARNING)}
      >
        <Text size="3xl" view="warning" weight="semibold">
          {warningCount}
        </Text>
        <Text className={styles.text} view="warning" weight="semibold">
          {t('machineControl.machine.list.statusBar.warning')}
        </Text>
      </div>
      <div className={classNames(styles.card)} onClick={() => onSelectedStatus(StatusColor.ERROR)}>
        <Text size="3xl" view="alert" weight="semibold">
          {alertCount}
        </Text>
        <Text className={styles.text} view="alert" weight="semibold">
          {t('machineControl.machine.list.statusBar.alert')}
        </Text>
      </div>
    </div>
  );
};

export default MachineListStatusBar;
