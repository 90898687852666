import { FC, useState } from 'react';
import {
  CreateProductLineDTO,
  EditProductLineDTO,
} from '../../../../types/serverInterface/productLineDTO';
import { useAppDispatch } from '../../../../app/hooks/store';
import { useFormik } from 'formik';
import { createProductLineValidationScheme } from './createProductLineValidationScheme';
import { editProductLineValidationScheme } from './editProductLineValidationScheme';
import {
  createProductLineAction,
  editProductLineAction,
} from '../../../../state/productBase/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DefaultModal from '../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import styles from './ProductLineForm.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение формы создания линейки продуктов
 */
const createInitialValue: CreateProductLineDTO = {
  name: '',
  description: '',
  mediaKey: '',
  organizationId: null,
  brandId: 0,
};

/**
 * Дополнительные свойства для формы создания линейки продуктов
 */
type CreateProductLineFormProps = {
  /**
   * Тип формы
   */
  type: 'create';
  /**
   * id бренда
   */
  brandId: number;
};

/**
 * Дополнительные свойства для формы изменения линейки продуктов
 */
type EditProductLineFormProps = {
  /**
   * Тип формы
   */
  type: 'edit';
  /**
   * Линейка продуктов
   */
  productLine: EditProductLineDTO;
};

/**
 * Свойства компонента ProductLineForm
 */
type ProductLineFormProps = (CreateProductLineFormProps | EditProductLineFormProps) & {
  /**
   * Флаг открытия формы линейки продуктов
   */
  isOpen: boolean;
  /**
   * Обработчик формы линейки продуктов
   */
  onClose: () => void;
};

/**
 * Форма линейки продуктов
 */
const ProductLineForm: FC<ProductLineFormProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isDescriptionFocus, setIsDescriptionFocus] = useState(false);

  const { isOpen, type, onClose } = props;

  const isCreate = type === 'create';

  const initialValue = isCreate
    ? {
        ...createInitialValue,
        brandId: props?.brandId || 0,
        organizationId: null,
      }
    : { ...props.productLine, description: props.productLine?.description || '' };

  const formik = useFormik({
    validationSchema: isCreate
      ? createProductLineValidationScheme
      : editProductLineValidationScheme,
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчик
  function handleSubmit(data: CreateProductLineDTO | EditProductLineDTO) {
    if (isCreate) {
      dispatch(createProductLineAction(data)).then(() => {
        onClose();
        formik.setValues({
          ...createInitialValue,
          brandId: props?.brandId || 0,
          organizationId: null,
        });
      });
    }

    if (!isCreate) {
      dispatch(editProductLineAction(data as EditProductLineDTO)).then(() => {
        onClose();
        formik.setValues({ ...createInitialValue, organizationId: null });
      });
    }
  }

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleDescriptionFocus = () => setIsDescriptionFocus(true);

  const handleDescriptionBlur = () => setIsDescriptionFocus(false);

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBase.productLine.form.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button
        label={
          isCreate
            ? t('productBase.productLine.form.add.button.label')
            : t('productBase.productLine.form.edit.button.label')
        }
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={
        isCreate
          ? t('productBase.productLine.form.add.modal.title')
          : t('productBase.productLine.form.edit.modal.title')
      }
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      <form className={styles.productLineForm} onSubmit={formik.handleSubmit}>
        <TextField
          label={t('productBase.productLine.form.name.input.label')}
          name="name"
          width="full"
          value={formik.values.name}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          label={t('productBase.productLine.form.mediaKey.input.label')}
          name="mediaKey"
          width="full"
          value={formik.values.mediaKey}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          label={t('productBase.productLine.form.description.input.label')}
          name="description"
          type="textarea"
          minRows={2}
          maxRows={isDescriptionFocus ? 1000 : 2}
          width="full"
          value={formik.values.description}
          onChange={({ e }) => formik.handleChange(e)}
          onFocus={handleDescriptionFocus}
          onBlur={handleDescriptionBlur}
        />
      </form>
    </DefaultModal>
  );
};

export default ProductLineForm;
