import styles from './TableHeader.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Column, TableHeaderProps } from '../types';
import classNames from 'classnames';
import { Button } from '@consta/uikit/Button';
import { IconSortUp } from '@consta/uikit/IconSortUp';
import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { DisplayType, displayTypeValue } from '../../../pages/App';

function convertRecordToArray<T extends Record<string, any>, K extends keyof T>(
  columns: Record<K, Column<T, K>>,
) {
  const columnsList: {
    key: K;
    title: string;
    alignment: 'left' | 'right';
    sortable?: boolean;
    className?: string;
    width?: string;
  }[] = [];
  let count = 0;

  for (const key in columns) {
    if (columns.hasOwnProperty(key)) {
      if (!columns[key]?.multi) {
        const column = {
          key,
          title: columns[key].title,
          alignment: columns[key].alignment || 'left',
          sortable: columns[key].sortable,
          className: columns[key].className,
          width: columns[key].width,
        };
        columnsList.push(column);
        count++;
      } else {
        const columnCount = columns[key].count as number;

        for (let i = 0; i < columnCount; i++) {
          const column = {
            key,
            title: columns[key].title + ' ' + (i + 1),
            alignment: columns[key].alignment || 'left',
            sortable: columns[key].sortable,
            className: columns[key].className,
            width: columns[key].width,
          };
          columnsList.push(column);
        }

        count = count + columnCount;
      }
    }
  }

  return { columnsList, count };
}

/**
 * Компонент шапки таблицы вида "карточки"
 *
 * @param columns колонки таблицы
 * @param sortOrder направление сортировки для столбцов
 * @param onSortClick обработчик изменения направления сортировки
 */
const TableHeader = <T extends Record<string, any>, K extends keyof T>({
  columns,
  sortOrder,
  onSortClick,
}: TableHeaderProps<T, K>) => {
  const { columnsList, count } = convertRecordToArray(columns);

  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const handleSortClick = (key: K) => () => {
    if (sortOrder[key] === 'asc') {
      onSortClick({ ...sortOrder, [key]: 'desc' });
    } else if (sortOrder[key] === 'desc') {
      onSortClick({ ...sortOrder, [key]: 'none' });
    } else {
      onSortClick({ ...sortOrder, [key]: 'asc' });
    }
  };

  return (
    <tr className={classNames(styles.TableHeader, isLaptop && styles.LAPTOP)}>
      {columnsList.map((data) => (
        <th
          key={String(data.title)}
          style={{ width: data.width }}
          className={classNames(
            styles.columnTitle,
            data.alignment === 'right' && styles.alignRight,
            data?.className,
          )}
        >
          <Text size={isLaptop ? 'm' : 'l'}>{String(data.title)}</Text>
          {data.sortable && (
            <Button
              onlyIcon
              view="clear"
              iconLeft={() => {
                switch (sortOrder[data.key]) {
                  case 'asc':
                    return (<IconSortUp {...defaultIconProps} />) as any;
                  case 'desc':
                    return (<IconSortDown {...defaultIconProps} />) as any;
                  default:
                    return (<IconHamburger {...defaultIconProps} />) as any;
                }
              }}
              onClick={handleSortClick(data.key as K)}
            />
          )}
        </th>
      ))}
    </tr>
  );
};

export default TableHeader;
