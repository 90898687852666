import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconEdit2SizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.01134 2.36124L7.05637 2.38724C7.44543 2.61184 7.76986 2.79914 8.00742 2.98246C8.25816 3.17596 8.45717 3.39805 8.54053 3.70915C8.62389 4.02024 8.56258 4.31209 8.44218 4.60503C8.32811 4.88257 8.1408 5.20698 7.91617 5.59603L5.9784 8.95239C5.82994 9.21003 5.71254 9.41375 5.53386 9.56728C5.35518 9.72081 5.13611 9.80619 4.85904 9.91417L4.78417 9.94339C4.27006 10.1443 3.84629 10.3098 3.49926 10.3909C3.13631 10.4757 2.78588 10.4863 2.45925 10.2977C2.13261 10.1091 1.96653 9.80035 1.8585 9.44363C1.7552 9.10255 1.68671 8.65278 1.60361 8.1071L1.59148 8.02764C1.54647 7.73371 1.51088 7.50129 1.55449 7.26979C1.55995 7.24085 1.56656 7.21235 1.57424 7.18413C1.62803 6.98656 1.73445 6.80259 1.86474 6.57736L3.80254 3.22103C4.02715 2.83197 4.21445 2.50754 4.39777 2.26999C4.59126 2.01925 4.81336 1.82023 5.12445 1.73687C5.43554 1.65352 5.72739 1.71482 6.02034 1.83522C6.29788 1.94929 6.62229 2.1366 7.01134 2.36124ZM4.25156 3.94331L7.06615 5.56831L5.35443 8.53309C5.16795 8.85608 5.11463 8.93867 5.04508 8.99842C4.97554 9.05818 4.88587 9.09845 4.53848 9.23418C3.99025 9.44837 3.61686 9.59325 3.32863 9.6606C3.05019 9.72565 2.92384 9.6999 2.83425 9.64817C2.74465 9.59644 2.65918 9.4999 2.5763 9.22624C2.4905 8.94295 2.42928 8.54715 2.34066 7.96526C2.28451 7.59656 2.27455 7.49876 2.29153 7.40866C2.3085 7.31855 2.35337 7.23108 2.53984 6.90809L4.25156 3.94331ZM7.74849 4.31992C7.68223 4.48114 7.58089 4.6707 7.44036 4.91834L4.62735 3.29424C4.77154 3.04873 4.88504 2.86618 4.99153 2.72819C5.13376 2.54388 5.22956 2.48517 5.31857 2.46132C5.40758 2.43747 5.5199 2.44041 5.73523 2.52892C5.96063 2.62155 6.24143 2.78275 6.65885 3.02376C7.07628 3.26476 7.35629 3.42734 7.54921 3.57622C7.73352 3.71845 7.79223 3.81425 7.81608 3.90326C7.83993 3.99227 7.83699 4.10459 7.74849 4.31992Z"
    />
    <path d="M6.32983 10.1254C6.32983 9.91827 6.49773 9.75038 6.70483 9.75038H9.70483C9.91194 9.75038 10.0798 9.91827 10.0798 10.1254C10.0798 10.3325 9.91194 10.5004 9.70483 10.5004H6.70483C6.49773 10.5004 6.32983 10.3325 6.32983 10.1254Z" />
  </svg>
);

const IconEdit2SizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.97272 1.8418L10.0465 1.88463C10.6839 2.25464 11.2154 2.56318 11.6046 2.86518C12.0154 3.18394 12.3415 3.54982 12.4781 4.06231C12.6146 4.57479 12.5142 5.05557 12.3169 5.53816C12.1301 5.99537 11.8232 6.5298 11.4551 7.1707L8.28041 12.6999C8.03716 13.1243 7.84483 13.4599 7.55209 13.7128C7.25935 13.9657 6.90043 14.1064 6.4465 14.2843L6.32384 14.3324C5.48154 14.6634 4.78725 14.9361 4.21869 15.0697C3.62406 15.2094 3.04993 15.2268 2.51478 14.9161C1.97964 14.6054 1.70755 14.0968 1.53054 13.5091C1.3613 12.9473 1.24909 12.2063 1.11296 11.3074L1.09309 11.1765C1.01934 10.6923 0.961021 10.3094 1.03248 9.92801C1.04141 9.88034 1.05224 9.83339 1.06483 9.7869C1.15296 9.46143 1.32732 9.15835 1.54077 8.78732L4.71558 3.2582C5.08357 2.61727 5.39042 2.08282 5.69077 1.69147C6.00778 1.27841 6.37166 0.950559 6.88134 0.81324C7.39101 0.67592 7.86917 0.776908 8.34911 0.975252C8.80382 1.16317 9.33533 1.47174 9.97272 1.8418ZM5.45123 4.44806L10.0625 7.12504L7.25811 12.0091C6.9526 12.5412 6.86524 12.6773 6.7513 12.7757C6.63736 12.8742 6.49045 12.9405 5.92131 13.1641C5.0231 13.5169 4.41137 13.7556 3.93914 13.8666C3.48296 13.9737 3.27596 13.9313 3.12917 13.8461C2.98237 13.7609 2.84235 13.6018 2.70656 13.151C2.56599 12.6843 2.4657 12.0323 2.3205 11.0737C2.2285 10.4663 2.21219 10.3052 2.24 10.1568C2.26781 10.0083 2.34131 9.86425 2.64683 9.33216L5.45123 4.44806ZM11.1804 5.06848C11.0719 5.33407 10.9058 5.64635 10.6756 6.05429L6.0669 3.3788C6.30314 2.97435 6.48909 2.67363 6.66355 2.44631C6.89658 2.14268 7.05354 2.04596 7.19936 2.00667C7.34519 1.96738 7.52922 1.97223 7.882 2.11803C8.25128 2.27064 8.71133 2.53619 9.39523 2.93321C10.0791 3.33024 10.5379 3.59806 10.8539 3.84333C11.1559 4.07763 11.2521 4.23546 11.2912 4.38208C11.3303 4.52871 11.3254 4.71375 11.1804 5.06848Z"
    />
    <path d="M8.85618 14.6322C8.85618 14.2911 9.13124 14.0145 9.47056 14.0145H14.3856C14.7249 14.0145 15 14.2911 15 14.6322C15 14.9734 14.7249 15.25 14.3856 15.25H9.47056C9.13124 15.25 8.85618 14.9734 8.85618 14.6322Z" />
  </svg>
);

const IconEdit2SizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0227 4.72247L14.1127 4.77447C14.8909 5.22369 15.5397 5.59828 16.0148 5.96492C16.5163 6.35191 16.9143 6.79611 17.0811 7.41829C17.2478 8.04048 17.1252 8.62417 16.8844 9.21006C16.6562 9.76514 16.2816 10.414 15.8323 11.1921L11.9568 17.9048C11.6599 18.4201 11.4251 18.8275 11.0677 19.1346C10.7104 19.4416 10.2722 19.6124 9.71809 19.8283L9.56835 19.8868C8.54013 20.2885 7.69257 20.6197 6.99851 20.7819C6.27263 20.9514 5.57176 20.9726 4.91849 20.5954C4.26522 20.2182 3.93306 19.6007 3.71699 18.8873C3.51039 18.2051 3.37341 17.3056 3.20723 16.2142L3.18297 16.0553C3.09294 15.4674 3.02175 15.0026 3.10899 14.5396C3.11989 14.4817 3.13311 14.4247 3.14848 14.3683C3.25606 13.9731 3.46891 13.6052 3.72947 13.1547L7.60509 6.44206C8.0543 5.66394 8.42889 5.01509 8.79553 4.53997C9.18253 4.03849 9.62672 3.64046 10.2489 3.47375C10.8711 3.30704 11.4548 3.42964 12.0407 3.67044C12.5958 3.89858 13.2446 4.27321 14.0227 4.72247ZM8.50313 7.88661L14.1323 11.1366L10.7089 17.0662C10.3359 17.7122 10.2293 17.8773 10.0902 17.9968C9.95108 18.1164 9.77174 18.1969 9.07696 18.4684C7.98049 18.8967 7.23373 19.1865 6.65725 19.3212C6.10038 19.4513 5.84769 19.3998 5.66849 19.2963C5.48929 19.1929 5.31836 18.9998 5.1526 18.4525C4.981 17.8859 4.85857 17.0943 4.68132 15.9305C4.56901 15.1931 4.5491 14.9975 4.58305 14.8173C4.61701 14.6371 4.70673 14.4622 5.07969 13.8162L8.50313 7.88661ZM15.497 8.63984C15.3645 8.96228 15.1618 9.34141 14.8807 9.83667L9.2547 6.58848C9.54308 6.09745 9.77007 5.73237 9.98305 5.45638C10.2675 5.08776 10.4591 4.97034 10.6371 4.92264C10.8152 4.87494 11.0398 4.88083 11.4705 5.05783C11.9213 5.24311 12.4829 5.56551 13.3177 6.04751C14.1526 6.52952 14.7126 6.85468 15.0984 7.15244C15.467 7.4369 15.5845 7.62851 15.6322 7.80652C15.6799 7.98454 15.674 8.20919 15.497 8.63984Z"
    />
    <path d="M12.6597 20.2508C12.6597 19.8365 12.9955 19.5008 13.4097 19.5008H19.4097C19.8239 19.5008 20.1597 19.8365 20.1597 20.2508C20.1597 20.665 19.8239 21.0008 19.4097 21.0008H13.4097C12.9955 21.0008 12.6597 20.665 12.6597 20.2508Z" />
  </svg>
);

export const IconEdit2 = createIcon({
  name: 'IconEdit2',
  xs: IconEdit2SizeXS,
  s: IconEdit2SizeS,
  m: IconEdit2SizeM,
});
