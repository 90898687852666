import * as Yup from 'yup';
import { EditTasteDTO } from '../../../../types/serverInterface/tasteDTO';

/**
 * Схема валидации изменения вкуса
 */
export const editTasteValidationScheme: Yup.ObjectSchema<EditTasteDTO> = Yup.object({
  name: Yup.string().required(),
  mediaKey: Yup.string().required(),
  hexCode: Yup.string().min(7).max(9).required(),
});
