import React, { FC, useEffect } from 'react';
import ClassicTable from '../../../../components/ClassicTable';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  selectMachineSales,
  selectMachineSalesQty,
} from '../../../../state/machineControl/selectors';
import { getMachineSaLesListAction } from '../../../../state/machineControl/actions';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { MachineSalesDTO, SaleMethod } from '../../../../types/serverInterface/machineDTO';
import VerticalContainer from '../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { IconSaleMethodCard } from '../../../../assets/icon/iconSaleMethodCard';
import { IconSaleMethodQrCode } from '../../../../assets/icon/iconSaleMethodQrCode';
import { IconSaleMethodCash } from '../../../../assets/icon/iconSaleMethodCash';
import { IconSaleMethodRfId } from '../../../../assets/icon/iconSaleMethodRfId';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { TypeDiscountEnum } from '../../../../types/serverInterface/promoCodeDTO';
import styles from './MachineSales.module.scss';
import { withTooltip } from '@consta/uikit/withTooltip';
import { getMachineSaLesListQtyThunk } from '../../../../state/machineControl/thunk';
import { DisplayType, displayTypeValue } from '../../../App';
import classNames from 'classnames';
import TableHeaderButton from '../../../../components/ClassicTable/ClassicTableHeader/TableHeaderButton';
import { IconDocFilled } from '../../../../assets/icon/iconDocFilled';
import { useExportSalesFromExcel } from './helpers/useExportSalesFromExcel';

/**
 * Свойства компонента MachineSales
 */
type MachineSalesProps = {
  /**
   * Серийный номер автомата
   */
  serialNumber: string;
};

const TextWithTooltip = withTooltip({ direction: 'downCenter' })(Text);

/**
 * Список продаж автомата
 */
const MachineSales: FC<MachineSalesProps> = ({ serialNumber }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const dispatch = useAppDispatch();

  const { state, isLoading } = useAppSelector(selectMachineSales());
  const fullLength = useAppSelector(selectMachineSalesQty());
  const { handleExcelExportClick } = useExportSalesFromExcel({
    serialNumber,
    page: 0,
    limit: fullLength,
  });

  // const [pageLength, setPageLength] = useState<number>(20);
  // const [startPageIndex, setStartPageIndex] = useState<number>(0);

  const salesList = state?.data;
  const { page, limit } = state?.pagination || { page: 1, limit: 10 };

  useEffect(() => {
    serialNumber && dispatch(getMachineSaLesListAction(serialNumber, page, limit));
  }, [dispatch, serialNumber, page, limit]);

  useEffect(() => {
    serialNumber && dispatch(getMachineSaLesListQtyThunk(serialNumber));
  }, [dispatch, serialNumber]);

  // Обработчики
  const handleBackClick = () => {
    !isLoading &&
      serialNumber &&
      dispatch(getMachineSaLesListAction(serialNumber, page - 1, limit));
  };

  const handleNextClick = () => {
    !isLoading &&
      serialNumber &&
      dispatch(getMachineSaLesListAction(serialNumber, page + 1, limit));
  };

  const handleLimitChange = (limit: number) => {
    !isLoading && serialNumber && dispatch(getMachineSaLesListAction(serialNumber, page, limit));
  };

  // render методы
  const renderNameCell = (sale: MachineSalesDTO) => (
    <VerticalContainer space={isLaptop ? '2xs' : '2xs'}>
      <Text size={isLaptop ? 'm' : 'l'}>{sale.name}</Text>
      <Text size={isLaptop ? 'm' : 'l'} view="ghost">
        {sale.volume}
        {sale.unit === 'ML' ? 'мл' : 'шт'}
      </Text>
    </VerticalContainer>
  );

  const renderPriceCell = (sale: MachineSalesDTO) => {
    const { price, discountPrices } = sale;
    const discountPrice = discountPrices?.reduce((acc, price) => {
      return acc + price.price;
    }, 0 as number);

    return (
      <HorizontalContainer space="xs" align="center">
        <VerticalContainer space={isLaptop ? '2xs' : '2xs'} align="start">
          <TextWithTooltip
            tooltipProps={{
              content: (
                <HorizontalContainer>
                  {discountPrices?.map(({ method, price }, index) => {
                    switch (method) {
                      case SaleMethod.CARD:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size={isLaptop ? 'm' : 'l'}>{price}</Text>
                            <IconSaleMethodCard {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                      case SaleMethod.RF_ID:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size={isLaptop ? 'm' : 'l'}>{price}</Text>
                            <IconSaleMethodRfId {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                      case SaleMethod.CASH:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size={isLaptop ? 'm' : 'l'}>{price}</Text>
                            <IconSaleMethodCash {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                      case SaleMethod.QR_CODE:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size={isLaptop ? 'm' : 'l'}>{price}</Text>
                            <IconSaleMethodQrCode {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                    }
                  })}
                </HorizontalContainer>
              ) as any,
            }}
          >
            {discountPrice || 0}
          </TextWithTooltip>
          {discountPrice !== price && (
            <Text size={isLaptop ? 'm' : 'l'} className={styles.lineThrough} view="ghost">
              {price}
            </Text>
          )}
        </VerticalContainer>
        <>
          {discountPrices?.map(({ method }, index) => {
            switch (method) {
              case SaleMethod.CARD:
                return <IconSaleMethodCard key={index} {...defaultIconProps} />;
              case SaleMethod.RF_ID:
                return <IconSaleMethodRfId key={index} {...defaultIconProps} />;
              case SaleMethod.CASH:
                return <IconSaleMethodCash key={index} {...defaultIconProps} />;
              case SaleMethod.QR_CODE:
                return <IconSaleMethodQrCode key={index} {...defaultIconProps} />;
            }
          })}
        </>
      </HorizontalContainer>
    );
  };

  const renderPromoCodeCell = (sale: MachineSalesDTO) => (
    <VerticalContainer space={isLaptop ? '2xs' : '2xs'}>
      <Text size={isLaptop ? 'm' : 'l'}>{sale.promoCode?.code}</Text>
      <Text
        size={isLaptop ? 'm' : 'l'}
        view="ghost"
      >{`${sale.promoCode?.type !== TypeDiscountEnum.FREE ? sale.promoCode?.amount || '' : ''} ${
        sale.promoCode?.type === TypeDiscountEnum.PERCENT
          ? '%'
          : sale.promoCode?.type === TypeDiscountEnum.FIXED
            ? 'руб'
            : sale.promoCode?.type === TypeDiscountEnum.FREE
              ? 'Бесплатно'
              : 'Нет скидки'
      }`}</Text>
    </VerticalContainer>
  );

  const renderDateSaleCell = (sale: MachineSalesDTO) => (
    <VerticalContainer space={isLaptop ? '2xs' : '2xs'}>
      <Text size={isLaptop ? 'm' : 'l'}>{sale.dateSale.split(' ')[0]}</Text>
      <Text size={isLaptop ? 'm' : 'l'} view="ghost">
        {sale.dateSale.split(' ')[1]}
      </Text>
    </VerticalContainer>
  );

  const renderTableHeaderActions = () => (
    <HorizontalContainer space="xs">
      <TableHeaderButton
        size={isLaptop ? 's' : 'm'}
        label="Импорт в excel"
        // tooltipText="Подробное описание кнопки"
        disabled={false}
        iconLeft={IconDocFilled as any}
        onClick={handleExcelExportClick}
      />
    </HorizontalContainer>
  );

  // if (!salesList) return null;

  return (
    <div className={classNames(styles.machineSales, isLaptop && styles.laptop)}>
      <ClassicTable
        // Для пагинации
        withPageSetting
        fullLength={fullLength}
        limit={limit}
        page={page}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        onLimitChange={handleLimitChange}
        // Не пагинация
        withHeaderActions
        renderTableHeadActions={renderTableHeaderActions}
        rows={salesList || []}
        columns={{
          name: {
            key: 'name',
            alignment: 'left',
            title: 'НАЗВАНИЕ ПРОДУКТА',
            className: styles.nameCell,
            renderCell: renderNameCell,
          },
          price: {
            key: 'price',
            alignment: 'left',
            title: 'ЦЕНА',
            className: styles.priceCell,
            renderCell: renderPriceCell,
          },
          promoCode: {
            key: 'promoCode',
            alignment: 'left',
            title: 'ПРОМОКОД',
            className: styles.promoCodeCell,
            renderCell: renderPromoCodeCell,
          },
          dateSale: {
            key: 'dateSale',
            alignment: 'right',
            title: 'ВРЕМЯ ПОКУПКИ',
            className: styles.dateSaleCell,
            renderCell: renderDateSaleCell,
          },
        }}
      />
    </div>
  );
};

export default MachineSales;
