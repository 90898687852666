import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectRecipeDetails } from '../../../../state/productBase/selectors';
import { deleteRecipeAction, getRecipeByIdAction } from '../../../../state/productBase/actions';
import DeleteModal from '../../../../components/DeleteModal';
import DefaultModal from '../../../../components/DefaultModal';
import { Loader } from '@consta/uikit/Loader';
import { RecipeDetailsDTO } from '../../../../types/serverInterface/recipeDTO';
import NoContent from '../../../../components/NoContent';
import RecipeInfo from '../RecipeInfo';
import { RecipeContextMenuItems } from '../types';
import EditRecipe from '../RecipeForm/EditRecipe';
import RecipeLocale from '../RecipeLocale';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента RecipeModal
 */
type RecipeInfoProps = {
  /**
   * Выбранный шаг
   */
  selectedStep: RecipeContextMenuItems;
  /**
   * id рецепта
   */
  recipeId: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Допуск к редактированию
   */
  isAllowedEdit: boolean;
  /**
   * Обработчик выбора шага
   */
  onSelectedStepChange: (step: RecipeContextMenuItems) => void;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Модальное окно рецепта
 */
const RecipeModal: FC<RecipeInfoProps> = ({
  selectedStep,
  recipeId,
  organizationId,
  isAllowedEdit,
  onSelectedStepChange,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state: recipe, isLoading } = useAppSelector(selectRecipeDetails());

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getRecipeByIdAction(recipeId));
  }, [dispatch, recipeId]);

  // Обработчики
  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    dispatch(deleteRecipeAction(recipeId, organizationId)).finally(() => setIsDeleteLoading(false));
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleLocaleClose = () => {
    handleClose();
  };

  // render методы
  const renderLoading = () => (
    <DefaultModal
      isOpen
      modalTitle={t('productBase.recipe.modal.loading.title')}
      onClose={handleClose}
    >
      <Loader />
    </DefaultModal>
  );

  const renderNoContent = () => (
    <DefaultModal
      isOpen
      modalTitle={t('productBase.recipe.modal.noContent.title')}
      onClose={handleClose}
    >
      <NoContent />
    </DefaultModal>
  );

  const renderDeleteModal = (recipeName: string) => (
    <DeleteModal
      isOpen
      isDeleteLoading={isDeleteLoading}
      title={t('productBase.recipe.modal.deleteModal.title', { recipeName })}
      description={t('productBase.recipe.modal.deleteModal.description', { recipeName })}
      withConfirmDeletion={false}
      onClose={handleClose}
      onDelete={handleDelete}
    />
  );

  const renderInfoModal = (recipe: RecipeDetailsDTO) => (
    <RecipeInfo
      recipe={recipe}
      selectedStep={selectedStep}
      onSelectedStepChange={onSelectedStepChange}
      onClose={handleClose}
      onEditClick={handleEditClick}
    />
  );

  const renderLocaleModal = (recipe: RecipeDetailsDTO) => (
    <RecipeLocale
      recipeId={recipeId}
      organizationId={organizationId}
      selectedStep={selectedStep}
      recipeName={recipe.name}
      onSelectedStepChange={onSelectedStepChange}
      onClose={handleLocaleClose}
    />
  );

  const renderEditModal = (recipe: RecipeDetailsDTO) => (
    <EditRecipe recipe={recipe} selectedStep={selectedStep} onEditClose={handleEditClose} />
  );

  const renderModal = (recipe: RecipeDetailsDTO) => {
    const { nameLocale, name } = recipe;

    if (isEdit) {
      return renderEditModal(recipe);
    }

    switch (selectedStep) {
      case RecipeContextMenuItems.DELETE:
        return renderDeleteModal(nameLocale || name);
      case RecipeContextMenuItems.LOCALE:
        return renderLocaleModal(recipe);
      default:
        return renderInfoModal(recipe);
    }
  };

  if (isLoading) return renderLoading();
  if (!recipe) return renderNoContent();
  return renderModal(recipe);
};

export default RecipeModal;
