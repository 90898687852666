import { CustomFormType } from './customForm';

/**
 * Возможные единицы измерения объёма состава
 */
export enum CellCategoryInitEnum {
  ML = 'ML',
  G = 'G',
}

/**
 * dto создания категории контейнера
 */
export type CreateCellCategoryDTO = {
  /**
   * Название категории контейнера
   */
  name: string;
  /**
   * Единицы измерения объёма состава
   */
  unit: CellCategoryInitEnum;
  /**
   * Json конфигурации автомата
   */
  configMachine: CustomFormType;
};

/**
 * dto изменения категории контейнера
 */
export type EditCellCategoryDTO = CreateCellCategoryDTO;

/**
 * dto получения категории контейнера
 */
export type CellCategoryDTO = EditCellCategoryDTO & {
  id: number;
};

// Для запросов
/**
 * Тело запроса создания категории контейнера
 */
export type CreateCellCategoryReq = {
  name: string;
  unit: CellCategoryInitEnum;
  /**
   * Json конфигурации автомата
   */
  configMachine: string;
};

/**
 * Тело запроса изменения категории контейнера
 */
export type EditCellCategoryReq = CreateCellCategoryReq;

/**
 * Тело запроса получения категории контейнера
 */
export type CellCategoryRes = EditCellCategoryReq & {
  id: number;
};
