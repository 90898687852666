import { FC, useEffect, useState } from 'react';
import FilterButton from '../../filters/FilterButton';
import VerticalContainer from '../../VerticalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import styles from './ModelFilter.module.scss';
import { useTranslation } from 'react-i18next';
import { MachineListFiltersModelInfo } from '../../../types/serverInterface/machineListDTO';
import HorizontalContainer from '../../HorizontalContainer';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

/**
 * Свойства компонента ModelFilter
 */
type ModelFilterProps = {
  size?: 'm' | 'l';
  /**
   * Выбранные модели автомата
   */
  selectedModelId: number[] | null;
  /**
   * Список моделей
   */
  modelList: MachineListFiltersModelInfo[] | null;
  /**
   * Обработчик выбранных модели автомата
   *
   * @param modelIdList список выбранных моделей автомата
   */
  onSelectModelId: (modelIdList: number[] | null) => void;
};

/**
 * Фильтр по модели автомата
 */
const ModelFilter: FC<ModelFilterProps> = ({
  size = 'm',
  onSelectModelId,
  selectedModelId,
  modelList,
}) => {
  const { t } = useTranslation();

  const [selectedModelIds, setSelectedModelIds] = useState<number[] | null>(selectedModelId);

  useEffect(() => {
    setSelectedModelIds(selectedModelId);
  }, [selectedModelId]);

  // Обработчики
  const handleCheckboxChange =
    (modelId: number) =>
    ({ checked }: { checked: boolean }) => {
      if (checked) {
        setSelectedModelIds((prevSelectedModelIds) => [...(prevSelectedModelIds || []), modelId]);
      } else {
        setSelectedModelIds((prevSelectedModelIds) =>
          (prevSelectedModelIds || []).filter((id) => id !== modelId),
        );
      }
    };

  const handleApplyButtonClick = () => {
    onSelectModelId(selectedModelIds);
  };

  // render методы
  const renderModelList = ({ modelName, modelId, modelCount }: MachineListFiltersModelInfo) => (
    <HorizontalContainer space="auto" key={modelId}>
      <Checkbox
        label={modelName}
        checked={(selectedModelIds || []).includes(modelId)}
        onChange={handleCheckboxChange(modelId)}
      />
      <Text size="m" view="secondary">
        {modelCount}
      </Text>
    </HorizontalContainer>
  );

  const renderModelFilter = () => (
    <VerticalContainer className={styles.ModelFilter} space="5xl">
      <VerticalContainer>{modelList?.map(renderModelList)}</VerticalContainer>
      <Button
        label={t('machineControl.machine.list.filter.model.submit.button.label')}
        onClick={handleApplyButtonClick}
      />
    </VerticalContainer>
  );

  return (
    <FilterButton
      size={size}
      label={t('machineControl.machine.list.filter.model.filterButton.label')}
      modalChildren={renderModelFilter}
    />
  );
};

export default ModelFilter;
