import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultModal from '../../../components/DefaultModal';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectOrganization } from '../../../state/organization/selectors';
import {
  editOrganizationActions,
  getOrganizationByIdAction,
} from '../../../state/organization/actions';
import { OrganizationBasicProps } from './types';
import EditOrganizationInfo from './EditOrganizationInfo';
import OrganizationBasicInfo from './OrganizationBasicInfo';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import {
  DetailedOrganizationDTO,
  EditOrganizationBasicProps,
  EditOrganizationDTO,
} from '../../../types/serverInterface/organizationDTO';
import styles from './OrganizationBasic.module.scss';
import { Loader } from '@consta/uikit/Loader';

/**
 * Форма для отображения и редактирования информации об организации.
 */
const OrganizationBasic: FC<OrganizationBasicProps> = ({ organizationId, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organization: DetailedOrganizationDTO | null = useAppSelector(selectOrganization());
  const [isLoading, setIsLoading] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editValues, setEditValues] = useState<EditOrganizationBasicProps>({
    name: '',
    description: '',
  });

  const modalTitle = isEditOpen
    ? t('organization.basic.edit.modal.title')
    : t('organization.basic.info.modal.title');

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true);
      dispatch(getOrganizationByIdAction(organizationId)).finally(() => setIsLoading(false));
    }
  }, [dispatch, organizationId]);

  //TODO: убрать useEffect
  useEffect(() => {
    if (organization) {
      setEditValues({
        name: organization.name,
        description: organization.description,
      });
    }
  }, [organization]);

  //Обработчики
  const handleCancelButtonClick = () => {
    setIsEditOpen(false);
  };

  const handleSaveButtonClick = () => {
    setIsEditOpen(false);
    if (organizationId) {
      const data: EditOrganizationDTO = {
        name: editValues.name,
        description: editValues.description,
      };
      onClose();
      dispatch(editOrganizationActions(data, organizationId));
    }
  };

  const handleClose = () => {
    setIsEditOpen(false);
    onClose();
  };

  const handleChange = (key: keyof EditOrganizationBasicProps) => (value: string | null) => {
    setEditValues((prev) => ({
      ...prev,
      [key]: value || '',
    }));
  };

  //render методы
  const renderActions = () => (
    <>
      {isEditOpen ? (
        <>
          <Button
            label={t('organization.basic.edit.cancel.button.label')}
            view="clear"
            onClick={handleCancelButtonClick}
            size="l"
          />
          <Button
            label={t('organization.basic.edit.save.button.label')}
            view="primary"
            onClick={handleSaveButtonClick}
            size="l"
          />
        </>
      ) : (
        <Button
          label={t('organization.basic.info.edit.button.label')}
          view="primary"
          onClick={() => setIsEditOpen(true)}
          size="l"
        />
      )}
    </>
  );

  const renderContent = () => {
    if (isLoading) return <Loader />;
    if (isEditOpen) {
      return <EditOrganizationInfo value={editValues} onChange={handleChange} />;
    }
    return <OrganizationBasicInfo organization={organization} />;
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={handleClose}
      renderActions={renderActions}
      className={styles.organizationBasic}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default OrganizationBasic;
