import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconArrowRightSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M3.98483 9.76517C3.8517 9.63203 3.8396 9.4237 3.94853 9.27689L3.98483 9.23483L7.2195 6L3.98483 2.76516C3.8517 2.63203 3.8396 2.4237 3.94853 2.27689L3.98483 2.23484C4.11797 2.1017 4.3263 2.0896 4.47311 2.19853L4.51517 2.23484L8.01516 5.73484C8.1483 5.86797 8.1604 6.0763 8.05147 6.22311L8.01516 6.26517L4.51517 9.76517C4.36872 9.91161 4.13128 9.91161 3.98483 9.76517Z" />
  </svg>
);

const IconArrowRightSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M5.31307 13.0202C5.13556 12.8427 5.11942 12.5649 5.26466 12.3692L5.31307 12.3131L9.62596 7.99996L5.31307 3.68685C5.13556 3.50934 5.11942 3.23156 5.26466 3.03582L5.31307 2.97974C5.49058 2.80223 5.76836 2.78609 5.9641 2.93133L6.02018 2.97974L10.6868 7.64641C10.8644 7.82392 10.8805 8.10169 10.7353 8.29743L10.6868 8.35351L6.02018 13.0202C5.82492 13.2154 5.50833 13.2154 5.31307 13.0202Z" />
  </svg>
);

const IconArrowRightSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M7.96967 19.5303C7.7034 19.2641 7.6792 18.8474 7.89705 18.5538L7.96967 18.4697L14.439 12L7.96967 5.53033C7.7034 5.26406 7.6792 4.8474 7.89705 4.55379L7.96967 4.46967C8.23594 4.2034 8.6526 4.1792 8.94621 4.39705L9.03033 4.46967L16.0303 11.4697C16.2966 11.7359 16.3208 12.1526 16.1029 12.4462L16.0303 12.5303L9.03033 19.5303C8.73744 19.8232 8.26256 19.8232 7.96967 19.5303Z" />
  </svg>
);

export const IconArrowRight = createIcon({
  name: 'IconArrowRight',
  xs: IconArrowRightSizeXS,
  s: IconArrowRightSizeS,
  m: IconArrowRightSizeM,
});
