import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectProductListByProductLineId } from '../../../state/productBase/selectors';
import { getProductListAction } from '../../../state/productBase/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import NoContent from '../../../components/NoContent';
import CreateProductForm from './ProductForm/CreateProductForm';
import { Loader } from '@consta/uikit/Loader';
import ProductItem from './ProductItem';
import { ProductListItemDTO } from '../../../types/serverInterface/productDTO';
import { useTranslation } from 'react-i18next';
import styles from './ProductList.module.scss';
import { selectRole } from '../../../state/user/selectors';
import { Roles } from '../../../types/serverInterface/cabinetDTO';

/**
 * Свойства компонента ProductList
 */
type ProductListProps = {
  /**
   * id линейки продуктов
   */
  productLineId: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Возможность пользователя редактировать глобальные сущности
   */
  isAllowedGlobalEdit: boolean;
};

/**
 * Список продуктов в линейке продуктов
 */
const ProductList: FC<ProductListProps> = ({
  productLineId,
  organizationId,
  isAllowedGlobalEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const state = useAppSelector(selectProductListByProductLineId(productLineId));
  const role = useAppSelector(selectRole());
  const isShowEdit = role === Roles.ROOT || role === Roles.MANUFACTURER || role === Roles.DEVELOPER;

  const [isProductCreateOpen, setIsProductCreateOpen] = useState(false);

  useEffect(() => {
    dispatch(getProductListAction(productLineId, { organizationId }));
  }, [dispatch, productLineId, organizationId]);

  if (!state) return null;

  const { state: productList, isLoading } = state;

  // Обработчики
  const handleAddProductButtonClick = () => {
    setIsProductCreateOpen(true);
  };

  const handleAddProductModalClose = () => {
    setIsProductCreateOpen(false);
  };

  // render методы
  const renderAddProductForm = () => (
    <CreateProductForm
      isOpen={isProductCreateOpen}
      productLineId={productLineId}
      organizationId={organizationId}
      onClose={handleAddProductModalClose}
    />
  );

  const renderProductList = (productList: ProductListItemDTO[]) => (
    <div className={styles.table}>
      {productList.map((product, index) => (
        <ProductItem
          key={product.id}
          product={product}
          index={index}
          productLineId={productLineId}
          organizationId={organizationId}
          isAllowedGlobalEdit={isAllowedGlobalEdit}
        />
      ))}
    </div>
  );

  const renderContent = () =>
    isLoading ? (
      <Loader />
    ) : productList && productList.length ? (
      renderProductList(productList)
    ) : (
      <NoContent />
    );

  return (
    <div className={styles.productList}>
      {isShowEdit && (
        <Button
          className={styles.addButton}
          label={t('productBase.product.list.button.add.label')}
          view="ghost"
          iconLeft={IconAdd as any}
          onClick={handleAddProductButtonClick}
        />
      )}
      {renderContent()}
      {renderAddProductForm()}
    </div>
  );
};

export default ProductList;
