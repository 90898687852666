import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconUnlockSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.375 7.68506C5.375 7.33988 5.65482 7.06006 6 7.06006C6.34518 7.06006 6.625 7.33988 6.625 7.68506C6.625 8.03024 6.34518 8.31006 6 8.31006C5.65482 8.31006 5.375 8.03024 5.375 7.68506Z"
    />
    <path d="M6 0.810059C4.41218 0.810059 3.125 2.09724 3.125 3.68506V4.24192C3.125 4.27532 3.12937 4.3077 3.13756 4.33853C2.41951 4.60237 1.85983 5.18165 1.62236 5.91252C1.5 6.2891 1.5 6.75442 1.5 7.68506C1.5 8.6157 1.5 9.08102 1.62236 9.4576C1.86965 10.2187 2.46636 10.8154 3.22746 11.0627C3.60404 11.1851 4.06936 11.1851 4.99999 11.1851H7C7.93063 11.1851 8.39596 11.1851 8.77254 11.0627C9.53364 10.8154 10.1303 10.2187 10.3776 9.4576C10.5 9.08102 10.5 8.6157 10.5 7.68506C10.5 6.75442 10.5 6.2891 10.3776 5.91252C10.1303 5.15142 9.53364 4.55471 8.77254 4.30742C8.68764 4.27983 8.59823 4.25847 8.5 4.24192C8.1625 4.18506 7.72083 4.18506 7 4.18506H5C4.51736 4.18506 4.15987 4.18506 3.875 4.20213V3.68506C3.875 2.51145 4.8264 1.56006 6 1.56006C6.47891 1.56006 6.91983 1.71803 7.27499 1.98487C7.44057 2.10927 7.67565 2.07589 7.80005 1.91031C7.92446 1.74473 7.89108 1.50965 7.7255 1.38525C7.24491 1.02417 6.64692 0.810059 6 0.810059ZM4.625 7.68506C4.625 6.92567 5.24061 6.31006 6 6.31006C6.75939 6.31006 7.375 6.92567 7.375 7.68506C7.375 8.44445 6.75939 9.06006 6 9.06006C5.24061 9.06006 4.625 8.44445 4.625 7.68506Z" />
  </svg>
);

const IconUnlockSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16667 10.2446C7.16667 9.78543 7.53976 9.41323 8 9.41323C8.46024 9.41323 8.83333 9.78543 8.83333 10.2446C8.83333 10.7037 8.46024 11.0759 8 11.0759C7.53976 11.0759 7.16667 10.7037 7.16667 10.2446Z"
    />
    <path d="M8 1.09998C5.88291 1.09998 4.16667 2.81208 4.16667 4.92407V5.66476C4.16667 5.70919 4.17249 5.75226 4.18342 5.79326C3.22602 6.14421 2.47978 6.91472 2.16314 7.88686C2 8.38776 2 9.00669 2 10.2446C2 11.4824 2 12.1013 2.16314 12.6022C2.49287 13.6146 3.28848 14.4083 4.30328 14.7372C4.80538 14.9 5.42581 14.9 6.66665 14.9H9.33333C10.5742 14.9 11.1946 14.9 11.6967 14.7372C12.7115 14.4083 13.5071 13.6146 13.8369 12.6022C14 12.1013 14 11.4824 14 10.2446C14 9.00669 14 8.38776 13.8369 7.88686C13.5071 6.87451 12.7115 6.08082 11.6967 5.75188C11.5835 5.71519 11.4643 5.68677 11.3333 5.66476C10.8833 5.58913 10.2944 5.58913 9.33333 5.58913H6.66667C6.02314 5.58913 5.54649 5.58913 5.16667 5.61183V4.92407C5.16667 3.36304 6.43519 2.09757 8 2.09757C8.63854 2.09757 9.22645 2.30769 9.69999 2.66261C9.92076 2.82809 10.2342 2.78369 10.4001 2.56344C10.5659 2.3432 10.5214 2.03052 10.3007 1.86505C9.65988 1.38477 8.86256 1.09998 8 1.09998ZM6.16667 10.2446C6.16667 9.23447 6.98748 8.41564 8 8.41564C9.01252 8.41564 9.83333 9.23447 9.83333 10.2446C9.83333 11.2546 9.01252 12.0735 8 12.0735C6.98748 12.0735 6.16667 11.2546 6.16667 10.2446Z" />
  </svg>
);

const IconUnlockSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.75 15.37C10.75 14.6796 11.3096 14.12 12 14.12C12.6904 14.12 13.25 14.6796 13.25 15.37C13.25 16.0604 12.6904 16.62 12 16.62C11.3096 16.62 10.75 16.0604 10.75 15.37Z"
    />
    <path d="M12 1.62C8.82436 1.62 6.25 4.19436 6.25 7.37V8.48371C6.25 8.55052 6.25873 8.61528 6.27512 8.67693C4.83903 9.20462 3.71966 10.3632 3.24472 11.8249C3 12.5781 3 13.5087 3 15.37C3 17.2313 3 18.1619 3.24472 18.9151C3.73931 20.4373 4.93273 21.6307 6.45492 22.1253C7.20808 22.37 8.13871 22.37 9.99998 22.37H14C15.8613 22.37 16.7919 22.37 17.5451 22.1253C19.0673 21.6307 20.2607 20.4373 20.7553 18.9151C21 18.1619 21 17.2313 21 15.37C21 13.5087 21 12.5781 20.7553 11.8249C20.2607 10.3027 19.0673 9.1093 17.5451 8.61471C17.3753 8.55954 17.1965 8.51681 17 8.48371C16.325 8.37 15.4417 8.37 14 8.37H10C9.03471 8.37 8.31973 8.37 7.75 8.40413V7.37C7.75 5.02279 9.65279 3.12 12 3.12C12.9578 3.12 13.8397 3.43594 14.55 3.96962C14.8811 4.21842 15.3513 4.15166 15.6001 3.8205C15.8489 3.48934 15.7822 3.01918 15.451 2.77038C14.4898 2.04823 13.2938 1.62 12 1.62ZM9.25 15.37C9.25 13.8512 10.4812 12.62 12 12.62C13.5188 12.62 14.75 13.8512 14.75 15.37C14.75 16.8888 13.5188 18.12 12 18.12C10.4812 18.12 9.25 16.8888 9.25 15.37Z" />
  </svg>
);

export const IconUnlock = createIcon({
  name: 'IconUnlock',
  xs: IconUnlockSizeXS,
  s: IconUnlockSizeS,
  m: IconUnlockSizeM,
});
