import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconFaucetSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M8.5886 7.84655C8.68197 7.73392 8.83573 7.66669 9 7.66669C9.16427 7.66669 9.31803 7.73392 9.4114 7.84655C9.42207 7.85941 9.67557 8.16591 9.9325 8.53502C10.4412 9.26574 10.5 9.5784 10.5 9.75001C10.5 10.4393 9.8271 11 9 11C8.1729 11 7.5 10.4393 7.5 9.75001C7.5 9.5784 7.55883 9.26574 8.0675 8.53502C8.32443 8.16591 8.57793 7.85941 8.5886 7.84655Z" />
    <path d="M2 2.66665H4.5V1.83333H4C3.72387 1.83333 3.5 1.64677 3.5 1.41666C3.5 1.18655 3.72387 1 4 1H6C6.27613 1 6.5 1.18655 6.5 1.41666C6.5 1.64677 6.27613 1.83333 6 1.83333H5.5V2.66665H8C9.3785 2.66665 10.5 3.60123 10.5 4.74997V6.41662C10.5 6.64673 10.2761 6.83329 10 6.83329H8C7.72387 6.83329 7.5 6.64673 7.5 6.41662V5.16663H2C1.72387 5.16663 1.5 4.98008 1.5 4.74997V3.08332C1.5 2.85321 1.72387 2.66665 2 2.66665Z" />
  </svg>
);

const IconFaucetSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M11.4515 10.4005C11.576 10.2541 11.781 10.1667 12 10.1667C12.219 10.1667 12.424 10.2541 12.5485 10.4005C12.5628 10.4172 12.9008 10.8157 13.2433 11.2955C13.9216 12.2455 14 12.6519 14 12.875C14 13.771 13.1028 14.5 12 14.5C10.8972 14.5 10 13.771 10 12.875C10 12.6519 10.0784 12.2455 10.7567 11.2955C11.0992 10.8157 11.4372 10.4172 11.4515 10.4005Z" />
    <path d="M2.66667 3.66665H6V2.58332H5.33333C4.96516 2.58332 4.66667 2.3408 4.66667 2.04166C4.66667 1.74252 4.96516 1.5 5.33333 1.5H8C8.36818 1.5 8.66667 1.74252 8.66667 2.04166C8.66667 2.3408 8.36818 2.58332 8 2.58332H7.33333V3.66665H10.6667C12.5047 3.66665 14 4.8816 14 6.37496V8.54161C14 8.84075 13.7015 9.08327 13.3333 9.08327H10.6667C10.2985 9.08327 10 8.84075 10 8.54161V6.91662H2.66667C2.29849 6.91662 2 6.6741 2 6.37496V4.20831C2 3.90917 2.29849 3.66665 2.66667 3.66665Z" />
  </svg>
);

const IconFaucetSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M17.1772 15.6932C17.3639 15.4679 17.6715 15.3335 18 15.3335C18.3285 15.3335 18.6361 15.4679 18.8228 15.6932C18.8441 15.7189 19.3511 16.3319 19.865 17.0702C20.8823 18.5316 21 19.1569 21 19.5002C21 20.8787 19.6542 22.0002 18 22.0002C16.3458 22.0002 15 20.8787 15 19.5002C15 19.1569 15.1177 18.5316 16.135 17.0702C16.6489 16.3319 17.1559 15.7189 17.1772 15.6932Z" />
    <path d="M4 5.33333H9V3.66667H8C7.44773 3.66667 7 3.29356 7 2.83333C7 2.37311 7.44773 2 8 2H12C12.5523 2 13 2.37311 13 2.83333C13 3.29356 12.5523 3.66667 12 3.66667H11V5.33333H16C18.757 5.33333 21 7.2025 21 9.5V12.8333C21 13.2936 20.5523 13.6667 20 13.6667H16C15.4477 13.6667 15 13.2936 15 12.8333V10.3333H4C3.44773 10.3333 3 9.96022 3 9.5V6.16667C3 5.70645 3.44773 5.33333 4 5.33333Z" />
  </svg>
);

export const IconFaucet = createIcon({
  name: 'IconFaucet',
  xs: IconFaucetSizeXS,
  s: IconFaucetSizeS,
  m: IconFaucetSizeM,
});
