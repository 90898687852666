import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconArrowDownSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M2.23483 3.98483C2.36797 3.8517 2.5763 3.8396 2.72311 3.94853L2.76517 3.98483L6 7.2195L9.23484 3.98483C9.36797 3.8517 9.5763 3.8396 9.72311 3.94853L9.76516 3.98483C9.8983 4.11797 9.9104 4.3263 9.80147 4.47311L9.76516 4.51517L6.26516 8.01516C6.13203 8.1483 5.9237 8.1604 5.77689 8.05147L5.73483 8.01516L2.23483 4.51517C2.08839 4.36872 2.08839 4.13128 2.23483 3.98483Z" />
  </svg>
);

const IconArrowDownSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M2.97982 5.31307C3.15733 5.13556 3.43511 5.11942 3.63085 5.26466L3.68693 5.31307L8.00004 9.62596L12.3132 5.31307C12.4907 5.13556 12.7684 5.11942 12.9642 5.26466L13.0203 5.31307C13.1978 5.49058 13.2139 5.76836 13.0687 5.9641L13.0203 6.02018L8.35359 10.6868C8.17608 10.8644 7.89831 10.8805 7.70257 10.7353L7.64649 10.6868L2.97982 6.02018C2.78456 5.82492 2.78456 5.50833 2.97982 5.31307Z" />
  </svg>
);

const IconArrowDownSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z" />
  </svg>
);

export const IconArrowDown = createIcon({
  name: 'IconArrowDown',
  xs: IconArrowDownSizeXS,
  s: IconArrowDownSizeS,
  m: IconArrowDownSizeM,
});
