import { FC } from 'react';
import MatrixSchemeRow from './MatrixSchemeRow';
import VerticalContainer from '../../../../components/VerticalContainer';
import { MatrixSchemeProps } from './types';

/**
 * Схема ячеек модели автомата снек
 */
const MatrixScheme: FC<MatrixSchemeProps> = ({ modelMatrix }) => {
  const maxRows = modelMatrix.maxRows ?? 0;
  const maxCells = modelMatrix.maxCells ?? 0;

  return (
    <VerticalContainer space="3xs">
      {Array.from({ length: maxRows }, (_, rowIndex) => (
        <MatrixSchemeRow key={rowIndex} maxCells={maxCells} />
      ))}
    </VerticalContainer>
  );
};

export default MatrixScheme;
