import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSignOutSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" {...props}>
    <path d="M13.5 20.25H11.16C10.6062 20.2682 10.0678 20.066 9.66288 19.6878C9.25795 19.3096 9.01956 18.7862 9 18.2325V14.25H10.5V18.2325C10.5224 18.3871 10.6035 18.5271 10.7264 18.6234C10.8493 18.7198 11.0046 18.7651 11.16 18.75H13.5V20.25Z" />
    <path d="M21.75 16.4999H20.25V4.26741C20.2276 4.11427 20.1476 3.97543 20.0264 3.87926C19.9051 3.78309 19.7517 3.73682 19.5975 3.74991H11.16C11.0046 3.7348 10.8493 3.78013 10.7264 3.8765C10.6035 3.97286 10.5224 4.11285 10.5 4.26741V8.24991H9V4.26741C9.01956 3.71367 9.25795 3.19029 9.66288 2.81208C10.0678 2.43386 10.6062 2.23169 11.16 2.24991H19.5975C20.15 2.23372 20.6865 2.43679 21.0898 2.81479C21.4931 3.19279 21.7304 3.71501 21.75 4.26741V16.4999Z" />
    <path d="M6.3451 15.5325L3.1276 12.3075C2.84822 12.0265 2.69141 11.6463 2.69141 11.25C2.69141 10.8538 2.84822 10.4736 3.1276 10.1925L6.3451 6.96753L7.4101 8.03253L4.1926 11.25L7.4101 14.4675L6.3451 15.5325Z" />
    <path d="M10.6275 10.5H3.75V12H10.6275V10.5Z" />
    <path d="M14.6625 21.7499C14.4077 21.7563 14.1543 21.7103 13.918 21.615C13.6816 21.5197 13.4672 21.3771 13.2881 21.1958C13.1089 21.0145 12.9687 20.7985 12.8762 20.561C12.7837 20.3235 12.7407 20.0696 12.75 19.8149V6.31494C12.777 5.71934 12.995 5.14825 13.3719 4.68627C13.7488 4.22428 14.2645 3.89597 14.8425 3.74994L19.3425 2.65494C19.6327 2.58045 19.9361 2.5728 20.2297 2.63256C20.5233 2.69232 20.7995 2.81793 21.0375 2.99994C21.2614 3.18033 21.4417 3.40888 21.5651 3.66857C21.6884 3.92827 21.7516 4.21244 21.75 4.49994V17.9999C21.7284 18.5971 21.5156 19.1716 21.1429 19.6388C20.7703 20.1059 20.2575 20.4411 19.68 20.5949L15.18 21.6824C15.0109 21.7258 14.8371 21.7484 14.6625 21.7499ZM19.86 4.07244H19.68L15.18 5.15994C14.9239 5.23145 14.6966 5.38123 14.5298 5.58833C14.3631 5.79543 14.2652 6.04949 14.25 6.31494V19.8149C14.2451 19.8811 14.2546 19.9476 14.2779 20.0098C14.3012 20.072 14.3377 20.1283 14.385 20.1749C14.4498 20.2136 14.522 20.2382 14.597 20.2473C14.6719 20.2563 14.7479 20.2496 14.82 20.2274L19.32 19.1324C19.5722 19.0613 19.7964 18.9146 19.9627 18.7121C20.129 18.5096 20.2292 18.2611 20.25 17.9999V4.49994C20.2549 4.43486 20.2453 4.36951 20.222 4.30857C20.1987 4.24763 20.1621 4.19261 20.115 4.14744C20.0406 4.09499 19.951 4.06862 19.86 4.07244Z" />
  </svg>
);

export const IconSignOut = createIcon({
  name: 'IconSignOut',
  s: IconSignOutSizeL,
  m: IconSignOutSizeL,
  xs: IconSignOutSizeL,
});
