/**
 * Свойства компонента CreateOrganizationForm
 */
export type CreateOrganizationFormProps = {
  /**
   * Флаг открытия формы создания организации
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия формы создания организации
   */
  onClose: () => void;
};

/**
 * Шаги в форме
 */
export enum OrganizationFormSteps {
  /**
   * Базовая информация
   */
  BASE = 'BASE',
  /**
   * Контакты
   */
  CONTACTS = 'CONTACTS',
}
