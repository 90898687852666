import { FC, useEffect, useState } from 'react';
import QrModal from '../../../../../components/QrModal';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { selectMachineRegistrationCode } from '../../../../../state/machineControl/selectors';
import { getOrganizationId } from '../../../../../helpers/getOrganizationId';
import {
  getMachineRegistrationCodeAction,
  regenerateMachineRegistrationCodeAction,
  toggleActiveMachineRegistrationCodeAction,
} from '../../../../../state/machineControl/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import AlertButton from '../../../../../components/AlertButton';
import styles from './MachineRegistrationModal.module.scss';

/**
 * Свойства компонента MachineRegistrationModal
 */
type MachineRegistrationModalProps = {
  /**
   * Флаг открытия модального окна с ключом добавления автомата
   */
  isOpenKey: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Модальное окно с ключом добавления автомата
 */
const MachineRegistrationModal: FC<MachineRegistrationModalProps> = ({ isOpenKey, onClose }) => {
  const dispatch = useAppDispatch();
  const { state } = useAppSelector(selectMachineRegistrationCode());

  const organizationId = getOrganizationId();

  const { code, isActive } = state || { code: null, isActive: false };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    isOpenKey && organizationId && dispatch(getMachineRegistrationCodeAction(organizationId));
  }, [dispatch, isOpenKey, organizationId]);

  // Обработчики
  const handleBlockKeyClick = () => {
    if (code && organizationId) {
      setIsLoading(true);

      dispatch(toggleActiveMachineRegistrationCodeAction(code, organizationId)).then(() =>
        setIsLoading(false),
      );
    }
  };

  const handleRegenerateKeyClick = () => {
    if (code && organizationId) {
      setIsLoading(true);

      dispatch(regenerateMachineRegistrationCodeAction(code, organizationId)).then(() =>
        setIsLoading(false),
      );
    }
  };

  // render методы
  const renderActions = () => (
    <>
      {isActive ? (
        <AlertButton
          className={styles.Button}
          loading={isLoading}
          label="Заблокировать"
          onClick={handleBlockKeyClick}
        />
      ) : (
        <Button
          className={styles.Button}
          loading={isLoading}
          label="Разблокировать"
          view="secondary"
          onClick={handleBlockKeyClick}
        />
      )}
      <Button
        className={styles.Button}
        loading={isLoading}
        label="Создать новый"
        onClick={handleRegenerateKeyClick}
      />
    </>
  );

  return (
    <QrModal
      isOpen={isOpenKey}
      isActiveKey={isActive}
      code={code}
      modalTitle="Подключить автомат"
      fileName="Ключ регистрации"
      activeDescription="Отсканируйте QR-код сканером автомата или введите ключ авторизации"
      blockDescription="QR-код и ключ авторизации заблокирован"
      noCodeDescription="Кода нет"
      onClose={onClose}
      renderActions={renderActions}
    />
  );
};

export default MachineRegistrationModal;
