import { FC, useState } from 'react';
import VerticalContainer from '../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconAdd } from '@consta/uikit/IconAdd';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import styles from './PromoCodeSchedulesForm.module.scss';
import ScheduleForm from './ScheduleForm';
import { ScheduleByForm } from '../../../types/serverInterface/promoCodeDTO';
import SchedulesListByForm from './SchedulesListByForm';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента PromoCodeSchedulesForm
 */
type PromoCodeSchedulesFormProps = {
  /**
   * Список расписаний
   */
  list: ScheduleByForm[];
  /**
   * Обработчик изменения списка расписаний
   *
   * @param list обновлённые список расписаний
   */
  onSchedulesChange: (list: ScheduleByForm[]) => void;
};

/**
 * Форма расписания промокода
 */
const PromoCodeSchedulesForm: FC<PromoCodeSchedulesFormProps> = ({ list, onSchedulesChange }) => {
  const { t } = useTranslation();

  const [isOpenScheduleForm, setIsOpenScheduleForm] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleByForm | null>(null);

  // Обработчики
  const handleAddSchedulesButtonClick = () => {
    setSelectedSchedule(null);
    setIsOpenScheduleForm(true);
  };

  const handleAddSchedulesModalClose = () => {
    setIsOpenScheduleForm(false);
  };

  const handleAddScheduleChange = (data: ScheduleByForm) => {
    const newList = [...(list || [])];
    const selectedIndex = newList.findIndex((item) => selectedSchedule?.id === item.id);

    if (selectedIndex !== -1) {
      newList.splice(selectedIndex, 1, data);
    } else {
      newList.push(data);
    }

    onSchedulesChange(newList);
    handleAddSchedulesModalClose();
  };

  const handleScheduleClick = (schedule: ScheduleByForm) => {
    setIsOpenScheduleForm(true);
    setSelectedSchedule(schedule);
  };

  const handleDeleteSchedule = (index: number) => {
    const newList = [...(list || [])];

    newList.splice(index, 1);
    onSchedulesChange(newList);
  };

  return (
    <VerticalContainer className={styles.card} space="3xl">
      <Text size="2xl">{t('promoCode.schedules.titles.schedules')}</Text>
      <HorizontalContainer>
        <SchedulesListByForm
          list={list}
          onItemClick={handleScheduleClick}
          onDelete={handleDeleteSchedule}
        />
        <div className={styles.timeAddButton} onClick={handleAddSchedulesButtonClick}>
          <IconAdd {...defaultIconProps} />
          <Text size="l" className={styles.text}>
            {t('promoCode.schedules.titles.schedules.add.button.label')}
          </Text>
        </div>
      </HorizontalContainer>
      <ScheduleForm
        schedule={selectedSchedule}
        isOpen={isOpenScheduleForm}
        onClose={handleAddSchedulesModalClose}
        onSubmit={handleAddScheduleChange}
      />
    </VerticalContainer>
  );
};

export default PromoCodeSchedulesForm;
