import { FC, useEffect, useMemo, useState } from 'react';
import { MachineStoragePageTypeOld } from '../../../../../components/Machine/MachineStorage/MachineStorage';
import MachineStorageScheme from '../../../../../components/Machine/MachineStorage/MachineStorageScheme';
import { MachineHomeCellsDTO } from '../../../../../types/serverInterface/machineDTO';
import { SeparatedMachineStorageHomeType } from '../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../types/serverInterface/storageDTO';

const initialStorage: SeparatedMachineStorageHomeType = {
  cells: {
    [ProductGroup.COFFEE]: [],
    [ProductGroup.CONCENTRATE]: [],
    [ProductGroup.POWDER]: [],
  },
  cellWaters: [],
  cellCups: [],
  cellDisposables: undefined,
};

/**
 * Сортировка групп склада по номеру контейнера
 *
 * @param storage склад
 */
export const getHomeStorageSortedGroup = (storage: SeparatedMachineStorageHomeType) => {
  const arr: Array<ProductGroup> = [];

  for (const key in storage.cells) {
    const minCellNumber = storage.cells[key as ProductGroup]?.length
      ? storage.cells[key as ProductGroup].reduce(
          (acc, item) => (!acc || item.info.cellNumber < acc ? item.info.cellNumber : acc),
          0,
        )
      : null;

    if (minCellNumber !== null) {
      arr[minCellNumber] = key as ProductGroup;
    }
  }

  return arr.splice(1).filter(Boolean);
};

/**
 * Свойства компонента MachineHomaPageScheme
 */
type MachineHomaPageSchemeProps = {
  /**
   * Остатки автомата
   */
  storage: MachineHomeCellsDTO;
};

/**
 * Схема остатков автомата
 */
const MachineHomaPageScheme: FC<MachineHomaPageSchemeProps> = ({ storage }) => {
  const [machineStorageArr, setMachineStorageArr] = useState({
    ...initialStorage,
  });

  const sortedGroup = useMemo(
    () => getHomeStorageSortedGroup(machineStorageArr),
    [machineStorageArr],
  );

  useEffect(() => {
    const separateStorageInfoByGroup = (storageInfoArray: MachineHomeCellsDTO) => {
      const sortedCells = [...storageInfoArray.cells];
      sortedCells.sort((a, b) => a.cellNumber - b.cellNumber);

      const separatedStorageInfo: SeparatedMachineStorageHomeType = JSON.parse(
        JSON.stringify(initialStorage),
      );

      sortedCells.forEach((produceCell) => {
        separatedStorageInfo.cells[produceCell.group]?.push({
          info: produceCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      separatedStorageInfo.cellWaters?.push({
        info: storageInfoArray.cellWaters[0],
        isHoverStatus: false,
        isActiveStatus: false,
      });

      storageInfoArray.cellCups.forEach((cupCell) => {
        separatedStorageInfo.cellCups?.push({
          info: cupCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      setMachineStorageArr(separatedStorageInfo);
    };

    storage && separateStorageInfoByGroup(storage);
  }, [storage]);

  return (
    <MachineStorageScheme
      pageType={MachineStoragePageTypeOld.DETAILS_PAGE}
      storage={machineStorageArr}
      sortedGroup={sortedGroup}
      onClick={() => () => {
        null;
      }}
      onHover={() => () => {
        null;
      }}
      onHoverLeave={() => () => {
        null;
      }}
      onValueChange={() => () => () => {
        null;
      }}
    />
  );
};

export default MachineHomaPageScheme;
