import { FC, MouseEvent, useMemo, useRef } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';
import { getCell, getViewStorageItemClassName } from '../../helpers';
import { MachineStoragePageTypeOld } from '../../MachineStorage';
import { IconLock } from '@consta/uikit/IconLock';
import styles from './StorageSchemeCard.module.scss';
import classNames from 'classnames';
import { SeparatedMachineCellUnionType, SeparatedMachineHomeCellUnionType } from '../../types';
import {
  MachineCellGroup,
  MachineCellGroupUnion,
} from '../../../../../types/serverInterface/machineDTO';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { DisplayType, displayTypeValue } from '../../../../../pages/App';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import { IconBottle } from '../../../../../assets/icon/iconBottle';
import { IconFaucet } from '../../../../../assets/icon/iconFaucet';
import HorizontalContainer from '../../../../HorizontalContainer';

/**
 * Свойства компонента StorageSchemeCard
 */
type StorageSchemeCardProps = {
  /**
   * Тип страницы
   */
  pageType: MachineStoragePageTypeOld;
  cellGroup: MachineCellGroupUnion;
  /**
   * Ячейка
   */
  storageCell: SeparatedMachineCellUnionType | SeparatedMachineHomeCellUnionType;
  /**
   * Флаг горизонтального расположения
   */
  isHorizontal: boolean;
  /**
   * Обработчик нажатия на ячейку
   */
  onClick?: () => void;
  /**
   * Обработчик события hover на ячейке
   */
  onHover?: () => void;
  /**
   * Обработчик события hoverLeave на ячейке
   */
  onHoverLeave?: () => void;
  /**
   * Обработчик пополнения контейнера
   */
  onValueChange?: (value: number | string | null) => void;
};

/**
 * Ячейка в схеме склада автомата
 */
const StorageSchemeCard: FC<StorageSchemeCardProps> = ({
  pageType,
  storageCell,
  cellGroup,
  isHorizontal,
  onClick = () => {
    null;
  },
  onHover = () => {
    null;
  },
  onHoverLeave = () => {
    null;
  },
  onValueChange = () => {
    null;
  },
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const containerRef = useRef<HTMLDivElement>(null);

  const { info, isActiveStatus, isHoverStatus } = storageCell;

  const isSlider = pageType !== MachineStoragePageTypeOld.EDIT;

  const { valuePercent, maxVolume, view, isActive } = useMemo(
    () => getCell(storageCell, cellGroup),
    [storageCell, cellGroup],
  );

  // Обработчики
  const handleSliderClick = (e: MouseEvent<HTMLDivElement>) => {
    if (pageType === MachineStoragePageTypeOld.REFILL) {
      const positionValue = { x: e.pageX, y: e.pageY };

      const container = containerRef.current?.getBoundingClientRect();

      const positionCell = {
        x: container?.x || 0,
        y: container?.y || 0,
        width: container?.width || 0,
        height: container?.height || 0,
      };

      let valuePercent = 0;

      if (positionCell.width === positionCell.height) {
        valuePercent = 0;
      }

      if (positionCell.width < positionCell.height) {
        valuePercent =
          ((positionCell.height - (positionValue.y - positionCell.y)) / positionCell.height) * 100;
      }

      if (positionCell.width > positionCell.height) {
        valuePercent =
          ((positionValue.x - positionCell.x - positionCell.height / 2) / positionCell.width) * 100;
      }

      maxVolume && onValueChange(Math.round((valuePercent * maxVolume) / 100));
    }
  };

  // render методы
  const renderCellRightSide = () => {
    // if (pageType !== MachineStoragePageTypeOld.REFILL) return null;

    if (pageType !== MachineStoragePageTypeOld.REFILL) {
      switch (cellGroup) {
        case MachineCellGroup.WATER:
        case MachineCellGroup.CUP:
        case MachineCellGroup.DISPOSABLE:
          return (
            <HorizontalContainer space="xs" align="center">
              <Text
                weight="semibold"
                size={isLaptop ? 'm' : 'l'}
                className={classNames(styles.text, styles[(storageCell?.info as any)?.view])}
              >
                {getInputNumberValue(info.volume)}
              </Text>
              <Text>{cellGroup === MachineCellGroup.WATER ? 'л' : 'шт'}</Text>
            </HorizontalContainer>
          );
      }
    }

    switch (cellGroup) {
      case MachineCellGroup.WATER:
      case MachineCellGroup.CUP:
      case MachineCellGroup.DISPOSABLE:
        return (
          <HorizontalContainer space="xs" align="center">
            <TextField
              className={styles.rightTextField}
              size={isLaptop ? 'xs' : 's'}
              type="number"
              incrementButtons={false}
              disabled={(storageCell.info as any).isCount === false}
              value={getInputNumberValue(info.volume)}
              onChange={({ value }) => {
                const formatedValue = value && Math.floor(Number(value) * 10) / 10;

                onValueChange(
                  maxVolume
                    ? Number(formatedValue) > maxVolume
                      ? maxVolume
                      : formatedValue
                    : formatedValue,
                );
              }}
            />
            <Text>{cellGroup === MachineCellGroup.WATER ? 'л' : 'шт'}</Text>
          </HorizontalContainer>
        );
    }
  };

  const renderCellInfo = () => {
    switch (cellGroup) {
      case MachineCellGroup.WATER:
        return (
          <div
            className={classNames(
              styles.icon,
              getViewStorageItemClassName(MachineCellGroup.WATER, view),
            )}
          >
            {(storageCell.info as any).isCount ? (
              <IconBottle size={isLaptop ? 's' : 'm'} {...defaultIconProps} />
            ) : (
              <IconFaucet size={isLaptop ? 's' : 'm'} {...defaultIconProps} />
            )}
          </div>
        );
      case MachineCellGroup.CUP: {
        // const storageInfo = storageCell.info as CupCell;

        return (
          <div
            className={classNames(
              styles.icon,
              getViewStorageItemClassName(MachineCellGroup.CUP, view),
            )}
          >
            <IconShakerCup size={isLaptop ? 's' : 'm'} {...defaultIconProps} />
            {/*{storageInfo.cupVolume !== null && (*/}
            {/*  <Text*/}
            {/*    weight="semibold"*/}
            {/*    view="secondary"*/}
            {/*    size="xl"*/}
            {/*    className={classNames(*/}
            {/*      styles.text,*/}
            {/*      getViewStorageItemClassName(MachineCellGroup.CUP, view),*/}
            {/*    )}*/}
            {/*  >*/}
            {/*    {`${storageInfo.cupVolume} ${t('machineControl.storage.cupValue.unit')}`}*/}
            {/*  </Text>*/}
            {/*)}*/}
          </div>
        );
      }
      default:
        return isActive === false ? (
          <div className={classNames(styles.text, getViewStorageItemClassName(null, view))}>
            <IconLock size="s" {...defaultIconProps} />
          </div>
        ) : (
          <Text
            weight="semibold"
            view="secondary"
            size={isLaptop ? 'm' : 'l'}
            className={classNames(styles.text, getViewStorageItemClassName(null, view))}
          >
            {info.cellNumber}
          </Text>
        );
    }
  };

  return (
    <div
      className={classNames(
        styles.storageSchemeCard,
        getViewStorageItemClassName(cellGroup, view),
        isSlider && styles.slider,
        isHorizontal && styles.horizontal,
        isLaptop && styles.laptop,
      )}
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
    >
      {renderCellRightSide()}

      <div
        className={classNames(
          styles.line,
          isHoverStatus && styles.hoverLine,
          isActiveStatus && styles.activeLine,
        )}
        ref={containerRef}
        onClick={handleSliderClick}
      >
        <div className={styles.point} />
        <div
          className={styles.lineSlider}
          style={{
            ['--slider-line-size' as string]: `${valuePercent}%`,
          }}
        />
      </div>
      {renderCellInfo()}
    </div>
  );
};

export default StorageSchemeCard;
