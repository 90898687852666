import { FC, useState } from 'react';

import { Button } from '@consta/uikit/__internal__/src/components/Button';

import { TextField } from '@consta/uikit/TextField';
import styles from './CellCategoryForm.module.scss';

import { Combobox } from '@consta/uikit/Combobox';
import {
  CellCategoryInitEnum,
  CreateCellCategoryDTO,
  EditCellCategoryDTO,
} from '../../../../types/serverInterface/cellCategoryDTO';
import { enumToArray } from '../../../../types/enums';
import { useAppDispatch } from '../../../../app/hooks/store';
import {
  customFormValidation,
  ErrorsCustomFormList,
} from '../../../../components/CustomForm/customFormValidation';
import {
  createCellCategoryAction,
  editCellCategoryAction,
} from '../../../../state/productBase/actions';
import { CustomFormType } from '../../../../types/serverInterface/customForm';
import DefaultModal from '../../../../components/DefaultModal';
import EditCustomForm from '../../../../components/CustomForm';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение формы создания категории контейнера
 */
const createInitialValue: CreateCellCategoryDTO = {
  name: '',
  configMachine: [],
  unit: CellCategoryInitEnum.ML,
};

/**
 * Дополнительные свойства формы создания категории контейнера
 */
type CreateCellCategoryFormProps = {
  /**
   * Тип формы
   */
  type: 'create';
};

/**
 * Дополнительные свойства формы изменения категории контейреа
 */
type EditCellCategoryFormProps = {
  /**
   * Тип формы
   */
  type: 'edit';
  /**
   * Категория контейнера
   */
  cellCategory: EditCellCategoryDTO;
  /**
   * id категории контейнера
   */
  cellCategoryId: number;
};

/**
 * Свойства компонента CellCategoryForm
 */
type CellCategoryFormProps = (CreateCellCategoryFormProps | EditCellCategoryFormProps) & {
  /**
   * Флаг открытия формы
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Единицы измерения состава
 */
const cellCategoryUnits = enumToArray(CellCategoryInitEnum).map((item) => String(item));

/**
 * Форма категории контейнера
 */
const CellCategoryForm: FC<CellCategoryFormProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, type, onClose } = props;

  const isCreate = type === 'create';

  const initialValue = isCreate ? createInitialValue : props.cellCategory;

  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState<ErrorsCustomFormList | null>(null);

  // Обработчики
  function handleSubmit() {
    const isError = handleValidate();

    if (!isError) {
      if (isCreate) {
        dispatch(createCellCategoryAction(formValue)).then(() => {
          onClose();
          setFormValue(createInitialValue);
        });
      }

      if (!isCreate) {
        dispatch(editCellCategoryAction(formValue, props.cellCategoryId)).then(() => {
          onClose();
          setFormValue(createInitialValue);
        });
      }
    }
  }

  const handleChange =
    (key: keyof CreateCellCategoryDTO) =>
    ({ value }: { value: string | null }) => {
      setFormValue((prevState) => ({ ...prevState, [key]: value }));
    };

  // TODO: дублирование кода
  const handleConfigChange = (data: CustomFormType) => (errors: ErrorsCustomFormList | null) => {
    setFormValue((prevState) => ({ ...prevState, configMachine: data }));
    setFormError(errors);
  };

  const handleValidate = (): boolean => {
    const { isError, data } = customFormValidation(formValue.configMachine);

    setFormError(data);

    return isError;
  };

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBaseConfig.cellCategory.form.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button
        label={t('productBaseConfig.cellCategory.form.validate.button.label')}
        view="secondary"
        onClick={(e) => {
          e.preventDefault();
          handleValidate();
        }}
      />
      <Button
        label={
          isCreate
            ? t('productBaseConfig.cellCategory.form.add.button.label')
            : t('productBaseConfig.cellCategory.form.edit.button.label')
        }
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={
        isCreate
          ? t('productBaseConfig.cellCategory.form.create.modal.title')
          : t('productBaseConfig.cellCategory.form.edit.modal.title')
      }
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      <div className={styles.cellCategoryForm}>
        <TextField
          label={t('productBaseConfig.cellCategory.form.name.input.label')}
          name="name"
          width="full"
          value={formValue.name}
          onChange={handleChange('name')}
        />
        <Combobox
          label={t('productBaseConfig.cellCategory.form.unit.select.label')}
          items={cellCategoryUnits}
          value={formValue.unit}
          getItemLabel={(data) => t(`productBase.units.${data}`)}
          getItemKey={(data) => data}
          onChange={handleChange('unit')}
        />
        <EditCustomForm
          config={formValue.configMachine}
          errors={formError}
          onChange={handleConfigChange}
        />
      </div>
    </DefaultModal>
  );
};

export default CellCategoryForm;
