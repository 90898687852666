import React, { FC, useEffect, useState } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  toggleKeyAction,
  generateKeyAction,
  getKeyAction,
  getEmployeeWithKeyAction,
  getEmployeeWithKeyListAction,
} from '../../../../state/organization/actions';
import { selectMemberKey } from '../../../../state/organization/selectors';
import VerticalContainer from '../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './QrKeyModal.module.scss';
import { QRCode } from 'react-qrcode-logo';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import html2canvas from 'html2canvas';
import { IconPrinterStroked } from '../../../../assets/icon/iconPrinterStroked';
import { IconDownload } from '../../../../assets/icon/iconDownload';
import classNames from 'classnames';

type QrKeyModalProps = {
  isOpen: boolean;
  organizationId: number;
  userUuid: string;
  userName: string;
  isActive: boolean;
  onClose: () => void;
};

const QrKeyModal: FC<QrKeyModalProps> = ({
  isOpen,
  organizationId,
  userUuid,
  userName,
  isActive,
  onClose,
}) => {
  const [isPrint, setIsPrint] = useState(false);

  // Путь к изображению в папке public
  const logoImagePath = process.env.PUBLIC_URL + '/img/qr-logo.png';

  const dispatch = useAppDispatch();

  const key = useAppSelector(selectMemberKey());

  useEffect(() => {
    dispatch(getKeyAction(organizationId, userUuid));
  }, [dispatch, organizationId, userUuid]);

  // useEffect(() => {
  //   if (isPrint) {
  //     html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
  //       const imgData = canvas.toDataURL();
  //
  //       // Создать новый элемент img с изображением для печати
  //       const img = new Image();
  //       img.src = imgData;
  //
  //       // Добавить изображение на страницу
  //       document.body.appendChild(img);
  //
  //       // Вызвать окно печати браузера
  //       window.print();
  //
  //       // После печати удалить изображение
  //       img.remove();
  //
  //       setIsPrint(false);
  //     });
  //   }
  // }, [isPrint]);

  // Обработчики
  const handleCancelClick = () => {
    dispatch(toggleKeyAction(key, organizationId)).then(() => {
      dispatch(getEmployeeWithKeyAction(organizationId, userUuid));
      dispatch(getKeyAction(organizationId, userUuid));
    });
  };

  const handlePrimaryClick = () => {
    dispatch(generateKeyAction(organizationId, userUuid)).then(() => {
      dispatch(getEmployeeWithKeyAction(organizationId, userUuid));
      dispatch(getKeyAction(organizationId, userUuid));
      dispatch(getEmployeeWithKeyListAction(organizationId));
    });
  };

  const handleDownload = () => {
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
      const link = document.createElement('a');
      link.download = `${userName}-qr-code.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  // const handlePrint = () => {
  //   html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
  //     const imgData = canvas.toDataURL();
  //
  //     // Создать новый элемент img с изображением для печати
  //     const img = new Image();
  //     img.src = imgData;
  //
  //     // Создать новый элемент для печати
  //     const printWindow = window.open('', '_blank');
  //     if (printWindow) {
  //       printWindow.document.open();
  //       printWindow.document.write('<img src="' + img.src + '" />');
  //       printWindow.document.close();
  //
  //       // Создать новый промис для печати
  //       new Promise<void>(function (resolve) {
  //         resolve();
  //       }).then(function () {
  //         printWindow.print();
  //       });
  //     }
  //   });
  // };

  const handlePrint = () => {
    html2canvas(document.querySelector('#react-qrcode-logo') as any).then(function (canvas) {
      const imgData = canvas.toDataURL();

      // Создать новый элемент img с изображением для печати
      const img = new Image();
      img.onload = function () {
        // Создать новый элемент для печати
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          printWindow.document.open();
          printWindow.document.write('<img src="' + img.src + '" />');

          // Добавить текст после картинки
          printWindow.document.write(
            '<p id="key" style="font-size: 18px; color: #21201f; font-weight: 600; margin-left: 100px">key</p>',
          );

          const element = printWindow.document.getElementById('key');
          if (element) {
            element.innerText = key;

            // Добавить задержку после изменения текста
            setTimeout(() => {
              // В этом блоке вы можете добавить дополнительные действия после задержки, если это необходимо
              console.log('Текст изменен после задержки');
            }, 500); // задержка 500 миллисекунд (0.5 секунды)
          }

          printWindow.document.close();

          // Создать новый промис для печати
          new Promise<void>(function (resolve) {
            resolve();
          }).then(function () {
            printWindow.print();
          });
        }
      };
      img.src = imgData;
    });
  };

  // const handlePrint = () => {
  //   setIsPrint(true);
  // };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={isActive ? 'Заблокировать' : 'Разблокировать'}
        view="clear"
        onClick={handleCancelClick}
      />
      <Button label="Сгенерировать новый" onClick={handlePrimaryClick} />
    </>
  );

  return (
    <DefaultModal
      className={classNames(styles.QrKeyModal, isPrint && styles.isPrint)}
      isOpen={isOpen}
      modalTitle="QR-ключ"
      renderActions={renderActions}
      onClose={onClose}
      isPrint={isPrint}
    >
      <div className={styles.card}>
        <VerticalContainer space="2xl" align="center">
          {/*<QrKeyPrint key={key} userName={userName} />*/}
          <div className={styles.qrContainer}>
            <QRCode
              value={key}
              qrStyle="fluid"
              logoImage={logoImagePath}
              logoPadding={5}
              logoWidth={53}
              logoHeight={53}
              size={250}
            />
          </div>
          <Text size="l" weight="semibold">
            {key}
          </Text>
          {!isPrint && (
            <HorizontalContainer space="2xl" align="center" justify="center">
              <Button
                size="l"
                view="ghost"
                onlyIcon
                iconLeft={IconPrinterStroked as any}
                onClick={handlePrint}
              />
              <Button
                size="l"
                view="ghost"
                onlyIcon
                iconLeft={IconDownload as any}
                onClick={handleDownload}
              />
            </HorizontalContainer>
          )}
        </VerticalContainer>
      </div>
    </DefaultModal>
  );
};

export default QrKeyModal;
