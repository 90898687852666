import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@consta/uikit/TextField';
import { EditOrganizationBasicProps } from '../../../../types/serverInterface/organizationDTO';
import { EditOrganizationInfoProps } from './types';

/**
 * Форма редактирования информации об организации.
 */
const EditOrganizationInfo: FC<EditOrganizationInfoProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const { name, description } = value;

  //Обработчики
  const handleChange =
    (key: keyof EditOrganizationBasicProps) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  return (
    <>
      <TextField
        label={t('organization.basic.edit.organizationName.input.label')}
        name="name"
        width="full"
        size="l"
        placeholder={t('organization.basic.edit.organizationName.input.placeholder')}
        value={name}
        onChange={handleChange('name')}
      />
      <TextField
        label={t('organization.basic.edit.description.input.label')}
        name="description"
        width="full"
        size="l"
        type="textarea"
        rows={3}
        placeholder={t('organization.basic.edit.description.input.placeholder')}
        value={description}
        onChange={handleChange('description')}
      />
    </>
  );
};

export default EditOrganizationInfo;
