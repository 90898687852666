import { FC, useState } from 'react';
import { EditRecipeBasic } from '../../../../../types/serverInterface/recipeDTO';
import { TextField } from '@consta/uikit/TextField';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента RecipeBasicForm
 */
type RecipeBasicFormProps = {
  /**
   * Рецепт
   */
  recipe: EditRecipeBasic;
  /**
   * Обработчик изменений в базовой информации
   *
   * @param key ключ поля
   * @param value значение
   */
  onChange: (key: keyof EditRecipeBasic) => (value: string | null) => void;
};

/**
 * Форма базовой информации рецепта
 */
const RecipeBasicForm: FC<RecipeBasicFormProps> = ({ recipe, onChange }) => {
  const { t } = useTranslation();

  const { name, description, baseVolume } = recipe;

  const [isDescriptionFocus, setIsDescriptionFocus] = useState(false);

  // Обработчики
  const handleDescriptionFocus = () => setIsDescriptionFocus(true);

  const handleDescriptionBlur = () => setIsDescriptionFocus(false);

  const handleChange =
    (key: keyof EditRecipeBasic) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  return (
    <>
      <TextField
        label={t('productBase.recipe.form.basic.name.input.label')}
        width="full"
        value={name}
        onChange={handleChange('name')}
      />
      <TextField
        label={t('productBase.recipe.form.basic.baseVolume.input.label')}
        width="full"
        type="number"
        value={String(baseVolume)}
        onChange={handleChange('baseVolume')}
      />
      <TextField
        label={t('productBase.recipe.form.basic.description.input.label')}
        type="textarea"
        width="full"
        minRows={2}
        maxRows={isDescriptionFocus ? 1000 : 2}
        value={description}
        onFocus={handleDescriptionFocus}
        onBlur={handleDescriptionBlur}
        onChange={handleChange('description')}
      />
    </>
  );
};

export default RecipeBasicForm;
