import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './OrganizationsList.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getOrganizationsListAction } from '../../../state/organization/actions';
import { selectOrganizationsList } from '../../../state/organization/selectors';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrganizationDTO } from '../../../types/serverInterface/organizationDTO';
import { Loader } from '@consta/uikit/Loader';
import { useRoleChecked } from '../../../helpers/roleChecked';
import { Roles } from '../../../types/serverInterface/cabinetDTO';
import { resetSelectOrganization } from '../../../state/organization/slice';
import TableCardWithBackground from '../../../components/TableCardWithBackground';
import HorizontalContainer from '../../../components/HorizontalContainer';
import VerticalContainer from '../../../components/VerticalContainer';
import CreateOrganizationForm from '../CreateOrganizationForm';
import OrganizationBasic from '../OrganizationBasic';
import OrganizationContact from '../OrganizationContact';
import { IconInfo } from '../../../assets/icon/iconInfo';
import { IconPhone } from '../../../assets/icon/iconPhone';

/**
 * Страница списка организаций.
 */
const OrganizationsList: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const organizationsList = useAppSelector(selectOrganizationsList());

  const isAllowedCreate = useRoleChecked([Roles.MANUFACTURER]);

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOrganizationForm, setIsCreateOrganizationForm] = useState(false);
  const [selectedOrganizationBasicId, setSelectedOrganizationBasicId] = useState<number | null>(
    null,
  );
  const [selectedOrganizationContactId, setSelectedOrganizationContactId] = useState<number | null>(
    null,
  );

  const filteredOrganizations = useMemo(() => {
    return organizationsList.filter((org) =>
      org.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [organizationsList, searchQuery]);

  useEffect(() => {
    dispatch(resetSelectOrganization());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getOrganizationsListAction()).finally(() => setIsLoading(false));
  }, [dispatch]);

  // Обработчики
  const handleAddOrganizationButtonClick = () => {
    setIsCreateOrganizationForm(true);
  };

  const handleAddCloseOrganizationForm = () => {
    setIsCreateOrganizationForm(false);
  };

  const handleRowClick = (data: OrganizationDTO) => {
    localStorage.setItem('organizationId', String(data.id));
    navigate(`/organization/members/list`);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleOrganizationBasicClick = (event: React.MouseEvent, id: number) => {
    event.stopPropagation();
    setSelectedOrganizationBasicId(id);
  };

  const handleOrganizationContactClick = (event: React.MouseEvent, id: number) => {
    event.stopPropagation();
    setSelectedOrganizationContactId(id);
  };

  const handleCloseOrganizationBasic = () => {
    setSelectedOrganizationBasicId(null);
  };

  const handleCloseOrganizationContact = () => {
    setSelectedOrganizationContactId(null);
  };

  // Рендер методы
  const renderRowAction = (data: OrganizationDTO) => (
    <HorizontalContainer space="xs" justify="end">
      <Button
        size="m"
        iconLeft={IconInfo as any}
        view="clear"
        onlyIcon
        onClick={(event) => handleOrganizationBasicClick(event, data.id)}
      />
      <Button
        size="m"
        iconLeft={IconPhone as any}
        view="clear"
        onlyIcon
        onClick={(event) => handleOrganizationContactClick(event, data.id)}
      />
    </HorizontalContainer>
  );

  const renderForm = () => (
    <>
      <CreateOrganizationForm
        isOpen={isCreateOrganizationForm}
        onClose={handleAddCloseOrganizationForm}
      />
      <OrganizationBasic
        organizationId={selectedOrganizationBasicId}
        isOpen={!!selectedOrganizationBasicId}
        onClose={handleCloseOrganizationBasic}
      />
      <OrganizationContact
        organizationId={selectedOrganizationContactId}
        isOpen={!!selectedOrganizationContactId}
        onClose={handleCloseOrganizationContact}
      />
    </>
  );

  const renderAction = () => (
    <div className={styles.actions}>
      {isAllowedCreate && (
        <Button
          label={t('organization.list.header.add.button.label')}
          size="m"
          iconLeft={IconAdd as any}
          onClick={handleAddOrganizationButtonClick}
        />
      )}
      <TextField
        width="full"
        size="m"
        placeholder={t('organization.list.header.search.input.placeholder')}
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
  );

  if (isLoading) return <Loader />;

  return (
    <VerticalContainer space="m">
      {renderForm()}
      {renderAction()}
      <TableCardWithBackground
        rows={filteredOrganizations}
        columns={{
          name: {
            key: 'name',
            title: t('organization.list.table.name.column.title'),
            alignment: 'left',
            width: '24%',
            getItemLabel: (data) => data.name,
          },
          description: {
            key: 'description',
            title: t('organization.list.table.description.column.title'),
            alignment: 'left',
            width: '59%',
            getItemLabel: (data) => data.description,
          },
          id: {
            key: 'id',
            title: t('organization.list.table.actions.column.title'),
            alignment: 'right',
            width: '14%',
            renderCell: renderRowAction,
          },
        }}
        onRowClick={handleRowClick}
      />
    </VerticalContainer>
  );
};

export default OrganizationsList;
