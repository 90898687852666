import {
  CreateCellsModel,
  CreateCupModelCell,
  CreateDisposableModelCell,
  CreateProductModelCell,
  CreateWaterModelCell,
} from '../../../../../types/serverInterface/machineModelDTO';
import { CellsModelError } from '../types';

/**
 * Свойства компонента CreateModelCellsForm
 */
export type CreateModelCellsFormProps = {
  /**
   * Значение формы
   */
  value: CreateCellsModel;
  /**
   * Ошибки формы
   */
  errors: CellsModelError;
  /**
   * Обработчки изменения ячейки продукта
   *
   * @param index index ячейки в массиве ячеек продуктов
   * @param key ключ поля
   * @param value новое значение поля
   */
  onProductChange: (
    index: number,
  ) => (key: keyof CreateProductModelCell) => (value: string | number | null) => void;
  /**
   * Обработчки изменения ячейки продукта
   *
   * @param index index ячейки в массиве ячеек продуктов
   * @param key ключ поля
   * @param value новое значение поля
   */
  onAddProductClick: () => void;
  /**
   * Обработчки удаления ячейки продукта
   *
   * @param index index ячейки в массиве ячеек продуктов
   */
  onDeleteProductClick: (index: number) => () => void;
  /**
   * Обработчки копирования ячейки продукта
   *
   * @param index index ячейки в массиве ячеек продуктов
   */
  onCopyProductClick: (index: number) => () => void;
  /**
   * Обработчки изменения ячейки воды
   *
   * @param index index ячейки в массиве ячеек воды
   * @param key ключ поля
   * @param value новое значение поля
   */
  onWaterChange: (
    index: number,
  ) => (key: keyof CreateWaterModelCell) => (value: string | null) => void;
  /**
   * Обработчки изменения ячейки стакана
   *
   * @param index index ячейки в массиве ячеек стакана
   * @param key ключ поля
   * @param value новое значение поля
   */
  onCupChange: (index: number) => (key: keyof CreateCupModelCell) => (value: string | null) => void;
  /**
   * Обработчик добавления ячайки стакана
   */
  onAddCupClick: () => void;
  /**
   * Обработчик удаления ячейки стакана
   *
   * @param index index ячейки в массиве ячеек стаканов
   */
  onDeleteCupClick: (index: number) => () => void;
  /**
   * Обработчик копирования ячейки стакана
   *
   * @param index index ячейки в массиве ячеек стаканов
   */
  onCopyCupClick: (index: number) => () => void;
  /**
   * Обработчки изменения ячейки расходника
   *
   * @param index index ячейки в массиве ячеек расходника
   * @param key ключ поля
   * @param value новое значение поля
   */
  onDisposableChange: (
    index: number,
  ) => (key: keyof CreateDisposableModelCell) => (value: string | null) => void;
  /**
   * Обработчки добавления расходника
   */
  onAddDisposableClick: () => void;
  /**
   * Обработчки удаления ячейки с расходником
   *
   * @param index index ячейки в массиве ячеек расходников
   */
  onDeleteDisposableClick: (index: number) => () => void;
  /**
   * Обработчки копирования ячейки с расходником
   *
   * @param index index ячейки в массиве ячеек расходников
   */
  onCopyDisposableClick: (index: number) => () => void;
};

/**
 * Группы ячеек склада автомата для формы создания модели автомата
 */
export enum CellGroupByCreateModelForm {
  PRODUCT = 'PRODUCT',
  WATER = 'WATER',
  CUP = 'CUP',
  DISPOSABLE = 'DISPOSABLE',
}
