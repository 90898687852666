import { Roles } from '../types/serverInterface/cabinetDTO';
import { useAppSelector } from '../app/hooks/store';
import { selectRole } from '../state/user/selectors';
import { selectLoadingOrganization, selectOrganizationId } from '../state/organization/selectors';

export const useRoleChecked = (needRoles: Roles[], needOrganization?: boolean): boolean => {
  const role = useAppSelector(selectRole());
  const organizationId = useAppSelector(selectOrganizationId());
  const loadingOrganization = useAppSelector(selectLoadingOrganization());

  // TODO: на проде убрать это, когда избавимся от root
  const formattedNeedRoles = [...needRoles, Roles.ROOT];

  const hasRequiredRole = !!formattedNeedRoles.find((roleItem) => roleItem === role);

  const hasOrganizationId = needOrganization ? loadingOrganization || !!organizationId : true;

  return !role || (hasRequiredRole && hasOrganizationId);
};
