import { FC, useEffect, useState } from 'react';
import { OrganizationContactsProps } from './types';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DefaultModal from '../../../components/DefaultModal';
import {
  editOrganizationContactsAction,
  getOrganizationContactsByIdAction,
} from '../../../state/organization/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectOrganizationContacts } from '../../../state/organization/selectors';
import EditOrganizationContact from './EditOrganizationContact';
import OrganizationContactInfo from './OrganizationContactInfo';
import {
  EditContactDTO,
  ContactOrganization,
  EditOrganizationContactDTO,
} from '../../../types/serverInterface/organizationDTO';
import styles from './OrganizationContact.module.scss';
import { Loader } from '@consta/uikit/Loader';

/**
 * Форма для отображения и редактирования контактов организации.
 */
const OrganizationContact: FC<OrganizationContactsProps> = ({
  organizationId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationContacts = useAppSelector(selectOrganizationContacts());

  const [isLoading, setIsLoading] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editValues, setEditValues] = useState<EditContactDTO[]>([]);

  const modalTitle = isEditOpen
    ? t('organization.contacts.edit.modal.title')
    : t('organization.contacts.info.modal.title');

  useEffect(() => {
    if (organizationId) {
      setIsLoading(true);
      dispatch(getOrganizationContactsByIdAction(organizationId)).finally(() =>
        setIsLoading(false),
      );
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (organizationContacts && organizationContacts.length > 0) {
      setEditValues(
        organizationContacts.map((contact: ContactOrganization) => ({
          id: contact.id ?? null,
          name: contact.name,
          contact: contact.contact,
        })),
      );
    }
  }, [organizationContacts]);

  //Обработчики
  const handleContactsChange =
    (index: number) => (key: keyof EditContactDTO) => (value: string | null) => {
      setEditValues((prevState) => {
        const newContacts = [...prevState];
        newContacts[index] = { ...newContacts[index], [key]: value ?? '' };
        return newContacts;
      });
    };

  const handleContactsDelete = (index: number) => {
    setEditValues((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleAddContact = () => {
    setEditValues((prevState) => [...prevState, { id: null, name: '', contact: '' }]);
  };

  const handleCancelButtonClick = () => {
    setIsEditOpen(false);
    if (organizationContacts) {
      setEditValues(
        organizationContacts.map((contact) => ({
          id: contact.id ?? null,
          name: contact.name,
          contact: contact.contact,
        })),
      );
    }
  };

  const handleSaveButtonClick = () => {
    setIsEditOpen(false);
    if (organizationId) {
      const data: EditOrganizationContactDTO = editValues;
      dispatch(editOrganizationContactsAction(data, organizationId));
      onClose();
    }
  };

  const handleClose = () => {
    setIsEditOpen(false);
    onClose();
  };

  const handleEditButtonClick = () => {
    setIsEditOpen(true);
  };

  //render методы
  const renderActions = () => (
    <>
      {isEditOpen ? (
        <>
          <Button
            label={t('organization.contacts.edit.cancel.button.label')}
            view="clear"
            onClick={handleCancelButtonClick}
            size="l"
          />
          <Button
            label={t('organization.contacts.edit.save.button.label')}
            view="primary"
            onClick={handleSaveButtonClick}
            size="l"
          />
        </>
      ) : (
        <Button
          label={t('organization.contacts.info.edit.button.label')}
          view="primary"
          onClick={handleEditButtonClick}
          size="l"
        />
      )}
    </>
  );

  const renderContent = () => {
    if (isLoading) return <Loader />;
    if (isEditOpen) {
      return (
        <EditOrganizationContact
          value={editValues}
          onChange={handleContactsChange}
          onDelete={handleContactsDelete}
          onAdd={handleAddContact}
        />
      );
    }

    return (
      organizationContacts && (
        <OrganizationContactInfo organizationContacts={organizationContacts} />
      )
    );
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={modalTitle}
      onClose={handleClose}
      renderActions={renderActions}
      className={styles.organizationContact}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default OrganizationContact;
