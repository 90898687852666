import { FC } from 'react';
import { SeparatedMachineStorageType } from '../../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../../types/serverInterface/storageDTO';
import { MachineStoragePageTypeOld } from '../../../../../../components/Machine/MachineStorage/MachineStorage';
import MachineStorageScheme from '../../../../../../components/Machine/MachineStorage/MachineStorageScheme';
import styles from './MachineRefillScheme.module.scss';
import { MachineCellGroupUnion } from '../../../../../../types/serverInterface/machineDTO';
import classNames from 'classnames';

/**
 * Свойства компонента MachineFillingInfoScheme
 */
type MachineFillingInfoSchemeProps = {
  /**
   * Внешний className
   */
  className: string;
  /**
   * Склад автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  /**
   * Обработчик hover на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHover: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчки hover leave на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHoverLeave: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчик пополнения контейнера
   */
  onValueChange: (
    group: MachineCellGroupUnion,
  ) => (index: number) => (value: number | string | null) => void;
};

/**
 * Схема остатков страницы информации наполнения
 */
const MachineRefillScheme: FC<MachineFillingInfoSchemeProps> = ({
  className,
  sortedGroup,
  storage,
  onHover,
  onHoverLeave,
  onValueChange,
}) => {
  return (
    <div className={classNames(styles.MachineFillingInfoScheme, className)}>
      <MachineStorageScheme
        pageType={MachineStoragePageTypeOld.REFILL}
        storage={storage}
        sortedGroup={sortedGroup}
        onHover={onHover}
        onHoverLeave={onHoverLeave}
        onClick={() => () => {
          null;
        }}
        onValueChange={onValueChange}
      />
    </div>
  );
};

export default MachineRefillScheme;
