import { FC, LegacyRef } from 'react';
import classNames from 'classnames';
import styles from './MenuItem.module.scss';
import { cnMixFocus } from '@consta/uikit/MixFocus';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../HorizontalContainer';

/**
 * Свойтсва компонента MenuItem
 */
type MenuItemProps = {
  /**
   * ref кнопки
   */
  innerRef?: LegacyRef<HTMLButtonElement> | undefined;
  /**
   * Заголовок
   */
  label: string;
  /**
   * Маленький формат меню
   */
  isSmall?: boolean;
  /**
   * Активный элемент списка меню
   */
  isActive?: boolean;
  /**
   * Иконка слева
   */
  iconLeft?: React.ReactNode;
  /**
   * Иконка справа
   */
  iconRight?: React.ReactNode;
  /**
   * Обработчик нажатия
   */
  onClick?: () => void;
};

/**
 * Элемент меню контекстного меню
 */
const MenuItem: FC<MenuItemProps> = ({
  innerRef,
  isSmall = false,
  iconRight,
  label,
  iconLeft,
  isActive,
  onClick,
}) => {
  return (
    <button
      ref={innerRef}
      className={classNames(
        styles.MenuItem,
        isActive && styles.isActive,
        isSmall && styles.isSmall,
        cnMixFocus,
      )}
      onClick={onClick}
    >
      <HorizontalContainer space="auto" align="center">
        <HorizontalContainer space="s" align="center">
          {iconLeft}
          <Text size="l">{label}</Text>
        </HorizontalContainer>
        {iconRight || <div />}
      </HorizontalContainer>
    </button>
  );
};

export default MenuItem;
