export enum Roles {
  ROOT = 'ROOT',
  DEVELOPER = 'DEVELOPER',
  MANUFACTURER = 'MANUFACTURER',
  ORGANIZATION_OWNER = 'ORGANIZATION_OWNER',
  ORGANIZATION_MEMBER = 'ORGANIZATION_MEMBER',
  TECHNICIAN = 'TECHNICIAN',
}

/**
 * dto личного кабинета
 */
export type CabinetDTO = {
  /**
   * uuid пользователя
   */
  uuid: string;
  /**
   * login пользователя
   */
  login: string;
  /**
   * Имя
   */
  name: string;
  /**
   * Фамилия
   */
  surname: string;
  /**
   * Отчество
   */
  patronymic: string;
  /**
   * Количество файлов пользователя
   */
  countFileLinksUser: number;
  /**
   * Количество файлов сотрудника
   */
  countFileLinksUserOrganization: number;
  role: Roles;
  organizationIds: number[];
  // TODO: добавить список картинок, после исправления Саши
};

/**
 * dto изменения пользователя
 */
export type EditUserDTO = {
  /**
   * Имя
   */
  name: string;
  /**
   * Фамилия
   */
  surname: string;
  /**
   * Отчество
   */
  patronymic: string;
};
