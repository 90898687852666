import { FC } from 'react';
import { RecipeDetailsDTO } from '../../../../types/serverInterface/recipeDTO';
import DefaultModal from '../../../../components/DefaultModal';
import DefaultInfo from '../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../components/DefaultInfoItem';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import Chips from '../../../../components/Chips';
import { RecipeContextMenuItems, recipeInfoStepsList } from '../types';
import ChipsGroup from '../../../../components/Chips/ChipsGroup';
import RecipeIngredientsInfo from './RecipeIngredientsInfo';
import styles from './RecipeInfo.module.scss';
import { useTranslation } from 'react-i18next';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

/**
 * Свойства компонента RecipeInfo
 */
type RecipeInfoProps = {
  /**
   * Рецепт
   */
  recipe: RecipeDetailsDTO;
  /**
   * Выбранный шаг
   */
  selectedStep: RecipeContextMenuItems;
  /**
   * Обработчик выбора шага
   */
  onSelectedStepChange: (step: RecipeContextMenuItems) => void;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик нажатия на кнопку редактирования
   */
  onEditClick: () => void;
};

/**
 * Информация о рецепте
 */
const RecipeInfo: FC<RecipeInfoProps> = ({
  recipe,
  selectedStep,
  onSelectedStepChange,
  onClose,
  onEditClick,
}) => {
  const { t } = useTranslation();

  const { name, nameLocale, description, baseVolume, updated, ingredients } = recipe;
  const recipeName = nameLocale || name;

  // Обработчики
  const handleCLose = () => {
    onClose();
  };

  const handleStepChange = (step: RecipeContextMenuItems) => () => {
    onSelectedStepChange(step);
  };

  const handleEditClick = () => {
    onEditClick();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBase.recipe.info.back.button.label')}
        view="clear"
        onClick={handleCLose}
      />
      <Button label={t('productBase.recipe.info.edit.button.label')} onClick={handleEditClick} />
    </>
  );

  const renderBasicInfo = () => (
    <DefaultInfo>
      <DefaultInfoItem
        item={{ name: t('productBase.recipe.info.defaultInfoItem.name'), value: name }}
      />
      <DefaultInfoItem
        item={{
          name: t('productBase.recipe.info.defaultInfoItem.baseVolume'),
          value: t('productBase.recipe.info.defaultInfoItem.baseVolumeUnit', { baseVolume }),
        }}
      />
      <DefaultInfoItem
        item={{ name: t('productBase.recipe.info.defaultInfoItem.updated'), value: updated }}
      />
      <DefaultInfoItem
        isBig
        item={{
          name: t('productBase.recipe.info.defaultInfoItem.description'),
          value: description,
        }}
      />
    </DefaultInfo>
  );

  const renderIngredientsInfo = () => <RecipeIngredientsInfo ingredients={ingredients} />;

  const renderInfo = () => {
    switch (selectedStep) {
      case RecipeContextMenuItems.INFO:
        return renderBasicInfo();
      case RecipeContextMenuItems.INGREDIENTS:
        return renderIngredientsInfo();
      default:
        return <Text>noContent</Text>;
    }
  };

  const renderChips = () => (
    <ChipsGroup>
      {recipeInfoStepsList.map((name, index) => (
        <Chips
          key={index}
          label={t(`productBase.recipe.info.chips.${name}`)}
          name={name}
          isSelect={name === selectedStep}
          onClick={handleStepChange(name)}
        />
      ))}
    </ChipsGroup>
  );

  return (
    <DefaultModal
      className={styles.recipeInfo}
      isOpen
      modalTitle={t('productBase.recipe.info.modal.title', { recipeName })}
      onClose={handleCLose}
      renderActions={renderActions}
    >
      {renderChips()}
      {renderInfo()}
    </DefaultModal>
  );
};

export default RecipeInfo;
