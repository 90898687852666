import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import OutletList from './OutletList';
import PrivateRolesPage from '../RolesPage/PrivateRolesPage';
import { Roles } from '../../types/serverInterface/cabinetDTO';

const OutletPage: FC = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRolesPage
            needRoles={[Roles.ORGANIZATION_MEMBER, Roles.ORGANIZATION_OWNER, Roles.MANUFACTURER]}
            needOrganization
          />
        }
      >
        <Route path="/list" element={<OutletList />} />
      </Route>
    </Routes>
  );
};

export default OutletPage;
