import { Column } from '../../types';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import { useRef, useState } from 'react';
import ContextModal from '../../../ContextModal';
import styles from './FilterButton.module.scss';
import classNames from 'classnames';

type FilterButtonProps<T extends Record<string, any>, K extends keyof T> = {
  column: Column<T, K>;
  isActive: boolean | undefined;
  className?: string;
};

const FilterButton = <T extends Record<string, any>, K extends keyof T>({
  isActive,
  column,
  className,
}: FilterButtonProps<T, K>) => {
  const [isOpenContextModal, setIsOpenContextModal] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Обработчики
  const handlePageInfoTextClick = () => {
    setIsOpenContextModal((prevState) => !prevState);
  };

  // render методы
  const renderFilterModal = () => (
    <ContextModal className={className} currentRef={buttonRef} isOpen={isOpenContextModal}>
      {column.withFilter && column.renderFilter()}
    </ContextModal>
  );

  if (!column.withFilter) return null;

  return (
    <>
      <Button
        size="s"
        className={classNames(
          styles.FilterButton,
          isActive && styles.isActive,
          isOpenContextModal && styles.isOpen,
        )}
        ref={buttonRef}
        onlyIcon
        view="clear"
        iconLeft={IconFunnel as any}
        onClick={handlePageInfoTextClick}
      />
      {renderFilterModal()}
    </>
  );
};

export default FilterButton;
