import { FC, useState } from 'react';
import {
  AccessEmployeeWithKeyElementDTO,
  MachineAccessEmployeeWithKeyElementDTO,
} from '../../../../../types/serverInterface/employeeWithKeyElementDTO';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MemberKeyAccess.module.scss';
import { DisplayType, displayTypeValue } from '../../../../App';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Switch } from '@consta/uikit/Switch';
import { useAppDispatch } from '../../../../../app/hooks/store';
import {
  getEmployeeWithKeyAction,
  toggleAllMachineAccessKeyAction,
  toggleMachineAccessKeyAction,
} from '../../../../../state/organization/actions';

type MemberKeyAccessProps = {
  access: AccessEmployeeWithKeyElementDTO;
  organizationId: number;
  userUuid: string;
};

const MemberKeyAccess: FC<MemberKeyAccessProps> = ({ access, userUuid, organizationId }) => {
  const dispatch = useAppDispatch();

  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const [isLoading, setIsLoading] = useState(false);

  // Обработчики
  const handleAccessMachineAllToggle = () => {
    setIsLoading(true);
    dispatch(toggleAllMachineAccessKeyAction(organizationId, userUuid)).finally(() => {
      dispatch(getEmployeeWithKeyAction(organizationId, userUuid)).finally(() => {
        setIsLoading(false);
      });
    });
  };

  const handleAccessMachineToggle = (userOrganizationId: number, serialNumber: string) => () => {
    setIsLoading(true);
    dispatch(toggleMachineAccessKeyAction(userOrganizationId, serialNumber)).finally(() => {
      dispatch(getEmployeeWithKeyAction(organizationId, userUuid)).finally(() => {
        setIsLoading(false);
      });
    });
  };

  // render методы
  const renderHistoryItem = ({
    isAccess,
    name,
    serialNumber,
    userOrganizationId,
    address,
  }: MachineAccessEmployeeWithKeyElementDTO) => {
    return (
      <HorizontalContainer className={styles.memberKeyCard} isAutoSpace align="center">
        <HorizontalContainer space={isLaptop ? 's' : 'm'} align="center">
          <VerticalContainer space="3xs">
            <Text className={styles.text} size={isLaptop ? 'm' : 'l'} weight="semibold">
              {name}
            </Text>
            <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
              {address}
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <Switch
          checked={isAccess}
          disabled={isLoading}
          onClick={handleAccessMachineToggle(userOrganizationId, serialNumber)}
        />
      </HorizontalContainer>
    );
  };

  return (
    <ContentCard className={styles.MemberKeyAccess}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        Доступ
      </Text>
      <HorizontalContainer className={styles.memberKeyCard} isAutoSpace align="center">
        <HorizontalContainer space={isLaptop ? 's' : 'm'} align="center">
          <VerticalContainer space="3xs">
            <Text className={styles.text} size={isLaptop ? 'm' : 'l'} weight="semibold">
              Все автоматы
            </Text>
            <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
              Доступ ко всем автоматам в организации
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <Switch
          checked={access.isAllMachine}
          disabled={isLoading}
          onClick={handleAccessMachineAllToggle}
        />
      </HorizontalContainer>
      {access.machines.map(renderHistoryItem)}
    </ContentCard>
  );
};

export default MemberKeyAccess;
