import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectProductLineListByBrandId } from '../../../state/productBase/selectors';
import { getProductLineListAction } from '../../../state/productBase/actions';
import styles from './ProductLineList.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import ProductLineItem from './ProductLineItem';
import ProductLineForm from './ProductLineForm';
import { useTranslation } from 'react-i18next';
import { selectRole } from '../../../state/user/selectors';
import { Roles } from '../../../types/serverInterface/cabinetDTO';

/**
 * Свойства компонента ProductLineList
 */
type ProductLineListProps = {
  /**
   * id бренда
   */
  brandId: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Возможность пользователя редактировать глобальные сущности
   */
  isAllowedGlobalEdit: boolean;
};

/**
 * Список линеек продуктов внутри бренда
 *
 * @param brandId id бренда
 * @param organizationId id организации
 */
const ProductLineList: FC<ProductLineListProps> = ({
  brandId,
  organizationId,
  isAllowedGlobalEdit,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const productLineList = useAppSelector(selectProductLineListByBrandId(brandId));
  const role = useAppSelector(selectRole());
  const isShowEdit = role === Roles.ROOT || role === Roles.MANUFACTURER || role === Roles.DEVELOPER;

  const [isProductLineCreateModalOpen, setIsProductLineModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getProductLineListAction(brandId, { organizationId }));
  }, [brandId, organizationId, dispatch]);

  // Обработчики
  const handleAddProductLineButtonClick = () => {
    setIsProductLineModalOpen(true);
  };

  const handleAddProductLineModalClose = () => {
    setIsProductLineModalOpen(false);
  };

  // render методы
  const renderAddProductLineForm = () => (
    <ProductLineForm
      type="create"
      brandId={brandId}
      isOpen={isProductLineCreateModalOpen}
      onClose={handleAddProductLineModalClose}
    />
  );

  const renderProductLineList = () =>
    productLineList?.map((productLine) => (
      <ProductLineItem
        key={productLine.id}
        brandId={brandId}
        productLine={productLine}
        isAllowedGlobalEdit={isAllowedGlobalEdit}
      />
    ));

  return (
    <div className={styles.productLineList}>
      {isShowEdit && (
        <Button
          className={styles.addButton}
          label={t('productBase.productLine.add.button.label')}
          view="ghost"
          iconLeft={IconAdd as any}
          onClick={handleAddProductLineButtonClick}
        />
      )}
      {renderProductLineList()}
      {renderAddProductLineForm()}
    </div>
  );
};

export default ProductLineList;
