import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconCalendarSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      d="M4 1V2.5M8 1V2.5M1.75 4.545H10.25M7.84735 6.85001H7.85184M7.84735 8.35001H7.85184M5.99774 6.85001H6.00223M5.99774 8.35001H6.00223M4.14716 6.85001H4.15165M4.14716 8.35001H4.15165M10.5 4.25V8.5C10.5 10 9.75 11 8 11H4C2.25 11 1.5 10 1.5 8.5V4.25C1.5 2.75 2.25 1.75 4 1.75H8C9.75 1.75 10.5 2.75 10.5 4.25Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconCalendarSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_31_43)">
      <path
        d="M4.88889 0V2.25M11.1111 0V2.25M1.38889 5.3175H14.6111M10.8737 8.77501H10.8806M10.8737 11.025H10.8806M7.99649 8.77501H8.00347M7.99649 11.025H8.00347M5.1178 8.77501H5.12478M5.1178 11.025H5.12478M15 4.875V11.25C15 13.5 13.8333 15 11.1111 15H4.88889C2.16667 15 1 13.5 1 11.25V4.875C1 2.625 2.16667 1.125 4.88889 1.125H11.1111C13.8333 1.125 15 2.625 15 4.875Z"
        stroke="var(--icon-stroke-color)"
        fill="var(--icon-fill-color)"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_31_43">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

const IconCalendarSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M8 2V5M16 2V5M3.5 9.09H20.5M15.6947 13.7H15.7037M15.6947 16.7H15.7037M11.9955 13.7H12.0045M11.9955 16.7H12.0045M8.29431 13.7H8.30329M8.29431 16.7H8.30329M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconCalendar = createIcon({
  name: 'IconCalendar',
  xs: IconCalendarSizeXS,
  s: IconCalendarSizeS,
  m: IconCalendarSizeM,
});
