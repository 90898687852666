import { FC, useMemo } from 'react';
import { IngredientInfo } from '../../../../../types/serverInterface/recipeDTO';
import RecipeIngredientsStepInfo from './RecipeIngredientsStepInfo';

/**
 * Свойства компонента RecipeIngredientsInfo
 */
type RecipeIngredientsInfoProps = {
  /**
   * Список ингредиентов
   */
  ingredients: IngredientInfo[];
};

/**
 * Информация о списке ингредиентов рецепта
 */
const RecipeIngredientsInfo: FC<RecipeIngredientsInfoProps> = ({ ingredients }) => {
  const transformedIngredients: IngredientInfo[][] = useMemo(() => {
    const arr: IngredientInfo[][] = [];

    ingredients.forEach((item) => {
      const index = item.actionSerialNumber;
      if (!arr[index]) {
        arr[index] = [];
      }
      arr[index].push(item);
    });

    return arr;
  }, [JSON.stringify(ingredients)]);

  // render методы
  const renderInfoItem = (ingredient: IngredientInfo[], index: number) => (
    <RecipeIngredientsStepInfo key={index} ingredients={ingredient} index={index} />
  );

  return <>{transformedIngredients.map(renderInfoItem)}</>;
};

export default RecipeIngredientsInfo;
