import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import PromoCodeList from './PromoCodeList';
import CreatePromoCode from './CreatePromoCode';
import PromoCodeInfo from './PromoCodeInfo';
import EditPromoCode from './EditPromoCode';

/**
 * Базовая страница промокодов
 */
const PromoCodePage: FC = () => {
  return (
    <Routes>
      <Route path="/list" element={<PromoCodeList />} />
      <Route path="/create" element={<CreatePromoCode />} />
      <Route path="/edit/:promoCodeId" element={<EditPromoCode />} />
      <Route path="/info/:promoCodeId" element={<PromoCodeInfo />} />
    </Routes>
  );
};

export default PromoCodePage;
