import { FC, useEffect, useState } from 'react';
import NameDescriptionLocale from '../../../../components/locale/NameDescriptionLocale';
import { LocaleNameDescriptionList } from '../../../../components/locale/NameDescriptionLocale/types';
import { Loader } from '@consta/uikit/Loader';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectProductLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getProductListAction,
  getProductLocaleByIdAction,
} from '../../../../state/productBase/actions';
import NoContent from '../../../../components/NoContent';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ProductLocale
 */
type ProductLocaleProps = {
  /**
   * Флаг открытия локализации продукта
   */
  isOpen: boolean;
  /**
   * id линейки продукта
   */
  productLineId: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * id продукта
   */
  productId: number;
  /**
   * Название продукта
   */
  productName: string;
  /**
   * Обработчик закрытия локализации продукта
   */
  onClose: () => void;
};

/**
 * Компонент локализации продукта
 */
const ProductLocale: FC<ProductLocaleProps> = ({
  isOpen,
  productLineId,
  organizationId,
  productId,
  productName,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state: productLocale, isLoading, isReject } = useAppSelector(selectProductLocale());

  const [isLocaleEdit, setIsLocaleEdit] = useState(false);

  useEffect(() => {
    dispatch(getProductLocaleByIdAction(productId));
  }, [dispatch, productId]);

  // Обработчики
  const handleSubmit = (data: LocaleNameDescriptionList) => {
    productLocale &&
      dispatch(
        editLocaleAction({
          ...productLocale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
            {
              entityFieldName: FieldNames.DESCRIPTION,
              localeValues: data.map((value) => ({
                value: value.description,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() => dispatch(getProductLocaleByIdAction(productId)).then(() => handleEditClose()));
  };

  const handleEditClick = () => {
    setIsLocaleEdit(true);
  };

  const handleEditClose = () => {
    setIsLocaleEdit(false);
  };

  const handleClose = () => {
    dispatch(getProductListAction(productLineId, { organizationId }));
    onClose();
  };

  // render методы
  const renderLocale = () => (
    <NameDescriptionLocale
      isOpen={isOpen}
      isTransformList={false}
      locale={productLocale}
      isEdit={isLocaleEdit}
      modalTitle={t('productBase.product.locale.modal.title', { productName })}
      onSubmit={handleSubmit}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onClose={handleClose}
    />
  );

  const renderContent = () => {
    if (isLoading) return <Loader />;
    if (isReject) return <>Reject</>;
    if (!productLocale) return <NoContent />;
    return renderLocale();
  };

  if (!productLocale) return <Loader />;

  return renderContent();
};

export default ProductLocale;
