import { FC } from 'react';
import {
  ProductComponent,
  ProductDetailsDTO,
} from '../../../../../types/serverInterface/productDTO';
import DefaultInfo from '../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ProductComponentsInfo
 */
type ProductComponentsInfoProps = {
  /**
   * Продукт
   */
  product: ProductDetailsDTO;
};

/**
 * Информация о составе продукта
 */
const ProductComponentsInfo: FC<ProductComponentsInfoProps> = ({ product }) => {
  const { t } = useTranslation();
  const { components } = product;

  // render методы
  const renderComponent = (component: ProductComponent) => (
    <DefaultInfoItem
      item={{
        name: component.name,
        value: `${component.qty} ${t(`productBase.units.${component.unit}`)}`,
      }}
    />
  );

  return <DefaultInfo>{components.map(renderComponent)}</DefaultInfo>;
};

export default ProductComponentsInfo;
