import { LanguageEnum } from './locales';

/**
 * Названия полей
 */
export enum FieldNames {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
}

/**
 *
 */
export enum EntityNames {
  BRAND = 'BRAND',
}

/**
 * Значение локализации
 */
export type LocaleValue = {
  /**
   * Выбранный язык
   */
  language: LanguageEnum;
  /**
   * Значение
   */
  value: string | null;
};

/**
 * Поле
 */
export type Field = {
  /**
   * Название поля
   */
  entityFieldName: FieldNames;
  /**
   * Значения локализации для поля
   */
  localeValues: LocaleValue[];
};

/**
 * dto локализации
 */
export type LocaleDTO = {
  /**
   * Ключ локализации
   */
  localeKey: string;
  /**
   * Название сущности
   */
  entityName: EntityNames;
  fields: Field[];
};
