import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { checkToken, getAccessTokenPKCEThunk } from '../../../state/auth';
import { useAppDispatch } from '../../../app/hooks/store';

/**
 * Страница для получения токена после авторизации
 */
const Authenticated: FC = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if ('code' in parsed) {
      const code = parsed.code as string;

      dispatch(getAccessTokenPKCEThunk(code)).then(() => dispatch(checkToken()));
    }
  }, [dispatch, parsed]);

  return <div>redirect</div>;
};

export default Authenticated;
