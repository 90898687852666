import { FC, useEffect, useState } from 'react';
import styles from './ScheduleForm.module.scss';
import DefaultModal from '../../../../components/DefaultModal';
import VerticalContainer from '../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { enumToArray } from '../../../../types/enums';
import { ScheduleByForm, WeekdayEnum } from '../../../../types/serverInterface/promoCodeDTO';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { schedulePromoCodeInitialError } from './helpers';
import {
  initialFieldError,
  requiredFieldError,
  requiredValidate,
} from '../../../../helpers/validateHelpers';
import DatePickerWithTooltip from '../../../../components/withTooltip/DatePicker';
import TextFieldWithTooltip from '../../../../components/withTooltip/TextField';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

/**
 * Свойства компонента AddScheduleForm
 */
type ScheduleFormProps = {
  /**
   * Флаг открытия формы
   */
  isOpen: boolean;
  /**
   * Элемент расписания
   */
  schedule: ScheduleByForm | null;
  /**
   * Обработчик закрытия формы
   */
  onClose: () => void;
  /**
   * Отправка формы
   */
  onSubmit: (data: ScheduleByForm) => void;
};

/**
 * Список дней недели
 */
const weekDayList = enumToArray(WeekdayEnum);

/**
 * Дефолтное значение формы
 */
const initialFormValue: ScheduleByForm = {
  from: null,
  to: null,
  weekday: {
    [WeekdayEnum.MO]: false,
    [WeekdayEnum.TU]: false,
    [WeekdayEnum.WE]: false,
    [WeekdayEnum.TH]: false,
    [WeekdayEnum.FR]: false,
    [WeekdayEnum.SA]: false,
    [WeekdayEnum.SU]: false,
  },
  id: null,
  name: null,
};

/**
 * Форма добавления расписания
 */
const ScheduleForm: FC<ScheduleFormProps> = ({ isOpen, schedule, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState(schedule || initialFormValue);
  const [formErrors, setFormErrors] = useState(schedulePromoCodeInitialError);

  useEffect(() => {
    schedule && setFormState(schedule);
    !schedule && setFormState(initialFormValue);
    setFormErrors(schedulePromoCodeInitialError);
  }, [schedule]);

  // Вспомогательные методы
  const validateForm = () => {
    let isValid = true;

    const setIsValid = (value: boolean) => {
      isValid = isValid ? value : isValid;
    };

    const { from, to, weekday, name } = formState;
    const hasWeekdaySelected = weekDayList.reduce((acc, item) => acc || weekday[item], false);
    setIsValid(hasWeekdaySelected);

    const weekDayError = hasWeekdaySelected
      ? initialFieldError
      : {
          ...requiredFieldError,
          label: 'promoCode.schedules.form.weekdays.required.error.text',
        };

    setFormErrors({
      ...schedulePromoCodeInitialError,
      from: requiredValidate(from, setIsValid),
      to: requiredValidate(to, setIsValid),
      name: requiredValidate(name, setIsValid),
      weekday: weekDayError,
    });

    return { isValid };
  };

  // Обработчики
  const handleChange =
    (key: keyof ScheduleByForm) =>
    ({ value }: { value: string | null }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleFromChange = ({ value }: { value: Date | null }) => {
    setFormState((prevState) => ({
      ...prevState,
      from: value,
      to: value && formState.to && value < formState.to ? formState.to : value,
    }));
  };

  const handleToChange = ({ value }: { value: Date | null }) => {
    setFormState((prevState) => ({
      ...prevState,
      from: value && formState.from && value > formState.from ? formState.from : value,
      to: value,
    }));
  };

  const handleWeekDayChange = (day: WeekdayEnum) => () => {
    setFormState((prevState) => ({
      ...prevState,
      weekday: { ...prevState.weekday, [day]: !prevState.weekday[day] },
    }));
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handlePrimaryClick = () => {
    validateForm().isValid && onSubmit(formState);
  };

  // render методы
  const renderWeekDayItem = (day: WeekdayEnum) => (
    <div
      className={classNames(styles.weekDayItem, formState.weekday[day] && styles.selected)}
      onClick={handleWeekDayChange(day)}
    >
      {t(`weekDay.small.${day}`)}
    </div>
  );

  const renderActions = () => (
    <>
      <Button
        label={t('promoCode.schedules.form.actions.back.button.label')}
        view="clear"
        onClick={handleCancelClick}
      />
      <Button
        label={
          schedule
            ? t('promoCode.schedules.form.actions.edit.button.label')
            : t('promoCode.schedules.form.actions.add.button.label')
        }
        onClick={handlePrimaryClick}
      />
    </>
  );

  return (
    <DefaultModal
      isOpen={isOpen}
      position="center"
      modalTitle={
        schedule
          ? t('promoCode.schedules.form.edit.modal.title')
          : t('promoCode.schedules.form.create.modal.title')
      }
      renderActions={renderActions}
      onClose={handleCancelClick}
    >
      <VerticalContainer className={styles.AddScheduleForm}>
        <VerticalContainer className={styles.card} space="m">
          <Text size="l" weight="semibold">
            {t('promoCode.schedules.form.name.label')}
          </Text>
          <TextFieldWithTooltip
            tooltipProps={{
              content: formErrors.name.label
                ? t(formErrors.name.label, formErrors.name.optionsObj)
                : t('promoCode.schedules.form.name.tooltip.text'),
            }}
            status={formErrors.name.status}
            placeholder={t('promoCode.schedules.form.name.textField.placeholder')}
            value={formState.name}
            onChange={handleChange('name')}
          />
        </VerticalContainer>
        <VerticalContainer className={styles.card} space="m">
          <Text size="l" weight="semibold">
            {t('promoCode.schedules.form.periodUse.label')}
          </Text>
          <HorizontalContainer space="s">
            <DatePickerWithTooltip
              tooltipProps={{
                content: formErrors.from.label
                  ? t(formErrors.from.label, formErrors.from.optionsObj)
                  : t('promoCode.schedules.form.from.tooltip.text'),
              }}
              status={formErrors.from.status}
              className={styles.timeField}
              value={formState.from}
              type="time"
              leftSide={t('promoCode.schedules.form.from.datePicker.leftSide')}
              multiplicitySeconds={0}
              multiplicityMinutes={1}
              placeholder={t('promoCode.schedules.form.from.datePicker.placeholder')}
              onChange={handleFromChange}
            />
            <DatePickerWithTooltip
              tooltipProps={{
                content: formErrors.to.label
                  ? t(formErrors.to.label, formErrors.to.optionsObj)
                  : t('promoCode.schedules.form.to.tooltip.text'),
              }}
              status={formErrors.name.status}
              className={styles.timeField}
              value={formState.to}
              type="time"
              leftSide={t('promoCode.schedules.form.to.datePicker.leftSide')}
              multiplicitySeconds={0}
              multiplicityMinutes={1}
              placeholder={t('promoCode.schedules.form.to.datePicker.placeholder')}
              onChange={handleToChange}
            />
          </HorizontalContainer>
        </VerticalContainer>
        <VerticalContainer className={styles.card} space="m">
          <Text size="l" weight="semibold">
            {t('promoCode.schedules.form.weekdays.label')}
          </Text>
          <HorizontalContainer space="auto">
            {weekDayList.map(renderWeekDayItem)}
          </HorizontalContainer>
          {formErrors.weekday.isError && (
            <Text size="m" view="alert">
              {t(formErrors.weekday.label || '')}
            </Text>
          )}
        </VerticalContainer>
      </VerticalContainer>
    </DefaultModal>
  );
};

export default ScheduleForm;
