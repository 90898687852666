import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectOutletFilters, selectOutletList } from '../../../state/machineControl/selectors';
import { useEffect, useMemo, useState } from 'react';
import {
  getOutletListAction,
  getOutletListFiltersAction,
} from '../../../state/machineControl/actions';
import { useMachineList } from '../../MachineControlPage/MachineList/hook';
import {
  MachineListFiltersModelInfo,
  MachineListItem,
} from '../../../types/serverInterface/machineListDTO';

/**
 * Хук для работы со списком торговых точек
 *
 * @param organizationId id организации
 */
export const useOutletList = (organizationId: number | null) => {
  const dispatch = useAppDispatch();

  const { state: outletListState } = useAppSelector(selectOutletList());
  const { state: filtersState } = useAppSelector(selectOutletFilters());

  const { machineList, filters, setFilters } = useMachineList(organizationId);

  const [searchQuery, setSearchQuery] = useState('');

  const salesFilter = filters.salesFilter;

  const machineOutletMap = useMemo(() => {
    const map: Record<number, MachineListItem[]> = {};

    machineList?.forEach((machine) => {
      if (machine.outletId) {
        map[machine.outletId] = map[machine.outletId] || [];
        map[machine.outletId].push(machine);
      }
    });

    return map;
  }, [machineList]);

  const filterOutletList = useMemo(() => {
    return (
      (outletListState &&
        outletListState.outlets?.filter(
          ({ name }) => name && name.toLowerCase().includes(searchQuery.toLowerCase()),
        )) ||
      []
    );
  }, [searchQuery, outletListState]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOutletListAction(organizationId, { salesFilter }));
    }

    // Это поведение верное. Таймер нужен для регулярного обновления списка автоматов
    // Это требование бизнеса. В дальнейшем переделается на push уведомления или на websocket
    const interval = setInterval(() => {
      if (organizationId) {
        dispatch(getOutletListAction(organizationId, { salesFilter }));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, organizationId, salesFilter]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOutletListFiltersAction(organizationId));
    }

    // Это поведение верное. Таймер нужен для регулярного обновления списка автоматов
    // Это требование бизнеса. В дальнейшем переделается на push уведомления или на websocket
    const interval = setInterval(() => {
      if (organizationId) {
        dispatch(getOutletListFiltersAction(organizationId));
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, organizationId]);

  return {
    outletList: outletListState && outletListState.outlets,
    filterOutletList,
    machineOutletMap,
    machineStatusesCount: filtersState?.machineStatusQty || {
      error: 0,
      success: 0,
      warning: 0,
    },
    machineAtModelsCount: filtersState?.models.map(
      ({ id, qty, name }): MachineListFiltersModelInfo => ({
        modelId: id,
        modelName: name,
        modelCount: qty,
      }),
    ),
    filters,
    searchQuery,
    setFilters,
    setSearchQuery,
  };
};
