import React, { FC } from 'react';
import Notification from './Notification';
import styles from './Notification.module.scss';
import { useAppSelector } from '../../app/hooks/store';
import { selectNotificationsList } from '../../state/user/selectors';

/**
 * Компонент списка уведомлений
 */
const NotificationsList: FC = () => {
  const notificationsList = useAppSelector(selectNotificationsList());

  return (
    <div className={styles.list}>
      {notificationsList.map((notification, index) => (
        <Notification key={index} notification={notification} />
      ))}
    </div>
  );
};

export default NotificationsList;
