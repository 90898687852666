import React, { FC, useState } from 'react';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './DeleteModal.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { useTranslation } from 'react-i18next';
import { TextField } from '@consta/uikit/TextField';
import { IconTrash } from '../../assets/icon/iconTrash';

/**
 * Свойства компонента DeleteModal
 */
type DeleteModalProps = {
  /**
   * Текст primary кнопки
   */
  primaryButtonLabel?: string;
  /**
   * Флаг открытия модального окна
   */
  isOpen: boolean;
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Заголовок модального окна (требуется локализованное название)
   */
  title: string;
  /**
   * Описание модального окна (требуется локализованное описание)
   */
  description: string | string[];
  /**
   * Флаг наличия подтверждения удаления (для важных удалений)
   */
  withConfirmDeletion: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

/**
 * Модальное окно подтверждения удаления
 */
const DeleteModal: FC<DeleteModalProps> = ({
  primaryButtonLabel,
  isOpen,
  isDeleteLoading,
  title,
  description,
  withConfirmDeletion,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState<string>('');

  // Обработчики
  const handleCloseButtonClick = () => onClose();

  const handleCancelButtonClick = () => onClose();

  const handleDeleteClick = () => onDelete();

  const handleClickOutside = () => onClose();

  const handleEscClick = () => onClose();

  const handleConfirmChange = ({ value }: { value: string | null }) => {
    setConfirm(value || '');
  };

  // render методы
  const renderSingleDescription = (description: string) => <Text size="l">{description}</Text>;

  const renderMultiDescription = (descriptionList: string[]) =>
    descriptionList.map((description, index) => (
      <Text size="l" key={index}>
        {description}
      </Text>
    ));

  const renderDescription = () => {
    if (typeof description === 'string') {
      return renderSingleDescription(description);
    } else if (Array.isArray(description)) {
      return renderMultiDescription(description);
    }

    return null;
  };

  return (
    <Modal
      className={styles.deleteModal}
      onClickOutside={handleClickOutside}
      onEsc={handleEscClick}
      isOpen={isOpen}
    >
      <div className={styles.header}>
        <Text size="2xl">{title}</Text>
        <Button
          onlyIcon
          view="clear"
          iconLeft={IconClose as any}
          onClick={handleCloseButtonClick}
        />
      </div>

      <div className={styles.content}>
        {renderDescription()}
        {withConfirmDeletion && (
          <div className={styles.confirmDeletion}>
            <Text size="l">{t('deleteModal.confirmDeletion.text.title')}</Text>
            <Text size="l">{t('deleteModal.confirmDeletion.text.description')}</Text>
            <TextField view="default" width="full" value={confirm} onChange={handleConfirmChange} />
          </div>
        )}
      </div>

      <div className={styles.actions}>
        <Button
          label={t('deleteModal.cancel.button.label')}
          view="clear"
          size="l"
          onClick={handleCancelButtonClick}
        />
        <Button
          className={styles.deleteButton}
          label={primaryButtonLabel || t('deleteModal.delete.button.label')}
          iconLeft={IconTrash as any}
          size="l"
          loading={isDeleteLoading}
          disabled={
            withConfirmDeletion && confirm !== t('deleteModal.confirmDeletion.text.description')
          }
          onClick={handleDeleteClick}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;
