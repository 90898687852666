import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconBottleSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M9.62416 5.50237C9.69367 5.50237 9.75 5.44931 9.75 5.38389V4.72038C9.75 4.70199 9.74544 4.68384 9.73671 4.66739C9.39269 4.01953 8.7593 3.52403 7.90505 3.23448C7.43668 3.07571 7.02574 3.01924 6.83056 3V2.37441H7.00674C7.24266 2.37441 7.43459 2.1937 7.43459 1.97156V1.40284C7.43459 1.18071 7.24266 1 7.00674 1H4.99331C4.75739 1 4.56546 1.18071 4.56546 1.40284V1.97156C4.56546 2.1937 4.75739 2.37441 4.99331 2.37441H5.16949V3.00002C4.97429 3.01927 4.56337 3.07573 4.09497 3.2345C3.24073 3.52405 2.60736 4.01955 2.26331 4.66742C2.25456 4.68384 2.25 4.70199 2.25 4.72038V5.38389C2.25 5.44931 2.30635 5.50237 2.37584 5.50237H2.9547V6.78199H2.67785C2.44193 6.78199 2.25 6.9627 2.25 7.18483V7.94313C2.25 8.16526 2.44193 8.34597 2.67785 8.34597H2.9547V9.43602H2.67785C2.44193 9.43602 2.25 9.61673 2.25 9.83886V10.5972C2.25 10.8193 2.44193 11 2.67785 11H9.32215C9.55807 11 9.75 10.8193 9.75 10.5972V9.83886C9.75 9.61673 9.55807 9.43602 9.32215 9.43602H9.0453V8.34597H9.32215C9.55807 8.34597 9.75 8.16526 9.75 7.94313V7.18483C9.75 6.9627 9.55807 6.78199 9.32215 6.78199H9.0453V5.50237H9.62416Z" />
  </svg>
);

const IconBottleSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M12.8322 7.30332C12.9249 7.30332 13 7.22904 13 7.13744V6.20853C13 6.18279 12.9939 6.15737 12.9823 6.13435C12.5236 5.22734 11.6791 4.53364 10.5401 4.12827C9.91557 3.906 9.36765 3.82694 9.10742 3.8V2.92417H9.34232C9.65688 2.92417 9.91279 2.67118 9.91279 2.36019V1.56398C9.91279 1.253 9.65688 1 9.34232 1H6.65775C6.34319 1 6.08728 1.253 6.08728 1.56398V2.36019C6.08728 2.67118 6.34319 2.92417 6.65775 2.92417H6.89265V3.80003C6.63238 3.82697 6.0845 3.90603 5.45997 4.1283C4.32097 4.53367 3.47648 5.22737 3.01775 6.13438C3.00607 6.15737 3 6.18279 3 6.20853V7.13744C3 7.22904 3.07513 7.30332 3.16779 7.30332H3.9396V9.09479H3.57047C3.25591 9.09479 3 9.34778 3 9.65877V10.7204C3 11.0314 3.25591 11.2844 3.57047 11.2844H3.9396V12.8104H3.57047C3.25591 12.8104 3 13.0634 3 13.3744V14.436C3 14.747 3.25591 15 3.57047 15H12.4295C12.7441 15 13 14.747 13 14.436V13.3744C13 13.0634 12.7441 12.8104 12.4295 12.8104H12.0604V11.2844H12.4295C12.7441 11.2844 13 11.0314 13 10.7204V9.65877C13 9.34778 12.7441 9.09479 12.4295 9.09479H12.0604V7.30332H12.8322Z" />
  </svg>
);

const IconBottleSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M19.7315 10.9052C19.8798 10.9052 20 10.7885 20 10.6445V9.18483C20 9.14438 19.9903 9.10445 19.9717 9.06827C19.2377 7.64296 17.8865 6.55286 16.0641 5.91585C15.0649 5.56656 14.1882 5.44233 13.7719 5.4V4.0237H14.1477C14.651 4.0237 15.0605 3.62613 15.0605 3.13744V1.88626C15.0605 1.39756 14.651 1 14.1477 1H9.8524C9.3491 1 8.93965 1.39756 8.93965 1.88626V3.13744C8.93965 3.62613 9.3491 4.0237 9.8524 4.0237H10.2282V5.40005C9.81181 5.44238 8.93519 5.56662 7.93595 5.91591C6.11356 6.55292 4.76236 7.64301 4.0284 9.06832C4.00972 9.10445 4 9.14438 4 9.18483V10.6445C4 10.7885 4.12021 10.9052 4.26846 10.9052H5.50336V13.7204H4.91275C4.40945 13.7204 4 14.1179 4 14.6066V16.2749C4 16.7636 4.40945 17.1611 4.91275 17.1611H5.50336V19.5592H4.91275C4.40945 19.5592 4 19.9568 4 20.4455V22.1137C4 22.6024 4.40945 23 4.91275 23H19.0872C19.5906 23 20 22.6024 20 22.1137V20.4455C20 19.9568 19.5906 19.5592 19.0872 19.5592H18.4966V17.1611H19.0872C19.5906 17.1611 20 16.7636 20 16.2749V14.6066C20 14.1179 19.5906 13.7204 19.0872 13.7204H18.4966V10.9052H19.7315Z" />
  </svg>
);

export const IconBottle = createIcon({
  name: 'IconBottle',
  xs: IconBottleSizeXS,
  s: IconBottleSizeS,
  m: IconBottleSizeM,
});
