import { FC } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineOfflineBlockModal.module.scss';

type MachineOfflineBlockModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onRepeatClick: () => void;
};

const MachineOfflineBlockModal: FC<MachineOfflineBlockModalProps> = ({
  isOpen,
  onCancel,
  onRepeatClick,
}) => {
  const renderActions = () => (
    <>
      <Button label="Назад" view="ghost" onClick={onCancel} />
      <Button label="Попробовать снова" onClick={onRepeatClick} />
    </>
  );

  return (
    <DefaultModal
      className={styles.MachineOfflineBlockModal}
      isOpen={isOpen}
      modalTitle="Невозможно отправить"
      renderActions={renderActions}
      onClose={onCancel}
    >
      <Text>
        Невозможно отправить данные. Автомат не в сети. Подключите автомат к сети и попробуйте
        снова.
      </Text>
    </DefaultModal>
  );
};

export default MachineOfflineBlockModal;
