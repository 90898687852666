import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconEditSquareSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M8.36382 0.625C8.04175 0.625 7.77571 0.75975 7.52391 0.951867C7.28535 1.13388 7.02047 1.39878 6.70282 1.71645L3.96236 4.45687C3.75192 4.66697 3.58553 4.83309 3.48348 5.04541C3.38143 5.25774 3.35566 5.49145 3.32306 5.78702L3.31421 5.8669C3.25324 6.41548 3.20299 6.86767 3.21449 7.22387C3.22652 7.59639 3.30702 7.93761 3.57372 8.20431C3.84042 8.47101 4.18164 8.55151 4.55416 8.56354C4.91035 8.57504 5.36253 8.52479 5.91111 8.46382L5.99103 8.45497C6.28658 8.42237 6.5203 8.3966 6.73262 8.29455C6.94494 8.19251 7.11106 8.02611 7.32115 7.81567L10.0616 5.07521C10.3792 4.75756 10.6441 4.49268 10.8262 4.25412C11.0183 4.00232 11.153 3.73628 11.153 3.41421C11.153 3.09215 11.0183 2.82611 10.8262 2.57431C10.6441 2.33575 10.3793 2.07087 10.0616 1.75322L10.0248 1.71646C9.70717 1.39879 9.44228 1.13388 9.20372 0.951867C8.95192 0.75975 8.68588 0.625 8.36382 0.625ZM9.77739 4.29746L7.48057 2.00064C7.6834 1.80081 7.84027 1.65386 7.97885 1.54813C8.16393 1.40692 8.27167 1.375 8.36382 1.375C8.45596 1.375 8.5637 1.40692 8.74879 1.54813C8.94253 1.69595 9.17204 1.92434 9.51287 2.26516C9.85369 2.60599 10.0821 2.8355 10.2299 3.02924C10.3711 3.21433 10.403 3.32207 10.403 3.41421C10.403 3.50636 10.3711 3.6141 10.2299 3.79918C10.1242 3.93776 9.97722 4.09463 9.77739 4.29746ZM6.9496 2.53033L9.2477 4.82843L6.82696 7.24916C6.56324 7.51289 6.49037 7.57886 6.40773 7.61858C6.32509 7.65829 6.22805 7.67399 5.85737 7.71517C5.27238 7.78017 4.87422 7.82348 4.57837 7.81393C4.29258 7.8047 4.17721 7.74714 4.10405 7.67398C4.0309 7.60082 3.97333 7.48545 3.9641 7.19966C3.95455 6.90381 3.99786 6.50565 4.06285 5.92066C4.10404 5.54999 4.11974 5.45294 4.15945 5.3703C4.19917 5.28766 4.26515 5.21479 4.52887 4.95107L6.9496 2.53033Z" />
    <path d="M4.50449 1.96423C4.71159 1.96175 4.87746 1.79186 4.87497 1.58476C4.87249 1.37767 4.7026 1.2118 4.4955 1.21429C3.28225 1.22883 2.44947 1.29522 1.81012 1.75973C1.56605 1.93706 1.35141 2.1517 1.17408 2.39577C0.881162 2.79893 0.750003 3.27113 0.686864 3.85389C0.624993 4.42496 0.624996 5.14476 0.625 6.06303V6.10828C0.624996 7.02655 0.624993 7.74635 0.686864 8.31742C0.750003 8.90019 0.881162 9.37238 1.17408 9.77554C1.3514 10.0196 1.56605 10.2343 1.81012 10.4116C2.21328 10.7045 2.68547 10.8357 3.26824 10.8988C3.8393 10.9607 4.55908 10.9607 5.47733 10.9607H5.52264C6.44088 10.9607 7.1607 10.9607 7.73176 10.8988C8.31453 10.8357 8.78672 10.7045 9.18988 10.4116C9.43395 10.2343 9.6486 10.0196 9.82592 9.77554C10.2904 9.13619 10.3568 8.30341 10.3714 7.09015C10.3739 6.88306 10.208 6.71316 10.0009 6.71068C9.7938 6.7082 9.62391 6.87407 9.62142 7.08116C9.60644 8.33156 9.52226 8.91752 9.21916 9.3347C9.08809 9.5151 8.92945 9.67375 8.74904 9.80482C8.49504 9.98936 8.16993 10.0969 7.65098 10.1532C7.125 10.2101 6.44579 10.2107 5.5 10.2107C4.55421 10.2107 3.875 10.2101 3.34902 10.1532C2.83007 10.0969 2.50496 9.98936 2.25096 9.80482C2.07056 9.67375 1.91191 9.5151 1.78084 9.3347C1.5963 9.0807 1.48873 8.75559 1.4325 8.23663C1.37551 7.71066 1.375 7.03144 1.375 6.08566C1.375 5.13987 1.37551 4.46065 1.4325 3.93468C1.48873 3.41572 1.5963 3.09061 1.78084 2.83661C1.91191 2.65621 2.07056 2.49756 2.25096 2.36649C2.66813 2.0634 3.25409 1.97922 4.50449 1.96423Z" />
  </svg>
);

const IconEditSquareSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M11.1518 0.833374C10.7224 0.833374 10.3677 1.01304 10.0319 1.2692C9.71385 1.51188 9.36067 1.86508 8.93714 2.28864L5.28319 5.94253C5.0026 6.22266 4.78074 6.44416 4.64468 6.72726C4.50861 7.01036 4.47425 7.32197 4.43079 7.71607L4.41899 7.82257C4.3377 8.55402 4.27069 9.15694 4.28603 9.63186C4.30207 10.1286 4.40941 10.5835 4.765 10.9391C5.1206 11.2947 5.57556 11.4021 6.07226 11.4181C6.54718 11.4334 7.15008 11.3664 7.88152 11.2851L7.98808 11.2733C8.38215 11.2299 8.69377 11.1955 8.97686 11.0594C9.25996 10.9234 9.48145 10.7015 9.76158 10.4209L13.4155 6.76699C13.839 6.34346 14.1922 5.99028 14.4349 5.6722C14.6911 5.33646 14.8707 4.98175 14.8707 4.55233C14.8707 4.1229 14.6911 3.76819 14.4349 3.43245C14.1922 3.11437 13.839 2.7612 13.4155 2.33767L13.3665 2.28866C12.9429 1.86509 12.5898 1.51189 12.2717 1.2692C11.9359 1.01304 11.5812 0.833374 11.1518 0.833374ZM13.0366 5.72998L9.97414 2.66756C10.2446 2.40112 10.4537 2.20519 10.6385 2.06422C10.8853 1.87593 11.0289 1.83337 11.1518 1.83337C11.2747 1.83337 11.4183 1.87593 11.6651 2.06422C11.9234 2.26131 12.2294 2.56582 12.6839 3.02026C13.1383 3.4747 13.4428 3.78071 13.6399 4.03903C13.8282 4.28581 13.8707 4.42946 13.8707 4.55233C13.8707 4.67519 13.8282 4.81884 13.6399 5.06562C13.4989 5.25038 13.303 5.45955 13.0366 5.72998ZM9.26618 3.37381L12.3303 6.43794L9.10266 9.66559C8.75103 10.0172 8.65386 10.1052 8.54368 10.1581C8.43349 10.2111 8.3041 10.232 7.80986 10.2869C7.02987 10.3736 6.499 10.4314 6.10453 10.4186C5.72349 10.4063 5.56965 10.3296 5.47211 10.232C5.37457 10.1345 5.29781 9.98064 5.28551 9.59959C5.27277 9.20513 5.33051 8.67425 5.41718 7.89426C5.4721 7.40002 5.49302 7.27063 5.54598 7.16045C5.59894 7.05026 5.6869 6.95309 6.03853 6.60146L9.26618 3.37381Z" />
    <path d="M6.00603 2.61902C6.28216 2.61571 6.50331 2.38918 6.5 2.11306C6.49669 1.83694 6.27017 1.61578 5.99405 1.61909C4.37637 1.63848 3.266 1.72699 2.41353 2.34635C2.0881 2.58279 1.80191 2.86898 1.56548 3.1944C1.17492 3.73195 1.00004 4.36154 0.915859 5.13857C0.833364 5.89999 0.833369 6.85972 0.833374 8.08408V8.14441C0.833369 9.36877 0.833364 10.3285 0.91586 11.0899C1.00004 11.867 1.17492 12.4965 1.56548 13.0341C1.80191 13.3595 2.0881 13.6457 2.41353 13.8821C2.95108 14.2727 3.58067 14.4476 4.35769 14.5318C5.1191 14.6143 6.07882 14.6143 7.30314 14.6142H7.36356C8.58788 14.6143 9.54765 14.6143 10.3091 14.5318C11.0861 14.4476 11.7157 14.2727 12.2532 13.8821C12.5786 13.6457 12.8648 13.3595 13.1013 13.0341C13.7206 12.1816 13.8091 11.0713 13.8285 9.45358C13.8318 9.17745 13.6107 8.95093 13.3346 8.94762C13.0584 8.94431 12.8319 9.16547 12.8286 9.44159C12.8086 11.1088 12.6964 11.8901 12.2923 12.4463C12.1175 12.6868 11.906 12.8984 11.6654 13.0731C11.3268 13.3192 10.8933 13.4626 10.2013 13.5376C9.50005 13.6136 8.59442 13.6142 7.33337 13.6142C6.07233 13.6142 5.1667 13.6136 4.4654 13.5376C3.77346 13.4626 3.33999 13.3192 3.00132 13.0731C2.76078 12.8984 2.54925 12.6868 2.37449 12.4463C2.12844 12.1076 1.98501 11.6742 1.91004 10.9822C1.83406 10.2809 1.83337 9.3753 1.83337 8.11425C1.83337 6.8532 1.83406 5.94758 1.91004 5.24628C1.98501 4.55434 2.12844 4.12086 2.37449 3.78219C2.54925 3.54166 2.76078 3.33013 3.00132 3.15537C3.55755 2.75124 4.33883 2.639 6.00603 2.61902Z" />
  </svg>
);

const IconEditSquareSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M16.7276 1.25C16.0835 1.25 15.5514 1.5195 15.0478 1.90373C14.5707 2.26776 14.0409 2.79756 13.4056 3.4329L7.92473 8.91374C7.50384 9.33393 7.17105 9.66617 6.96696 10.0908C6.76286 10.5155 6.71131 10.9829 6.64612 11.574L6.62843 11.7338C6.50649 12.831 6.40597 13.7353 6.42898 14.4477C6.45304 15.1928 6.61405 15.8752 7.14744 16.4086C7.68084 16.942 8.36328 17.103 9.10833 17.1271C9.82071 17.1501 10.7251 17.0496 11.8222 16.9276L11.9821 16.9099C12.5732 16.8447 13.0406 16.7932 13.4652 16.5891C13.8899 16.385 14.2221 16.0522 14.6423 15.6313L20.1231 10.1504C20.7585 9.51512 21.2883 8.98536 21.6523 8.50824C22.0366 8.00463 22.3061 7.47256 22.3061 6.82843C22.3061 6.1843 22.0366 5.65222 21.6523 5.14862C21.2883 4.6715 20.7585 4.14174 20.1232 3.50644L20.0497 3.43293C19.4143 2.79757 18.8846 2.26777 18.4074 1.90373C17.9038 1.5195 17.3718 1.25 16.7276 1.25ZM19.5548 8.59491L14.9611 4.00128C15.3668 3.60162 15.6805 3.30772 15.9577 3.09627C16.3279 2.81383 16.5433 2.75 16.7276 2.75C16.9119 2.75 17.1274 2.81383 17.4976 3.09627C17.8851 3.3919 18.3441 3.84867 19.0257 4.53033C19.7074 5.21199 20.1642 5.67101 20.4598 6.05849C20.7422 6.42866 20.8061 6.64413 20.8061 6.82843C20.8061 7.01272 20.7422 7.22819 20.4598 7.59836C20.2483 7.87551 19.9544 8.18927 19.5548 8.59491ZM13.8992 5.06066L18.4954 9.65685L13.6539 14.4983C13.1265 15.0258 12.9807 15.1577 12.8155 15.2372C12.6502 15.3166 12.4561 15.348 11.7147 15.4303C10.5448 15.5603 9.74843 15.647 9.15674 15.6279C8.58517 15.6094 8.35441 15.4943 8.2081 15.348C8.06179 15.2016 7.94665 14.9709 7.9282 14.3993C7.90909 13.8076 7.99571 13.0113 8.12571 11.8413C8.20808 11.1 8.23947 10.9059 8.31891 10.7406C8.39835 10.5753 8.53029 10.4296 9.05773 9.90213L13.8992 5.06066Z" />
    <path d="M9.00899 3.92846C9.42317 3.9235 9.75491 3.58371 9.74995 3.16953C9.74498 2.75534 9.40519 2.42361 8.99101 2.42857C6.56449 2.45766 4.89894 2.59043 3.62024 3.51946C3.13209 3.87412 2.70281 4.3034 2.34815 4.79155C1.76232 5.59787 1.50001 6.54225 1.37373 7.70779C1.24999 8.84992 1.24999 10.2895 1.25 12.1261V12.2166C1.24999 14.0531 1.24999 15.4927 1.37373 16.6348C1.50001 17.8004 1.76232 18.7448 2.34815 19.5511C2.70281 20.0392 3.13209 20.4685 3.62024 20.8232C4.42656 21.409 5.37094 21.6713 6.53648 21.7976C7.67859 21.9213 9.11817 21.9213 10.9547 21.9213H11.0453C12.8818 21.9213 14.3214 21.9213 15.4635 21.7976C16.6291 21.6713 17.5734 21.409 18.3798 20.8232C18.8679 20.4685 19.2972 20.0392 19.6518 19.5511C20.5809 18.2724 20.7137 16.6068 20.7427 14.1803C20.7477 13.7661 20.416 13.4263 20.0018 13.4214C19.5876 13.4164 19.2478 13.7481 19.2428 14.1623C19.2129 16.6631 19.0445 17.835 18.4383 18.6694C18.1762 19.0302 17.8589 19.3475 17.4981 19.6096C16.9901 19.9787 16.3399 20.1939 15.302 20.3063C14.25 20.4203 12.8916 20.4213 11 20.4213C9.10843 20.4213 7.74999 20.4203 6.69804 20.3063C5.66013 20.1939 5.00992 19.9787 4.50191 19.6096C4.14111 19.3475 3.82382 19.0302 3.56168 18.6694C3.19259 18.1614 2.97745 17.5112 2.865 16.4733C2.75103 15.4213 2.75 14.0629 2.75 12.1713C2.75 10.2797 2.75103 8.9213 2.865 7.86936C2.97745 6.83144 3.19259 6.18123 3.56168 5.67322C3.82382 5.31242 4.14111 4.99513 4.50191 4.73299C5.33627 4.1268 6.50819 3.95844 9.00899 3.92846Z" />
  </svg>
);

export const IconEditSquare = createIcon({
  name: 'IconEditSquare',
  xs: IconEditSquareSizeXS,
  s: IconEditSquareSizeS,
  m: IconEditSquareSizeM,
});
