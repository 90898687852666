import { FC } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { EditOutletDTO } from '../../../../../types/serverInterface/outletDTO';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента EditOutletName
 */
type EditOutletNameProps = {
  /**
   * Торговая точка
   */
  outlet: EditOutletDTO;
  /**
   * Обработчик изменения торговой точки
   *
   * @param key ключ поля
   */
  onChange: (key: keyof EditOutletDTO) => ({ value }: { value: string | null }) => void;
};

/**
 * Форма изменения названия торговой точки
 */
const EditOutletName: FC<EditOutletNameProps> = ({ outlet, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t('outlet.edit.name.name.input.label')}
        name="name"
        width="full"
        value={outlet.name}
        onChange={onChange('name')}
      />
    </>
  );
};

export default EditOutletName;
