import * as Yup from 'yup';
import { CreateOutletDTO } from '../../../../types/serverInterface/outletDTO';

/**
 * Схема валидации формы создания торговой точки
 */
export const createOutletValidationScheme: Yup.ObjectSchema<CreateOutletDTO> = Yup.object({
  name: Yup.string().required('Это поле обязательное'),
  country: Yup.string().required('Это поле обязательное'),
  city: Yup.string().required('Это поле обязательное'),
  street: Yup.string().required('Это поле обязательное'),
  houseNumber: Yup.number().required('Это поле обязательное'),
  organizationId: Yup.number().required('Это поле обязательное'),
});
