import { FC } from 'react';
import { EmployeeDTO } from '../../../../../types/serverInterface/employeeDTO';
import DeleteModal from '../../../../../components/DeleteModal';

type DeleteMemberProps = {
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Сотрудник
   */
  member: EmployeeDTO | null;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

const DeleteMember: FC<DeleteMemberProps> = ({ member, onDelete, isDeleteLoading, onClose }) => {
  if (!member) return null;

  const { surname, patronymic, name } = member;
  const userFullName = `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`;

  return (
    <DeleteModal
      primaryButtonLabel="Уволить"
      isOpen={!!member}
      isDeleteLoading={isDeleteLoading}
      title={`Увольнение сотрудника ${userFullName}`}
      description={`Вы уверены, что хотите уволить сотрудника ${userFullName}`}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteMember;
