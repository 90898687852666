import { FC, useEffect, useMemo, useState } from 'react';
import StorageCardGroup from './StorageCardGroup';
import MachineStorageScheme from './MachineStorageScheme';
import { getSortedGroup } from './helpers';
import styles from './MachineStorage.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import {
  editNewMachineStorage,
  getMachineStorageInfoAction,
  refillMachineStorageAction,
} from '../../../state/machineControl/actions';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import {
  MachineStorageCupCellType,
  MachineStorageDisposableCellType,
  MachineStorageProductCellType,
  MachineStorageWaterCellType,
  SeparatedMachineCellUnionType,
  SeparatedMachineStorageType,
  StorageUnionType,
} from './types';
import { TextFieldPropStatus } from '@consta/uikit/TextField';
import { ProductGroup } from '../../../types/serverInterface/storageDTO';
import {
  selectMachineBaseInfo,
  selectMachineStorageInfo,
  selectNewStorage,
} from '../../../state/machineControl/selectors';
import {
  ConnectionStatus,
  MachineCellGroup,
  MachineCellGroupUnion,
  MachineStorageInfoCupCell,
  MachineStorageInfoDisposableCell,
  MachineStorageInfoFormatted,
  MachineStorageInfoProductCellFormatted,
  MachineStorageInfoWaterCell,
  RefillMachineCell,
  RefillMachineStorageDTO,
} from '../../../types/serverInterface/machineDTO';
import { enumToArray } from '../../../types/enums';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import MachineOfflineBlockModal from '../../../pages/MachineControlPage/Machine/MachineOfflineBlockModal';

/**
 * Ошибка склада автомата
 */
type Error = {
  /**
   * Статус ошибки
   */
  status: TextFieldPropStatus;
  /**
   * Название ошибки
   */
  label?: string;
};

/**
 * Типы страниц склада автомата
 */
export enum MachineStoragePageTypeOld {
  /**
   * Информация о остатках
   */
  INFO = 'INFO',
  /**
   * Изменение наполнения
   */
  EDIT = 'EDIT',
  /**
   * Пополнение остатков
   */
  REFILL = 'REFILL',
  /**
   * Домашняя страница автомата
   */
  DETAILS_PAGE = 'DETAILS_PAGE',
}

/**
 * Свойства компонента MachineStorage
 */
type MachineStorageProps = {
  /**
   * id автомата
   */
  machineId: number;
  editStorageNextStep: () => void;
};

/**
 * Дефолтное значение склада автомата
 */
const initialStorage: SeparatedMachineStorageType = {
  cells: {
    [ProductGroup.COFFEE]: [],
    [ProductGroup.CONCENTRATE]: [],
    [ProductGroup.POWDER]: [],
  },
  cellWaters: [],
  cellCups: [],
  cellDisposables: [],
};

/**
 * Ошибка ячейки склада автоматов
 */
export type StorageFormCardError<T extends StorageUnionType> = Partial<Record<keyof T, Error>>;

/**
 * Мапа ошибок группы ячеек склада автомата
 */
export type StorageFormGroupError<T extends StorageUnionType> = Record<
  number,
  StorageFormCardError<T>
>;

/**
 * Ошибки склада автомата
 */
export type StorageFormError = Record<
  MachineCellGroupUnion,
  StorageFormGroupError<StorageUnionType>
>;

/**
 * Дефолтное значение ошибки склада автомата
 */
const initialError: StorageFormError = {
  [ProductGroup.CONCENTRATE]: {},
  [ProductGroup.POWDER]: {},
  [ProductGroup.COFFEE]: {},
  [MachineCellGroup.WATER]: {},
  [MachineCellGroup.CUP]: {},
  [MachineCellGroup.DISPOSABLE]: {},
};

/**
 * Ошибка не заполненого обязательного поля
 */
const noContentError: Error = {
  status: 'alert',
  label: 'Обязательное поле',
};

/**
 * Валидация группы продукты склада автомата
 *
 * @param storage группа склада автомата
 */
const storageProductFormValidate = (
  storage: MachineStorageProductCellType[],
): StorageFormCardError<MachineStorageInfoProductCellFormatted> => {
  const errors: StorageFormGroupError<MachineStorageInfoProductCellFormatted> = {};

  storage.forEach(({ info }, index) => {
    const { minVolume, ingredientId, ingredientLineId, brandId } = info;

    if (!errors[index]) {
      errors[index] = {};
    }

    // TODO: тут поработать с вложенностью product и ошибками. Это тут работает плохо.
    //  Возможно стоит пересмотреть типизацию
    if (!brandId) {
      errors[index].brandId = noContentError;
    }
    if (!ingredientLineId) {
      errors[index].ingredientLineId = noContentError;
    }
    if (!ingredientId) {
      errors[index].ingredientId = noContentError;
    }
    if (!minVolume) {
      errors[index].minVolume = noContentError;
    }
  });

  return errors;
};

/**
 * Валидация группы вода склада автомата
 *
 * @param storage группа склада автомата
 */
const storageWaterValidate = (
  storage: MachineStorageWaterCellType[],
): StorageFormGroupError<MachineStorageInfoWaterCell> => {
  const errors: StorageFormGroupError<MachineStorageInfoWaterCell> = {};

  return errors;
};

/**
 * Валидация группы стаканы склада автомата
 *
 * @param storage группа склада автомата
 */
const storageCupValidate = (
  storage: MachineStorageCupCellType[],
): StorageFormGroupError<MachineStorageInfoCupCell> => {
  const errors: StorageFormGroupError<MachineStorageInfoCupCell> = {};

  return errors;
};

/**
 * Валидация группы расходники склада автомата
 *
 * @param storage группа склада автомата
 */
const storageDisposableValidate = (
  storage: MachineStorageDisposableCellType[],
): StorageFormGroupError<MachineStorageInfoDisposableCell> => {
  const errors: StorageFormGroupError<MachineStorageInfoDisposableCell> = {};

  return errors;
};

/**
 * Валидация всего склада автомата
 *
 * @param storage склада автомата
 */
const storageValidate = (
  storage: SeparatedMachineStorageType,
): { errors: StorageFormError; isError: boolean } => {
  const errors = {
    [ProductGroup.POWDER]: storageProductFormValidate(storage.cells[ProductGroup.POWDER]),
    [ProductGroup.CONCENTRATE]: storageProductFormValidate(storage.cells[ProductGroup.CONCENTRATE]),
    [ProductGroup.COFFEE]: storageProductFormValidate(storage.cells[ProductGroup.COFFEE]),
    [MachineCellGroup.WATER]: storageWaterValidate(storage.cellWaters),
    [MachineCellGroup.CUP]: storageCupValidate(storage.cellCups),
    [MachineCellGroup.DISPOSABLE]: storageDisposableValidate(storage.cellDisposables),
  };

  return { errors, isError: false };
};

/**
 * 1
 *
 * @param group
 */
const getGroup = (
  group: MachineCellGroupUnion,
): keyof Omit<SeparatedMachineStorageType, 'cells'> => {
  switch (group) {
    case MachineCellGroup.WATER:
      return 'cellWaters';
    case MachineCellGroup.CUP:
      return 'cellCups';
    default:
      return 'cellDisposables';
  }
};

/**
 * Склад автомата
 */
const MachineStorage: FC<MachineStorageProps> = ({ machineId, editStorageNextStep }) => {
  const dispatch = useAppDispatch();

  const { state: oldStorage } = useAppSelector(selectMachineStorageInfo());
  const newStorage = useAppSelector(selectNewStorage());
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());
  const organizationId = getOrganizationId();

  const machineFilling = newStorage || oldStorage;

  const [machineStorageArr, setMachineStorageArr] = useState({
    ...initialStorage,
  });
  const [storageError, setStorageError] = useState(initialError);
  const [isOpenEditOfflineBlockModal, setIsOpenEditOfflineBlockModal] = useState(false);
  const [pageType, setPageType] = useState<MachineStoragePageTypeOld>(
    MachineStoragePageTypeOld.INFO,
  );

  const sortedGroup = useMemo(() => getSortedGroup(machineStorageArr), [machineStorageArr]);

  useEffect(() => {
    const separateStorageInfoByGroup = (storageInfoArray: MachineStorageInfoFormatted) => {
      const sortedCells = [...storageInfoArray.cells];
      sortedCells.sort((a, b) => a.cellNumber - b.cellNumber);

      const separatedStorageInfo: SeparatedMachineStorageType = JSON.parse(
        JSON.stringify(initialStorage),
      );

      sortedCells.forEach((produceCell) => {
        separatedStorageInfo.cells[produceCell.group]?.push({
          info: produceCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      separatedStorageInfo.cellWaters?.push({
        info: storageInfoArray.cellWaters[0],
        isHoverStatus: false,
        isActiveStatus: false,
      });

      storageInfoArray.cellCups.forEach((cupCell) => {
        separatedStorageInfo.cellCups?.push({
          info: cupCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      storageInfoArray.cellDisposables.forEach((disposableCell) => {
        separatedStorageInfo.cellDisposables?.push({
          info: disposableCell,
          isHoverStatus: false,
          isActiveStatus: false,
        });
      });

      setMachineStorageArr(separatedStorageInfo);
    };

    machineFilling && separateStorageInfoByGroup(machineFilling);
  }, [machineFilling, pageType]);

  // Обработчики
  const handleClick = (group: MachineCellGroupUnion) => (index: number) => {
    if (pageType === MachineStoragePageTypeOld.EDIT) {
      if (
        group === MachineCellGroup.CUP ||
        group == MachineCellGroup.DISPOSABLE ||
        group === MachineCellGroup.WATER
      ) {
        const formatGroup = getGroup(group);

        return setMachineStorageArr((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              isActiveStatus: !prevState[formatGroup][index].isActiveStatus,
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setMachineStorageArr((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                isActiveStatus: !prevState.cells[group][index].isActiveStatus,
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));
      }
    }
  };

  const handleHover = (group: MachineCellGroupUnion) => (index: number) => {
    if (
      group === MachineCellGroup.CUP ||
      group == MachineCellGroup.DISPOSABLE ||
      group === MachineCellGroup.WATER
    ) {
      const formatGroup = getGroup(group);

      return setMachineStorageArr((prevState) => ({
        ...prevState,
        [formatGroup]: [
          ...prevState[formatGroup].slice(0, index),
          { ...prevState[formatGroup][index], isHoverStatus: true },
          ...prevState[formatGroup].slice(index + 1),
        ],
      }));
    }

    if (
      group === ProductGroup.COFFEE ||
      group == ProductGroup.CONCENTRATE ||
      group === ProductGroup.POWDER
    ) {
      setMachineStorageArr((prevState) => ({
        ...prevState,
        cells: {
          ...prevState.cells,
          [group]: [
            ...prevState.cells[group].slice(0, index),
            { ...prevState.cells[group][index], isHoverStatus: true },
            ...prevState.cells[group].slice(index + 1),
          ],
        },
      }));
    }
  };

  const handleHoverLeave = (group: MachineCellGroupUnion) => (index: number) => {
    if (
      group === MachineCellGroup.CUP ||
      group == MachineCellGroup.DISPOSABLE ||
      group === MachineCellGroup.WATER
    ) {
      const formatGroup = getGroup(group);

      return setMachineStorageArr((prevState) => ({
        ...prevState,
        [formatGroup]: [
          ...prevState[formatGroup].slice(0, index),
          { ...prevState[formatGroup][index], isHoverStatus: false },
          ...prevState[formatGroup].slice(index + 1),
        ],
      }));
    }

    if (
      group === ProductGroup.COFFEE ||
      group == ProductGroup.CONCENTRATE ||
      group === ProductGroup.POWDER
    ) {
      setMachineStorageArr((prevState) => ({
        ...prevState,
        cells: {
          ...prevState.cells,
          [group]: [
            ...prevState.cells[group].slice(0, index),
            { ...prevState.cells[group][index], isHoverStatus: false },
            ...prevState.cells[group].slice(index + 1),
          ],
        },
      }));
    }
  };

  const handleInputChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof StorageUnionType) =>
    (value: number | string | null) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setMachineStorageArr((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: value,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setMachineStorageArr((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: value,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }
    };

  const handleValueChange =
    (group: MachineCellGroupUnion) => (index: number) => (value: number | string | null) => {
      handleInputChange(group)(index)('volume')(value);
    };

  const handleSelectChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof StorageUnionType) =>
    (id: number | null) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setMachineStorageArr((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: id,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setMachineStorageArr((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: id,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }
    };

  const handleBooleanChange =
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    (key: keyof StorageUnionType) =>
    (checked: boolean) => {
      if (
        group === MachineCellGroup.CUP ||
        group === MachineCellGroup.WATER ||
        group === MachineCellGroup.DISPOSABLE
      ) {
        const formatGroup = getGroup(group);

        setMachineStorageArr((prevState) => ({
          ...prevState,
          [formatGroup]: [
            ...prevState[formatGroup].slice(0, index),
            {
              ...prevState[formatGroup][index],
              info: {
                ...prevState[formatGroup][index].info,
                [key]: checked,
              },
            },
            ...prevState[formatGroup].slice(index + 1),
          ],
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }

      if (
        group === ProductGroup.COFFEE ||
        group == ProductGroup.CONCENTRATE ||
        group === ProductGroup.POWDER
      ) {
        setMachineStorageArr((prevState) => ({
          ...prevState,
          cells: {
            ...prevState.cells,
            [group]: [
              ...prevState.cells[group].slice(0, index),
              {
                ...prevState.cells[group][index],
                info: {
                  ...prevState.cells[group][index].info,
                  [key]: checked,
                },
              },
              ...prevState.cells[group].slice(index + 1),
            ],
          },
        }));

        setStorageError((prevState) => ({
          ...prevState,
          [group]: {
            ...prevState[group],
            [index]: {
              ...prevState[group][index],
              [key]: {},
            },
          },
        }));
      }
    };

  const handleEditClick = () => {
    setPageType(MachineStoragePageTypeOld.EDIT);
  };

  const handleRefillClick = () => {
    setPageType(MachineStoragePageTypeOld.REFILL);
  };

  const handleCancelEditClick = () => {
    setStorageError(initialError);
    setPageType(MachineStoragePageTypeOld.INFO);
  };

  const handleNextEditClick = () => {
    console.log('next form step');
    // TODO: тут нужно сделать запрет перехода на следующую страницу и не отправлять dispatch
    //  Посмотреть пример можно в модели автомата
    const { errors, isError } = storageValidate(machineStorageArr);
    setStorageError(errors);

    // TODO: добавить прееход на страницу
    if (!isError) {
      dispatch(
        editNewMachineStorage({
          id: machineId,
          cells: [
            ...machineStorageArr.cells[ProductGroup.POWDER].map((item) => ({ ...item.info })),
            ...machineStorageArr.cells[ProductGroup.CONCENTRATE].map((item) => ({ ...item.info })),
            ...machineStorageArr.cells[ProductGroup.COFFEE].map((item) => ({ ...item.info })),
          ],
          cellWaters: machineStorageArr.cellWaters.map((item) => ({ ...item.info })),
          cellCups: machineStorageArr.cellCups.map((item) => ({ ...item.info })),
          cellDisposables: machineStorageArr.cellDisposables.map((item) => ({ ...item.info })),
        }),
      );

      editStorageNextStep();
    }
  };

  const handleCancelRefillClick = () => {
    setPageType(MachineStoragePageTypeOld.INFO);
  };

  const handleEditOfflineBlockModalClose = () => {
    setIsOpenEditOfflineBlockModal(false);
  };

  const handleEditOfflineBlockModalRepeatClick = () => {
    handleRefillSubmitClick();
  };

  const handleRefillSubmitClick = () => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsOpenEditOfflineBlockModal(true);
      return null;
    }

    const cells = {
      ...machineStorageArr.cells,
      [MachineCellGroup.WATER]: machineStorageArr.cellWaters,
      [MachineCellGroup.CUP]: machineStorageArr.cellCups,
      [MachineCellGroup.DISPOSABLE]: machineStorageArr.cellDisposables,
    };

    const transformCellByRefill =
      (group: MachineCellGroupUnion) =>
      ({ info }: SeparatedMachineCellUnionType): RefillMachineCell => {
        return { id: info.id, volume: info.volume, group };
      };

    const combinedArr: RefillMachineStorageDTO = [
      ...cells[ProductGroup.COFFEE].map(transformCellByRefill(ProductGroup.COFFEE)),
      ...cells[ProductGroup.POWDER].map(transformCellByRefill(ProductGroup.POWDER)),
      ...cells[ProductGroup.CONCENTRATE].map(transformCellByRefill(ProductGroup.CONCENTRATE)),
      ...cells[MachineCellGroup.WATER].map(transformCellByRefill(MachineCellGroup.WATER)),
      ...cells[MachineCellGroup.CUP].map(transformCellByRefill(MachineCellGroup.CUP)),
      ...cells[MachineCellGroup.DISPOSABLE].map(transformCellByRefill(MachineCellGroup.DISPOSABLE)),
    ];

    dispatch(refillMachineStorageAction(combinedArr, machineId)).then(() => {
      dispatch(getMachineStorageInfoAction(machineId));
      setPageType(MachineStoragePageTypeOld.INFO);
    });
  };

  const getMachineCellByGroup = (group: MachineCellGroup) => {
    switch (group) {
      case MachineCellGroup.WATER:
        return machineStorageArr.cellWaters;
      case MachineCellGroup.CUP:
        return machineStorageArr.cellCups;
      case MachineCellGroup.DISPOSABLE:
        return machineStorageArr.cellDisposables;
    }
  };

  // render методы
  const renderStorageList = () => (
    <div className={styles.storage}>
      {sortedGroup.map((key, index) => (
        <StorageCardGroup
          key={key}
          pageType={pageType}
          groupIndex={index}
          storage={machineStorageArr.cells[key]}
          group={key}
          errors={storageError[key]}
          onClick={handleClick}
          onHover={handleHover}
          onHoverLeave={handleHoverLeave}
          onInputChange={handleInputChange}
          onSelectChange={handleSelectChange}
          onBooleanChange={handleBooleanChange}
        />
      ))}
      {enumToArray(MachineCellGroup).map((group) => (
        <StorageCardGroup
          key={group}
          pageType={pageType}
          groupIndex={10}
          storage={getMachineCellByGroup(group)}
          group={group}
          errors={storageError[group]}
          onClick={handleClick}
          onHover={handleHover}
          onHoverLeave={handleHoverLeave}
          onInputChange={handleInputChange}
          onSelectChange={handleSelectChange}
          onBooleanChange={handleBooleanChange}
        />
      ))}
    </div>
  );

  const renderStorageScheme = () => (
    <MachineStorageScheme
      pageType={pageType}
      storage={machineStorageArr}
      sortedGroup={sortedGroup}
      onClick={handleClick}
      onHover={handleHover}
      onHoverLeave={handleHoverLeave}
      onValueChange={handleValueChange}
    />
  );

  const renderInfoAction = () => (
    <>
      <Button view="secondary" label="Пополнить" onClick={handleRefillClick} />
      <Button label="Изменить" onClick={handleEditClick} />
    </>
  );

  const renderEditAction = () => (
    <>
      <Button view="clear" label="Назад" onClick={handleCancelEditClick} />
      <Button label="Далее" onClick={handleNextEditClick} />
    </>
  );

  const renderRefillAction = () => (
    <>
      <Button view="clear" label="Отмена" onClick={handleCancelRefillClick} />
      <Button label="Сохранить" onClick={handleRefillSubmitClick} />
    </>
  );

  const renderAction = () => {
    switch (pageType) {
      case MachineStoragePageTypeOld.INFO:
        return renderInfoAction();
      case MachineStoragePageTypeOld.EDIT:
        return renderEditAction();
      case MachineStoragePageTypeOld.REFILL:
        return renderRefillAction();
      case MachineStoragePageTypeOld.DETAILS_PAGE:
        return null;
    }
  };

  return (
    <>
      <MachineOfflineBlockModal
        isOpen={isOpenEditOfflineBlockModal}
        onCancel={handleEditOfflineBlockModalClose}
        onRepeatClick={handleEditOfflineBlockModalRepeatClick}
      />
      {renderStorageList()}
      <div className={styles.rightSide}>
        {renderStorageScheme()}
        <div className={styles.actions}>{renderAction()}</div>
      </div>
    </>
  );
};

export default MachineStorage;
