import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSaleMethodQrCodeSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_6864_69259)">
      <path
        d="M2.39844 5.22412L5.30483 10.4191V13.5879L2.40184 18.7727L2.39844 5.22412Z"
        fill="#707070"
      />
      <path
        d="M13.5586 8.52839L16.282 6.85919L21.8556 6.854L13.5586 11.9368V8.52839Z"
        fill="#707070"
      />
      <path
        d="M13.5423 5.19341L13.5577 12.0714L10.6445 10.2814V0L13.5423 5.19341Z"
        fill="#707070"
      />
      <path
        d="M21.8547 6.85421L16.2809 6.85939L13.5423 5.19341L10.6445 0L21.8547 6.85421Z"
        fill="#707070"
      />
      <path
        d="M13.5577 18.8015V15.4645L10.6445 13.7085L10.6461 24.0001L13.5577 18.8015Z"
        fill="#707070"
      />
      <path
        d="M16.2744 17.1475L5.30464 10.4191L2.39844 5.22412L21.843 17.1407L16.2744 17.1475Z"
        fill="#707070"
      />
      <path
        d="M10.6484 24L13.5596 18.8014L16.2762 17.1474L21.8448 17.1406L10.6484 24Z"
        fill="#707070"
      />
      <path
        d="M2.40234 18.7726L10.6689 13.7086L7.88974 12.0034L5.30534 13.5878L2.40234 18.7726Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_6864_69259">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconSaleMethodQrCode = createIcon({
  name: 'IconSaleMethodQrCode',
  s: IconSaleMethodQrCodeSizeL,
  m: IconSaleMethodQrCodeSizeL,
  xs: IconSaleMethodQrCodeSizeL,
});
