import { FC } from 'react';
import { CreateBaseModel } from '../../../../../types/serverInterface/machineModelDTO';
import DefaultInfo from '../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента BaseModelInfo
 */
type BaseModelInfoProps = {
  /**
   * Модель автомата
   */
  model: CreateBaseModel;
};

/**
 * Базовая информация о модели автомата
 */
const BaseModelInfo: FC<BaseModelInfoProps> = ({ model }) => {
  const { t } = useTranslation();

  return (
    <DefaultInfo>
      <DefaultInfoItem
        item={{ name: t('machineControl.model.info.base.name.label'), value: model.name }}
      />
      <DefaultInfoItem
        item={{
          name: t('machineControl.model.info.base.groupModel.label'),
          value: t(`machineControl.model.info.base.groupModel.${model.groupModel}`),
        }}
      />
      <DefaultInfoItem
        item={{
          name: t('machineControl.model.info.base.maxVolume.label'),
          value: String(model.qtyDosage),
        }}
      />
    </DefaultInfo>
  );
};

export default BaseModelInfo;
