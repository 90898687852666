import { FC } from 'react';
import { CellsModel } from '../../../../../types/serverInterface/machineModelDTO';
import ProductCellsModelInfo from './ProductCellsModelInfo';
import WaterCellsModelInfo from './WaterCellsModelInfo';
import CupCellsModelInfo from './CupCellsModelInfo';
import DisposableCellsModelInfo from './DisposableCellsModelInfo';
import VerticalContainer from '../../../../../components/VerticalContainer';

/**
 * Свойства компонента CellsModelInfo
 */
type CellsModelInfoProps = {
  /**
   * Ячейки модели автомата
   */
  modelCells: CellsModel;
};

/**
 * Информация о ячейках модели автомата
 */
const CellsModelInfo: FC<CellsModelInfoProps> = ({ modelCells }) => {
  const { cells, cellWaters, cellCups, cellDisposables } = modelCells;

  return (
    <VerticalContainer space="xl">
      <ProductCellsModelInfo cells={cells ?? []} />
      <WaterCellsModelInfo cells={cellWaters ?? []} />
      <CupCellsModelInfo cells={cellCups ?? []} />
      <DisposableCellsModelInfo cells={cellDisposables ?? []} />
    </VerticalContainer>
  );
};

export default CellsModelInfo;
