import { FC } from 'react';
import { IconNoContent } from '../../assets/icon/iconNoContent';
import styles from './NoContent.module.scss';
import { defaultIconProps } from '../../consts/defaultIconProps';

const NoContent: FC = () => {
  return (
    <div className={styles.noContent}>
      <IconNoContent className={styles.icon} {...defaultIconProps} />
    </div>
  );
};

export default NoContent;
