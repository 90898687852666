import { enumToArray } from '../enums';
// TODO: этот файл полностью удаляется
/**
 * Виды подключения воды
 */
export enum WaterStorageTypes {
  // Кран
  TAP = 'TAP',
  // Бутылка
  BOTTLE = 'BOTTLE',
}

/**
 * Список видов подключения воды
 */
export const waterStorageTypes = enumToArray(WaterStorageTypes).map((item) => String(item));

/**
 * Группы продуктов склада автомата
 */
export enum ProductGroup {
  // Порошок
  POWDER = 'POWDER',
  // Концентрат
  CONCENTRATE = 'CONCENTRATE',
  // Кофе
  COFFEE = 'COFFEE',
}

/**
 * Список групп продуктов склада автомата
 */
export const productGroupList = enumToArray(ProductGroup);

/**
 * Внешние виды продукта
 */
export enum ProductView {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  COFFEE = 'COFFEE',
  DEFAULT = 'DEFAULT',
}

/**
 * Список внешних видов продукта
 */
export const productViewList = enumToArray(ProductView);
//
// /**
//  * Базовая информация о ячейке
//  */
// export type StorageInfo = {
//   /**
//    * id ячейки
//    * 1. Не используется в обработчиках
//    * 2. Служебное поля для бэка. Необходимо отправлять массив изменённых ячеек с этим id
//    */
//   id?: number;
//   /**
//    * Номер ячейки склада
//    * В пределах одного склада не желательно иметь контейнеры с 1 номеров
//    */
//   cellNumber: number;
//   /**
//    * Блокированная ячейка
//    */
//   isBlocked?: boolean;
//   /**
//    * Остатки в контейнере
//    */
//   value?: number;
// };

export type Dosage = {
  price: number;
  volume: number;
  isBlock: boolean;
};

export type CellByPrices = {
  cellNumber: number;
  view: ProductView;
};

export type MachineProduct = {
  name: string;
  productId?: number;
  cellNumbers: CellByPrices[];
  dosage: Dosage[];
};

export type MachinePricesDTO = {
  maxDosageNumber: number;
  products: MachineProduct[];
};
