import { FC } from 'react';
import CellCategoryForm from './CellCategoryForm';
import { useAppSelector } from '../../../../app/hooks/store';
import { selectCellCategoryById } from '../../../../state/productBase/selectors';

/**
 * Свойства компонента EditCellCategory
 */
type EditCellCategoryProps = {
  /**
   * id категории контейнера
   */
  cellCategoryId: number;
  /**
   * Обработчик закрытия формы
   */
  onClose: () => void;
};

/**
 * Компонент изменения категории контейнера
 */
const EditCellCategory: FC<EditCellCategoryProps> = ({ cellCategoryId, onClose }) => {
  const cellCategory = useAppSelector(selectCellCategoryById(cellCategoryId));

  return (
    cellCategory && (
      <CellCategoryForm
        type="edit"
        isOpen={!!cellCategory}
        cellCategoryId={cellCategoryId}
        cellCategory={cellCategory}
        onClose={onClose}
      />
    )
  );
};

export default EditCellCategory;
