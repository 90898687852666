import { FC, useState } from 'react';
import DefaultModal from '../../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import ProductBasicForm from '../ProductBasicForm';
import ProductCharacteristicsForm from '../ProductCharacteristicsForm';
import {
  CreateProductDTO,
  EditProductCharacteristicsDTO,
  EditProductDTO,
  ProductCharacteristicItem,
  ProductCharacteristics,
} from '../../../../../types/serverInterface/productDTO';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import {
  selectCellCategoryList,
  selectCellPurposeList,
  selectCellViewList,
  selectTasteList,
} from '../../../../../state/productBase/selectors';
import { createProductAction } from '../../../../../state/productBase/actions';
import { useTranslation } from 'react-i18next';
import { CreateProductFormProps, ProductFormSteps } from './types';
import { createProductBasicInitialValue, creteProductCharacteristicsInitialValue } from './consts';

/**
 * Форма создания продукта
 */
const CreateProductForm: FC<CreateProductFormProps> = ({
  isOpen,
  productLineId,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const cellCategoryList = useAppSelector(selectCellCategoryList());
  const cellPurposeList = useAppSelector(selectCellPurposeList());
  const tasteList = useAppSelector(selectTasteList());
  const sportPitList = useAppSelector(selectCellViewList());

  const [formStep, setFormStep] = useState<ProductFormSteps>(ProductFormSteps.BASE);
  const [characteristicFormState, setCharacteristicFormState] = useState<ProductCharacteristics>(
    creteProductCharacteristicsInitialValue,
  );
  const [basicFormState, setBasicFormState] = useState<EditProductDTO>(
    createProductBasicInitialValue,
  );

  // Вспомогательные методы
  const getPrimaryButtonLabel = () => {
    switch (formStep) {
      case ProductFormSteps.BASE:
        return t('productBase.product.form.create.further.button.label');
      case ProductFormSteps.CHARACTERISTICS:
        return t('productBase.product.form.create.add.button.label');
    }
  };

  const getCancelButtonLabel = () => {
    switch (formStep) {
      case ProductFormSteps.BASE:
        return t('productBase.product.form.create.cancel.button.label');
      case ProductFormSteps.CHARACTERISTICS:
        return t('productBase.product.form.create.back.button.label');
    }
  };

  // Обработчики
  const handleCancelButtonClick = () => {
    switch (formStep) {
      case ProductFormSteps.BASE:
        return onClose();
      case ProductFormSteps.CHARACTERISTICS:
        return setFormStep(ProductFormSteps.BASE);
    }
  };

  const handlePrimaryButtonClick = () => {
    switch (formStep) {
      case ProductFormSteps.BASE:
        return setFormStep(ProductFormSteps.CHARACTERISTICS);
      case ProductFormSteps.CHARACTERISTICS:
        return handleSubmit();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleCharacteristicsChange =
    (key: keyof ProductCharacteristics) => (value: ProductCharacteristicItem | null) => {
      setCharacteristicFormState((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleBasicChange = (key: keyof EditProductDTO) => (value: string | null) => {
    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = () => {
    const cellPurpose = cellPurposeList.find(
      ({ id }) => id === characteristicFormState.cellPurpose?.id,
    );

    const { tasted, sportPit } = cellPurpose || { tasted: false, sportPit: false };

    const characteristics: EditProductCharacteristicsDTO = {
      cellCategoryId: characteristicFormState.cellCategory?.id,
      cellPurposeId: characteristicFormState.cellPurpose?.id,
      tasteId: tasted ? characteristicFormState.taste?.id : undefined,
      viewId: sportPit ? characteristicFormState.view?.id : undefined,
    };

    const data: CreateProductDTO = {
      ...basicFormState,
      ...characteristics,
      ingredientLineId: productLineId,
      organizationId: null,
    };

    dispatch(createProductAction(data, productLineId, organizationId)).then(() => {
      handleClose();
      setBasicFormState(createProductBasicInitialValue);
      setCharacteristicFormState(creteProductCharacteristicsInitialValue);
      setFormStep(ProductFormSteps.BASE);
    });
  };

  // render методы
  const renderActions = () => (
    <>
      <Button label={getCancelButtonLabel()} view="clear" onClick={handleCancelButtonClick} />
      <Button label={getPrimaryButtonLabel()} onClick={handlePrimaryButtonClick} />
    </>
  );

  const renderContent = () => {
    switch (formStep) {
      case ProductFormSteps.BASE:
        return <ProductBasicForm value={basicFormState} onChange={handleBasicChange} />;
      case ProductFormSteps.CHARACTERISTICS:
        return (
          <ProductCharacteristicsForm
            characteristics={characteristicFormState}
            cellCategoryList={cellCategoryList}
            cellPurposeList={cellPurposeList}
            sportPitList={sportPitList}
            tasteList={tasteList}
            onChange={handleCharacteristicsChange}
          />
        );
    }
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={t('productBase.product.form.create.modal.title')}
      renderActions={renderActions}
      onClose={handleClose}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default CreateProductForm;
