import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import styles from './MachineHomaPageLogs.module.scss';
import { LogMachines, LogMachinesTypeEnum } from '../../../../../types/serverInterface/machineDTO';
import { IconTrash } from '@consta/uikit/IconTrash';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { IconCheck } from '../../../../../assets/icon/iconCheck';
import { IconWarning } from '../../../../../assets/icon/iconWarning';
import { IconAlert } from '../../../../../assets/icon/iconAlert';
import { DisplayType, displayTypeValue } from '../../../../App';
import classNames from 'classnames';

const mockLogs: LogMachines[] = [
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.SUCCESS,
    description: 'Пополнения остатков',
    name: 'Автомат обслужен',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.WARNING,
    description: 'Заменить бутылку с водой',
    name: 'Требует обслуживания',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.ALERT,
    description: 'Error.SnackDoesntAnswer',
    name: 'Ошибка',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.SUCCESS,
    description: 'Пополнения остатков',
    name: 'Автомат обслужен',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.WARNING,
    description: 'Заменить бутылку с водой',
    name: 'Требует обслуживания',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.ALERT,
    description: 'Error.SnackDoesntAnswer',
    name: 'Ошибка',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.SUCCESS,
    description: 'Пополнения остатков',
    name: 'Автомат обслужен',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.WARNING,
    description: 'Заменить бутылку с водой',
    name: 'Требует обслуживания',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.ALERT,
    description: 'Error.SnackDoesntAnswer',
    name: 'Ошибка',
  },
];

/**
 * Свойства компонента MachineHomaPageLogs
 */
type MachineHomaPageLogsProps = {
  /**
   * Сообщения автомата
   */
  logs: LogMachines[];
};

/**
 * Сообщения автомата
 */
const MachineHomaPageLogs: FC<MachineHomaPageLogsProps> = ({ logs = mockLogs }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  // Вспомогательные методы
  const getIcon = (type: LogMachinesTypeEnum) => {
    switch (type) {
      case LogMachinesTypeEnum.SUCCESS:
        return <IconCheck size="m" className={styles.successIcon} {...defaultIconProps} />;
      case LogMachinesTypeEnum.WARNING:
        return <IconWarning size="m" className={styles.warningIcon} {...defaultIconProps} />;
      case LogMachinesTypeEnum.ALERT:
        return <IconAlert size="m" className={styles.alertIcon} {...defaultIconProps} />;
      default:
        return <IconTrash size="m" {...defaultIconProps} />;
    }
  };

  // render методы
  const renderLog = (log: LogMachines) => {
    const { description, date: logDate, type, name } = log;
    const [date, time] = logDate.split(' ');

    return (
      <HorizontalContainer className={styles.logCard} isAutoSpace align="center">
        <HorizontalContainer space={isLaptop ? 's' : 'm'} align="center">
          {getIcon(type)}
          <VerticalContainer space="3xs">
            <Text className={styles.text} size={isLaptop ? 'm' : 'l'} weight="semibold">
              {name}
            </Text>
            <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
              {description}
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <VerticalContainer align="end" space="2xs">
          <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
            {date}
          </Text>
          <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
            {time}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
    );
  };

  return (
    <ContentCard className={classNames(styles.MachineHomaPageLogs, isLaptop && styles.laptop)}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        Сообщения от автомата
      </Text>
      {logs?.map((log) => renderLog(log))}
    </ContentCard>
  );
};

export default MachineHomaPageLogs;
