import * as Yup from 'yup';
import { CreateCellViewDTO } from '../../../../types/serverInterface/cellViewDTO';

/**
 * Схема валидации создания вида спортивного питания
 */
export const createSportPitValidationScheme: Yup.ObjectSchema<CreateCellViewDTO> = Yup.object({
  name: Yup.string().required(),
  mediaKey: Yup.string().required(),
});
