import {
  CustomFormItem,
  CustomFormItemType,
  CustomFormType,
} from '../../types/serverInterface/customForm';
import { FieldError } from '../../types/types';
import { requiredValidate } from '../../helpers/validateHelpers';

/**
 * Ошибка для элемента кастомной формы
 */
export type ErrorCustomForm = {
  /**
   * Название
   */
  name: FieldError;
  /**
   * Описание
   */
  description: FieldError;
  /**
   * Ключь для автомата
   */
  key: FieldError;
  /**
   * Тип поля
   */
  type: FieldError;
  /**
   * Единицы измерения для поля типа number
   */
  unit?: FieldError;
  /**
   * Дефолтное значение поля с типом boolean
   */
  defaultValue: FieldError;
};

/**
 * Ошибка кастомной формы
 */
export type ErrorsCustomFormList = Array<ErrorCustomForm>;

/**
 * Дефолтное значение ошибки
 */
export const errorInitialValue: FieldError = {
  isError: false,
  label: '',
  status: undefined,
};

/**
 * Функция валидации кастомной формы
 *
 * @param data кастомная форма
 */
export const customFormValidation = (
  data: CustomFormType,
): { data: ErrorsCustomFormList; isError: boolean } => {
  let isError = false;

  const getItemErrors = (item: CustomFormItem): ErrorCustomForm => {
    const { name, key, type, unit, value } = item;

    const nameError: FieldError = requiredValidate(name);
    isError = isError || nameError.isError;

    // Валидация не требуется, не является обязательным полем
    const descriptionError: FieldError = {
      isError: false,
      label: '',
      status: 'success' as const,
    };
    isError = isError || descriptionError.isError;

    const keyError: FieldError = requiredValidate(key);
    isError = isError || keyError.isError;

    const typeError: FieldError = requiredValidate(type);
    isError = isError || typeError.isError;

    const unitError: FieldError =
      type === CustomFormItemType.NUMBER
        ? requiredValidate(String(unit || ''))
        : {
            isError: false,
            label: '',
            status: 'success' as const,
          };
    isError = isError || unitError.isError;

    const defaultValueError: FieldError = requiredValidate(value);
    isError = isError || defaultValueError.isError;

    return {
      name: nameError,
      description: descriptionError,
      key: keyError,
      type: typeError,
      unit: unitError,
      defaultValue: defaultValueError,
    };
  };

  return { data: data?.map(getItemErrors), isError };
};
