import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconAddSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M6.375 2C6.375 1.79289 6.20711 1.625 6 1.625C5.79289 1.625 5.625 1.79289 5.625 2L5.625 5.625H2C1.79289 5.625 1.625 5.79289 1.625 6C1.625 6.20711 1.79289 6.375 2 6.375H5.625V10C5.625 10.2071 5.79289 10.375 6 10.375C6.20711 10.375 6.375 10.2071 6.375 10V6.375H10C10.2071 6.375 10.375 6.20711 10.375 6C10.375 5.79289 10.2071 5.625 10 5.625H6.375L6.375 2Z" />
  </svg>
);

const IconAddSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M8.49999 2.66675C8.49999 2.39061 8.27613 2.16675 7.99999 2.16675C7.72385 2.16675 7.49999 2.39061 7.49999 2.66675L7.49999 7.50008H2.66666C2.39051 7.50008 2.16666 7.72394 2.16666 8.00008C2.16666 8.27622 2.39051 8.50008 2.66666 8.50008H7.49999V13.3334C7.49999 13.6096 7.72385 13.8334 7.99999 13.8334C8.27613 13.8334 8.49999 13.6096 8.49999 13.3334V8.50008H13.3333C13.6095 8.50008 13.8333 8.27622 13.8333 8.00008C13.8333 7.72394 13.6095 7.50008 13.3333 7.50008H8.49999L8.49999 2.66675Z" />
  </svg>
);

const IconAddSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4L11.25 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H11.25V20C11.25 20.4142 11.5858 20.75 12 20.75C12.4142 20.75 12.75 20.4142 12.75 20V12.75H20C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25H12.75L12.75 4Z" />
  </svg>
);

export const IconAdd = createIcon({
  name: 'IconAdd',
  xs: IconAddSizeXS,
  s: IconAddSizeS,
  m: IconAddSizeM,
});
