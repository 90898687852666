import { FC, useEffect, useState } from 'react';
import MachineFillingInfoTable from './MachineFillingInfoTable';
import { SeparatedMachineStorageType } from '../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../types/serverInterface/storageDTO';
import MachineFillingInfoScheme from './MachineFillingInfoScheme';
import styles from './MachineFillingInfo.module.scss';
import { DisplayType, displayTypeValue } from '../../../../App';
import classNames from 'classnames';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import {
  MachineCellGroup,
  MachineCellGroupUnion,
} from '../../../../../types/serverInterface/machineDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { MachineStoragePageType } from '../MachineFilling';
import HorizontalContainer from '../../../../../components/HorizontalContainer';

const getGroup = (
  group: MachineCellGroupUnion,
): keyof Omit<SeparatedMachineStorageType, 'cells'> => {
  switch (group) {
    case MachineCellGroup.WATER:
      return 'cellWaters';
    case MachineCellGroup.CUP:
      return 'cellCups';
    default:
      return 'cellDisposables';
  }
};

/**
 * Дефолтное значение склада автомата
 */
const initialStorage: SeparatedMachineStorageType = {
  cells: {
    [ProductGroup.COFFEE]: [],
    [ProductGroup.CONCENTRATE]: [],
    [ProductGroup.POWDER]: [],
  },
  cellWaters: [],
  cellCups: [],
  cellDisposables: [],
};

/**
 * Свойства компонента MachineFillingInfo
 */
type MachineFillingInfoProps = {
  /**
   * Склад автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  onPageTypeChange: (newPageType: MachineStoragePageType) => void;
  onIsShowTabsChange: (isShow: boolean) => void;
};

/**
 * Информация о наполнении
 */
const MachineFillingInfo: FC<MachineFillingInfoProps> = ({
  storage,
  sortedGroup,
  onPageTypeChange,
  onIsShowTabsChange,
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const [machineStorageArr, setMachineStorageArr] = useState({
    ...initialStorage,
  });

  useEffect(() => {
    setMachineStorageArr(storage);
  }, [storage]);

  // Обработчики
  const handleHover = (group: MachineCellGroupUnion) => (index: number) => {
    if (
      group === MachineCellGroup.CUP ||
      group == MachineCellGroup.DISPOSABLE ||
      group === MachineCellGroup.WATER
    ) {
      const formatGroup = getGroup(group);

      return setMachineStorageArr((prevState) => ({
        ...prevState,
        [formatGroup]: [
          ...prevState[formatGroup].slice(0, index),
          { ...prevState[formatGroup][index], isHoverStatus: true },
          ...prevState[formatGroup].slice(index + 1),
        ],
      }));
    }

    if (
      group === ProductGroup.COFFEE ||
      group == ProductGroup.CONCENTRATE ||
      group === ProductGroup.POWDER
    ) {
      setMachineStorageArr((prevState) => ({
        ...prevState,
        cells: {
          ...prevState.cells,
          [group]: [
            ...prevState.cells[group].slice(0, index),
            { ...prevState.cells[group][index], isHoverStatus: true },
            ...prevState.cells[group].slice(index + 1),
          ],
        },
      }));
    }
  };

  const handleHoverLeave = (group: MachineCellGroupUnion) => (index: number) => {
    if (
      group === MachineCellGroup.CUP ||
      group == MachineCellGroup.DISPOSABLE ||
      group === MachineCellGroup.WATER
    ) {
      const formatGroup = getGroup(group);

      return setMachineStorageArr((prevState) => ({
        ...prevState,
        [formatGroup]: [
          ...prevState[formatGroup].slice(0, index),
          { ...prevState[formatGroup][index], isHoverStatus: false },
          ...prevState[formatGroup].slice(index + 1),
        ],
      }));
    }

    if (
      group === ProductGroup.COFFEE ||
      group == ProductGroup.CONCENTRATE ||
      group === ProductGroup.POWDER
    ) {
      setMachineStorageArr((prevState) => ({
        ...prevState,
        cells: {
          ...prevState.cells,
          [group]: [
            ...prevState.cells[group].slice(0, index),
            { ...prevState.cells[group][index], isHoverStatus: false },
            ...prevState.cells[group].slice(index + 1),
          ],
        },
      }));
    }
  };

  // render методы
  const renderActions = () => (
    <HorizontalContainer className={styles.actions}>
      <Button
        label="Пополнить"
        view="secondary"
        onClick={() => {
          onPageTypeChange(MachineStoragePageType.REFILL);
          onIsShowTabsChange(false);
        }}
      />
      <Button
        label="Изменить"
        onClick={() => {
          onPageTypeChange(MachineStoragePageType.EDIT);
          onIsShowTabsChange(false);
        }}
      />
    </HorizontalContainer>
  );

  return (
    <div className={classNames(styles.MachineFillingInfo, isLaptop && styles.laptop)}>
      <VerticalContainer className={styles.leftSide} space={isLaptop ? 's' : 'l'}>
        <Text weight="semibold" view="secondary" size={isLaptop ? 'l' : 'xl'}>
          Наполнение
        </Text>
        <MachineFillingInfoTable
          storage={machineStorageArr}
          sortedGroup={sortedGroup}
          onHover={handleHover}
          onHoverLeave={handleHoverLeave}
        />
      </VerticalContainer>
      <MachineFillingInfoScheme
        className={styles.rightSide}
        storage={machineStorageArr}
        sortedGroup={sortedGroup}
        onHover={handleHover}
        onHoverLeave={handleHoverLeave}
      />
      {renderActions()}
    </div>
  );
};

export default MachineFillingInfo;
