import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconQRSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M2.48438 5.25781V2.875C2.48438 2.65924 2.65924 2.48438 2.875 2.48438H5.25781C5.47357 2.48438 5.64844 2.65924 5.64844 2.875C5.64844 3.09076 5.47357 3.26562 5.25781 3.26562H3.26562V5.25781C3.26562 5.47357 3.09076 5.64844 2.875 5.64844C2.65924 5.64844 2.48438 5.47357 2.48438 5.25781ZM6.35156 5.25781V2.875C6.35156 2.65924 6.52643 2.48438 6.74219 2.48438H9.125C9.34076 2.48438 9.51562 2.65924 9.51562 2.875V5.25781C9.51562 5.47357 9.34076 5.64844 9.125 5.64844H6.74219C6.52643 5.64844 6.35156 5.47357 6.35156 5.25781ZM7.13281 4.86719H8.73438V3.26562H7.13281V4.86719ZM11 1.39062V10.6094C11 10.8251 10.8251 11 10.6094 11H1.39062C1.17487 11 1 10.8251 1 10.6094V1.39062C1 1.17487 1.17487 1 1.39062 1H10.6094C10.8251 1 11 1.17487 11 1.39062ZM10.2188 1.78125H1.78125V10.2188H10.2188V1.78125ZM2.48438 9.125V6.74219C2.48438 6.52643 2.65924 6.35156 2.875 6.35156H5.25781C5.47357 6.35156 5.64844 6.52643 5.64844 6.74219V8.73438H6.74219C6.95795 8.73438 7.13281 8.90924 7.13281 9.125C7.13281 9.34076 6.95795 9.51562 6.74219 9.51562C6.64468 9.51562 2.97563 9.51562 2.875 9.51562C2.65924 9.51562 2.48438 9.34076 2.48438 9.125ZM3.26562 8.73438H4.86719V7.13281H3.26562V8.73438ZM8.63672 8.14844C8.36702 8.14844 8.14844 8.36702 8.14844 8.63672C8.14844 8.90642 8.36702 9.125 8.63672 9.125C8.90642 9.125 9.125 8.90642 9.125 8.63672C9.125 8.36702 8.90642 8.14844 8.63672 8.14844ZM9.125 6.35156H7.64062C7.42487 6.35156 7.25 6.52643 7.25 6.74219C7.25 6.95795 7.42487 7.13281 7.64062 7.13281H9.125C9.34076 7.13281 9.51562 6.95795 9.51562 6.74219C9.51562 6.52643 9.34076 6.35156 9.125 6.35156Z" />
  </svg>
);

const IconQRSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M3.07812 5.96094V2.625C3.07812 2.32294 3.32294 2.07812 3.625 2.07812H6.96094C7.263 2.07812 7.50781 2.32294 7.50781 2.625C7.50781 2.92706 7.263 3.17188 6.96094 3.17188H4.17188V5.96094C4.17188 6.263 3.92706 6.50781 3.625 6.50781C3.32294 6.50781 3.07812 6.263 3.07812 5.96094ZM8.49219 5.96094V2.625C8.49219 2.32294 8.737 2.07812 9.03906 2.07812H12.375C12.6771 2.07812 12.9219 2.32294 12.9219 2.625V5.96094C12.9219 6.263 12.6771 6.50781 12.375 6.50781H9.03906C8.737 6.50781 8.49219 6.263 8.49219 5.96094ZM9.58594 5.41406H11.8281V3.17188H9.58594V5.41406ZM15 0.546875V13.4531C15 13.7552 14.7552 14 14.4531 14H1.54688C1.24481 14 1 13.7552 1 13.4531V0.546875C1 0.244812 1.24481 0 1.54688 0H14.4531C14.7552 0 15 0.244812 15 0.546875ZM13.9062 1.09375H2.09375V12.9062H13.9062V1.09375ZM3.07812 11.375V8.03906C3.07812 7.737 3.32294 7.49219 3.625 7.49219H6.96094C7.263 7.49219 7.50781 7.737 7.50781 8.03906V10.8281H9.03906C9.34113 10.8281 9.58594 11.0729 9.58594 11.375C9.58594 11.6771 9.34113 11.9219 9.03906 11.9219C8.90256 11.9219 3.76588 11.9219 3.625 11.9219C3.32294 11.9219 3.07812 11.6771 3.07812 11.375ZM4.17188 10.8281H6.41406V8.58594H4.17188V10.8281ZM11.6914 10.0078C11.3138 10.0078 11.0078 10.3138 11.0078 10.6914C11.0078 11.069 11.3138 11.375 11.6914 11.375C12.069 11.375 12.375 11.069 12.375 10.6914C12.375 10.3138 12.069 10.0078 11.6914 10.0078ZM12.375 7.49219H10.2969C9.99481 7.49219 9.75 7.737 9.75 8.03906C9.75 8.34113 9.99481 8.58594 10.2969 8.58594H12.375C12.6771 8.58594 12.9219 8.34113 12.9219 8.03906C12.9219 7.737 12.6771 7.49219 12.375 7.49219Z" />
  </svg>
);

const IconQRSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M4.96875 10.5156V5.75C4.96875 5.31848 5.31848 4.96875 5.75 4.96875H10.5156C10.9471 4.96875 11.2969 5.31848 11.2969 5.75C11.2969 6.18152 10.9471 6.53125 10.5156 6.53125H6.53125V10.5156C6.53125 10.9471 6.18152 11.2969 5.75 11.2969C5.31848 11.2969 4.96875 10.9471 4.96875 10.5156ZM12.7031 10.5156V5.75C12.7031 5.31848 13.0529 4.96875 13.4844 4.96875H18.25C18.6815 4.96875 19.0312 5.31848 19.0312 5.75V10.5156C19.0312 10.9471 18.6815 11.2969 18.25 11.2969H13.4844C13.0529 11.2969 12.7031 10.9471 12.7031 10.5156ZM14.2656 9.73438H17.4688V6.53125H14.2656V9.73438ZM22 2.78125V21.2188C22 21.6503 21.6503 22 21.2188 22H2.78125C2.34973 22 2 21.6503 2 21.2188V2.78125C2 2.34973 2.34973 2 2.78125 2H21.2188C21.6503 2 22 2.34973 22 2.78125ZM20.4375 3.5625H3.5625V20.4375H20.4375V3.5625ZM4.96875 18.25V13.4844C4.96875 13.0529 5.31848 12.7031 5.75 12.7031H10.5156C10.9471 12.7031 11.2969 13.0529 11.2969 13.4844V17.4688H13.4844C13.9159 17.4688 14.2656 17.8185 14.2656 18.25C14.2656 18.6815 13.9159 19.0312 13.4844 19.0312C13.2894 19.0312 5.95126 19.0312 5.75 19.0312C5.31848 19.0312 4.96875 18.6815 4.96875 18.25ZM6.53125 17.4688H9.73438V14.2656H6.53125V17.4688ZM17.2734 16.2969C16.734 16.2969 16.2969 16.734 16.2969 17.2734C16.2969 17.8128 16.734 18.25 17.2734 18.25C17.8128 18.25 18.25 17.8128 18.25 17.2734C18.25 16.734 17.8128 16.2969 17.2734 16.2969ZM18.25 12.7031H15.2812C14.8497 12.7031 14.5 13.0529 14.5 13.4844C14.5 13.9159 14.8497 14.2656 15.2812 14.2656H18.25C18.6815 14.2656 19.0312 13.9159 19.0312 13.4844C19.0312 13.0529 18.6815 12.7031 18.25 12.7031Z" />
  </svg>
);

export const IconQR = createIcon({
  name: 'IconQR',
  xs: IconQRSizeXS,
  s: IconQRSizeS,
  m: IconQRSizeM,
});
