import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconLogoFullSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="295" height="187" viewBox="0 0 295 187" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_34721_278)">
      <path d="M284.2 145.3H276.7L276.2 147.6H278.6L277.4 153.7H280.1L281.4 147.6H283.8L284.2 145.3Z" />
      <path d="M291.8 145.3L288.5 149.6L286.7 145.3H284.5L282.9 153.7H285.4L286.1 150.2L287.3 152.9H288.6L290.6 150.3L289.9 153.7H292.5L294.2 145.3H291.8Z" />
      <path d="M189.6 170.2H219.6L225.7 152.4C226.3 150.6 226 148.8 225 147.2C223.9 145.7 222.2 144.8 220.4 144.8H193C190.6 144.8 188.4 146.3 187.6 148.6L177.3 178.6C176.7 180.4 177 182.2 178 183.8C179.1 185.3 180.8 186.2 182.6 186.2H214.1L217.3 176.9H189.9C189.3 176.9 188.7 176.6 188.4 176.1C188 175.6 188 175 188.2 174.4L189.6 170.2ZM192.8 160.9L194.7 155.4C195 154.6 195.7 154.1 196.5 154.1H212.9C213.5 154.1 214.1 154.4 214.4 154.9C214.8 155.4 214.8 156 214.7 156.6L213.3 160.9H192.8Z" />
      <path d="M88.7 144.8L83.1 160.9H62.7L68.2 144.8H58.7L44.4 186.2H53.9L59.5 170.2H79.9L74.4 186.2H83.9L98.2 144.8H88.7Z" />
      <path d="M139.1 147.2C138 145.7 136.3 144.8 134.5 144.8H103.1L99.9 154.1H127.1C127.7 154.1 128.3 154.4 128.6 154.9C129 155.4 129 156 128.8 156.6L127.3 160.9H101.5C99.1 160.9 96.9 162.4 96.1 164.7L91.3 178.6C90.7 180.4 91 182.2 92 183.8C93.1 185.3 94.8 186.2 96.6 186.2H128L139.6 152.3C140.5 150.6 140.2 148.7 139.1 147.2ZM121.9 176.9H102.4C102.1 176.9 102 176.8 101.9 176.7C101.8 176.5 101.8 176.3 101.8 176.1L103.4 171.4C103.7 170.6 104.4 170.1 105.2 170.1H124.1L121.9 176.9Z" />
      <path d="M45.2 163.4C44.1 161.8 42 160.8 39.7 160.8H19.1C18.9 160.8 18.7 160.7 18.6 160.5C18.5 160.3 18.5 160.1 18.5 159.9L20.1 155.2C20.4 154.4 21.1 153.9 21.9 153.9H50.5L53.7 144.6H19.2C16.2 144.6 13.6 146.5 12.6 149.3L8.1 162.5C7.5 164.3 7.8 166.1 8.9 167.7C10 169.2 11.7 170.1 13.5 170.1H34.6C34.8 170.1 35 170.2 35.1 170.4C35.2 170.6 35.2 170.8 35.2 171L33.6 175.6C33.3 176.3 32.6 176.9 31.8 176.9H3.2L0 186.2H35C37.7 186.2 40 184.6 40.9 182.1C40.9 182 44.6 171.4 45.8 167.9C46.4 166.4 46.2 164.8 45.2 163.4Z" />
      <path d="M264.7 144.8H233.3L219 186.2H228.5L234 170.2H252.3C252.8 170.2 253.2 170.4 253.5 170.8C253.8 171.2 253.9 171.7 253.7 172.2L248.9 186.2H258.4L263.4 171.6C263.9 170.3 263.7 168.9 263.1 167.7C262.8 167.1 262.4 166.7 262 166.3C265.4 165.5 266.3 163 266.4 162.8L270 152.4C270.6 150.6 270.3 148.8 269.3 147.2C268.2 145.6 266.6 144.8 264.7 144.8ZM256.3 160.8H237.4L239.7 154.1H259.2C259.5 154.1 259.6 154.2 259.7 154.3C259.8 154.5 259.8 154.7 259.8 154.9L258.2 159.6C257.8 160.3 257.1 160.8 256.3 160.8Z" />
      <path d="M177.6 162.9L184 144.8H174.4L169.2 159.5C168.9 160.3 168.2 160.8 167.4 160.8H151.3L156.8 144.8H147.3L133 186.2H142.5L148 170.2H163.4C163.9 170.2 164.3 170.4 164.6 170.8C164.9 171.2 165 171.7 164.8 172.2L160 186.2H169.5L174.5 171.6C175 170.3 174.8 168.9 174.2 167.7C173.9 167.2 173.5 166.7 173.1 166.3C176.7 165.5 177.6 163 177.6 162.9Z" />
      <path d="M210.4 55.5C210.4 56.4 210.2 57.2 209.8 58L209.7 58.2L180.4 108.9C179.6 110 178.3 110.8 176.8 111H117.7C116.1 110.9 114.7 110.1 113.8 108.8L105.9 95.1H166.4C166.6 95.1 167.4 95.1 167.5 95.1C169 94.9 170.3 94.1 171.2 92.9C171.4 92.6 190.4 59.6 190.4 59.6L190.6 59.2C191.2 58.1 191.6 56.8 191.6 55.4C191.6 54 191.3 52.8 190.6 51.6L190.4 51.2C189 48.9 186.5 47.4 183.7 47.4H128.8C127.3 47.4 126.1 46.2 126.1 44.7C126.1 44.3 126.2 43.8 126.4 43.5L131.9 34C132.8 32.6 134.4 31.6 136.3 31.6H194.4C196.3 31.6 198 32.6 198.9 34.1L209.8 53C210.1 53.8 210.4 54.6 210.4 55.5Z" />
      <path d="M167.7 67.4C167.9 67 168 66.6 168 66.1C168 64.6 166.8 63.4 165.3 63.4H110.5C107.6 63.4 105.1 61.9 103.7 59.6C103.6 59.5 102.6 57.5 102.6 55.5C102.6 54.1 102.9 52.9 103.5 51.8C103.6 51.7 122.8 18.4 122.8 18.3C123.2 17.6 123.8 17.1 124.5 16.6C125.3 16.1 126.3 15.8 126.8 15.8C130.2 15.8 155.6 15.8 155.6 15.8H188.3L180.6 2.5C179.7 1.1 178.2 0.1 176.4 0H117.8C116.1 0.1 114.6 1 113.7 2.3L113.3 3L84.4 53C84 53.7 83.8 54.6 83.8 55.5C83.8 56.4 84 57.2 84.4 58L95.4 77C96.3 78.4 97.9 79.3 99.7 79.3H157.7H157.8C159.7 79.3 161.4 78.3 162.3 76.8L167.7 67.4Z" />
    </g>
    <defs>
      <clipPath id="clip0_34721_278">
        <rect width="294.2" height="186.2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconLogoFull = createIcon({
  name: 'IconLogoFull',
  xs: IconLogoFullSizeM,
  s: IconLogoFullSizeM,
  m: IconLogoFullSizeM,
});
