import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectCellViewList } from '../../../state/productBase/selectors';
import { deleteSportPitAction, getCellViewListAction } from '../../../state/productBase/actions';
import styles from './SportPit.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Loader } from '@consta/uikit/Loader';
import TableCard from '../../../components/TableCard';
import { CellViewDTO } from '../../../types/serverInterface/cellViewDTO';
import EditSportPit from './SportPitForm/EditSportPit';
import DeleteSportPit from './DeleteSportPit';
import SportPitForm from './SportPitForm';
import { useTranslation } from 'react-i18next';
import SportPitLocale from './SportPitLocale';

/**
 * Базовый компонент вида спортивного питания
 */
const SportPit: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const sportPitList = useAppSelector(selectCellViewList());

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [editableId, setEditableId] = useState<number | null>(null);
  const [deletableId, setDeletableId] = useState<number | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [localeId, setLocaleId] = useState<number | null>(null);

  const filterSportPit = useMemo(() => {
    return sportPitList.filter((sportPit) =>
      sportPit.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, sportPitList]);

  useEffect(() => {
    dispatch(getCellViewListAction()).finally(() => setIsLoading(false));
  }, [dispatch]);

  // Обработчики
  const handleAddButtonClick = () => {
    setIsCreateOpen(true);
  };

  const handleAddClose = () => {
    setIsCreateOpen(false);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleEditClick = (id: number) => () => {
    setEditableId(id);
  };

  const handleEditClose = () => {
    setEditableId(null);
  };

  const handleDeleteClick = (id: number) => () => {
    setDeletableId(id);
  };

  const handleCancelDeleteClick = () => {
    setDeletableId(null);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletableId &&
      dispatch(deleteSportPitAction(deletableId)).finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const handleLocaleClick = (id: number) => () => {
    setLocaleId(id);
  };

  const handleLocaleClose = () => {
    setLocaleId(null);
  };

  // render методы
  const renderAction = () => (
    <div className={styles.actions}>
      <Button
        label={t('productBaseConfig.sportPit.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddButtonClick}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
  );

  const renderRowAction = ({ id }: CellViewDTO) => (
    <div className={styles.rowActions}>
      <Button view="ghost" iconLeft={IconWorldStroked as any} onClick={handleLocaleClick(id)} />
      <Button view="ghost" iconLeft={IconEdit as any} onClick={handleEditClick(id)} />
      <Button view="ghost" iconLeft={IconTrash as any} onClick={handleDeleteClick(id)} />
    </div>
  );

  const renderCreateForm = () => (
    <SportPitForm type="create" isOpen={isCreateOpen} onClose={handleAddClose} />
  );

  const renderEditForm = () =>
    editableId && <EditSportPit sportPitId={editableId} onClose={handleEditClose} />;

  const renderDeleteModal = () =>
    deletableId && (
      <DeleteSportPit
        sportPitId={deletableId}
        isDeleteLoading={isDeleteLoading}
        onClose={handleCancelDeleteClick}
        onDelete={handleDelete}
      />
    );

  const renderLocale = () =>
    localeId && (
      <SportPitLocale isOpen={!!localeId} sportPitId={localeId} onClose={handleLocaleClose} />
    );

  if (isLoading) return <Loader />;

  return (
    <div>
      {renderCreateForm()}
      {renderEditForm()}
      {renderDeleteModal()}
      {renderLocale()}
      {renderAction()}
      <TableCard
        rows={filterSportPit}
        columns={{
          name: {
            key: 'name',
            title: t('productBaseConfig.sportPit.name.table.column.title'),
            alignment: 'left',
            getItemLabel: (data) => data.name,
          },
          id: {
            key: 'id',
            title: t('productBaseConfig.sportPit.action.table.column.title'),
            alignment: 'right',
            renderCell: renderRowAction,
          },
        }}
      />
    </div>
  );
};

export default SportPit;
