import { FC, useState } from 'react';
import { CreateCupModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import DefaultInfo from '../../../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../../../components/DefaultInfoItem';
import { Collapse } from '@consta/uikit/Collapse';
import { useTranslation } from 'react-i18next';

/**
 * Свойства комопнента CupCellModelInfo
 */
type CupCellModelInfoProps = {
  /**
   * Ячейка стакана
   */
  cell: CreateCupModelCell;
};

/**
 * Информация о ячейке со стаканами модели автомата
 */
const CupCellModelInfo: FC<CupCellModelInfoProps> = ({ cell }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleCollapseClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Collapse
      isOpen={isOpen}
      iconPosition="right"
      label={t('machineControl.model.info.cells.cup.collapse.label', {
        cupVolume: cell.cupVolume,
        cellNumber: cell.cellNumber,
      })}
      onClick={handleCollapseClick}
    >
      <DefaultInfo>
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.cup.cellNumber.item.label'),
            value: String(cell.cellNumber),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.cup.isCount.item.label'),
            value: t(`machineControl.model.info.cells.cup.isCount.${String(cell.isCount)}`),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.cup.cupVolume.item.label'),
            value: String(cell.cupVolume),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.cup.maxVolume.item.label'),
            value: String(cell.maxVolume),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.cup.minVolume.item.label'),
            value: String(cell.minVolume),
          }}
        />
      </DefaultInfo>
    </Collapse>
  );
};

export default CupCellModelInfo;
