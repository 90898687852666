import { FC, useRef, useState } from 'react';
import styles from './UserSetting.module.scss';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectCabinet } from '../../../state/user/selectors';
import classNames from 'classnames';
import { cnMixFocus } from '@consta/uikit/MixFocus';
import ContextModal from '../../ContextModal';
import VerticalContainer from '../../VerticalContainer';
import { selectOrganization } from '../../../state/organization/selectors';
import ThemeMenuItem from './ThemeMenuItem';
import MenuItem from './MenuItem';
import HorizontalContainer from '../../HorizontalContainer';
import { ListDivider } from '@consta/uikit/ListCanary';
import { useNavigate } from 'react-router-dom';
import { ThemeName } from '../TopMenu';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { IconSignOut } from '../../../assets/icon/iconSignOut';
import { DisplayType, displayTypeValue } from '../../../pages/App';
import { logoutAction } from '../../../state/organization/actions';
import { IconProfileCircle } from '../../../assets/icon/iconProfileCircle';

/**
 * Свойства компонентов UserSetting
 */
type UserSettingProps = {
  /**
   * Выбранная тема
   */
  theme: ThemeName;
  /**
   * Обработчик изменения темы
   *
   * @param value новое значение темы
   */
  onThemeChange: ({ value }: { value: ThemeName }) => void;
};

/**
 * Меню настройки пользователя для верхнего меню
 */
const UserSetting: FC<UserSettingProps> = ({ theme, onThemeChange }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const user = useAppSelector(selectCabinet());
  const organization = useAppSelector(selectOrganization());

  if (!user) return <Button label="Выйти" onClick={() => dispatch(logoutAction())} />;

  const userLabel = `${user.surname} ${user.name}`;

  // Обработчики
  const handleOpenClick = () => {
    setIsOpenMenu(true);
  };

  const handleClose = () => {
    setIsOpenMenu(false);
  };

  const logOut = () => {
    dispatch(logoutAction());
  };

  //render методы
  const renderOrganizationTag = () =>
    organization && (
      <div className={styles.organizationTag}>
        <Avatar name={organization.name} />
        <Text size="s" className={styles.text}>
          {organization.name}
        </Text>
      </div>
    );

  const renderDropDown = () => (
    <ContextModal
      className={styles.dropDownMenu}
      currentRef={ref}
      isOpen={isOpenMenu}
      align="center"
      onClickOutside={handleClose}
    >
      <VerticalContainer className={styles.dropDownMenuContainer} space="2xl">
        <VerticalContainer space="xs" align="center">
          <Avatar className={styles.avatarContainer} name={userLabel} />
          <Text size="l">{userLabel}</Text>
          {renderOrganizationTag()}
        </VerticalContainer>
        <VerticalContainer space={0}>
          <MenuItem
            label="Личный кабинет"
            iconLeft={<IconProfileCircle size="m" {...defaultIconProps} />}
            onClick={() => navigate('/cabinet')}
          />
          <ThemeMenuItem theme={theme} onThemeChange={onThemeChange} />
          {/*<LocaleMenuItem />*/}
          <ListDivider {...defaultIconProps} />
          <MenuItem
            label="Выйти"
            iconLeft={<IconSignOut size="m" {...defaultIconProps} />}
            onClick={logOut}
          />
        </VerticalContainer>
      </VerticalContainer>
    </ContextModal>
  );

  return (
    <>
      <button
        ref={ref}
        className={classNames(
          styles.UserSetting,
          isOpenMenu && styles.isOpenMenu,
          cnMixFocus(),
          isLaptop && styles.isLaptop,
        )}
        onClick={handleOpenClick}
      >
        <HorizontalContainer space="s" isAutoSpace align="center">
          <HorizontalContainer space="s" align="center">
            <Avatar className={styles.avatar} name={userLabel} />
            <Text className={styles.text} size={isLaptop ? 'm' : 'l'} weight="regular">
              {userLabel}
            </Text>
          </HorizontalContainer>
          <IconArrowDown {...defaultIconProps} className={styles.icon} />
        </HorizontalContainer>
      </button>
      {renderDropDown()}
    </>
  );
};

export default UserSetting;
