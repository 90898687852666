import { FC, useState } from 'react';
import { CreateDisposableModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import DefaultInfo from '../../../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../../../components/DefaultInfoItem';
import { Collapse } from '@consta/uikit/Collapse';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DisposableCellModelInfo
 */
type DisposableCellModelInfoProps = {
  /**
   * Ячейка расходника
   */
  cell: CreateDisposableModelCell;
};

/**
 * Информация о ячейке расходника модели автомата
 */
const DisposableCellModelInfo: FC<DisposableCellModelInfoProps> = ({ cell }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleCollapseClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Collapse
      isOpen={isOpen}
      iconPosition="right"
      label={t('machineControl.model.info.cells.disposable.collapse.label', {
        name: cell.name,
        cellNumber: cell.cellNumber,
      })}
      onClick={handleCollapseClick}
    >
      <DefaultInfo>
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.disposable.cellNumber.item.label'),
            value: String(cell.cellNumber),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.disposable.cellNumber.item.label'),
            value: String(cell.name),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.disposable.isCount.item.label'),
            value: t(`machineControl.model.info.cells.disposable.isCount.${String(cell.isCount)}`),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.disposable.minVolume.item.label'),
            value: String(cell.minVolume),
          }}
        />
      </DefaultInfo>
    </Collapse>
  );
};

export default DisposableCellModelInfo;
