import { FC } from 'react';
import { useAppSelector } from '../../../../app/hooks/store';
import { selectCellPurposeById } from '../../../../state/productBase/selectors';
import CellPurposeForm from './CellPurposeForm';

/**
 * Свойства компонента EditCellPurpose
 */
type EditCellPurposeProps = {
  /**
   * id назначения контейнера
   */
  cellPurposeId: number;
  /**
   * Обработчик закрытия формы изменения назначения контейнера
   */
  onClose: () => void;
};

/**
 * Компонент изменения назначения контейнера
 */
const EditCellPurpose: FC<EditCellPurposeProps> = ({ cellPurposeId, onClose }) => {
  const cellPurpose = useAppSelector(selectCellPurposeById(cellPurposeId));

  return (
    cellPurpose && (
      <CellPurposeForm
        type="edit"
        isOpen={!!cellPurposeId}
        cellPurposeId={cellPurposeId}
        cellPurpose={cellPurpose}
        onClose={onClose}
      />
    )
  );
};

export default EditCellPurpose;
