import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconDollarCircleSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_34469_1330)">
      <path d="M5.52367 3.49672C5.55695 3.4775 5.59076 3.46023 5.625 3.44486V5.45997C4.81151 5.10072 4.73532 3.95187 5.52367 3.49672Z" />
      <path d="M6.375 8.55519V6.53998C7.18859 6.89918 7.26482 8.04811 6.47644 8.50329C6.44312 8.52252 6.40927 8.53981 6.375 8.55519Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.625 6C0.625 3.03147 3.03147 0.625 6 0.625C8.96853 0.625 11.375 3.03147 11.375 6C11.375 8.96853 8.96853 11.375 6 11.375C3.03147 11.375 0.625 8.96853 0.625 6ZM6.375 2.5C6.375 2.29289 6.2071 2.125 6 2.125C5.79289 2.125 5.625 2.29289 5.625 2.5V2.65405C5.46162 2.69503 5.30131 2.75907 5.14867 2.8472C3.78773 3.63293 3.96071 5.64946 5.43558 6.19194L5.625 6.26162V8.60972C5.35756 8.53428 5.11862 8.35849 4.96902 8.09938L4.78124 7.77413C4.67769 7.59477 4.44834 7.53332 4.26898 7.63687C4.08962 7.74042 4.02817 7.96977 4.13172 8.14913L4.3195 8.47438C4.60873 8.97534 5.09633 9.2898 5.625 9.37674V9.5C5.625 9.70711 5.79289 9.875 6 9.875C6.2071 9.875 6.375 9.70711 6.375 9.5V9.34599C6.53841 9.305 6.69876 9.24095 6.85144 9.15281C8.21237 8.36707 8.0394 6.35055 6.56453 5.80806L6.375 5.73836V3.39026C6.64248 3.46567 6.88146 3.64148 7.03108 3.90063L7.21886 4.22588C7.32242 4.40523 7.55176 4.46669 7.73112 4.36313C7.91048 4.25958 7.97194 4.03023 7.86838 3.85087L7.6806 3.52563C7.39135 3.02463 6.9037 2.71016 6.375 2.62325V2.5Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_34469_1330">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

const IconDollarCircleSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_34469_1326)">
      <path d="M7.36481 4.66233C7.40918 4.63671 7.45427 4.61368 7.49992 4.59319V7.28C6.41526 6.801 6.31367 5.2692 7.36481 4.66233Z" />
      <path d="M8.49992 11.407V8.72002C9.58471 9.19894 9.68635 10.7309 8.63517 11.3378C8.59075 11.3634 8.54561 11.3865 8.49992 11.407Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833252 8.00004C0.833252 4.042 4.04188 0.833374 7.99992 0.833374C11.958 0.833374 15.1666 4.042 15.1666 8.00004C15.1666 11.9581 11.958 15.1667 7.99992 15.1667C4.04188 15.1667 0.833252 11.9581 0.833252 8.00004ZM8.49992 3.33337C8.49992 3.05723 8.27606 2.83337 7.99992 2.83337C7.72377 2.83337 7.49992 3.05723 7.49992 3.33337V3.53877C7.28208 3.59342 7.06833 3.6788 6.86481 3.7963C5.05023 4.84395 5.28087 7.53265 7.24735 8.25596L7.49992 8.34887V11.4797C7.14334 11.3791 6.82475 11.1447 6.62528 10.7992L6.37491 10.3655C6.23684 10.1264 5.93104 10.0445 5.6919 10.1825C5.45275 10.3206 5.37081 10.6264 5.50888 10.8655L5.75926 11.2992C6.1449 11.9672 6.79503 12.3864 7.49992 12.5024V12.6667C7.49992 12.9429 7.72377 13.1667 7.99992 13.1667C8.27606 13.1667 8.49992 12.9429 8.49992 12.6667V12.4614C8.7178 12.4067 8.9316 12.3213 9.13517 12.2038C10.9497 11.1561 10.7191 8.46744 8.75263 7.74413L8.49992 7.65119V4.52038C8.85655 4.62094 9.1752 4.85535 9.37469 5.20088L9.62507 5.63454C9.76314 5.87369 10.0689 5.95562 10.3081 5.81755C10.5472 5.67948 10.6292 5.37369 10.4911 5.13454L10.2407 4.70088C9.85505 4.03288 9.20485 3.61358 8.49992 3.4977V3.33337Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_34469_1326">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

const IconDollarCircleSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M11.0473 6.99343C11.1139 6.95501 11.1815 6.92046 11.25 6.88972V10.9199C9.62301 10.2014 9.47063 7.90374 11.0473 6.99343Z" />
    <path d="M12.75 17.1104V13.08C14.3772 13.7984 14.5296 16.0962 12.9529 17.0066C12.8862 17.045 12.8185 17.0796 12.75 17.1104Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5V5.30809C10.9232 5.39007 10.6026 5.51814 10.2973 5.6944C7.57547 7.26586 7.92142 11.2989 10.8712 12.3839L11.25 12.5232V17.2194C10.7151 17.0686 10.2372 16.717 9.93805 16.1988L9.56248 15.5483C9.35538 15.1895 8.89668 15.0666 8.53796 15.2737C8.17924 15.4808 8.05634 15.9395 8.26344 16.2983L8.63901 16.9488C9.21747 17.9507 10.1927 18.5796 11.25 18.7535V19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19V18.692C13.0768 18.61 13.3975 18.4819 13.7029 18.3056C16.4247 16.7341 16.0788 12.7011 13.1291 11.6161L12.75 11.4767V6.78051C13.285 6.93135 13.7629 7.28296 14.0622 7.80125L14.4377 8.45175C14.6448 8.81047 15.1035 8.93338 15.4622 8.72627C15.821 8.51916 15.9439 8.06047 15.7368 7.70175L15.3612 7.05125C14.7827 6.04927 13.8074 5.42031 12.75 5.24649V5Z"
    />
  </svg>
);

export const IconDollarCircle = createIcon({
  name: 'IconDollarCircle',
  xs: IconDollarCircleSizeXS,
  s: IconDollarCircleSizeS,
  m: IconDollarCircleSizeM,
});
