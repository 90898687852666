import {
  PromoCodeGroupRes,
  PromoCodeListFiltersRes,
  PromoCodeListItemRes,
  PromoCodeListRes,
  PromoCodeRes,
} from '../../types/serverInterface/promoCodeDTO';
import { errorHandler, NotificationType } from '../handlers';
import { createSlice, isRejected } from '@reduxjs/toolkit';
import {
  createPromoCodeGroupThunk,
  createPromoCodeThunk,
  editPromoCodeThunk,
  generatePromoCodeThunk,
  getFilterPromoCodeThunk,
  getPromoCodeGroupListThunk,
  getPromoCodeListByExportThunk,
  getPromoCodeListQtyThunk,
  getPromoCodeListThunk,
  getPromoCodeThunk,
  switchSelectPromoCodeThunk,
} from './thunk';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

export type PromoCodeState = {
  groupList: StateItemType<PromoCodeGroupRes[]>;
  promoCodeList: StateItemType<PromoCodeListRes>;
  promoCodeListQty: number;
  filterPromoCode: StateItemType<PromoCodeListFiltersRes>;
  promoCodeInfo: StateItemType<PromoCodeRes>;
  notifications: NotificationType[];
};

const initialState: PromoCodeState = {
  groupList: {
    state: [],
    isLoading: false,
    isReject: false,
  },
  promoCodeListQty: 0,
  filterPromoCode: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  promoCodeList: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  promoCodeInfo: { state: null, isLoading: false, isReject: false },
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: PromoCodeState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getPromoCodeGroupListThunk
    builder.addCase(getPromoCodeGroupListThunk.pending, (state) => {
      state.groupList.state = [];
      state.groupList.isLoading = true;
      state.groupList.isReject = false;
    });

    builder.addCase(getPromoCodeGroupListThunk.rejected, (state) => {
      state.groupList.isLoading = false;
      state.groupList.isReject = true;
    });

    builder.addCase(getPromoCodeGroupListThunk.fulfilled, (state, action) => {
      state.groupList.state = action.payload;
      state.groupList.isLoading = false;
    });

    // getPromoCodeListThunk
    builder.addCase(getPromoCodeListThunk.pending, (state) => {
      state.promoCodeList.isLoading = true;
      state.promoCodeList.isReject = false;
    });

    builder.addCase(getPromoCodeListThunk.rejected, (state) => {
      state.promoCodeList.state = null;
      state.promoCodeList.isLoading = false;
      state.promoCodeList.isReject = true;
    });

    builder.addCase(getPromoCodeListThunk.fulfilled, (state, action) => {
      state.promoCodeList.state = action.payload;
      state.promoCodeList.isLoading = false;
    });

    // getPromoCodeThunk
    builder.addCase(getPromoCodeThunk.pending, (state) => {
      state.promoCodeInfo.state = null;
      state.promoCodeInfo.isLoading = true;
      state.promoCodeInfo.isReject = false;
    });

    builder.addCase(getPromoCodeThunk.rejected, (state) => {
      state.promoCodeInfo.isLoading = false;
      state.promoCodeInfo.isReject = true;
    });

    builder.addCase(getPromoCodeThunk.fulfilled, (state, action) => {
      state.promoCodeInfo.state = action.payload;
      state.promoCodeInfo.isLoading = false;
    });

    // getPromoCodeListQtyThunk
    builder.addCase(getPromoCodeListQtyThunk.fulfilled, (state, action) => {
      state.promoCodeListQty = action.payload.qty;
    });

    // getFilterPromoCodeThunk
    builder.addCase(getFilterPromoCodeThunk.pending, (state) => {
      state.filterPromoCode.state = null;
      state.filterPromoCode.isLoading = true;
      state.filterPromoCode.isReject = false;
    });

    builder.addCase(getFilterPromoCodeThunk.rejected, (state) => {
      state.filterPromoCode.isLoading = false;
      state.filterPromoCode.isReject = true;
    });

    builder.addCase(getFilterPromoCodeThunk.fulfilled, (state, action) => {
      state.filterPromoCode.state = action.payload;
      state.filterPromoCode.isLoading = false;
    });

    // switchSelectPromoCodeThunk
    builder.addCase(switchSelectPromoCodeThunk.fulfilled, (state, action) => {
      const { promoCodeId, isSelected } = action.meta.arg;

      if (state.promoCodeList.state) {
        const promoCodeList = JSON.parse(
          JSON.stringify(state.promoCodeList.state.data),
        ) as PromoCodeListItemRes[];

        const updatedIndex = promoCodeList.findIndex(({ id }) => promoCodeId === id);

        const updatedEl = { ...promoCodeList[updatedIndex], isSelected };

        state.promoCodeList.state.data = [
          ...promoCodeList.slice(0, updatedIndex),
          updatedEl,
          ...promoCodeList.slice(updatedIndex + 1),
        ];
      }
    });

    builder.addMatcher(
      isRejected(
        createPromoCodeGroupThunk,
        getPromoCodeGroupListThunk,
        generatePromoCodeThunk,
        createPromoCodeThunk,
        editPromoCodeThunk,
        getPromoCodeThunk,
        getPromoCodeListThunk,
        getPromoCodeListQtyThunk,
        getFilterPromoCodeThunk,
        switchSelectPromoCodeThunk,
        getPromoCodeListByExportThunk,
      ),
      (state, action) => {
        errorHandler(action)(addNotification(state));
      },
    );
  },
});

export const promoCodeReducer = promoCodeSlice.reducer;
