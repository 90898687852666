import { FC } from 'react';
import { CustomFormType } from '../../../../../types/serverInterface/customForm';
import CustomForm from '../../../../../components/CustomForm/CustomForm';
import { transformValueByFormItem } from '../../../../../components/CustomForm/helpers';

/**
 * Свойства компонента ProductCalibrationForm
 */
type ProductCalibrationFormProps = {
  /**
   * Кастомная формы из категории контейнера
   */
  categoryConfig: CustomFormType;
  /**
   * Кастомная форма из назначения контейнера
   */
  purposeConfig: CustomFormType;
  /**
   * Обработчик изменения кастомной формы категории контейнера
   *
   * @param data изменённые данные формы
   */
  onCategoryChange: (data: CustomFormType) => void;
  /**
   * Обработчик изменения кастомной формы назначения контейнера
   *
   * @param data изменённые данные формы
   */
  onPurposeChange: (data: CustomFormType) => void;
};

/**
 * Форма изменения калибровки продукта
 */
const ProductCalibrationForm: FC<ProductCalibrationFormProps> = ({
  categoryConfig,
  purposeConfig,
  onCategoryChange,
  onPurposeChange,
}) => {
  const config = [...(categoryConfig || []), ...(purposeConfig || [])];

  // Обработчики
  const handleChange = (index: number) => (value: string | boolean | Date | null) => {
    const isCategoryConfig = categoryConfig.length >= index + 1;
    if (isCategoryConfig) {
      const editedConfig = [...categoryConfig];

      editedConfig[index] = transformValueByFormItem(editedConfig[index])(value);

      onCategoryChange(editedConfig);
    }

    if (!isCategoryConfig) {
      const purposeConfigIndex = index - categoryConfig.length;
      const editedConfig = [...purposeConfig];

      editedConfig[purposeConfigIndex] = transformValueByFormItem(editedConfig[purposeConfigIndex])(
        value,
      );

      onPurposeChange(editedConfig);
    }
  };

  return <CustomForm config={config} onChange={handleChange} />;
};

export default ProductCalibrationForm;
