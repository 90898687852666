import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconFavoriteOutLineSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_28171_2559)">
      <path d="M8.08217 2.1378C7.42679 0.120735 4.57319 0.120735 3.9178 2.1378C3.72511 2.73084 3.17247 3.13235 2.54891 3.13235C0.428049 3.13235 -0.453763 5.84629 1.26205 7.09291C1.76652 7.45942 1.97761 8.10909 1.78492 8.70213C1.12954 10.7192 3.43815 12.3965 5.15397 11.1499C5.65844 10.7834 6.34154 10.7834 6.84601 11.1499C8.56183 12.3965 10.8704 10.7192 10.2151 8.70213C10.0224 8.10909 10.2335 7.45942 10.7379 7.09291C12.4537 5.84629 11.5719 3.13235 9.45107 3.13235C8.82751 3.13235 8.27487 2.73084 8.08217 2.1378ZM4.6311 2.36956C5.06197 1.04348 6.93801 1.04348 7.36888 2.36956C7.66198 3.27162 8.50259 3.88235 9.45107 3.88235C10.8454 3.88235 11.4251 5.66658 10.2971 6.48614C9.52975 7.04364 9.20867 8.03184 9.50177 8.9339C9.93263 10.26 8.41488 11.3627 7.28685 10.5431C6.51952 9.98563 5.48046 9.98562 4.71313 10.5431C3.5851 11.3627 2.06735 10.26 2.49821 8.9339C2.79131 8.03184 2.47023 7.04364 1.70289 6.48614C0.574861 5.66658 1.15459 3.88235 2.54891 3.88235C3.49739 3.88235 4.338 3.27162 4.6311 2.36956Z" />
    </g>
    <defs>
      <clipPath id="clip0_28171_2559">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

const IconFavoriteOutLineSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_28171_2555)">
      <path d="M10.7762 2.85044C9.9024 0.16102 6.09759 0.16102 5.22375 2.85044C4.96683 3.64116 4.22997 4.17651 3.39856 4.17651C0.570742 4.17651 -0.605008 7.7951 1.68275 9.45725C2.35537 9.94594 2.63683 10.8122 2.37991 11.6029C1.50606 14.2923 4.58421 16.5287 6.87197 14.8666C7.54459 14.3779 8.4554 14.3779 9.12803 14.8666C11.4158 16.5287 14.4939 14.2923 13.6201 11.6029C13.3632 10.8122 13.6446 9.94594 14.3172 9.45725C16.605 7.7951 15.4293 4.17651 12.6014 4.17651C11.77 4.17651 11.0332 3.64116 10.7762 2.85044ZM6.17481 3.15945C6.7493 1.39135 9.25069 1.39135 9.82519 3.15945C10.216 4.3622 11.3368 5.17651 12.6014 5.17651C14.4605 5.17651 15.2335 7.55548 13.7295 8.64823C12.7064 9.39157 12.2782 10.7092 12.669 11.9119C13.2435 13.68 11.2199 15.1503 9.71581 14.0575C8.6927 13.3142 7.3073 13.3142 6.28418 14.0575C4.78014 15.1503 2.75647 13.68 3.33096 11.9119C3.72176 10.7092 3.29365 9.39157 2.27053 8.64823C0.766492 7.55548 1.53947 5.17651 3.39856 5.17651C4.6632 5.17651 5.78401 4.3622 6.17481 3.15945Z" />
    </g>
    <defs>
      <clipPath id="clip0_28171_2555">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

const IconFavoriteOutLineSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M16.1643 4.02559C14.8536 -0.00853062 9.14638 -0.00853062 7.83561 4.02559C7.45023 5.21167 6.34494 6.01471 5.09783 6.01471C0.856098 6.01471 -0.907527 11.4426 2.5241 13.9358C3.53304 14.6688 3.95522 15.9682 3.56984 17.1543C2.25908 21.1884 6.8763 24.543 10.3079 22.0498C11.3169 21.3167 12.6831 21.3167 13.692 22.0498C17.1237 24.543 21.7409 21.1884 20.4301 17.1543C20.0447 15.9682 20.4669 14.6688 21.4759 13.9358C24.9075 11.4426 23.1439 6.01471 18.9021 6.01471C17.655 6.01471 16.5497 5.21167 16.1643 4.02559ZM9.2622 4.48912C10.1239 1.83696 13.876 1.83696 14.7378 4.48912C15.324 6.29323 17.0052 7.51471 18.9021 7.51471C21.6908 7.51471 22.8502 11.0832 20.5942 12.7223C19.0595 13.8373 18.4173 15.8137 19.0035 17.6178C19.8653 20.27 16.8298 22.4754 14.5737 20.8363C13.039 19.7213 10.9609 19.7212 9.42626 20.8363C7.1702 22.4754 4.13469 20.27 4.99643 17.6178C5.58262 15.8137 4.94045 13.8373 3.40578 12.7223C1.14972 11.0832 2.30918 7.51471 5.09783 7.51471C6.99478 7.51471 8.676 6.29323 9.2622 4.48912Z" />
  </svg>
);

export const IconFavoriteOutLine = createIcon({
  name: 'IconFavoriteOutLine',
  xs: IconFavoriteOutLineSizeXS,
  s: IconFavoriteOutLineSizeS,
  m: IconFavoriteOutLineSizeM,
});
