import { FC } from 'react';
import { EditOrganizationContactItemProps } from './types';
import { TextField } from '@consta/uikit/TextField';
import styles from './EditOrganizationContactItem.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '../../../../../assets/icon/iconTrash';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';

/**
 * Изменение контакта организации
 */
const EditOrganizationContactItem: FC<EditOrganizationContactItemProps> = ({
  contact,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <HorizontalContainer space="m" align="end" justify="center">
      <TextField
        label={t('organization.contacts.edit.contactName.input.label')}
        name="name"
        width="full"
        size="l"
        className={styles.nameField}
        value={contact.name}
        placeholder={t('organization.contacts.edit.contactName.input.placeholder')}
        onChange={onChange('name')}
      />
      <TextField
        label={t('organization.contacts.edit.contact.input.label')}
        name="phone"
        width="full"
        size="l"
        className={styles.contactField}
        value={contact.contact}
        placeholder={t('organization.contacts.edit.contact.input.placeholder')}
        onChange={onChange('contact')}
      />
      <Button size="l" iconLeft={IconTrash as any} view="clear" onlyIcon onClick={onDelete} />
    </HorizontalContainer>
  );
};

export default EditOrganizationContactItem;
