import { FC } from 'react';
import {
  MachineStorageProductCellType,
  SeparatedMachineStorageType,
} from '../../../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../../../types/serverInterface/storageDTO';
import styles from './MachineRefillTable.module.scss';
import ContentCard from '../../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import { DisplayType, displayTypeValue } from '../../../../../App';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../app/hooks/store';
import { selectCellCategoryUnit } from '../../../../../../state/productBase/selectors';
import {
  MachineCellGroupUnion,
  MachineStorageInfoProductCellFormatted,
} from '../../../../../../types/serverInterface/machineDTO';
import { TextField } from '@consta/uikit/TextField';
import HorizontalContainer from '../../../../../../components/HorizontalContainer';
import { getInputNumberValue } from '../../../../../../helpers/ inputHelpers';

/**
 * Свойства компонента MachineFillingInfoTable
 */
type MachineFillingInfoTableProps = {
  /**
   * Склада автомата
   */
  storage: SeparatedMachineStorageType;
  /**
   * Сортированный список групп продуктов склада автомата
   */
  sortedGroup: ProductGroup[];
  /**
   * Обработчик hover на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHover: (group: MachineCellGroupUnion) => (index: number) => void;
  /**
   * Обработчки hover leave на ячейку склада
   *
   * @param group группа ячеек склада
   * @param index индекс внутри группы
   */
  onHoverLeave: (group: MachineCellGroupUnion) => (index: number) => void;
  onInputChange: (
    group: MachineCellGroupUnion,
  ) => (
    index: number,
  ) => (key: keyof MachineStorageInfoProductCellFormatted) => (value: string | null) => void;
};

/**
 * Таблица склада страницы информации наполнения автомата
 */
const MachineRefillTable: FC<MachineFillingInfoTableProps> = ({
  storage,
  sortedGroup,
  onHover,
  onHoverLeave,
  onInputChange,
}) => {
  const cellCategoryMap = useAppSelector(selectCellCategoryUnit());

  const { t } = useTranslation();

  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  // Обработчики
  const handleValueChange =
    (maxVolume: number) =>
    (group: MachineCellGroupUnion) =>
    (index: number) =>
    ({ value }: { value: string | null }) => {
      onInputChange(group)(index)('volume')(
        maxVolume ? (Number(value) > maxVolume ? String(maxVolume) : value) : value,
      );
    };

  // render методы
  const renderHeader = () => (
    <thead className={styles.head}>
      <tr className={styles.row}>
        <th className={classNames(styles.cell, styles.cellNumberCell)}>
          <Text
            className={styles.text}
            size={isLaptop ? 's' : 'l'}
            weight="semibold"
            view="secondary"
          >
            №
          </Text>
        </th>
        <th className={classNames(styles.cell, styles.brandCell)}>
          <Text
            className={styles.text}
            size={isLaptop ? 's' : 'l'}
            weight="semibold"
            view="secondary"
          >
            Бренд
          </Text>
        </th>
        <th className={classNames(styles.cell, styles.ingredientLineCell)}>
          <Text
            className={styles.text}
            size={isLaptop ? 's' : 'l'}
            weight="semibold"
            view="secondary"
          >
            Линейка
          </Text>
        </th>
        <th className={classNames(styles.cell, styles.ingredientCell)}>
          <Text
            className={styles.text}
            size={isLaptop ? 's' : 'l'}
            weight="semibold"
            view="secondary"
          >
            Вкус
          </Text>
        </th>
        <th className={classNames(styles.cell, styles.volumeCell)} />
      </tr>
    </thead>
  );

  const renderRow = (
    { info, isHoverStatus }: MachineStorageProductCellType,
    index: number,
    group: ProductGroup,
  ) => (
    <tr
      className={classNames(styles.row, styles[info.view], isHoverStatus && styles.hover)}
      onMouseEnter={() => onHover(info.group)(index)}
      onMouseLeave={() => onHoverLeave(info.group)(index)}
    >
      <th className={classNames(styles.cell, styles.cellNumberCell)}>
        <Text className={styles.text} size={isLaptop ? 's' : 'l'} weight="regular">
          {info.cellNumber}
        </Text>
      </th>
      <th className={classNames(styles.cell, styles.brandCell)}>
        <Text className={styles.text} size={isLaptop ? 's' : 'l'} weight="regular">
          {info.brandName}
        </Text>
      </th>
      <th className={classNames(styles.cell, styles.ingredientLineCell)}>
        <Text className={styles.text} size={isLaptop ? 's' : 'l'} weight="regular">
          {info.ingredientLineName}
        </Text>
      </th>
      <th className={classNames(styles.cell, styles.ingredientCell)}>
        <Text className={styles.text} size={isLaptop ? 's' : 'l'} weight="regular">
          {info.ingredientName}
        </Text>
      </th>
      <th className={classNames(styles.cell, styles.volumeCell)}>
        <HorizontalContainer space={0}>
          <TextField
            className={styles.textField}
            size={isLaptop ? 'xs' : 's'}
            value={getInputNumberValue(info.volume)}
            type="number"
            incrementButtons={false}
            onChange={handleValueChange(info.maxVolume)(group)(index)}
            onDrop={(e) => {
              e.preventDefault();
            }}
          />
          <Text className={styles.unitText} size="s" view="secondary">
            {cellCategoryMap[info.cellCategoryId]
              ? t(`productBase.units.${cellCategoryMap[info.cellCategoryId]}`)
              : ''}
          </Text>
        </HorizontalContainer>
      </th>
    </tr>
  );

  const renderRows = () => (
    <tbody className={styles.body}>
      {sortedGroup.map((group) =>
        storage.cells[group].map((cell, index) => renderRow(cell, index, group)),
      )}
    </tbody>
  );

  return (
    <ContentCard
      className={classNames(styles.MachineFillingInfoTable, isLaptop && styles.isLaptop)}
    >
      <table className={styles.table}>
        {renderHeader()}
        {renderRows()}
      </table>
    </ContentCard>
  );
};

export default MachineRefillTable;
