import {
  ActivePromoCodeFilterEnum,
  DiscountTypePromoCodeFilterEnum,
  SelectedPromoCodeFilterEnum,
} from '../../pages/PromoCodePage/PromoCodeList/types';
import { Pagination } from '../types';
import { SortDirection } from '../enums/sortDirection';

/**
 * req создания группы промокодов
 */
export type CreatePromoCodeGroupReq = {
  /**
   * Название группы промокода
   */
  name: string;
  /**
   * id организации
   */
  organizationId: number;
};

/**
 * res создания группы промокодов
 */
export type CreatePromoCodeGroupRes = {
  /**
   * id группы промокода
   */
  id: number;
};

/**
 * res генерации промокода
 */
export type GeneratePromoCodeRes = {
  /**
   * Код промокода
   */
  code: string;
};

/**
 * Группа промокодов
 */
export type PromoCodeGroupRes = {
  /**
   * id группы промокодов
   */
  id: number;
  /**
   * Название группы промокодов
   */
  name: string;
};

/**
 * Варианты скидки промокода
 */
export enum TypeDiscountEnum {
  /**
   * Фиксированная цена
   */
  FIXED = 'FIXED',
  /**
   * Процент скидка
   */
  PERCENT = 'PERCENT',
  /**
   * Бесплатная продажа
   */
  FREE = 'FREE',
}

/**
 * Дни недели
 */
export enum WeekdayEnum {
  /**
   * Понедельник
   */
  MO = 'MO',
  /**
   * Вторник
   */
  TU = 'TU',
  /**
   * Среда
   */
  WE = 'WE',
  /**
   * Четверг
   */
  TH = 'TH',
  /**
   * Пятница
   */
  FR = 'FR',
  /**
   * Суббота
   */
  SA = 'SA',
  /**
   * Воскресенье
   */
  SU = 'SU',
}

/**
 * Настройки генерации промокода(для массового создания)
 */
export type GenerationSetting = {
  /**
   * Префикс промокода
   */
  prefix: string | null;
  /**
   * Количество символов
   */
  qtyLetter: number | null;
  /**
   * Количество промокодов
   */
  qty: number | null;
};

/**
 * Скидка
 */
export type Discount = {
  /**
   * Тип скидки
   */
  type: TypeDiscountEnum;
  /**
   * Количество скидки
   */
  amount: number | null;
};

/**
 * Время использования
 */
export type PeriodUse = {
  /**
   * Начало (YYYY-mm-dd)
   */
  from: string | null;
  /**
   * Окончание (YYYY-mm-dd)
   */
  to: string | null;
};

/**
 * Группа промокодов
 */
export type Group = {
  /**
   * id группы
   */
  id: number | null;
  /**
   * Название группы
   */
  name: string | null;
};

/**
 * Расписание работы промокодов
 */
export type Schedule = {
  /**
   * id расписания
   */
  id: number | null;
  /**
   * Название расписания
   */
  name: string | null;
  /**
   * Время начала действия (HH:mm)
   */
  from: string | null;
  /**
   * Время окончания действия
   */
  to: string | null;
  /**
   * Дни недели
   */
  weekday: WeekdayEnum[];
};

/**
 * Расписание для формы
 */
export type ScheduleByForm = Omit<Schedule, 'to' | 'from' | 'weekday'> & {
  /**
   * Время окончания
   */
  to: Date | null;
  /**
   * Время начала
   */
  from: Date | null;
  /**
   * Мапа дней недели
   */
  weekday: Record<WeekdayEnum, boolean>;
};

/**
 * Основные поля промокода
 */
interface PromoCodeFields {
  /**
   * Код промокода
   */
  code: string | null;
  /**
   * Максимальное количество использований
   */
  qty: number | null;
  /**
   * Скидка
   */
  discount: Discount;
  /**
   * Период использования
   */
  periodUse: PeriodUse;
  /**
   * Описание промокода
   */
  description: string | null;
}

/**
 * Элемент списка из базы продуктов (используется дл бренда, линейки и продукта
 * на запросах списков для промокодов)
 */
export type ProductBaseItemDTO = {
  id: number;
  name: string;
};

export type IngredientLineId = {
  id: number;
  ingredientIds: number[] | null;
};

export type BrandId = {
  id: number | null;
  ingredientLines: IngredientLineId[] | null;
};

/**
 * Продукты промокода для формы
 */
export type ProductByForm = {
  brands: BrandId[];
  categoryIds: number[];
  viewIds: number[];
};

export type ProductByInfo = {
  brands: {
    id: number;
    name: string;
    ingredientLines: {
      id: number;
      name: string;
      ingredientIds: { id: number; name: string }[];
    }[];
  }[];
  categoryIds: { id: number; name: string }[];
  viewIds: { id: number; name: string }[];
};

/**
 * req формы создания промокода
 */
export type CreatePromoCodeReq = {
  /**
   * Сгенерировать несколько
   */
  isGenerateSeveral: boolean;
  /**
   * Максимальное количество использований
   */
  qty: number | null;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Настройка генерации промокода
   */
  generationSetting: GenerationSetting;
  /**
   * id группы промокодов
   */
  groupId: number | null;
  /**
   * Расписание промокода
   */
  schedules: Schedule[];
  /**
   * Список id автоматов
   */
  machineIds: number[] | null;
  /**
   * Выбранные продукты
   */
  product: ProductByForm;
} & PromoCodeFields;

/**
 * Тип формы
 */
export type CreatePromoCodeDTO = Omit<CreatePromoCodeReq, 'periodUse' | 'schedules'> & {
  /**
   * Период использования
   */
  periodUse: {
    /**
     * Конец
     */
    to: Date | null;
    /**
     * Начало
     */
    from: Date | null;
  };
  /**
   * Расписание промокода
   */
  schedules: ScheduleByForm[];
  /**
   * Неограниченное использование времени
   */
  isUnlimitedTimeUse: boolean;
  /**
   * Неограниченное использование количества
   */
  isUnlimitedCountUse: boolean;
  /**
   * Все автоматы
   */
  isFullMachine: boolean;
};

/**
 * res создания промокода
 */
export type CreatePromoCodeRes = {
  /**
   * id промокода
   */
  id: number;
};

/**
 * req формы изменения промокода
 */
export type EditPromoCodeReq = {
  /**
   * Статус промокода
   */
  isActive: boolean;
  /**
   * id группы промокодов
   */
  groupId: number | null;
  /**
   * Расписание промокода
   */
  schedules: Schedule[];
  /**
   * Список id автоматов
   */
  machineIds: number[] | null;
  /**
   * Выбранные продукты
   */
  product: ProductByForm;
} & PromoCodeFields;

/**
 * Тип формы
 */
export type EditPromoCodeDTO = Omit<EditPromoCodeReq, 'periodUse' | 'schedules'> & {
  /**
   * Период использования
   */
  periodUse: {
    /**
     * Конец
     */
    to: Date | null;
    /**
     * Начало
     */
    from: Date | null;
  };
  /**
   * Расписание промокода
   */
  schedules: ScheduleByForm[];
  /**
   * Неограниченное использование времени
   */
  isUnlimitedTimeUse: boolean;
  /**
   * Неограниченное использование количества
   */
  isUnlimitedCountUse: boolean;
  /**
   * Все автоматы
   */
  isFullMachine: boolean;
};

export type PromoCodeResMachineListItem = {
  /**
   * id автомата
   */
  id: number;
  /**
   * Название автомата
   */
  name: string;
};

/**
 * res детальной информации о промокоде
 */
export type PromoCodeRes = {
  /**
   * Статус промокода
   */
  isActive: boolean;
  /**
   * Группа промокодов
   */
  group: Group;
  /**
   * Количество использований промокода (текущее)
   */
  used: number;
  /**
   * Количество промокодов в группе
   */
  qtyInGroup: number;
  /**
   * Расписание
   */
  schedules: Schedule[];
  /**
   * Список id автоматов
   */
  machines: PromoCodeResMachineListItem[];
  /**
   * Продукты
   */
  product: ProductByInfo;
  [key: string]: any;
} & PromoCodeFields;

/**
 * res элемента списка промокодов
 */
export type PromoCodeListItemRes = {
  /**
   * id промокода
   */
  id: number;
  /**
   * Избранный
   */
  isSelected: boolean;
  /**
   * Количество использований промокода (текущее)
   */
  used: number;
  /**
   * Статус промокода
   */
  isActive: boolean;
  /**
   * Название группы
   */
  groupName: string;
} & PromoCodeFields;

/**
 * Список промокодов
 */
export type PromoCodeListRes = {
  /**
   * Список промокодов
   */
  data: PromoCodeListItemRes[];
  /**
   * Настройка страниц списка промокодов
   */
  pagination: Pagination;
};

/**
 * Фильтры промокода
 */
export type PromoCodeListFiltersDTO = {
  /**
   * Избранные промокоды
   */
  selected: SelectedPromoCodeFilterEnum;
  /**
   *Активные промокоды
   */
  active: ActivePromoCodeFilterEnum;
  /**
   * Дата начала периода
   */
  periodTo: Date | null;
  /**
   * Дата окончания периода
   */
  periodFrom: Date | null;
  /**
   * Количество минимальных использований
   */
  qtyMin: number | null;
  /**
   * Количество максимальных использований
   */
  qtyMax: number | null;
  /**
   * Тип скидки
   */
  discountType: DiscountTypePromoCodeFilterEnum;
  /**
   * Размер скидки минимум
   */
  discountAmountMin: number | null;
  /**
   * Размер скидки максимум
   */
  discountAmountMax: number | null;
  /**
   * Строка поиска промокода по его коду
   */
  code: string | null;
  /**
   * Сортировка по дате создания
   */
  createdSort: SortDirection | null;
};

/**
 * Фильтр списка промокодов для отправки на сервер
 */
export type PromoCodeListFiltersReq = {
  /**
   * Избранные промокоды
   */
  isSelected: boolean | null;
  /**
   *Активные промокоды
   */
  isActive: boolean | null;
  /**
   * Дата начала периода 2006-01-02T00:00:00Z
   */
  periodTo: string | null;
  /**
   * Дата окончания периода 2006-01-02T00:00:00Z
   */
  periodFrom: string | null;
  /**
   * Количество минимальных использований
   */
  qtyMin: number | null;
  /**
   * Количество максимальных использований
   */
  qtyMax: number | null;
  /**
   * Тип скидки
   */
  discountType: TypeDiscountEnum | null;
  /**
   * Размер скидки минимум
   */
  discountAmountMin: number | null;
  /**
   * Размер скидки максимум
   */
  discountAmountMax: number | null;
  /**
   * Строка поиска промокода по его коду
   */
  code: string | null;
  /**
   * Сортировка по дате создания
   */
  createdSort: SortDirection | null;
};

/**
 * Текущее состояние фильтра
 */
export type PromoCodeListFiltersRes = {
  /**
   * Текущий фильтр
   */
  current: PromoCodeListFiltersReq;
  /**
   * Изменяемые значения
   */
  value: {
    /**
     * Максимальное значение размера скидки
     */
    discountAmountMax: number;
    /**
     * Количество использований
     */
    maxQty: number;
  };
};
