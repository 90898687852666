import { FC } from 'react';
import styles from './MatrixSchemeRowItem.module.scss';

/**
 * Ячейка в группе автомата снек
 */
const MatrixSchemeRowItem: FC = () => {
  return <div className={styles.matrixItem}></div>;
};

export default MatrixSchemeRowItem;
