import {
  CreateOrganizationBasic,
  ContactOrganization,
} from '../../../types/serverInterface/organizationDTO';

/**
 * Первоначальное значение поля для создания организации.
 */
export const createOrganizationBasicInitialValue: CreateOrganizationBasic = {
  name: '',
  description: '',
};

/**
 * Первоначальное значение поля для создания контактов организации.
 */
export const createOrganizationContactsInitialValue: ContactOrganization[] = [
  { name: '', contact: '' },
];
