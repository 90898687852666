import React, { FC, useEffect, useMemo } from 'react';
import { getOrganizationByIdAction } from '../../../state/organization/actions';
import { useAppDispatch } from '../../../app/hooks/store';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MembersTable from './MembersTable';
import KeysTable from './KeysTable';
import MemberKeyPage from './MemberKeyPage';
import TabsBadge from '../../../components/TabsBadge';
import { TabProps } from '../../../components/TabsBadge/TabBadge/TabBadge';
import { IconGroup3Filled } from '../../../assets/icon/iconGroup3Filled';
import { IconQR } from '../../../assets/icon/iconQR';
import { useLocation } from 'react-router';
import VerticalContainer from '../../../components/VerticalContainer';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import styles from './OrganizationMembers.module.scss';
import { getOrganizationId } from '../../../helpers/getOrganizationId';

const OrganizationMembers: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const organizationId = getOrganizationId();

  useEffect(() => {
    organizationId && dispatch(getOrganizationByIdAction(organizationId));
  }, [dispatch, organizationId]);

  const tabsList = useMemo(
    (): TabProps[] => [
      {
        label: 'Сотрудники',
        renderIcon: () => <IconGroup3Filled {...defaultIconProps} />,
        isSelect: pathname.includes('list'),
        onClick: () => {
          navigate(`/organization/members/list`);
        },
      },
      {
        label: 'Доступ к автоматам',
        renderIcon: () => <IconQR {...defaultIconProps} />,
        isSelect: pathname.includes('keys'),
        onClick: () => {
          navigate(`/organization/members/keys`);
        },
      },
    ],
    [pathname, navigate],
  );

  // render методы
  const renderTabs = () => <TabsBadge tabsList={tabsList} size="m" />;

  if (!organizationId) return null;

  return (
    <VerticalContainer className={styles.OrganizationMember} space="3xl">
      {!pathname.includes('memberWithKey') && renderTabs()}
      <Routes>
        <Route path="/keys/*" element={<KeysTable organizationId={organizationId} />} />
        <Route
          path="/memberWithKey/:uuid/*"
          element={<MemberKeyPage organizationId={organizationId} />}
        />
        <Route path="/list/*" element={<MembersTable organizationId={organizationId} />} />
      </Routes>
    </VerticalContainer>
  );
};

export default OrganizationMembers;
