import React, { FC } from 'react';
import { EmployeeWithKeyElementDTO } from '../../../../../types/serverInterface/employeeWithKeyElementDTO';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MemberKeyProfile.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { DisplayType, displayTypeValue } from '../../../../App';
import VerticalContainer from '../../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconQR } from '../../../../../assets/icon/iconQR';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@consta/uikit/Avatar';

type MemberKeyProfileProps = {
  employee: EmployeeWithKeyElementDTO;
  onOpenQr: () => void;
};

const MemberKeyProfile: FC<MemberKeyProfileProps> = ({ employee, onOpenQr }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { t } = useTranslation();

  const { name, surname, patronymic, position } = employee;

  const fullName = `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`;

  return (
    <ContentCard className={styles.MemberKeyProfile}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        Профиль
      </Text>
      <HorizontalContainer className={styles.profileCard} isAutoSpace align="center">
        <HorizontalContainer space="m" align="center">
          <Avatar className={styles.avatar} name={fullName} />
          <VerticalContainer space={0}>
            <Text className={styles.text} size={isLaptop ? 'l' : 'xl'}>
              {fullName}
            </Text>
            <Text className={styles.text} size={isLaptop ? 'm' : 'l'} view="secondary">
              {t(`roles.${position}`)}
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <HorizontalContainer align="end" space="xs">
          <Button view="clear" size="m" onlyIcon iconLeft={IconQR as any} onClick={onOpenQr} />
        </HorizontalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MemberKeyProfile;
