import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconFormulaSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M4 3.625C3.79289 3.625 3.625 3.79289 3.625 4C3.625 4.20711 3.79289 4.375 4 4.375H8C8.20711 4.375 8.375 4.20711 8.375 4C8.375 3.79289 8.20711 3.625 8 3.625H4Z" />
    <path d="M4 5.625C3.79289 5.625 3.625 5.79289 3.625 6C3.625 6.20711 3.79289 6.375 4 6.375H8C8.20711 6.375 8.375 6.20711 8.375 6C8.375 5.79289 8.20711 5.625 8 5.625H4Z" />
    <path d="M4 7.625C3.79289 7.625 3.625 7.79289 3.625 8C3.625 8.20711 3.79289 8.375 4 8.375H5.5C5.70711 8.375 5.875 8.20711 5.875 8C5.875 7.79289 5.70711 7.625 5.5 7.625H4Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.23176 0.686864C7.66069 0.624993 6.94089 0.624996 6.02262 0.625H5.97738C5.05911 0.624996 4.33931 0.624993 3.76824 0.686864C3.18547 0.750003 2.71328 0.881162 2.31012 1.17408C2.06605 1.35141 1.85141 1.56605 1.67408 1.81012C1.38116 2.21328 1.25 2.68547 1.18686 3.26824C1.12499 3.83931 1.125 4.55911 1.125 5.47737V6.52262C1.125 7.44089 1.12499 8.16069 1.18686 8.73176C1.25 9.31453 1.38116 9.78672 1.67408 10.1899C1.85141 10.434 2.06605 10.6486 2.31012 10.8259C2.71328 11.1188 3.18547 11.25 3.76824 11.3131C4.3393 11.375 5.05908 11.375 5.97733 11.375H6.02264C6.94088 11.375 7.6607 11.375 8.23176 11.3131C8.81453 11.25 9.28672 11.1188 9.68988 10.8259C9.93396 10.6486 10.1486 10.434 10.3259 10.1899C10.6188 9.78672 10.75 9.31453 10.8131 8.73176C10.875 8.1607 10.875 7.44092 10.875 6.52267V5.47736C10.875 4.55912 10.875 3.8393 10.8131 3.26824C10.75 2.68547 10.6188 2.21328 10.3259 1.81012C10.1486 1.56604 9.93396 1.3514 9.68988 1.17408C9.28672 0.881162 8.81453 0.750003 8.23176 0.686864ZM2.75096 1.78084C3.00496 1.5963 3.33007 1.48873 3.84902 1.4325C4.375 1.37551 5.05421 1.375 6 1.375C6.94579 1.375 7.625 1.37551 8.15098 1.4325C8.66993 1.48873 8.99504 1.5963 9.24904 1.78084C9.42945 1.91191 9.58809 2.07056 9.71916 2.25096C9.90371 2.50496 10.0113 2.83007 10.0675 3.34902C10.1245 3.875 10.125 4.55421 10.125 5.5V6.5C10.125 7.44579 10.1245 8.125 10.0675 8.65098C10.0113 9.16993 9.90371 9.49504 9.71916 9.74904C9.58809 9.92944 9.42945 10.0881 9.24904 10.2192C8.99504 10.4037 8.66993 10.5113 8.15098 10.5675C7.625 10.6245 6.94579 10.625 6 10.625C5.05421 10.625 4.375 10.6245 3.84902 10.5675C3.33007 10.5113 3.00496 10.4037 2.75096 10.2192C2.57056 10.0881 2.41191 9.92944 2.28084 9.74904C2.0963 9.49504 1.98873 9.16993 1.9325 8.65098C1.87551 8.125 1.875 7.44579 1.875 6.5V5.5C1.875 4.55421 1.87551 3.875 1.9325 3.34902C1.98873 2.83007 2.0963 2.50496 2.28084 2.25096C2.41191 2.07056 2.57056 1.91191 2.75096 1.78084Z"
    />
  </svg>
);

const IconFormulaSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M5.33333 4.83337C5.05719 4.83337 4.83333 5.05723 4.83333 5.33337C4.83333 5.60952 5.05719 5.83337 5.33333 5.83337H10.6667C10.9428 5.83337 11.1667 5.60952 11.1667 5.33337C11.1667 5.05723 10.9428 4.83337 10.6667 4.83337H5.33333Z" />
    <path d="M5.33333 7.50004C5.05719 7.50004 4.83333 7.7239 4.83333 8.00004C4.83333 8.27618 5.05719 8.50004 5.33333 8.50004H10.6667C10.9428 8.50004 11.1667 8.27618 11.1667 8.00004C11.1667 7.7239 10.9428 7.50004 10.6667 7.50004H5.33333Z" />
    <path d="M5.33333 10.1667C5.05719 10.1667 4.83333 10.3906 4.83333 10.6667C4.83333 10.9428 5.05719 11.1667 5.33333 11.1667H7.33333C7.60948 11.1667 7.83333 10.9428 7.83333 10.6667C7.83333 10.3906 7.60948 10.1667 7.33333 10.1667H5.33333Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9757 0.91586C10.2143 0.833364 9.25452 0.833369 8.03017 0.833374H7.96983C6.74548 0.833369 5.78574 0.833364 5.02432 0.91586C4.24729 1.00004 3.61771 1.17492 3.08016 1.56548C2.75473 1.80191 2.46854 2.0881 2.2321 2.41353C1.84155 2.95108 1.66667 3.58067 1.58249 4.35769C1.49999 5.11911 1.49999 6.07885 1.5 7.30321V8.69687C1.49999 9.92123 1.49999 10.881 1.58249 11.6424C1.66667 12.4194 1.84155 13.049 2.2321 13.5866C2.46854 13.912 2.75473 14.1982 3.08016 14.4346C3.61771 14.8252 4.24729 15 5.02432 15.0842C5.78573 15.1667 6.74544 15.1667 7.96977 15.1667H8.03018C9.25451 15.1667 10.2143 15.1667 10.9757 15.0842C11.7527 15 12.3823 14.8252 12.9198 14.4346C13.2453 14.1982 13.5315 13.912 13.7679 13.5866C14.1585 13.049 14.3333 12.4194 14.4175 11.6424C14.5 10.881 14.5 9.92126 14.5 8.69694V7.30319C14.5 6.07887 14.5 5.1191 14.4175 4.35769C14.3333 3.58067 14.1585 2.95108 13.7679 2.41353C13.5315 2.0881 13.2453 1.80191 12.9198 1.56548C12.3823 1.17492 11.7527 1.00004 10.9757 0.91586ZM3.66794 2.37449C4.00661 2.12844 4.44009 1.98501 5.13203 1.91004C5.83333 1.83406 6.73895 1.83337 8 1.83337C9.26105 1.83337 10.1667 1.83406 10.868 1.91004C11.5599 1.98501 11.9934 2.12844 12.3321 2.37449C12.5726 2.54925 12.7841 2.76078 12.9589 3.00132C13.2049 3.33999 13.3484 3.77346 13.4233 4.4654C13.4993 5.1667 13.5 6.07233 13.5 7.33337V8.66671C13.5 9.92776 13.4993 10.8334 13.4233 11.5347C13.3484 12.2266 13.2049 12.6601 12.9589 12.9988C12.7841 13.2393 12.5726 13.4508 12.3321 13.6256C11.9934 13.8716 11.5599 14.0151 10.868 14.09C10.1667 14.166 9.26105 14.1667 8 14.1667C6.73895 14.1667 5.83333 14.166 5.13203 14.09C4.44009 14.0151 4.00661 13.8716 3.66794 13.6256C3.42741 13.4508 3.21588 13.2393 3.04112 12.9988C2.79506 12.6601 2.65163 12.2266 2.57667 11.5347C2.50069 10.8334 2.5 9.92776 2.5 8.66671V7.33337C2.5 6.07233 2.50069 5.1667 2.57667 4.4654C2.65163 3.77346 2.79506 3.33999 3.04112 3.00132C3.21588 2.76078 3.42741 2.54925 3.66794 2.37449Z"
    />
  </svg>
);

const IconFormulaSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M8 7.25C7.58579 7.25 7.25 7.58579 7.25 8C7.25 8.41421 7.58579 8.75 8 8.75H16C16.4142 8.75 16.75 8.41421 16.75 8C16.75 7.58579 16.4142 7.25 16 7.25H8Z" />
    <path d="M8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8Z" />
    <path d="M8 15.25C7.58579 15.25 7.25 15.5858 7.25 16C7.25 16.4142 7.58579 16.75 8 16.75H11C11.4142 16.75 11.75 16.4142 11.75 16C11.75 15.5858 11.4142 15.25 11 15.25H8Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4635 1.37373C15.3214 1.24999 13.8818 1.24999 12.0452 1.25H11.9548C10.1182 1.24999 8.67861 1.24999 7.53648 1.37373C6.37094 1.50001 5.42656 1.76232 4.62024 2.34815C4.13209 2.70281 3.70281 3.13209 3.34815 3.62024C2.76232 4.42656 2.50001 5.37094 2.37373 6.53648C2.24999 7.67861 2.24999 9.11821 2.25 10.9548V13.0452C2.24999 14.8818 2.24999 16.3214 2.37373 17.4635C2.50001 18.6291 2.76232 19.5734 3.34815 20.3798C3.70281 20.8679 4.13209 21.2972 4.62024 21.6518C5.42656 22.2377 6.37094 22.5 7.53648 22.6263C8.67859 22.75 10.1182 22.75 11.9547 22.75H12.0453C13.8818 22.75 15.3214 22.75 16.4635 22.6263C17.6291 22.5 18.5734 22.2377 19.3798 21.6518C19.8679 21.2972 20.2972 20.8679 20.6518 20.3798C21.2377 19.5734 21.5 18.6291 21.6263 17.4635C21.75 16.3214 21.75 14.8818 21.75 13.0453V10.9547C21.75 9.11824 21.75 7.67859 21.6263 6.53648C21.5 5.37094 21.2377 4.42656 20.6518 3.62024C20.2972 3.13209 19.8679 2.70281 19.3798 2.34815C18.5734 1.76232 17.6291 1.50001 16.4635 1.37373ZM5.50191 3.56168C6.00992 3.19259 6.66013 2.97745 7.69804 2.865C8.74999 2.75103 10.1084 2.75 12 2.75C13.8916 2.75 15.25 2.75103 16.302 2.865C17.3399 2.97745 17.9901 3.19259 18.4981 3.56168C18.8589 3.82382 19.1762 4.14111 19.4383 4.50191C19.8074 5.00992 20.0225 5.66013 20.135 6.69804C20.249 7.74999 20.25 9.10843 20.25 11V13C20.25 14.8916 20.249 16.25 20.135 17.302C20.0225 18.3399 19.8074 18.9901 19.4383 19.4981C19.1762 19.8589 18.8589 20.1762 18.4981 20.4383C17.9901 20.8074 17.3399 21.0225 16.302 21.135C15.25 21.249 13.8916 21.25 12 21.25C10.1084 21.25 8.74999 21.249 7.69804 21.135C6.66013 21.0225 6.00992 20.8074 5.50191 20.4383C5.14111 20.1762 4.82382 19.8589 4.56168 19.4981C4.19259 18.9901 3.97745 18.3399 3.865 17.302C3.75103 16.25 3.75 14.8916 3.75 13V11C3.75 9.10843 3.75103 7.74999 3.865 6.69804C3.97745 5.66013 4.19259 5.00992 4.56168 4.50191C4.82382 4.14111 5.14111 3.82382 5.50191 3.56168Z"
    />
  </svg>
);

export const IconFormula = createIcon({
  name: 'IconFormula',
  xs: IconFormulaSizeXS,
  s: IconFormulaSizeS,
  m: IconFormulaSizeM,
});
