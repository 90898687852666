import { FC, useState } from 'react';
import { Ingredient } from '../../../../../types/serverInterface/recipeDTO';
import RecipeIngredientsStepForm from './RecipeIngredientsStepForm';
import styles from './RecipeIngredientsFrom.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение ингредиента
 */
const initialStepValue: Ingredient = {
  qty: 0,
  required: true,
  maxNumberPortion: 1,
  actionSerialNumber: 0,
};

/**
 * Свойства компонента RecipeIngredientsForm
 */
type RecipeIngredientsFromProps = {
  /**
   * Список ингредиентов (Немного необычная структура, вложенность массивов необходима для отработки шагов с
   * несколькими ингредиентами)
   */
  ingredients: Ingredient[][];
  /**
   * Обработчик изменения списка ингредиентов
   *
   * @param data данные изменения списка ингредииентов
   */
  onChange: (data: Ingredient[][]) => void;
};

/**
 * Форма изменения ингредиентов рецепта
 */
const RecipeIngredientsForm: FC<RecipeIngredientsFromProps> = ({ ingredients, onChange }) => {
  const { t } = useTranslation();

  const [selectedStepIndex, setSelectedStepIndex] = useState<number | null>(null);

  // Обработчики
  const handleChange =
    (stepIndex: number) => (index: number) => (key: keyof Ingredient) => (value: string | null) => {
      const newState = [...ingredients];
      newState[stepIndex] = [...newState[stepIndex]];
      newState[stepIndex][index] = {
        ...newState[stepIndex][index],
        [key]: value,
      };
      onChange(newState);
    };

  const handleBooleanChange =
    (stepIndex: number) => (index: number) => (key: keyof Ingredient) => (checked: boolean) => {
      const newState = [...ingredients];
      newState[stepIndex] = [...newState[stepIndex]];
      newState[stepIndex][index] = {
        ...newState[stepIndex][index],
        [key]: checked,
      };

      onChange(newState);
    };

  const handleSelectChange =
    (stepIndex: number) => (index: number) => (key: keyof Ingredient) => (id: number | null) => {
      const newState = [...ingredients];
      newState[stepIndex] = [...newState[stepIndex]];
      newState[stepIndex][index] = {
        ...newState[stepIndex][index],
        [key]: id,
      };

      onChange(newState);
    };

  const handleTasteChange = (stepIndex: number) => (index: number) => (idList: number[]) => {
    const newState = [...ingredients];
    newState[stepIndex] = [...newState[stepIndex]];

    newState[stepIndex][index] = {
      ...newState[stepIndex][index],
      tastesId: idList,
    };

    onChange(newState);
  };

  const handleAddClick = () => {
    const newStep: Ingredient[] = [{ ...initialStepValue, actionSerialNumber: ingredients.length }];
    const newState: Ingredient[][] = [...ingredients, newStep];

    onChange(newState);
  };

  const handleDeleteStep = (index: number) => () => {
    const newState = [...ingredients];
    newState.splice(index, 1);
    onChange(newState);
  };

  const handleMoveStepUp = (index: number) => () => {
    if (index > 0) {
      const newState = [...ingredients];
      const tempStep = newState[index];
      newState[index] = newState[index - 1];
      newState[index - 1] = tempStep;
      onChange(newState);
    }
  };

  const handleMoveStepDown = (index: number) => () => {
    if (index < ingredients.length - 1) {
      const newState = [...ingredients];
      const tempStep = newState[index];
      newState[index] = newState[index + 1];
      newState[index + 1] = tempStep;
      onChange(newState);
    }
  };

  const handleSelectStep = (index: number) => () => {
    if (!selectedStepIndex) {
      setSelectedStepIndex(index);
    }

    if (selectedStepIndex === index) {
      setSelectedStepIndex(null);
    }

    if (
      selectedStepIndex &&
      ingredients.length > selectedStepIndex &&
      ingredients.length > index &&
      selectedStepIndex !== index
    ) {
      const newState = [...ingredients];
      const newSelectedStep = ingredients[index];

      newState[selectedStepIndex] = [...newState[selectedStepIndex]];
      newState[selectedStepIndex] = newState[selectedStepIndex].concat(newSelectedStep);
      newState.splice(index, 1);

      onChange(newState);
      setSelectedStepIndex(null);
    }
  };

  // TODO: 6917 исправить баг с разделением шага
  const handleDisconnectStep = (index: number) => () => {
    const newArr = JSON.parse(JSON.stringify(ingredients)) as Ingredient[][];
    // const newArr = [...ingredients];
    const disconnectStepsArr = newArr[index].map((item) => [item]);
    const restArr = newArr.splice(index + 1);
    if (index === 0) {
      newArr.splice(0, 1);
    }
    newArr.splice(0, index);
    onChange(newArr.concat(disconnectStepsArr, restArr));
    setSelectedStepIndex(index);
  };

  // render методы
  const renderRecipeIngredientsItemFormItem = (ingredients: Ingredient[], index: number) => (
    <RecipeIngredientsStepForm
      key={index}
      ingredients={ingredients}
      index={index}
      isSelectStep={index === selectedStepIndex}
      onChange={handleChange(index)}
      onBooleanChange={handleBooleanChange(index)}
      onSelectChange={handleSelectChange(index)}
      onTasteChange={handleTasteChange(index)}
      onDeleteStep={handleDeleteStep(index)}
      onMoveStepUp={handleMoveStepUp(index)}
      onMoveStepDown={handleMoveStepDown(index)}
      onSelectStep={handleSelectStep(index)}
      onDisconnectStep={handleDisconnectStep(index)}
    />
  );

  return (
    <div className={styles.form}>
      {ingredients.map(renderRecipeIngredientsItemFormItem)}
      <Button
        label={t('productBase.recipe.form.ingredients.addStep.button.label')}
        width="full"
        view="ghost"
        iconLeft={IconAdd as any}
        onClick={handleAddClick}
      />
    </div>
  );
};

export default RecipeIngredientsForm;
