import React, { FC, useState } from 'react';
import { ProductLineDTO } from '../../../../types/serverInterface/productLineDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import styles from './ProductLineItem.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { deleteProductLineAction } from '../../../../state/productBase/actions';
import { selectOrganizationId } from '../../../../state/organization/selectors';
import ProductLineInfo from '../ProductLineInfo';
import ProductLineForm from '../ProductLineForm';
import DeleteModal from '../../../../components/DeleteModal';
import ProductLineLocale from '../ProductLineLocale';
import TableContextMenu from '../../../../components/TableContextMenu';
import { IconKebab } from '@consta/uikit/IconKebab';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TableContextMenuItem } from '../../../../components/TableContextMenu/TableContextMenu';
import ProductList from '../../Product';
import { useTranslation } from 'react-i18next';

/**
 * Возможные элементы списка контекстного меню линейки продуктов
 */
enum ProductLineContextMenuItems {
  /**
   * Информация
   */
  INFO = 'INFO',
  /**
   * Локализация
   */
  LOCALE = 'LOCALE',
  /**
   * Удаление
   */
  DELETE = 'DELETE',
}

/**
 * Список элементов контекстного меню линейки продуктов с редактирование
 */
const productLineContextMenuItemsWithEdit = [
  {
    name: ProductLineContextMenuItems.INFO,
    label: 'productBase.productLine.contextMenu.INFO',
    iconLeft: IconInfo,
    group: 1,
  },
  {
    name: ProductLineContextMenuItems.LOCALE,
    label: 'productBase.productLine.contextMenu.LOCALE',
    iconLeft: IconWorldStroked,
    group: 1,
  },
  {
    name: ProductLineContextMenuItems.DELETE,
    label: 'productBase.productLine.contextMenu.DELETE',
    iconLeft: IconTrash,
    group: 2,
  },
];

/**
 * Список элементов контекстного меню линейки продуктов без редактирования
 */
const productLineContextMenuItemsWithoutEdit = [
  {
    name: ProductLineContextMenuItems.INFO,
    label: 'productBase.productLine.contextMenu.INFO',
    iconLeft: IconInfo,
    group: 1,
  },
];

/**
 * Свойства компонента ProductLineItem
 */
type ProductLineItemProps = {
  /**
   * Линейка продуктов
   */
  productLine: ProductLineDTO;
  /**
   * id бренда
   */
  brandId: number;
  /**
   * Возможность пользователя редактировать глобальные сущности
   */
  isAllowedGlobalEdit: boolean;
};

/**
 * Элемент списка линейки продуктов
 */
const ProductLineItem: FC<ProductLineItemProps> = ({
  productLine,
  brandId,
  isAllowedGlobalEdit,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector(selectOrganizationId());

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isEditProductLineOpen, setIsEditProductLineOpen] = useState(false);
  const [isProductLineInfoOpen, setIsProductLineInfoOpen] = useState(false);
  const [isDeleteProductLineOpen, setIsDeleteProductLineOpen] = useState(false);
  const [isProductLineLocaleOpen, setIsProductLineLocaleOpen] = useState(false);
  const [isProductListOpen, setIsProductListOpen] = useState(false);

  const { name, id } = productLine;
  const isAllowedEdit = !!productLine.organizationId || isAllowedGlobalEdit;

  // Обработчики
  const handleEditProductLineFormClose = () => {
    setIsEditProductLineOpen(false);
    setIsProductLineInfoOpen(true);
  };

  const handleEditProductLineClick = () => {
    setIsEditProductLineOpen(true);
    setIsProductLineInfoOpen(false);
  };

  const handleProductLineInfoClick = () => {
    setIsProductLineInfoOpen(true);
  };

  const handleProductLineInfoClose = () => {
    setIsProductLineInfoOpen(false);
  };

  const handleDeleteProductLineClick = () => {
    setIsDeleteProductLineOpen(true);
  };

  const handleDeleteProductLineCancel = () => {
    setIsDeleteProductLineOpen(false);
  };

  const handleDeleteProductLine = () => {
    setIsDeleteLoading(true);
    isAllowedEdit &&
      dispatch(deleteProductLineAction(id, brandId, selectedOrganizationId || null)).then(() => {
        setIsDeleteLoading(false);
        setIsDeleteProductLineOpen(false);
      });
  };

  const handleProductLineLocaleClick = () => {
    setIsProductLineLocaleOpen(true);
  };

  const handleProductLineLocaleCloseClick = () => {
    setIsProductLineLocaleOpen(false);
  };

  const handleProductListOpenClick = () => {
    setIsProductListOpen((prevState) => !prevState);
  };

  const handleContextMenuItemClick = (item: TableContextMenuItem) => {
    switch (item.name) {
      case ProductLineContextMenuItems.INFO:
        handleProductLineInfoClick();
        return;
      case ProductLineContextMenuItems.LOCALE:
        handleProductLineLocaleClick();
        return;
      case ProductLineContextMenuItems.DELETE:
        handleDeleteProductLineClick();
        return;
    }
  };

  const handleRowClick = () => {
    !isProductListOpen && setIsProductListOpen(true);
  };

  // render методы
  const renderEditProductLineForm = () => (
    <ProductLineForm
      type="edit"
      productLine={{ ...productLine, brandId, organizationId: selectedOrganizationId || null }}
      isOpen={isEditProductLineOpen}
      onClose={handleEditProductLineFormClose}
    />
  );

  const renderProductLineInfo = () => (
    <ProductLineInfo
      productLine={productLine}
      isOpen={isProductLineInfoOpen}
      isAllowedEdit={isAllowedEdit}
      onClose={handleProductLineInfoClose}
      onEditClick={handleEditProductLineClick}
    />
  );

  const renderDeleteProductLine = () => (
    <DeleteModal
      isOpen={isDeleteProductLineOpen}
      isDeleteLoading={isDeleteLoading}
      title={t('productBase.productLine.deleteModal.title', { name })}
      description={t('productBase.productLine.deleteModal.description', { name })}
      withConfirmDeletion={false}
      onClose={handleDeleteProductLineCancel}
      onDelete={handleDeleteProductLine}
    />
  );

  const renderProductLineLocale = () =>
    isProductLineLocaleOpen && (
      <ProductLineLocale
        productLineId={id}
        isOpen={isProductLineLocaleOpen}
        onClose={handleProductLineLocaleCloseClick}
      />
    );

  const renderProductList = () =>
    isProductListOpen && (
      <ProductList
        productLineId={id}
        organizationId={selectedOrganizationId || null}
        isAllowedGlobalEdit={isAllowedGlobalEdit}
      />
    );

  return (
    <div className={styles.tableProductLineItem}>
      <div className={styles.productLine} onClick={handleRowClick}>
        <div className={styles.productLineInfo}>
          <Text size="l">{name}</Text>
          <TableContextMenu
            className={styles.productLineContextMenu}
            buttonIcon={IconKebab}
            items={
              isAllowedEdit
                ? productLineContextMenuItemsWithEdit
                : productLineContextMenuItemsWithoutEdit
            }
            onItemClick={handleContextMenuItemClick}
          />
        </div>
        <Button
          view="clear"
          iconLeft={(isProductListOpen ? IconArrowUp : IconArrowDown) as any}
          onlyIcon
          onClick={handleProductListOpenClick}
        />
      </div>
      {renderProductList()}
      {renderProductLineInfo()}
      {isAllowedEdit && renderEditProductLineForm()}
      {isAllowedEdit && renderDeleteProductLine()}
      {isAllowedEdit && renderProductLineLocale()}
    </div>
  );
};

export default ProductLineItem;
