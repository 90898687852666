import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSaleMethodCashSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_6947_33258)">
      <path
        d="M16 6.06823V5.46691C16 2.1777 3 2.1777 3 5.46691V11.9808C3 12.4494 3.26798 12.868 3.77058 13.2285C1.6493 13.5659 0 14.3176 0 15.4882V18.4946C0 20.1393 3.27 21 6.50002 21C9.73003 21 13 20.1393 13 18.4946V16.3372C14.1796 16.7503 15.7891 16.9915 17.5 16.9915C20.73 16.9915 24 16.1307 24 14.4861V8.47329C24 6.5734 19.6641 5.77329 16 6.06823ZM9.50002 3.99996C13.0586 3.99996 15 4.99066 15 5.49996C15 6.00926 13.0586 6.99996 9.50002 6.99996C5.94145 6.99996 4.00003 6.00926 4.00003 5.49996C3.99998 4.99071 5.94141 3.99996 9.50002 3.99996ZM3.99998 6.89135C5.21325 7.62157 7.36233 7.99999 9.49997 7.99999C10.0534 7.99999 10.607 7.9729 11.1461 7.92284C11.0508 8.10241 11 8.29479 11 8.50001V9.92585C10.5042 9.97048 10.003 10 9.49997 10C5.94136 10 3.99998 9.0093 3.99998 8.50001V6.89135ZM3.99998 9.89135C5.21325 10.6216 7.36233 11 9.49997 11C10.0026 11 10.5034 10.9714 11 10.9293V13.6654C9.59142 13.1841 7.63078 12.9813 5.75255 13.0546L5.75583 13.0429C4.46775 12.6782 3.99998 12.2192 3.99998 12V9.89135ZM12 18.5C12 19.0093 10.0586 20 6.50002 20C2.94145 20 1.00003 19.0093 1.00003 18.5V16.8913C2.21325 17.6216 4.36238 18 6.50002 18C8.63766 18 10.7868 17.6216 12 16.8913V18.5ZM6.50002 17C2.94141 17 1.00003 16.0093 1.00003 15.5C1.00003 14.9907 2.94145 14 6.50002 14C10.0586 14 12 14.9907 12 15.5C12 16.0092 10.0586 17 6.50002 17ZM23 14.4861C23 14.9965 21.0586 15.9893 17.5 15.9893C15.698 15.9893 14.0405 15.7152 12.9707 15.2535C12.8805 14.8001 12.5305 14.4166 12 14.1015V12.874C13.2133 13.6058 15.3624 13.985 17.5 13.985C19.6377 13.985 21.7868 13.6058 23 12.874V14.4861ZM23 11.4797C23 11.9901 21.0586 12.9829 17.5 12.9829C13.9415 12.9829 12 11.9901 12 11.4797V9.86763C13.2133 10.5994 15.3623 10.9787 17.5 10.9787C19.6376 10.9787 21.7867 10.5994 23 9.86763V11.4797H23ZM17.5 9.97652C13.9414 9.97652 12 8.98366 12 8.47334C12 7.96296 13.9414 6.97015 17.5 6.97015C21.0585 6.97015 23 7.96301 23 8.47334C23 8.98366 21.0586 9.97652 17.5 9.97652Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_6947_33258">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconSaleMethodCash = createIcon({
  name: 'IconSaleMethodCash',
  s: IconSaleMethodCashSizeL,
  m: IconSaleMethodCashSizeL,
  xs: IconSaleMethodCashSizeL,
});
