import { FC, useEffect } from 'react';
import styles from './MachineSettings.module.scss';
import MachineSettingsActions from './MachineSettingsActions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  selectMachineBaseInfo,
  selectMachineSettings,
} from '../../../../state/machineControl/selectors';
import { getMachineSettingsAction } from '../../../../state/machineControl/actions';
import { Loader } from '@consta/uikit/Loader';
import MachineSettingsWater from './MachineSettingsWater';
import MachineDisconnect from './MachineDisconnect';
import MachineConnect from './MachineConnect';

/**
 * Свойства компонента MachineSettings
 */
type MachineSettingsProps = {
  machineId: number;
};

/**
 * Страница настроек автомата
 */
const MachineSettings: FC<MachineSettingsProps> = ({ machineId }) => {
  const dispatch = useAppDispatch();

  const { state: machineSettings } = useAppSelector(selectMachineSettings());
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  useEffect(() => {
    dispatch(getMachineSettingsAction(machineId));
  }, [dispatch, machineId]);

  if (!machineSettings || !machineBaseInfo) return <Loader />;

  const { isArchived } = machineSettings;

  return (
    <div className={styles.MachineSettings}>
      <MachineSettingsActions
        machineId={machineId}
        actions={machineSettings?.action}
        machine={machineBaseInfo.machine}
      />
      <MachineSettingsWater machineId={machineId} cellWaters={machineSettings?.cellWaters} />
      {/*<MachineSettingForms />*/}
      {/*<MachineSettingsDisposable />*/}
      {/*<MachineSettingsCalibration />*/}
      {isArchived ? <MachineConnect /> : <MachineDisconnect machineId={machineId} />}
    </div>
  );
};

export default MachineSettings;
