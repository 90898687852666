import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconSortUp } from '@consta/uikit/IconSortUp';
import { IconSortDown } from '@consta/uikit/IconSortDown';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import { Column } from '../../types';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { SortDirection } from '../../../../types/enums/sortDirection';

/**
 * Свойства компонента SortButton
 */
type SortButtonProps<T extends Record<string, any>, K extends keyof T> = {
  /**
   * Столбец
   */
  column: Column<T, K>;
  /**
   * Направление сортировки
   */
  sortOrder?: SortDirection | null | undefined;
};

/**
 * Кнопка сортировки
 */
const SortButton = <T extends Record<string, any>, K extends keyof T>({
  sortOrder,
  column,
}: SortButtonProps<T, K>) => {
  const { sortable } = column;

  // render методы
  const renderSort = () => {
    if (sortable) {
      if (column.type === 'default') {
        return (
          <Button
            onlyIcon
            view="clear"
            iconLeft={() => {
              switch (sortOrder) {
                case SortDirection.ASC:
                  return (<IconSortUp {...defaultIconProps} />) as any;
                case SortDirection.DESC:
                  return (<IconSortDown {...defaultIconProps} />) as any;
                default:
                  return (<IconHamburger {...defaultIconProps} />) as any;
              }
            }}
            onClick={() => column.onSortClick(column.key as K)}
          />
        );
      }

      return (
        <Button
          onlyIcon
          view="clear"
          iconLeft={() => {
            switch (sortOrder) {
              case SortDirection.ASC:
                return (<IconSortUp {...defaultIconProps} />) as any;
              case SortDirection.DESC:
                return (<IconSortDown {...defaultIconProps} />) as any;
              default:
                return (<IconHamburger {...defaultIconProps} />) as any;
            }
          }}
          // onClick={handleSortClick(data.key)}
        />
      );
    }

    return null;
  };

  return renderSort();
};

export default SortButton;
