import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectOrganizationId } from '../../../state/organization/selectors';
import { getRecipeListAction } from '../../../state/productBase/actions';
import { selectRecipeList } from '../../../state/productBase/selectors';
import { Loader } from '@consta/uikit/Loader';
import NoContent from '../../../components/NoContent';
import { RecipeDTO } from '../../../types/serverInterface/recipeDTO';
import TableCard from '../../../components/TableCard';
import TableContextMenu from '../../../components/TableContextMenu';
import { IconKebab } from '@consta/uikit/IconKebab';
import styles from './RecipeList.module.scss';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconTrash } from '@consta/uikit/IconTrash';
import RecipeModal from './RecipeModal';
import { RecipeContextMenuItems } from './types';
import { IconFormula } from '../../../assets/icon/iconFormula';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import CreateRecipe from './RecipeForm/CreateRecipe';
import { useRoleChecked } from '../../../helpers/roleChecked';
import { Roles } from '../../../types/serverInterface/cabinetDTO';

/**
 * Список элементов для контекстного меню рецепта с редактированием
 */
const recipeContextMenuItemsWithEdit = [
  {
    name: RecipeContextMenuItems.INFO,
    label: 'productBase.recipe.list.contextMenu.info.label',
    iconLeft: IconInfo,
    group: 1,
  },
  {
    name: RecipeContextMenuItems.INGREDIENTS,
    label: 'productBase.recipe.list.contextMenu.ingredients.label',
    iconLeft: IconFormula,
    group: 1,
  },
  {
    name: RecipeContextMenuItems.LOCALE,
    label: 'productBase.recipe.list.contextMenu.locale.label',
    iconLeft: IconWorldStroked,
    group: 1,
  },
  {
    name: RecipeContextMenuItems.DELETE,
    label: 'productBase.recipe.list.contextMenu.delete.label',
    iconLeft: IconTrash,
    group: 2,
  },
];

/**
 * Список элементов для контекстного меню рецепта без редактирования
 */
const recipeContextMenuItemsWithoutEdit = [
  {
    name: RecipeContextMenuItems.INFO,
    label: 'productBase.recipe.list.contextMenu.info.label',
    iconLeft: IconInfo,
    group: 1,
  },
  {
    name: RecipeContextMenuItems.INGREDIENTS,
    label: 'productBase.recipe.list.contextMenu.ingredients.label',
    iconLeft: IconFormula,
    group: 1,
  },
];

/**
 * Базовая страница рецептов
 */
const RecipeList: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const isAllowedGlobalEdit = useRoleChecked([Roles.MANUFACTURER, Roles.DEVELOPER]);

  const organizationId = useAppSelector(selectOrganizationId()) || null;
  const { state: recipeList, isLoading } = useAppSelector(selectRecipeList());

  const [selectedRecipeId, setSelectedRecipeId] = useState<number | null>(null);
  const [selectedRecipeStep, setSelectedRecipeStep] = useState<RecipeContextMenuItems | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const filterRecipe = useMemo(() => {
    return recipeList?.filter(({ name }) => name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [recipeList, searchQuery]);

  const selectedRecipeIsAllowedEdit = useMemo(() => {
    return (
      !!recipeList?.find(({ id }) => id === selectedRecipeId)?.organizationId || isAllowedGlobalEdit
    );
  }, [recipeList, selectedRecipeId]);

  useEffect(() => {
    dispatch(getRecipeListAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleContextMenuChange = (id: number) => (section: RecipeContextMenuItems) => {
    setSelectedRecipeId(id);
    setSelectedRecipeStep(section);
  };

  const handleRecipeModalClose = () => {
    setSelectedRecipeId(null);
    setSelectedRecipeStep(null);
  };

  const handleRecipeStepChange = (step: RecipeContextMenuItems) => {
    setSelectedRecipeStep(step);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleAddButtonClick = () => {
    setIsAddFormOpen(true);
  };

  const handleAddCloseButtonClick = () => {
    setIsAddFormOpen(false);
  };

  // render методы
  const renderRowAction = ({ id, organizationId }: RecipeDTO) => (
    <TableContextMenu
      className={styles.recipeContextmenu}
      buttonIcon={IconKebab}
      items={
        organizationId || isAllowedGlobalEdit
          ? recipeContextMenuItemsWithEdit
          : recipeContextMenuItemsWithoutEdit
      }
      onItemClick={({ name }) => handleContextMenuChange(id)(name as RecipeContextMenuItems)}
    />
  );

  const renderList = (recipeList: RecipeDTO[]) => (
    <TableCard
      rows={recipeList}
      columns={{
        name: {
          key: 'name',
          title: t('productBase.recipe.list.name.column.title'),
          alignment: 'left',
          getItemLabel: (data) => data.name,
        },
        description: {
          key: 'description',
          title: t('productBase.recipe.list.description.column.title'),
          alignment: 'left',
          getItemLabel: (data) => data.description,
        },
        updated: {
          key: 'updated',
          title: t('productBase.recipe.list.updated.column.title'),
          alignment: 'right',
          getItemLabel: (data) => data.updated,
        },
        id: {
          key: 'id',
          title: t('productBase.recipe.list.actions.column.title'),
          alignment: 'right',
          renderCell: renderRowAction,
        },
      }}
    />
  );

  const renderContent = () =>
    isLoading ? (
      <Loader />
    ) : filterRecipe && filterRecipe.length ? (
      renderList(filterRecipe)
    ) : (
      <NoContent />
    );

  const renderCreateForm = () => (
    <CreateRecipe
      isOpen={isAddFormOpen}
      organizationId={organizationId}
      onClose={handleAddCloseButtonClick}
    />
  );

  const renderRecipeModal = () =>
    selectedRecipeId &&
    selectedRecipeStep && (
      <RecipeModal
        recipeId={selectedRecipeId}
        selectedStep={selectedRecipeStep}
        organizationId={organizationId}
        isAllowedEdit={selectedRecipeIsAllowedEdit}
        onSelectedStepChange={handleRecipeStepChange}
        onClose={handleRecipeModalClose}
      />
    );

  const renderAction = () => (
    <div className={styles.actions}>
      <Button
        label={t('productBase.recipe.list.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddButtonClick}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
  );

  return (
    <>
      {renderAction()}
      {renderContent()}
      {renderCreateForm()}
      {renderRecipeModal()}
    </>
  );
};

export default RecipeList;
