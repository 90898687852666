import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSettingSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 8.25001C9.92888 8.25001 8.24995 9.92895 8.24995 12C8.24995 14.0711 9.92888 15.75 11.9999 15.75C14.071 15.75 15.7499 14.0711 15.7499 12C15.7499 9.92895 14.071 8.25001 11.9999 8.25001ZM9.74995 12C9.74995 10.7574 10.7573 9.75002 11.9999 9.75002C13.2426 9.75002 14.2499 10.7574 14.2499 12C14.2499 13.2427 13.2426 14.25 11.9999 14.25C10.7573 14.25 9.74995 13.2427 9.74995 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4147 3.2323C14.4342 -0.203084 9.56564 -0.203083 8.58523 3.2323C8.28146 4.2967 7.18792 4.92806 6.11423 4.65893C2.6489 3.7903 0.214599 8.00664 2.69952 10.5734C3.46944 11.3687 3.46944 12.6314 2.69952 13.4266C0.214599 15.9934 2.6489 20.2097 6.11423 19.3411C7.18792 19.072 8.28146 19.7033 8.58523 20.7677C9.56564 24.2031 14.4342 24.2031 15.4147 20.7677C15.7184 19.7033 16.812 19.072 17.8857 19.3411C21.351 20.2097 23.7853 15.9934 21.3004 13.4266C20.5305 12.6314 20.5305 11.3687 21.3004 10.5734C23.7853 8.00664 21.351 3.7903 17.8857 4.65893C16.812 4.92806 15.7184 4.2967 15.4147 3.2323ZM10.0276 3.64394C10.5939 1.6597 13.406 1.6597 13.9722 3.64394C14.4982 5.48678 16.3915 6.57987 18.2504 6.11391C20.2519 5.6122 21.6579 8.04752 20.2227 9.53005C18.8897 10.9069 18.8897 13.0931 20.2227 14.47C21.6579 15.9525 20.2519 18.3878 18.2504 17.8861C16.3915 17.4202 14.4982 18.5133 13.9722 20.3561C13.406 22.3403 10.5939 22.3403 10.0276 20.3561C9.50172 18.5133 7.60843 17.4202 5.74952 17.8861C3.74798 18.3878 2.34196 15.9525 3.77722 14.47C5.1102 13.0931 5.1102 10.9069 3.77722 9.53005C2.34196 8.04752 3.74798 5.6122 5.74952 6.11391C7.60843 6.57987 9.50172 5.48678 10.0276 3.64394Z"
    />
  </svg>
);

export const IconSetting = createIcon({
  name: 'IconSetting',
  s: IconSettingSizeS,
  m: IconSettingSizeS,
  xs: IconSettingSizeS,
});
