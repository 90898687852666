import { FC, useEffect } from 'react';
import styles from './Cabinet.module.scss';
import HeaderCard from '../../../components/HeaderCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectCabinet, selectCabinetIsLoading } from '../../../state/user/selectors';
import { Loader } from '@consta/uikit/Loader';
import { getCabinetAction } from '../../../state/user/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useTranslation } from 'react-i18next';
import { Grid, GridItem } from '@consta/uikit/Grid';
import ContentCard from '../../../components/ContentCard';
import { useNavigate } from 'react-router-dom';

/**
 * Личный кабинет
 */
const Cabinet: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isCabinetLoading = useAppSelector(selectCabinetIsLoading());
  const cabinet = useAppSelector(selectCabinet());

  useEffect(() => {
    dispatch(getCabinetAction());
  }, [dispatch]);

  if (isCabinetLoading) return <Loader />;

  if (!cabinet) return null;

  // Обработчики
  const handleEditClick = () => {
    navigate('/cabinet/edit');
  };

  const handleDocsClick = () => {
    navigate(`/cabinet/${cabinet.uuid}/docs`);
  };

  return (
    <div className={styles.cabinet}>
      <HeaderCard className={styles.header}>
        <div className={styles.headerContent}>
          <Grid cols={2} gap="l">
            <GridItem>
              <Text>{t('cabinet.header.name.title')}</Text>
            </GridItem>
            <GridItem>
              <Text>{cabinet.name}</Text>
            </GridItem>
            <GridItem>
              <Text>{t('cabinet.header.surname.title')}</Text>
            </GridItem>
            <GridItem>
              <Text>{cabinet.surname}</Text>
            </GridItem>
            <GridItem>
              <Text>{t('cabinet.header.patronymic.title')}</Text>
            </GridItem>
            <GridItem>
              <Text>{cabinet.patronymic}</Text>
            </GridItem>
          </Grid>
        </div>
        <div className={styles.headerAction}>
          <Button label={t('cabinet.header.edit.button.label')} onClick={handleEditClick} />
        </div>
      </HeaderCard>
      <Grid className={styles.content} cols={2} gap="3xl">
        <GridItem>
          <ContentCard onCLick={handleDocsClick}>
            <Text size="3xl">{t('cabinet.content.userDocs.card.title')}</Text>
            <Text size="l">{cabinet.countFileLinksUser}</Text>
          </ContentCard>
        </GridItem>
        <GridItem>
          <ContentCard>
            <Text size="3xl">{t('cabinet.content.employeeDocs.card.title')}</Text>
            <Text size="l">{cabinet.countFileLinksUserOrganization}</Text>
          </ContentCard>
        </GridItem>
      </Grid>
    </div>
  );
};

export default Cabinet;
