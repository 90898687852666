import { FC } from 'react';
import { ScheduleByForm, WeekdayEnum } from '../../../../../types/serverInterface/promoCodeDTO';
import styles from './ScheduleCardByForm.module.scss';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { enumToArray } from '../../../../../types/enums';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '../../../../../assets/icon/iconTrash';
import { transformDate } from '../../../../../helpers/transformDate';

/**
 * Свойства комопнента ScheduleCardByForm
 */
type ScheduleCardByFormProps = {
  /**
   * Элемент расписания
   */
  item: ScheduleByForm;
  /**
   * Обработчик нажатия на элемент
   *
   * @param schedule элемент расписания
   */
  onItemClick: (schedule: ScheduleByForm) => void;
  /**
   * Удаление расписания
   *
   * @param index index расписания
   */
  onDelete: () => void;
};

/**
 * Список дней недели
 */
const weekDayList = enumToArray(WeekdayEnum);

/**
 * Карточка расписания для формы промокода
 */
const ScheduleCardByForm: FC<ScheduleCardByFormProps> = ({ item, onItemClick, onDelete }) => {
  const { t } = useTranslation();

  const { to, from, name, weekday } = item;

  const selectedWeekDayList = weekDayList.filter((item) => weekday[item]);

  // Обработчики
  const handleItemClick = () => {
    onItemClick(item);
  };

  return (
    <div className={styles.ScheduleCardByForm} onClick={handleItemClick}>
      <VerticalContainer space={0}>
        <Text weight="semibold">{name}</Text>
        <Text view="brand">
          {`${transformDate(from)}-${transformDate(to)}, ${selectedWeekDayList.map((item) => t(`weekDay.small.${item}`)).join(' ')}`}
        </Text>
      </VerticalContainer>
      <Button
        view="clear"
        size="m"
        iconLeft={IconTrash as any}
        onlyIcon
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      />
    </div>
  );
};

export default ScheduleCardByForm;
