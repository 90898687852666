import { FC } from 'react';
import { TextField } from '@consta/uikit/TextField';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';
import { EditOutletDTO } from '../../../../../types/serverInterface/outletDTO';
import { getInputNumberValue } from '../../../../../helpers/ inputHelpers';
import styles from './EditOutletAddress.module.scss';

/**
 * Свойства компонента EditOutletAddress
 */
type EditOutletAddressProps = {
  /**
   * Торговая точка
   */
  outlet: EditOutletDTO;
  /**
   * Обработчик изменения торговой точки
   *
   * @param key ключ поля
   */
  onChange: (key: keyof EditOutletDTO) => ({ value }: { value: string | null }) => void;
};

/**
 * Форма изменения адреса торговой точки
 */
const EditOutletAddress: FC<EditOutletAddressProps> = ({ outlet, onChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        label={t('outlet.edit.address.country.input.label')}
        name="country"
        width="full"
        value={outlet.country}
        onChange={onChange('country')}
      />
      <TextField
        label={t('outlet.edit.address.city.input.label')}
        name="city"
        width="full"
        value={outlet.city}
        onChange={onChange('city')}
      />
      <HorizontalContainer space="l" isAutoSpace>
        <TextField
          label={t('outlet.edit.address.street.input.label')}
          name="street"
          width="full"
          value={outlet.street}
          onChange={onChange('street')}
        />
        <TextField
          className={styles.houseNumberField}
          label={t('outlet.edit.address.houseNumber.input.label')}
          name="houseNumber"
          width="full"
          value={getInputNumberValue(outlet.houseNumber)}
          onChange={onChange('houseNumber')}
        />
      </HorizontalContainer>
    </>
  );
};

export default EditOutletAddress;
