import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconGroup3FilledSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M4.32532 5.18406C3.89277 4.75391 3.625 4.15822 3.625 3.5C3.625 2.84178 3.89277 2.24609 4.32532 1.81594C4.07628 1.69366 3.79616 1.625 3.5 1.625C2.46447 1.625 1.625 2.46447 1.625 3.5C1.625 4.53553 2.46447 5.375 3.5 5.375C3.79616 5.375 4.07628 5.30634 4.32532 5.18406Z" />
    <path d="M4.125 3.5C4.125 2.46447 4.96447 1.625 6 1.625C7.03553 1.625 7.875 2.46447 7.875 3.5C7.875 4.53553 7.03553 5.375 6 5.375C4.96447 5.375 4.125 4.53553 4.125 3.5Z" />
    <path d="M7.67468 1.81594C8.10723 2.24609 8.375 2.84178 8.375 3.5C8.375 4.15822 8.10723 4.75391 7.67468 5.18406C7.92372 5.30634 8.20384 5.375 8.5 5.375C9.53553 5.375 10.375 4.53553 10.375 3.5C10.375 2.46447 9.53553 1.625 8.5 1.625C8.20384 1.625 7.92372 1.69366 7.67468 1.81594Z" />
    <path d="M2.625 8C2.625 6.96447 3.46447 6.125 4.5 6.125H7.5C8.53553 6.125 9.375 6.96447 9.375 8C9.375 9.03553 8.53553 9.875 7.5 9.875H4.5C3.46447 9.875 2.625 9.03553 2.625 8Z" />
    <path d="M0.125 7.5C0.125 6.46447 0.964466 5.625 2 5.625H4.5C3.18832 5.625 2.125 6.68832 2.125 8C2.125 8.51244 2.28729 8.98697 2.56328 9.375H2C0.964466 9.375 0.125 8.53553 0.125 7.5Z" />
    <path d="M9.875 8C9.875 8.51244 9.71271 8.98697 9.43672 9.375H10C11.0355 9.375 11.875 8.53553 11.875 7.5C11.875 6.46447 11.0355 5.625 10 5.625H7.5C8.81168 5.625 9.875 6.68832 9.875 8Z" />
  </svg>
);

const IconGroup3FilledSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M5.76711 6.9121C5.19038 6.33856 4.83335 5.54432 4.83335 4.66669C4.83335 3.78906 5.19038 2.99481 5.76711 2.42127C5.43506 2.25824 5.06157 2.16669 4.66669 2.16669C3.28597 2.16669 2.16669 3.28598 2.16669 4.66669C2.16669 6.0474 3.28597 7.16669 4.66669 7.16669C5.06157 7.16669 5.43506 7.07514 5.76711 6.9121Z" />
    <path d="M5.50002 4.66669C5.50002 3.28598 6.61931 2.16669 8.00002 2.16669C9.38073 2.16669 10.5 3.28598 10.5 4.66669C10.5 6.0474 9.38073 7.16669 8.00002 7.16669C6.61931 7.16669 5.50002 6.0474 5.50002 4.66669Z" />
    <path d="M10.2329 2.42127C10.8097 2.99481 11.1667 3.78906 11.1667 4.66669C11.1667 5.54432 10.8097 6.33856 10.2329 6.9121C10.565 7.07514 10.9385 7.16669 11.3334 7.16669C12.7141 7.16669 13.8334 6.0474 13.8334 4.66669C13.8334 3.28598 12.7141 2.16669 11.3334 2.16669C10.9385 2.16669 10.565 2.25824 10.2329 2.42127Z" />
    <path d="M3.50002 10.6667C3.50002 9.28598 4.61931 8.16669 6.00002 8.16669H10C11.3807 8.16669 12.5 9.28598 12.5 10.6667C12.5 12.0474 11.3807 13.1667 10 13.1667H6.00002C4.61931 13.1667 3.50002 12.0474 3.50002 10.6667Z" />
    <path d="M0.166687 10C0.166687 8.61931 1.28598 7.50002 2.66669 7.50002H6.00002C4.25112 7.50002 2.83335 8.91779 2.83335 10.6667C2.83335 11.3499 3.04974 11.9826 3.41772 12.5H2.66669C1.28598 12.5 0.166687 11.3807 0.166687 10Z" />
    <path d="M13.1667 10.6667C13.1667 11.3499 12.9503 11.9826 12.5823 12.5H13.3334C14.7141 12.5 15.8334 11.3807 15.8334 10C15.8334 8.61931 14.7141 7.50002 13.3334 7.50002H10C11.7489 7.50002 13.1667 8.91779 13.1667 10.6667Z" />
  </svg>
);

const IconGroup3FilledSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M8.65063 10.3681C7.78554 9.50781 7.25 8.31645 7.25 7C7.25 5.68355 7.78554 4.49219 8.65063 3.63188C8.15256 3.38733 7.59232 3.25 7 3.25C4.92893 3.25 3.25 4.92893 3.25 7C3.25 9.07107 4.92893 10.75 7 10.75C7.59232 10.75 8.15256 10.6127 8.65063 10.3681Z" />
    <path d="M8.25 7C8.25 4.92893 9.92893 3.25 12 3.25C14.0711 3.25 15.75 4.92893 15.75 7C15.75 9.07107 14.0711 10.75 12 10.75C9.92893 10.75 8.25 9.07107 8.25 7Z" />
    <path d="M15.3494 3.63188C16.2145 4.49219 16.75 5.68355 16.75 7C16.75 8.31645 16.2145 9.50781 15.3494 10.3681C15.8474 10.6127 16.4077 10.75 17 10.75C19.0711 10.75 20.75 9.07107 20.75 7C20.75 4.92893 19.0711 3.25 17 3.25C16.4077 3.25 15.8474 3.38733 15.3494 3.63188Z" />
    <path d="M5.25 16C5.25 13.9289 6.92893 12.25 9 12.25H15C17.0711 12.25 18.75 13.9289 18.75 16C18.75 18.0711 17.0711 19.75 15 19.75H9C6.92893 19.75 5.25 18.0711 5.25 16Z" />
    <path d="M0.25 15C0.25 12.9289 1.92893 11.25 4 11.25H9C6.37665 11.25 4.25 13.3766 4.25 16C4.25 17.0249 4.57458 17.9739 5.12655 18.75H4C1.92893 18.75 0.25 17.0711 0.25 15Z" />
    <path d="M19.75 16C19.75 17.0249 19.4254 17.9739 18.8734 18.75H20C22.0711 18.75 23.75 17.0711 23.75 15C23.75 12.9289 22.0711 11.25 20 11.25H15C17.6234 11.25 19.75 13.3766 19.75 16Z" />
  </svg>
);

export const IconGroup3Filled = createIcon({
  name: 'IconGroup3Filled',
  xs: IconGroup3FilledSizeXS,
  s: IconGroup3FilledSizeS,
  m: IconGroup3FilledSizeM,
});
