import React, { FC, useEffect, useMemo, useState } from 'react';
import DefaultModal from '../../../../../components/DefaultModal';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { selectEmployeesWithoutKey } from '../../../../../state/organization/selectors';
import {
  addMachineAccessesKeyAction,
  getMembersListWithoutKeyAction,
} from '../../../../../state/organization/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { MemberWithoutKeyDTO } from '../../../../../types/serverInterface/memberWithoutKeyDTO';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import styles from './AddKeys.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Avatar } from '@consta/uikit/Avatar';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@consta/uikit/Checkbox';

/**
 * Свойства компонента AddKeys
 */
type AddKeysProps = {
  /**
   * Флаг открытия формы
   */
  isOpen: boolean;
  /**
   * id организации
   */
  organizationId: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Форма добавления ключа доступа к автоматам
 */
const AddKeys: FC<AddKeysProps> = ({ isOpen, organizationId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const membersList = useAppSelector(selectEmployeesWithoutKey());

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMap, setSelectedMap] = useState<Record<string, boolean>>({});

  const filterMembersList = useMemo(() => {
    return membersList.filter(({ name, surname, patronymic }) =>
      `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, membersList]);

  const memberMap: Record<string, boolean> = useMemo(() => {
    const map: Record<string, boolean> = {};

    membersList.forEach(({ uuid }) => {
      map[uuid] = false;
    });

    return map;
  }, [membersList]);

  useEffect(() => {
    setSelectedMap(memberMap);
  }, [memberMap]);

  useEffect(() => {
    dispatch(getMembersListWithoutKeyAction(organizationId));
  }, [dispatch, organizationId]);

  if (!isOpen) return null;

  // Обработчики
  const handlePrimaryClick = () => {
    dispatch(
      addMachineAccessesKeyAction(
        organizationId,
        Object.keys(selectedMap)
          .filter((key) => selectedMap[key])
          .map((uuid) => ({ uuid })),
      ),
    ).then(() => {
      onClose();
    });
  };

  const handleCancelClick = () => {
    onClose();
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleSelectChange =
    (uuid: string) =>
    ({ checked }: { checked: boolean }) => {
      setSelectedMap((prevState) => ({ ...prevState, [uuid]: checked }));
    };

  // render методы
  const renderActions = () => (
    <>
      <Button label="Назад" view="clear" onClick={handleCancelClick} />
      <Button label="Добавить" onClick={handlePrimaryClick} />
    </>
  );

  const renderItem = ({ name, surname, position, uuid }: MemberWithoutKeyDTO) => {
    const fullName = `${name ? name : 'noName'} ${surname ? surname : 'noName'}`;

    return (
      <HorizontalContainer
        className={styles.memberCard}
        space={0}
        justify="stretch"
        isAutoSpace
        align="center"
      >
        <HorizontalContainer space="s" align="center">
          <Avatar className={styles.avatar} name={fullName} />
          <VerticalContainer space={0}>
            <Text className={styles.text} size="l" weight="medium">
              {fullName}
            </Text>
            <Text className={styles.text} size="l" view="secondary">
              {position ? t(`roles.${position}`) : 'Нет должности'}
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <Checkbox size="l" checked={selectedMap[uuid]} onChange={handleSelectChange(uuid)} />
      </HorizontalContainer>
    );
  };

  return (
    <DefaultModal
      className={styles.AddKeys}
      isOpen={isOpen}
      modalTitle="Добавить сотрудников"
      renderActions={renderActions}
      onClose={onClose}
    >
      <TextField
        width="full"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {filterMembersList.map(renderItem)}
    </DefaultModal>
  );
};

export default AddKeys;
