import { FC } from 'react';
import styles from './EditCustomFormItem.module.scss';
import {
  CustomFormItem,
  CustomFormItemNumberUnits,
  CustomFormItemType,
  UnionCustomFormItemType,
} from '../../../types/serverInterface/customForm';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { Combobox } from '@consta/uikit/Combobox';
import { enumToArray } from '../../../types/enums';
import { ErrorCustomForm, errorInitialValue } from '../customFormValidation';
import { useTranslation } from 'react-i18next';
import { FieldError } from '../../../types/types';

/**
 * Свойства компонента элемента формы изменения кастомной формы
 */
type EditCustomFormItemProps = {
  /**
   * Элемент формы
   */
  formItem: CustomFormItem;
  /**
   * Ошабки валидации
   */
  error: ErrorCustomForm | null;
  /**
   * Обработчик изменения названия
   */
  onChange: (key: keyof CustomFormItem) => (value: string | null) => void;
  /**
   * Полное изменение элемента
   */
  onFullItemChange: (value: CustomFormItem) => (error: ErrorCustomForm | null) => void;
  /**
   * Обработчик нажатия на кнопку удаления
   */
  onDeleteClick: () => void;
  /**
   * Обработчик нажатия на кнопку локализации
   */
  onLocaleClick: () => void;
};

/**
 * Список типов поля
 */
const typeList = enumToArray(CustomFormItemType).map((item) => String(item));

/**
 * Единицы измерения для числового поля
 */
const numberUnits = enumToArray(CustomFormItemNumberUnits).map((item) => String(item));

/**
 * Элемент формы изменения кастомной формы
 */
const EditCustomFormItem: FC<EditCustomFormItemProps> = ({
  formItem,
  error,
  onChange,
  onFullItemChange,
  onDeleteClick,
  onLocaleClick,
}) => {
  const { t } = useTranslation();

  // Вспомогательные методы
  const getDefaultValueInputType = () => {
    if (formItem.type === CustomFormItemType.NUMBER) {
      return 'number';
    }

    if (formItem.type === CustomFormItemType.STRING) {
      return 'text';
    }

    if (formItem.type === CustomFormItemType.BOOLEAN) {
      return 'text';
    }

    return 'default';
  };

  const getDefaultValue = () => {
    if (formItem.type === CustomFormItemType.NUMBER) {
      return String(formItem.value);
    }

    if (formItem.type === CustomFormItemType.STRING) {
      return formItem.value as string;
    }

    if (formItem.type === CustomFormItemType.BOOLEAN) {
      return 'false';
    }

    return 'default';
  };

  const getErrorItem = (formKey: keyof ErrorCustomForm) => (errorKey: keyof FieldError) =>
    error ? error?.[formKey]?.[errorKey] || undefined : undefined;

  // Обработчики
  const handleDeleteClick = () => {
    onDeleteClick();
  };

  const handleLocaleClick = () => {
    onLocaleClick();
  };

  const handleChange =
    (key: keyof CustomFormItem) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  const handleTypeChange = ({ value }: { value: string | null }) => {
    const newType = value as CustomFormItemType | null; // Приводим тип из строки к enum значению

    let newFormItem: UnionCustomFormItemType;

    if (newType === CustomFormItemType.NUMBER) {
      newFormItem = {
        type: newType,
        unit: CustomFormItemNumberUnits.ML,
        value: 0,
        minValue: null,
        maxValue: null,
      };
    } else if (newType === CustomFormItemType.STRING) {
      newFormItem = {
        type: newType,
        unit: undefined,
        value: '',
      };
    } else if (newType === CustomFormItemType.BOOLEAN) {
      newFormItem = {
        type: newType,
        value: false,
      };
    } else if (newType === CustomFormItemType.TIME) {
      newFormItem = {
        type: newType,
        value: null,
      };
    } else if (newType === CustomFormItemType.ARRAY) {
      newFormItem = {
        type: newType,
        value: null,
        items: [],
      };
    } else if (newType === CustomFormItemType.INTEGER) {
      newFormItem = {
        type: newType,
        value: null,
        minValue: null,
        maxValue: null,
        unit: null,
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onFullItemChange({ ...formItem, ...newFormItem })(
      error && {
        ...error,
        type: errorInitialValue,
        unit: errorInitialValue,
        defaultValue: errorInitialValue,
      },
    );
  };

  // render методы
  const renderUnitCombobox = () =>
    formItem.type === CustomFormItemType.NUMBER ? (
      <Combobox
        label={t('customForm.unit.select.label')}
        status={getErrorItem('unit')('status') as any}
        items={numberUnits}
        value={formItem.unit}
        getItemLabel={(data) => t(`productBase.units.${data}`)}
        getItemKey={(data) => data}
        onChange={handleChange('unit')}
      />
    ) : (
      <Combobox
        label={t('customForm.unit.select.label')}
        disabled
        items={numberUnits}
        getItemLabel={(data) => data}
        getItemKey={(data) => data}
        onChange={() => {
          null;
        }}
      />
    );

  const renderDefaultValueInput = () => {
    if (formItem.type === CustomFormItemType.NUMBER) {
      return (
        <TextField
          label={t('customForm.defaultValue.input.label')}
          width="full"
          status={getErrorItem('defaultValue')('status') as any}
          type={getDefaultValueInputType()}
          value={getDefaultValue()}
          onChange={handleChange('value')}
        />
      );
    }

    if (formItem.type === CustomFormItemType.STRING) {
      return (
        <TextField
          label={t('customForm.defaultValue.input.label')}
          width="full"
          status={getErrorItem('defaultValue')('status') as any}
          type={getDefaultValueInputType()}
          value={getDefaultValue()}
          onChange={handleChange('value')}
        />
      );
    }

    if (formItem.type === CustomFormItemType.BOOLEAN) {
      return (
        <TextField
          label={t('customForm.defaultValue.input.label')}
          width="full"
          type={getDefaultValueInputType()}
          value={getDefaultValue()}
        />
      );
    }

    return <TextField label={t('customForm.defaultValue.input.label')} width="full" disabled />;
  };

  return (
    <div className={styles.rowContent}>
      <div className={styles.contentItem}>
        <TextField
          label={t('customForm.name.input.label')}
          width="full"
          status={getErrorItem('name')('status') as any}
          value={formItem.name}
          onChange={handleChange('name')}
        />
        <TextField
          label={t('customForm.key.input.label')}
          width="full"
          status={getErrorItem('key')('status') as any}
          value={formItem.key}
          onChange={handleChange('key')}
        />
        <Button view="ghost" iconLeft={IconTrash as any} onlyIcon onClick={handleDeleteClick} />
      </div>
      <div className={styles.contentItem}>
        <Combobox
          label={t('customForm.type.select.label')}
          status={getErrorItem('type')('status') as any}
          items={typeList}
          value={formItem.type}
          getItemLabel={(data) => t(`productBase.units.${data}`)}
          getItemKey={(data) => data}
          onChange={handleTypeChange}
        />
        {renderDefaultValueInput()}
        {renderUnitCombobox()}
        <Button
          view="ghost"
          iconLeft={IconWorldStroked as any}
          onlyIcon
          onClick={handleLocaleClick}
        />
      </div>
      <div className={styles.contentItem}>
        <TextField
          label={t('customForm.description.input.label')}
          type="textarea"
          minRows={1}
          maxRows={5}
          width="full"
          value={formItem.description}
          onChange={handleChange('description')}
        />
      </div>
    </div>
  );
};

export default EditCustomFormItem;
