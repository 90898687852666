import { Routes, Route, useNavigate } from 'react-router-dom';
import Authenticated from './Authenticated';
import AuthRedirect from './AuthRedirect';
import Registration from './Registration';
import styles from './AuthPage.module.scss';
import { IconLogoFull } from '../../assets/icon/iconLogoFull';
import { defaultIconProps } from '../../consts/defaultIconProps';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../components/HorizontalContainer';

/**
 * Модуль авторизации
 */
const AuthPage = () => {
  const navigate = useNavigate();

  // Обработчики
  const handleSignInClick = () => {
    navigate('/');
  };

  return (
    <div className={styles.AuthPage}>
      <div className={styles.card}>
        <IconLogoFull className={styles.logo} {...defaultIconProps} />
        <Routes>
          <Route path="/registration/:hash" element={<Registration />} />
          <Route path="/redirect/*" element={<Authenticated />} />
          <Route path="/" element={<AuthRedirect />} />
        </Routes>
      </div>
      <Routes>
        <Route
          path="/registration/:hash"
          element={
            <HorizontalContainer space="xs">
              <Text size="l">Уже зарегистрировались?</Text>
              <Text size="l" view="link" cursor="pointer" onClick={handleSignInClick}>
                Войти
              </Text>
            </HorizontalContainer>
          }
        />
      </Routes>
    </div>
  );
};

export default AuthPage;
