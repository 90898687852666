import { ProductGroup, ProductView, WaterStorageTypes } from './storageDTO';
import { MachineModelBaseCellByList } from './machineModelDTO';
import { TypeDiscountEnum } from './promoCodeDTO';
import { Pagination } from '../types';
import { Roles } from './cabinetDTO';
import { Icons } from '../../helpers/getIcon';
import { CustomFormType } from './customForm';

/**
 * Статус online/offline автомата
 */
export enum ConnectionStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

/**
 * Цвета статусов
 */
export enum StatusColor {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export type MachineStatus = {
  /**
   * Текстовое обозначение статуса
   */
  text: string;
  /**
   * Дата возникновения статуса 09.04.2024 11:46
   */
  date: string | null;
  /**
   * Цвет статуса
   */
  color: StatusColor;
};

// /**
//  * dto создание автомата
//  */
// export type CreateMachineDTO = {
//   /**
//    * Название автомата
//    */
//   name: string;
//   /**
//    * Серийный номер автомата
//    */
//   serialNumber: string;
//   /**
//    * id организации, в которой привязан автомат
//    */
//   organizationId: number | null;
//   /**
//    * id модели автомата
//    */
//   machineModelId: number | null;
// };

/**
 * dto изменения автомата
 */
export type EditMachineDTO = {
  /**
   * Название автомата
   */
  name: string;
  /**
   * Серийный номер автомата
   */
  serialNumber: string;
  /**
   * id организации, в которой привязан автомат
   */
  organizationId: number | null;
  /**
   * id модели автомата
   */
  machineModelId: number | null;
};

// Ячейки автомата для списка автомата
/**
 * Ячейка продукта для списка автоматов
 */
// export type ProductCellsByList = MachineModelBaseCellByList & {
//   /**
//    * Максимальный объём ячейки
//    */
//   maxVolume: number;
//   /**
//    * Минимальный объём ячейки для отправки оповещения
//    */
//   minVolume: number;
//   /**
//    * Текущий объём ячейки
//    */
//   volume: number;
//   /**
//    * Название ячейки
//    */
//   name: string;
//   /**
//    * Группа продуктов
//    */
//   group: ProductGroup;
//   /**
//    * Внешний вид продуктов
//    */
//   view: ProductView;
// };

/**
 * Ячейка стакана для списка автоматов
 */
// export type CupCellByList = MachineModelBaseCellByList & {
//   /**
//    * Максимальный объём ячейки
//    */
//   maxVolume: number;
//   /**
//    * Минимальный объём ячейки для отправки оповещения
//    */
//   minVolume: number;
//   /**
//    * Текущий объём ячейки
//    */
//   volume: number;
//   /**
//    * Флаг считать количество
//    */
//   isCount: boolean;
// };

/**
 * Ячейка расходника для списка автоматов
 */
// export type DisposableCellByList = MachineModelBaseCellByList & {
//   /**
//    * Минимальный объём ячейки для отправки оповещения
//    */
//   minVolume: number;
//   /**
//    * Текущий объём ячейки
//    */
//   volume: number;
//   /**
//    * Название расходника
//    */
//   name: string;
//   /**
//    * Флаг считать количество
//    */
//   isCount: boolean;
// };

/**
 * Ячейка воды для списка автоматов
 */
// export type WaterCellByList = MachineModelBaseCellByList & {
//   /**
//    * Максимальный объём ячейки
//    */
//   maxVolume: number;
//   /**
//    * Минимальный объём ячейки для отправки оповещения
//    */
//   minVolume: number;
//   /**
//    * Текущий объём ячейки
//    */
//   volume: number;
//   /**
//    * Флаг считать количество
//    */
//   isCount: boolean;
//   /**
//    * Таймер годности фильтра
//    */
//   expirationTimer?: number;
//   /**
//    * Объём годности фильтра
//    */
//   filterValue?: number;
// };

/**
 * Ячейки автомата
 */
// export type MachineCellsDTO = {
//   /**
//    * Ячейки продуктов
//    */
//   cells: ProductCellsByList[];
//   /**
//    * Ячейки воды
//    */
//   cellWaters: WaterCellByList[];
//   /**
//    * Ячейки стаканов
//    */
//   cellCups: CupCellByList[];
//   /**
//    * Ячейки расходников
//    */
//   cellDisposables: DisposableCellByList[];
// };

/**
 * Модель для списка автоматов
 */
// export type ModelByMachineListDTO = { name: string; id: number };

/**
 * Автомат
 */
// export type MachineDTO = Omit<EditMachineDTO, 'organizationId'> &
//   MachineCellsDTO & {
//     /**
//      * id автомата
//      */
//     id: number;
//     /**
//      * Статус подключения автомата к телеметрии
//      */
//     connectionStatus: ConnectionStatus;
//     /**
//      * Статус автомата
//      */
//     status: MachineStatus;
//     /**
//      * Флаг нового автомата
//      */
//     isNew: true;
//     /**
//      * Модель автомата
//      */
//     model: ModelByMachineListDTO;
//     salePeriod: {
//       qty: number;
//     };
//   };

/**
 * Элемент простого списка автоматов
 */
export type MachineSmallListItemDTO = {
  /**
   * id автомата
   */
  id: number;
  /**
   * Название автомата
   */
  name: string;
  /**
   * id торговой точки
   */
  outletId: number;
};

// Ячейки наполнения автомата
/**
 * Ячейка продукта страницы наполнения автомата
 */
export type ProductCell = MachineModelBaseCellByList & {
  /**
   * id ячейки
   */
  id?: number;
  /**
   * Таймер годности
   */
  expirationTimer: number;
  /**
   * id категории ячейки
   */
  cellCategoryId: number;
  /**
   * id назначения ячейки
   */
  cellPurposeId: number;
  /**
   * id бренда
   */
  brandId: number | null;
  /**
   * id линейки продуктов
   */
  productLineId: number | null;
  /**
   * id продукта
   */
  productId: number | null;
  /**
   * Флаг включённости ячейки для продажи
   */
  isActive: boolean | null;
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Название ячейки
   */
  name: string;
  /**
   * Группа продуктов
   */
  group: ProductGroup;
  /**
   * Внешний вид продуктов
   */
  view: ProductView;
};

/**
 * Ячейка стакана страницы наполнения автомата
 */
export type CupCell = MachineModelBaseCellByList & {
  /**
   * id ячейки
   */
  id?: number;
  /**
   * Объём стакана
   */
  cupVolume: number;
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Флаг считать количество
   */
  isCount: boolean;
};

/**
 * Ячейка воды страницы наполения автомата
 */
export type WaterCell = MachineModelBaseCellByList & {
  /**
   * id ячейки
   */
  id?: number;
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Флаг считать количество
   */
  isCount: boolean;
  /**
   * Таймер годности фильтра
   */
  expirationTimer?: number;
  /**
   * Объём годности фильтра
   */
  filterValue?: number;
};

/**
 * Ячейка расходника страницы наполнения автомата
 */
export type DisposableCell = MachineModelBaseCellByList & {
  /**
   * id ячейки
   */
  id: number;
  /**
   * Минимальный объём ячейки для отправки оповещения
   */
  minVolume: number;
  /**
   * Текущий объём ячейки
   */
  volume: number;
  /**
   * Название расходника
   */
  name: string;
  /**
   * Флаг считать количество
   */
  isCount: boolean;
};

/**
 * dto получения склада автомата
 */
export type MachineStorageDTO = {
  /**
   * Ячейки продуктов
   */
  cells: ProductCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: CupCell[];
  /**
   * Ячейки воды
   */
  cellWaters: WaterCell[];
  /**
   * Ячейки расходников
   */
  cellDisposables: DisposableCell[];
};

export enum MachineCellGroup {
  // PRODUCT = 'PRODUCT',
  WATER = 'WATER',
  CUP = 'CUP',
  DISPOSABLE = 'DISPOSABLE',
}

export type MachineCellGroupUnion = ProductGroup | MachineCellGroup;

// Пополнение
export type RefillCellDTO = {
  /**
   * id ячейки
   */
  id: number | null;
  /**
   * Объём ячейки
   */
  volume: number;
  /**
   * Группа ячеек автомата
   */
  group: MachineCellGroupUnion;
};

// TODO: ниже новые dto

/**
 * Основная информация автомата
 */
export type MachineGeneralInfo = {
  /**
   * Название автомата
   */
  name: string;
  /**
   * Название модели автомата
   */
  modelName: string;
  /**
   * Серийный номер автомата
   */
  serialNumber: string;
};

/**
 * Базовая информация о автомате
 */
export type MachineBaseInfoDTO = {
  /**
   * Основная информация о автомате
   */
  machine: MachineGeneralInfo;
  /**
   * Статус подключения автомата
   */
  connectionStatus: ConnectionStatus;
  /**
   * Статус автомата
   */
  status: MachineStatus;
};

export enum SuitabilityContent {
  SUCCESS = 'SUCCESS',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED ',
}

export type MachineHomeCell = {
  id: number;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  group: ProductGroup;
  view: ProductView;
  suitabilityContent: SuitabilityContent;
  expirationTimer: number;
  isActive: boolean;
};

export type MachineHomeCupCell = {
  id: number;
  cellNumber: number;
  cupVolume: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  isCount: true;
  suitabilityContent: SuitabilityContent;
};

export type MachineHomeWaterCellRes = {
  id: number;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  isActive: boolean;
  isCount: boolean;
  suitabilityContent: SuitabilityContent;
  filterValue: number;
  expirationTimer: number;
};

export type MachineHomeWaterCellDTO = {
  id: number;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  isActive: boolean;
  isCount: boolean;
  suitabilityContent: SuitabilityContent;
  filterValue: number;
  expirationTimer: number;
};

export type MachineHomeCellsRes = {
  /**
   * Ячейки продуктов
   */
  cells: MachineHomeCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: MachineHomeCupCell[];
  /**
   * Ячейки воды
   */
  cellWaters: MachineHomeWaterCellRes[];
};

export type MachineHomeCellsDTO = {
  /**
   * Ячейки продуктов
   */
  cells: MachineHomeCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: MachineHomeCupCell[];
  /**
   * Ячейки воды
   */
  cellWaters: MachineHomeWaterCellDTO[];
};

/**
 * Выручка автомата на домашней странице автомата
 */
export type MachineHomeSalePeriod = {
  /**
   * За день
   */
  day: number;
  /**
   * За неделю
   */
  week: number;
  /**
   * За месяц
   */
  month: number;
  /**
   * Дата последней продажи 09.04.2024 11:46
   */
  dateLastSale: string;
};

/**
 * Тип сообщения от автомата
 */
export enum LogMachinesTypeEnum {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ALERT = 'ALERT',
}

/**
 * Сообщения от автомата
 */
export type LogMachines = {
  /**
   * Тип сообщения от автомата
   */
  type: LogMachinesTypeEnum;
  /**
   * Название сообщения
   */
  name: string;
  /**
   * Описание сообщения
   */
  description: string;
  /**
   * Дата сообщения
   */
  date: string; // 09.04.2024 11:46
};

/**
 * Элемент истории автомата
 */
export type MachineHistory = {
  /**
   * Имя сотрудника
   */
  name: string;
  /**
   * Фамилия сотрудника
   */
  surname: string;
  /**
   * Отчество сотрудника
   */
  patronymic: string;
  /**
   * Роль сотрудника
   */
  position: Roles;
  /**
   * Дата сообщения в истории 09.04.2024 11:46
   */
  date: string;
};

/**
 * dto домашней страницы автомата
 */
export type MachineHome = {
  /**
   * Выручка
   */
  salePeriod: MachineHomeSalePeriod;
  /**
   * Сообщения от автомата
   */
  logMachines: LogMachines[];
  /**
   * История автомата
   */
  histories: MachineHistory[];
};

export type MachineHomeRes = MachineHome & MachineHomeCellsRes;

export type MachineHomeDTO = MachineHome & MachineHomeCellsDTO;

export type MachineStorageInfoProduct = {
  brandId: number;
  ingredientLineId: number;
  ingredientId: number;
  isActive: boolean;
};

/**
 * Этот тип до трансформации. Требуется внести сюда изменения
 */
export type MachineStorageInfoProductCell = {
  id: number;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  productName: string;
  productLineName: string;
  brandName: string;
  group: ProductGroup;
  view: ProductView;
  expirationTimer: number;
  cellCategoryId: number;
  cellPurposeId: number;
  isActive: boolean;
  ingredientCell: MachineStorageInfoProduct | null;
};

/**
 * Этот тип трансформирован и используется внутри компонентов
 */
export type MachineStorageInfoProductCellFormatted = {
  id: number;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  brandName: string;
  ingredientLineName: string;
  ingredientName: string;
  group: ProductGroup;
  view: ProductView;
  expirationTimer: number;
  cellCategoryId: number;
  cellPurposeId: number;
  isActive: boolean;
  brandId: number | null;
  ingredientLineId: number | null;
  ingredientId: number | null;
  isActiveProduct: boolean | null;
};

export type MachineStorageInfoCupCell = {
  id: number;
  cellNumber: number;
  cupVolume: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  isCount: boolean;
  suitabilityContent: SuitabilityContent;
};

export type MachineStorageInfoWaterCellRes = {
  id: number;
  cellNumber: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  isActive: boolean;
  /**
   * Флаг "считать количество"
   *
   * Если true - способ подключения "бутылка"
   * Если false - способ подключения "кран"
   */
  isCount: boolean;
  suitabilityContent: SuitabilityContent;
  filterValue: number;
  expirationTimer: number;
};

export type MachineStorageInfoWaterCell = {
  suitabilityContent: SuitabilityContent;
  cellNumber: number;
  isCount: boolean;
  // bottle
  id: number;
  maxVolume: number;
  minVolume: number;
  volume: number;
  expirationTimer: number;

  // tap
  tapId: number;
  filterTimer: number;
  filterMaxVolume: number;
  filterVolume: number;
};

export type MachineStorageInfoDisposableCell = {
  id: number;
  cellNumber: number;
  minVolume: number;
  volume: number;
  name: string;
  /**
   * Флаг "считать количество"
   */
  isCount: boolean;
};

/**
 * res детальной страницы склада автомата
 */
export type MachineStorageInfoRes = {
  /**
   * id автомата
   */
  id: number;
  /**
   * Ячейки продуктов
   */
  cells: MachineStorageInfoProductCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: MachineStorageInfoCupCell[];
  /**
   * Ячейки воды
   */
  cellWaters: MachineStorageInfoWaterCellRes[];
  /**
   * Ячейки расходников
   */
  cellDisposables: MachineStorageInfoDisposableCell[];
};

// /**
//  * dto детальной страницы склада автомата
//  */
// export type MachineStorageInfoDTO = {
//   /**
//    * id автомата
//    */
//   id: number;
//   /**
//    * Ячейки продуктов
//    */
//   cells: MachineStorageInfoProductCell[];
//   /**
//    * Ячейки стаканов
//    */
//   cellCups: MachineStorageInfoCupCell[];
//   /**
//    * Ячейки воды
//    */
//   cellWaters: MachineStorageInfoWaterCell[];
//   /**
//    * Ячейки расходников
//    */
//   cellDisposables: MachineStorageInfoDisposableCell[];
// };

export type MachineStorageInfoFormatted = {
  /**
   * id автомата
   */
  id: number;
  /**
   * Ячейки продуктов
   */
  cells: MachineStorageInfoProductCellFormatted[];
  /**
   * Ячейки стаканов
   */
  cellCups: MachineStorageInfoCupCell[];
  /**
   * Ячейки воды
   */
  cellWaters: MachineStorageInfoWaterCell[];
  /**
   * Ячейки расходников
   */
  cellDisposables: MachineStorageInfoDisposableCell[];
};

export type MachinePriceCell = {
  // id: number | null;
  number: number;
  view: ProductView;
};

export type MachinePrice = {
  id: number | null;
  volume: number | null;
  price: number | null;
  isBlock: boolean;
};

export type MachinePriceInfo = {
  productName: string;
  productLineName: string;
  brandName: string;
  ingredientId: number;
  cell: MachinePriceCell[];
  prices: MachinePrice[];
};

export type MachinePricesInfoDTO = {
  qtyDosage: number;
  prices: MachinePriceInfo[];
};

export type MachinePriceInfoRes = {
  productName: string;
  productLineName: string;
  brandName: string;
  ingredientId: number;
  cell: MachinePriceCell[];
  prices: MachinePrice[];
};

export type MachinePricesInfoRes = {
  qtyDosage: number;
  prices: MachinePriceInfoRes[];
};

export type RefillMachineCell = {
  id: number;
  volume: number | null;
  group: ProductGroup | MachineCellGroup;
};

/**
 * Форма пополнения автомата
 */
export type RefillMachineStorageDTO = RefillMachineCell[];

/**
 * Изменённая цена для продукта
 */
export type EditMachinePrice = {
  /**
   * id цены
   */
  id: number | null;
  /**
   * id ингредиента
   */
  ingredientId: number | null;
  /**
   * Объём
   */
  volume: number | null;
  /**
   * Цена
   */
  price: number | null;
};

/**
 * Форма изменения цен
 */
export type EditMachinePricesDTO = {
  /**
   * Цены автомата
   */
  prices: EditMachinePrice[];
  /**
   * Калибровка ингредиентов автомата
   */
  calibrations: EditMachineCalibrationsDTO;
};

/**
 * Ячейка продуктов в форме изменения наполнения
 */
export type EditMachineStorageProductCell = {
  /**
   * id ячейки
   */
  id: number | null;
  /**
   * id ингредиента (из базы ингредиентов)
   */
  ingredientId: number | null;
  /**
   * Флаг "активная ячейка"
   */
  isActive: boolean | null;
  /**
   * Вместимость контейнера
   */
  maxVolume: number | null;
  /**
   * Минимальный объём, при котором высылается оповещение
   */
  minVolume: number | null;
  /**
   * Срок годности
   */
  expirationTimer: number | null;
};

/**
 * Ячейка воды в форме изменения наполнения
 */
export type EditMachineStorageWaterCell = {
  /**
   * id ячейки
   */
  id: number | null;
  /**
   * Вместимость контейнера
   */
  maxVolume: number | null;
  /**
   * Минимальный объём, при котором высылается оповещение
   */
  minVolume: number | null;
  /**
   * Флаг "активная ячейка"
   */
  isActive: boolean;
  /**
   * Флаг "считать количество"
   *
   * Если true - способ подключения "бутылка"
   * Если false - способ подключения "кран"
   */
  isCount: boolean;
  /**
   * Ресурс фильтра объём
   */
  filterValue: number | null;
  /**
   * Срок годности
   */
  expirationTimer: number | null;
};

/**
 * Ячейка стакана в форме изменения наполнения
 */
export type EditMachineStorageCupCell = {
  /**
   * id ячейки
   */
  id: number | null;
  /**
   * Вместимость контейнера
   */
  maxVolume: number | null;
  /**
   * Минимальный объём, при котором высылается оповещение
   */
  minVolume: number | null;
  /**
   * Флаг "считать количество"
   */
  isCount: boolean;
  /**
   * Объём стакана
   */
  cupVolume: number | null;
};

/**
 * Ячейка расходника в форме изменения наполнения
 */
export type EditMachineStorageDisposableCell = {
  /**
   * id ячейки
   */
  id: number | null;
  /**
   * Название расходника
   */
  name: string;
  /**
   * Флаг "считать количество"
   */
  isCount: boolean;
  /**
   * Минимальный объём, при котором высылается оповещение
   */
  minVolume: number | null;
};

/**
 * Ячейки автомата в форме изменения наполнения
 */
export type EditMachineStorageCells = {
  /**
   * Ячейки продуктов
   */
  cells: EditMachineStorageProductCell[];
  /**
   * Ячейки стаканов
   */
  cellCups: EditMachineStorageCupCell[];
  /**
   * Ячейки воды
   */
  cellWaters: EditMachineStorageWaterCell[];
  /**
   * Ячейки расходников
   */
  cellDisposables: EditMachineStorageDisposableCell[];
};

/**
 * Изменение калибровки ингредиента
 */
export type EditMachineCalibration = {
  /**
   * id ингредиента
   */
  ingredientId: number;
  /**
   * Конфигурация категории ячейки
   */
  categoryConfigMachine: CustomFormType;
  /**
   * Конфигурация назначения ячейки
   */
  purposeConfigMachine: CustomFormType;
};

export type EditMachineCalibrationsDTO = EditMachineCalibration[];

/**
 * dto изменения наполнения автомата
 */
export type EditMachineStorageDTO = EditMachinePricesDTO & {
  /**
   * Ячейки автомата
   */
  cells: EditMachineStorageCells;
};

/**
 * dto изменения базовой информации автомата
 */
export type EditMachineBaseInfoDTO = {
  /**
   * Название автомата
   */
  name: string;
  /**
   * Описание автомата
   */
  description: string;
};

export enum SaleMethod {
  CASH = 'CASH',
  CARD = 'CARD',
  QR_CODE = 'QR_CODE',
  RF_ID = 'RF_ID',
}

export type PromoCodeBySaleDto = {
  /**
   * Код промокода
   */
  code: string;
  /**
   * Размер скидки
   */
  amount?: number;
  /**
   * Тип скидки промокода
   */
  type: TypeDiscountEnum;
};

export type MachineSalesDTO = {
  id: number;
  /**
   * Название продукта
   */
  name: string;
  /**
   * Объём продукта
   */
  volume: number;
  /**
   * Единица измерения продукта
   */
  // TODO: тут необходимо согласовать enum (должен быть один из существующих)
  unit: string;
  /**
   * Стоимость
   */
  price: number;
  /**
   * Цена со скидкой
   */
  // TODO: тут потипизировать
  discountPrices?: {
    method: string;
    price: number;
  }[];
  /**
   * Тип оплаты
   */
  // TODO: тут может появится усложнение
  /**
   * Метод продажи (способ оплаты)
   */
  methods: SaleMethod[];
  /**
   * Промокод
   */
  promoCode?: PromoCodeBySaleDto;
  /**
   * Дата и время продажи
   *
   * '27.03.2024 16:03:22'
   */
  dateSale: string; // string, дата продажи
};

/**
 * Список продаж автомата
 */
export type MachineSalesRes = {
  /**
   * Список продаж автомата
   */
  data: MachineSalesDTO[];
  /**
   * Настройка страниц списка продаж автомата
   */
  pagination: Pagination;
};

/**
 * Статус кастомного действия
 */
enum MachineSettingsActionStatus {
  CREATE = 'CREATE',
  SEND = 'SEND',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

/**
 * dto статуса кастомного действия
 */
export type MachineSettingsActionStatusDTO = {
  /**
   * Код действия
   */
  code: string;
  /**
   * Статус действия
   */
  status: MachineSettingsActionStatus;
  /**
   * Текст выполнения действия
   */
  text: string;
  /**
   * Время выполнения
   */
  completed: string;
  /**
   * Время создания
   */
  created: string;
};

export type MachineSettingsCellWater = {
  type: WaterStorageTypes;
  maxVolume: number;
  filterValue: number;
  isActive: boolean;
};

export type MachineSettingsRes = {
  /**
   * Кастомные действия
   */
  action: string;
  /**
   * Кастомные формы
   */
  form: string;
  /**
   * Код активации автомата
   */
  activationCode: string;
  /**
   * Флаг архивности автомата
   */
  isArchived: boolean;
  /**
   * Ячейки воды
   */
  cellWaters: MachineSettingsCellWater[];
};

export type Action = {
  label: string;
  key: string;
  icon: Icons;
};

export type ActionGroup = {
  label: string;
  icon: Icons;
  actions: Action[];
};

/**
 * dto страницы настроек автомата
 */
export type MachineSettingsDTO = Omit<MachineSettingsRes, 'action'> & {
  action: ActionGroup[];
};

/**
 * dto отправки кастомного действия
 */
export type SendMachineCustomActionDTO = {
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Код кастомного действия
   */
  code: string;
};

/**
 * dto изменения способа подключения воды
 */
export type EditWaterCell = {
  /**
   * Тип подключения воды
   */
  type: WaterStorageTypes | null;
  /**
   * Объём
   */
  maxVolume: number | null;
  /**
   * Значение фильтра
   */
  filterValue: number | null;
};
