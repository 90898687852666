import * as Yup from 'yup';
import { CreateProductLineDTO } from '../../../../types/serverInterface/productLineDTO';

/**
 * Валидационная схема формы создания линейки продуктов
 */
export const createProductLineValidationScheme: Yup.ObjectSchema<CreateProductLineDTO> = Yup.object(
  {
    name: Yup.string().required(),
    mediaKey: Yup.string().required(),
    description: Yup.string(),
    brandId: Yup.number().required(),
    organizationId: Yup.number().nullable(),
  },
);
