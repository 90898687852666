import { Roles } from './cabinetDTO';

export const CreateEmployeeRoles = {
  [Roles.ORGANIZATION_MEMBER]: Roles.ORGANIZATION_MEMBER,
  [Roles.TECHNICIAN]: Roles.TECHNICIAN,
};

export const CreateEmployeeRolesWithOwner = {
  [Roles.ORGANIZATION_OWNER]: Roles.ORGANIZATION_OWNER,
  [Roles.ORGANIZATION_MEMBER]: Roles.ORGANIZATION_MEMBER,
  [Roles.TECHNICIAN]: Roles.TECHNICIAN,
};

/**
 * dto создания приглашения
 */
export type CreateInviteEmployeeDTO = {
  /**
   * Имя
   */
  name: string;
  /**
   * Фамилия
   */
  surname: string;
  /**
   * Отчество
   */
  patronymic?: string;
  /**
   * Должность
   */
  position: Roles;
  /**
   * email
   */
  email?: string;
  /**
   * Телефон
   */
  phone: string;
};
