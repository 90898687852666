import { FC, ReactNode } from 'react';
import styles from './ContentCard.module.scss';
import classNames from 'classnames';
import { DisplayType, displayTypeValue } from '../../pages/App';

type ContentCardProps = {
  /**
   * Контент внутри карточки
   */
  children?: ReactNode;
  /**
   * ClassName контейнера карточки
   */
  className?: string;
  /**
   * Обработчик клика по карточке
   */
  onCLick?: () => void;
};

/**
 * Карточка для контента
 */
const ContentCard: FC<ContentCardProps> = ({
  children,
  className,
  onCLick = () => {
    null;
  },
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const handleCardClick = () => {
    onCLick();
  };

  return (
    <div
      className={classNames(styles.contentCard, className, isLaptop && styles.laptop)}
      onClick={handleCardClick}
    >
      {children}
    </div>
  );
};

export default ContentCard;
