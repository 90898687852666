import { FC, useState } from 'react';
import {
  CreateComponentDTO,
  EditComponentDTO,
} from '../../../../types/serverInterface/componentDTO';
import { useAppDispatch } from '../../../../app/hooks/store';
import { createComponentAction, editComponentAction } from '../../../../state/productBase/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DefaultModal from '../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import { Combobox } from '@consta/uikit/Combobox';
import {
  ComponentTypeUnit,
  componentUnitEnergyValue,
  componentUnitVolumeList,
  componentUnitWeight,
  UnitWeight,
} from '../../../../types/enums/componentunits';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение формы создания возмоного компонента состава
 */
const createInitialValue: CreateComponentDTO = {
  name: '',
  typeUnit: ComponentTypeUnit.WEIGHT,
  defaultUnit: UnitWeight.G,
};

/**
 * Дополнительные свойства формы создания возможного компонента состава
 */
type CreateComponentFormProps = {
  /**
   * Тип формы
   */
  type: 'create';
};

/**
 * Дополнительные свойства формы изменения возможного компонента состава
 */
type EditComponentFormProps = {
  /**
   * Тип формы
   */
  type: 'edit';
  /**
   * Возможный компонент состава
   */
  component: EditComponentDTO;
  /**
   * id взможного компонента  состава
   */
  componentId: number;
};

/**
 * Объединение дополнительных свойств формы
 */
type UnionComponentFormProps = CreateComponentFormProps | EditComponentFormProps;

/**
 * Свойства компонента ComponentForm
 */
type ComponentFormProps = UnionComponentFormProps & {
  /**
   * Флаг открытия формы
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Тип элемента единицы измерения
 */
type ItemUnit = {
  /**
   * label единицы измерения
   */
  label: string;
  /**
   * id группы единиц измерения
   */
  groupId: number;
};

/**
 * Тип группы единиц измерения
 */
type GroupUnit = {
  /**
   * id группы единиц измерения
   */
  id: number;
  /**
   * label группы единиц измерения
   */
  label: string;
};

/**
 * Список групп единиц измерения
 */
const unitGroups: GroupUnit[] = [
  { label: ComponentTypeUnit.VOLUME, id: 1 },
  { label: ComponentTypeUnit.WEIGHT, id: 2 },
  { label: ComponentTypeUnit.ENERGY_VALUE, id: 3 },
];

/**
 * Список единиц измерения по группам
 */
const unitItems: ItemUnit[] = [
  ...componentUnitVolumeList.map((label) => ({ label, groupId: 1 })),
  ...componentUnitWeight.map((label) => ({ label, groupId: 2 })),
  ...componentUnitEnergyValue.map((label) => ({
    label,
    groupId: 3,
  })),
];

/**
 * Форма возможного коммпонента состава
 */
const ComponentForm: FC<ComponentFormProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, type, onClose } = props;

  const isCreate = type === 'create';

  const initialValue = isCreate ? createInitialValue : props.component;

  const [formValue, setFormValue] = useState<CreateComponentDTO | EditComponentDTO>(initialValue);

  // Вспомогательные методы
  const getGroupIdByLabel = (groupLabel: string) =>
    unitGroups.find((group) => group.label === groupLabel)?.id as number;

  const getGroupLabelById = (groupId: number) =>
    unitGroups.find((group) => group.id === groupId)?.label as ComponentTypeUnit;

  const getDefaultUnitComboboxValue = () =>
    formValue.defaultUnit
      ? { label: formValue.defaultUnit, groupId: getGroupIdByLabel(formValue.typeUnit) }
      : null;

  // Обработчики
  const handleSubmit = () => {
    if (isCreate) {
      dispatch(createComponentAction(formValue)).then(handleClose);
    }

    if (!isCreate) {
      dispatch(editComponentAction(formValue, props.componentId)).then(handleClose);
    }
  };

  const handleChange =
    (key: keyof (CreateComponentDTO | EditComponentDTO)) =>
    ({ value }: { value: string | null }) => {
      setFormValue((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
    setFormValue(createInitialValue);
  };

  // TODO: побаловаться с типизацией
  const handleDefaultUnitChange = ({ value }: { value: ItemUnit | null }) => {
    value &&
      setFormValue((prevState) => ({
        ...prevState,
        defaultUnit: value.label as any,
        typeUnit: getGroupLabelById(value.groupId) as any,
      }));
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBaseConfig.component.form.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button
        label={
          isCreate
            ? t('productBaseConfig.component.form.add.button.label')
            : t('productBaseConfig.component.form.edit.button.label')
        }
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={
        isCreate
          ? t('productBaseConfig.component.form.create.modal.title')
          : t('productBaseConfig.component.form.edit.modal.title')
      }
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      <TextField
        label={t('productBaseConfig.component.form.name.input.label')}
        name="name"
        width="full"
        value={formValue.name}
        onChange={handleChange('name')}
      />
      <Combobox
        label={t('productBaseConfig.component.form.defaultUnit.select.label')}
        items={unitItems}
        value={getDefaultUnitComboboxValue()}
        getItemKey={(data) => data.label}
        getGroupKey={(data) => data.id}
        getGroupLabel={(data) => t(`productBase.units.${data.label}`)}
        getItemLabel={(data) => t(`productBase.units.${data.label}`)}
        onChange={handleDefaultUnitChange}
        groups={unitGroups}
      />
    </DefaultModal>
  );
};

export default ComponentForm;
