import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconFilterSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2.125C2.24061 2.125 1.625 2.74061 1.625 3.5C1.625 4.25939 2.24061 4.875 3 4.875C3.75939 4.875 4.375 4.25939 4.375 3.5C4.375 2.74061 3.75939 2.125 3 2.125ZM2.375 3.5C2.375 3.15482 2.65482 2.875 3 2.875C3.34518 2.875 3.625 3.15482 3.625 3.5C3.625 3.84518 3.34518 4.125 3 4.125C2.65482 4.125 2.375 3.84518 2.375 3.5Z"
    />
    <path d="M6 3.125C5.79289 3.125 5.625 3.29289 5.625 3.5C5.625 3.70711 5.79289 3.875 6 3.875L10 3.875C10.2071 3.875 10.375 3.70711 10.375 3.5C10.375 3.29289 10.2071 3.125 10 3.125L6 3.125Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7.125C8.24061 7.125 7.625 7.74061 7.625 8.5C7.625 9.25939 8.24061 9.875 9 9.875C9.75939 9.875 10.375 9.25939 10.375 8.5C10.375 7.74061 9.75939 7.125 9 7.125ZM8.375 8.5C8.375 8.15482 8.65482 7.875 9 7.875C9.34518 7.875 9.625 8.15482 9.625 8.5C9.625 8.84518 9.34518 9.125 9 9.125C8.65482 9.125 8.375 8.84518 8.375 8.5Z"
    />
    <path d="M2 8.125C1.79289 8.125 1.625 8.29289 1.625 8.5C1.625 8.70711 1.79289 8.875 2 8.875L6 8.875C6.20711 8.875 6.375 8.70711 6.375 8.5C6.375 8.29289 6.20711 8.125 6 8.125L2 8.125Z" />
  </svg>
);

const IconFilterSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00008 2.83337C2.98756 2.83337 2.16675 3.65419 2.16675 4.66671C2.16675 5.67923 2.98756 6.50004 4.00008 6.50004C5.0126 6.50004 5.83341 5.67923 5.83341 4.66671C5.83341 3.65419 5.0126 2.83337 4.00008 2.83337ZM3.16675 4.66671C3.16675 4.20647 3.53984 3.83337 4.00008 3.83337C4.46032 3.83337 4.83341 4.20647 4.83341 4.66671C4.83341 5.12694 4.46032 5.50004 4.00008 5.50004C3.53984 5.50004 3.16675 5.12694 3.16675 4.66671Z"
    />
    <path d="M8.00008 4.16671C7.72394 4.16671 7.50008 4.39056 7.50008 4.66671C7.50008 4.94285 7.72394 5.16671 8.00008 5.16671L13.3334 5.16671C13.6096 5.16671 13.8334 4.94285 13.8334 4.66671C13.8334 4.39057 13.6096 4.16671 13.3334 4.16671L8.00008 4.16671Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 9.50004C10.9876 9.50004 10.1667 10.3209 10.1667 11.3334C10.1667 12.3459 10.9876 13.1667 12.0001 13.1667C13.0126 13.1667 13.8334 12.3459 13.8334 11.3334C13.8334 10.3209 13.0126 9.50004 12.0001 9.50004ZM11.1667 11.3334C11.1667 10.8731 11.5398 10.5 12.0001 10.5C12.4603 10.5 12.8334 10.8731 12.8334 11.3334C12.8334 11.7936 12.4603 12.1667 12.0001 12.1667C11.5398 12.1667 11.1667 11.7936 11.1667 11.3334Z"
    />
    <path d="M2.66675 10.8334C2.39061 10.8334 2.16675 11.0572 2.16675 11.3334C2.16675 11.6095 2.39061 11.8334 2.66675 11.8334H8.00008C8.27622 11.8334 8.50008 11.6095 8.50008 11.3334C8.50008 11.0572 8.27622 10.8334 8.00008 10.8334L2.66675 10.8334Z" />
  </svg>
);

const IconFilterSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.25C4.48122 4.25 3.25 5.48122 3.25 7C3.25 8.51878 4.48122 9.75 6 9.75C7.51878 9.75 8.75 8.51878 8.75 7C8.75 5.48122 7.51878 4.25 6 4.25ZM4.75 7C4.75 6.30964 5.30964 5.75 6 5.75C6.69036 5.75 7.25 6.30964 7.25 7C7.25 7.69036 6.69036 8.25 6 8.25C5.30964 8.25 4.75 7.69036 4.75 7Z"
    />
    <path d="M12 6.25C11.5858 6.25 11.25 6.58579 11.25 7C11.25 7.41421 11.5858 7.75 12 7.75L20 7.75C20.4142 7.75 20.75 7.41421 20.75 7C20.75 6.58579 20.4142 6.25 20 6.25L12 6.25Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 14.25C16.4812 14.25 15.25 15.4812 15.25 17C15.25 18.5188 16.4812 19.75 18 19.75C19.5188 19.75 20.75 18.5188 20.75 17C20.75 15.4812 19.5188 14.25 18 14.25ZM16.75 17C16.75 16.3096 17.3096 15.75 18 15.75C18.6904 15.75 19.25 16.3096 19.25 17C19.25 17.6904 18.6904 18.25 18 18.25C17.3096 18.25 16.75 17.6904 16.75 17Z"
    />
    <path d="M4 16.25C3.58579 16.25 3.25 16.5858 3.25 17C3.25 17.4142 3.58579 17.75 4 17.75L12 17.75C12.4142 17.75 12.75 17.4142 12.75 17C12.75 16.5858 12.4142 16.25 12 16.25L4 16.25Z" />
  </svg>
);

export const IconFilter = createIcon({
  name: 'IconFilter',
  xs: IconFilterSizeXS,
  s: IconFilterSizeS,
  m: IconFilterSizeM,
});
