import {
  EditProductDTO,
  ProductCharacteristics,
} from '../../../../../types/serverInterface/productDTO';

/**
 * Дефолтное значение базовой информации о продукте
 */
export const createProductBasicInitialValue: EditProductDTO = {
  name: '',
  mediaKey: '',
  description: '',
};

/**
 * Дефолтное значения характеристик продукта
 */
export const creteProductCharacteristicsInitialValue: ProductCharacteristics = {};
