/**
 * Возможные элементы контекстного меню рецепта
 */
import { enumToArray } from '../../../types/enums';

export enum RecipeContextMenuItems {
  /**
   * Информация
   */
  INFO = 'INFO',
  /**
   * Приготовление
   */
  INGREDIENTS = 'INGREDIENTS',
  /**
   * Локализация
   */
  LOCALE = 'LOCALE',
  /**
   * Удаление
   */
  DELETE = 'DELETE',
}

/**
 * Список шагов на странице информации
 */
export const recipeInfoStepsList = enumToArray(RecipeContextMenuItems).splice(
  0,
  3,
) as RecipeContextMenuItems[];
