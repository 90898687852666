import { CustomFormItem, CustomFormItemType } from '../../types/serverInterface/customForm';
import { LanguageEnum } from '../../types/serverInterface/locales';

/**
 * Получение локализованного значения поля
 *
 * @param formItem элемент кастомной формы
 * @param lang выбранный язык
 */
export const getLocaleCustomFormField = (formItem: CustomFormItem) => (lang: LanguageEnum) =>
  formItem.locale?.find((locale) => locale.lang === lang) || null;

/**
 * Ограничение значения по максимальному и минамальнму значению
 *
 * @param value значение
 * @param minValue минимальное значения
 * @param maxValue максимальное значение
 */
const validateMinMaxValue = (value: number, minValue: number | null, maxValue: number | null) => {
  let validateValue: number | null = null;

  if (minValue !== null && maxValue !== null && maxValue > minValue) {
    validateValue = value;

    if (minValue > value) {
      validateValue = minValue;
    }

    if (maxValue < value) {
      validateValue = maxValue;
    }
  } else if (minValue !== null && value < minValue) {
    validateValue = minValue;
  } else if (maxValue !== null && value > maxValue) {
    validateValue = maxValue;
  } else {
    validateValue = value;
  }

  return validateValue;
};

/**
 * Запись значений кастомной формы
 *
 * @param formItem элемент кастомной формы
 * @param value новое значение
 */
export const transformValueByFormItem =
  (formItem: CustomFormItem) =>
  (value: string | boolean | Date | null): CustomFormItem => {
    switch (formItem.type) {
      case CustomFormItemType.BOOLEAN:
        return { ...formItem, value: !!value };
      case CustomFormItemType.NUMBER: {
        const { minValue, maxValue } = formItem;
        const newValue = parseFloat(String(value));

        return {
          ...formItem,
          value: validateMinMaxValue(newValue, minValue, maxValue),
        };
      }
      case CustomFormItemType.INTEGER: {
        const { minValue, maxValue } = formItem;
        const newValue = parseInt(String(value));

        return {
          ...formItem,
          value: validateMinMaxValue(newValue, minValue, maxValue),
        };
      }
      case CustomFormItemType.TIME:
        return { ...formItem, value: value as Date };
      case CustomFormItemType.ARRAY:
        return { ...formItem, value: String(value) };
      case CustomFormItemType.STRING:
        return { ...formItem, value: String(value) };
      case null:
        return { ...formItem };
    }
  };
