import {
  SnackBrandListInfo,
  SnackProductInfoDTO,
  SnackProductListInfo,
} from '../../../../types/serverInterface/SnackProductBaseDTO';
import { getDataFromServer } from '../../../../helpers/getDataFromServer';

export const snackBranListMock: SnackBrandListInfo[] = [
  { id: 1, name: 'Nestle', productCount: 5 },
  { id: 2, name: 'Кондитерская фабрика "Красный Октябрь"', productCount: 9 },
  { id: 3, name: 'Mars', productCount: 3 },
  { id: 4, name: 'PepsiCo', productCount: 3 },
  { id: 5, name: 'Mondelez', productCount: 6 },
  { id: 6, name: 'Ferrero', productCount: 3 },
  { id: 7, name: 'Hershey', productCount: 2 },
  { id: 8, name: 'Торчин', productCount: 2 },
  { id: 9, name: 'Лев Левыч', productCount: 2 },
  { id: 10, name: 'Кофе в мешках', productCount: 2 },
  { id: 11, name: 'Chipsy', productCount: 2 },
  { id: 12, name: 'Кошерные деликатесы', productCount: 2 },
  { id: 13, name: 'Долина', productCount: 2 },
  { id: 14, name: 'Восток', productCount: 2 },
  { id: 15, name: 'Royal', productCount: 2 },
  { id: 16, name: 'Золотая рыбка', productCount: 2 },
  { id: 17, name: 'Cheetos', productCount: 2 },
  { id: 18, name: 'Pringles', productCount: 2 },
  { id: 19, name: 'Lay’s', productCount: 2 },
  { id: 20, name: 'Doritos', productCount: 2 },
];

export const snackProductListMock: (SnackProductListInfo & SnackProductInfoDTO)[] = [
  {
    id: 56,
    name: 'Вода детская Святой Источник Спортик 0,33л Спорт негаз. ПЭТ',
    brandId: 1,
    allergens: 'Кавё, Вамбан, Невак, Солаж',
    barcode: '111123235345',
    vendorCode: '234567890',
    brandName: 'Nestle',
    calories: 496,
    carbohydrates: 58.3,
    compound:
      'Молочный шоколад (сахар, какао масло, какао тертое, сухое цельное молоко, лактоза, сухая молочная сыворотка, молочный жир, эмульгатор (соевый лецитин), ароматизатор (ванилин)), сушеная мякоть кокоса, сахар, глюкозный сироп (кукурузный, пшеничный), эмульгатор (моно- и диглицериды жирных кислот), агент влагоудерживающий (глицерин), ароматизатор (ванилин), соль. содержит глютен. может содержать незначительное количество яичного белка, арахиса, фундука',
    description:
      'Молочный шоколад (сахар, какао масло, какао тертое, сухое цельное молоко, лактоза, сухая молочная сыворотка, молочный жир, эмульгатор (соевый лецитин), ароматизатор (ванилин)), сушеная мякоть кокоса, сахар, глюкозный сироп (кукурузный, пшеничный), эмульгатор (моно- и диглицериды жирных кислот), агент влагоудерживающий (глицерин), ароматизатор (ванилин), соль. содержит глютен. может содержать незначительное количество яичного белка, арахиса, фундука',
    fats: 25.8,
    proteins: 7.6,
  },
  {
    id: 1,
    name: 'Шоколадный батончик',
    brandId: 1,
    allergens: 'Орехи, молоко',
    barcode: '4820024700017',
    vendorCode: 'M-0452−2024',
    brandName: 'Nestle',
    calories: 250,
    carbohydrates: 30,
    compound: 'Шоколад, орехи',
    description: 'Вкусный шоколадный батончик',
    fats: 12,
    proteins: 5,
  },
  {
    id: 2,
    name: 'Печенье',
    brandId: 1,
    allergens: 'Глютен, яйца',
    barcode: '4820024700018',
    vendorCode: 'M-0453−2024',
    brandName: 'Nestle',
    calories: 150,
    carbohydrates: 70,
    compound: 'Мука, сахар, масло',
    description: 'Сладкое печенье с шоколадом',
    fats: 8,
    proteins: 3,
  },
  {
    id: 3,
    name: 'Молочный шоколад',
    brandId: 1,
    allergens: 'Орехи, молоко',
    barcode: '4820024700019',
    vendorCode: 'M-0454−2024',
    brandName: 'Nestle',
    calories: 520,
    carbohydrates: 65,
    compound: 'Шоколад, молоко',
    description: 'Нежный молочный шоколад',
    fats: 30,
    proteins: 6,
  },
  {
    id: 4,
    name: 'Какао',
    brandId: 1,
    allergens: 'Нет',
    barcode: '4820024700020',
    vendorCode: 'M-0455−2024',
    brandName: 'Nestle',
    calories: 180,
    carbohydrates: 40,
    compound: 'Какао-порошок, сахар',
    description: 'Ароматный напиток из какао',
    fats: 4,
    proteins: 8,
  },

  {
    id: 5,
    name: 'Шоколад "Алёнка"',
    brandId: 2,
    allergens: 'Орехи, молоко',
    barcode: '4601562000010',
    vendorCode: 'K-1001−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 550,
    carbohydrates: 60,
    compound: 'Шоколад, сгущенное молоко, орехи',
    description: 'Популярный молочный шоколад',
    fats: 35,
    proteins: 7,
  },
  {
    id: 6,
    name: 'Печенье "Юбилейное"',
    brandId: 2,
    allergens: 'Глютен, молоко',
    barcode: '4601562000027',
    vendorCode: 'K-1002−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 450,
    carbohydrates: 65,
    compound: 'Мука, сахар, масло',
    description: 'Традиционное мягкое печенье',
    fats: 18,
    proteins: 6,
  },
  {
    id: 7,
    name: 'Конфеты "Трюфели"',
    brandId: 2,
    allergens: 'Орехи, глютен',
    barcode: '4601562000034',
    vendorCode: 'K-1003−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 300,
    carbohydrates: 45,
    compound: 'Шоколад, орехи, кефир',
    description: 'Нежные конфеты с начинкой',
    fats: 20,
    proteins: 4,
  },
  {
    id: 8,
    name: 'Марципан "Солнышко"',
    brandId: 2,
    allergens: 'Миндаль',
    barcode: '4601562000041',
    vendorCode: 'K-1004−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 400,
    carbohydrates: 50,
    compound: 'Миндаль, сахар, вода',
    description: 'Сладость из миндального теста',
    fats: 10,
    proteins: 8,
  },
  {
    id: 9,
    name: 'Зефир "Фруктовый"',
    brandId: 2,
    allergens: 'Нет',
    barcode: '4601562000058',
    vendorCode: 'K-1005−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 250,
    carbohydrates: 60,
    compound: 'Сахар, яичный белок, фруктовый сок',
    description: 'Легкий зефир с фруктовым вкусом',
    fats: 0,
    proteins: 3,
  },
  {
    id: 10,
    name: 'Карамель "Кузя"',
    brandId: 2,
    allergens: 'Нет',
    barcode: '4601562000065',
    vendorCode: 'K-1006−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 350,
    carbohydrates: 80,
    compound: 'Сахар, глюкоза, ароматизаторы',
    description: 'Вкусная мягкая карамель',
    fats: 7,
    proteins: 1,
  },
  {
    id: 11,
    name: 'Шоколад "Молочный с орехами"',
    brandId: 2,
    allergens: 'Орехи, молоко',
    barcode: '4601562000072',
    vendorCode: 'K-1007−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 580,
    carbohydrates: 65,
    compound: 'Шоколад, орехи, сухое молоко',
    description: 'Шоколад с хрустящими орехами',
    fats: 36,
    proteins: 8,
  },
  {
    id: 12,
    name: 'Пирожное "Наполеон"',
    brandId: 2,
    allergens: 'Глютен, молоко, яйца',
    barcode: '4601562000089',
    vendorCode: 'K-1008−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 400,
    carbohydrates: 55,
    compound: 'Слоеное тесто, крем, сахар',
    description: 'Классическое пирожное с кремом',
    fats: 20,
    proteins: 5,
  },
  {
    id: 13,
    name: 'Мармелад "Фруктовый"',
    brandId: 2,
    allergens: 'Нет',
    barcode: '4601562000096',
    vendorCode: 'K-1009−2024',
    brandName: 'Кондитерская фабрика "Красный Октябрь"',
    calories: 250,
    carbohydrates: 65,
    compound: 'Сахар, желатин, фруктовый сок',
    description: 'Яркий мармелад с фруктовым вкусом',
    fats: 0,
    proteins: 2,
  },

  {
    id: 14,
    name: 'Шоколадный батончик "Mars"',
    brandId: 3,
    allergens: 'Молоко, орехи, глютен',
    barcode: '5012035823005',
    vendorCode: 'M-1001−2024',
    brandName: 'Mars',
    calories: 250,
    carbohydrates: 35,
    compound: 'Шоколад, карамель, молоко, сахар',
    description: 'Популярный батончик с молочным вкусом и карамелью.',
    fats: 12,
    proteins: 2,
  },
  {
    id: 15,
    name: 'Шоколадный батончик "Snickers"',
    brandId: 3,
    allergens: 'Молоко, орехи, глютен',
    barcode: '5012035823012',
    vendorCode: 'M-1002−2024',
    brandName: 'Mars',
    calories: 250,
    carbohydrates: 32,
    compound: 'Шоколад, карамель, арахис, сахар',
    description: 'Шоколадный батончик с арахисом и карамелью.',
    fats: 12,
    proteins: 4,
  },
  {
    id: 16,
    name: 'Шоколад "Twix"',
    brandId: 3,
    allergens: 'Молоко, орехи, глютен',
    barcode: '5012035823029',
    vendorCode: 'M-1003−2024',
    brandName: 'Mars',
    calories: 250,
    carbohydrates: 33,
    compound: 'Шоколад, печенье, карамель',
    description: 'Сладкий шоколадный батончик с печеньем и карамелью.',
    fats: 12,
    proteins: 3,
  },

  {
    id: 17,
    name: 'Чипсы "Lay\'s Соль"',
    brandId: 4,
    allergens: 'Молоко, глютен',
    barcode: '4602008097656',
    vendorCode: 'P-1001−2024',
    brandName: 'PepsiCo',
    calories: 530,
    carbohydrates: 50,
    compound: 'Картофель, масло растительное, соль',
    description: 'Хрустящие картофельные чипсы с легким соленым вкусом.',
    fats: 35,
    proteins: 6,
  },
  {
    id: 18,
    name: 'Напиток "Tropicana Апельсин"',
    brandId: 4,
    allergens: 'Нет аллергенов',
    barcode: '4602008097663',
    vendorCode: 'P-1002−2024',
    brandName: 'PepsiCo',
    calories: 45,
    carbohydrates: 9,
    compound: 'Сок апельсиновый, сахар, лимонная кислота',
    description: 'Свежеотжатый сок из спелых апельсинов.',
    fats: 0,
    proteins: 0,
  },
  {
    id: 19,
    name: 'Снеки "Doritos Сырные"',
    brandId: 4,
    allergens: 'Молоко, глютен',
    barcode: '4602008097670',
    vendorCode: 'P-1003−2024',
    brandName: 'PepsiCo',
    calories: 486,
    carbohydrates: 49,
    compound: 'Кукурузная мука, масло растительное, сырный порошок',
    description: 'Хрустящие кукурузные снеки с сырным вкусом.',
    fats: 28,
    proteins: 6,
  },

  {
    id: 20,
    name: 'Шоколад "Milka Оригинальный"',
    brandId: 5,
    allergens: 'Молоко, орехи',
    barcode: '7613035461024',
    vendorCode: 'M-1001−2024',
    brandName: 'Mondelez',
    calories: 540,
    carbohydrates: 60,
    compound: 'Какао-масло, сахар, сухое молоко',
    description: 'Нежный молочный шоколад с богатым вкусом.',
    fats: 30,
    proteins: 7,
  },
  {
    id: 21,
    name: 'Песочное печенье "Oreo"',
    brandId: 5,
    allergens: 'Молоко, пшеница, соя',
    barcode: '0661418110598',
    vendorCode: 'M-1002−2024',
    brandName: 'Mondelez',
    calories: 480,
    carbohydrates: 66,
    compound: 'Сахар, мука, растительное масло',
    description: 'Классика среди печенья с уникальным вкусом.',
    fats: 22,
    proteins: 4,
  },
  {
    id: 22,
    name: 'Конфеты "Milka Девочка с коровкой"',
    brandId: 5,
    allergens: 'Молоко, орехи',
    barcode: '7613035254835',
    vendorCode: 'M-1003−2024',
    brandName: 'Mondelez',
    calories: 600,
    carbohydrates: 62,
    compound: 'Шоколад, орехи, сахар',
    description: 'Шоколадные конфеты с ореховой начинкой.',
    fats: 35,
    proteins: 6,
  },
  {
    id: 23,
    name: 'Сникерс "Dairy Milk"',
    brandId: 5,
    allergens: 'Молоко, арахис',
    barcode: '7613035288941',
    vendorCode: 'M-1004−2024',
    brandName: 'Mondelez',
    calories: 550,
    carbohydrates: 64,
    compound: 'Шоколад, карамель, арахис',
    description: 'Сладкий шоколад с карамелью и хрустящим арахисом.',
    fats: 28,
    proteins: 9,
  },
  {
    id: 24,
    name: 'Крекеры "Ritz"',
    brandId: 5,
    allergens: 'Глютен, молоко',
    barcode: '076810271529',
    vendorCode: 'M-1005−2024',
    brandName: 'Mondelez',
    calories: 180,
    carbohydrates: 23,
    compound: 'Мука, растительное масло, соль',
    description: 'Хрустящие крекеры с восхитительным вкусом.',
    fats: 9,
    proteins: 2,
  },
  {
    id: 25,
    name: 'Готовый десерт "Купол"',
    brandId: 5,
    allergens: 'Молоко',
    barcode: '7613035208715',
    vendorCode: 'M-1006−2024',
    brandName: 'Mondelez',
    calories: 250,
    carbohydrates: 30,
    compound: 'Шоколад, молоко, сахар',
    description: 'Нежный десерт с шоколадной оболочкой и мягкой начинкой.',
    fats: 12,
    proteins: 4,
  },

  {
    id: 26,
    name: 'Конфеты "Ferrero Rocher"',
    brandId: 6,
    allergens: 'Орехи, молоко, глютен',
    barcode: '3017620422003',
    vendorCode: 'F-2001−2024',
    brandName: 'Ferrero',
    calories: 600,
    carbohydrates: 37,
    compound: 'Шоколад, лесные орехи, сахара',
    description: 'Изящные шоколадные конфеты с хрустящей оболочкой и ореховой сердцевиной.',
    fats: 43,
    proteins: 8,
  },
  {
    id: 27,
    name: 'Шоколадный бар "Rocher"',
    brandId: 6,
    allergens: 'Молоко, орехи',
    barcode: '3017620424779',
    vendorCode: 'F-2002−2024',
    brandName: 'Ferrero',
    calories: 520,
    carbohydrates: 50,
    compound: 'Шоколад, орехи, молоко, сахар',
    description: 'Нежный шоколадный бар с орехами и кремом.',
    fats: 30,
    proteins: 6,
  },
  {
    id: 28,
    name: 'Пастель "Ferrero Rondnoir"',
    brandId: 6,
    allergens: 'Глютен, молоко, орехи',
    barcode: '3017620421389',
    vendorCode: 'F-2003−2024',
    brandName: 'Ferrero',
    calories: 575,
    carbohydrates: 48,
    compound: 'Шоколад, сахар, прикосновение темного шоколада',
    description: 'Шоколадные конфеты с неповторимым вкусом и ароматом.',
    fats: 40,
    proteins: 8,
  },
  // Продукты для Hershey (id: 7)
  {
    id: 29,
    name: 'Шоколадные плитки "Hershey"',
    brandId: 7,
    allergens: 'Молоко, орехи, соя',
    barcode: '0340000001234',
    vendorCode: 'H-1001−2024',
    brandName: 'Hershey',
    calories: 210,
    carbohydrates: 28,
    compound: 'Шоколад, сахар, какао-масло, молоко',
    description: 'Классические шоколадные плитки Hershey с богатым вкусом.',
    fats: 12,
    proteins: 2,
  },
  {
    id: 30,
    name: 'Конфеты "Reese\'s Peanut Butter Cups"',
    brandId: 7,
    allergens: 'Орехи, молоко, соя',
    barcode: '0340000004567',
    vendorCode: 'H-1002−2024',
    brandName: 'Hershey',
    calories: 320,
    carbohydrates: 30,
    compound: 'Шоколад, арахисовое масло, сахар',
    description: 'Нежные конфеты с арахисовым маслом и шоколадом.',
    fats: 19,
    proteins: 8,
  },

  // Продукты для Торчин (id: 8)
  {
    id: 31,
    name: 'Соус "Торчин Классический"',
    brandId: 8,
    allergens: 'Глютен, соя',
    barcode: '4602491240012',
    vendorCode: 'T-2001−2024',
    brandName: 'Торчин',
    calories: 120,
    carbohydrates: 25,
    compound: 'Томатная паста, сахар, специи',
    description: 'Классический соус для приготовления блюд.',
    fats: 1,
    proteins: 2,
  },
  {
    id: 32,
    name: 'Приправа "Торчин для рыбы"',
    brandId: 8,
    allergens: 'Не содержит аллергенов',
    barcode: '4602491240029',
    vendorCode: 'T-2002−2024',
    brandName: 'Торчин',
    calories: 80,
    carbohydrates: 15,
    compound: 'Специи, соль',
    description: 'Приправа для рыбы с ароматом трав.',
    fats: 0,
    proteins: 3,
  },
  // Продукты для Лев Левыч (id: 9)
  {
    id: 33,
    name: 'Каша "Лев Левыч Гречневая"',
    brandId: 9,
    allergens: 'Не содержит аллергенов',
    barcode: '4600727000016',
    vendorCode: 'LL-3001−2024',
    brandName: 'Лев Левыч',
    calories: 90,
    carbohydrates: 20,
    compound: 'Гречка',
    description: 'Полезная гречневая каша без добавок.',
    fats: 1,
    proteins: 4,
  },
  {
    id: 34,
    name: 'Пшенная каша "Лев Левыч"',
    brandId: 9,
    allergens: 'Не содержит аллергенов',
    barcode: '4600727000023',
    vendorCode: 'LL-3002−2024',
    brandName: 'Лев Левыч',
    calories: 85,
    carbohydrates: 18,
    compound: 'Пшено',
    description: 'Нежная пшенная каша с полезными свойствами.',
    fats: 1,
    proteins: 3,
  },
  // Продукты для Кофе в мешках (id: 10)
  {
    id: 35,
    name: 'Кофе в мешках "Арабика"',
    brandId: 10,
    allergens: 'Не содержит аллергенов',
    barcode: '1234567890123',
    vendorCode: 'KM-4001−2024',
    brandName: 'Кофе в мешках',
    calories: 5,
    carbohydrates: 0,
    compound: 'Молотый кофе арабика',
    description: 'Отборный молотый кофе арабика в мешках.',
    fats: 0,
    proteins: 0,
  },
  {
    id: 36,
    name: 'Кофе в мешках "Робуста"',
    brandId: 10,
    allergens: 'Не содержит аллергенов',
    barcode: '1234567890456',
    vendorCode: 'KM-4002−2024',
    brandName: 'Кофе в мешках',
    calories: 6,
    carbohydrates: 0,
    compound: 'Молотый кофе робуста',
    description: 'Крепкий молотый кофе робуста в мешках.',
    fats: 0,
    proteins: 0,
  },
  // Продукты для Chipsy (id: 11)
  {
    id: 37,
    name: 'Чипсы "Chipsy Классические"',
    brandId: 11,
    allergens: 'Глютен',
    barcode: '3998765432101',
    vendorCode: 'C-5001−2024',
    brandName: 'Chipsy',
    calories: 540,
    carbohydrates: 50,
    compound: 'Картофель, растительное масло, соль',
    description: 'Хрустящие чипсы с натуральным вкусом.',
    fats: 36,
    proteins: 5,
  },
  {
    id: 38,
    name: 'Чипсы "Chipsy Вкусные"',
    brandId: 11,
    allergens: 'Глютен, молоко',
    barcode: '3998765432192',
    vendorCode: 'C-5002−2024',
    brandName: 'Chipsy',
    calories: 550,
    carbohydrates: 52,
    compound: 'Картофель, растительное масло, специи',
    description: 'Вкусные чипсы с пряностями.',
    fats: 35,
    proteins: 4,
  },
  // Продукты для Кошерные деликатесы (id: 12)
  {
    id: 39,
    name: 'Фалафель "Кошерные деликатесы"',
    brandId: 12,
    allergens: 'Глютен, орехи',
    barcode: '9801234567890',
    vendorCode: 'KD-6001−2024',
    brandName: 'Кошерные деликатесы',
    calories: 340,
    carbohydrates: 45,
    compound: 'Горох, специи, масло растительное',
    description: 'Натуральный фалафель с пряностями.',
    fats: 15,
    proteins: 8,
  },
  {
    id: 40,
    name: 'Хумус "Кошерные деликатесы"',
    brandId: 12,
    allergens: 'Горох',
    barcode: '9801234567891',
    vendorCode: 'KD-6002−2024',
    brandName: 'Кошерные деликатесы',
    calories: 200,
    carbohydrates: 20,
    compound: 'Нут, тахини, специи',
    description: 'Вкусный хумус на основе нута.',
    fats: 10,
    proteins: 7,
  },
  // Продукты для Долина (id: 13)
  {
    id: 41,
    name: 'Сыр "Долина Творожный"',
    brandId: 13,
    allergens: 'Молоко',
    barcode: '9912345678901',
    vendorCode: 'D-7001−2024',
    brandName: 'Долина',
    calories: 250,
    carbohydrates: 5,
    compound: 'Творог, сливочное масло, соль',
    description: 'Нежный творожный сыр, пастообразной консистенции.',
    fats: 20,
    proteins: 12,
  },
  {
    id: 42,
    name: 'Сметана "Долина 20%"',
    brandId: 13,
    allergens: 'Молоко',
    barcode: '9912345678902',
    vendorCode: 'D-7002−2024',
    brandName: 'Долина',
    calories: 200,
    carbohydrates: 4,
    compound: 'Сливки, закваска',
    description: 'Сметана с классическим вкусом и 20% жирности.',
    fats: 20,
    proteins: 5,
  },
  // Продукты для Восток (id: 14)
  {
    id: 43,
    name: 'Чай "Восток Черный"',
    brandId: 14,
    allergens: 'Не содержит аллергенов',
    barcode: '8001234567890',
    vendorCode: 'V-8001−2024',
    brandName: 'Восток',
    calories: 2,
    carbohydrates: 0,
    compound: 'Черный чай',
    description: 'Классический черный чай с насыщенным вкусом.',
    fats: 0,
    proteins: 0,
  },
  {
    id: 44,
    name: 'Чай "Восток Зеленый"',
    brandId: 14,
    allergens: 'Не содержит аллергенов',
    barcode: '8001234567891',
    vendorCode: 'V-8002−2024',
    brandName: 'Восток',
    calories: 2,
    carbohydrates: 0,
    compound: 'Зеленый чай',
    description: 'Ароматный зеленый чай с легким вкусом.',
    fats: 0,
    proteins: 0,
  },

  // Продукты для Royal (id: 15)
  {
    id: 45,
    name: 'Конфеты "Royal Сахарные"',
    brandId: 15,
    allergens: 'Не содержит аллергенов',
    barcode: '4000123456789',
    vendorCode: 'R-9001−2024',
    brandName: 'Royal',
    calories: 350,
    carbohydrates: 80,
    compound: 'Сахар, кукурузный сироп',
    description: 'Сладкие конфеты с сахаром',
    fats: 0,
    proteins: 0,
  },
  {
    id: 46,
    name: 'Шоколад "Royal Молочный"',
    brandId: 15,
    allergens: 'Молоко, орехи',
    barcode: '4000123456790',
    vendorCode: 'R-9002−2024',
    brandName: 'Royal',
    calories: 500,
    carbohydrates: 50,
    compound: 'Шоколад, сгущенное молоко',
    description: 'Молочный шоколад с нежным вкусом.',
    fats: 28,
    proteins: 8,
  },

  // Продукты для Золотая рыбка (id: 16)
  {
    id: 47,
    name: 'Снеки "Золотая рыбка Чесночные"',
    brandId: 16,
    allergens: 'Не содержит аллергенов',
    barcode: '5001234567890',
    vendorCode: 'ZR-1001−2024',
    brandName: 'Золотая рыбка',
    calories: 480,
    carbohydrates: 40,
    compound: 'Мука, растительное масло, чеснок',
    description: 'Хрустящие снеки со вкусом чеснока.',
    fats: 29,
    proteins: 7,
  },
  {
    id: 48,
    name: 'Снеки "Золотая рыбка Классические"',
    brandId: 16,
    allergens: 'Не содержит аллергенов',
    barcode: '5001234567891',
    vendorCode: 'ZR-1002−2024',
    brandName: 'Золотая рыбка',
    calories: 490,
    carbohydrates: 39,
    compound: 'Мука, растительное масло, соль',
    description: 'Классические снеки с хрустящей текстурой.',
    fats: 30,
    proteins: 6,
  },

  // Продукты для Cheetos (id: 17)
  {
    id: 49,
    name: 'Снеки "Cheetos Классические"',
    brandId: 17,
    allergens: 'Молоко, глютен',
    barcode: '8001234567892',
    vendorCode: 'C-1101−2024',
    brandName: 'Cheetos',
    calories: 530,
    carbohydrates: 55,
    compound: 'Кукуруза, растительное масло, специи',
    description: 'Хрустящие и остренькие снеки Cheetos.',
    fats: 34,
    proteins: 6,
  },
  {
    id: 50,
    name: 'Снеки "Cheetos Мягкие"',
    brandId: 17,
    allergens: 'Молоко, глютен',
    barcode: '8001234567893',
    vendorCode: 'C-1102−2024',
    brandName: 'Cheetos',
    calories: 520,
    carbohydrates: 54,
    compound: 'Кукуруза, растительное масло, сыр',
    description: 'Вкусные мягкие снеки с сырным вкусом.',
    fats: 32,
    proteins: 5,
  },

  // Продукты для Pringles (id: 18)
  {
    id: 51,
    name: 'Чипсы "Pringles Оригинальные"',
    brandId: 18,
    allergens: 'Глютен, молоко',
    barcode: '5051234567894',
    vendorCode: 'P-1201−2024',
    brandName: 'Pringles',
    calories: 540,
    carbohydrates: 60,
    compound: 'Картофель, растительное масло, соль',
    description: 'Классические хрустящие чипсы Pringles.',
    fats: 36,
    proteins: 7,
  },
  {
    id: 27,
    name: 'Чипсы "Pringles Сырные"',
    brandId: 18,
    allergens: 'Глютен, молоко',
    barcode: '5051234567895',
    vendorCode: 'P-1202−2024',
    brandName: 'Pringles',
    calories: 550,
    carbohydrates: 61,
    compound: 'Картофель, сыр, растительное масло',
    description: 'Сырные чипсы с ярким вкусом.',
    fats: 37,
    proteins: 6,
  },

  // Продукты для Lay’s (id: 19)
  {
    id: 52,
    name: 'Чипсы "Lay’s Классические"',
    brandId: 19,
    allergens: 'Глютен',
    barcode: '6001234567890',
    vendorCode: 'L-1301−2024',
    brandName: 'Lay’s',
    calories: 540,
    carbohydrates: 53,
    compound: 'Картофель, растительное масло, соль',
    description: 'Классические чипсы Lay’s с натуральным вкусом.',
    fats: 34,
    proteins: 6,
  },
  {
    id: 53,
    name: 'Чипсы "Lay’s Сырные"',
    brandId: 19,
    allergens: 'Глютен, молоко',
    barcode: '6001234567891',
    vendorCode: 'L-1302−2024',
    brandName: 'Lay’s',
    calories: 550,
    carbohydrates: 56,
    compound: 'Картофель, сыр, специи',
    description: 'Сырные чипсы Lay’s с ярким вкусом.',
    fats: 36,
    proteins: 6,
  },

  // Продукты для Doritos (id: 20)
  {
    id: 54,
    name: 'Чипсы "Doritos Сырные"',
    brandId: 20,
    allergens: 'Глютен, молоко',
    barcode: '7001234567890',
    vendorCode: 'D-1401−2024',
    brandName: 'Doritos',
    calories: 540,
    carbohydrates: 55,
    compound: 'Кукуруза, растительное масло, сыр',
    description: 'Сырные чипсы Doritos с ярким вкусом.',
    fats: 34,
    proteins: 6,
  },
  {
    id: 55,
    name: 'Чипсы "Doritos Острая Сырная"',
    brandId: 20,
    allergens: 'Глютен, молоко',
    barcode: '7001234567891',
    vendorCode: 'D-1402−2024',
    brandName: 'Doritos',
    calories: 540,
    carbohydrates: 56,
    compound: 'Кукуруза, растительное масло, острые специи',
    description: 'Острые чипсы Doritos с богатым вкусом.',
    fats: 35,
    proteins: 6,
  },
];

// Метод для получения списка продуктов по id бренда
export function getSnackProductsByBrandIdMock(
  brandId: number,
  products: (SnackProductListInfo & SnackProductInfoDTO)[],
) {
  return getDataFromServer(
    {
      productList: products.filter((product) => product.brandId === brandId) || [],
    },
    1000,
  );
}

// Метод для получения продукта по id
export function getSnackProductByIdMock(
  productId: number,
  products: (SnackProductListInfo & SnackProductInfoDTO)[],
) {
  const product = products.find((product) => product.id === productId) || products[0];

  return getDataFromServer(product as SnackProductInfoDTO);
}
