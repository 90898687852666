import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconThemeSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M5.99999 1C3.243 1 1 3.243 1 6C1 8.757 3.243 11 6.00001 11C8.75702 11 11 8.75702 11 6C11 3.24298 8.757 1 5.99999 1ZM4.20777 1.76356C4.40013 1.68188 4.59925 1.61322 4.80387 1.55806V10.4419C4.59925 10.3868 4.40013 10.3181 4.20777 10.2364V1.76354V1.76356ZM3.80777 10.0432C3.59872 9.92942 3.39962 9.79964 3.21166 9.65594V2.34406C3.3996 2.20038 3.59872 2.07058 3.80777 1.95678V10.0432ZM5.20387 1.46914C5.39867 1.435 5.59767 1.41332 5.79999 1.40462V10.5954C5.59767 10.5867 5.39867 10.565 5.20387 10.5309V1.46914ZM1.81556 4.09032V7.90968C1.5489 7.32768 1.4 6.68094 1.4 6C1.4 5.31906 1.5489 4.6723 1.81556 4.09032ZM2.21556 8.61176V3.38824C2.39076 3.13514 2.59056 2.90036 2.81168 2.68746V9.31254C2.59058 9.09964 2.39078 8.86486 2.21556 8.61176ZM6.19999 10.5954V1.4046C8.64398 1.5097 10.6 3.53058 10.6 6C10.6 8.46942 8.64398 10.4903 6.19999 10.5954Z" />
  </svg>
);

const IconThemeSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M7.99999 1C4.14019 1 1 4.1402 1 8C1 11.8598 4.14019 15 8.00001 15C11.8598 15 15 11.8598 15 8C15 4.14017 11.8598 1 7.99999 1ZM5.49088 2.06898C5.76019 1.95463 6.03895 1.85851 6.32542 1.78128V14.2187C6.03895 14.1415 5.76019 14.0454 5.49088 13.931V2.06898ZM4.93088 13.6605C4.6382 13.5012 4.35946 13.3195 4.09632 13.1183V2.88168C4.35943 2.68053 4.6382 2.49881 4.93088 2.33949V13.6605ZM6.88542 1.6568C7.15814 1.609 7.43674 1.57865 7.71999 1.56647V14.4336C7.43674 14.4214 7.15814 14.391 6.88542 14.3432V1.6568ZM2.14178 5.32645V10.6736C1.76846 9.85875 1.56 8.95332 1.56 8C1.56 7.04668 1.76846 6.14122 2.14178 5.32645ZM2.70178 11.6565V4.34354C2.94706 3.9892 3.22678 3.6605 3.53635 3.36244V12.6376C3.22681 12.3395 2.94709 12.0108 2.70178 11.6565ZM8.27999 14.4336V1.56644C11.7016 1.71358 14.44 4.54281 14.44 8C14.44 11.4572 11.7016 14.2864 8.27999 14.4336Z" />
  </svg>
);

const IconThemeSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12 2C6.48599 2 2 6.486 2 12C2 17.514 6.48599 22 12 22C17.514 22 22 17.514 22 12C22 6.48596 17.514 2 12 2ZM8.41555 3.52712C8.80027 3.36376 9.19851 3.22644 9.60775 3.11612V20.8839C9.19851 20.7736 8.80027 20.6362 8.41555 20.4729V3.52712ZM7.61555 20.0864C7.19743 19.8588 6.79923 19.5993 6.42331 19.3119V4.68812C6.79919 4.40076 7.19743 4.14116 7.61555 3.91356V20.0864ZM10.4077 2.93828C10.7973 2.87 11.1953 2.82664 11.6 2.80924V21.1908C11.1953 21.1734 10.7973 21.13 10.4077 21.0618V2.93828ZM3.63112 8.18064V15.8194C3.0978 14.6554 2.8 13.3619 2.8 12C2.8 10.6381 3.0978 9.3446 3.63112 8.18064ZM4.43112 17.2235V6.77648C4.78151 6.27028 5.18111 5.80072 5.62335 5.37492V18.6251C5.18115 18.1993 4.78155 17.7297 4.43112 17.2235ZM12.4 21.1908V2.8092C17.288 3.0194 21.2 7.06116 21.2 12C21.2 16.9388 17.288 20.9806 12.4 21.1908Z" />
  </svg>
);

export const IconTheme = createIcon({
  name: 'IconTheme',
  xs: IconThemeSizeXS,
  s: IconThemeSizeS,
  m: IconThemeSizeM,
});
