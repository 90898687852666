import styles from './TableRow.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Column } from '../types';
import { useMemo } from 'react';
import classNames from 'classnames';
import { DisplayType, displayTypeValue } from '../../../pages/App';

type TableRow<T, K extends keyof T> = {
  data: T;
  rowIndex: number;
  columns: Record<K, Column<T, K>>;
  onRowClick?: (data: T) => void;
};

/**
 * Компонент строки таблицы вида "карточки"
 */
const TableRow = <T, K extends keyof T>({
  data,
  rowIndex,
  columns,
  onRowClick = () => null,
}: TableRow<T, K>) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { rowData, count } = useMemo(() => {
    const rowData: {
      cell: string | JSX.Element;
      alignment: 'left' | 'right';
      className?: string;
      width?: string;
    }[] = [];
    let count = 0;

    for (const key in columns) {
      if (columns.hasOwnProperty(key)) {
        if (!columns[key].multi) {
          rowData.push({
            cell: columns[key]?.renderCell?.(data, rowIndex) || (
              <Text size={isLaptop ? 'm' : 'l'} className={styles.text}>
                {columns[key].getItemLabel?.(data)}
              </Text>
            ),
            alignment: columns[key].alignment || 'left',
            className: columns[key].className,
            width: columns[key].width,
          });
          count++;
        }

        if (columns[key].multi) {
          const multiColumn = columns[key];

          const columnCount = multiColumn.count || 0;
          count = count + columnCount;
          for (let i = 0; i < columnCount; i++) {
            const renderMultiCell = multiColumn?.renderMultiCell;
            const cellData = data[key] as any[];

            renderMultiCell &&
              rowData.push({
                cell: renderMultiCell(cellData[i], rowIndex, i),
                alignment: columns[key].alignment || 'left',
                className: columns[key].className,
                width: columns[key].width,
              });
          }
        }
      }
    }

    return { rowData, count };
  }, [data, rowIndex, columns]);

  const handleRowClick = (data: T) => onRowClick(data);

  return (
    <tr
      className={classNames(styles.row, isLaptop && styles.LAPTOP)}
      onClick={() => handleRowClick(data)}
    >
      {rowData.map(({ cell, alignment, className }, index) => (
        <td
          key={index}
          className={classNames(styles.cell, alignment === 'right' && styles.alignRight, className)}
        >
          {cell}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
