import { FC, useState } from 'react';
import { LeftMenuItemGroupType } from '../type';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './LeftMenuItemGrpup.module.scss';
import { isLaptop } from '../../../pages/App';
import classNames from 'classnames';
import LeftMenuItem from './LeftMenuItem';
import { IconSelect } from '../../../assets/icon/iconSelect';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { IconSelectOpen } from '../../../assets/icon/iconSelectOpen';
import HorizontalContainer from '../../HorizontalContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type LeftMenuItemGroupProps = {
  group: LeftMenuItemGroupType;
};

const LeftMenuItemGroup: FC<LeftMenuItemGroupProps> = ({ group }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isSingleGroup = group.listItems.length === 1;
  const isViewGroup = group.listItems.length > 0;
  const isSelectedGroup = group.listItems?.find((item) => item.path === pathname);
  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleClick = (path: string) => {
    navigate(path);
  };

  if (!isViewGroup) return null;

  return (
    <div
      className={classNames(
        styles.group,
        (isOpen || isSelectedGroup) && styles.select,
        isLaptop && styles.isLaptop,
      )}
    >
      <div
        className={classNames(
          styles.LeftMenuItemGroupCard,
          (isOpen || isSelectedGroup) && styles.select,
          isSingleGroup && styles.single,
        )}
        onClick={() =>
          !isSingleGroup
            ? setIsOpen((prevState) => !prevState)
            : handleClick(group?.listItems[0]?.path)
        }
      >
        <HorizontalContainer space={isLaptop ? 'xs' : 's'}>
          {group.renderIcon()}
          <Text className={styles.text} weight="medium" size={isLaptop ? 'm' : 'l'}>
            {t(
              isSingleGroup
                ? group.listItems[0].fullLabel || group.listItems[0].label
                : group.label,
            )}
          </Text>
        </HorizontalContainer>
        {!isSingleGroup &&
          (isOpen || isSelectedGroup ? (
            <IconSelectOpen size="s" {...defaultIconProps} />
          ) : (
            <IconSelect size="s" {...defaultIconProps} />
          ))}
      </div>
      {!isSingleGroup &&
        (isOpen || isSelectedGroup) &&
        group.listItems.map((item) => <LeftMenuItem item={item} key={item.label} />)}
    </div>
  );
};

export default LeftMenuItemGroup;
