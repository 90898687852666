import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconDisconnectSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="21" viewBox="0 0 18 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.954915 5.06107C0 6.3754 0 8.25027 0 12C0 15.7497 0 17.6246 0.954915 18.9389C1.26331 19.3634 1.6366 19.7367 2.06107 20.0451C3.3754 21 5.25027 21 9 21C12.7497 21 14.6246 21 15.9389 20.0451C16.3634 19.7367 16.7367 19.3634 17.0451 18.9389C18 17.6246 18 15.7497 18 12C18 8.25027 18 6.3754 17.0451 5.06107C16.7367 4.6366 16.3634 4.26331 15.9389 3.95491C15.9159 3.93821 15.8928 3.92181 15.8694 3.90569C14.5584 3 12.6842 3 9 3C5.31596 3 3.44167 3 2.13068 3.9056C2.1073 3.92175 2.0841 3.93818 2.06107 3.95491C1.6366 4.26331 1.26331 4.6366 0.954915 5.06107ZM7.53033 9.46967C7.23744 9.17678 6.76256 9.17678 6.46967 9.46967C6.17678 9.76256 6.17678 10.2374 6.46967 10.5303L8.06066 12.1213L6.46967 13.7123C6.17678 14.0052 6.17678 14.4801 6.46967 14.773C6.76256 15.0659 7.23744 15.0659 7.53033 14.773L9.12132 13.182L10.7123 14.773C11.0052 15.0659 11.4801 15.0659 11.773 14.773C12.0659 14.4801 12.0659 14.0052 11.773 13.7123L10.182 12.1213L11.773 10.5303C12.0659 10.2374 12.0659 9.76256 11.773 9.46967C11.4801 9.17678 11.0052 9.17678 10.7123 9.46967L9.12132 11.0607L7.53033 9.46967Z"
    />
    <path d="M8.99996 0C10.8612 0 11.7919 0 12.545 0.244717C13.8631 0.672979 14.9346 1.6252 15.5194 2.86114C14.8992 2.59476 14.202 2.45008 13.4019 2.3672C12.2703 2.24999 10.8487 2.24999 9.04279 2.25H8.95714C7.15132 2.24999 5.72975 2.24999 4.59815 2.36719C3.798 2.45006 3.10072 2.59474 2.48047 2.86114C3.06527 1.6252 4.13682 0.672979 5.45487 0.244717C6.20803 0 7.13868 0 8.99996 0Z" />
  </svg>
);

const IconDisconnectSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="21" viewBox="0 0 18 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.954915 5.06107C0 6.3754 0 8.25027 0 12C0 15.7497 0 17.6246 0.954915 18.9389C1.26331 19.3634 1.6366 19.7367 2.06107 20.0451C3.3754 21 5.25027 21 9 21C12.7497 21 14.6246 21 15.9389 20.0451C16.3634 19.7367 16.7367 19.3634 17.0451 18.9389C18 17.6246 18 15.7497 18 12C18 8.25027 18 6.3754 17.0451 5.06107C16.7367 4.6366 16.3634 4.26331 15.9389 3.95491C15.9159 3.93821 15.8928 3.92181 15.8694 3.90569C14.5584 3 12.6842 3 9 3C5.31596 3 3.44167 3 2.13068 3.9056C2.1073 3.92175 2.0841 3.93818 2.06107 3.95491C1.6366 4.26331 1.26331 4.6366 0.954915 5.06107ZM7.53033 9.46967C7.23744 9.17678 6.76256 9.17678 6.46967 9.46967C6.17678 9.76256 6.17678 10.2374 6.46967 10.5303L8.06066 12.1213L6.46967 13.7123C6.17678 14.0052 6.17678 14.4801 6.46967 14.773C6.76256 15.0659 7.23744 15.0659 7.53033 14.773L9.12132 13.182L10.7123 14.773C11.0052 15.0659 11.4801 15.0659 11.773 14.773C12.0659 14.4801 12.0659 14.0052 11.773 13.7123L10.182 12.1213L11.773 10.5303C12.0659 10.2374 12.0659 9.76256 11.773 9.46967C11.4801 9.17678 11.0052 9.17678 10.7123 9.46967L9.12132 11.0607L7.53033 9.46967Z"
    />
    <path d="M8.99996 0C10.8612 0 11.7919 0 12.545 0.244717C13.8631 0.672979 14.9346 1.6252 15.5194 2.86114C14.8992 2.59476 14.202 2.45008 13.4019 2.3672C12.2703 2.24999 10.8487 2.24999 9.04279 2.25H8.95714C7.15132 2.24999 5.72975 2.24999 4.59815 2.36719C3.798 2.45006 3.10072 2.59474 2.48047 2.86114C3.06527 1.6252 4.13682 0.672979 5.45487 0.244717C6.20803 0 7.13868 0 8.99996 0Z" />
  </svg>
);

const IconDisconnectSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="21" viewBox="0 0 18 21" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.954915 5.06107C0 6.3754 0 8.25027 0 12C0 15.7497 0 17.6246 0.954915 18.9389C1.26331 19.3634 1.6366 19.7367 2.06107 20.0451C3.3754 21 5.25027 21 9 21C12.7497 21 14.6246 21 15.9389 20.0451C16.3634 19.7367 16.7367 19.3634 17.0451 18.9389C18 17.6246 18 15.7497 18 12C18 8.25027 18 6.3754 17.0451 5.06107C16.7367 4.6366 16.3634 4.26331 15.9389 3.95491C15.9159 3.93821 15.8928 3.92181 15.8694 3.90569C14.5584 3 12.6842 3 9 3C5.31596 3 3.44167 3 2.13068 3.9056C2.1073 3.92175 2.0841 3.93818 2.06107 3.95491C1.6366 4.26331 1.26331 4.6366 0.954915 5.06107ZM7.53033 9.46967C7.23744 9.17678 6.76256 9.17678 6.46967 9.46967C6.17678 9.76256 6.17678 10.2374 6.46967 10.5303L8.06066 12.1213L6.46967 13.7123C6.17678 14.0052 6.17678 14.4801 6.46967 14.773C6.76256 15.0659 7.23744 15.0659 7.53033 14.773L9.12132 13.182L10.7123 14.773C11.0052 15.0659 11.4801 15.0659 11.773 14.773C12.0659 14.4801 12.0659 14.0052 11.773 13.7123L10.182 12.1213L11.773 10.5303C12.0659 10.2374 12.0659 9.76256 11.773 9.46967C11.4801 9.17678 11.0052 9.17678 10.7123 9.46967L9.12132 11.0607L7.53033 9.46967Z"
    />
    <path d="M8.99996 0C10.8612 0 11.7919 0 12.545 0.244717C13.8631 0.672979 14.9346 1.6252 15.5194 2.86114C14.8992 2.59476 14.202 2.45008 13.4019 2.3672C12.2703 2.24999 10.8487 2.24999 9.04279 2.25H8.95714C7.15132 2.24999 5.72975 2.24999 4.59815 2.36719C3.798 2.45006 3.10072 2.59474 2.48047 2.86114C3.06527 1.6252 4.13682 0.672979 5.45487 0.244717C6.20803 0 7.13868 0 8.99996 0Z" />
  </svg>
);

export const IconDisconnect = createIcon({
  name: 'IconDisconnect',
  xs: IconDisconnectSizeXS,
  s: IconDisconnectSizeS,
  m: IconDisconnectSizeM,
});
