import { FC, useState } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { EditProductDTO } from '../../../../../types/serverInterface/productDTO';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента изменения базовой информации о продукте
 */
type ProductBasicFormProps = {
  /**
   * Значение формы
   */
  value: EditProductDTO;
  /**
   * Обработчик изменения данных в форме
   *
   * @param key ключ, какое поле меняется
   * @param value значение, на которое меняем
   */
  onChange: (key: keyof EditProductDTO) => (value: string | null) => void;
};

/**
 * Форма базовой информации о продукте
 */
const ProductBasicForm: FC<ProductBasicFormProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const { name, mediaKey, description } = value;

  const [isDescriptionFocus, setIsDescriptionFocus] = useState(false);

  // Обработчики
  const handleDescriptionFocus = () => setIsDescriptionFocus(true);

  const handleDescriptionBlur = () => setIsDescriptionFocus(false);

  const handleChange =
    (key: keyof EditProductDTO) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  return (
    <>
      <TextField
        label={t('productBase.product.form.basic.name.input.label')}
        width="full"
        value={name}
        onChange={handleChange('name')}
      />
      <TextField
        label={t('productBase.product.form.basic.mediaKey.input.label')}
        width="full"
        value={mediaKey}
        onChange={handleChange('mediaKey')}
      />
      <TextField
        label={t('productBase.product.form.basic.description.input.label')}
        type="textarea"
        width="full"
        minRows={2}
        maxRows={isDescriptionFocus ? 1000 : 2}
        value={description}
        onFocus={handleDescriptionFocus}
        onBlur={handleDescriptionBlur}
        onChange={handleChange('description')}
      />
    </>
  );
};

export default ProductBasicForm;
