import { FC } from 'react';
import { useAppSelector } from '../../../app/hooks/store';
import { selectTasteById } from '../../../state/productBase/selectors';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DeleteTaste
 */
type DeleteTasteProps = {
  /**
   * id вкуса
   */
  tasteId: number;
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

/**
 * Подтверждение удаления вкуса
 */
const DeleteTaste: FC<DeleteTasteProps> = ({ tasteId, isDeleteLoading, onClose, onDelete }) => {
  const { t } = useTranslation();

  const taste = useAppSelector(selectTasteById(tasteId));

  if (!taste) return null;

  const { name } = taste;

  return (
    <DeleteModal
      isOpen={!!tasteId}
      isDeleteLoading={isDeleteLoading}
      title={t('productBaseConfig.taste.deleteModal.title', { name })}
      description={t('productBaseConfig.taste.deleteModal.description', { name })}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteTaste;
