import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconHomeSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M3.14683 2.48278C1.81343 3.45156 1.14672 3.93595 0.884869 4.65146C0.863863 4.70885 0.844969 4.767 0.828226 4.82578C0.619503 5.55856 0.874161 6.34231 1.38348 7.90983C1.89279 9.47735 2.14745 10.2611 2.74703 10.7312C2.79512 10.769 2.84459 10.8049 2.89532 10.839C3.10864 10.9823 3.34378 11.0773 3.625 11.1403V9.40983C3.625 8.09815 4.68832 7.03483 6 7.03483C7.31168 7.03483 8.375 8.09815 8.375 9.40983V11.1403C8.65622 11.0773 8.89136 10.9823 9.10469 10.839C9.15542 10.8049 9.20488 10.769 9.25298 10.7312C9.85255 10.2611 10.1072 9.47735 10.6165 7.90983C11.1258 6.34231 11.3805 5.55856 11.1718 4.82578C11.155 4.767 11.1361 4.70885 11.1151 4.65146C10.8533 3.93595 10.1866 3.45156 8.85318 2.48278C7.51977 1.51401 6.85306 1.02961 6.09165 1.00168C6.03057 0.99944 5.96943 0.99944 5.90835 1.00168C5.14695 1.02961 4.48024 1.514 3.14683 2.48278Z" />
    <path d="M7.625 11.2366V9.40983C7.625 8.51237 6.89746 7.78483 6 7.78483C5.10254 7.78483 4.375 8.51237 4.375 9.40983V11.2366C4.81148 11.2639 5.33958 11.2639 6 11.2639C6.66042 11.2639 7.18852 11.2639 7.625 11.2366Z" />
  </svg>
);

const IconHomeSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M4.19578 3.31041C2.4179 4.60212 1.52896 5.24797 1.17982 6.20198C1.15182 6.27851 1.12663 6.35604 1.1043 6.43442C0.826004 7.41145 1.16555 8.45646 1.84464 10.5465C2.52373 12.6365 2.86327 13.6815 3.6627 14.3084C3.72683 14.3587 3.79278 14.4066 3.86043 14.452C4.14486 14.6431 4.45837 14.7698 4.83333 14.8537V12.5465C4.83333 10.7976 6.2511 9.37981 8 9.37981C9.7489 9.37981 11.1667 10.7976 11.1667 12.5465V14.8537C11.5416 14.7698 11.8551 14.6431 12.1396 14.452C12.2072 14.4066 12.2732 14.3587 12.3373 14.3084C13.1367 13.6815 13.4763 12.6365 14.1554 10.5465C14.8345 8.45646 15.174 7.41145 14.8957 6.43442C14.8734 6.35604 14.8482 6.27851 14.8202 6.20198C14.471 5.24797 13.5821 4.60212 11.8042 3.31042C10.0264 2.01872 9.13741 1.37286 8.1222 1.33561C8.04077 1.33263 7.95924 1.33263 7.8778 1.33561C6.86259 1.37286 5.97365 2.01871 4.19578 3.31041Z" />
    <path d="M10.1667 14.9822V12.5465C10.1667 11.3499 9.19662 10.3798 8 10.3798C6.80338 10.3798 5.83333 11.3499 5.83333 12.5465V14.9822C6.4153 15.0186 7.11945 15.0186 8 15.0186C8.88056 15.0186 9.5847 15.0186 10.1667 14.9822Z" />
  </svg>
);

const IconHomeSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M6.29367 4.96556C3.62685 6.90311 2.29344 7.87189 1.76974 9.30291C1.72773 9.41771 1.68994 9.534 1.65645 9.65157C1.23901 11.1171 1.74832 12.6846 2.76696 15.8197C3.78559 18.9547 4.2949 20.5222 5.49405 21.4625C5.59025 21.5379 5.68918 21.6098 5.79064 21.678C6.21729 21.9647 6.68756 22.1546 7.25 22.2805V18.8197C7.25 16.1963 9.37665 14.0697 12 14.0697C14.6234 14.0697 16.75 16.1963 16.75 18.8197V22.2805C17.3124 22.1546 17.7827 21.9647 18.2094 21.678C18.3108 21.6098 18.4098 21.5379 18.506 21.4625C19.7051 20.5222 20.2144 18.9547 21.2331 15.8197C22.2517 12.6846 22.761 11.1171 22.3436 9.65157C22.3101 9.534 22.2723 9.41771 22.2303 9.30291C21.7066 7.87189 20.3732 6.90312 17.7064 4.96557C15.0395 3.02801 13.7061 2.05923 12.1833 2.00336C12.0611 1.99888 11.9389 1.99888 11.8167 2.00336C10.2939 2.05923 8.96048 3.02801 6.29367 4.96556Z" />
    <path d="M15.25 22.4732V18.8197C15.25 17.0247 13.7949 15.5697 12 15.5697C10.2051 15.5697 8.75 17.0247 8.75 18.8197V22.4732C9.62296 22.5279 10.6792 22.5279 12 22.5279C13.3208 22.5279 14.377 22.5279 15.25 22.4732Z" />
  </svg>
);

export const IconHome = createIcon({
  name: 'IconHome',
  xs: IconHomeSizeXS,
  s: IconHomeSizeS,
  m: IconHomeSizeM,
});
