import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectUserDocuments } from '../../../state/user/selectors';
import { AddDocumentLink, EditDocumentLink } from '../../../types/serverInterface/documentLink';
import { editFileLinksUserAction, getUserDocumentLinksAction } from '../../../state/user/actions';
import DocumentForm from '../../../components/DocumentForm';
import DeleteModal from '../../../components/DeleteModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DocumentsList from '../../../components/DocumentsList';

const UserDocs: FC = () => {
  const { t } = useTranslation();

  const { userUuid } = useParams<{ userUuid: string }>();

  const documents = useAppSelector(selectUserDocuments());

  const dispatch = useAppDispatch();

  const [isOpenForm, setIsOpenForm] = useState(false);
  const [indexOfEdit, setIndexOfEdit] = useState<number | null>(null);
  const [documentLinks, setDocumentLinks] = useState<(AddDocumentLink | EditDocumentLink)[]>(
    documents.map(({ id, name, path }) => ({ id, name, path })),
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [indexOfDelete, setIndexOfDelete] = useState<number | null>(null);

  const isSaveButtonActive = JSON.stringify(documentLinks) !== JSON.stringify(documents);

  const editFormValue =
    indexOfEdit !== null && 0 <= indexOfEdit && indexOfEdit < documentLinks.length
      ? documentLinks[indexOfEdit]
      : null;

  // Обработчики
  useEffect(() => {
    userUuid && dispatch(getUserDocumentLinksAction(userUuid));
  }, [dispatch, userUuid]);

  useEffect(() => {
    setDocumentLinks(documents);
  }, [documents]);

  const handleAddClick = () => {
    setIndexOfEdit(null);
    setIsOpenForm(true);
  };

  const handleEditClick = (index: number) => {
    setIsOpenForm(true);
    setIndexOfEdit(index);
  };

  const handleAddFormSubmit = (data: AddDocumentLink) => {
    setDocumentLinks((prevState) => [...prevState, data]);
    setIsOpenForm(false);
  };

  const handleEditSubmitForm = (data: EditDocumentLink) => {
    if (indexOfEdit !== null && indexOfEdit >= 0 && indexOfEdit < documentLinks.length) {
      setDocumentLinks((prevState) => {
        const updatedDocumentLinks = [...prevState];
        updatedDocumentLinks[indexOfEdit] = data;
        return updatedDocumentLinks;
      });
    }

    setIsOpenForm(false);
  };

  const handleAddFormClose = () => {
    setIsOpenForm(false);
  };

  const handleSubmit = () => {
    userUuid &&
      dispatch(
        editFileLinksUserAction({
          userUuid,
          fileLinks: documentLinks,
        }),
      );
  };

  const handleDeleteClick = (index: number) => {
    setIsDeleteModalOpen(true);
    setIndexOfDelete(index);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setIndexOfDelete(null);
  };

  const handleDeleteModalDeleteClick = () => {
    if (indexOfDelete !== null && indexOfDelete >= 0 && indexOfDelete < documentLinks.length) {
      const updatedDocumentLinks = [...documentLinks];
      updatedDocumentLinks.splice(indexOfDelete, 1);
      setDocumentLinks(updatedDocumentLinks);
    }

    setIsDeleteModalOpen(false);
    setIndexOfDelete(null);
  };

  // Рендер методы
  const renderDocumentForm = () => (
    <DocumentForm
      isOpen={isOpenForm}
      value={editFormValue}
      index={indexOfEdit}
      onClose={handleAddFormClose}
      onAddSubmit={handleAddFormSubmit}
      onEditSubmit={handleEditSubmitForm}
    />
  );

  const renderDeleteModal = () => (
    <DeleteModal
      isOpen={isDeleteModalOpen}
      isDeleteLoading={false}
      title={t('documents.deleteModal.title')}
      description={t('documents.deleteModal.description')}
      withConfirmDeletion={false}
      onClose={handleDeleteModalClose}
      onDelete={handleDeleteModalDeleteClick}
    />
  );

  const renderSaveButton = () =>
    isSaveButtonActive && (
      <Button label={t('documents.save.button.label')} onClick={handleSubmit} />
    );

  return (
    <>
      {renderSaveButton()}
      {renderDocumentForm()}
      {renderDeleteModal()}
      <DocumentsList
        documentsList={documentLinks}
        onAddClick={handleAddClick}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
      />
    </>
  );
};

export default UserDocs;
