import { FC, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { RecipeContextMenuItems } from '../../types';
import {
  EditRecipeBasic,
  EditRecipeDTO,
  Ingredient,
  RecipeDetailsDTO,
} from '../../../../../types/serverInterface/recipeDTO';
import DefaultModal from '../../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import RecipeBasicForm from '../RecipeBasicForm';
import {
  editRecipeAction,
  editRecipeIngredientsAction,
} from '../../../../../state/productBase/actions';
import RecipeIngredientsForm from '../RecipeIngredientsFrom';
import { useTranslation } from 'react-i18next';

type EditRecipeProps = {
  recipe: RecipeDetailsDTO;
  selectedStep: RecipeContextMenuItems;
  onEditClose: () => void;
};

const EditRecipe: FC<EditRecipeProps> = ({ recipe, selectedStep, onEditClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { ingredients } = recipe;

  const transformedIngredients: Ingredient[][] = useMemo(() => {
    const arr: Ingredient[][] = [];

    ingredients.forEach((item) => {
      const transformItem: Ingredient = {
        id: item.id,
        maxNumberPortion: item.maxNumberPortion,
        actionSerialNumber: item.actionSerialNumber,
        tastesId: item.tastesId?.map(({ id }) => id),
        qty: item.qty,
        cellPurposeId: item.cellPurpose?.id,
        cellCategoryId: item.cellCategory?.id,
        required: item.required,
      };
      const index = transformItem.actionSerialNumber;
      if (!arr[index]) {
        arr[index] = [];
      }
      arr[index].push(transformItem);
    });

    return arr;
  }, [JSON.stringify(ingredients)]);

  const [basicFormState, setBasicFormState] = useState<EditRecipeBasic>({ ...recipe });
  const [ingredientsFormState, setIngredientsFormState] =
    useState<Ingredient[][]>(transformedIngredients);

  // Обработчики
  const handleEditClose = () => {
    onEditClose();
  };

  const handleBasicFormSubmit = () => {
    dispatch(editRecipeAction(basicFormState, recipe.id)).then(() => handleEditClose());
  };

  const handleIngredientsSubmit = () => {
    const ingredients = ingredientsFormState.reduce((acc, value, index) => {
      return acc.concat(value.map((item) => ({ ...item, actionSerialNumber: index })));
    }, []);

    dispatch(editRecipeIngredientsAction(ingredients, recipe.id)).then(() => {
      handleEditClose();
    });
  };

  const handleSubmit = () => {
    switch (selectedStep) {
      case RecipeContextMenuItems.INFO:
        return handleBasicFormSubmit();
      case RecipeContextMenuItems.INGREDIENTS:
        return handleIngredientsSubmit();
    }
  };

  const handleRecipeBasicFormChange = (key: keyof EditRecipeDTO) => (value: string | null) => {
    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleRecipeIngredientsFormChange = (data: Ingredient[][]) => {
    setIngredientsFormState(data);
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBase.recipe.form.edit.back.button.label')}
        view="clear"
        onClick={handleEditClose}
      />
      <Button label={t('productBase.recipe.form.edit.edit.button.label')} onClick={handleSubmit} />
    </>
  );

  const renderBasicForm = () => (
    <RecipeBasicForm recipe={basicFormState} onChange={handleRecipeBasicFormChange} />
  );

  const renderIngredientForm = () => (
    <RecipeIngredientsForm
      ingredients={ingredientsFormState}
      onChange={handleRecipeIngredientsFormChange}
    />
  );

  const renderForm = () => {
    switch (selectedStep) {
      case RecipeContextMenuItems.INFO:
        return renderBasicForm();
      case RecipeContextMenuItems.INGREDIENTS:
        return renderIngredientForm();
    }
  };

  return (
    <DefaultModal
      isOpen
      modalTitle={t('productBase.recipe.form.edit.modal.title', { name: recipe.name })}
      onClose={handleEditClose}
      renderActions={renderActions}
    >
      {renderForm()}
    </DefaultModal>
  );
};

export default EditRecipe;
