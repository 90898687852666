import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectCellPurposeLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getCellPurposeLocaleByIdAction,
} from '../../../../state/productBase/actions';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { LocaleNameList } from '../../../../components/locale/NameLocale/types';
import NameLocale from '../../../../components/locale/NameLocale';
import { Loader } from '@consta/uikit/Loader';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента CellPurposeLocale
 */
type CellPurposeLocaleProps = {
  /**
   * Флаг открытия локализации назначения контейнера
   */
  isOpen: boolean;
  /**
   * id назначения контейнера
   */
  cellPurposeId: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Компонент локализации назначения контейнера
 */
const CellPurposeLocale: FC<CellPurposeLocaleProps> = ({ isOpen, cellPurposeId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectCellPurposeLocale());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getCellPurposeLocaleByIdAction(cellPurposeId));
  }, [dispatch, cellPurposeId]);

  // Обработчики
  function handleSubmit(data: LocaleNameList) {
    locale &&
      dispatch(
        editLocaleAction({
          ...locale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() =>
        dispatch(getCellPurposeLocaleByIdAction(cellPurposeId)).then(() => {
          handleEditClose();
        }),
      );
  }

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleClose = onClose;

  if (!locale) return <Loader />;

  return (
    <NameLocale
      isOpen={isOpen}
      isEdit={isEdit}
      locale={locale}
      modalTitle={t('productBaseConfig.cellPurpose.locale.modal.title')}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export default CellPurposeLocale;
