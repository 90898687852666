import React, { FC, useState } from 'react';
import VerticalContainer from '../../../components/VerticalContainer';
import OutletTable from './OutletListTable';
import { useOutletList } from './hook';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import CreateOutlet from './CreateOutlet';
import styles from './OutletList.module.scss';
import OutletListStatusBar from './OutletListStatusBar';
import OutletListHeader from './OutletListHeader';
import { SalesFilters } from '../../../types/serverInterface/machineListDTO';
import EditOutlet from './EditOutlet';
import DeleteOutlet from './DeleteOutlet';
import { OutletListItem } from '../../../types/serverInterface/outletDTO';

/**
 * Список торговых точек
 */
const OutletList: FC = () => {
  const organizationId = getOrganizationId();

  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const [selectedEditOutlet, setSelectedEditOutlet] = useState<OutletListItem | null>(null);
  const [selectedDeleteOutlet, setSelectedDeleteOutlet] = useState<OutletListItem | null>(null);

  const {
    outletList,
    filterOutletList,
    machineStatusesCount,
    machineOutletMap,
    machineAtModelsCount,
    filters,
    searchQuery,
    setFilters,
    setSearchQuery,
  } = useOutletList(organizationId);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleCreateOpen = () => {
    setIsCreateOpen(true);
  };

  const handleClose = () => {
    setIsCreateOpen(false);
  };

  const handleSaleTypeChange = (salesFilter: SalesFilters) => {
    setFilters((prevState) => ({ ...prevState, salesFilter }));
  };

  const handleSelectModelId = (modelIdList: number[] | null) => {
    setFilters((prevState) => ({ ...prevState, machineModelIds: modelIdList }));
  };

  const handleEditClick = (outlet: OutletListItem) => {
    setSelectedEditOutlet(outlet);
  };

  const handleEditClose = () => {
    setSelectedEditOutlet(null);
  };

  const handleDeleteClick = (outlet: OutletListItem) => {
    setSelectedDeleteOutlet(outlet);
  };

  const handleDeleteClose = () => {
    setSelectedDeleteOutlet(null);
  };

  // render методы
  const renderStatusBar = () => (
    <OutletListStatusBar
      listCount={
        machineStatusesCount.success + machineStatusesCount.warning + machineStatusesCount.error
      }
      successCount={machineStatusesCount.success}
      warningCount={machineStatusesCount.warning}
      alertCount={machineStatusesCount.error}
      selectedStatus={null}
      onSelectedStatus={() => {
        null;
      }}
    />
  );

  const renderTableActions = () => (
    <OutletListHeader
      selectedSaleType={filters.salesFilter}
      searchQuery={searchQuery}
      listCount={
        machineStatusesCount.success + machineStatusesCount.warning + machineStatusesCount.error
      }
      successCount={machineStatusesCount.success}
      warningCount={machineStatusesCount.warning}
      alertCount={machineStatusesCount.error}
      selectedStatus={filters.statusColors && filters.statusColors[0]}
      onSelectedStatus={(value) => {
        setFilters((prevState) => ({ ...prevState, statusColors: value && [value] }));
      }}
      onSearchChange={handleSearchChange}
      onCreateOpen={handleCreateOpen}
      onSaleTypeChange={handleSaleTypeChange}
      selectedModelId={filters.machineModelIds}
      onSelectModelId={handleSelectModelId}
      modelList={machineAtModelsCount || null}
    />
  );

  const renderTable = () =>
    filterOutletList && (
      <OutletTable
        list={filterOutletList}
        machineOutletMap={machineOutletMap}
        onDeleteClick={handleDeleteClick}
        onEditClick={handleEditClick}
      />
    );

  const renderCreateForm = () =>
    isCreateOpen &&
    organizationId && (
      <CreateOutlet organizationId={organizationId} filters={filters} onCLose={handleClose} />
    );

  const renderEditForm = () =>
    selectedEditOutlet &&
    organizationId &&
    outletList && (
      <EditOutlet
        outlet={selectedEditOutlet}
        outletList={outletList}
        organizationId={organizationId}
        filters={filters}
        onClose={handleEditClose}
      />
    );

  const renderDeleteModal = () =>
    selectedDeleteOutlet &&
    organizationId && (
      <DeleteOutlet
        outlet={selectedDeleteOutlet}
        onClose={handleDeleteClose}
        organizationId={organizationId}
        filters={filters}
      />
    );

  return (
    <VerticalContainer className={styles.OutletList} space="5xl">
      {renderCreateForm()}
      {renderEditForm()}
      {renderDeleteModal()}
      {renderStatusBar()}
      {renderTableActions()}
      {renderTable()}
    </VerticalContainer>
  );
};

export default OutletList;
