import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectProductLineLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getProductLineLocaleAction,
} from '../../../../state/productBase/actions';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { LocaleNameDescriptionList } from '../../../../components/locale/NameDescriptionLocale/types';
import NameDescriptionLocale from '../../../../components/locale/NameDescriptionLocale';
import { useTranslation } from 'react-i18next';

type ProductLineLocaleProps = {
  /**
   * Флаг открытия локализации линейки продукта
   */
  isOpen: boolean;
  /**
   * id линейки продукта
   */
  productLineId: number;
  /**
   * Обработчик закрытия локализации линейки продукта
   */
  onClose: () => void;
};

const ProductLineLocale: FC<ProductLineLocaleProps> = ({ productLineId, isOpen, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectProductLineLocale());

  const [isLocaleEdit, setIsLocaleEdit] = useState(false);

  useEffect(() => {
    dispatch(getProductLineLocaleAction(productLineId));
  }, [dispatch, productLineId]);

  // Обработчики
  const handleSubmit = (data: LocaleNameDescriptionList) => {
    locale &&
      dispatch(
        editLocaleAction({
          ...locale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                language: value.lang,
                value: value?.name || null,
              })),
            },
            {
              entityFieldName: FieldNames.DESCRIPTION,
              localeValues: data.map((value) => ({
                language: value.lang,
                value: value?.description || null,
              })),
            },
          ],
        }),
      ).then(() =>
        dispatch(getProductLineLocaleAction(productLineId)).then(() => handleEditClose()),
      );
  };

  const handleEditClick = () => {
    setIsLocaleEdit(true);
  };

  const handleEditClose = () => {
    setIsLocaleEdit(false);
  };

  const handleClose = () => onClose();

  return (
    <NameDescriptionLocale
      isOpen={isOpen}
      isTransformList={false}
      locale={locale}
      isEdit={isLocaleEdit}
      modalTitle={t('productBase.productLine.locale.modal.title')}
      onSubmit={handleSubmit}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onClose={handleClose}
    />
  );
};

export default ProductLineLocale;
