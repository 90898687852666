import { FC, useEffect, useState } from 'react';
import { enumToArray } from '../../../../types/enums';
import EditOutletName from './EditOutletName';
import EditOutletAddress from './EditOutletAddress';
import EditOutletMachines from './EditOutletMachines';
import DefaultModal from '../../../../components/DefaultModal';
import styles from './EditOutlet.module.scss';
import Chips from '../../../../components/Chips';
import { ProductInfoSteps } from '../../../ProductBasePage/Product/ProductInfo/ProductInfo';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import {
  EditOutletDTO,
  OutletFiltersDTO,
  OutletListItem,
} from '../../../../types/serverInterface/outletDTO';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  editOutletAction,
  getOutletMachineIdsAction,
} from '../../../../state/machineControl/actions';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { selectOutletMachineIds } from '../../../../state/machineControl/selectors';

/**
 * Шаги формы изменения торговой точки
 */
enum EditOutletStep {
  /**
   * Название
   */
  NAME = 'NAME',
  /**
   * Адрес
   */
  ADDRESS = 'ADDRESS',
  /**
   * Автоматы
   */
  MACHINES = 'MACHINES',
}

/**
 * Список шагов формы изменения торговой точки
 */
const editOutletStepList = enumToArray(EditOutletStep);

/**
 * Свойства компонента EditOutlet
 */
type EditOutletProps = {
  /**
   * id организации
   */
  organizationId: number;
  /**
   * Фильтра
   */
  filters: OutletFiltersDTO;
  /**
   * торговая точка
   */
  outlet: OutletListItem;
  /**
   * Список торговых точек
   */
  outletList: OutletListItem[];
  /**
   * Обработчик закрытия изменения торговой точки
   */
  onClose: () => void;
};

/**
 * Изменение торговой точки
 */
const EditOutlet: FC<EditOutletProps> = ({
  outlet,
  organizationId,
  outletList,
  filters,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { state: outletMachineState } = useAppSelector(selectOutletMachineIds());
  const machineIds = outletMachineState?.machineIdsList || [];

  const [selectedStep, setSelectedStep] = useState<EditOutletStep>(EditOutletStep.NAME);
  const [formState, setFormState] = useState<EditOutletDTO>({
    city: outlet.city,
    name: outlet.name,
    houseNumber: outlet.houseNumber,
    street: outlet.street,
    country: outlet.country,
    machineIds: machineIds || [],
    organizationId: outlet.organizationId,
  });

  const { id: outletId } = outlet;

  useEffect(() => {
    setFormState((prevState) => ({ ...prevState, machineIds }));
  }, [JSON.stringify(machineIds)]);

  useEffect(() => {
    dispatch(getOutletMachineIdsAction(outletId));
  }, [dispatch, outletId]);

  // Обработчики
  const handleChipsClick = (item: string) => {
    setSelectedStep(item as EditOutletStep);
  };

  const handleSubmit = () => {
    dispatch(editOutletAction(formState, outlet.id, organizationId, filters)).then(() => {
      onClose();
    });
  };

  const handleChange =
    (key: keyof EditOutletDTO) =>
    ({ value }: { value: string | number[] | null }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
    };

  // render методы
  const renderNameForm = () => <EditOutletName outlet={formState} onChange={handleChange} />;

  const renderAddressForm = () => <EditOutletAddress outlet={formState} onChange={handleChange} />;

  const renderMachinesForm = () => (
    <EditOutletMachines
      outlet={formState}
      outletList={outletList}
      organizationId={organizationId}
      onChange={handleChange}
    />
  );

  const renderContent = () => {
    switch (selectedStep) {
      case EditOutletStep.NAME:
        return renderNameForm();
      case EditOutletStep.ADDRESS:
        return renderAddressForm();
      case EditOutletStep.MACHINES:
        return renderMachinesForm();
      default:
        return <>Error</>;
    }
  };

  const renderChips = () => (
    <HorizontalContainer space="xs">
      {editOutletStepList.map((name) => (
        <Chips
          key={name}
          label={t(`outlet.edit.chips.label.${name}`)}
          name={name}
          isSelect={selectedStep === name}
          onClick={(name) => handleChipsClick(name as ProductInfoSteps)}
        />
      ))}
    </HorizontalContainer>
  );

  const renderActions = () => (
    <>
      <Button view="clear" label={t('outlet.edit.cancel.button.label')} onClick={onClose} />
      <Button label={t('outlet.edit.save.button.label')} onClick={handleSubmit} />
    </>
  );

  return (
    <DefaultModal
      className={styles.EditOutlet}
      modalTitle={t('outlet.edit.modal.title')}
      onClose={onClose}
      renderActions={renderActions}
    >
      {renderChips()}
      {renderContent()}
    </DefaultModal>
  );
};

export default EditOutlet;
