import { FC } from 'react';
import { EditOrganizationContactProps } from './types';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useTranslation } from 'react-i18next';
import VerticalContainer from '../../../../components/VerticalContainer';
import EditOrganizationContactItem from './EditOrganizationContactItem';
import { EditContactDTO } from '../../../../types/serverInterface/organizationDTO';

/**
 * Форма для редактирования контактов организации.
 */
const EditOrganizationContact: FC<EditOrganizationContactProps> = ({
  value,
  onChange,
  onDelete,
  onAdd,
}) => {
  const { t } = useTranslation();

  // Обработчики
  const handleItemChange =
    (index: number) =>
    (key: keyof EditContactDTO) =>
    ({ value }: { value: string | null }) => {
      onChange(index)(key)(value);
    };

  // render методы
  const renderContent = () =>
    value.map((contact, index) => (
      <VerticalContainer space="l" key={index}>
        <EditOrganizationContactItem
          contact={contact}
          onChange={handleItemChange(index)}
          onDelete={() => onDelete(index)}
        />
      </VerticalContainer>
    ));

  return (
    <>
      {renderContent()}
      <Button
        label={t('organization.contacts.edit.addContact.button.label')}
        size="l"
        view="ghost"
        width="full"
        onClick={onAdd}
      />
    </>
  );
};

export default EditOrganizationContact;
