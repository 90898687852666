import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconProfileAcceptedSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0.625C3.68832 0.625 2.625 1.68832 2.625 3C2.625 4.31168 3.68832 5.375 5 5.375C6.31168 5.375 7.375 4.31168 7.375 3C7.375 1.68832 6.31168 0.625 5 0.625ZM3.375 3C3.375 2.10254 4.10254 1.375 5 1.375C5.89746 1.375 6.625 2.10254 6.625 3C6.625 3.89746 5.89746 4.625 5 4.625C4.10254 4.625 3.375 3.89746 3.375 3Z"
    />
    <path d="M11.2794 6.75014C11.4175 6.59583 11.4044 6.35876 11.2501 6.22061C11.0958 6.08247 10.8588 6.09556 10.7206 6.24986L9.03603 8.13145C8.69468 8.51272 8.4648 8.76814 8.26826 8.93333C8.08103 9.0907 7.97106 9.125 7.875 9.125C7.77894 9.125 7.66897 9.0907 7.48174 8.93333C7.2852 8.76814 7.05532 8.51272 6.71397 8.13145L6.27939 7.64605C6.14124 7.49174 5.90417 7.47865 5.74986 7.61679C5.59556 7.75494 5.58247 7.99202 5.72061 8.14632L6.17375 8.65245C6.49168 9.00758 6.75687 9.30381 6.99918 9.50746C7.25549 9.7229 7.53196 9.875 7.875 9.875C8.21804 9.875 8.4945 9.7229 8.75082 9.50747C8.99313 9.30381 9.25831 9.00759 9.57623 8.65246L11.2794 6.75014Z" />
    <path d="M3.5 6.125C2.18832 6.125 1.125 7.18832 1.125 8.5C1.125 9.81168 2.18832 10.875 3.5 10.875H6.5C6.70711 10.875 6.875 10.7071 6.875 10.5C6.875 10.2929 6.70711 10.125 6.5 10.125H3.5C2.60254 10.125 1.875 9.39746 1.875 8.5C1.875 7.60254 2.60254 6.875 3.5 6.875H6.5C6.70711 6.875 6.875 6.70711 6.875 6.5C6.875 6.29289 6.70711 6.125 6.5 6.125H3.5Z" />
  </svg>
);

const IconProfileAcceptedSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 1.83331C4.91777 1.83331 3.5 3.25108 3.5 4.99998C3.5 6.74888 4.91777 8.16665 6.66667 8.16665C8.41557 8.16665 9.83333 6.74888 9.83333 4.99998C9.83333 3.25108 8.41557 1.83331 6.66667 1.83331ZM4.5 4.99998C4.5 3.80336 5.47005 2.83331 6.66667 2.83331C7.86328 2.83331 8.83333 3.80336 8.83333 4.99998C8.83333 6.1966 7.86328 7.16665 6.66667 7.16665C5.47005 7.16665 4.5 6.1966 4.5 4.99998Z"
    />
    <path d="M15.0392 10.0002C15.2234 9.79442 15.2059 9.47832 15.0002 9.29413C14.7944 9.10994 14.4783 9.1274 14.2942 9.33313L12.048 11.8419C11.5929 12.3503 11.2864 12.6908 11.0243 12.9111C10.7747 13.1209 10.6281 13.1666 10.5 13.1666C10.3719 13.1666 10.2253 13.1209 9.97565 12.9111C9.7136 12.6908 9.4071 12.3503 8.95196 11.8419L8.37252 11.1947C8.18832 10.989 7.87222 10.9715 7.66649 11.1557C7.46075 11.3399 7.44329 11.656 7.62748 11.8617L8.23167 12.5366C8.65557 13.0101 9.00916 13.4051 9.33223 13.6766C9.67399 13.9639 10.0426 14.1666 10.5 14.1666C10.9574 14.1666 11.326 13.9639 11.6678 13.6766C11.9908 13.4051 12.3444 13.0101 12.7683 12.5366L15.0392 10.0002Z" />
    <path d="M4.66667 9.16665C2.91777 9.16665 1.5 10.5844 1.5 12.3333C1.5 14.0822 2.91776 15.5 4.66667 15.5H8.66667C8.94281 15.5 9.16667 15.2761 9.16667 15C9.16667 14.7238 8.94281 14.5 8.66667 14.5H4.66667C3.47005 14.5 2.5 13.5299 2.5 12.3333C2.5 11.1367 3.47005 10.1666 4.66667 10.1666H8.66667C8.94281 10.1666 9.16667 9.94279 9.16667 9.66665C9.16667 9.3905 8.94281 9.16665 8.66667 9.16665H4.66667Z" />
  </svg>
);

const IconProfileAcceptedSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.25C7.37665 2.25 5.25 4.37665 5.25 7C5.25 9.62335 7.37665 11.75 10 11.75C12.6234 11.75 14.75 9.62335 14.75 7C14.75 4.37665 12.6234 2.25 10 2.25ZM6.75 7C6.75 5.20507 8.20507 3.75 10 3.75C11.7949 3.75 13.25 5.20507 13.25 7C13.25 8.79493 11.7949 10.25 10 10.25C8.20507 10.25 6.75 8.79493 6.75 7Z"
    />
    <path d="M22.5588 14.5003C22.8351 14.1917 22.8089 13.7175 22.5003 13.4412C22.1917 13.1649 21.7175 13.1911 21.4412 13.4997L18.0721 17.2629C17.3894 18.0254 16.9296 18.5363 16.5365 18.8667C16.1621 19.1814 15.9421 19.25 15.75 19.25C15.5579 19.25 15.3379 19.1814 14.9635 18.8667C14.5704 18.5363 14.1106 18.0254 13.4279 17.2629L12.5588 16.2921C12.2825 15.9835 11.8083 15.9573 11.4997 16.2336C11.1911 16.5099 11.1649 16.984 11.4412 17.2926L12.3475 18.3049C12.9834 19.0152 13.5137 19.6076 13.9984 20.0149C14.511 20.4458 15.0639 20.75 15.75 20.75C16.4361 20.75 16.989 20.4458 17.5016 20.0149C17.9863 19.6076 18.5166 19.0152 19.1525 18.3049L22.5588 14.5003Z" />
    <path d="M7 13.25C4.37665 13.25 2.25 15.3766 2.25 18C2.25 20.6234 4.37665 22.75 7 22.75H13C13.4142 22.75 13.75 22.4142 13.75 22C13.75 21.5858 13.4142 21.25 13 21.25H7C5.20507 21.25 3.75 19.7949 3.75 18C3.75 16.2051 5.20507 14.75 7 14.75H13C13.4142 14.75 13.75 14.4142 13.75 14C13.75 13.5858 13.4142 13.25 13 13.25H7Z" />
  </svg>
);

export const IconProfileAccepted = createIcon({
  name: 'IconProfileAccepted',
  xs: IconProfileAcceptedSizeXS,
  s: IconProfileAcceptedSizeS,
  m: IconProfileAcceptedSizeM,
});
