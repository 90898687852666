import { FC, ReactNode, useMemo } from 'react';
import { FieldNames, LocaleDTO } from '../../../types/serverInterface/localeDTO';
import { LocaleNameDescriptionList } from './types';
import { useFormik } from 'formik';
import { editNameDescriptionLocaleValidationScheme } from './LocaleNameDescriptionForm/editNameDescriptionLocaleValidationScheme';
import { LanguageEnum } from '../../../types/serverInterface/locales';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DefaultModal from '../../DefaultModal';
import LocaleNameDescriptionForm from './LocaleNameDescriptionForm';
import LocaleNameDescriptionInfo from './LocaleNameDescriptionInfo';
import { useTranslation } from 'react-i18next';

/**
 * Трансформированная локализация
 */
type TransformLocaleList = {
  /**
   * Флаг транформированной локализации
   */
  isTransformList: true;
  /**
   * Локализация
   */
  locale: LocaleNameDescriptionList;
};

/**
 * Не трансформированная локализация
 */
type NotTransformLocaleList = {
  /**
   * Флаг транформированной локализации
   */
  isTransformList: false;
  /**
   * Локализация
   */
  locale: LocaleDTO | null;
};

/**
 * Объединяющий тип для трансформированной локализации и нет
 */
type UnionTransformLocaleList = TransformLocaleList | NotTransformLocaleList;

/**
 * Свойства компонента NameDescriptionLocale
 */
type NameDescriptionLocaleProps = UnionTransformLocaleList & {
  /**
   * Флаг запущенного редактирования
   */
  isEdit: boolean;
  /**
   * Флаг открытия локализации
   */
  isOpen: boolean;
  /**
   * Заголовок модального окна
   */
  modalTitle: string;
  /**
   * Чипсы для внедрения в страницы информации с чипсами
   */
  chips?: ReactNode;
  /**
   * Обработчик отправки формы
   *
   * @param data данные локализации для отправки формы
   */
  onSubmit: (data: LocaleNameDescriptionList) => void;
  /**
   * Обработчик нажатия на кнопку редактирования
   */
  onEditClick: () => void;
  /**
   * Обработчик закрытия редактирования
   */
  onEditClose: () => void;
  /**
   * Обработчик закрытия локализации
   */
  onClose: () => void;
};

/**
 * Общий компонент локализации сущности с названием о=и описанием
 */
const NameDescriptionLocale: FC<NameDescriptionLocaleProps> = ({
  isOpen,
  isEdit,
  isTransformList,
  locale,
  modalTitle,
  chips,
  onSubmit,
  onEditClick,
  onEditClose,
  onClose,
}) => {
  const { t } = useTranslation();

  const localeList: LocaleNameDescriptionList = useMemo(() => {
    if (isTransformList) {
      return locale as LocaleNameDescriptionList;
    }

    const transformedData: LocaleNameDescriptionList = [];

    const nameField = locale?.fields?.find((field) => field.entityFieldName === FieldNames.NAME);
    const descriptionField = locale?.fields?.find(
      (field) => field.entityFieldName === FieldNames.DESCRIPTION,
    );

    if (nameField && descriptionField) {
      nameField.localeValues?.forEach((value) => {
        const lang = value.language;
        const nameValue = value.value;
        const descriptionValue =
          descriptionField.localeValues.find((desc) => desc.language === lang)?.value || null;

        const transformedItem = {
          lang,
          name: nameValue,
          description: descriptionValue,
        };

        transformedData.push(transformedItem);
      });
    }

    return transformedData;
  }, [isTransformList, locale]);

  const formik = useFormik({
    validationSchema: editNameDescriptionLocaleValidationScheme,
    initialValues: localeList,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  // Обработчики
  const handleLangChange =
    (index: number) =>
    ({ value }: { value: string | null }) => {
      const langList = [...formik.values];

      if (langList.length > index) {
        langList[index] = { ...langList[index], lang: value as LanguageEnum };
      }

      formik.setValues(langList);
    };

  const handleNameChange =
    (index: number) =>
    ({ value }: { value: string | null }) => {
      const langList = [...formik.values];

      if (langList.length > index) {
        langList[index] = { ...langList[index], name: value };
      }

      formik.setValues(langList);
    };

  const handleDescriptionChange =
    (index: number) =>
    ({ value }: { value: string | null }) => {
      const langList = [...formik.values];

      if (langList.length > index) {
        langList[index] = { ...langList[index], description: value };
      }

      formik.setValues(langList);
    };

  const handleLangDelete = (index: number) => () => {
    const langList = [...formik.values];

    if (index < langList.length) {
      formik.setValues([...langList.slice(0, index), ...langList.slice(index + 1)]);
    }
  };

  const handleAddClick = () => {
    const langList = formik.values;
    const initialLang = {
      lang: '' as LanguageEnum,
      name: '',
      description: '',
    };
    formik.setValues([...langList, initialLang]);
  };

  const handleClose = () => onClose();

  const handleEditClick = onEditClick;

  const handleEditClose = onEditClose;

  const handleCancelClick = () => {
    isEdit && handleEditClose();
    !isEdit && handleClose();
  };

  const handlePrimaryClick = () => {
    isEdit && formik.handleSubmit();
    !isEdit && handleEditClick();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={
          isEdit ? t('defaultLocale.cancel.button.label') : t('defaultLocale.back.button.label')
        }
        view="clear"
        onClick={handleCancelClick}
      />
      <Button
        label={isEdit ? t('defaultLocale.save.button.label') : t('defaultLocale.edit.button.label')}
        onClick={handlePrimaryClick}
      />
    </>
  );

  const renderContent = () =>
    isEdit ? (
      <LocaleNameDescriptionForm
        locale={formik.values}
        onNameChange={handleNameChange}
        onDescriptionChange={handleDescriptionChange}
        onLangChange={handleLangChange}
        onDeleteLang={handleLangDelete}
        onAddClick={handleAddClick}
      />
    ) : (
      <LocaleNameDescriptionInfo locale={localeList} chips={chips} />
    );

  return (
    <DefaultModal
      modalTitle={modalTitle}
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default NameDescriptionLocale;
