import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getRegistrationInfoActions, registrationAction } from '../../../state/auth/actions';
import {
  selectIsRegistrationInfoLoading,
  selectRegistrationInfo,
} from '../../../state/auth/selectors';
import { Loader } from '@consta/uikit/Loader';
import styles from './Registration.module.scss';
import { TextField } from '@consta/uikit/TextField';
import { RegistrationDTO } from '../../../types/serverInterface/authDTO';
import { useFormik } from 'formik';
import { registrationValidationScheme } from './registrationValidationScheme';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VerticalContainer from '../../../components/VerticalContainer';

/**
 * Форма регистрации по приглашению организации
 */
const Registration: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hash } = useParams<{ hash: string }>();
  const registrationInfo = useAppSelector(selectRegistrationInfo());
  const isRegistrationInfoLoading = useAppSelector(selectIsRegistrationInfoLoading());
  const navigate = useNavigate();

  useEffect(() => {
    hash && dispatch(getRegistrationInfoActions(hash));
  }, [dispatch, hash]);

  const formik = useFormik({
    validationSchema: registrationValidationScheme,
    validateOnChange: false,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: RegistrationDTO) {
    hash && dispatch(registrationAction({ data, hash })).then(() => navigate('/'));
  }

  if (isRegistrationInfoLoading || !registrationInfo || !hash) return <Loader />;

  return (
    <VerticalContainer space="5xl" className={styles.registration}>
      <VerticalContainer space="l">
        <Text align="center" size="2xl" weight="semibold">
          Регистрация
        </Text>
        <Text size="l" view="secondary">
          {t('registration.info.organization.text')}: {registrationInfo.organization.name}
        </Text>
        <Text size="l" view="secondary">
          {t('registration.info.email.text')}: {registrationInfo.login}
        </Text>
      </VerticalContainer>
      <VerticalContainer space="l" className={styles.form}>
        <TextField
          width="full"
          label={t('registration.form.password.input.label')}
          type="password"
          name="password"
          value={formik.values.password}
          status={formik.errors.password ? 'alert' : undefined}
          caption={formik.errors.password}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          width="full"
          label={t('registration.form.confirmPassword.input.label')}
          type="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          status={formik.errors.confirmPassword ? 'alert' : undefined}
          caption={formik.errors.confirmPassword}
          onChange={({ e }) => formik.handleChange(e)}
        />
      </VerticalContainer>
      <Button
        label={t('registration.form.registration.button.label')}
        onClick={() => formik.handleSubmit()}
      />
    </VerticalContainer>
  );
};

export default Registration;
