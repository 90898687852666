import * as Yup from 'yup';
import { EditCellViewDTO } from '../../../../types/serverInterface/cellViewDTO';

/**
 * Схема валидации изменения вида спортивного питания
 */
export const editSportPitValidationScheme: Yup.ObjectSchema<EditCellViewDTO> = Yup.object({
  name: Yup.string().required(),
  mediaKey: Yup.string().required(),
});
