import { FC } from 'react';
import {
  CustomFormItem,
  CustomFormItemType,
  CustomFormType,
} from '../../types/serverInterface/customForm';
import DefaultInfoItem from '../DefaultInfoItem';
import { getLocaleCustomFormField } from './helpers';
import { LanguageEnum } from '../../types/serverInterface/locales';
import DefaultInfo from '../DefaultInfo';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента CustomFormInfo
 */
type CustomFormInfoProps = {
  /**
   * Кастомная форма
   */
  config: CustomFormType;
};

/**
 * Информация о кастомной форме
 */
const CustomFormInfo: FC<CustomFormInfoProps> = ({ config }) => {
  const { t } = useTranslation();

  // render методы
  const renderFormItem = (formItem: CustomFormItem, index: number) => {
    let formItemValue = '';
    const formItemUnit = formItem.unit ? ` ${t(`productBase.units.${formItem.unit}`)}` : '';
    const localeFormItem = getLocaleCustomFormField(formItem)(LanguageEnum.ru);
    const name = localeFormItem?.name || formItem.name;
    const description = localeFormItem?.description || formItem.description;

    switch (formItem.type) {
      case CustomFormItemType.ARRAY:
        const item = formItem.items.find((item) => item.key === formItem.value);
        formItemValue =
          item?.locale.find(({ lang }) => lang === LanguageEnum.ru)?.label || item?.label || '';
        break;
      case CustomFormItemType.TIME:
        formItemValue = formItem.value
          ? `${formItem.value.getHours()}:${formItem.value.getMinutes()}`
          : '';
        break;
      default:
        formItemValue = formItem.value ? `${formItem.value} ${formItemUnit}` : formItem.name;
    }

    return (
      <DefaultInfoItem
        key={index}
        tooltipText={description}
        item={{
          name: name,
          value: formItemValue,
        }}
      />
    );
  };

  return <DefaultInfo>{config.map(renderFormItem)}</DefaultInfo>;
};

export default CustomFormInfo;
