import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconProfileCircleSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_34625_181)">
      <path d="M7.5 4.5C7.5 5.32843 6.82843 6 6 6V7C7.38071 7 8.5 5.88071 8.5 4.5H7.5ZM6 6C5.17157 6 4.5 5.32843 4.5 4.5H3.5C3.5 5.88071 4.61929 7 6 7V6ZM4.5 4.5C4.5 3.67157 5.17157 3 6 3V2C4.61929 2 3.5 3.11929 3.5 4.5H4.5ZM6 3C6.82843 3 7.5 3.67157 7.5 4.5H8.5C8.5 3.11929 7.38071 2 6 2V3ZM4.5 8.5H7.5V7.5H4.5V8.5ZM1.5 6C1.5 3.51472 3.51472 1.5 6 1.5V0.5C2.96243 0.5 0.5 2.96243 0.5 6H1.5ZM6 1.5C8.48528 1.5 10.5 3.51472 10.5 6H11.5C11.5 2.96243 9.03757 0.5 6 0.5V1.5ZM10.5 6C10.5 7.27622 9.96939 8.42765 9.11539 9.24722L9.8078 9.96872C10.8502 8.9684 11.5 7.55967 11.5 6H10.5ZM9.11539 9.24722C8.30637 10.0236 7.20934 10.5 6 10.5V11.5C7.47762 11.5 8.82005 10.9166 9.8078 9.96872L9.11539 9.24722ZM7.5 8.5C8.22723 8.5 8.83457 9.01802 8.97118 9.70543L9.952 9.5105C9.72418 8.36419 8.71357 7.5 7.5 7.5V8.5ZM6 10.5C4.79066 10.5 3.69363 10.0236 2.88461 9.24722L2.1922 9.96872C3.17995 10.9166 4.52238 11.5 6 11.5V10.5ZM2.88461 9.24722C2.03061 8.42765 1.5 7.27622 1.5 6H0.5C0.5 7.55967 1.14985 8.9684 2.1922 9.96872L2.88461 9.24722ZM4.5 7.5C3.28643 7.5 2.27581 8.36419 2.048 9.5105L3.02882 9.70543C3.16543 9.01802 3.77277 8.5 4.5 8.5V7.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_34625_181">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

const IconProfileCircleSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M9.83333 5.66667C9.83333 6.86328 8.86328 7.83333 7.66667 7.83333V8.83333C9.41557 8.83333 10.8333 7.41557 10.8333 5.66667H9.83333ZM7.66667 7.83333C6.47005 7.83333 5.5 6.86328 5.5 5.66667H4.5C4.5 7.41557 5.91776 8.83333 7.66667 8.83333V7.83333ZM5.5 5.66667C5.5 4.47005 6.47005 3.5 7.66667 3.5V2.5C5.91776 2.5 4.5 3.91776 4.5 5.66667H5.5ZM7.66667 3.5C8.86328 3.5 9.83333 4.47005 9.83333 5.66667H10.8333C10.8333 3.91776 9.41557 2.5 7.66667 2.5V3.5ZM5.66667 10.8333H9.66667V9.83333H5.66667V10.8333ZM1.5 7.66667C1.5 4.26091 4.26091 1.5 7.66667 1.5V0.5C3.70863 0.5 0.5 3.70863 0.5 7.66667H1.5ZM7.66667 1.5C11.0724 1.5 13.8333 4.26091 13.8333 7.66667H14.8333C14.8333 3.70863 11.6247 0.5 7.66667 0.5V1.5ZM13.8333 7.66667C13.8333 9.41553 13.106 10.9937 11.9359 12.1165L12.6283 12.838C13.9867 11.5344 14.8333 9.69898 14.8333 7.66667H13.8333ZM11.9359 12.1165C10.8274 13.1803 9.32384 13.8333 7.66667 13.8333V14.8333C9.59212 14.8333 11.3411 14.0734 12.6283 12.838L11.9359 12.1165ZM9.66667 10.8333C10.7174 10.8333 11.5944 11.5817 11.7917 12.5748L12.7725 12.3798C12.484 10.9279 11.2037 9.83333 9.66667 9.83333V10.8333ZM7.66667 13.8333C6.0095 13.8333 4.50589 13.1803 3.39741 12.1165L2.705 12.838C3.99222 14.0734 5.74121 14.8333 7.66667 14.8333V13.8333ZM3.39741 12.1165C2.22736 10.9937 1.5 9.41553 1.5 7.66667H0.5C0.5 9.69898 1.34659 11.5344 2.705 12.838L3.39741 12.1165ZM5.66667 9.83333C4.12963 9.83333 2.84936 10.9279 2.5608 12.3798L3.54162 12.5748C3.73897 11.5817 4.61598 10.8333 5.66667 10.8333V9.83333Z" />
  </svg>
);

const IconProfileCircleSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M15.4 9C15.4 10.8778 13.8778 12.4 12 12.4V13.6C14.5405 13.6 16.6 11.5405 16.6 9H15.4ZM12 12.4C10.1222 12.4 8.6 10.8778 8.6 9H7.4C7.4 11.5405 9.45949 13.6 12 13.6V12.4ZM8.6 9C8.6 7.12223 10.1222 5.6 12 5.6V4.4C9.45949 4.4 7.4 6.45949 7.4 9H8.6ZM12 5.6C13.8778 5.6 15.4 7.12223 15.4 9H16.6C16.6 6.45949 14.5405 4.4 12 4.4V5.6ZM9 16.6H15V15.4H9V16.6ZM2.6 12C2.6 6.80852 6.80852 2.6 12 2.6V1.4C6.14578 1.4 1.4 6.14578 1.4 12H2.6ZM12 2.6C17.1915 2.6 21.4 6.80852 21.4 12H22.6C22.6 6.14578 17.8542 1.4 12 1.4V2.6ZM21.4 12C21.4 14.6658 20.2911 17.0716 18.5077 18.783L19.3386 19.6488C21.348 17.7205 22.6 15.006 22.6 12H21.4ZM18.5077 18.783C16.8182 20.4044 14.526 21.4 12 21.4V22.6C14.8479 22.6 17.4346 21.4761 19.3386 19.6488L18.5077 18.783ZM15 16.6C16.649 16.6 18.025 17.7745 18.3347 19.3329L19.5117 19.099C19.0925 16.9899 17.2326 15.4 15 15.4V16.6ZM12 21.4C9.474 21.4 7.18179 20.4044 5.49226 18.783L4.66137 19.6488C6.56538 21.4761 9.15206 22.6 12 22.6V21.4ZM5.49226 18.783C3.70892 17.0716 2.6 14.6658 2.6 12H1.4C1.4 15.006 2.65201 17.7205 4.66137 19.6488L5.49226 18.783ZM9 15.4C6.7674 15.4 4.90748 16.9899 4.48832 19.099L5.6653 19.3329C5.97502 17.7745 7.35101 16.6 9 16.6V15.4Z" />
  </svg>
);

export const IconProfileCircle = createIcon({
  name: 'IconProfileCircle',
  xs: IconProfileCircleSizeXS,
  s: IconProfileCircleSizeS,
  m: IconProfileCircleSizeM,
});
