import { FC } from 'react';
import TableCard from '../../../components/TableCard';
import styles from './MachineList.module.scss';
import { useNavigate } from 'react-router-dom';
import { ConnectionStatus, StatusColor } from '../../../types/serverInterface/machineDTO';
import MachineListStatusBar from './MachineListStatusBar';
import MachineListHeader from './MachineListHeader';
import VerticalContainer from '../../../components/VerticalContainer';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { Text, TextPropView } from '@consta/uikit/__internal__/src/components/Text';
import MachineStorageCell from './MachineStorageCell';
import classNames from 'classnames';
import { machineListInitialFilters, useMachineList } from './hook';
import { useTranslation } from 'react-i18next';
import {
  MachineListItem,
  SalesFilters,
  ShutdownReasonFilters,
} from '../../../types/serverInterface/machineListDTO';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import { Badge } from '@consta/uikit/Badge';
import MachineArchivedTabs from './MachineArchivedTabs';
import { Radio } from '@consta/uikit/Radio';
import { SortDirection } from '../../../types/enums/sortDirection';

const getStatus = (color: StatusColor): TextPropView => {
  switch (color) {
    case StatusColor.ERROR:
      return 'alert';
    case StatusColor.WARNING:
      return 'warning';
    case StatusColor.SUCCESS:
      return 'success';
  }
  return 'alert';
};

/**
 * Список автоматов организации (минимальный)
 */
const MachineList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const organizationId = getOrganizationId();

  const {
    machineList,
    machineAtModelsCount,
    machineAtStatusesCount,
    archivedCount,
    machineAtShutdownCount,
    filters,
    setFilters,
  } = useMachineList(organizationId);

  // Вспомогательные методы
  const getConnectionStatusClass = (connectionStatus: ConnectionStatus) => {
    switch (connectionStatus) {
      case ConnectionStatus.ONLINE:
        return styles.connectionStatus_online;
      case ConnectionStatus.OFFLINE:
        return styles.connectionStatus_offline;
    }
  };

  // Обработчики
  const handleInfoClick = (machineId: number) => {
    navigate(`/machineControl/machine/${machineId}`);
  };

  const handleRowClick = ({ id }: { id: number }) => {
    handleInfoClick(id);
  };

  const handleSearchChange = (value: string) => {
    setFilters((prevState) => ({ ...prevState, name: value }));
  };

  const handleSelectModelId = (modelIdList: number[] | null) => {
    setFilters((prevState) => ({ ...prevState, machineModelIds: modelIdList }));
  };

  const handleSelectedStatus = (status: StatusColor | null) => {
    setFilters((prevState) => ({
      //
      ...prevState,
      statusColors: status ? [status] : null,
    }));
  };

  const handleSaleTypeChange = (salesFilter: SalesFilters) => {
    setFilters((prevState) => ({ ...prevState, salesFilter }));
  };

  const handleShutdownStatusChange = (shutdownReasonFilter: ShutdownReasonFilters | null) => {
    setFilters((prevState) => ({ ...prevState, shutdownReasonFilter }));
  };

  // render методы
  const renderNameCell = ({
    name,
    model,
    serialNumber,
    connectionStatus,
    isNew,
  }: MachineListItem) => (
    <VerticalContainer space="2xs">
      <HorizontalContainer space="xs" align="center">
        {isNew && <Badge status="success" label="NEW" />}
        <Text className={styles.text} weight="semibold">
          {name}
        </Text>
      </HorizontalContainer>
      <HorizontalContainer space="xs" align="center">
        <div
          className={classNames(
            styles.connectionStatus,
            getConnectionStatusClass(connectionStatus),
          )}
        />
        <HorizontalContainer space="xs">
          <Text className={styles.text} weight="semibold" view="secondary">
            {model?.name}
          </Text>
          <Text className={styles.text} view="secondary" size="m">
            {serialNumber}
          </Text>
        </HorizontalContainer>
      </HorizontalContainer>
    </VerticalContainer>
  );

  const renderSalesCell = ({ salePeriod }: MachineListItem) => (
    <VerticalContainer space="2xs">
      <Text className={styles.text} weight="semibold">
        {salePeriod?.qty}
      </Text>
      {/*<Text className={styles.text} weight="semibold" size="m">*/}
      {/*  0*/}
      {/*</Text>*/}
    </VerticalContainer>
  );

  const renderStorageCell = (machine: MachineListItem) => <MachineStorageCell machine={machine} />;

  const renderStatusCell = ({ status }: MachineListItem) => (
    <VerticalContainer space="2xs" align="end">
      <Text className={styles.text} weight="semibold" view={getStatus(status?.color)}>
        {status?.text}
      </Text>
      <Text className={styles.text} view="secondary" size="m">
        {status?.date}
      </Text>
    </VerticalContainer>
  );

  const getNewSortOrder = (oldOrder: SortDirection | null): SortDirection | null => {
    switch (oldOrder) {
      case SortDirection.ASC:
        return SortDirection.DESC;
      case SortDirection.DESC:
        return null;
      default:
        return SortDirection.ASC;
    }
  };

  if (!machineList) return null;

  return (
    <div className={styles.MachineList}>
      <MachineArchivedTabs
        isArchived={filters.isArchived}
        archivedCount={archivedCount}
        onArchiverChange={(isArchived) => () => {
          setFilters(() => ({ ...machineListInitialFilters, isArchived, organizationId }));
        }}
      />
      {!filters.isArchived && (
        <MachineListStatusBar
          listCount={filters.items}
          successCount={machineAtStatusesCount?.success || 0}
          warningCount={machineAtStatusesCount?.warning || 0}
          alertCount={machineAtStatusesCount?.error || 0}
          selectedStatus={filters.statusColors?.[0] || null}
          onSelectedStatus={handleSelectedStatus}
        />
      )}
      <MachineListHeader
        isArchived={!!filters.isArchived}
        listCount={filters.items}
        successCount={machineAtStatusesCount?.success || 0}
        warningCount={machineAtStatusesCount?.warning || 0}
        alertCount={machineAtStatusesCount?.error || 0}
        searchText={filters.name || ''}
        modelList={machineAtModelsCount || null}
        selectedModelId={filters.machineModelIds}
        onSearchChange={handleSearchChange}
        onSelectModelId={handleSelectModelId}
        selectedStatus={filters.statusColors?.[0] || null}
        onSelectedStatus={handleSelectedStatus}
        selectedSaleType={filters.salesFilter}
        onSaleTypeChange={handleSaleTypeChange}
        inStockNumber={machineAtShutdownCount?.in_STOCK || 0}
        selectedShutdownStatus={filters.shutdownReasonFilter}
        repairNumber={machineAtShutdownCount?.on_REPAIR || 0}
        soldNumber={machineAtShutdownCount?.sold || 0}
        scrapNumber={machineAtShutdownCount?.scrap || 0}
        onSelectShutdownStatus={handleShutdownStatusChange}
      />
      <TableCard
        className={styles.table}
        rows={machineList}
        sortOrder={{
          name: filters.sortNameDirection,
          salePeriod: null,
          cells: null,
          status: filters.sortStatusDirection,
        }}
        onRowClick={handleRowClick}
        columns={{
          name: {
            key: 'name',
            title: t('machineControl.machine.list.table.column.name'),
            sortable: true,
            type: 'default',
            onSortClick: () => {
              setFilters((prevState) => ({
                ...prevState,
                sortNameDirection: getNewSortOrder(prevState.sortNameDirection),
              }));
            },
            renderCell: renderNameCell,
          },
          salePeriod: {
            key: 'salePeriod',
            title: t('machineControl.machine.list.table.column.sales'),
            isNoRender: filters.isArchived || false,
            alignment: 'left',
            renderCell: renderSalesCell,
          },
          cells: {
            key: 'cells',
            title: t('machineControl.machine.list.table.column.cells'),
            alignment: 'left',
            sortable: true,
            type: 'custom',
            renderSort: () => (
              <VerticalContainer space="l">
                <Radio
                  label="По возрастанию воды"
                  checked={filters.sortWaterDirection === SortDirection.ASC}
                  onChange={({ checked }) => {
                    checked &&
                      setFilters((prevState) => ({
                        ...prevState,
                        sortCupsDirection: null,
                        sortWaterDirection: SortDirection.ASC,
                      }));
                  }}
                />
                <Radio
                  label="По возрастанию стаканов"
                  checked={filters.sortCupsDirection === SortDirection.ASC}
                  onChange={({ checked }) => {
                    checked &&
                      setFilters((prevState) => ({
                        ...prevState,
                        sortCupsDirection: SortDirection.ASC,
                        sortWaterDirection: null,
                      }));
                  }}
                />
                <Radio
                  label="По убыванию воды"
                  checked={filters.sortWaterDirection === SortDirection.DESC}
                  onChange={({ checked }) => {
                    checked &&
                      setFilters((prevState) => ({
                        ...prevState,
                        sortCupsDirection: null,
                        sortWaterDirection: SortDirection.DESC,
                      }));
                  }}
                />
                <Radio
                  label="По убыванию стаканов"
                  checked={filters.sortCupsDirection === SortDirection.DESC}
                  onChange={({ checked }) => {
                    checked &&
                      setFilters((prevState) => ({
                        ...prevState,
                        sortCupsDirection: SortDirection.DESC,
                        sortWaterDirection: null,
                      }));
                  }}
                />
              </VerticalContainer>
            ),
            renderCell: renderStorageCell,
          },
          status: {
            key: 'status',
            title: t('machineControl.machine.list.table.column.statusView'),
            alignment: 'right',
            sortable: true,
            type: 'default',
            onSortClick: () => {
              setFilters((prevState) => ({
                ...prevState,
                sortStatusDirection: getNewSortOrder(prevState.sortStatusDirection),
              }));
            },
            renderCell: renderStatusCell,
          },
        }}
      />
    </div>
  );
};

export default MachineList;
