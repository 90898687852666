import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconLogoShakerSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg width="295" height="42" viewBox="0 0 295 42" {...props}>
    <path d="M284.2 0.699997H276.7L276.2 3H278.6L277.4 9.09999H280.1L281.4 3H283.8L284.2 0.699997Z" />
    <path d="M291.8 0.699997L288.5 5L286.7 0.699997H284.5L282.9 9.09999H285.4L286.1 5.59999L287.3 8.29999H288.6L290.6 5.7L289.9 9.09999H292.5L294.2 0.699997H291.8Z" />
    <path d="M189.6 25.6H219.6L225.7 7.79999C226.3 5.99999 226 4.19999 225 2.59999C223.9 1.09999 222.2 0.199997 220.4 0.199997H193C190.6 0.199997 188.4 1.7 187.6 4L177.3 34C176.7 35.8 177 37.6 178 39.2C179.1 40.7 180.8 41.6 182.6 41.6H214.1L217.3 32.3H189.9C189.3 32.3 188.7 32 188.4 31.5C188 31 188 30.4 188.2 29.8L189.6 25.6ZM192.8 16.3L194.7 10.8C195 9.99999 195.7 9.5 196.5 9.5H212.9C213.5 9.5 214.1 9.79999 214.4 10.3C214.8 10.8 214.8 11.4 214.7 12L213.3 16.3H192.8Z" />
    <path d="M88.7 0.199997L83.1 16.3H62.7L68.2 0.199997H58.7L44.4 41.6H53.9L59.5 25.6H79.9L74.4 41.6H83.9L98.2 0.199997H88.7Z" />
    <path d="M139.1 2.59999C138 1.09999 136.3 0.199997 134.5 0.199997H103.1L99.9 9.5H127.1C127.7 9.5 128.3 9.79999 128.6 10.3C129 10.8 129 11.4 128.8 12L127.3 16.3H101.5C99.1 16.3 96.9 17.8 96.1 20.1L91.3 34C90.7 35.8 91 37.6 92 39.2C93.1 40.7 94.8 41.6 96.6 41.6H128L139.6 7.7C140.5 6 140.2 4.09999 139.1 2.59999ZM121.9 32.3H102.4C102.1 32.3 102 32.2 101.9 32.1C101.8 31.9 101.8 31.7 101.8 31.5L103.4 26.8C103.7 26 104.4 25.5 105.2 25.5H124.1L121.9 32.3Z" />
    <path d="M45.2 18.8C44.1 17.2 42 16.2 39.7 16.2H19.1C18.9 16.2 18.7 16.1 18.6 15.9C18.5 15.7 18.5 15.5 18.5 15.3L20.1 10.6C20.4 9.79999 21.1 9.29999 21.9 9.29999H50.5L53.7 0H19.2C16.2 0 13.6 1.9 12.6 4.7L8.1 17.9C7.5 19.7 7.8 21.5 8.9 23.1C10 24.6 11.7 25.5 13.5 25.5H34.6C34.8 25.5 35 25.6 35.1 25.8C35.2 26 35.2 26.2 35.2 26.4L33.6 31C33.3 31.7 32.6 32.3 31.8 32.3H3.2L0 41.6H35C37.7 41.6 40 40 40.9 37.5C40.9 37.4 44.6 26.8 45.8 23.3C46.4 21.8 46.2 20.2 45.2 18.8Z" />
    <path d="M264.7 0.199997H233.3L219 41.6H228.5L234 25.6H252.3C252.8 25.6 253.2 25.8 253.5 26.2C253.8 26.6 253.9 27.1 253.7 27.6L248.9 41.6H258.4L263.4 27C263.9 25.7 263.7 24.3 263.1 23.1C262.8 22.5 262.4 22.1 262 21.7C265.4 20.9 266.3 18.4 266.4 18.2L270 7.79999C270.6 5.99999 270.3 4.19999 269.3 2.59999C268.2 0.999991 266.6 0.199997 264.7 0.199997ZM256.3 16.2H237.4L239.7 9.5H259.2C259.5 9.5 259.6 9.6 259.7 9.7C259.8 9.9 259.8 10.1 259.8 10.3L258.2 15C257.8 15.7 257.1 16.2 256.3 16.2Z" />
    <path d="M177.6 18.3L184 0.199997H174.4L169.2 14.9C168.9 15.7 168.2 16.2 167.4 16.2H151.3L156.8 0.199997H147.3L133 41.6H142.5L148 25.6H163.4C163.9 25.6 164.3 25.8 164.6 26.2C164.9 26.6 165 27.1 164.8 27.6L160 41.6H169.5L174.5 27C175 25.7 174.8 24.3 174.2 23.1C173.9 22.6 173.5 22.1 173.1 21.7C176.7 20.9 177.6 18.4 177.6 18.3Z" />
  </svg>
);

export const IconLogoShaker = createIcon({
  name: 'IconLogoShaker',
  m: IconLogoShakerSizeL,
  s: IconLogoShakerSizeL,
  xs: IconLogoShakerSizeL,
});
