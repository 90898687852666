import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconFavoriteFillSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_29751_2617)">
      <path d="M8.08214 2.1378C7.42676 0.120735 4.57316 0.120735 3.91777 2.1378C3.72508 2.73084 3.17244 3.13235 2.54888 3.13235C0.428019 3.13235 -0.453794 5.84629 1.26202 7.09291C1.76649 7.45942 1.97758 8.10909 1.78489 8.70213C1.12951 10.7192 3.43812 12.3965 5.15394 11.1499C5.65841 10.7834 6.34151 10.7834 6.84598 11.1499C8.5618 12.3965 10.8704 10.7192 10.215 8.70213C10.0223 8.10909 10.2334 7.45942 10.7379 7.09291C12.4537 5.84629 11.5719 3.13235 9.45104 3.13235C8.82748 3.13235 8.27483 2.73084 8.08214 2.1378Z" />
    </g>
    <defs>
      <clipPath id="clip0_29751_2617">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconFavoriteFillSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_29751_2613)">
      <path d="M10.7762 2.85044C9.90234 0.16102 6.09753 0.16102 5.22369 2.85044C4.96677 3.64116 4.22991 4.17651 3.3985 4.17651C0.570681 4.17651 -0.605069 7.7951 1.68268 9.45725C2.35531 9.94594 2.63677 10.8122 2.37985 11.6029C1.506 14.2923 4.58415 16.5287 6.87191 14.8666C7.54453 14.3779 8.45534 14.3779 9.12797 14.8666C11.4157 16.5287 14.4939 14.2923 13.62 11.6029C13.3631 10.8122 13.6446 9.94594 14.3172 9.45725C16.6049 7.7951 15.4292 4.17651 12.6014 4.17651C11.77 4.17651 11.0331 3.64116 10.7762 2.85044Z" />
    </g>
    <defs>
      <clipPath id="clip0_29751_2613">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconFavoriteFillSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M16.1643 4.02559C14.8535 -0.00853062 9.14631 -0.00853062 7.83555 4.02559C7.45017 5.21167 6.34488 6.01471 5.09776 6.01471C0.856037 6.01471 -0.907588 11.4426 2.52404 13.9358C3.53298 14.6688 3.95516 15.9682 3.56978 17.1543C2.25902 21.1884 6.87624 24.543 10.3079 22.0498C11.3168 21.3167 12.683 21.3167 13.692 22.0498C17.1236 24.543 21.7408 21.1884 20.4301 17.1543C20.0447 15.9682 20.4669 14.6688 21.4758 13.9358C24.9074 11.4426 23.1438 6.01471 18.9021 6.01471C17.655 6.01471 16.5497 5.21167 16.1643 4.02559Z" />
  </svg>
);

export const IconFavoriteFill = createIcon({
  name: 'IconFavoriteFill',
  xs: IconFavoriteFillSizeXS,
  s: IconFavoriteFillSizeS,
  m: IconFavoriteFillSizeM,
});
