import { FC } from 'react';
import { CreateWaterModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import ModelWaterCellFormItem from './ModelWaterCellFormItem';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import { WaterCellsFieldError } from '../../types';

/**
 * Свойства компонента ModelWaterCellForm
 */
type ModelWaterCellFormProps = {
  /**
   * Список ячеек воды
   */
  cellList: CreateWaterModelCell[];
  /**
   * Ошибки
   */
  errors: Record<number, WaterCellsFieldError>;
  /**
   * Обработчик изменения ячеек воды модели автомата
   *
   * @param index порядковый номер ячейки в списке ячеек воды
   * @param key ключ поля в форме ячейки воды
   * @param value новое значение поля
   */
  onChange: (index: number) => (key: keyof CreateWaterModelCell) => (value: string | null) => void;
};

/**
 * Форма изменения ячеек воды
 */
const ModelWaterCellForm: FC<ModelWaterCellFormProps> = ({ cellList, errors, onChange }) => {
  //render методы
  const renderCellItemForm = (cell: CreateWaterModelCell, index: number) => (
    <ModelWaterCellFormItem
      key={index}
      cell={cell}
      error={errors?.[index]}
      onChange={onChange(index)}
    />
  );
  return <VerticalContainer>{cellList.map(renderCellItemForm)}</VerticalContainer>;
};

export default ModelWaterCellForm;
