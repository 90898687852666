import { FC, useState } from 'react';
import { ProductModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import DefaultInfo from '../../../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../../../components/DefaultInfoItem';
import { Collapse } from '@consta/uikit/Collapse';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ProductCellModelInfo
 */
type ProductCellModelInfoProps = {
  /**
   * Ячейка
   */
  cell: ProductModelCell;
};

/**
 * Информация о ячейке продукта модели автомата
 */
const ProductCellModelInfo: FC<ProductCellModelInfoProps> = ({ cell }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleCollapseClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Collapse
      isOpen={isOpen}
      iconPosition="right"
      label={t('machineControl.model.info.cells.product.collapse.label', {
        cellNumber: cell.cellNumber,
      })}
      onClick={handleCollapseClick}
    >
      <DefaultInfo>
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.cellNumber.item.label'),
            value: String(cell.cellNumber),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.cellCategoryName.item.label'),
            value: String(cell.cellCategoryName),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.cellPurposeName.item.label'),
            value: String(cell.cellPurposeName),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.group.item.label'),
            value: t(`machineControl.model.info.cells.product.group.${String(cell.group)}`),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.view.item.label'),
            value: t(`machineControl.model.info.cells.product.view.${String(cell.view)}`),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.maxVolume.item.label'),
            value: String(cell.maxVolume),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.minVolume.item.label'),
            value: String(cell.minVolume),
          }}
        />
        <DefaultInfoItem
          item={{
            name: t('machineControl.model.info.cells.product.expirationTimer.item.label'),
            value: String(cell.expirationTimer),
          }}
        />
      </DefaultInfo>
    </Collapse>
  );
};

export default ProductCellModelInfo;
