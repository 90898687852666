import * as Yup from 'yup';
import { EditProductLineDTO } from '../../../../types/serverInterface/productLineDTO';

/**
 * Валидационная схема формы изменения линейки продуктов
 */
export const editProductLineValidationScheme: Yup.ObjectSchema<EditProductLineDTO> = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required(),
  description: Yup.string(),
  mediaKey: Yup.string().required(),
  brandId: Yup.number().required(),
  organizationId: Yup.number().nullable(),
});
