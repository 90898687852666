import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../components/VerticalContainer';
import MemberKeyProfile from './MemberKeyProfile';
import MemberKeyHistory from './MemberKeyHistory';
import MemberKeyActive from './MemberKeyActive';
import MemberKeyAccess from './MemberKeyAccess';
import styles from './MemberKeyPage.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectEmployeeWithKeyElement } from '../../../../state/organization/selectors';
import { getEmployeeWithKeyAction } from '../../../../state/organization/actions';
import { DisplayType, displayTypeValue } from '../../../App';
import classNames from 'classnames';
import QrKeyModal from '../QrKeyModal';

type MemberKeyPageProps = {
  organizationId: number;
};

const MemberKeyPage: FC<MemberKeyPageProps> = ({ organizationId }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { uuid } = useParams<{ uuid: string }>();

  const [isOpenQr, setIsOpenQr] = useState(false);

  const dispatch = useAppDispatch();

  const employee = useAppSelector(selectEmployeeWithKeyElement());

  useEffect(() => {
    uuid && organizationId && dispatch(getEmployeeWithKeyAction(organizationId, uuid));
  }, [uuid, dispatch, organizationId]);

  if (!uuid || !employee) return null;

  const { name, patronymic, surname, isActiveKey } = employee;

  const userName = `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`;

  // Обработчики
  const handleOpenQr = () => {
    setIsOpenQr(true);
  };

  const handleCloseQr = () => {
    uuid &&
      organizationId &&
      dispatch(getEmployeeWithKeyAction(organizationId, uuid)).then(() => {
        setIsOpenQr(false);
      });
  };

  return (
    <HorizontalContainer
      className={classNames(styles.MemberKeyPage, isLaptop && styles.laptop)}
      space={isLaptop ? '3xl' : '5xl'}
    >
      <QrKeyModal
        isOpen={isOpenQr}
        organizationId={organizationId}
        userUuid={uuid}
        userName={userName}
        isActive={isActiveKey}
        onClose={handleCloseQr}
      />
      <VerticalContainer className={styles.leftSide} space={isLaptop ? '3xl' : '5xl'}>
        <MemberKeyProfile employee={employee} onOpenQr={handleOpenQr} />
        <MemberKeyHistory histories={employee.histories} />
      </VerticalContainer>
      <VerticalContainer className={styles.rightSide} space={isLaptop ? '3xl' : '5xl'}>
        <MemberKeyActive activity={employee.activity} />
        <MemberKeyAccess access={employee.access} organizationId={organizationId} userUuid={uuid} />
      </VerticalContainer>
    </HorizontalContainer>
  );
};

export default MemberKeyPage;
