import { FC } from 'react';
import { Ingredient } from '../../../../../../types/serverInterface/recipeDTO';
import RecipeIngredientForm from './RecipeIngredientForm';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconTop } from '@consta/uikit/IconTop';
import { IconDown } from '@consta/uikit/IconDown';
import { IconCollapse } from '@consta/uikit/IconCollapse';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import classNames from 'classnames';
import { IconExpand } from '@consta/uikit/IconExpand';
import StepBadge from '../../../../../../components/StepBadge';
import { IconAdd } from '@consta/uikit/IconAdd';
import styles from './RecipeIngredientsStepForm.module.scss';

/**
 * Свойства компонента RecipeIngredientsStepForm
 */
type RecipeIngredientsItemFormItemProps = {
  /**
   * Список ингредиентов шага
   */
  ingredients: Ingredient[];
  /**
   * Индекс шага
   */
  index: number;
  /**
   * Флаг- является выбранным шагом для объединения
   */
  isSelectStep: boolean;
  /**
   * Обработчик изменения ингредиентов внутри шага
   *
   * @param index индекс ингредиента
   * @param key ключ в ингредиенте
   * @param value изменённое значение
   */
  onChange: (index: number) => (key: keyof Ingredient) => (value: string | null) => void;
  /**
   * Обработчик изменения ингредиентов внутри шага (boolean)
   *
   * @param index индекс ингредиента
   * @param key ключ в ингредиенте
   * @param checked изменённое значение
   */
  onBooleanChange: (index: number) => (key: keyof Ingredient) => (checked: boolean) => void;
  /**
   * Обработчик изменения ингредиентов внутри шага (select)
   *
   * @param index индекс ингредиента
   * @param key ключ в ингредиенте
   * @param id изменённое значение
   */
  onSelectChange: (index: number) => (key: keyof Ingredient) => (id: number | null) => void;
  /**
   * Обработчик изменения ингредиентов внутри шага (вкус)
   *
   * @param index индекс ингредиента
   * @param idList список id вкусов
   */
  onTasteChange: (index: number) => (idList: number[]) => void;
  /**
   * Обработчик удаления шага
   */
  onDeleteStep: () => void;
  /**
   * Обработчик перемещения шага на строку вверх
   */
  onMoveStepUp: () => void;
  /**
   * Обработчик перемещение шага на строку вниз
   */
  onMoveStepDown: () => void;
  /**
   * Обработчик выбора шага для объединения
   */
  onSelectStep: () => void;
  /**
   * Обработчик разбития шага на ингредиенты в разные шаги
   */
  onDisconnectStep: () => void;
};

/**
 * Шаг в форме изменение списка ингредиентов рецепта
 */
const RecipeIngredientsStepForm: FC<RecipeIngredientsItemFormItemProps> = ({
  ingredients,
  index,
  isSelectStep,
  onChange,
  onBooleanChange,
  onSelectChange,
  onTasteChange,
  onDeleteStep,
  onMoveStepUp,
  onMoveStepDown,
  onSelectStep,
  onDisconnectStep,
}) => {
  const isCombinedStep = ingredients.length > 1;

  // Обработчики
  const handleChange = onChange;

  const handleBooleanChange = onBooleanChange;

  const handleSelectChange = onSelectChange;

  const handleTasteChange = onTasteChange;

  const handleDeleteStep = () => {
    onDeleteStep();
  };

  const handleMoveStepUp = () => {
    onMoveStepUp();
  };

  const handleMoveStepDown = () => {
    onMoveStepDown();
  };

  const handleSelectStep = () => {
    onSelectStep();
  };

  const handleDisconnectStep = () => {
    onDisconnectStep();
  };

  // render методы
  const renderIngredient = (ingredient: Ingredient, index: number) => (
    <RecipeIngredientForm
      key={index}
      ingredient={ingredient}
      onChange={handleChange(index)}
      onBooleanChange={handleBooleanChange(index)}
      onSelectChange={handleSelectChange(index)}
      onTasteChange={handleTasteChange(index)}
    />
  );

  const renderCombineActions = () =>
    isCombinedStep ? (
      <FieldGroup size="s">
        <Button
          view="secondary"
          onlyIcon
          iconLeft={(isSelectStep ? IconAdd : IconCollapse) as any}
          onClick={handleSelectStep}
        />
        <Button
          view="secondary"
          onlyIcon
          iconLeft={IconExpand as any}
          onClick={handleDisconnectStep}
        />
      </FieldGroup>
    ) : (
      <Button
        size="s"
        view="secondary"
        onlyIcon
        iconLeft={(isSelectStep ? IconAdd : IconCollapse) as any}
        onClick={handleSelectStep}
      />
    );

  return (
    <div className={classNames(styles.formItem, isSelectStep && styles.selectStep)}>
      <div className={styles.header}>
        <div className={styles.actions}>
          <StepBadge stepNumber={index + 1} />
          <FieldGroup size="s">
            <Button
              view="secondary"
              onlyIcon
              iconLeft={IconTop as any}
              onClick={handleMoveStepUp}
            />
            <Button
              view="secondary"
              onlyIcon
              iconLeft={IconDown as any}
              onClick={handleMoveStepDown}
            />
          </FieldGroup>
          {renderCombineActions()}
        </div>
        <Button view="clear" onlyIcon iconLeft={IconTrash as any} onClick={handleDeleteStep} />
      </div>

      {ingredients.map(renderIngredient)}
    </div>
  );
};

export default RecipeIngredientsStepForm;
