import React, { FC, useState } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import ProductLineList from '../ProductLine';
import { BrandDTO } from '../../../types/serverInterface/brandDTO';
import { deleteBrandAction } from '../../../state/productBase/actions';
import BrandForm from './BrandForm';
import BrandInfo from './BrandInfo';
import DeleteModal from '../../../components/DeleteModal';
import BrandLocale from './BrandLocale';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectOrganizationId } from '../../../state/organization/selectors';
import styles from './Brand.module.scss';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { IconArrowUp } from '@consta/uikit/IconArrowUp';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconKebab } from '@consta/uikit/IconKebab';
import TableContextMenu from '../../../components/TableContextMenu';
import { TableContextMenuItem } from '../../../components/TableContextMenu/TableContextMenu';
import { useTranslation } from 'react-i18next';

/**
 * Возможные элементы контекстного меню бренда
 */
enum BrandContextMenuItems {
  /**
   * Информация
   */
  INFO = 'INFO',
  /**
   * Локализация
   */
  LOCALE = 'LOCALE',
  /**
   * Удаление
   */
  DELETE = 'DELETE',
}

/**
 * Список элементов для контекстного меню бренда с возможностью редактрировать
 */
const brandContextMenuItemsWithEdit = [
  {
    name: BrandContextMenuItems.INFO,
    label: 'productBase.brand.contextMenu.INFO',
    iconLeft: IconInfo,
    group: 1,
  },
  {
    name: BrandContextMenuItems.LOCALE,
    label: 'productBase.brand.contextMenu.LOCALE',
    iconLeft: IconWorldStroked,
    group: 1,
  },
  {
    name: BrandContextMenuItems.DELETE,
    label: 'productBase.brand.contextMenu.DELETE',
    iconLeft: IconTrash,
    group: 2,
  },
];

/**
 * Список элементов для контекстного меню бренда без возможности редактировать
 */
const brandContextMenuItemsWithoutEdit = [
  {
    name: BrandContextMenuItems.INFO,
    label: 'productBase.brand.contextMenu.INFO',
    iconLeft: IconInfo,
    group: 1,
  },
];

/**
 * Свойства компонента Brand
 */
type BrandProps = {
  /**
   * Бренд
   */
  brand: BrandDTO;
  /**
   * Возможность пользователя редактировать глобальные сущности
   */
  isAllowedGlobalEdit: boolean;
};

/**
 * Элемент списка брендов
 */
const Brand: FC<BrandProps> = ({ brand, isAllowedGlobalEdit }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const selectedOrganizationId = useAppSelector(selectOrganizationId());

  const isAllowedEdit = !!brand.organizationId || isAllowedGlobalEdit;

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isEditBrandOpen, setIsEditBrandOpen] = useState(false);
  const [isBrandInfoOpen, setIsBrandInfoOpen] = useState(false);
  const [isDeleteBrandOpen, setIsDeleteBrandOpen] = useState(false);
  const [isBrandLocaleOpen, setIsBrandLocaleOpen] = useState(false);
  const [isProductLineListOpen, setIsProductLineListOpen] = useState(false);

  const { id, name } = brand;

  // Обработчики
  const handleEditBrandFormClose = () => {
    setIsEditBrandOpen(false);
    setIsBrandInfoOpen(true);
  };

  const handleEditBrandClick = () => {
    setIsEditBrandOpen(true);
    setIsBrandInfoOpen(false);
  };

  const handleBrandInfoClick = () => {
    setIsBrandInfoOpen(true);
  };

  const handleBrandInfoClose = () => {
    setIsBrandInfoOpen(false);
  };

  const handleDeleteBrandClick = () => {
    setIsDeleteBrandOpen(true);
  };

  const handleDeleteBrandCancel = () => {
    setIsDeleteBrandOpen(false);
  };

  const handleDeleteBrand = () => {
    setIsDeleteLoading(true);
    isAllowedEdit &&
      dispatch(deleteBrandAction(id, selectedOrganizationId)).finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const handleBrandLocaleClick = () => {
    setIsBrandLocaleOpen(true);
  };

  const handleBrandLocaleCloseClick = () => {
    setIsBrandLocaleOpen(false);
  };

  const handleProductLineOpenClick = () => {
    setIsProductLineListOpen((prevState) => !prevState);
  };

  const handleContextMenuItemClick = (item: TableContextMenuItem) => {
    switch (item.name) {
      case BrandContextMenuItems.INFO:
        handleBrandInfoClick();
        return;
      case BrandContextMenuItems.LOCALE:
        handleBrandLocaleClick();
        return;
      case BrandContextMenuItems.DELETE:
        handleDeleteBrandClick();
        return;
    }
  };

  const handleRowClick = () => {
    !isProductLineListOpen && setIsProductLineListOpen(true);
  };

  // render методы
  const renderEditBrandForm = () => (
    <BrandForm
      type="edit"
      brand={brand}
      isOpen={isEditBrandOpen}
      onClose={handleEditBrandFormClose}
    />
  );

  const renderBrandInfo = () => (
    <BrandInfo
      brand={brand}
      isOpen={isBrandInfoOpen}
      isAllowedEdit={isAllowedEdit}
      onClose={handleBrandInfoClose}
      onEditClick={handleEditBrandClick}
    />
  );

  const renderDeleteBrand = () => (
    <DeleteModal
      isOpen={isDeleteBrandOpen}
      isDeleteLoading={isDeleteLoading}
      title={t('productBase.brand.deleteModal.title', { name })}
      description={t('productBase.brand.deleteModal.description', { name })}
      withConfirmDeletion={false}
      onClose={handleDeleteBrandCancel}
      onDelete={handleDeleteBrand}
    />
  );

  const renderBrandLocale = () => (
    <BrandLocale brandId={id} isOpen={isBrandLocaleOpen} onClose={handleBrandLocaleCloseClick} />
  );

  const renderProductLineList = () =>
    isProductLineListOpen && (
      <ProductLineList
        brandId={id}
        organizationId={selectedOrganizationId || null}
        isAllowedGlobalEdit={isAllowedGlobalEdit}
      />
    );

  return (
    <div className={styles.tableBrandItem}>
      <div className={styles.brand} onClick={handleRowClick}>
        <div className={styles.brandInfo}>
          <Text size="2xl">{name}</Text>
          <TableContextMenu
            className={styles.brandContextMenu}
            buttonIcon={IconKebab}
            items={isAllowedEdit ? brandContextMenuItemsWithEdit : brandContextMenuItemsWithoutEdit}
            onItemClick={handleContextMenuItemClick}
          />
        </div>
        <Button
          view="clear"
          iconLeft={(isProductLineListOpen ? IconArrowUp : IconArrowDown) as any}
          onlyIcon
          onClick={handleProductLineOpenClick}
        />
      </div>
      {renderProductLineList()}
      {isAllowedEdit && renderEditBrandForm()}
      {renderBrandInfo()}
      {isAllowedEdit && renderDeleteBrand()}
      {isAllowedEdit && renderBrandLocale()}
    </div>
  );
};

export default Brand;
