import { FC } from 'react';
import { ProductModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import ProductCellModelInfo from './ProductCellModelInfo';
import { cellSortFunc } from '../helpers';

/**
 * Свойства компонента ProductCellsModelInfo
 */
type ProductCellsModelInfoProps = {
  /**
   * Ячейки продуктов
   */
  cells: ProductModelCell[];
};

/**
 * Информация о ячейках продуктов модели автомата
 */
const ProductCellsModelInfo: FC<ProductCellsModelInfoProps> = ({ cells }) => {
  const sortedCells = [...cells].sort(cellSortFunc);

  // render методы
  const renderCell = (cell: ProductModelCell, index: number) => (
    <ProductCellModelInfo key={index} cell={cell} />
  );

  return <VerticalContainer>{sortedCells.map(renderCell)}</VerticalContainer>;
};

export default ProductCellsModelInfo;
