import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconWaterSizeXS = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path d="M6 0.369995C5.63547 0.369995 5.35262 0.522546 5.09612 0.755543C4.86102 0.969105 4.61857 1.27831 4.33397 1.64128L4.31452 1.66608C3.73144 2.40962 3.06639 3.32926 2.54532 4.25047C2.03255 5.15701 1.625 6.12302 1.625 6.94338C1.625 9.50134 3.55833 11.6227 6 11.6227C8.44167 11.6227 10.375 9.50135 10.375 6.94339C10.375 6.12302 9.96745 5.15701 9.45468 4.25047C8.93361 3.32926 8.26856 2.40962 7.68548 1.66609L7.66603 1.64128C7.38143 1.27831 7.13898 0.969106 6.90388 0.755543C6.64738 0.522546 6.36453 0.369995 6 0.369995Z" />
  </svg>
);

const IconWaterSizeS = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path d="M8.00002 0.5C7.51398 0.5 7.13685 0.703401 6.79485 1.01406C6.48138 1.29881 6.15811 1.71109 5.77865 2.19504L5.75271 2.22812C4.97527 3.2195 4.08854 4.44568 3.39378 5.67397C2.71009 6.88269 2.16669 8.1707 2.16669 9.26452C2.16669 12.6751 4.74446 15.5036 8.00002 15.5036C11.2556 15.5036 13.8334 12.6751 13.8334 9.26452C13.8334 8.1707 13.2899 6.88269 12.6063 5.67397C11.9115 4.44568 11.0248 3.2195 10.2473 2.22812L10.2214 2.19505C9.84193 1.71109 9.51866 1.29881 9.20519 1.01406C8.86319 0.703401 8.48606 0.5 8.00002 0.5Z" />
  </svg>
);

const IconWaterSizeM = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M12 0.75C11.2709 0.75 10.7052 1.05503 10.1922 1.52091C9.72204 1.94793 9.23714 2.56619 8.66794 3.29194L8.62903 3.34155C7.46288 4.82826 6.13277 6.66708 5.09064 8.50906C4.06511 10.3217 3.25 12.2533 3.25 13.8936C3.25 19.0083 7.11666 23.25 12 23.25C16.8833 23.25 20.75 19.0083 20.75 13.8936C20.75 12.2533 19.9349 10.3217 18.9094 8.50906C17.8672 6.66708 16.5371 4.82826 15.371 3.34155L15.3321 3.29195C14.7629 2.56619 14.278 1.94793 13.8078 1.52091C13.2948 1.05503 12.7291 0.75 12 0.75Z" />
  </svg>
);

export const IconWater = createIcon({
  name: 'IconWater',
  xs: IconWaterSizeXS,
  s: IconWaterSizeS,
  m: IconWaterSizeM,
});
