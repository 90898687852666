import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductBaseList from './ProductBaseList';
import RecipeList from './Recipe';

/**
 * Роут базы продуктов
 *
 * Внешние роли разработчик / производитель / владелец / пользователь
 */
const ProductBasePage: FC = () => (
  <Routes>
    <Route path="/list/*" element={<ProductBaseList />} />
    <Route path="/recipe/*" element={<RecipeList />} />
  </Routes>
);

export default ProductBasePage;
