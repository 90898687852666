import * as Yup from 'yup';
import { EditBrandDTO } from '../../../../types/serverInterface/brandDTO';

/**
 * Схема валидации для изменения бренда
 */
export const editBrandValidationScheme: Yup.ObjectSchema<EditBrandDTO> = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required(),
  mediaKey: Yup.string().required(),
  organizationId: Yup.number().nullable(),
});
