import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconCheckSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M10.2881 3.74008C10.4207 3.58097 10.3992 3.34451 10.2401 3.21193C10.081 3.07934 9.8445 3.10084 9.71191 3.25994L7.01657 6.49435C6.47515 7.14405 6.09429 7.59968 5.76394 7.89799C5.44129 8.18934 5.21862 8.28193 4.99999 8.28193C4.78136 8.28193 4.5587 8.18934 4.23605 7.89799C3.9057 7.59968 3.52483 7.14405 2.98341 6.49434L2.28808 5.65994C2.15549 5.50084 1.91903 5.47934 1.75993 5.61193C1.60082 5.74451 1.57932 5.98097 1.71191 6.14008L2.42655 6.99764C2.94418 7.61882 3.35872 8.11628 3.7334 8.45462C4.12043 8.80411 4.51606 9.03193 4.99999 9.03193C5.48393 9.03193 5.87956 8.80411 6.26658 8.45462C6.64126 8.11629 7.0558 7.61883 7.57343 6.99766L10.2881 3.74008Z" />
  </svg>
);

const IconCheckSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M13.7174 4.98685C13.8942 4.77471 13.8656 4.45943 13.6534 4.28265C13.4413 4.10587 13.126 4.13453 12.9492 4.34667L9.35543 8.65921C8.63353 9.52548 8.12571 10.133 7.68525 10.5307C7.25505 10.9192 6.95816 11.0427 6.66665 11.0427C6.37515 11.0427 6.07826 10.9192 5.64806 10.5307C5.2076 10.133 4.69977 9.52548 3.97788 8.65921L3.05076 7.54667C2.87398 7.33453 2.5587 7.30587 2.34656 7.48265C2.13442 7.65943 2.10576 7.97471 2.28254 8.18685L3.23539 9.33027C3.92557 10.1585 4.47829 10.8218 4.97787 11.2729C5.4939 11.7389 6.02141 12.0427 6.66665 12.0427C7.3119 12.0427 7.8394 11.7389 8.35544 11.2729C8.85501 10.8218 9.40773 10.1585 10.0979 9.33029L13.7174 4.98685Z" />
  </svg>
);

const IconCheckSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M20.5762 6.48016C20.8413 6.16195 20.7983 5.68903 20.4801 5.42385C20.1619 5.15868 19.689 5.20167 19.4238 5.51988L14.0331 11.9887C12.9503 13.2881 12.1886 14.1994 11.5279 14.796C10.8826 15.3787 10.4372 15.5639 9.99999 15.5639C9.56273 15.5639 9.11739 15.3787 8.4721 14.796C7.8114 14.1994 7.04966 13.2881 5.96683 11.9887L4.57615 10.3199C4.31098 10.0017 3.83806 9.95868 3.51985 10.2239C3.20164 10.489 3.15865 10.9619 3.42382 11.2802L4.85309 12.9953C5.88836 14.2376 6.71745 15.2326 7.46681 15.9092C8.24086 16.6082 9.03212 17.0639 9.99999 17.0639C10.9679 17.0639 11.7591 16.6082 12.5332 15.9092C13.2825 15.2326 14.1116 14.2377 15.1469 12.9953L20.5762 6.48016Z" />
  </svg>
);

export const IconCheck = createIcon({
  name: 'IconCheck',
  xs: IconCheckSizeXS,
  s: IconCheckSizeS,
  m: IconCheckSizeM,
});
