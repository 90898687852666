import { FC, useEffect } from 'react';
import { EditedProductComponent } from '../../../../../types/serverInterface/productDTO';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Combobox } from '@consta/uikit/Combobox';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { selectComponentList } from '../../../../../state/productBase/selectors';
import { getComponentListAction } from '../../../../../state/productBase/actions';
import { ComponentDTO } from '../../../../../types/serverInterface/componentDTO';
import styles from './ProductComponentsForm.module.scss';
import { IconAdd } from '@consta/uikit/IconAdd';
import {
  ComponentTypeUnit,
  componentUnitEnergyValue,
  componentUnitVolumeList,
  componentUnitWeight,
} from '../../../../../types/enums/componentunits';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение элемента состава(используется про добавлении нового элемента состава в продукт)
 */
const componentInitial: EditedProductComponent = {
  componentId: null,
  qty: null,
  unit: null,
};

/**
 * Свойства компонента ProductComponentsForm
 */
type ProductComponentsFormProps = {
  /**
   * Состав продукта
   */
  productComponents: EditedProductComponent[];
  /**
   * Обработчик изменения состава
   *
   * @param data обновлённый состав продукта
   */
  onChange: (data: EditedProductComponent[]) => void;
};

/**
 * Форма изменения состава продукта
 */
const ProductComponentsForm: FC<ProductComponentsFormProps> = ({ productComponents, onChange }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const components = useAppSelector(selectComponentList());

  useEffect(() => {
    dispatch(getComponentListAction());
  }, [dispatch]);

  // Вспомогательные методы
  const getComponentById = (id: number | null) =>
    components.find((component) => component.id === id) || null;

  const getUnits = ({ componentId }: { componentId: number | null }) => {
    const component = getComponentById(componentId);

    const typeUnit = component?.typeUnit;

    const unitsList = (
      typeUnit && typeUnit === ComponentTypeUnit.WEIGHT
        ? componentUnitWeight
        : typeUnit === ComponentTypeUnit.VOLUME
          ? componentUnitVolumeList
          : typeUnit === ComponentTypeUnit.ENERGY_VALUE
            ? componentUnitEnergyValue
            : []
    ) as string[];

    return { defaultUnit: component?.defaultUnit, unitsList };
  };

  // Обработчики
  const handleAddClick = () => {
    const editableComponents = [...(productComponents || ([] as EditedProductComponent[]))] || [];

    editableComponents.push(componentInitial);

    onChange(editableComponents);
  };

  const handleDeleteClick = (index: number) => () => {
    const editableComponents = [...productComponents];

    if (index < editableComponents.length) {
      onChange([...editableComponents.slice(0, index), ...editableComponents.slice(index + 1)]);
    }
  };

  const handleChange =
    (index: number) =>
    (key: keyof EditedProductComponent) =>
    ({ value }: { value: string | null }) => {
      const editableComponents = [...productComponents];

      if (editableComponents.length > index) {
        editableComponents[index] = { ...editableComponents[index], [key]: value };

        onChange(editableComponents);
      }
    };

  const handleComponentIdChange =
    (index: number) =>
    ({ value }: { value: ComponentDTO | null }) => {
      const componentId = value ? value.id : null;
      const editableComponents = [...productComponents];

      if (editableComponents.length > index) {
        const { defaultUnit } = getUnits({ componentId });

        editableComponents[index] = {
          ...editableComponents[index],
          componentId,
          unit: defaultUnit || null,
        };

        onChange(editableComponents);
      }
    };

  if (!components) return null;

  // render методы
  const renderFormItem = (formItem: EditedProductComponent, index: number) => {
    const { unitsList } = getUnits(formItem);

    return (
      <div className={styles.formItem}>
        <Combobox
          className={styles.componentsSelect}
          value={getComponentById(formItem.componentId)}
          items={components}
          getItemLabel={({ name }: ComponentDTO) => name}
          onChange={handleComponentIdChange(index)}
        />
        <TextField
          className={styles.unitsSelect}
          type="number"
          value={String(formItem.qty)}
          onChange={handleChange(index)('qty')}
        />
        <Combobox
          className={styles.unitsSelect}
          items={unitsList}
          value={formItem.unit}
          getItemLabel={(data) => t(`productBase.units.${data}`)}
          getItemKey={(data) => data}
          onChange={handleChange(index)('unit')}
        />
        <Button
          view="ghost"
          onlyIcon
          iconLeft={IconTrash as any}
          onClick={handleDeleteClick(index)}
        />
      </div>
    );
  };

  return (
    <>
      {productComponents.map(renderFormItem)}
      <Button
        label={t('productBase.product.form.components.add.button.label')}
        view="ghost"
        width="full"
        iconLeft={IconAdd as any}
        onClick={handleAddClick}
      />
    </>
  );
};

export default ProductComponentsForm;
