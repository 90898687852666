import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProductBaseConfigHome from './ProductBaseConfigHome';
import CellPurpose from './CellPurpose';
import Taste from './Taste';
import SportPit from './SportPit';
import Component from './Component';
import CellCategory from './CellCategory';

/**
 * Роут конфигурации базы продуктов
 */
const ProductBaseConfigPage: FC = () => {
  return (
    <Routes>
      <Route path="/component" element={<Component />} />
      <Route path="/sportPit" element={<SportPit />} />
      <Route path="/taste" element={<Taste />} />
      <Route path="/cellCategory" element={<CellCategory />} />
      <Route path="/cellPurpose" element={<CellPurpose />} />
      <Route path="/" element={<ProductBaseConfigHome />} />
    </Routes>
  );
};

export default ProductBaseConfigPage;
