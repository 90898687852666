import { FC } from 'react';
import { CreateCupModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import CupCellModelInfo from './CupCellModelInfo';
import { cellSortFunc } from '../helpers';

/**
 * Свойства компонента CupCellsModelInfo
 */
type CupCellsModelInfoProps = {
  /**
   * Ячейки стаканов
   */
  cells: CreateCupModelCell[];
};

/**
 * Информация о ячейках стаканов модели автомата
 */
const CupCellsModelInfo: FC<CupCellsModelInfoProps> = ({ cells }) => {
  const sortedCells = [...cells].sort(cellSortFunc);

  // render методы
  const renderCell = (cell: CreateCupModelCell, index: number) => (
    <CupCellModelInfo key={index} cell={cell} />
  );

  return <VerticalContainer>{sortedCells.map(renderCell)}</VerticalContainer>;
};

export default CupCellsModelInfo;
