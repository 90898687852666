import { FC } from 'react';
import { CreateCupModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import ModelCupCellFormItem from './ModelCupCellFormItem';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { CupCellsFieldError } from '../../types';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ModelCupCellForm
 */
type ModelCupCellFormProps = {
  /**
   * Ячейки стаканов модели автомата
   */
  cellList: CreateCupModelCell[];
  /**
   * Ошибки ячеек стаканов модели автомата
   */
  errors: Record<number, CupCellsFieldError>;
  /**
   * Обработчик изменения ячейки стакана
   *
   * @param index порядковый номер ячейки в списке ячеек стаканов модели автомата
   * @param key ключ поля в форме изменения ячейки стакана модели автомата
   * @param value новое значение поля
   */
  onChange: (index: number) => (key: keyof CreateCupModelCell) => (value: string | null) => void;
  /**
   * Обработчик добавления ячейки стакана
   */
  onAddClick: () => void;
  /**
   * Обработчик удаления ячейки стакана
   *
   * @param index порядковый номер ячейки в списке ячеек стаканов модели автомата
   */
  onDeleteClick: (index: number) => () => void;
  /**
   * Обработчик копирования ячейки стакана
   *
   * @param index порядковый номер ячейки в списке ячеек стаканов модели автомата
   */
  onCopyClick: (index: number) => () => void;
};

/**
 * Форма ячеек стаканов
 */
const ModelCupCellForm: FC<ModelCupCellFormProps> = ({
  cellList,
  errors,
  onChange,
  onAddClick,
  onDeleteClick,
  onCopyClick,
}) => {
  const { t } = useTranslation();

  //render методы
  const renderCellItemForm = (cell: CreateCupModelCell, index: number) => (
    <ModelCupCellFormItem
      key={index}
      cell={cell}
      error={errors?.[index]}
      onChange={onChange(index)}
      onCopyClick={onCopyClick(index)}
      onDeleteClick={onDeleteClick(index)}
    />
  );

  return (
    <VerticalContainer>
      {cellList.map(renderCellItemForm)}
      <Button
        label={t('machineControl.model.create.cells.cup.add.button.label')}
        iconLeft={IconAdd as any}
        view="ghost"
        onClick={onAddClick}
      />
    </VerticalContainer>
  );
};

export default ModelCupCellForm;
