import { ScheduleByForm } from '../../../../types/serverInterface/promoCodeDTO';
import { FieldError } from '../../../../types/types';
import { initialFieldError } from '../../../../helpers/validateHelpers';

/**
 * Ошибки формы расписания
 */
export type SchedulePromoCodeErrors = Record<keyof Omit<ScheduleByForm, 'id'>, FieldError>;

/**
 * Дефолтное значение ошибок формы расписания промокода
 */
export const schedulePromoCodeInitialError: SchedulePromoCodeErrors = {
  from: initialFieldError,
  to: initialFieldError,
  weekday: initialFieldError,
  name: initialFieldError,
};
