import styles from './ClassicTableColumns.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import { Column } from '../types';
import HorizontalContainer from '../../HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconFunnel } from '@consta/uikit/IconFunnel';
import SortButton from './SortButton';
import FilterButton from './FilterButton';
import { DisplayType, displayTypeValue } from '../../../pages/App';
import { SortDirection } from '../../../types/enums/sortDirection';

/**
 * Конвертация объекта в массив
 *
 * @param columns колонки таблицы
 */
function convertRecordToArray<T extends Record<string, any>, K extends keyof T>(
  columns: Record<K, Column<T, K>>,
) {
  const columnsList: Column<T, K>[] = [];
  let count = 0;

  for (const key in columns) {
    if (columns.hasOwnProperty(key)) {
      columnsList.push(columns[key]);
      count++;
    }
  }

  return { columnsList, count };
}

/**
 * Свойства компонента ClassicTableColumns
 */
type ClassicTableColumnsProps<T extends Record<string, any>, K extends keyof T> = {
  /**
   * Колонки
   */
  columns: Record<K, Column<T, K>>;
  /**
   * Использовать checkbox
   */
  withCheckbox?: boolean | undefined;
  /**
   * Состояние сортировки
   */
  sortOrder?: Record<K, SortDirection | null>;
  /**
   * Фильтры
   */
  filters?: Record<K, boolean>;
};

/**
 * Колонки таблицы
 */
const ClassicTableColumns = <T extends Record<string, any>, K extends keyof T>({
  columns,
  sortOrder,
  withCheckbox,
  filters,
}: ClassicTableColumnsProps<T, K>) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { columnsList } = convertRecordToArray(columns);

  const renderFilter = (column: Column<T, K>) => {
    if (column.withFilter) {
      return (
        <Button
          onlyIcon
          view="clear"
          iconLeft={IconFunnel as any}
          // onClick={handleSortClick(data.key)}
        />
      );
    }

    return null;
  };

  return (
    <tr className={classNames(styles.ClassicTableColumns, isLaptop && styles.laptop)}>
      {withCheckbox && <th className={classNames(styles.checkboxContainer, styles.cell)} />}
      {columnsList.map((data) => (
        <th
          key={data.key as string}
          className={classNames(
            styles.cell,
            data.alignment == 'left' && styles.alignLeft,
            data.alignment == 'right' && styles.alignRight,
            data.className,
          )}
        >
          <HorizontalContainer
            align="center"
            space="xs"
            justify={
              data.alignment == 'left' ? 'start' : data.alignment == 'right' ? 'end' : 'stretch'
            }
          >
            <Text size={isLaptop ? 's' : 'm'} weight="semibold">
              {data.title}
            </Text>
            <SortButton sortOrder={sortOrder?.[data.key as K]} column={data} />
            <FilterButton
              column={data}
              isActive={filters?.[data.key as K]}
              className={data.withFilter ? data.filterClassName : undefined}
            />
          </HorizontalContainer>
        </th>
      ))}
    </tr>
  );
};

export default ClassicTableColumns;
