import { FC, ReactNode } from 'react';
import './HorizontalContainer.scss';
import { Space } from '@consta/uikit/MixSpace';
import classNames from 'classnames';

/**
 * Свойства компонента HorizontalContainer
 */
type HorizontalContainerProps = {
  /**
   * Внешний класс
   */
  className?: string;
  /**
   * Дочерние элементы
   */
  children: ReactNode;
  /**
   * Отступ
   */
  space?: Space;
  /**
   * Выравнивание по горизонту
   */
  align?: 'stretch' | 'center' | 'start' | 'end';
  justify?: 'stretch' | 'center' | 'start' | 'end';
  /**
   * Аналог justify-content: space-between;
   */
  isAutoSpace?: boolean;
  onClick?: () => void;
};

/**
 * Горизонтальный контейнер
 */
const HorizontalContainer: FC<HorizontalContainerProps> = ({
  children,
  className,
  space = 'l',
  align = 'stretch',
  justify = 'stretch',
  isAutoSpace,
  onClick = () => {
    null;
  },
}) => {
  return (
    <div
      className={classNames(
        'HorizontalContainer',
        className,
        `gap_${space}`,
        `align_${align}`,
        `justify_${justify}`,
        isAutoSpace && 'gap_auto',
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default HorizontalContainer;
