import { FC } from 'react';
import {
  CustomFormItem,
  CustomFormItemType,
  CustomFormType,
} from '../../types/serverInterface/customForm';
import { TextField } from '@consta/uikit/TextField';
import { Switch } from '@consta/uikit/Switch';
import { getLocaleCustomFormField } from './helpers';
import { LanguageEnum } from '../../types/serverInterface/locales';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@consta/uikit/DatePicker';
import { Combobox } from '@consta/uikit/Combobox';
import styles from './CustomForm.module.scss';
import { withTooltip } from '@consta/uikit/withTooltip';

/**
 * Свойства компонента CustomForm
 */
type CustomFormProps = {
  /**
   * Кастомная форма
   */
  config: CustomFormType;
  /**
   * Обработчик изменения в кастомной форме
   *
   * @param index номер поля в кастомной форме
   * @param value изменённое значение поля
   */
  onChange: (index: number) => (value: string | Date | boolean | null) => void;
};

/**
 * Текстовое поле ввода с tooltip
 */
const TextFieldWithTooltip = withTooltip({ direction: 'downCenter' })(TextField);

/**
 * Поле ввода даты с tooltip
 */
const DatePickerWithTooltip = withTooltip({ direction: 'downCenter' })(DatePicker);

/**
 * Выпадающий список с tooltip
 */
const ComboboxWithTooltip = withTooltip({ direction: 'downCenter' })(Combobox);

/**
 * Переключатель с tooltip
 */
const SwitchWithTooltip = withTooltip({ direction: 'downCenter' })(Switch);

/**
 * Компонент заполнения полей кастомной формы
 */
const CustomForm: FC<CustomFormProps> = ({ config, onChange }) => {
  const { t } = useTranslation();

  // Обработчики
  const handleChange =
    (index: number) =>
    ({ value }: { value: string | Date | null }) => {
      onChange(index)(value);
    };

  const handleBooleanChange =
    (index: number) =>
    ({ checked }: { checked: boolean }) => {
      onChange(index)(checked);
    };

  // render методы
  const renderFormItem = (formItem: CustomFormItem, index: number) => {
    const formItemUnit = formItem.unit ? ` (${t(`productBase.units.${formItem.unit}`)})` : '';
    const localeFormItem = getLocaleCustomFormField(formItem)(LanguageEnum.ru);
    const formItemName = localeFormItem?.name || formItem.name;
    const formItemValue = formItem.value;
    const formItemDescription = localeFormItem?.description || formItem.description;

    switch (formItem.type) {
      case CustomFormItemType.STRING:
        return (
          <TextFieldWithTooltip
            tooltipProps={{
              content: formItemDescription,
            }}
            key={index}
            width="full"
            value={String(formItemValue)}
            label={formItemName}
            rightSide={formItemUnit}
            onChange={handleChange(index)}
          />
        );
      case CustomFormItemType.NUMBER:
        return (
          <TextFieldWithTooltip
            tooltipProps={{
              content: formItemDescription,
            }}
            key={index}
            type="number"
            width="full"
            incrementButtons={false}
            rightSide={formItemUnit}
            value={String(formItemValue)}
            label={formItemName}
            onChange={handleChange(index)}
          />
        );
      case CustomFormItemType.INTEGER:
        return (
          <TextFieldWithTooltip
            tooltipProps={{
              content: formItemDescription,
            }}
            key={index}
            type="number"
            width="full"
            incrementButtons={false}
            rightSide={formItemUnit}
            value={String(formItemValue)}
            label={formItemName}
            onChange={({ value }) => {
              if (Number.isInteger(Number(value))) {
                handleChange(index)({ value });
              }
            }}
          />
        );
      case CustomFormItemType.TIME:
        return (
          <DatePickerWithTooltip
            tooltipProps={{
              content: formItemDescription,
            }}
            className={styles.time}
            key={index}
            type="time"
            format="HH:mm"
            placeholder="ЧЧ:ММ"
            multiplicitySeconds={0}
            multiplicityMinutes={5}
            value={formItemValue as Date}
            label={formItemName}
            onChange={handleChange(index)}
          />
        );
      case CustomFormItemType.ARRAY:
        return (
          <ComboboxWithTooltip
            tooltipProps={{
              content: formItemDescription,
            }}
            key={index}
            value={formItem.items.find(({ key }) => key === formItem.value)}
            items={formItem.items}
            getItemLabel={({ label }) => label}
            getItemKey={({ key }) => key}
            onChange={({ value }) => {
              onChange(index)(value ? value.key : null);
            }}
          />
        );
      case CustomFormItemType.BOOLEAN:
        return (
          <SwitchWithTooltip
            tooltipProps={{
              content: formItemDescription,
            }}
            key={index}
            checked={!!formItemValue}
            label={formItemName}
            onChange={handleBooleanChange(index)}
          />
        );
    }
  };

  return <>{config.map(renderFormItem)}</>;
};

export default CustomForm;
