import { FC, useState } from 'react';
import styles from './Notification.module.scss';
import { Informer } from '@consta/uikit/Informer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '@consta/uikit/IconClose';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../../state/handlers';

/**
 * Свойства компонента Notification
 */
type NotificationProps = {
  /**
   * Уведомление
   */
  notification: NotificationType;
};

/**
 * Компонент уведомления
 */
const Notification: FC<NotificationProps> = ({ notification }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);

  const { header, description, status } = notification;

  // Обработчики
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Informer className={styles.notification} status={status}>
          <div className={styles.notification}>
            <div className={styles.content}>
              <Text size="xl">{t(header.key, { value: header.value })}</Text>
              <Text>{t(description.key, { value: description.value })}</Text>
            </div>
            <Button
              onlyIcon
              iconLeft={IconClose as any}
              view="clear"
              size="s"
              onClick={handleClose}
            />
          </div>
        </Informer>
      )}
    </>
  );
};

export default Notification;
