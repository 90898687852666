import { FC, useState } from 'react';
import { CreateWaterModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import { Collapse } from '@consta/uikit/Collapse';
import DefaultInfo from '../../../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента WaterCellModelInfo
 */
type WaterCellModelInfoProps = {
  /**
   * Ячейка
   */
  cell: CreateWaterModelCell;
};

/**
 * Информация о ячейке воды модели автомата
 */
const WaterCellModelInfo: FC<WaterCellModelInfoProps> = ({ cell }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleCollapseClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  switch (cell.isCount) {
    case true:
      return (
        <Collapse
          isOpen={isOpen}
          iconPosition="right"
          label={t('machineControl.model.info.cells.water.bottle.collapse.label', {
            cellNumber: cell.cellNumber,
          })}
          onClick={handleCollapseClick}
        >
          <DefaultInfo>
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.bottle.cellNumber.item.label'),
                value: String(cell.cellNumber),
              }}
            />
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.bottle.minVolume.item.label'),
                value: String(cell.minVolume),
              }}
            />
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.bottle.maxVolume.item.label'),
                value: String(cell.maxVolume),
              }}
            />
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.bottle.expirationTimer.item.label'),
                value: String(cell.expirationTimer),
              }}
            />
          </DefaultInfo>
        </Collapse>
      );
    case false:
      return (
        <Collapse
          isOpen={isOpen}
          iconPosition="right"
          label={t('machineControl.model.info.cells.water.tap.collapse.label', {
            cellNumber: cell.cellNumber,
          })}
          onClick={handleCollapseClick}
        >
          <DefaultInfo>
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.tap.cellNumber.item.label'),
                value: String(cell.cellNumber),
              }}
            />
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.tap.filterValue.item.label'),
                value: String(cell.filterValue),
              }}
            />
            <DefaultInfoItem
              item={{
                name: t('machineControl.model.info.cells.water.tap.expirationTimer.item.label'),
                value: String(cell.expirationTimer),
              }}
            />
          </DefaultInfo>
        </Collapse>
      );
  }
};

export default WaterCellModelInfo;
