import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import { selectPromoCodeGroupList, selectPromoCodeInfo } from '../../../state/promoCode/selectors';
import {
  editPromoCodeAction,
  getPromoCodeAction,
  getPromoCodeGroupListAction,
} from '../../../state/promoCode/actions';
import {
  BrandId,
  Discount,
  EditPromoCodeDTO,
  ProductByForm,
  ProductByInfo,
  PromoCodeRes,
  ScheduleByForm,
  TypeDiscountEnum,
  WeekdayEnum,
} from '../../../types/serverInterface/promoCodeDTO';
import HorizontalContainer from '../../../components/HorizontalContainer';
import styles from '../CreatePromoCode/PromoCodeForm.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../helpers/ inputHelpers';
import VerticalContainer from '../../../components/VerticalContainer';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Combobox } from '@consta/uikit/Combobox';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconRevert } from '@consta/uikit/IconRevert';
import { enumToArray } from '../../../types/enums';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../CreatePromoCode/CreatePromoCode';
import PromoCodeSchedulesForm from '../PromoCodeSchedulesForm';
import { transformDate } from '../../../helpers/transformDate';
import { MachineSmallListItemDTO } from '../../../types/serverInterface/machineDTO';
import { getMachineListByOrganizationIdAction } from '../../../state/machineControl/actions';
import { selectSmallMachineList } from '../../../state/machineControl/selectors';
import { useTranslation } from 'react-i18next';
import { editPromoCodeInitialErrors, getEditPromoCodeFieldError } from './helpers';
import ComboboxWithTooltip from '../../../components/withTooltip/Combobox';
import TextFieldWithTooltip from '../../../components/withTooltip/TextField';
import {
  initialFieldError,
  minMaxValidate,
  onlyEnglishLettersValidate,
  requiredValidate,
} from '../../../helpers/validateHelpers';
import DatePickerWithTooltip from '../../../components/withTooltip/DatePicker';
import PromoCodeProductForm from '../PromoCodeProductForm';
import { Switch } from '@consta/uikit/Switch';

const initialBrandsValue: BrandId[] = [
  {
    id: null,
    ingredientLines: null,
  },
];

/**
 * Дефотлное значение формы изменения промокода
 */
const editPromoCodeInitialValue: EditPromoCodeDTO = {
  code: null,
  description: null,
  qty: null,
  isUnlimitedTimeUse: false,
  isUnlimitedCountUse: false,
  discount: {
    type: TypeDiscountEnum.PERCENT,
    amount: null,
  },
  periodUse: {
    to: null,
    from: null,
  },
  groupId: null,
  machineIds: [],
  product: {
    brands: initialBrandsValue,
    categoryIds: [],
    viewIds: [],
  },
  schedules: [],
  isActive: true,
  isFullMachine: false,
};

/**
 * Трансформация из строки в дату
 *
 * @param dateString строка с датой
 */
function parseDate(dateString: string | null): Date | null {
  if (!dateString) return null;

  const parts = dateString.split('.');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
}

function transformProductByInfoToForm(products: ProductByInfo): ProductByForm {
  return {
    brands: products.brands?.map((brand) => ({
      id: brand.id,
      ingredientLines: brand.ingredientLines?.map((line) => ({
        id: line.id,
        ingredientIds: line.ingredientIds?.map((ingredient) => ingredient.id),
      })),
    })),
    categoryIds: products.categoryIds?.map((category) => category.id),
    viewIds: products.viewIds?.map((view) => view.id),
  };
}

/**
 * Трансформация информации в тип, с которым работает форма
 *
 * @param info данные информации с сервера
 */
const transformInfoFromEditDTO = (info: PromoCodeRes | null): EditPromoCodeDTO => {
  if (!info) return editPromoCodeInitialValue;

  return {
    ...info,
    isFullMachine: !info.machines?.length,
    machineIds: info.machines?.map(({ id }) => id) || [],
    periodUse: {
      ...info.periodUse,
      to: parseDate(info.periodUse.to),
      from: parseDate(info.periodUse.from),
    },
    groupId: info.group?.id,
    product: transformProductByInfoToForm(info.product),
    isUnlimitedTimeUse: !info.periodUse.to,
    isUnlimitedCountUse: !info.qty,
    schedules: info.schedules?.map((schedule) => ({
      id: schedule.id,
      name: schedule.name,
      from: new Date('1970-01-01T' + schedule.from + 'Z'),
      to: new Date('1970-01-01T' + schedule.to + 'Z'),
      weekday: {
        [WeekdayEnum.MO]: schedule.weekday?.includes(WeekdayEnum.MO),
        [WeekdayEnum.TU]: schedule.weekday?.includes(WeekdayEnum.TU),
        [WeekdayEnum.SA]: schedule.weekday?.includes(WeekdayEnum.SA),
        [WeekdayEnum.WE]: schedule.weekday?.includes(WeekdayEnum.WE),
        [WeekdayEnum.SU]: schedule.weekday?.includes(WeekdayEnum.SU),
        [WeekdayEnum.FR]: schedule.weekday?.includes(WeekdayEnum.FR),
        [WeekdayEnum.TH]: schedule.weekday?.includes(WeekdayEnum.TH),
      },
    })),
  };
};

/**
 * Список дней недели
 */
const weekDayList = enumToArray(WeekdayEnum);

/**
 * Форма изменения промокода
 */
const EditPromoCode: FC = () => {
  const dispatch = useAppDispatch();
  const { promoCodeId } = useParams<{ promoCodeId: string }>();
  const promoCodeIdFormatted = Number(promoCodeId);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const organizationId = getOrganizationId();

  const { state: promoCode } = useAppSelector(selectPromoCodeInfo());
  const { state: groupList } = useAppSelector(selectPromoCodeGroupList());
  const { state: machineList } = useAppSelector(selectSmallMachineList());

  const [formState, setFormState] = useState<EditPromoCodeDTO>(transformInfoFromEditDTO(promoCode));
  const [formErrors, setFormErrors] = useState(editPromoCodeInitialErrors);

  useEffect(() => {
    promoCode && setFormState(transformInfoFromEditDTO(promoCode));
    // promoCodeId && localStorage.setItem('promoCodeId', promoCodeId);
  }, [promoCode, promoCodeId]);

  useEffect(() => {
    promoCodeIdFormatted && dispatch(getPromoCodeAction(promoCodeIdFormatted));
  }, [dispatch, promoCodeIdFormatted]);

  useEffect(() => {
    organizationId && dispatch(getPromoCodeGroupListAction(organizationId));
    organizationId && dispatch(getMachineListByOrganizationIdAction(organizationId));
  }, [dispatch, organizationId]);

  // Вспомогательные методы
  const getMachinesValue = (arr: number[] | null): MachineSmallListItemDTO[] => {
    const array: MachineSmallListItemDTO[] = [];

    (arr || []).forEach((item) => {
      const machine = machineList?.find(({ id }) => id === item);

      machine && array.push(machine);
    });

    return array;
  };

  const validateForm = () => {
    let isValid = true;

    const setIsValid = (value: boolean) => {
      isValid = isValid ? value : isValid;
    };

    const {
      code,
      isFullMachine,
      machineIds,
      discount,
      isUnlimitedTimeUse,
      periodUse,
      qty,
      isUnlimitedCountUse,
    } = formState;
    const { amount, type } = discount;
    const minQty = 1;
    const maxQty = 100;
    const minQtyLetter = 3;
    const fullMaxCodeLength = 12;

    setFormErrors({
      ...editPromoCodeInitialErrors,
      code: requiredValidate(
        code,
        setIsValid,
        minMaxValidate(
          fullMaxCodeLength,
          minQtyLetter,
          code?.length || 0,
          setIsValid,
          onlyEnglishLettersValidate(code, setIsValid),
        ),
      ),
      machineIds: isFullMachine
        ? initialFieldError
        : requiredValidate((machineIds && machineIds.length) || null, setIsValid),
      type: requiredValidate(type, setIsValid),
      amount:
        type === TypeDiscountEnum.FREE
          ? initialFieldError
          : requiredValidate(
              amount,
              setIsValid,
              type === TypeDiscountEnum.PERCENT
                ? minMaxValidate(100, 0, amount || -1, setIsValid)
                : minMaxValidate(1000, 0, amount || -1, setIsValid),
            ),
      periodUse: isUnlimitedTimeUse
        ? initialFieldError
        : requiredValidate(periodUse.to, setIsValid),
      qty: isUnlimitedCountUse
        ? initialFieldError
        : requiredValidate(qty, setIsValid, minMaxValidate(maxQty, minQty, qty || 0, setIsValid)),

      // Служебные поля
      isUnlimitedTimeUse: initialFieldError,
      isUnlimitedCountUse: initialFieldError,
      isFullMachine: initialFieldError,
      // Не требуется проверка
      schedules: initialFieldError,
      description: initialFieldError,
      groupId: initialFieldError,
      product: initialFieldError,
      isActive: initialFieldError,
    });

    return { isValid };
  };

  // Обработчики
  const handleChange =
    (key: keyof EditPromoCodeDTO) =>
    ({ value }: { value: string | number | null }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleBooleanChange =
    (key: keyof EditPromoCodeDTO) =>
    ({ checked }: { checked: boolean }) => {
      setFormState((prevState) => ({ ...prevState, [key]: checked }));
    };

  const handleDiscountChange =
    (key: keyof Discount) =>
    ({ value }: { value: string | null }) => {
      setFormState((prevState) => {
        const type = (key === 'type' ? value : prevState.discount.type) as TypeDiscountEnum;
        const amount =
          key === 'amount' ? (value ? Number(value) : null) : prevState.discount.amount;

        const getNewAmount = () => {
          switch (type) {
            case TypeDiscountEnum.FREE:
              return 0;
            case TypeDiscountEnum.FIXED:
              return amount && (amount > 500 ? 500 : amount < 0 ? 0 : amount);
            case TypeDiscountEnum.PERCENT:
              return amount && (amount > 100 ? 100 : amount < 0 ? 0 : amount);
          }
        };

        return {
          ...prevState,
          discount: {
            ...prevState.discount,
            amount: getNewAmount(),
            type,
          },
        };
      });
    };

  const handlePeriodUseChange = ({ value }: { value: (Date | undefined)[] | null }) => {
    value &&
      setFormState((prevState) => ({
        ...prevState,
        periodUse: {
          to: value[1] || null,
          from: value[0] || null,
        },
      }));
  };

  const handleSubmit = () => {
    validateForm().isValid
      ? dispatch(
          editPromoCodeAction(
            {
              ...formState,
              code: formState.code?.toUpperCase() || '',
              periodUse: {
                ...formState.periodUse,
                to: formatDate(formState.periodUse.to),
                from: formatDate(formState.periodUse.from),
              },
              schedules: formState.schedules?.map((item) => ({
                ...item,
                to: transformDate(item.to),
                from: transformDate(item.from),
                weekday: weekDayList.filter((weekDay) => item.weekday[weekDay]),
              })),
              qty: formState.qty ? Number(formState.qty) : null,
              machineIds: formState.isFullMachine ? null : formState.machineIds,
              discount: {
                ...formState.discount,
                amount: formState.discount.amount ? Number(formState.discount.amount) : null,
              },
              product: {
                ...formState.product,
                brands: formState.product.brands.filter(({ id }) => id),
              },
            },
            promoCodeIdFormatted,
          ),
        ).then(() => navigate(`/promoCode/info/${promoCodeId}`))
      : document.getElementById('appContent')?.scrollTo(0, 0);
  };

  const handleSchedulesChange = (list: ScheduleByForm[]) => {
    setFormState((prevState) => ({ ...prevState, schedules: list }));
  };

  // render методы
  const renderMachine = () => (
    <VerticalContainer className={styles.card} space="2xl">
      <Text size="2xl">{t('promoCode.edit.titles.machinesSetting')}</Text>
      <Checkbox
        checked={formState.isFullMachine}
        label={t('promoCode.edit.isFullMachine.checkbox.label')}
        onChange={handleBooleanChange('isFullMachine')}
      />
      <VerticalContainer className={styles.secondaryCard} space="l">
        <HorizontalContainer className={styles.wrapper} space="auto">
          <Text size="l">{t('promoCode.edit.titles.machines')}</Text>
          <HorizontalContainer className={styles.fieldWrapper} space="m">
            <ComboboxWithTooltip
              tooltipProps={{
                content: t(
                  formErrors.machineIds.label || t('promoCode.edit.machines.tooltip.text'),
                ),
              }}
              status={formErrors.machineIds.status}
              placeholder={t('promoCode.edit.machines.combobox.placeholder')}
              disabled={formState.isFullMachine}
              value={getMachinesValue(formState.machineIds)}
              items={machineList || []}
              getItemLabel={({ name }) => name}
              getItemKey={({ id }) => id}
              multiple
              onChange={({ value }) => {
                const arr = (value || []).map((item) => item.id);

                setFormState((prevState) => ({ ...prevState, machineIds: arr }));
              }}
            />
          </HorizontalContainer>
        </HorizontalContainer>
      </VerticalContainer>
    </VerticalContainer>
  );

  const renderBaseInfo = () => {
    const codeError = getEditPromoCodeFieldError(formErrors)('code');
    const typeError = getEditPromoCodeFieldError(formErrors)('type');
    const amountError = getEditPromoCodeFieldError(formErrors)('amount');
    const periodUseError = getEditPromoCodeFieldError(formErrors)('periodUse');
    const qtyError = getEditPromoCodeFieldError(formErrors)('qty');

    return (
      <VerticalContainer className={styles.card} space="3xl">
        <Text size="2xl">{t('promoCode.edit.titles.base')}</Text>
        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.edit.titles.promoCode')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <HorizontalContainer space="s">
                <TextFieldWithTooltip
                  tooltipProps={{
                    content: t(codeError.label || t('promoCode.edit.code.tooltip.text')),
                  }}
                  status={codeError.status}
                  placeholder={t('promoCode.edit.code.textField.placeholder')}
                  value={formState.code?.toUpperCase()}
                  width="full"
                  onChange={handleChange('code')}
                />
                <Button onlyIcon iconLeft={IconRevert as any} view="ghost" />
              </HorizontalContainer>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.edit.titles.discount')}</Text>
            <HorizontalContainer className={styles.fieldWrapper} space="m">
              <ComboboxWithTooltip
                tooltipProps={{
                  content: typeError.label
                    ? t(typeError.label, typeError.optionsObj)
                    : t('promoCode.edit.discountType.tooltip.text'),
                }}
                status={typeError.status}
                placeholder={t('promoCode.edit.discountType.combobox.placeholder')}
                items={enumToArray(TypeDiscountEnum) as string[]}
                value={formState.discount.type}
                getItemLabel={(item) => t(`promoCode.amountType.${item}`)}
                getItemKey={(item) => item}
                onChange={handleDiscountChange('type')}
              />
              <TextFieldWithTooltip
                tooltipProps={{
                  content: amountError.label
                    ? t(amountError.label, amountError.optionsObj)
                    : t('promoCode.edit.discountAmount.tooltip.text'),
                }}
                status={amountError.status}
                placeholder={t('promoCode.edit.discountAmount.textField.placeholder')}
                disabled={
                  formState.discount.type === TypeDiscountEnum.FREE || !formState.discount.type
                }
                rightSide={
                  formState.discount.type
                    ? t(
                        `promoCode.edit.discountAmount.textField.rightSide.${formState.discount.type}`,
                      )
                    : ''
                }
                value={
                  formState.discount.type === TypeDiscountEnum.FREE || !formState.discount.type
                    ? null
                    : getInputNumberValue(formState.discount.amount)
                }
                onChange={handleDiscountChange('amount')}
              />
            </HorizontalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.edit.titles.periodUse')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <DatePickerWithTooltip
                tooltipProps={{
                  content: periodUseError.label
                    ? t(periodUseError.label, periodUseError.optionsObj)
                    : t('promoCode.edit.periodUse.tooltip.text'),
                }}
                status={periodUseError.status}
                type="date-range"
                disabled={formState.isUnlimitedTimeUse}
                multiplicitySeconds={0}
                multiplicityMinutes={5}
                value={[formState.periodUse.from || undefined, formState.periodUse.to || undefined]}
                onChange={handlePeriodUseChange}
              />
              <Checkbox
                checked={formState.isUnlimitedTimeUse}
                label={t('promoCode.edit.isUnlimitedTimeUse.checkbox.label')}
                onChange={handleBooleanChange('isUnlimitedTimeUse')}
              />
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.edit.titles.qty')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <TextFieldWithTooltip
                tooltipProps={{
                  content: qtyError.label
                    ? t(qtyError.label, qtyError.optionsObj)
                    : t('promoCode.edit.qty.tooltip.text'),
                }}
                status={qtyError.status}
                placeholder={t('promoCode.edit.qty.textField.placeholder')}
                value={getInputNumberValue(formState.qty)}
                disabled={formState.isUnlimitedCountUse}
                type="number"
                incrementButtons={false}
                onChange={handleChange('qty')}
              />
              <Checkbox
                checked={formState.isUnlimitedCountUse}
                label={t('promoCode.edit.isUnlimitedCountUse.checkbox.label')}
                onChange={handleBooleanChange('isUnlimitedCountUse')}
              />
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">Статус</Text>
            <HorizontalContainer className={styles.fieldWrapper}>
              <Switch
                checked={formState?.isActive}
                label={formState?.isActive ? 'Вкл' : 'Выкл'}
                onChange={handleBooleanChange('isActive')}
              />
            </HorizontalContainer>
          </HorizontalContainer>
        </VerticalContainer>

        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.edit.titles.group')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <Combobox
                items={groupList || []}
                value={groupList?.find(({ id }) => id === formState.groupId)}
                placeholder={t('promoCode.edit.group.textField.placeholder')}
                getItemLabel={({ name }) => name}
                getItemKey={({ id }) => id}
                onChange={({ value }) =>
                  handleChange('groupId')({ value: value ? value.id || null : null })
                }
                onCreate={() => {
                  null;
                }}
              />
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>

        <VerticalContainer className={styles.secondaryCard} space="l">
          <HorizontalContainer className={styles.wrapper} space="auto">
            <Text size="l">{t('promoCode.edit.titles.description')}</Text>
            <VerticalContainer space="3xs" className={styles.fieldWrapper}>
              <TextField
                placeholder={t('promoCode.edit.description.textField.placeholder')}
                value={formState.description}
                type="textarea"
                minRows={5}
                onChange={handleChange('description')}
              />
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </VerticalContainer>
    );
  };

  const renderActions = () => (
    <HorizontalContainer justify="end">
      <Button
        label={t('promoCode.edit.actions.back')}
        view="clear"
        onClick={() => navigate(`/promoCode/info/${promoCodeId}`)}
      />
      <Button label={t('promoCode.edit.actions.save')} onClick={handleSubmit} />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer className={styles.PromoCodeForm} space="5xl">
      {renderBaseInfo()}
      {/*{renderSchedules()}*/}
      {renderMachine()}
      {/*{renderProductList()}*/}
      <PromoCodeSchedulesForm
        list={formState.schedules}
        onSchedulesChange={handleSchedulesChange}
      />
      <PromoCodeProductForm
        product={formState.product}
        onCellCategoryChange={(ids) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              categoryIds: ids,
              brands: initialBrandsValue,
            },
          }));
        }}
        onCellViewChange={(ids) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              viewIds: ids,
              brands: initialBrandsValue,
            },
          }));
        }}
        onBrandChange={(index) => (brandId: number | null) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: [
                ...prevState.product.brands.slice(0, index),
                {
                  id: brandId,
                  ingredientLines: null,
                },
                ...prevState.product.brands.slice(index + 1),
              ],
            },
          }));
        }}
        onIngredientLineChange={(index) => (lineIds) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: prevState.product.brands.map((brand, idx) => {
                if (idx === index) {
                  return {
                    ...brand,
                    ingredientLines: lineIds
                      ? lineIds.map((id) => ({ id, ingredientIds: null }))
                      : null,
                  };
                }
                return brand;
              }),
            },
          }));
        }}
        onIngredientChange={(index) => (ingredients) => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: prevState.product.brands.map((brand, idx) => {
                if (idx === index) {
                  return {
                    ...brand,
                    ingredientLines: ingredients,
                  };
                }
                return brand;
              }),
            },
          }));
        }}
        onDeleteClick={(index) => () => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: prevState.product.brands.filter((_, idx) => idx !== index),
            },
          }));
        }}
        onAddClick={() => {
          setFormState((prevState) => ({
            ...prevState,
            product: {
              ...prevState.product,
              brands: [
                ...prevState.product.brands,
                {
                  id: null,
                  ingredientLines: null,
                },
              ],
            },
          }));
        }}
      />
      {renderActions()}
    </VerticalContainer>
  );
};

export default EditPromoCode;
