import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectCellCategoryLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getCellCategoryLocaleByIdAction,
} from '../../../../state/productBase/actions';
import { useTranslation } from 'react-i18next';
import { LocaleName } from '../../../../components/locale/NameLocale/types';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { Loader } from '@consta/uikit/Loader';
import NameLocale from '../../../../components/locale/NameLocale';

/**
 * Свойства компонента CellCategoryLocale
 */
type CellCategoryLocaleProps = {
  /**
   * Флаг открытия
   */
  isOpen: boolean;
  /**
   * id категории контейнера
   */
  cellCategoryId: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Страница локализации категории контейнера
 */
const CellCategoryLocale: FC<CellCategoryLocaleProps> = ({ isOpen, cellCategoryId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectCellCategoryLocale());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getCellCategoryLocaleByIdAction(cellCategoryId));
  }, [dispatch, cellCategoryId]);

  const handleSubmit = (data: LocaleName[]) => {
    locale &&
      dispatch(
        editLocaleAction({
          ...locale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() =>
        dispatch(getCellCategoryLocaleByIdAction(cellCategoryId)).then(() => {
          handleEditClose();
        }),
      );
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleClose = onClose;

  if (!locale) return <Loader />;

  return (
    <NameLocale
      isOpen={isOpen}
      isEdit={isEdit}
      locale={locale}
      modalTitle={t('productBaseConfig.cellCategory.locale.modal.title')}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export default CellCategoryLocale;
