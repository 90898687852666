import { AppDispatch } from '../../app/store';
import {
  createMachineModelThunk,
  // createMachineThunk,
  createOutletThunk,
  deleteMachineModelThunk,
  deleteMachineThunk,
  deleteOutletThunk,
  disconnectMachineThunk,
  editMachineBaseInfoThunk,
  editMachinePricesThunk,
  editMachineStorageThunk,
  editOutletThunk,
  // editMachineThunk,
  editWaterCellThunk,
  getIngredientCalibrationValueThunk,
  getMachineBaseInfoThunk,
  // getMachineFillingThunk,
  getMachineHomePageInfoThunk,
  getMachineListByOrganizationIdThunk,
  getMachineListWithFiltersThunk,
  // getMachineListThunk,
  getMachineModelDetailsThunk,
  getMachineModelListThunk,
  getMachinePricesInfoThunk,
  getMachineRegistrationCodeThunk,
  getMachineSaLesListByPrintThunk,
  // getMachinePricesThunk,
  getMachineSaLesListQtyThunk,
  getMachineSaLesListThunk,
  getMachineSettingsActionsStatusThunk,
  getMachineSettingsThunk,
  getMachineStorageInfoThunk,
  getOutletListFiltersThunk,
  getOutletListThunk,
  getOutletMachineIdsThunk,
  // getMachineThunk,
  refillMachineStorageThunk,
  // refillMachineThunk,
  regenerateMachineRegistrationCodeThunk,
  sendMachineCustomActionThunk,
  toggleActiveMachineRegistrationCodeThunk,
} from './thunk';
import {
  // CreateMachineDTO,
  EditMachineBaseInfoDTO,
  // EditMachineDTO,
  EditMachinePricesDTO,
  EditMachineStorageDTO,
  EditWaterCell,
  MachineStorageInfoFormatted,
  // RefillCellDTO,
  RefillMachineStorageDTO,
  SendMachineCustomActionDTO,
} from '../../types/serverInterface/machineDTO';
import { CreateMachineModelDTO } from '../../types/serverInterface/machineModelDTO';
import { editNewStorage } from './slice';
import {
  MachineFiltersDTO,
  ShutdownReasonFilters,
} from '../../types/serverInterface/machineListDTO';
import {
  CreateOutletDTO,
  EditOutletDTO,
  OutletFiltersDTO,
} from '../../types/serverInterface/outletDTO';

/**
 * Получение списка моделей автомата
 */
export const getMachineModelListAction = () => (dispatch: AppDispatch) =>
  dispatch(getMachineModelListThunk());

/**
 * Получение детальной информации о автомате
 *
 * @param modelId {number} id модели автомата
 */
export const getMachineModelDetailsAction = (modelId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachineModelDetailsThunk(modelId));

/**
 * Создание модели автомата
 *
 * @param {CreateMachineModelDTO} data данные формы создания модели автомата
 */
export const createMachineModelAction = (data: CreateMachineModelDTO) => (dispatch: AppDispatch) =>
  dispatch(createMachineModelThunk(data)).then(() => dispatch(getMachineModelListAction()));

/**
 * Удаление модели автомата
 *
 * @param {number} modelId id модели автомата
 */
export const deleteMachineModelAction = (modelId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteMachineModelThunk(modelId)).then(() => dispatch(getMachineModelListAction()));

/**
 * Удаление автомата
 *
 * @param machineId id автомата
 */
export const deleteMachineAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteMachineThunk(machineId));

// /**
//  * Получения списка автоматов организации
//  *
//  * @param organizationId id организации
//  */
// export const getMachineListAction = (organizationId: number | null) => (dispatch: AppDispatch) =>
//   dispatch(getMachineListThunk(organizationId));

/**
 * Получения списка автоматов организации
 *
 * @param organizationId id организации
 */
export const getMachineListByOrganizationIdAction =
  (organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineListByOrganizationIdThunk(organizationId));

// /**
//  * Получение детальной информации о автомате
//  *
//  * @param machineId id автомата
//  */
// export const getMachineAction = (machineId: number) => (dispatch: AppDispatch) =>
//   dispatch(getMachineThunk(machineId));
//
// /**
//  * Получение информации о складе автомата
//  *
//  * @param machineId
//  */
// export const getMachineFillingAction = (machineId: number) => (dispatch: AppDispatch) =>
//   dispatch(getMachineFillingThunk(machineId));
//
// /**
//  * Получение информации о товарах автомата
//  *
//  * @param machineId id автомата
//  */
// export const getMachinePricesAction = (machineId: number) => (dispatch: AppDispatch) =>
//   dispatch(getMachinePricesThunk(machineId));

// /**
//  * Пополнение остатков автомата
//  *
//  * @param storage обновленные остатки склада автомата
//  */
// export const refillMachineAction = (storage: RefillCellDTO[]) => (dispatch: AppDispatch) =>
//   dispatch(refillMachineThunk(storage));
//
// /**
//  * Изменение автоматаа
//  *
//  * @param data данные формы изменения автомата
//  * @param machineId id автомата
//  */
// export const editMachineAction =
//   (data: EditMachineDTO, machineId: number) => (dispatch: AppDispatch) =>
//     dispatch(editMachineThunk({ data, machineId }));

// /**
//  * Создание автомата
//  *
//  * @param data данные формы создания автомата
//  */
// export const createMachineAction = (data: CreateMachineDTO) => (dispatch: AppDispatch) =>
//   dispatch(createMachineThunk(data));

/**
 * Получение базовой информации о автомате
 *
 * @param machineId id автомата
 */
export const getMachineBaseInfoAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachineBaseInfoThunk(machineId));

/**
 * Получение информации о автомате для домашней страницы
 *
 * @param machineId id автомата
 */
export const getMachineHomePageInfoAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachineHomePageInfoThunk(machineId));

/**
 * Получение информации о складе автомата
 *
 * @param machineId id автомата
 */
export const getMachineStorageInfoAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachineStorageInfoThunk(machineId));

/**
 * Получение информации о ценах автомата
 *
 * @param machineId id автомата
 */
export const getMachinePricesInfoAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachinePricesInfoThunk(machineId));

/**
 * Пополнение остатков автомата
 *
 * @param refillStorage пополненый склад
 * @param machineId id  автомата
 */
export const refillMachineStorageAction =
  (refillStorage: RefillMachineStorageDTO, machineId: number) => (dispatch: AppDispatch) =>
    dispatch(refillMachineStorageThunk({ refillStorage, machineId }));

/**
 * Изменение цен на автомате
 *
 * @param machineId id  автомата
 * @param prices цены и объёмы автомата
 */
export const editMachinePricesAction =
  (machineId: number, prices: EditMachinePricesDTO) => (dispatch: AppDispatch) =>
    dispatch(editMachinePricesThunk({ machineId, prices })).then(() => {
      dispatch(getMachinePricesInfoAction(machineId));
    });

/**
 * Изменение наполнения автомата
 *
 * @param machineId id автомата
 * @param storage обновлённый склад автомата + цены
 */
export const editMachineStorageAction =
  (machineId: number, storage: EditMachineStorageDTO) => (dispatch: AppDispatch) =>
    dispatch(editMachineStorageThunk({ machineId, storage })).unwrap();

/**
 * Сохранение обновлённого склада автомата
 *
 * @param storage обновлённый склад
 */
export const editNewMachineStorage =
  (storage: MachineStorageInfoFormatted | null) => (dispatch: AppDispatch) =>
    dispatch(editNewStorage(storage));

/**
 * Изменение базовой информации автомата
 *
 * @param machineId id автомата
 * @param baseInfo обновлённая базовая информация
 */
export const editMachineBaseInfoAction =
  (machineId: number, baseInfo: EditMachineBaseInfoDTO) => (dispatch: AppDispatch) =>
    dispatch(editMachineBaseInfoThunk({ machineId, baseInfo })).then(() => {
      dispatch(getMachineBaseInfoAction(machineId));
      dispatch(getMachineHomePageInfoAction(machineId));
    });

/**
 * Список продаж автомата
 *
 * @param serialNumber серийный номер автомата
 * @param page страница
 * @param limit элементов наа странице
 */
export const getMachineSaLesListAction =
  (serialNumber: string, page: number, limit: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineSaLesListThunk({ serialNumber, limit, page }));

/**
 * Список продаж автомата
 *
 * @param serialNumber серийный номер автомата
 * @param page страница
 * @param limit элементов наа странице
 */
export const getMachineSaLesListByPrintAction =
  (serialNumber: string, page: number, limit: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineSaLesListByPrintThunk({ serialNumber, limit, page })).unwrap();

/**
 * Количество элементов продаж
 *
 * @param serialNumber серийный номер автомата
 */
export const getMachineSaLesListQtyAction = (serialNumber: string) => (dispatch: AppDispatch) =>
  dispatch(getMachineSaLesListQtyThunk(serialNumber)).unwrap();

/**
 * Получение данных о статусах кастомных действий
 *
 * @param machineId id автомата
 */
export const getMachineSettingsActionsStatusAction =
  (machineId: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineSettingsActionsStatusThunk(machineId));

/**
 * Настройки автомата
 *
 * @param machineId id автомата
 */
export const getMachineSettingsAction = (machineId: number) => (dispatch: AppDispatch) =>
  dispatch(getMachineSettingsThunk(machineId));

/**
 * Отправка кастомных действий на автомат
 *
 * @param data данные для отправки
 */
export const sendMachineCustomActionAction =
  (data: SendMachineCustomActionDTO) => (dispatch: AppDispatch) =>
    dispatch(sendMachineCustomActionThunk(data));

/**
 * Изменение ячейки воды
 *
 * @param data данные формы изменения настроек воды
 * @param machineId id автомата
 */
export const editWaterCellAction =
  (data: EditWaterCell, machineId: number) => (dispatch: AppDispatch) =>
    dispatch(editWaterCellThunk({ data, machineId })).then(() => {
      dispatch(getMachineSettingsAction(machineId));
    });

/**
 * Перегенерация ключа регистрации автомата
 *
 * @param keyCode старый ключ регистрации автомата
 * @param organizationId id организации
 */
export const regenerateMachineRegistrationCodeAction =
  (keyCode: string, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(regenerateMachineRegistrationCodeThunk(keyCode)).then(() => {
      dispatch(getMachineRegistrationCodeAction(organizationId));
    });

/**
 * Переключение активности ключа регистрации автомата
 *
 * @param keyCode код регистрации автомата
 * @param organizationId id организации
 */
export const toggleActiveMachineRegistrationCodeAction =
  (keyCode: string, organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(toggleActiveMachineRegistrationCodeThunk(keyCode)).then(() => {
      dispatch(getMachineRegistrationCodeAction(organizationId));
    });

/**
 * Получение или генерация ключа регистрации автомата
 *
 * @param organizationId id организации
 */
export const getMachineRegistrationCodeAction =
  (organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineRegistrationCodeThunk(organizationId));

/**
 * Получение калибровки ингредиента
 *
 * @param machineId id автомата
 * @param ingredientId id ингредиента
 * @param cellCategoryName название категории контейнера
 */
export const getIngredientCalibrationValueAction =
  (machineId: number, ingredientId: number, cellCategoryName: string) => (dispatch: AppDispatch) =>
    dispatch(
      getIngredientCalibrationValueThunk({ machineId, ingredientId, cellCategoryName }),
    ).then((res) => ({
      ...res,
    }));

/**
 * Получение списка автоматов
 *
 * @param filters фильтры списка автоматов
 */
export const getMachineListWithFiltersAction =
  (filters: MachineFiltersDTO) => (dispatch: AppDispatch) =>
    dispatch(getMachineListWithFiltersThunk(filters));

/**
 * Отключение автомата от телеметрии
 *
 * @param machineId id автомата
 * @param shutdownReason причина отключения автомата
 * @param isDropStock сбросить остатки автомата
 */
export const disconnectMachineAction =
  (machineId: number, shutdownReason: ShutdownReasonFilters, isDropStock: boolean) =>
  (dispatch: AppDispatch) =>
    dispatch(disconnectMachineThunk({ machineId, shutdownReason, isDropStock }));

/**
 * Удаление торговой точки
 *
 * @param outletId id торговой точки
 * @param organizationId id организации
 * @param filters фильтра
 */
export const deleteOutletAction =
  (outletId: number, organizationId: number, filters: OutletFiltersDTO) =>
  (dispatch: AppDispatch) =>
    dispatch(deleteOutletThunk(outletId)).then(() => {
      dispatch(getOutletListAction(organizationId, filters));
      dispatch(getOutletListFiltersAction(organizationId));
    });

/**
 * Получение списка торговых точек
 *
 * @param organizationId id организации
 * @param filters фильтра списка торговых точек
 */
export const getOutletListAction =
  (organizationId: number, filters: OutletFiltersDTO) => (dispatch: AppDispatch) =>
    dispatch(getOutletListThunk({ organizationId, filters }));

/**
 * Получение фильтра торговой точки
 *
 * @param organizationId id организации
 */
export const getOutletListFiltersAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getOutletListFiltersThunk(organizationId));

/**
 * Изменение торговой точки
 *
 * @param data данные формы изменения торговой точки
 * @param outletId id торговой точки
 * @param organizationId id организации
 * @param filters фильтра
 */
export const editOutletAction =
  (data: EditOutletDTO, outletId: number, organizationId: number, filters: OutletFiltersDTO) =>
  (dispatch: AppDispatch) =>
    dispatch(editOutletThunk({ outletId, data })).then(() => {
      dispatch(getOutletListAction(organizationId, filters));
      dispatch(getOutletListFiltersAction(organizationId));
    });

/**
 * Создание торговой точки
 *
 * @param data данные формы создания торговой точки
 * @param organizationId id организации
 * @param filters фильтра
 */
export const createOutletAction =
  (data: CreateOutletDTO, organizationId: number, filters: OutletFiltersDTO) =>
  (dispatch: AppDispatch) =>
    dispatch(createOutletThunk(data)).then(() => {
      dispatch(getOutletListAction(organizationId, filters));
      dispatch(getOutletListFiltersAction(organizationId));
    });

/**
 * Получение списка id автоматов торговой точки
 *
 * @param outletId id торговой точки
 */
export const getOutletMachineIdsAction = (outletId: number) => (dispatch: AppDispatch) =>
  dispatch(getOutletMachineIdsThunk(outletId));
