import { FC } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import ContentCard from '../../../../../components/ContentCard';
import React from 'react';
import MachineSettingsGroupAction from './MachineSettingsGroupAction';
import styles from './MachineSettingsActions.module.scss';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { ActionGroup, MachineGeneralInfo } from '../../../../../types/serverInterface/machineDTO';
import { useAppDispatch } from '../../../../../app/hooks/store';
import { sendMachineCustomActionAction } from '../../../../../state/machineControl/actions';

/**
 * Свойства компонента MachineSettingsActions
 */
export type MachineSettingsActionsProps = {
  actions: ActionGroup[];
  machine: MachineGeneralInfo;
  machineId: number;
};

const getMachineLogoFileName = (modelName: string) => {
  switch (modelName) {
    case 'ShakerTouch':
      return '/img/ShakerTouch.png';
    default:
      return '/img/milk.png';
  }
};

/**
 * Кастомные действия автомата
 */
const MachineSettingsActions: FC<MachineSettingsActionsProps> = ({
  machine,
  actions,
  machineId,
}) => {
  const dispatch = useAppDispatch();

  const { serialNumber, modelName } = machine;

  const logoImagePath = process.env.PUBLIC_URL + getMachineLogoFileName('ShakerTouch');

  // Обработчики
  const handleActionClick = (code: string) => {
    dispatch(sendMachineCustomActionAction({ machineId, code }));
  };

  // render методы
  const renderCustomActionGroup = (group: ActionGroup, index: number) => {
    return (
      <MachineSettingsGroupAction key={index} group={group} onActionClick={handleActionClick} />
    );
  };

  const renderCustomActions = () => (
    <HorizontalContainer space="2xs">{actions?.map(renderCustomActionGroup)}</HorizontalContainer>
  );

  return (
    <ContentCard className={styles.MachineSettingsActions}>
      <HorizontalContainer space="2xl">
        <div className={styles.machineImgWrapper}>
          <img src={logoImagePath} alt="machine-logo" />
        </div>
        <VerticalContainer space="2xs" justify="center">
          <Text size="l" weight="semibold">
            {modelName} {serialNumber}
          </Text>
          {/*<Text size="m" weight="regular">*/}
          {/*  Статус: Работает*/}
          {/*</Text>*/}
          {/*<Checkbox checked={false} label="Уведомлять об изменении статуса" />*/}
        </VerticalContainer>
      </HorizontalContainer>
      {renderCustomActions()}
    </ContentCard>
  );
};

export default MachineSettingsActions;
