import React, { FC, useMemo } from 'react';
import TabsBadge from '../../../../components/TabsBadge';
import { TabProps } from '../../../../components/TabsBadge/TabBadge/TabBadge';
import { useTranslation } from 'react-i18next';
import { isLaptop } from '../../../App';
import { ArchivedCount } from '../../../../types/serverInterface/machineListDTO';

/**
 * Свойства компонента MachineArchivedTabs
 */
type MachineArchivedTabsProps = {
  /**
   * Фильтр архивности автомата
   */
  isArchived: boolean | null;
  /**
   * Количество включённых/выключенный автоматов
   */
  archivedCount?: ArchivedCount;
  /**
   * обработчик переключения фильтра архивности автомата
   *
   * @param isArchived обновлённый фильтр архивности автомата
   */
  onArchiverChange: (isArchived: boolean) => () => void;
};

/**
 * Табы переключения архивного и не архивного автомата
 */
const MachineArchivedTabs: FC<MachineArchivedTabsProps> = ({
  isArchived,
  archivedCount,
  onArchiverChange,
}) => {
  const { t } = useTranslation();

  const tabsList = useMemo(
    (): TabProps[] => [
      {
        label: 'Активные',
        isSelect: !isArchived,
        value: archivedCount?.active,
        onClick: onArchiverChange(false),
      },
      {
        label: 'Отключенные',
        isSelect: !!isArchived,
        value: archivedCount?.archived,
        onClick: onArchiverChange(true),
      },
    ],
    [isArchived, onArchiverChange],
  );

  return <TabsBadge tabsList={tabsList} size={isLaptop ? 's' : 'm'} />;
};

export default MachineArchivedTabs;
