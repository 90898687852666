import { FC } from 'react';
import { HistoryEmployeeWithKeyElementDTO } from '../../../../../types/serverInterface/employeeWithKeyElementDTO';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MemberKeyHistory.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { DisplayType, displayTypeValue } from '../../../../App';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';

type MemberKeyHistoryProps = {
  histories: HistoryEmployeeWithKeyElementDTO[];
};

const MemberKeyHistory: FC<MemberKeyHistoryProps> = ({ histories }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  // render методы
  const renderHistoryItem = (historyItem: HistoryEmployeeWithKeyElementDTO) => {
    const { description, date: historyDate, name } = historyItem;
    const [date, time] = (historyDate || ' ').split(' ');

    return (
      <HorizontalContainer className={styles.historyItemCard} isAutoSpace align="center">
        <HorizontalContainer space={isLaptop ? 's' : 'm'} align="center">
          <VerticalContainer space="3xs">
            <Text className={styles.text} size={isLaptop ? 'm' : 'l'} weight="semibold">
              {name}
            </Text>
            <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
              {description}
            </Text>
          </VerticalContainer>
        </HorizontalContainer>
        <VerticalContainer align="end" space="2xs">
          <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
            {date}
          </Text>
          <Text className={styles.text} size={isLaptop ? 's' : 'm'} view="secondary">
            {time}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
    );
  };

  return (
    <ContentCard className={styles.MemberKeyHistory}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        История
      </Text>
      {histories.map(renderHistoryItem)}
    </ContentCard>
  );
};

export default MemberKeyHistory;
