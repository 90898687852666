import { InformerPropStatus } from '@consta/uikit/__internal__/src/components/Informer/Informer';
import { sendRequestTelegram } from '../helpers/sendTelegramText';

/**
 * Тип поля уведомления
 */
export type NotificationField = {
  /**
   * Ключ локализации поля
   */
  key: string;
  /**
   * Дополнительное значения
   */
  value?: string;
};

/**
 * Тип уведомления
 */
export type NotificationType = {
  /**
   * Заголовок
   */
  header: NotificationField;
  /**
   * Описание
   */
  description: NotificationField;
  /**
   * Статус
   */
  status: InformerPropStatus;
};

/**
 * Обработчик ошибок
 *
 * @param res ошибка
 */
export const errorHandler =
  (res: { error: { code?: string; message?: string } }) =>
  (addNotification: (notification: NotificationType) => void) => {
    sendRequestTelegram(res);
    addNotification({
      header: { key: 'error.server.header', value: res.error?.code },
      description: { key: 'error.server.description', value: res.error?.message },
      status: 'alert',
    });
  };
