import { FC } from 'react';
import TabBadge, { TabProps } from './TabBadge/TabBadge';
import HorizontalContainer from '../HorizontalContainer';

/**
 * Свойства компонента TabsBadge
 */
type TabsBadgeProps = {
  /**
   * Размер таба
   */
  size: 'm' | 's' | 'xs';
  /**
   * Заблокировано
   */
  disabled?: boolean;
  /**
   * Список табов
   */
  tabsList: TabProps[];
};

/**
 * Компонент табов
 */
const TabsBadge: FC<TabsBadgeProps> = ({ size, disabled = false, tabsList }) => {
  return (
    <HorizontalContainer space={size === 'xs' ? 'xs' : 's'}>
      {tabsList.map((tab, index) => (
        <TabBadge key={tab.label || index} disabled={disabled} size={size} {...tab} />
      ))}
    </HorizontalContainer>
  );
};

export default TabsBadge;
