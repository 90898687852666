import { FC, useMemo } from 'react';
import { CreateProductModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import VerticalContainer from '../../../../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import {
  ProductGroup,
  productGroupList,
  ProductView,
  productViewList,
} from '../../../../../../../types/serverInterface/storageDTO';
import styles from '../../CreateModelCellsForm.module.scss';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import TextFieldWithTooltip from '../../../../../../../components/withTooltip/TextField';
import ComboboxWithTooltip from '../../../../../../../components/withTooltip/Combobox';
import { useAppSelector } from '../../../../../../../app/hooks/store';
import {
  selectCellCategoryList,
  selectCellPurposeList,
} from '../../../../../../../state/productBase/selectors';
import { CellCategoryDTO } from '../../../../../../../types/serverInterface/cellCategoryDTO';
import { CellPurposeDTO } from '../../../../../../../types/serverInterface/cellPurpose';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconCopy } from '@consta/uikit/IconCopy';
import { ProductCellsFieldError } from '../../../types';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ModelProductCellFormItem
 */
type ModelProductCellFormItemProps = {
  /**
   * Ячейка
   */
  cell: CreateProductModelCell;
  /**
   * Ошибки
   */
  error: ProductCellsFieldError | undefined;
  /**
   * Обработчик изменения ячеек продуктов модели автомата
   *
   * @param key ключ поля в форме ячейки продукта
   * @param value новое значение поля
   */
  onChange: (key: keyof CreateProductModelCell) => (value: string | number | null) => void;
  /**
   * Обработчик удаления ячейки проудкта
   */
  onDeleteClick: () => void;
  /**
   * Обработчик копирования ячейки продукта
   */
  onCopyClick: () => void;
};

/**
 * Элемент формы изменения ячеек продукта
 */
const ModelProductCellFormItem: FC<ModelProductCellFormItemProps> = ({
  cell,
  error,
  onChange,
  onDeleteClick,
  onCopyClick,
}) => {
  const { t } = useTranslation();

  const cellCategoryList = useAppSelector(selectCellCategoryList());
  const cellPurposeList = useAppSelector(selectCellPurposeList());
  const {
    cellNumber,
    view,
    group,
    cellCategoryId,
    cellPurposeId,
    expirationTimer,
    minVolume,
    maxVolume,
  } = cell;

  const cellCategory = useMemo(
    () =>
      (cellCategoryId && cellCategoryList.find(({ id }) => id === Number(cellCategoryId))) || null,
    [cellCategoryList, cellCategoryId],
  );

  const cellPurpose = useMemo(
    () => (cellPurposeId && cellPurposeList.find(({ id }) => id === Number(cellPurposeId))) || null,
    [cellPurposeList, cellPurposeId],
  );

  // Обработчики
  const handleChange =
    (key: keyof CreateProductModelCell) =>
    ({ value }: { value: string | number | null }) => {
      switch (key) {
        case 'cellCategoryId':
        case 'cellNumber':
        case 'cellPurposeId':
        case 'expirationTimer':
        case 'maxVolume':
        case 'minVolume':
          return onChange(key)(Number(value));
        default:
          return onChange(key)(value);
      }
    };

  return (
    <div className={styles.formItem}>
      <VerticalContainer space="xs">
        <HorizontalContainer space="s" align="end">
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.cellNumber?.isError
                ? t('machineControl.model.create.cells.product.cellNumber.tooltip.label')
                : t(String(error.cellNumber?.label)),
            }}
            status={!error ? undefined : error.cellNumber?.status}
            label={t('machineControl.model.create.cells.product.cellNumber.input.label')}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(cellNumber)}
            onChange={handleChange('cellNumber')}
          />
          <ComboboxWithTooltip
            tooltipProps={{
              content: !error?.view?.isError
                ? t('machineControl.model.create.cells.product.view.tooltip.label')
                : t(String(error.view?.label)),
            }}
            status={!error ? undefined : error.view?.status}
            label={t('machineControl.model.create.cells.product.view.select.label')}
            items={productViewList}
            getItemLabel={(item: ProductView) =>
              t(`machineControl.model.create.cells.product.view.${item}`)
            }
            getItemKey={(item: ProductView) => item}
            value={view}
            onChange={handleChange('view')}
          />
          <ComboboxWithTooltip
            tooltipProps={{
              content: !error?.group?.isError
                ? t('machineControl.model.create.cells.product.group.tooltip.label')
                : t(String(error.group?.label)),
            }}
            status={!error ? undefined : error.group?.status}
            label={t('machineControl.model.create.cells.product.group.select.label')}
            items={productGroupList}
            getItemLabel={(item: ProductGroup) =>
              t(`machineControl.model.create.cells.product.group.${item}`)
            }
            getItemKey={(item: ProductGroup) => item}
            value={group}
            onChange={handleChange('group')}
          />
          <Button iconLeft={IconTrash as any} onlyIcon view="ghost" onClick={onDeleteClick} />
        </HorizontalContainer>
        <HorizontalContainer space="s" align="end">
          <ComboboxWithTooltip
            tooltipProps={{
              content: !error?.cellCategoryId?.isError
                ? t('machineControl.model.create.cells.product.cellCategoryId.tooltip.label')
                : t(String(error.cellCategoryId?.label)),
            }}
            status={!error ? undefined : error.cellCategoryId?.status}
            label={t('machineControl.model.create.cells.product.cellCategoryId.select.label')}
            items={cellCategoryList}
            value={cellCategory}
            getItemLabel={({ name }: CellCategoryDTO) => name}
            getItemKey={({ id }: CellCategoryDTO) => id}
            onChange={({ value }) =>
              handleChange('cellCategoryId')({ value: value?.id ? String(value.id) : null })
            }
          />
          <ComboboxWithTooltip
            tooltipProps={{
              content: !error?.cellPurposeId?.isError
                ? t('machineControl.model.create.cells.product.cellPurposeId.tooltip.label')
                : t(String(error.cellPurposeId?.label)),
            }}
            status={!error ? undefined : error.cellPurposeId?.status}
            label={t('machineControl.model.create.cells.product.cellPurposeId.select.label')}
            items={cellPurposeList}
            value={cellPurpose}
            getItemLabel={({ name }: CellPurposeDTO) => name}
            getItemKey={({ id }: CellPurposeDTO) => id}
            onChange={({ value }) =>
              handleChange('cellPurposeId')({ value: value?.id ? String(value.id) : null })
            }
          />
          <Button iconLeft={IconCopy as any} onlyIcon view="ghost" onClick={onCopyClick} />
        </HorizontalContainer>
        <HorizontalContainer space="s">
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.maxVolume?.isError
                ? t('machineControl.model.create.cells.product.maxVolume.tooltip.label')
                : t(String(error.maxVolume?.label)),
            }}
            status={!error ? undefined : error.maxVolume?.status}
            label={t('machineControl.model.create.cells.product.maxVolume.input.label')}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(maxVolume)}
            onChange={handleChange('maxVolume')}
          />
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.minVolume?.isError
                ? t('machineControl.model.create.cells.product.minVolume.tooltip.label')
                : t(String(error.minVolume?.label)),
            }}
            status={!error ? undefined : error.minVolume?.status}
            label={t('machineControl.model.create.cells.product.minVolume.input.label')}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(minVolume)}
            onChange={handleChange('minVolume')}
          />
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.expirationTimer?.isError
                ? t('machineControl.model.create.cells.product.expirationTimer.tooltip.label')
                : t(String(error.expirationTimer?.label)),
            }}
            status={!error ? undefined : error.expirationTimer?.status}
            label={t('machineControl.model.create.cells.product.expirationTimer.input.label')}
            rightSide="д"
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(expirationTimer)}
            onChange={handleChange('expirationTimer')}
          />
        </HorizontalContainer>
      </VerticalContainer>
    </div>
  );
};

export default ModelProductCellFormItem;
