import { FC } from 'react';
import { useAppDispatch } from '../../../../app/hooks/store';
import { useFormik } from 'formik';
import { createOutletValidationScheme } from './createOutletValidationScheme';
import { CreateOutletDTO, OutletFiltersDTO } from '../../../../types/serverInterface/outletDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../helpers/ inputHelpers';
import DefaultModal from '../../../../components/DefaultModal';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import styles from './CreateOutlet.module.scss';
import { createOutletAction } from '../../../../state/machineControl/actions';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение формы создания
 */
const createInitialValue: CreateOutletDTO = {
  name: null,
  city: null,
  country: null,
  houseNumber: null,
  street: null,
  organizationId: 0,
};

/**
 * Свойства компонента CreateOutlet
 */
type CreateOutletProps = {
  /**
   * Фильтра
   */
  filters: OutletFiltersDTO;
  /**
   * id организации
   */
  organizationId: number;
  /**
   * Обработчик закрытия формы
   */
  onCLose: () => void;
};

/**
 * Создание торговой точки
 */
const CreateOutlet: FC<CreateOutletProps> = ({ filters, organizationId, onCLose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    validationSchema: createOutletValidationScheme,
    initialValues: { ...createInitialValue, organizationId },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: CreateOutletDTO) {
    dispatch(createOutletAction(data, organizationId, filters)).then(() => {
      onCLose();
    });
  }

  const handleCancelClick = () => {
    onCLose();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('outlet.create.cancel.button.label')}
        view="clear"
        onClick={handleCancelClick}
      />
      <Button
        label={t('outlet.create.save.button.label')}
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  const renderForm = () => (
    <>
      <TextField
        label={t('outlet.create.name.input.label')}
        name="name"
        width="full"
        value={formik.values.name}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label={t('outlet.create.country.input.label')}
        name="country"
        width="full"
        value={formik.values.country}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label={t('outlet.create.city.input.label')}
        name="city"
        width="full"
        value={formik.values.city}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <HorizontalContainer space="l" isAutoSpace>
        <TextField
          label={t('outlet.create.street.input.label')}
          name="street"
          width="full"
          value={formik.values.street}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          className={styles.houseNumberField}
          label={t('outlet.create.houseNumber.input.label')}
          name="houseNumber"
          width="full"
          value={getInputNumberValue(formik.values.houseNumber)}
          onChange={({ e }) => formik.handleChange(e)}
        />
      </HorizontalContainer>
    </>
  );

  return (
    <DefaultModal
      className={styles.CreateOutlet}
      modalTitle={t('outlet.create.modal.title')}
      onClose={handleCancelClick}
      renderActions={renderActions}
    >
      {renderForm()}
    </DefaultModal>
  );
};

export default CreateOutlet;
