import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconArrowLeftSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M8.01517 2.23483C8.1483 2.36797 8.1604 2.5763 8.05147 2.72311L8.01517 2.76517L4.7805 6L8.01517 9.23484C8.1483 9.36797 8.1604 9.5763 8.05147 9.72311L8.01517 9.76516C7.88203 9.8983 7.6737 9.9104 7.52689 9.80147L7.48483 9.76516L3.98484 6.26516C3.8517 6.13203 3.8396 5.9237 3.94853 5.77689L3.98484 5.73483L7.48483 2.23483C7.63128 2.08839 7.86872 2.08839 8.01517 2.23483Z" />
  </svg>
);

const IconArrowLeftSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M10.6869 2.97982C10.8644 3.15733 10.8806 3.43511 10.7353 3.63085L10.6869 3.68693L6.37404 8.00004L10.6869 12.3132C10.8644 12.4907 10.8806 12.7684 10.7353 12.9642L10.6869 13.0203C10.5094 13.1978 10.2316 13.2139 10.0359 13.0687L9.97982 13.0203L5.31315 8.35359C5.13564 8.17608 5.11951 7.89831 5.26474 7.70257L5.31315 7.64649L9.97982 2.97982C10.1751 2.78456 10.4917 2.78456 10.6869 2.97982Z" />
  </svg>
);

const IconArrowLeftSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M16.0303 4.46967C16.2966 4.73594 16.3208 5.1526 16.1029 5.44621L16.0303 5.53033L9.561 12L16.0303 18.4697C16.2966 18.7359 16.3208 19.1526 16.1029 19.4462L16.0303 19.5303C15.7641 19.7966 15.3474 19.8208 15.0538 19.6029L14.9697 19.5303L7.96967 12.5303C7.7034 12.2641 7.6792 11.8474 7.89705 11.5538L7.96967 11.4697L14.9697 4.46967C15.2626 4.17678 15.7374 4.17678 16.0303 4.46967Z" />
  </svg>
);

export const IconArrowLeft = createIcon({
  name: 'IconArrowLeft',
  xs: IconArrowLeftSizeXS,
  s: IconArrowLeftSizeS,
  m: IconArrowLeftSizeM,
});
