import { FC, useEffect } from 'react';
import DefaultSidebar from '../../../../../../../components/DefaultSidebar';
import { SnackProductInfoProps } from './types';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/hooks/store';
import { selectSnackProductById } from '../../../../../../../state/productBase/selectors';
import DefaultInfo from '../../../../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../../../../components/DefaultInfoItem';
import { useTranslation } from 'react-i18next';
import VerticalContainer from '../../../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/Text';
import { getSnackProductAction } from '../../../../../../../state/productBase/actions';
import styles from './SnackProductInfo.module.scss';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import AlertButton from '../../../../../../../components/AlertButton';
import { IconTrash } from '../../../../../../../assets/icon/iconTrash';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconEdit2 } from '../../../../../../../assets/icon/iconEdit2';

/**
 * Информация о товаре бренда
 */
const SnackProductInfo: FC<SnackProductInfoProps> = ({ handleCloseModal, productId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const productInfo = useAppSelector(selectSnackProductById(productId));

  useEffect(() => {
    !productInfo && dispatch(getSnackProductAction(productId));
  }, [dispatch, productId, productInfo]);

  //render методы
  const renderMainInformation = () => {
    return (
      <VerticalContainer space="xs">
        <Text view="primary" size="s" lineHeight="s" weight="semibold">
          {t('snackProductBase.info.mainInformation.title')}
        </Text>
        <DefaultInfo className={styles.defaultInfo}>
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.brandName.item.label'),
              value: String(productInfo?.brandName),
            }}
          />
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.name.item.label'),
              value: String(productInfo?.name),
            }}
          />
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.barcode.item.label'),
              value: String(productInfo?.barcode),
            }}
          />
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.vendorCode.item.label'),
              value: String(productInfo?.vendorCode),
            }}
          />
        </DefaultInfo>
      </VerticalContainer>
    );
  };

  const renderNutritionalValue = () => {
    return (
      <VerticalContainer space="xs">
        <Text view="primary" size="s" lineHeight="s" weight="semibold">
          {t('snackProductBase.info.nutritionValue.title')}
        </Text>
        <DefaultInfo className={styles.defaultInfo}>
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.calories.item.label'),
              value: `${productInfo?.calories} ${t('snackProductBase.units.KCAL')}`,
            }}
          />
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.proteins.item.label'),
              value: `${productInfo?.proteins} ${t('snackProductBase.units.G')}`,
            }}
          />
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.fats.item.label'),
              value: `${productInfo?.fats} ${t('snackProductBase.units.G')}`,
            }}
          />
          <DefaultInfoItem
            className={styles.infoContainer}
            nameClassName={styles.nameContainer}
            valueClassName={styles.valueContainer}
            item={{
              name: t('snackProductBase.info.carbohydrates.item.label'),
              value: `${productInfo?.carbohydrates} ${t('snackProductBase.units.G')}`,
            }}
          />
        </DefaultInfo>
      </VerticalContainer>
    );
  };

  const renderCompound = () => {
    return (
      <VerticalContainer space="xs">
        <Text view="primary" size="s" lineHeight="s" weight="semibold">
          {t('snackProductBase.info.compound.title')}
        </Text>
        <Text size="s" lineHeight="s" view="primary">
          {productInfo?.compound}
        </Text>
      </VerticalContainer>
    );
  };

  const renderAllergens = () => {
    return (
      <VerticalContainer space="xs">
        <Text view="primary" size="s" lineHeight="s" weight="semibold">
          {t('snackProductBase.info.allergens.title')}
        </Text>
        <Text size="s" lineHeight="s" view="primary">
          {productInfo?.allergens}
        </Text>
      </VerticalContainer>
    );
  };

  const renderDescription = () => {
    return (
      <VerticalContainer space="xs">
        <Text view="primary" size="s" lineHeight="s" weight="semibold">
          {t('snackProductBase.info.description.title')}
        </Text>
        <Text size="s" lineHeight="s" view="primary">
          {productInfo?.description}
        </Text>
      </VerticalContainer>
    );
  };

  const renderProductInfo = () => {
    return (
      <VerticalContainer space="xl">
        {renderMainInformation()}
        {renderNutritionalValue()}
        {renderCompound()}
        {renderAllergens()}
        {renderDescription()}
      </VerticalContainer>
    );
  };

  const renderActions = () => {
    return (
      <HorizontalContainer>
        <AlertButton
          label={t('snackProductBase.info.delete.button.label')}
          size="m"
          iconLeft={IconTrash as any}
          view="primary"
        />
        <Button
          label={t('snackProductBase.info.edit.button.label')}
          size="m"
          iconLeft={IconEdit2 as any}
          view="primary"
        />
      </HorizontalContainer>
    );
  };

  return (
    <DefaultSidebar
      modalTitle={t('snackProductBase.info.modal.title')}
      className={styles.sidebar}
      onClose={handleCloseModal}
      // renderActions={renderActions}
    >
      {renderProductInfo()}
    </DefaultSidebar>
  );
};

export default SnackProductInfo;
