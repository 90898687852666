import { FC } from 'react';
import { CreateWaterModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import styles from '../../CreateModelCellsForm.module.scss';
import VerticalContainer from '../../../../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import TextFieldWithTooltip from '../../../../../../../components/withTooltip/TextField';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { WaterCellsFieldError } from '../../../types';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ModelWaterCellFormItem
 */
type ModelWaterCellFormItemProps = {
  /**
   * Ячейка воды
   */
  cell: CreateWaterModelCell;
  /**
   * Ошибка ячейки
   */
  error: WaterCellsFieldError | undefined;
  /**
   * Обработчик изменения ячеек воды модели автомата
   *
   * @param key ключ поля в форме ячейки воды
   * @param value новое значение поля
   */
  onChange: (key: keyof CreateWaterModelCell) => (value: string | null) => void;
};

/**
 * Форма изменения ячейки воды
 */
const ModelWaterCellFormItem: FC<ModelWaterCellFormItemProps> = ({ cell, error, onChange }) => {
  const { t } = useTranslation();

  const { minVolume, maxVolume, cellNumber, isCount, filterValue, expirationTimer } = cell;

  // Обработчики
  const handleChange =
    (key: keyof CreateWaterModelCell) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  // render методы
  const renderBottleForm = () => (
    <VerticalContainer space="xs">
      <Text>{t('machineControl.model.create.cells.water.bottle.text')}</Text>
      <HorizontalContainer space="s">
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.bottle.cellNumber.tooltip.label')
              : error.cellNumber?.label,
          }}
          status={!error ? undefined : error.cellNumber?.status}
          label={t('machineControl.model.create.cells.water.bottle.cellNumber.input.label')}
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(cellNumber)}
          onChange={handleChange('cellNumber')}
        />
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.bottle.maxVolume.tooltip.label')
              : error.maxVolume?.label,
          }}
          status={!error ? undefined : error.maxVolume?.status}
          label={t('machineControl.model.create.cells.water.bottle.maxVolume.input.label')}
          rightSide="л"
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(maxVolume)}
          onChange={handleChange('maxVolume')}
        />
      </HorizontalContainer>
      <HorizontalContainer space="s">
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.bottle.minVolume.tooltip.label')
              : error.minVolume?.label,
          }}
          status={!error ? undefined : error.minVolume?.status}
          label={t('machineControl.model.create.cells.water.bottle.minVolume.input.label')}
          rightSide="л"
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(minVolume)}
          onChange={handleChange('minVolume')}
        />
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.bottle.expirationTimer.tooltip.label')
              : error.expirationTimer?.label,
          }}
          status={!error ? undefined : error.expirationTimer?.status}
          label={t('machineControl.model.create.cells.water.bottle.expirationTimer.input.label')}
          rightSide="д"
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(expirationTimer)}
          onChange={handleChange('expirationTimer')}
        />
      </HorizontalContainer>
    </VerticalContainer>
  );

  const renderTapForm = () => (
    <VerticalContainer space="xs">
      <Text>{t('machineControl.model.create.cells.water.tap.text')}</Text>
      <HorizontalContainer space="s">
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.tap.cellNumber.tooltip.label')
              : error.cellNumber?.label,
          }}
          status={!error ? undefined : error.cellNumber?.status}
          label={t('machineControl.model.create.cells.water.tap.cellNumber.input.label')}
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(cellNumber)}
          onChange={handleChange('cellNumber')}
        />
      </HorizontalContainer>
      <HorizontalContainer space="s">
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.tap.filterValue.tooltip.label')
              : error.filterValue?.label,
          }}
          status={!error ? undefined : error.filterValue?.status}
          label={t('machineControl.model.create.cells.water.tap.filterValue.input.label')}
          rightSide="л"
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(filterValue)}
          onChange={handleChange('filterValue')}
        />
        <TextFieldWithTooltip
          tooltipProps={{
            content: !error
              ? t('machineControl.model.create.cells.water.tap.expirationTimer.tooltip.label')
              : error.expirationTimer?.label,
          }}
          status={!error ? undefined : error.expirationTimer?.status}
          label={t('machineControl.model.create.cells.water.tap.expirationTimer.input.label')}
          rightSide="д"
          width="full"
          type="number"
          incrementButtons={false}
          value={getInputNumberValue(expirationTimer)}
          onChange={handleChange('expirationTimer')}
        />
      </HorizontalContainer>
    </VerticalContainer>
  );

  const renderForm = () => {
    if (isCount) {
      return renderBottleForm();
    }

    return renderTapForm();
  };

  return <div className={styles.formItem}>{renderForm()}</div>;
};

export default ModelWaterCellFormItem;
