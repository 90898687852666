import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectComponentList } from '../../../state/productBase/selectors';
import { deleteComponentAction, getComponentListAction } from '../../../state/productBase/actions';
import styles from '../Taste/Taste.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import ComponentForm from './ComponentForm';
import EditComponent from './ComponentForm/EditComponent';
import DeleteComponent from './DeleteComponent';
import ComponentLocale from './ComponentLocale';
import { Loader } from '@consta/uikit/Loader';
import TableCard from '../../../components/TableCard';
import { ComponentDTO } from '../../../types/serverInterface/componentDTO';
import { useTranslation } from 'react-i18next';

/**
 * Базовый компонент возможного компонента состава
 */
const Component: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const componentList = useAppSelector(selectComponentList());

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [editableId, setEditableId] = useState<number | null>(null);
  const [deletableId, setDeletableId] = useState<number | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [localeId, setLocaleId] = useState<number | null>(null);

  const filterComponent = useMemo(() => {
    return componentList.filter((component) =>
      component.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, componentList]);

  useEffect(() => {
    dispatch(getComponentListAction()).finally(() => setIsLoading(false));
  }, [dispatch]);

  // Обработчики
  const handleAddButtonClick = () => {
    setIsCreateOpen(true);
  };

  const handleAddClose = () => {
    setIsCreateOpen(false);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleEditClick = (id: number) => () => {
    setEditableId(id);
  };

  const handleEditClose = () => {
    setEditableId(null);
  };

  const handleDeleteClick = (id: number) => () => {
    setDeletableId(id);
  };

  const handleCancelDeleteClick = () => {
    setDeletableId(null);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletableId &&
      dispatch(deleteComponentAction(deletableId)).finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const handleLocaleClick = (id: number) => () => {
    setLocaleId(id);
  };

  const handleLocaleClose = () => {
    setLocaleId(null);
  };

  // render методы
  const renderAction = () => (
    <div className={styles.actions}>
      <Button
        label={t('productBaseConfig.component.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddButtonClick}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
  );

  const renderRowAction = ({ id }: ComponentDTO) => (
    <div className={styles.rowActions}>
      <Button view="ghost" iconLeft={IconWorldStroked as any} onClick={handleLocaleClick(id)} />
      <Button view="ghost" iconLeft={IconEdit as any} onClick={handleEditClick(id)} />
      <Button view="ghost" iconLeft={IconTrash as any} onClick={handleDeleteClick(id)} />
    </div>
  );

  const renderCreateForm = () => (
    <ComponentForm type="create" isOpen={isCreateOpen} onClose={handleAddClose} />
  );

  const renderEditForm = () =>
    editableId && <EditComponent componentId={editableId} onClose={handleEditClose} />;

  const renderDeleteModal = () =>
    deletableId && (
      <DeleteComponent
        componentId={deletableId}
        isDeleteLoading={isDeleteLoading}
        onClose={handleCancelDeleteClick}
        onDelete={handleDelete}
      />
    );

  const renderLocale = () =>
    localeId && (
      <ComponentLocale isOpen={!!localeId} componentId={localeId} onClose={handleLocaleClose} />
    );

  if (isLoading) return <Loader />;

  return (
    <div>
      {renderCreateForm()}
      {renderEditForm()}
      {renderDeleteModal()}
      {renderLocale()}
      {renderAction()}
      <TableCard
        rows={filterComponent}
        columns={{
          name: {
            key: 'name',
            title: t('productBaseConfig.component.name.table.column.title'),
            alignment: 'left',
            getItemLabel: (data) => data.name,
          },
          typeUnit: {
            key: 'defaultUnit',
            title: t('productBaseConfig.component.defaultUnit.table.column.title'),
            alignment: 'left',
            getItemLabel: (data) => t(`productBase.units.${data.defaultUnit}`),
          },
          id: {
            key: 'id',
            title: t('productBaseConfig.component.action.table.column.title'),
            alignment: 'right',
            renderCell: renderRowAction,
          },
        }}
      />
    </div>
  );
};

export default Component;
