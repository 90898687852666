import {
  Discount,
  PromoCodeListFiltersReq,
  TypeDiscountEnum,
} from '../../../../types/serverInterface/promoCodeDTO';
import * as ExcelJS from 'exceljs';
import { Pagination } from '../../../../types/types';
import { useAppDispatch } from '../../../../app/hooks/store';
import { getPromoCodeListByExportAction } from '../../../../state/promoCode/actions';
import { useTranslation } from 'react-i18next';

export const useExportFromExcel = ({
  organizationId,
  filters,
  pagination,
}: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
}): { handleExcelExportClick: () => void } => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return {
    handleExcelExportClick: () => {
      dispatch(getPromoCodeListByExportAction(organizationId, filters, pagination)).then((res) => {
        const promoCodeList = res.data;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Список промокодов`);
        worksheet.getCell('A1').value = 'Промокод';
        worksheet.getCell('B1').value = 'Скидка';
        worksheet.getCell('C1').value = 'Начало использования';
        worksheet.getCell('D1').value = 'Конец использования';
        worksheet.getCell('E1').value = 'Количество использований';
        worksheet.getCell('F1').value = 'Группа';
        worksheet.getCell('G1').value = 'Описание';

        //Начинаем работать с внешним видом exel файла
        worksheet.eachRow((row) => {
          row.eachCell((cell) => {
            cell.style.font = { size: 16 };
            // Включаем автоматический перенос текста по словам
            cell.alignment = { wrapText: true, horizontal: 'left' };
          });
        });

        worksheet.getColumn('A').width = 16;
        worksheet.getColumn('B').width = 11;
        worksheet.getColumn('C').width = 21;
        worksheet.getColumn('D').width = 21;
        worksheet.getColumn('E').width = 21;
        worksheet.getColumn('F').width = 15;
        worksheet.getColumn('G').width = 30;

        const getSalesLabel = (discount: Discount): string => {
          if (discount.type === TypeDiscountEnum.FREE) {
            return t('promoCode.list.column.discount.label.FREE');
          }

          if (discount.type === TypeDiscountEnum.FIXED) {
            return `${discount.amount} ${t('promoCode.list.column.discount.label.FIXED')}`;
          }

          return `${discount.amount} ${t('promoCode.list.column.discount.label.PERCENT')}`;
        };

        promoCodeList?.forEach(
          ({ code, periodUse, qty, used, groupName, description, discount }, index) => {
            const rowExcelIndex = index + 2;
            worksheet.getCell(`A${rowExcelIndex}`).value = code;
            worksheet.getCell(`B${rowExcelIndex}`).value = getSalesLabel(discount);
            worksheet.getCell(`C${rowExcelIndex}`).value = periodUse?.from || '-';
            worksheet.getCell(`D${rowExcelIndex}`).value = periodUse?.to || '-';
            worksheet.getCell(`E${rowExcelIndex}`).value = qty ? used + '/' + qty : '-';
            worksheet.getCell(`F${rowExcelIndex}`).value = groupName || '-';
            worksheet.getCell(`G${rowExcelIndex}`).value = description || '-';
          },
        );

        const currentDate = new Date();

        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');

        const formattedDateTime = `${day}.${month}.${year} ${hours}-${minutes}-${seconds}`;

        //создание файла exel
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `Список промокодов ${formattedDateTime}.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      });
    },
  };
};
