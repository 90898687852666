import { FC } from 'react';
import { IngredientInfo } from '../../../../../../../types/serverInterface/recipeDTO';
import { Badge } from '@consta/uikit/Badge';
import { IconDiamond } from '@consta/uikit/IconDiamond';
import { IconShakerCup } from '../../../../../../../assets/icon/iconShakerCup';
import styles from './RecipeIngredientInfo.module.scss';

/**
 * Свойсства компоненета RecipeIngredientInfo
 */
type RecipeIngredientGroupProps = {
  /**
   * Игредиент
   */
  ingredient: IngredientInfo;
};

/**
 * Информация о ингредиенте в рецепте
 */
const RecipeIngredientInfo: FC<RecipeIngredientGroupProps> = ({ ingredient }) => {
  // render методы
  const renderTasted = (taste: { id: number; name: string }) => (
    <Badge size="l" view="filled" status="system" label={taste.name} />
  );

  return (
    <div className={styles.tagsListItem}>
      {ingredient.cellCategory && (
        <Badge size="l" view="filled" status="system" label={ingredient.cellCategory.name} />
      )}
      {ingredient.cellPurpose && (
        <Badge size="l" view="filled" status="system" label={ingredient.cellPurpose.name} />
      )}
      {ingredient.tastesId?.map(renderTasted)}
      <Badge size="l" view="filled" status="system" label={`${ingredient.qty} МЛ`} />
      <Badge
        size="l"
        view="filled"
        status="system"
        label={String(ingredient.maxNumberPortion)}
        iconRight={IconShakerCup as any}
      />
      {ingredient.required && (
        <Badge size="l" view="filled" status="system" iconLeft={IconDiamond as any} />
      )}
    </div>
  );
};

export default RecipeIngredientInfo;
