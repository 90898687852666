import { FC, useState } from 'react';
import { LocaleNameDescriptionList } from '../../locale/NameDescriptionLocale/types';
import { Loader } from '@consta/uikit/Loader';
import NameDescriptionLocale from '../../locale/NameDescriptionLocale';
import { useTranslation } from 'react-i18next';

/**
 * Свойтсва компонента EditCustomFormLocale
 */
type EditCustomFormLocaleProps = {
  /**
   * Флаг открытия
   */
  isOpen: boolean;
  /**
   * Локализация поля кастомной формы
   */
  locale: LocaleNameDescriptionList;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик отправки данных
   *
   * @param data данные формы
   */
  onSubmit: (data: LocaleNameDescriptionList) => void;
};

/**
 * Локализация поля кастомной формы
 */
const EditCustomFormLocale: FC<EditCustomFormLocaleProps> = ({
  isOpen,
  locale,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [isLocaleEdit, setIsLocaleEdit] = useState(false);

  // Обработчики
  const handleSubmit = (data: LocaleNameDescriptionList) => {
    onSubmit(data);
    setIsLocaleEdit(false);
  };

  const handleEditClick = () => {
    setIsLocaleEdit(true);
  };

  const handleEditClose = () => {
    setIsLocaleEdit(false);
  };

  const handleClose = onClose;

  if (!locale) return <Loader />;

  return (
    <NameDescriptionLocale
      isEdit={isLocaleEdit}
      isOpen={isOpen}
      isTransformList={true}
      locale={locale}
      modalTitle={t('customForm.locale.modal.title')}
      onSubmit={handleSubmit}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onClose={handleClose}
    />
  );
};

export default EditCustomFormLocale;
