import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconSettingsFilledSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_34469_1351)">
      <path d="M4.875 6.00001C4.875 5.37869 5.37868 4.87501 6 4.87501C6.62132 4.87501 7.125 5.37869 7.125 6.00001C7.125 6.62133 6.62132 7.12501 6 7.12501C5.37868 7.12501 4.875 6.62133 4.875 6.00001Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29262 1.61615C4.78282 -0.101541 7.21712 -0.101542 7.70733 1.61615C7.85921 2.14835 8.40599 2.46403 8.94283 2.32946C10.6755 1.89515 11.8926 4.00332 10.6502 5.28669C10.2652 5.68433 10.2652 6.31569 10.6502 6.71332C11.8926 7.9967 10.6755 10.1049 8.94283 9.67055C8.40599 9.53599 7.85921 9.85167 7.70733 10.3839C7.21712 12.1016 4.78282 12.1016 4.29262 10.3839C4.14073 9.85167 3.59396 9.53599 3.05712 9.67055C1.32445 10.1049 0.107299 7.9967 1.34976 6.71332C1.73472 6.31569 1.73472 5.68433 1.34976 5.28669C0.1073 4.00332 1.32445 1.89515 3.05712 2.32946C3.59396 2.46403 4.14073 2.14835 4.29262 1.61615ZM6 4.12501C4.96447 4.12501 4.125 4.96447 4.125 6.00001C4.125 7.03554 4.96447 7.87501 6 7.87501C7.03553 7.87501 7.875 7.03554 7.875 6.00001C7.875 4.96447 7.03553 4.12501 6 4.12501Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_34469_1351">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

const IconSettingsFilledSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M6.49992 7.99997C6.49992 7.17154 7.17149 6.49997 7.99992 6.49997C8.82835 6.49997 9.49992 7.17154 9.49992 7.99997C9.49992 8.8284 8.82835 9.49997 7.99992 9.49997C7.17149 9.49997 6.49992 8.8284 6.49992 7.99997Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.72341 2.15482C6.37701 -0.135429 9.62275 -0.13543 10.2764 2.15482C10.4789 2.86443 11.2079 3.28533 11.9237 3.10591C14.2339 2.52683 15.8568 5.33772 14.2002 7.04888C13.6869 7.57907 13.6869 8.42088 14.2002 8.95106C15.8568 10.6622 14.2339 13.4731 11.9237 12.894C11.2079 12.7146 10.4789 13.1355 10.2764 13.8451C9.62275 16.1354 6.37701 16.1354 5.72341 13.8451C5.5209 13.1355 4.79187 12.7146 4.07608 12.894C1.76585 13.4731 0.142985 10.6622 1.7996 8.95106C2.31288 8.42088 2.31288 7.57907 1.7996 7.04888C0.142985 5.33772 1.76585 2.52683 4.07607 3.10591C4.79186 3.28533 5.5209 2.86443 5.72341 2.15482ZM7.99992 5.49997C6.61921 5.49997 5.49992 6.61926 5.49992 7.99997C5.49992 9.38068 6.61921 10.5 7.99992 10.5C9.38063 10.5 10.4999 9.38068 10.4999 7.99997C10.4999 6.61926 9.38063 5.49997 7.99992 5.49997Z"
    />
  </svg>
);

const IconSettingsFilledSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M9.75 12C9.75 10.7574 10.7574 9.75002 12 9.75002C13.2426 9.75002 14.25 10.7574 14.25 12C14.25 13.2427 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2427 9.75 12Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.58523 3.2323C9.56564 -0.203083 14.4342 -0.203084 15.4147 3.2323C15.7184 4.2967 16.812 4.92806 17.8857 4.65893C21.351 3.7903 23.7853 8.00664 21.3004 10.5734C20.5305 11.3687 20.5305 12.6314 21.3004 13.4266C23.7853 15.9934 21.351 20.2097 17.8857 19.3411C16.812 19.072 15.7184 19.7033 15.4147 20.7677C14.4342 24.2031 9.56564 24.2031 8.58523 20.7677C8.28146 19.7033 7.18792 19.072 6.11423 19.3411C2.6489 20.2097 0.214599 15.9934 2.69952 13.4266C3.46944 12.6314 3.46944 11.3687 2.69952 10.5734C0.214599 8.00664 2.6489 3.7903 6.11423 4.65893C7.18792 4.92806 8.28146 4.2967 8.58523 3.2323ZM12 8.25002C9.92893 8.25002 8.25 9.92895 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92895 14.0711 8.25002 12 8.25002Z"
    />
  </svg>
);

export const IconSettingsFilled = createIcon({
  name: 'IconSettingsFilled',
  xs: IconSettingsFilledSizeXS,
  s: IconSettingsFilledSizeS,
  m: IconSettingsFilledSizeM,
});
