import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectRecipeLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getRecipeListAction,
  getRecipeLocaleAction,
} from '../../../../state/productBase/actions';
import { LocaleNameDescriptionList } from '../../../../components/locale/NameDescriptionLocale/types';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import NameDescriptionLocale from '../../../../components/locale/NameDescriptionLocale';
import ChipsGroup from '../../../../components/Chips/ChipsGroup';
import { RecipeContextMenuItems, recipeInfoStepsList } from '../types';
import Chips from '../../../../components/Chips';
import { useTranslation } from 'react-i18next';

/**
 * Свойства комопнента RecipeLocale
 */
type RecipeLocaleProps = {
  /**
   * Выбранный шаг
   */
  selectedStep: RecipeContextMenuItems;
  /**
   * id рецепта
   */
  recipeId: number;
  /**
   * Название рецепта
   */
  recipeName: string;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Обработчик выбора шага
   */
  onSelectedStepChange: (step: RecipeContextMenuItems) => void;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Локализация рецепта
 */
const RecipeLocale: FC<RecipeLocaleProps> = ({
  recipeId,
  recipeName,
  selectedStep,
  organizationId,
  onSelectedStepChange,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state: recipeLocale } = useAppSelector(selectRecipeLocale());

  const [isEditLocale, setIsEditLocale] = useState(false);

  useEffect(() => {
    dispatch(getRecipeLocaleAction(recipeId));
  }, [dispatch, recipeId]);

  // Обработчики
  const handleSubmit = (data: LocaleNameDescriptionList) => {
    recipeLocale &&
      dispatch(
        editLocaleAction({
          ...recipeLocale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
            {
              entityFieldName: FieldNames.DESCRIPTION,
              localeValues: data.map((value) => ({
                value: value.description,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() => dispatch(getRecipeLocaleAction(recipeId)).then(() => handleEditClose()));
  };

  const handleEditClick = () => {
    setIsEditLocale(true);
  };

  const handleEditClose = () => {
    setIsEditLocale(false);
  };

  const handleClose = () => {
    dispatch(getRecipeListAction(organizationId));
    onClose();
  };

  const handleStepChange = (step: RecipeContextMenuItems) => () => {
    onSelectedStepChange(step);
  };

  // render методы
  const renderChips = () => (
    <ChipsGroup>
      {recipeInfoStepsList.map((name, index) => (
        <Chips
          key={index}
          label={t(`productBase.recipe.info.chips.${name}`)}
          name={name}
          isSelect={name === selectedStep}
          onClick={handleStepChange(name)}
        />
      ))}
    </ChipsGroup>
  );

  return (
    <NameDescriptionLocale
      isOpen
      isTransformList={false}
      locale={recipeLocale}
      isEdit={isEditLocale}
      modalTitle={t('productBase.recipe.locale.modal.title', { recipeName })}
      chips={renderChips()}
      onSubmit={handleSubmit}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onClose={handleClose}
    />
  );
};

export default RecipeLocale;
