import { FC } from 'react';
import MatrixSchemeRowItem from './MatrixSchemeRowItem';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { MatrixSchemeRowProps } from './types';

/**
 * Ряд в группе автомата снек
 */
const MatrixSchemeRow: FC<MatrixSchemeRowProps> = ({ maxCells }) => {
  return (
    <HorizontalContainer space="3xs">
      {Array.from({ length: maxCells }, (_, cellIndex) => (
        <MatrixSchemeRowItem key={cellIndex} />
      ))}
    </HorizontalContainer>
  );
};

export default MatrixSchemeRow;
