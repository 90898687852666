import { FC } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { BrandDTO } from '../../../../types/serverInterface/brandDTO';
import DefaultModal from '../../../../components/DefaultModal';
import { useTranslation } from 'react-i18next';
import DefaultInfo from '../../../../components/DefaultInfo';
import DefaultInfoItem from '../../../../components/DefaultInfoItem';

/**
 * Свойства компонента BrandInfo
 */
type BrandInfoProps = {
  /**
   * Бренд
   */
  brand: BrandDTO;
  /**
   * Флаг открытия страницы информации о бренде
   */
  isOpen: boolean;
  /**
   * Флаг возможноости редактирования
   */
  isAllowedEdit: boolean;
  /**
   * Обработчик закрытия страницы информации
   */
  onClose: () => void;
  /**
   * Обработчик нажатия на кнопку изменения бренда
   */
  onEditClick: () => void;
};

/**
 * Компонент информации о бренде
 */
const BrandInfo: FC<BrandInfoProps> = ({ brand, isOpen, isAllowedEdit, onClose, onEditClick }) => {
  const { t } = useTranslation();

  const { name, mediaKey } = brand;

  // Обработчики
  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  const handleEdit = onEditClick;

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBase.brand.info.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      {isAllowedEdit && (
        <Button label={t('productBase.brand.info.edit.button.label')} onClick={handleEdit} />
      )}
    </>
  );

  return (
    <DefaultModal
      modalTitle={t('productBase.brand.info.modal.title')}
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      <DefaultInfo>
        <DefaultInfoItem
          item={{ name: t('productBase.brand.info.name.infoItem.label'), value: name }}
        />
        <DefaultInfoItem
          item={{
            name: t('productBase.brand.info.mediaKey.infoItem.label'),
            value: mediaKey,
          }}
        />
      </DefaultInfo>
    </DefaultModal>
  );
};

export default BrandInfo;
