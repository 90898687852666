import { FC } from 'react';
import { useAppSelector } from '../../../app/hooks/store';
import { selectCellViewById } from '../../../state/productBase/selectors';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DeleteSportPit
 */
type DeleteSportPitProps = {
  /**
   * id вида спортивного питания
   */
  sportPitId: number;
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

/**
 * Компонент удаления вида спортивного питания
 */
const DeleteSportPit: FC<DeleteSportPitProps> = ({
  sportPitId,
  isDeleteLoading,
  onClose,
  onDelete,
}) => {
  const { t } = useTranslation();

  const sportPit = useAppSelector(selectCellViewById(sportPitId));

  if (!sportPit) return null;

  const { name } = sportPit;

  return (
    <DeleteModal
      isOpen={!!sportPitId}
      isDeleteLoading={isDeleteLoading}
      title={t('productBaseConfig.sportPit.deleteModal.title', { name })}
      description={t('productBaseConfig.sportPit.deleteModal.description', { name })}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteSportPit;
