import React, { FC, useRef, useState } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import styles from './MachineSettingsGroupAction.module.scss';
import { getIconByName } from '../../../../../../helpers/getIcon';
import { ActionGroup } from '../../../../../../types/serverInterface/machineDTO';

/**
 * Свойства компоонента MachineSettingsGroupAction
 */
type MachineSettingsGroupActionProps = {
  /**
   * Группа кастомных действий
   */
  group: ActionGroup;
  onActionClick: (code: string) => void;
};

/**
 * Группа кастомных дейтсвий
 */
const MachineSettingsGroupAction: FC<MachineSettingsGroupActionProps> = ({
  group,
  onActionClick,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleOpenContextMenu = () => {
    setIsOpen(true);
  };

  const handleCloseContextmenu = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Button
        label={group.label}
        onlyIcon
        iconLeft={getIconByName(group.icon) as any}
        view="clear"
        ref={ref}
        onClick={handleOpenContextMenu}
      />
      <ContextMenu
        className={styles.contextMenu}
        isOpen={isOpen}
        direction="downStartLeft"
        anchorRef={ref}
        items={group.actions}
        getItemLabel={({ label }) => label}
        getItemLeftIcon={({ icon }) => getIconByName(icon) as any}
        onClickOutside={handleCloseContextmenu}
        onItemClick={({ item }) => onActionClick(item.key)}
      />
    </div>
  );
};

export default MachineSettingsGroupAction;
