import { FC } from 'react';
import { Modal } from '@consta/uikit/Modal';
import styles from './DefaultModal.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '@consta/uikit/IconClose';
import classNames from 'classnames';

/**
 * Свойства компонента DefaultModal
 */
type DefaultModalProps = {
  /**
   * Внешний className
   */
  className?: string;
  /**
   * Флаг открытия модального окна
   */
  isOpen?: boolean;
  /**
   * Заголовок модального окна
   */
  modalTitle: string;
  /**
   * Расположение модального окна
   */
  position?: 'center' | 'top';
  /**
   * Обработчик закрытия модального окна
   */
  onClose?: () => void;
  /**
   * Рендер метод для кнопок управления в нижней части модального окна
   */
  renderActions?: () => React.ReactNode;
  /**
   * Контент модального окна
   */
  children?: React.ReactNode;
  isPrint?: boolean;
};

/**
 * Модальное окно с настроенными стилями
 */
const DefaultModal: FC<DefaultModalProps> = ({
  className,
  isOpen = true,
  modalTitle,
  position = 'top',
  onClose,
  renderActions,
  children,
  isPrint = false,
}) => {
  // Обработчики
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Modal
      className={classNames(styles.defaultModal, className, isPrint && styles.isPrint)}
      position={position}
      isOpen={isOpen}
      onEsc={handleClose}
      onClickOutside={handleClose}
    >
      {!isPrint && (
        <div className={styles.header}>
          <Text size="2xl" weight="semibold">
            {modalTitle}
          </Text>
          <Button
            onlyIcon
            view="clear"
            size="m"
            iconLeft={IconClose as any}
            onClick={handleClose}
          />
        </div>
      )}

      <div className={styles.content}>{children}</div>
      {!isPrint && renderActions && <div className={styles.actions}>{renderActions()}</div>}
    </Modal>
  );
};

export default DefaultModal;
