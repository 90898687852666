import { FC } from 'react';
import FilterButton from '../../filters/FilterButton';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../HorizontalContainer';
import VerticalContainer from '../../VerticalContainer';
import styles from './StatusFilter.module.scss';
import { StatusColor } from '../../../types/serverInterface/machineDTO';
import { Radio } from '@consta/uikit/Radio';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента StatusFilter
 */
type StatusFilterProps = {
  /**
   * Размер кнопки
   */
  size?: 'm' | 'l';
  /**
   * Общее количество автоматов
   */
  listCount: number;
  /**
   * Количество автоматов со статусом success
   */
  successMachineNumber: number;
  /**
   * Количество автоматов со статусом warning
   */
  warningMachineNumber: number;
  /**
   * Количество автоматов со статусом alert
   */
  alertMachineNumber: number;
  /**
   * Выбранный статус
   */
  selectedStatus: StatusColor | null;
  /**
   * Обработчик выбора статуса
   *
   * @param status выбранный статус
   */
  onSelectedStatus: (status: StatusColor | null) => void;
};

/**
 * Фильтр статусов автомата
 */
const StatusFilter: FC<StatusFilterProps> = ({
  size = 'm',
  listCount,
  successMachineNumber,
  warningMachineNumber,
  alertMachineNumber,
  onSelectedStatus,
  selectedStatus,
}) => {
  const { t } = useTranslation();

  // render методы
  const renderStatusFilter = () => (
    <VerticalContainer space="5xl" className={styles.StatusFilter}>
      <VerticalContainer space="l">
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.all')}
            checked={selectedStatus === null}
            onChange={() => onSelectedStatus(null)}
          />
          <Text size="m" view="secondary">
            {listCount}
          </Text>
        </HorizontalContainer>
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.success')}
            checked={selectedStatus === StatusColor.SUCCESS}
            onChange={() => onSelectedStatus(StatusColor.SUCCESS)}
          />
          <Text size="m" view="secondary">
            {successMachineNumber}
          </Text>
        </HorizontalContainer>
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.warning')}
            checked={selectedStatus === StatusColor.WARNING}
            onChange={() => onSelectedStatus(StatusColor.WARNING)}
          />
          <Text size="m" view="secondary">
            {warningMachineNumber}
          </Text>
        </HorizontalContainer>
        <HorizontalContainer space="auto">
          <Radio
            label={t('machineControl.machine.list.filter.status.item.alert')}
            checked={selectedStatus === StatusColor.ERROR}
            onChange={() => onSelectedStatus(StatusColor.ERROR)}
          />
          <Text size="m" view="secondary">
            {alertMachineNumber}
          </Text>
        </HorizontalContainer>
      </VerticalContainer>
    </VerticalContainer>
  );

  return (
    <FilterButton
      size={size}
      label={t('machineControl.machine.list.filter.status.filterButton.label')}
      modalChildren={renderStatusFilter}
    />
  );
};

export default StatusFilter;
