import React, { FC, useEffect, useMemo, useState } from 'react';
import VerticalContainer from '../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconProfileAccepted } from '../../../../assets/icon/iconProfileAccepted';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  toggleKeyAction,
  getEmployeeWithKeyListAction,
} from '../../../../state/organization/actions';
import TableCardWithBackground from '../../../../components/TableCardWithBackground';
import { useTranslation } from 'react-i18next';
import { IconQR } from '../../../../assets/icon/iconQR';
import { IconLock } from '../../../../assets/icon/iconLock';
import { IconUnlock } from '../../../../assets/icon/iconUnlock';
import { IconEdit2 } from '../../../../assets/icon/iconEdit2';
import { useNavigate } from 'react-router-dom';
import AddKeys from './AddKeys';
import { selectEmployeeWithKesList } from '../../../../state/organization/selectors';
import QrKeyModal from '../QrKeyModal';
import { EmployeeWithKeyDTO } from '../../../../types/serverInterface/employeeWithKeyDTO';

/**
 * Свойства компонента KeysTable
 */
type KeysTableProps = {
  /**
   * id организации
   */
  organizationId: number;
};

/**
 * Таблица ключей доступов к автоматам
 */
const KeysTable: FC<KeysTableProps> = ({ organizationId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const keysList = useAppSelector(selectEmployeeWithKesList());

  const [searchQuery, setSearchQuery] = useState('');
  const [isAddKeyOpen, setIsAddKeyOen] = useState(false);
  const [isOpenQrUuid, setIsOpenQrUuid] = useState<string | null>(null);

  const filterKeysList = useMemo(() => {
    return keysList.filter(({ name, surname, patronymic }) =>
      `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, keysList]);

  const keysMapByUuid = useMemo(() => {
    const map: Record<string, EmployeeWithKeyDTO> = {};

    keysList.forEach((key) => {
      map[key.uuid] = key;
    });

    return map;
  }, [keysList]);

  const selectedKey = isOpenQrUuid && keysMapByUuid[isOpenQrUuid];

  useEffect(() => {
    dispatch(getEmployeeWithKeyListAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleBlockClick = (uuid: string, key: string) => {
    dispatch(toggleKeyAction(key, organizationId));
  };

  const handleAddClose = () => {
    setIsAddKeyOen(false);
  };

  const handleQrModalOpen = ({ uuid }: EmployeeWithKeyDTO) => {
    setIsOpenQrUuid(uuid);
  };

  const handleQrModalClose = () => {
    dispatch(getEmployeeWithKeyListAction(organizationId)).then(() => {
      setIsOpenQrUuid(null);
    });
  };

  // render методы
  const renderTableActions = () => (
    <HorizontalContainer space="xl">
      <Button
        label="Добавить"
        iconLeft={IconProfileAccepted as any}
        onClick={() => setIsAddKeyOen(true)}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer space="3xl">
      <AddKeys isOpen={isAddKeyOpen} organizationId={organizationId} onClose={handleAddClose} />
      {selectedKey && (
        <QrKeyModal
          isOpen={!!selectedKey}
          organizationId={organizationId}
          userUuid={selectedKey.uuid}
          isActive={selectedKey.isActiveKey}
          userName={
            selectedKey
              ? `${selectedKey.surname ? selectedKey.surname : ''} ${
                  selectedKey.name ? selectedKey.name : ''
                } ${selectedKey.patronymic ? selectedKey.patronymic : ''}`
              : ''
          }
          onClose={handleQrModalClose}
        />
      )}
      {renderTableActions()}
      <TableCardWithBackground
        rows={filterKeysList}
        // 1400
        columns={{
          // 80
          key: {
            key: 'key',
            title: 'Ключ',
            alignment: 'left',
            width: '6%',
            renderCell: (data) => (
              <Button
                view="clear"
                size="m"
                onlyIcon
                iconLeft={IconQR as any}
                onClick={(e) => {
                  e.stopPropagation();
                  handleQrModalOpen(data);
                }}
              />
            ),
          },
          // 400
          name: {
            key: 'name',
            title: 'ФИО',
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ name, surname, patronymic }) =>
              `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`,
          },
          // 400
          position: {
            key: 'position',
            title: 'Должность',
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ position }) => t(`roles.${position}`),
          },
          // 375
          lastActivity: {
            key: 'lastActivity',
            title: 'Последняя активность',
            alignment: 'left',
            width: '26%',
            getItemLabel: ({ lastActivity }) => lastActivity,
          },
          // 144
          uuid: {
            key: 'uuid',
            title: '',
            alignment: 'left',
            width: '10%',
            renderCell: ({ isActiveKey, uuid, key }) => (
              <HorizontalContainer space="s">
                <Button onlyIcon view="clear" size="m" iconLeft={IconEdit2 as any} />
                <Button
                  onlyIcon
                  iconLeft={(isActiveKey ? IconUnlock : IconLock) as any}
                  view="clear"
                  size="m"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBlockClick(uuid, key);
                  }}
                />
              </HorizontalContainer>
            ),
          },
        }}
        onRowClick={({ uuid }) => {
          navigate(`/organization/members/memberWithKey/${uuid}`);
        }}
      />
    </VerticalContainer>
  );
};

export default KeysTable;
