import { FC } from 'react';
import { ProductDetailsDTO } from '../../../../../types/serverInterface/productDTO';
import CustomFormInfo from '../../../../../components/CustomForm/CustomFormInfo';

/**
 * Свойства компонента ProductCalibrationInfo
 */
type ProductCalibrationInfoProps = {
  /**
   * Продукт
   */
  product: ProductDetailsDTO;
};

/**
 * Информация о калибровке продукта
 */
const ProductCalibrationInfo: FC<ProductCalibrationInfoProps> = ({ product }) => {
  const { categoryConfigMachine, purposeConfigMachine } = product;

  return (
    <CustomFormInfo config={[...(categoryConfigMachine || []), ...(purposeConfigMachine || [])]} />
  );
};

export default ProductCalibrationInfo;
