import { FC } from 'react';
import { CreateDisposableModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import DisposableCellModelInfo from './DisposableCellModelInfo';
import { cellSortFunc } from '../helpers';

/**
 * Свойства компонента DisposableCellsModelInfo
 */
type DisposableCellsModelInfoProps = {
  /**
   * Ячейки расходников
   */
  cells: CreateDisposableModelCell[];
};

/**
 * Информация о ячейках расходников модели автомата
 */
const DisposableCellsModelInfo: FC<DisposableCellsModelInfoProps> = ({ cells }) => {
  const sortedCells = [...cells].sort(cellSortFunc);

  // render методы
  const renderCell = (cell: CreateDisposableModelCell, index: number) => (
    <DisposableCellModelInfo key={index} cell={cell} />
  );

  return <VerticalContainer>{sortedCells.map(renderCell)}</VerticalContainer>;
};

export default DisposableCellsModelInfo;
