import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectCellViewLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getSportPitLocaleByIdAction,
  getTasteLocaleByIdAction,
} from '../../../../state/productBase/actions';
import { LocaleNameList } from '../../../../components/locale/NameLocale/types';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { Loader } from '@consta/uikit/Loader';
import NameLocale from '../../../../components/locale/NameLocale';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента SportPitLocale
 */
type SportPitLocaleProps = {
  /**
   * Флаг открытия локализации вида спортивного питания
   */
  isOpen: boolean;
  /**
   * id вида спортивного питания
   */
  sportPitId: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Компонент локализации вида спортивного питания
 */
const SportPitLocale: FC<SportPitLocaleProps> = ({ isOpen, sportPitId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectCellViewLocale());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getSportPitLocaleByIdAction(sportPitId));
  }, [dispatch, sportPitId]);

  // Обработчики
  const handleSubmit = (data: LocaleNameList) => {
    locale &&
      dispatch(
        editLocaleAction({
          ...locale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() =>
        dispatch(getTasteLocaleByIdAction(sportPitId)).then(() => {
          handleEditClose();
        }),
      );
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleClose = onClose;

  if (!locale) return <Loader />;

  return (
    <NameLocale
      isOpen={isOpen}
      isEdit={isEdit}
      locale={locale}
      modalTitle={t('productBaseConfig.sportPit.locale.modal.title')}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export default SportPitLocale;
