export enum LanguageEnum {
  ru = 'ru',
  en = 'en',
  pl = 'pl', // Польша
  es = 'es', // Испания
  de = 'de', // Германия
  ar = 'ar', // Арабский
  it = 'it', // Италия
  ro = 'ro', // Румыния
  ua = 'ua', // Украина
  no = 'no', // Норвегия
  hu = 'hu', // Венгрия
  se = 'se', // Швеция
  cs = 'cs', // Чехия
  lv = 'lv', // Латвия
  et = 'et', // Эстония
}

export function enumToArray(enumObject: any): string[] {
  return Object.keys(enumObject).map((key) => enumObject[key]);
}
