import { FC, useEffect } from 'react';
import { IngredientInfo } from '../../../../../../types/serverInterface/recipeDTO';
import { useAppDispatch } from '../../../../../../app/hooks/store';
import {
  getCellCategoryListAction,
  getCellPurposeListAction,
  getTasteListAction,
} from '../../../../../../state/productBase/actions';
import RecipeIngredientInfo from './RecipeIngredientInfo';
import StepBadge from '../../../../../../components/StepBadge';
import styles from './RecipeIngredientsStepInfo.module.scss';

/**
 * Свойства компонента RecipeIngredientsStepInfo
 */
type RecipeIngredientInfoItemProps = {
  /**
   * Список ингредиентов шага
   */
  ingredients: IngredientInfo[];
  /**
   * Индекс шага
   */
  index: number;
};

/**
 * Шаг в информации о ингредиентах рецепта
 */
const RecipeIngredientsStepInfo: FC<RecipeIngredientInfoItemProps> = ({ ingredients, index }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCellCategoryListAction());
    dispatch(getCellPurposeListAction());
    dispatch(getTasteListAction());
  }, [dispatch]);

  // render методы
  const renderIngredient = (ingredient: IngredientInfo, index: number) => (
    <RecipeIngredientInfo key={index} ingredient={ingredient} />
  );

  const renderIngredientsList = () => (
    <div className={styles.tagsList}>{ingredients.map(renderIngredient)}</div>
  );

  return (
    <div className={styles.itemCard}>
      <div>
        <StepBadge stepNumber={index + 1} />
      </div>
      {renderIngredientsList()}
    </div>
  );
};

export default RecipeIngredientsStepInfo;
