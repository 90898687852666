import { IconSettings } from '@consta/uikit/IconSettings';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconRevert } from '@consta/uikit/IconRevert';
import { IconUpload } from '@consta/uikit/IconUpload';
import { IconAddToComparison } from '@consta/uikit/IconAddToComparison';
import { IconSpeed } from '@consta/uikit/IconSpeed';
import { IconCalendar } from '@consta/uikit/IconCalendar';
import { IconCamera } from '@consta/uikit/IconCamera';
import { IconCrown } from '@consta/uikit/IconCrown';
import { IconRestart } from '@consta/uikit/IconRestart';
import { IconDrop } from '@consta/uikit/IconDrop';
import { IconSnowflake } from '@consta/uikit/IconSnowflake';
import { IconDownload } from '@consta/uikit/IconDownload';

/**
 * Иконки
 */
export enum Icons {
  IconAdd = 'IconAdd',
  IconSettings = 'IconSettings',
  IconRevert = 'IconRevert',
  IconUpload = 'IconUpload',
  IconAddToComparison = 'IconAddToComparison',
  IconCalendar = 'IconCalendar',
  IconCamera = 'IconCamera',
  IconCrown = 'IconCrown',
  IconSpeed = 'IconSpeed',
  IconRestart = 'IconRestart',
  IconDrop = 'IconDrop',
  IconSnowflake = 'IconSnowflake',
  IconDownload = 'IconDownload',
}

/**
 * Получение иконки по её названию
 *
 * @param iconName название иконки
 */
export function getIconByName(iconName: Icons) {
  switch (iconName) {
    case Icons.IconSettings:
      return IconSettings;
    case Icons.IconAdd:
      return IconAdd;
    case Icons.IconRevert:
      return IconRevert;
    case Icons.IconUpload:
      return IconUpload;
    case Icons.IconAddToComparison:
      return IconAddToComparison;
    case Icons.IconSpeed:
      return IconSpeed;
    case Icons.IconCalendar:
      return IconCalendar;
    case Icons.IconCamera:
      return IconCamera;
    case Icons.IconCrown:
      return IconCrown;
    case Icons.IconRestart:
      return IconRestart;
    case Icons.IconDrop:
      return IconDrop;
    case Icons.IconSnowflake:
      return IconSnowflake;
    case Icons.IconDownload:
      return IconDownload;
    default:
      return null;
  }
}
