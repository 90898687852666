import React, { FC } from 'react';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import StatusFilter from '../../../../components/Machine/StatusFilter';
import { StatusColor } from '../../../../types/serverInterface/machineDTO';
import SalesFilter from '../../../../components/Machine/SalesFilter';
import {
  MachineListFiltersModelInfo,
  SalesFilters,
} from '../../../../types/serverInterface/machineListDTO';
import ModelFilter from '../../../../components/Machine/ModelFilter';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента OutletListHeader
 */
type OutletListActionsProps = {
  /**
   * Список моделей автомат
   */
  modelList: MachineListFiltersModelInfo[] | null;
  /**
   * Фильтр списка выбранных моделей автомата
   */
  selectedModelId: number[] | null;
  /**
   * Выбранный тип продажи
   */
  selectedSaleType: SalesFilters;
  /**
   * Поле поиска
   */
  searchQuery: string | null;
  /**
   * Количество автоматов
   */
  listCount: number;
  /**
   * Количество автоматов в статусе success
   */
  successCount: number;
  /**
   * Количество автоматов в статусе warning
   */
  warningCount: number;
  /**
   * Количество автоматов в статусе alert
   */
  alertCount: number;
  /**
   * Выбранный статус
   */
  selectedStatus: StatusColor | null;
  /**
   * Обработчик выбора статуса
   *
   * @param status выбранный статус
   */
  onSelectedStatus: (status: StatusColor | null) => void;
  /**
   * Обработчик изменения поле поиска
   *
   * @param value значение поля
   */
  onSearchChange: ({ value }: { value: string | null }) => void;
  /**
   * Обработчик открытия создания
   */
  onCreateOpen: () => void;
  /**
   * Обработчик изменения типа продажи
   *
   * @param saleType тип продажи
   */
  onSaleTypeChange: (saleType: SalesFilters) => void;
  /**
   * Обработчик выбранных модели автомата
   *
   * @param modelIdList список выбранных моделей автомата
   */
  onSelectModelId: (modelIdList: number[] | null) => void;
};

/**
 * Шапка таблицы списка торговых точек
 */
const OutletListHeader: FC<OutletListActionsProps> = ({
  onSaleTypeChange,
  selectedSaleType,
  listCount,
  successCount,
  warningCount,
  alertCount,
  onSelectedStatus,
  selectedStatus,
  onSearchChange,
  searchQuery,
  onCreateOpen,
  onSelectModelId,
  selectedModelId,
  modelList,
}) => {
  const { t } = useTranslation();

  return (
    <HorizontalContainer>
      <Button
        size="l"
        label={t('outlet.list.header.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={onCreateOpen}
      />
      <TextField
        size="l"
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={onSearchChange}
      />
      <SalesFilter
        size="l"
        selectedSaleType={selectedSaleType}
        onSaleTypeChange={onSaleTypeChange}
      />
      <ModelFilter
        size="l"
        modelList={modelList}
        selectedModelId={selectedModelId}
        onSelectModelId={onSelectModelId}
      />
      <StatusFilter
        size="l"
        listCount={listCount}
        successMachineNumber={successCount}
        warningMachineNumber={warningCount}
        alertMachineNumber={alertCount}
        selectedStatus={selectedStatus}
        onSelectedStatus={onSelectedStatus}
      />
    </HorizontalContainer>
  );
};

export default OutletListHeader;
