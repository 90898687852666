import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconTrashSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M5.9837 0C4.51951 0 3.33255 1.18696 3.33255 2.65115V2.79069H1.5186C1.28741 2.79069 1.1 2.9781 1.1 3.20929C1.1 3.44048 1.28741 3.62789 1.5186 3.62789H10.4488C10.68 3.62789 10.8674 3.44048 10.8674 3.20929C10.8674 2.9781 10.68 2.79069 10.4488 2.79069H8.63485V2.65115C8.63485 1.18696 7.44789 0 5.9837 0ZM5.9837 0.837206C6.98552 0.837206 7.79765 1.64934 7.79765 2.65115V2.79069H4.16975V2.65115C4.16975 1.64934 4.98189 0.837206 5.9837 0.837206Z" />
    <path d="M2.49347 4.28597C2.4716 4.05582 2.2673 3.88697 2.03715 3.90884C1.80699 3.93071 1.63815 4.13501 1.66001 4.36516C1.71274 4.9201 1.8079 5.60387 1.93022 6.4828L2.08736 7.61196C2.23759 8.69181 2.32279 9.30421 2.50712 9.8059C2.85015 10.7396 3.46207 11.488 4.24904 11.8201C4.67639 12.0005 5.16521 12.0003 5.89291 12H6.07449C6.8022 12.0003 7.29101 12.0005 7.71836 11.8201C8.50534 11.488 9.11725 10.7396 9.46029 9.8059C9.64461 9.30421 9.72981 8.6918 9.88005 7.61193L10.0372 6.48281C10.1595 5.60388 10.2547 4.9201 10.3074 4.36516C10.3293 4.13501 10.1604 3.93071 9.93026 3.90884C9.70011 3.88697 9.4958 4.05582 9.47394 4.28597C9.42313 4.82064 9.33056 5.4865 9.20659 6.37734L9.06017 7.42944C8.89801 8.59462 8.82375 9.11079 8.67444 9.51718C8.3865 10.3009 7.90772 10.8315 7.39287 11.0488C7.14159 11.1548 6.83719 11.1627 5.9837 11.1627C5.13022 11.1627 4.82581 11.1548 4.57454 11.0488C4.05969 10.8315 3.5809 10.3009 3.29296 9.51717C3.14365 9.11079 3.06939 8.59462 2.90724 7.42945L2.76082 6.37734C2.63684 5.48651 2.54427 4.82065 2.49347 4.28597Z" />
    <path d="M5.28603 4.8837C5.28603 4.65251 5.09861 4.4651 4.86743 4.4651C4.63624 4.4651 4.44882 4.65251 4.44882 4.8837V9.3488C4.44882 9.57999 4.63624 9.7674 4.86743 9.7674C5.09861 9.7674 5.28603 9.57999 5.28603 9.3488V4.8837Z" />
    <path d="M7.51858 4.8837C7.51858 4.65251 7.33116 4.4651 7.09998 4.4651C6.86879 4.4651 6.68137 4.65251 6.68137 4.8837V9.3488C6.68137 9.57999 6.86879 9.7674 7.09998 9.7674C7.33116 9.7674 7.51858 9.57999 7.51858 9.3488V4.8837Z" />
  </svg>
);

const IconTrashSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M8 0C5.90132 0 4.2 1.58262 4.2 3.53487V3.72092H1.6C1.26863 3.72092 1 3.9708 1 4.27905C1 4.5873 1.26863 4.83719 1.6 4.83719H14.4C14.7314 4.83719 15 4.5873 15 4.27905C15 3.9708 14.7314 3.72092 14.4 3.72092H11.8V3.53487C11.8 1.58262 10.0987 0 8 0ZM8 1.11627C9.43594 1.11627 10.6 2.19912 10.6 3.53487V3.72092H5.4V3.53487C5.4 2.19912 6.56406 1.11627 8 1.11627Z" />
    <path d="M2.99731 5.71463C2.96597 5.40776 2.67313 5.18263 2.34325 5.21178C2.01336 5.24094 1.77135 5.51334 1.80269 5.82021C1.87827 6.56014 2.01466 7.47183 2.18998 8.64373L2.41522 10.1493C2.63056 11.5891 2.75268 12.4056 3.01688 13.0745C3.50856 14.3194 4.38564 15.3174 5.51365 15.7602C6.12618 16.0006 6.82682 16.0004 7.86986 16H8.13014C9.17318 16.0004 9.87382 16.0006 10.4864 15.7602C11.6144 15.3174 12.4914 14.3194 12.9831 13.0745C13.2473 12.4056 13.3694 11.5891 13.5848 10.1492L13.81 8.64374C13.9853 7.47184 14.1217 6.56014 14.1973 5.82021C14.2287 5.51334 13.9866 5.24094 13.6568 5.21178C13.3269 5.18263 13.034 5.40776 13.0027 5.71463C12.9299 6.42753 12.7972 7.31534 12.6195 8.50312L12.4096 9.90592C12.1772 11.4595 12.0708 12.1477 11.8567 12.6896C11.444 13.7345 10.7578 14.442 10.0198 14.7317C9.65965 14.8731 9.22333 14.8837 8 14.8837C6.77667 14.8837 6.34035 14.8731 5.98019 14.7317C5.24224 14.442 4.55597 13.7345 4.14326 12.6896C3.92925 12.1477 3.8228 11.4595 3.59038 9.90593L3.38051 8.50312C3.20281 7.31534 3.07013 6.42753 2.99731 5.71463Z" />
    <path d="M7 6.5116C7 6.20335 6.73137 5.95346 6.4 5.95346C6.06863 5.95346 5.8 6.20335 5.8 6.5116V12.4651C5.8 12.7733 6.06863 13.0232 6.4 13.0232C6.73137 13.0232 7 12.7733 7 12.4651V6.5116Z" />
    <path d="M10.2 6.5116C10.2 6.20335 9.93137 5.95346 9.6 5.95346C9.26863 5.95346 9 6.20335 9 6.5116V12.4651C9 12.7733 9.26863 13.0232 9.6 13.0232C9.93137 13.0232 10.2 12.7733 10.2 12.4651V6.5116Z" />
  </svg>
);

const IconTrashSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12 0C9.00188 0 6.57143 2.37392 6.57143 5.3023V5.58137H2.85714C2.38376 5.58137 2 5.9562 2 6.41858C2 6.88096 2.38376 7.25578 2.85714 7.25578H21.1429C21.6162 7.25578 22 6.88096 22 6.41858C22 5.9562 21.6162 5.58137 21.1429 5.58137H17.4286V5.3023C17.4286 2.37392 14.9981 0 12 0ZM12 1.67441C14.0513 1.67441 15.7143 3.29867 15.7143 5.3023V5.58137H8.28571V5.3023C8.28571 3.29867 9.94866 1.67441 12 1.67441Z" />
    <path d="M4.8533 8.57194C4.80852 8.11164 4.39019 7.77394 3.91893 7.81768C3.44766 7.86141 3.10192 8.27001 3.1467 8.73032C3.25467 9.84021 3.44952 11.2077 3.69998 12.9656L4.02174 15.2239C4.32936 17.3836 4.50382 18.6084 4.88125 19.6118C5.58366 21.4791 6.83663 22.9761 8.44806 23.6403C9.32312 24.0009 10.324 24.0005 11.8141 24H12.1859C13.676 24.0005 14.6769 24.0009 15.5519 23.6403C17.1634 22.9761 18.4163 21.4791 19.1187 19.6118C19.4962 18.6084 19.6706 17.3836 19.9783 15.2239L20.3 12.9656C20.5505 11.2078 20.7453 9.84021 20.8533 8.73032C20.8981 8.27001 20.5523 7.86141 20.0811 7.81768C19.6098 7.77394 19.1915 8.11164 19.1467 8.57194C19.0427 9.64129 18.8531 10.973 18.5993 12.7547L18.2995 14.8589C17.9674 17.1892 17.8154 18.2216 17.5096 19.0344C16.92 20.6018 15.9397 21.6631 14.8854 22.0976C14.3709 22.3097 13.7476 22.3255 12 22.3255C10.2524 22.3255 9.62907 22.3097 9.11456 22.0976C8.06034 21.6631 7.07996 20.6018 6.49037 19.0343C6.18464 18.2216 6.03258 17.1892 5.70055 14.8589L5.40073 12.7547C5.14688 10.973 4.95732 9.64129 4.8533 8.57194Z" />
    <path d="M10.5714 9.7674C10.5714 9.30503 10.1877 8.9302 9.71429 8.9302C9.2409 8.9302 8.85714 9.30503 8.85714 9.7674V18.6976C8.85714 19.16 9.2409 19.5348 9.71429 19.5348C10.1877 19.5348 10.5714 19.16 10.5714 18.6976V9.7674Z" />
    <path d="M15.1429 9.7674C15.1429 9.30503 14.7591 8.9302 14.2857 8.9302C13.8123 8.9302 13.4286 9.30503 13.4286 9.7674V18.6976C13.4286 19.16 13.8123 19.5348 14.2857 19.5348C14.7591 19.5348 15.1429 19.16 15.1429 18.6976V9.7674Z" />
  </svg>
);

export const IconTrash = createIcon({
  name: 'IconTrash',
  xs: IconTrashSizeXS,
  s: IconTrashSizeS,
  m: IconTrashSizeM,
});
