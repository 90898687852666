import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import OrganizationsList from './OrganizationsList';
import OrganizationMembers from './OrganizationMembers';

const OrganizationPage: FC = () => {
  return (
    <Routes>
      <Route path="/list/*" element={<OrganizationsList />} />
      <Route path="/members/*" element={<OrganizationMembers />} />
    </Routes>
  );
};

export default OrganizationPage;
