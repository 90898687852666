import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@consta/uikit/TextField';
import { CreateOrganizationBasic } from '../../../../types/serverInterface/organizationDTO';
import { OrganizationFormProps } from './types';

/**
 * Форма базовой информации об организации
 */
const OrganizationBasicForm: FC<OrganizationFormProps> = ({ value, onChange }) => {
  const { t } = useTranslation();

  const { name, description } = value;

  // Обработчики
  const handleChange =
    (key: keyof CreateOrganizationBasic) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  return (
    <>
      <TextField
        label={t('organization.create.basic.organizationName.input.label')}
        name="name"
        width="full"
        size="l"
        placeholder={t('organization.create.basic.organizationName.input.placeholder')}
        value={name}
        onChange={handleChange('name')}
      />
      <TextField
        label={t('organization.create.basic.description.input.label')}
        name="description"
        width="full"
        size="l"
        type="textarea"
        rows={3}
        placeholder={t('organization.create.basic.description.input.placeholder')}
        value={description}
        onChange={handleChange('description')}
      />
    </>
  );
};

export default OrganizationBasicForm;
