import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './ProductBaseList.module.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { getBrandListAction } from '../../../state/productBase/actions';
import { selectOrganizationId } from '../../../state/organization/selectors';
import { selectBrandList } from '../../../state/productBase/selectors';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import BrandForm from '../Brand/BrandForm';
import Brand from '../Brand';
import { useRoleChecked } from '../../../helpers/roleChecked';
import { Roles } from '../../../types/serverInterface/cabinetDTO';

/**
 * Базовая страница списка базы товаров(список брендов)
 */
const ProductBaseList: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const organizationId = useAppSelector(selectOrganizationId());
  const brandList = useAppSelector(selectBrandList());
  const isShowEdit = useRoleChecked([Roles.MANUFACTURER, Roles.DEVELOPER]);

  const isAllowedGlobalEdit = useRoleChecked([Roles.MANUFACTURER, Roles.DEVELOPER]);

  const [searchQuery, setSearchQuery] = useState('');
  const [isBrandCreateModalOpen, setIsBrandCreateModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getBrandListAction({ organizationId: organizationId || null }));
  }, [dispatch, organizationId]);

  const filterBrandList = useMemo(() => {
    return brandList.filter((brand) =>
      brand.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [brandList, searchQuery]);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleAddBrandButtonClick = () => {
    setIsBrandCreateModalOpen(true);
  };

  const handleAddBrandFormClose = () => {
    setIsBrandCreateModalOpen(false);
  };

  // render методы
  const renderList = () => (
    <div className={styles.table}>
      {isShowEdit && (
        <Button
          className={styles.addButton}
          view="ghost"
          label={t('productBase.add.brand.button.label')}
          iconLeft={IconAdd as any}
          onClick={handleAddBrandButtonClick}
        />
      )}

      {filterBrandList.map((brand) => (
        <Brand key={brand.id} brand={brand} isAllowedGlobalEdit={isAllowedGlobalEdit} />
      ))}
    </div>
  );

  const renderAction = () => (
    <div className={styles.actions}>
      <div className={styles.search}>
        <TextField
          width="full"
          form="round"
          placeholder={t('productBase.search.input.placeholder')}
          leftSide={IconSearch as any}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );

  const renderAddBrandForm = () => (
    <BrandForm type="create" isOpen={isBrandCreateModalOpen} onClose={handleAddBrandFormClose} />
  );

  return (
    <div className={styles.productBaseList}>
      {renderAction()}
      {renderList()}
      {renderAddBrandForm()}
    </div>
  );
};

export default ProductBaseList;
