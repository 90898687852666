import { FC, useEffect, useState } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import styles from './MachineListHeader.module.scss';
import ModelFilter from '../../../../components/Machine/ModelFilter';
import SalesFilter from '../../../../components/Machine/SalesFilter';
import StatusFilter from '../../../../components/Machine/StatusFilter';
import { StatusColor } from '../../../../types/serverInterface/machineDTO';
import { useTranslation } from 'react-i18next';
import MachineRegistrationModal from './MachineRegistrationModal';
import {
  MachineListFiltersModelInfo,
  SalesFilters,
  ShutdownReasonFilters,
} from '../../../../types/serverInterface/machineListDTO';
import useDebounce from '../../../../hoooks/useDebounce';
import { IconAdd } from '../../../../assets/icon/iconAdd';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import ShutdownFilter from '../../../../components/Machine/ShutdownFilter';

type MachineListHeaderProps = {
  /**
   * Фильтр архивных автоматов
   */
  isArchived: boolean;
  /**
   * Значения поля поиска
   */
  searchText: string;
  /**
   * Обработчик изменения поля поиска
   *
   * @param value новое значение поля поиска
   */
  onSearchChange: (value: string) => void;
  /**
   * Количество автоматов
   */
  listCount: number;
  /**
   * Количество автоматов в статусе success
   */
  successCount: number;
  /**
   * Количестов автоматов в статусе warning
   */
  warningCount: number;
  /**
   * Количество автоматов в статусе alert
   */
  alertCount: number;
  /**
   * Фильтр списка выбранных моделей автомата
   */
  selectedModelId: number[] | null;
  /**
   * Выбранный статус
   */
  selectedStatus: StatusColor | null;
  /**
   * Выбранный период продажи
   */
  selectedSaleType: SalesFilters;
  /**
   * Список моделей автомат
   */
  modelList: MachineListFiltersModelInfo[] | null;
  /**
   * Количество автоматов на складе
   */
  inStockNumber: number;
  /**
   * Количество утилизированных автоматов
   */
  scrapNumber: number;
  /**
   * Количество проданных автоматов
   */
  soldNumber: number;
  /**
   * Количество автоматов на ремонте
   */
  repairNumber: number;
  /**
   * Выбранный статус
   */
  selectedShutdownStatus: ShutdownReasonFilters | null;
  /**
   * Обработчик изменения фильтра причины отключения автомата
   *
   * @param status обновлённый фильтр
   */
  onSelectShutdownStatus: (status: ShutdownReasonFilters | null) => void;
  /**
   * Обработчик выбранных модели автомата
   *
   * @param modelIdList список выбранных моделей автомата
   */
  onSelectModelId: (modelIdList: number[] | null) => void;
  /**
   * Обработчик выбора статуса
   *
   * @param status выбранный статус
   */
  onSelectedStatus: (status: StatusColor | null) => void;
  /**
   * Обработчик изменения периода продажи
   *
   * @param saleType обновлённый период продажи
   */
  onSaleTypeChange: (saleType: SalesFilters) => void;
};

/**
 * Шапка таблицы списка автоматов
 */
const MachineListHeader: FC<MachineListHeaderProps> = ({
  isArchived,
  searchText,
  onSearchChange,
  listCount,
  successCount,
  warningCount,
  alertCount,
  onSelectModelId,
  selectedModelId,
  modelList,
  selectedStatus,
  selectedShutdownStatus,
  inStockNumber,
  scrapNumber,
  soldNumber,
  repairNumber,
  onSelectShutdownStatus,
  onSelectedStatus,
  selectedSaleType,
  onSaleTypeChange,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState(searchText);
  const [isOpenKey, setIsOpenKey] = useState(false);

  // const debouncedSearch = searchValue;
  const debouncedSearch = useDebounce(searchValue, 1000);

  useEffect(() => {
    onSearchChange(debouncedSearch);
  }, [debouncedSearch]);

  // Обработчики
  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchValue(value || '');
  };

  const handleOpenKey = () => {
    setIsOpenKey(true);
  };

  const handleCloseKey = () => {
    setIsOpenKey(false);
  };

  return (
    <div className={styles.MachineListHeader}>
      <Button label="Добавить" iconLeft={IconAdd as any} onClick={handleOpenKey} />
      <MachineRegistrationModal isOpenKey={isOpenKey} onClose={handleCloseKey} />
      <TextField
        width="full"
        form="round"
        placeholder={t('machineControl.machine.list.search.input.placeholder')}
        leftSide={IconSearch as any}
        value={searchValue}
        onChange={handleSearchChange}
      />
      <ModelFilter
        modelList={modelList}
        selectedModelId={selectedModelId}
        onSelectModelId={onSelectModelId}
      />
      {!isArchived && (
        <SalesFilter selectedSaleType={selectedSaleType} onSaleTypeChange={onSaleTypeChange} />
      )}
      {!isArchived && (
        <StatusFilter
          listCount={listCount}
          successMachineNumber={successCount}
          alertMachineNumber={alertCount}
          warningMachineNumber={warningCount}
          onSelectedStatus={onSelectedStatus}
          selectedStatus={selectedStatus}
        />
      )}
      {isArchived && (
        <ShutdownFilter
          listCount={listCount}
          inStockNumber={inStockNumber}
          repairNumber={repairNumber}
          selectedShutdownStatus={selectedShutdownStatus}
          scrapNumber={scrapNumber}
          soldNumber={soldNumber}
          onSelectShutdownStatus={onSelectShutdownStatus}
        />
      )}
    </div>
  );
};

export default MachineListHeader;
