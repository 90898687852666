import { FieldError } from '../types/types';

/**
 * Ошибка обязательного поля ввода
 */
export const requiredFieldError: FieldError = {
  isError: true,
  status: 'alert',
  label: 'error.validate.required.error.label',
};

/**
 * Ошибка максимального минимального значений
 */
export const maxMinFieldError: FieldError = {
  isError: true,
  status: 'alert',
  label: 'error.validate.maxMin.error.label',
};

/**
 * Успешная валидация поля ввода
 */
export const successFieldValidate: FieldError = {
  isError: false,
  status: 'success',
  label: 'error.validate.success.label',
};

/**
 * Ошибка любых не англ. букв
 */
export const onlyEnglishLettersFieldError: FieldError = {
  isError: true,
  status: 'alert',
  label: 'error.validate.onlyEnglishLetters.error.label',
};

/**
 * Начально значение ошибки
 */
export const initialFieldError: FieldError = {
  isError: false,
  status: undefined,
  label: undefined,
};

/**
 * Валидация на обязательное поле
 *
 * @param value значение поля
 * @param setIsValid функция записи правильности поля ввода
 * @param nextError ошибка дополнительной функции валидации
 */
export const requiredValidate = <T extends string | number | Date | boolean | null | undefined>(
  value: T,
  setIsValid?: (isValid: boolean) => void,
  nextError?: FieldError,
): FieldError => {
  if (value === null || value === '') {
    setIsValid && setIsValid(false);
    return requiredFieldError;
  }

  setIsValid && setIsValid(true);

  return nextError || successFieldValidate;
};

/**
 * Валидация на минимальное и максимальное значение.
 *
 * @param minValue минимальное значение
 * @param maxValue максимальное значение
 * @param value значение поля
 * @param setIsValid функция записи правильности поля ввода
 * @param nextError ошибка дополнительной функции валидации
 */
export const minMaxValidate = (
  maxValue: number | null,
  minValue: number | null,
  value: number,
  setIsValid?: (isValid: boolean) => void,
  nextError?: FieldError,
): FieldError => {
  const formattedValue = Number(value);

  const formattedMaxValue = maxValue || formattedValue;
  const formattedMinValue = minValue || formattedValue;

  if (formattedMaxValue < formattedValue || formattedValue < formattedMinValue) {
    setIsValid && setIsValid(false);

    return {
      ...maxMinFieldError,
      label: maxMinFieldError.label,
      optionsObj: {
        max: maxValue === null ? '-' : maxValue,
        min: minValue === null ? '-' : minValue,
      },
    };
  }

  setIsValid && setIsValid(true);
  return nextError || successFieldValidate;
};

/**
 * Валидация на английские буквы.
 *
 * @param value значение поля
 * @param setIsValid функция записи правильности поля ввода
 * @param nextError ошибка дополнительной функции валидации
 */
export const onlyEnglishLettersValidate = (
  value: string | null,
  setIsValid?: (isValid: boolean) => void,
  nextError?: FieldError,
): FieldError => {
  function isValidEnglishAlphanumeric(input: string): boolean {
    const regex = /^[A-Z0-9]+$/;
    return regex.test(input);
  }

  if (value === null || value === undefined || !isValidEnglishAlphanumeric(value)) {
    setIsValid && setIsValid(false);
    return onlyEnglishLettersFieldError;
  }

  setIsValid && setIsValid(true);
  return nextError || successFieldValidate;
};
