import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconShakerTagSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M6.27789 3.9781C6.13981 4.21724 6.22175 4.52304 6.4609 4.66111C6.70005 4.79918 7.00584 4.71724 7.14391 4.4781C7.28198 4.23895 7.20005 3.93315 6.9609 3.79508C6.72175 3.65701 6.41596 3.73895 6.27789 3.9781Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.40593 1.0537C6.26814 0.781683 6.79331 0.615997 7.26577 0.625378C8.25203 0.644962 9.15939 1.16883 9.66948 2.01316C9.91384 2.41763 10.0329 2.95528 10.2285 3.83797L10.254 3.95299C10.3306 4.29851 10.3777 4.5111 10.3926 4.72442C10.4235 5.16539 10.3523 5.60753 10.1845 6.01649C10.1033 6.21432 9.99178 6.40135 9.81052 6.70532L8.5269 8.85825C8.11836 9.54368 7.86905 9.96198 7.55267 10.2498C6.85362 10.8859 5.88662 11.1377 4.96613 10.9235C4.54953 10.8265 4.12786 10.583 3.43687 10.1839L3.30602 10.1083C2.61491 9.70947 2.19316 9.46606 1.90089 9.15376C1.25511 8.4637 0.989715 7.50034 1.19101 6.57693C1.28211 6.15901 1.5197 5.73396 1.90904 5.03743L3.13173 2.84932C3.30434 2.54036 3.41055 2.35025 3.54129 2.18104C3.81156 1.83125 4.15885 1.54848 4.55617 1.35473C4.74837 1.261 4.95605 1.19553 5.29358 1.08913L5.40593 1.0537ZM5.62837 3.6031C5.28319 4.20096 5.48803 4.96545 6.0859 5.31063C6.68376 5.65581 7.44825 5.45096 7.79343 4.8531C8.13861 4.25523 7.93376 3.49074 7.3359 3.14556C6.73803 2.80039 5.97355 3.00523 5.62837 3.6031Z"
    />
  </svg>
);

const IconShakerTagSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M8.37051 5.30417C8.18642 5.62303 8.29567 6.03076 8.61453 6.21485C8.93339 6.39895 9.34112 6.2897 9.52521 5.97083C9.70931 5.65197 9.60006 5.24425 9.2812 5.06015C8.96234 4.87606 8.55461 4.98531 8.37051 5.30417Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2079 1.40497C8.35751 1.04228 9.05774 0.82137 9.68769 0.833879C11.0027 0.859991 12.2125 1.55848 12.8926 2.68426C13.2184 3.22355 13.3772 3.94042 13.6379 5.11733L13.6719 5.27069C13.7741 5.73139 13.837 6.01485 13.8569 6.29927C13.898 6.88722 13.803 7.47675 13.5793 8.02203C13.4711 8.2858 13.3224 8.53518 13.0807 8.94047L11.3692 11.811C10.8245 12.725 10.4921 13.2827 10.0702 13.6665C9.13816 14.5145 7.84883 14.8503 6.62151 14.5647C6.06604 14.4354 5.50382 14.1107 4.58249 13.5786L4.40803 13.4778C3.48655 12.946 2.92422 12.6215 2.53452 12.205C1.67348 11.285 1.31962 10.0005 1.58801 8.76928C1.70948 8.21205 2.02627 7.64532 2.54539 6.71662L4.17564 3.79913C4.40578 3.38719 4.5474 3.13371 4.72171 2.9081C5.08208 2.4417 5.54513 2.06468 6.07489 1.80634C6.33116 1.68137 6.60807 1.59408 7.05811 1.45222L7.2079 1.40497ZM7.50449 4.80417C7.04425 5.60132 7.31738 6.62064 8.11453 7.08088C8.91169 7.54111 9.931 7.26799 10.3912 6.47083C10.8515 5.67368 10.5784 4.65436 9.7812 4.19413C8.98404 3.73389 7.96473 4.00701 7.50449 4.80417Z"
    />
  </svg>
);

const IconShakerTagSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12.5558 7.95619C12.2796 8.43448 12.4435 9.04607 12.9218 9.32222C13.4001 9.59836 14.0117 9.43448 14.2878 8.95619C14.564 8.4779 14.4001 7.86631 13.9218 7.59017C13.4435 7.31402 12.8319 7.4779 12.5558 7.95619Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8119 2.1074C12.5363 1.56337 13.5866 1.23199 14.5315 1.25076C16.5041 1.28992 18.3188 2.33765 19.339 4.02633C19.8277 4.83526 20.0659 5.91056 20.4569 7.67593L20.5079 7.90598C20.6611 8.59702 20.7554 9.02221 20.7853 9.44884C20.847 10.3308 20.7046 11.2151 20.369 12.033C20.2066 12.4286 19.9836 12.8027 19.621 13.4106L17.0538 17.7165C16.2367 19.0874 15.7381 19.924 15.1053 20.4997C13.7072 21.7717 11.7732 22.2754 9.93226 21.8469C9.09906 21.653 8.25573 21.166 6.87374 20.3678L6.61204 20.2167C5.22982 19.4189 4.38633 18.9321 3.80179 18.3075C2.51022 16.9274 1.97943 15.0007 2.38202 13.1539C2.56423 12.318 3.0394 11.4679 3.81809 10.0749L6.26346 5.69863C6.60868 5.08072 6.8211 4.70051 7.08257 4.36209C7.62311 3.66249 8.3177 3.09696 9.11234 2.70945C9.49673 2.522 9.9121 2.39107 10.5872 2.17826L10.8119 2.1074ZM11.2567 7.20619C10.5664 8.40192 10.9761 9.9309 12.1718 10.6213C13.3675 11.3116 14.8965 10.9019 15.5869 9.70619C16.2772 8.51046 15.8675 6.98148 14.6718 6.29113C13.4761 5.60077 11.9471 6.01046 11.2567 7.20619Z"
    />
  </svg>
);

export const IconShakerTag = createIcon({
  name: 'IconShakerTag',
  xs: IconShakerTagSizeXS,
  s: IconShakerTagSizeS,
  m: IconShakerTagSizeM,
});
