import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../app/api';
import { getAccessTokenPKCEThunk, getRegistrationInfoThunk } from './thunk';
import { ACCESS_TOKEN_STORAGE_NAME } from '../../consts';
import { RegistrationInfoDTO } from '../../types/serverInterface/authDTO';
import { errorHandler, NotificationType } from '../handlers';

export type AuthState = {
  isAuth: boolean;
  isCheckedKey: boolean;
  isInitReqExecute: boolean;
  registrationInfo: RegistrationInfoDTO | null;
  isRegistrationInfoLoading: boolean;
  notifications: NotificationType[];
};

const initialState: AuthState = {
  isAuth: false,
  isCheckedKey: false,
  isInitReqExecute: false,
  registrationInfo: null,
  isRegistrationInfoLoading: false,
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: AuthState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      api.clearTokens();
      checkToken();
      location.reload();
    },
    checkToken: (state) => {
      const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_NAME);

      if (!token) {
        state.isAuth = false;
        state.isInitReqExecute = false;
        state.isCheckedKey = true;
      }

      if (token) {
        state.isAuth = true;
        state.isInitReqExecute = true;
        state.isCheckedKey = true;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAccessTokenPKCEThunk.fulfilled, (state, actions) => {
      const token = actions.payload?.access_token;

      if (!token) {
        state.isAuth = false;
        state.isInitReqExecute = false;

        return localStorage.removeItem(ACCESS_TOKEN_STORAGE_NAME);
      }

      state.isAuth = true;
      state.isInitReqExecute = true;
      state.isCheckedKey = true;
      api.saveToken(token);
      // localStorage.setItem(ACCESS_TOKEN_STORAGE_NAME, token);
    });

    // getRegistrationInfo
    builder.addCase(getRegistrationInfoThunk.pending, (state) => {
      state.isRegistrationInfoLoading = true;
    });

    builder.addCase(getRegistrationInfoThunk.rejected, (state, action) => {
      state.isRegistrationInfoLoading = false;
      errorHandler(action)(addNotification(state));
    });

    builder.addCase(getRegistrationInfoThunk.fulfilled, (state, action) => {
      state.isRegistrationInfoLoading = false;
      state.registrationInfo = action.payload;
    });
  },
});

export const { logout, checkToken } = authSlice.actions;

export const authReducer = authSlice.reducer;
