import { FC, useState } from 'react';
import { CustomFormItem, CustomFormType } from '../../types/serverInterface/customForm';
import EditCustomFormItem from './EditCustomFormItem';
import styles from './EditCustomForm.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { ErrorCustomForm, errorInitialValue, ErrorsCustomFormList } from './customFormValidation';
import EditCustomFormLocale from './EditCustomFormLocale';
import { LocaleNameDescriptionList } from '../locale/NameDescriptionLocale/types';

/**
 * Дефолтное значение элемента кастомной формы
 */
const initialValue: CustomFormItem = {
  name: '',
  key: '',
  description: '',
  value: null,
  type: null,
  locale: [],
};

/**
 * Свойства компонента EditCustomForm
 */
type EditCustomFormProps = {
  /**
   * Кастомная форма
   */
  config: CustomFormType;
  /**
   * Ошибки кастомной формы
   */
  errors: ErrorsCustomFormList | null;
  /**
   * Обработчик изменения в кастомной форме
   *
   * @param data изменённые данные в кастомной форме
   * @param error ошибки в кастомной форме
   */
  onChange: (data: CustomFormType) => (errors: ErrorsCustomFormList | null) => void;
};

/**
 * Компонент кастомной формы
 */
const EditCustomForm: FC<EditCustomFormProps> = ({ config, onChange, errors }) => {
  const [editableLocaleId, setEditableLocaleId] = useState<number | null>(null);

  const isOpenLocale = editableLocaleId !== null;

  // Вспомогательные методы
  const getLocaleByFieldIndex = (index: number | null) => {
    if (index !== null && config.length > index) {
      return config[index].locale;
    }

    return [];
  };

  // Обработчики
  const handleAddClick = () => {
    const editableConfig = [...(config || [])] || [];

    editableConfig.push(initialValue);

    onChange(editableConfig)(errors);
  };

  const handleDeleteClick = (index: number) => () => {
    const editableConfig = [...config];

    if (index < editableConfig.length) {
      onChange([...editableConfig.slice(0, index), ...editableConfig.slice(index + 1)])(
        errors && [...errors.slice(0, index), ...errors.slice(index + 1)],
      );
    }
  };

  const handleLocaleClick = (index: number) => () => {
    setEditableLocaleId(index);
  };

  const handleLocaleClose = () => {
    setEditableLocaleId(null);
  };

  const handleChange = (index: number) => (key: keyof CustomFormItem) => (value: string | null) => {
    const editableConfig = [...config];
    const editableErrors = errors && [...errors];

    if (editableConfig.length > index) {
      editableConfig[index] = { ...editableConfig[index], [key]: value };

      if (editableErrors && editableErrors?.length > index) {
        editableErrors[index] = { ...editableErrors[index], [key]: errorInitialValue };
        onChange(editableConfig)(editableErrors);
      } else {
        onChange(editableConfig)(editableErrors);
      }
    }
  };

  const handleFullItemChange =
    (index: number) => (value: CustomFormItem) => (error: ErrorCustomForm | null) => {
      const editableConfig = [...config];
      const editableErrors = errors && [...errors];

      if (editableConfig.length > index && value) {
        editableConfig[index] = value;

        if (editableErrors && editableErrors.length > index && error) {
          editableErrors[index] = error;
          onChange(editableConfig)(editableErrors);
        } else {
          onChange(editableConfig)(null);
        }
      }
    };

  const handleLocaleSubmit = (data: LocaleNameDescriptionList) => {
    const editableConfig = [...config];

    if (isOpenLocale && editableConfig.length > editableLocaleId) {
      editableConfig[editableLocaleId] = { ...editableConfig[editableLocaleId], locale: data };

      onChange(editableConfig)(errors);
    }
  };

  // render методы
  const renderFormItem = (formItem: CustomFormItem, index: number) => (
    <EditCustomFormItem
      key={index}
      error={errors ? errors?.[index] || null : null}
      formItem={formItem}
      onChange={handleChange(index)}
      onFullItemChange={handleFullItemChange(index)}
      onDeleteClick={handleDeleteClick(index)}
      onLocaleClick={handleLocaleClick(index)}
    />
  );

  const renderContent = () => (
    <div className={styles.customForm}>
      {config?.map(renderFormItem)}
      <Button
        className={styles.addButton}
        label="Добавить"
        iconLeft={IconAdd as any}
        width="full"
        view="ghost"
        onClick={handleAddClick}
      />
    </div>
  );

  const renderLocale = () => (
    <EditCustomFormLocale
      isOpen={isOpenLocale}
      locale={getLocaleByFieldIndex(editableLocaleId)}
      onClose={handleLocaleClose}
      onSubmit={handleLocaleSubmit}
    />
  );

  return (
    <>
      {renderContent()}
      {renderLocale()}
    </>
  );
};

export default EditCustomForm;
