import { FC } from 'react';
import { SnackBrandListInfo } from '../../../../types/serverInterface/SnackProductBaseDTO';
import styles from './SnackBrandList.module.scss';
import SnackBrandListItem from './SnackBrandListItem';

/**
 * Свойства компонента SnackBrandList
 */
type SnackBrandListProps = {
  /**
   * Список брендов
   */
  brandList: SnackBrandListInfo[];
};

/**
 * Список брендов базы снеков
 */
const SnackBrandList: FC<SnackBrandListProps> = ({ brandList }) => {
  // render методы
  const renderBrandListItem = (brand: SnackBrandListInfo) => (
    <SnackBrandListItem key={brand.id} brand={brand} />
  );

  return <div className={styles.SnackBrandList}>{brandList.map(renderBrandListItem)}</div>;
};

export default SnackBrandList;
