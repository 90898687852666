import { FC } from 'react';
import { CreateCellViewDTO, EditCellViewDTO } from '../../../../types/serverInterface/cellViewDTO';
import { useAppDispatch } from '../../../../app/hooks/store';
import { useFormik } from 'formik';
import { createSportPitValidationScheme } from './createSportPitValidationScheme';
import { editSportPitValidationScheme } from './editSportPitValidationScheme';
import { createCellViewAction, editCellViewAction } from '../../../../state/productBase/actions';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DefaultModal from '../../../../components/DefaultModal';
import styles from './SportPitForm.module.scss';
import { TextField } from '@consta/uikit/TextField';
import { useTranslation } from 'react-i18next';

/**
 * Дефолтное значение для формы создания
 */
const createInitialValue: CreateCellViewDTO = {
  name: '',
  mediaKey: '',
};

/**
 * Свойства формы создания
 */
type CreateSportPitFormProps = {
  type: 'create';
};

/**
 * Свойства формы изменения
 */
type EditSportPitFormProps = {
  type: 'edit';
  sportPit: EditCellViewDTO;
  sportPitId: number;
};

/**
 * Объединение типов создания и изменения
 */
type UnionSportPitFormProps = CreateSportPitFormProps | EditSportPitFormProps;

/**
 * Свойства компонента SportPitForm
 */
type SportPitFormProps = UnionSportPitFormProps & {
  /**
   * Флаг открытия формы
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия формы
   */
  onClose: () => void;
};

/**
 * Форма вида спортивного питания
 */
const SportPitForm: FC<SportPitFormProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, type, onClose } = props;

  const isCreate = type === 'create';

  const initialValue = isCreate ? createInitialValue : props.sportPit;

  const formik = useFormik({
    validationSchema: isCreate ? createSportPitValidationScheme : editSportPitValidationScheme,
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: CreateCellViewDTO | EditCellViewDTO) {
    if (isCreate) {
      dispatch(createCellViewAction(data)).then(handleClose);
    }

    if (!isCreate) {
      dispatch(editCellViewAction(data, props.sportPitId)).then(handleClose);
    }
  }

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
    formik.setValues(createInitialValue);
  };

  // render методы
  const renderAction = () => (
    <>
      <Button
        label={t('productBaseConfig.sportPit.form.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button
        label={
          isCreate
            ? t('productBaseConfig.sportPit.form.add.button.label')
            : t('productBaseConfig.sportPit.form.edit.button.label')
        }
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={
        isCreate
          ? t('productBaseConfig.sportPit.form.create.modal.title')
          : t('productBaseConfig.sportPit.form.edit.modal.title')
      }
      isOpen={isOpen}
      onClose={handleCancelButtonClick}
      renderActions={renderAction}
    >
      <form className={styles.sportPitForm}>
        <TextField
          label={t('productBaseConfig.sportPit.form.name.input.label')}
          name="name"
          width="full"
          value={formik.values.name}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          label={t('productBaseConfig.sportPit.form.mediaKey.input.label')}
          name="mediaKey"
          width="full"
          value={formik.values.mediaKey}
          onChange={({ e }) => formik.handleChange(e)}
        />
      </form>
    </DefaultModal>
  );
};

export default SportPitForm;
