import { FC } from 'react';
import { MachineStatus, StatusColor } from '../../../../../types/serverInterface/machineDTO';
import ContentCard from '../../../../../components/ContentCard';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineHomaPageStatus.module.scss';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { DisplayType, displayTypeValue } from '../../../../App';
import classNames from 'classnames';

/**
 * Свойства компонента MachineHomaPageStatus
 */
type MachineHomaPageStatusProps = {
  /**
   * Статус автомата
   */
  status: MachineStatus;
};

/**
 * Статус автомата
 */
const MachineHomaPageStatus: FC<MachineHomaPageStatusProps> = ({ status }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const { text, date: dateStr, color } = status;
  const [date, time] = (dateStr || '').split(' ');

  // Вспомогательные методы
  const getTextView = () => {
    switch (color) {
      case StatusColor.SUCCESS:
        return 'success';
      case StatusColor.WARNING:
        return 'warning';
      case StatusColor.ERROR:
        return 'alert';
      default:
        return 'system';
    }
  };

  return (
    <ContentCard className={classNames(styles.MachineHomaPageStatus, isLaptop && styles.laptop)}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        Статус
      </Text>
      <HorizontalContainer className={styles.statusCard} align="center">
        <Text
          className={styles.text}
          size={isLaptop ? 'm' : 'l'}
          weight="semibold"
          view={getTextView()}
        >
          {text}
        </Text>
        <VerticalContainer align="end" space="2xs">
          <Text className={styles.dateText} size={isLaptop ? 'm' : 's'} view="secondary">
            {date}
          </Text>
          <Text className={styles.dateText} size={isLaptop ? 'm' : 's'} view="secondary">
            {time}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineHomaPageStatus;
