import { FC, useEffect } from 'react';
import { Combobox } from '@consta/uikit/Combobox';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { selectFlagCellPurposeById } from '../../../../../state/productBase/selectors';
import {
  getCellCategoryListAction,
  getCellPurposeListAction,
  getCellViewListAction,
  getTasteListAction,
} from '../../../../../state/productBase/actions';
import {
  ProductCharacteristicItem,
  ProductCharacteristics,
} from '../../../../../types/serverInterface/productDTO';
import { useTranslation } from 'react-i18next';

/**
 * Сфойства компонента ProductCharacteristicsForm
 */
type ProductCharacteristicsFormProps = {
  /**
   * Значение формы характеристик продукта
   */
  characteristics: ProductCharacteristics;
  /**
   * Список категорий контенйров
   */
  cellCategoryList: ProductCharacteristicItem[];
  /**
   * Список назначений контейнеров
   */
  cellPurposeList: ProductCharacteristicItem[];
  /**
   * Список видов спортивного питания
   */
  tasteList: ProductCharacteristicItem[];
  /**
   * Список вкусов
   */
  sportPitList: ProductCharacteristicItem[];
  /**
   * Обработчик изменений
   *
   * @param key ключ, одной из характеристик
   * @param value значение, на которое меняем
   */
  onChange: (
    key: keyof ProductCharacteristics,
  ) => (value: ProductCharacteristicItem | null) => void;
};

/**
 * Форма характеристик продукта
 */
const ProductCharacteristicsForm: FC<ProductCharacteristicsFormProps> = ({
  characteristics,
  cellCategoryList,
  cellPurposeList,
  sportPitList,
  tasteList,
  onChange,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { cellCategory, cellPurpose, taste, view } = characteristics;

  const { tasted: isOpenTaste, sportPit: isOpenSportPit } = useAppSelector(
    selectFlagCellPurposeById(cellPurpose && cellPurpose.id),
  );

  useEffect(() => {
    dispatch(getCellCategoryListAction());
    dispatch(getCellPurposeListAction());
    dispatch(getTasteListAction());
    dispatch(getCellViewListAction());
  }, [dispatch]);

  // Обработчики
  const handleChange =
    (key: keyof ProductCharacteristics) =>
    ({ value }: { value: ProductCharacteristicItem | null }) => {
      onChange(key)(value && value);
    };

  return (
    <>
      <Combobox
        label={t('productBase.product.form.characteristics.category.select.label')}
        value={cellCategory}
        items={cellCategoryList}
        getItemLabel={(data) => data.name}
        getItemKey={(data) => data.id}
        onChange={handleChange('cellCategory')}
      />
      <Combobox
        label={t('productBase.product.form.characteristics.purpose.select.label')}
        value={cellPurpose}
        items={cellPurposeList}
        getItemLabel={(data) => data.name}
        onChange={handleChange('cellPurpose')}
      />
      {isOpenTaste && (
        <Combobox
          label={t('productBase.product.form.characteristics.taste.select.label')}
          value={taste}
          items={tasteList}
          getItemLabel={(data) => data.name}
          onChange={handleChange('taste')}
        />
      )}
      {isOpenSportPit && (
        <Combobox
          label={t('productBase.product.form.characteristics.sportPit.select.label')}
          value={view}
          items={sportPitList}
          getItemLabel={(data) => data.name}
          onChange={handleChange('view')}
        />
      )}
    </>
  );
};

export default ProductCharacteristicsForm;
