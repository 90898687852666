import { FC } from 'react';
import { CreateWaterModelCell } from '../../../../../../types/serverInterface/machineModelDTO';
import VerticalContainer from '../../../../../../components/VerticalContainer';
import WaterCellModelInfo from './WaterCellModelInfo';
import { cellSortFunc } from '../helpers';

/**
 * Свойства компонента WaterCellsModelInfo
 */
type WaterCellsModelInfoProps = {
  /**
   * Ячейки воды
   */
  cells: CreateWaterModelCell[];
};

/**
 * Информация о ячейках воды модели автомата
 */
const WaterCellsModelInfo: FC<WaterCellsModelInfoProps> = ({ cells }) => {
  const sortedCells = [...cells].sort(cellSortFunc);

  // render методы
  const renderCell = (cell: CreateWaterModelCell, index: number) => (
    <WaterCellModelInfo key={index} cell={cell} />
  );

  return <VerticalContainer>{sortedCells.map(renderCell)}</VerticalContainer>;
};

export default WaterCellsModelInfo;
