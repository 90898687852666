import { FC } from 'react';
import { LocaleName, LocaleNameList } from '../types';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './LocaleNameInfo.module.scss';
import NoContent from '../../../NoContent';
import { useTranslation } from 'react-i18next';

type LocaleNameInfoProps = {
  locale: LocaleNameList;
};

const LocaleNameInfo: FC<LocaleNameInfoProps> = ({ locale }) => {
  const { t } = useTranslation();

  const isNoContent = !locale?.length;

  // render методы
  const renderLocaleItem = ({ lang, name }: LocaleName) =>
    name && (
      <div key={lang} className={styles.rowContent}>
        <div className={styles.contentItem}>
          <Text>{t(`lang.${lang}`)}</Text>
          <Text>{name}</Text>
        </div>
      </div>
    );

  const renderContent = () => (isNoContent ? <NoContent /> : locale?.map(renderLocaleItem));

  return <>{renderContent()}</>;
};

export default LocaleNameInfo;
