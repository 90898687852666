import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconInfoSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_34302_1215)">
      <path d="M6.375 5.5C6.375 5.29289 6.20711 5.125 6 5.125C5.79289 5.125 5.625 5.29289 5.625 5.5V8.5C5.625 8.70711 5.79289 8.875 6 8.875C6.20711 8.875 6.375 8.70711 6.375 8.5V5.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.625C3.03147 0.625 0.625 3.03147 0.625 6C0.625 8.96853 3.03147 11.375 6 11.375C8.96853 11.375 11.375 8.96853 11.375 6C11.375 3.03147 8.96853 0.625 6 0.625ZM1.375 6C1.375 3.44568 3.44568 1.375 6 1.375C8.55432 1.375 10.625 3.44568 10.625 6C10.625 8.55432 8.55432 10.625 6 10.625C3.44568 10.625 1.375 8.55432 1.375 6Z"
      />
      <path d="M6.5 4C6.5 4.27614 6.27614 4.5 6 4.5C5.72386 4.5 5.5 4.27614 5.5 4C5.5 3.72386 5.72386 3.5 6 3.5C6.27614 3.5 6.5 3.72386 6.5 4Z" />
    </g>
    <defs>
      <clipPath id="clip0_34302_1215">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

const IconInfoSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_34302_1210)">
      <path d="M8.49998 7.33337C8.49998 7.05723 8.27612 6.83337 7.99998 6.83337C7.72384 6.83337 7.49998 7.05723 7.49998 7.33337V11.3334C7.49998 11.6095 7.72384 11.8334 7.99998 11.8334C8.27612 11.8334 8.49998 11.6095 8.49998 11.3334V7.33337Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 0.833374C4.04194 0.833374 0.833313 4.042 0.833313 8.00004C0.833313 11.9581 4.04194 15.1667 7.99998 15.1667C11.958 15.1667 15.1666 11.9581 15.1666 8.00004C15.1666 4.042 11.958 0.833374 7.99998 0.833374ZM1.83331 8.00004C1.83331 4.59428 4.59422 1.83337 7.99998 1.83337C11.4057 1.83337 14.1666 4.59428 14.1666 8.00004C14.1666 11.4058 11.4057 14.1667 7.99998 14.1667C4.59422 14.1667 1.83331 11.4058 1.83331 8.00004Z"
      />
      <path d="M8.66664 5.33337C8.66664 5.70156 8.36817 6.00004 7.99998 6.00004C7.63179 6.00004 7.33331 5.70156 7.33331 5.33337C7.33331 4.96518 7.63179 4.66671 7.99998 4.66671C8.36817 4.66671 8.66664 4.96518 8.66664 5.33337Z" />
    </g>
    <defs>
      <clipPath id="clip0_34302_1210">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

const IconInfoSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M12.75 11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
    />
    <path d="M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z" />
  </svg>
);

export const IconInfo = createIcon({
  name: 'IconInfo',
  xs: IconInfoSizeXS,
  s: IconInfoSizeS,
  m: IconInfoSizeM,
});
