import { FC } from 'react';
import { IconPageError } from '../../assets/icon/IconPageError';
import styles from './ErrorPage.module.scss';
import VerticalContainer from '../../components/VerticalContainer';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { defaultIconProps } from '../../consts/defaultIconProps';

const ErrorPage: FC = () => {
  return (
    <div className={styles.ErrorPage}>
      <VerticalContainer space="5xl" align="center">
        <IconPageError className={styles.icon} {...defaultIconProps} />
        <Text size="4xl">У вас нет доступа к этой странице</Text>
      </VerticalContainer>
      <Button label="Вернуться назад" />
    </div>
  );
};

export default ErrorPage;
