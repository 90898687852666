import { FC } from 'react';
import { CreateDisposableModelCell } from '../../../../../../../types/serverInterface/machineModelDTO';
import styles from '../../CreateModelCellsForm.module.scss';
import VerticalContainer from '../../../../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import TextFieldWithTooltip from '../../../../../../../components/withTooltip/TextField';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import { Switch } from '@consta/uikit/Switch';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconCopy } from '@consta/uikit/IconCopy';
import { DisposableCellsFieldError } from '../../../types';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ModelDisposableCellFormItem
 */
type ModelDisposableCellFormItemProps = {
  /**
   * Ячейка расходника
   */
  cell: CreateDisposableModelCell;
  /**
   * Ошибка формы
   */
  error: DisposableCellsFieldError | undefined;
  /**
   * Обработчик изменения формы
   *
   * @param key ключ поля
   * @param value новое значение поля
   */
  onChange: (key: keyof CreateDisposableModelCell) => (value: string | null) => void;
  /**
   * Обработчик нажатия на кнопку удаления ячейки
   */
  onDeleteClick: () => void;
  /**
   * Обработчик нажатия на кнопку копирования ячейки
   */
  onCopyClick: () => void;
};

/**
 * Форма расходника модели автомата
 */
const ModelDisposableCellFormItem: FC<ModelDisposableCellFormItemProps> = ({
  cell,
  error,
  onChange,
  onDeleteClick,
  onCopyClick,
}) => {
  const { t } = useTranslation();

  const { isCount, cellNumber, name, minVolume } = cell;

  // Обработчики
  const handleChange =
    (key: keyof CreateDisposableModelCell) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  // t('machineControl.model.create.cells.cup.cellNumber.input.label')

  return (
    <div className={styles.formItem}>
      <VerticalContainer space="xs">
        <HorizontalContainer space="s" align="end">
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.cellNumber?.isError
                ? t('machineControl.model.create.cells.disposable.cellNumber.tooltip.label')
                : t(String(error.cellNumber?.label)),
            }}
            status={!error ? undefined : error.cellNumber?.status}
            label={t('machineControl.model.create.cells.disposable.cellNumber.input.label')}
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(cellNumber)}
            onChange={handleChange('cellNumber')}
          />
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.name?.isError
                ? t('machineControl.model.create.cells.disposable.name.tooltip.label')
                : t(String(error.name?.label)),
            }}
            status={!error ? undefined : error.name?.status}
            label={t('machineControl.model.create.cells.disposable.name.input.label')}
            width="full"
            value={name}
            onChange={handleChange('name')}
          />
          <Button iconLeft={IconTrash as any} onlyIcon view="ghost" onClick={onDeleteClick} />
        </HorizontalContainer>
        <HorizontalContainer space="s" align="end">
          <TextFieldWithTooltip
            tooltipProps={{
              content: !error?.minVolume?.isError
                ? t('machineControl.model.create.cells.disposable.minVolume.tooltip.label')
                : t(String(error.minVolume?.label)),
            }}
            status={!error ? undefined : error.minVolume?.status}
            label={t('machineControl.model.create.cells.disposable.minVolume.input.label')}
            rightSide="шт"
            width="full"
            type="number"
            incrementButtons={false}
            value={getInputNumberValue(minVolume)}
            onChange={handleChange('minVolume')}
          />
          <Button iconLeft={IconCopy as any} onlyIcon view="ghost" onClick={onCopyClick} />
        </HorizontalContainer>
        <Switch
          label={t('machineControl.model.create.cells.disposable.isCount.switch.label')}
          checked={isCount}
        />
      </VerticalContainer>
    </div>
  );
};

export default ModelDisposableCellFormItem;
