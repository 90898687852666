import { FC } from 'react';
import { useAppSelector } from '../../../app/hooks/store';
import { selectComponentById } from '../../../state/productBase/selectors';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента DeleteComponent
 */
type DeleteComponentProps = {
  /**
   * id возможного компонента состава
   */
  componentId: number;
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

/**
 * Компонент удаления возможного компонента состава
 */
const DeleteComponent: FC<DeleteComponentProps> = ({
  componentId,
  isDeleteLoading,
  onDelete,
  onClose,
}) => {
  const { t } = useTranslation();

  const component = useAppSelector(selectComponentById(componentId));

  if (!component) return null;

  const { name } = component;

  return (
    <DeleteModal
      isOpen={!!componentId}
      isDeleteLoading={isDeleteLoading}
      title={t('productBaseConfig.component.deleteModal.title', { name })}
      description={t('productBaseConfig.component.deleteModal.description', { name })}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteComponent;
