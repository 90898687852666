import { FC } from 'react';
import { LocaleName, LocaleNameList } from '../types';
import { Combobox } from '@consta/uikit/Combobox';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '@consta/uikit/IconTrash';
import { languagesArray } from '../consts';
import styles from './LocaleNameForm.module.scss';
import NoContent from '../../../NoContent';
import { useTranslation } from 'react-i18next';
import { IconAdd } from '@consta/uikit/IconAdd';

/**
 * Свойства компонента LocaleNameForm
 */
type LocaleNameFormProps = {
  locale: LocaleNameList;
  onLangChange: (index: number) => ({ value }: { value: string | null }) => void;
  onNameChange: (index: number) => ({ value }: { value: string | null }) => void;
  onDeleteLang: (index: number) => () => void;
  onAddClick: () => void;
};

/**
 * Форма изменения локализации только названия
 *
 * @param locale список локализаций названия
 * @param onAddClick обработчик нажатия на кнопку добавления
 * @param onLangChange общаботчик выбора языка
 * @param onNameChange обработчик изменения названия
 * @param onDeleteLang обработчик удаления элемента из локализации
 */
const LocaleNameForm: FC<LocaleNameFormProps> = ({
  locale,
  onAddClick,
  onLangChange,
  onNameChange,
  onDeleteLang,
}) => {
  const { t } = useTranslation();

  const isNoContent = !locale?.length;

  // render методы
  const renderLocaleItem = ({ lang, name }: LocaleName, index: number) => (
    <div className={styles.rowContent} key={lang}>
      <div className={styles.contentItem}>
        <Combobox
          label={t('defaultLocale.form.lang.select.label')}
          name="lang"
          value={lang}
          items={languagesArray}
          getItemKey={(lang) => lang}
          getItemLabel={(lang) => t(`lang.${lang}`)}
          onChange={onLangChange(index)}
        />
        <TextField
          label={t('defaultLocale.form.name.input.label')}
          name="name"
          value={name}
          width="full"
          onChange={onNameChange(index)}
        />
        <Button view="ghost" iconLeft={IconTrash as any} onlyIcon onClick={onDeleteLang(index)} />
      </div>
    </div>
  );

  const renderContent = () => (isNoContent ? <NoContent /> : locale?.map(renderLocaleItem));

  return (
    <>
      {renderContent()}
      <Button
        label={t('defaultLocale.form.add.button.label')}
        view="ghost"
        width="full"
        iconLeft={IconAdd as any}
        onClick={onAddClick}
      />
    </>
  );
};

export default LocaleNameForm;
