import { Discount, EditPromoCodeDTO } from '../../../types/serverInterface/promoCodeDTO';
import { FieldError } from '../../../types/types';
import { initialFieldError } from '../../../helpers/validateHelpers';

/**
 * Ошибки формы изменения промокода
 */
export type EditPromoCodeErrors = Record<
  keyof (Omit<EditPromoCodeDTO, 'discount'> & Discount),
  FieldError
>;

/**
 * Дефолтное состояние ошибок
 */
export const editPromoCodeInitialErrors: EditPromoCodeErrors = {
  code: initialFieldError,
  isFullMachine: initialFieldError,
  machineIds: initialFieldError,
  qty: initialFieldError,
  groupId: initialFieldError,
  schedules: initialFieldError,
  description: initialFieldError,
  isUnlimitedTimeUse: initialFieldError,
  product: initialFieldError,
  isUnlimitedCountUse: initialFieldError,
  periodUse: initialFieldError,
  isActive: initialFieldError,
  type: initialFieldError,
  amount: initialFieldError,
};

/**
 * Получение ошибки для поля формы изменения промокода
 *
 * @param error ошибка формы изменения из которой достаётся конкретное поле
 * @param key ключ поля
 */
export const getEditPromoCodeFieldError =
  (error: EditPromoCodeErrors) => (key: keyof EditPromoCodeErrors) => {
    return error?.[key] || initialFieldError;
  };
