import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  deleteEmployeeAction,
  getEmployeesListAction,
} from '../../../../state/organization/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import TableCardWithBackground from '../../../../components/TableCardWithBackground';
import { EmployeeDTO } from '../../../../types/serverInterface/employeeDTO';
import { Roles } from '../../../../types/serverInterface/cabinetDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { useTranslation } from 'react-i18next';
import { IconTrash } from '../../../../assets/icon/iconTrash';
import DeleteMember from './DeleteMember';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconProfileAccepted } from '../../../../assets/icon/iconProfileAccepted';
import VerticalContainer from '../../../../components/VerticalContainer';
import CreateMember from './CreateMember';
import { selectEmployeesList } from '../../../../state/organization/selectors';

type MembersTableProps = {
  organizationId: number;
};

// const employeesList: EmployeeDTO[] = [
//   {
//     name: 'Иван',
//     surname: 'Иванов',
//     patronymic: 'Иванович',
//     phone: '+79991234567',
//     uuid: '1',
//     email: 'ivan@mail.ru',
//     position: Roles.ORGANIZATION_OWNER,
//   },
//   {
//     name: 'Петр',
//     surname: 'Петров',
//     patronymic: 'Петрович',
//     phone: '+79992345678',
//     uuid: '2',
//     // email: 'petr@mail.ru',
//     position: Roles.TECHNICIAN,
//   },
//   {
//     name: 'Алексей',
//     surname: 'Сидоров',
//     patronymic: 'Алексеевич',
//     phone: '+79993456789',
//     uuid: '3',
//     email: 'aleksey@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Андрей',
//     surname: 'Игнатьев',
//     patronymic: 'Андреевич',
//     phone: '+79994567890',
//     uuid: '4',
//     email: 'andrey@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Егор',
//     surname: 'Кузнецов',
//     patronymic: 'Егорович',
//     phone: '+79995678901',
//     uuid: '5',
//     // email: 'egor@mail.ru',
//     position: Roles.TECHNICIAN,
//   },
//   {
//     name: 'Олег',
//     surname: 'Смирнов',
//     patronymic: 'Олегович',
//     phone: '+79996789012',
//     uuid: '6',
//     email: 'oleg@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Сергей',
//     surname: 'Козлов',
//     patronymic: 'Сергеевич',
//     phone: '+79997890123',
//     uuid: '7',
//     email: 'sergey@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Владимир',
//     surname: 'Николаев',
//     patronymic: 'Владимирович',
//     phone: '+79998901234',
//     uuid: '8',
//     // email: 'vladimir@mail.ru',
//     position: Roles.TECHNICIAN,
//   },
//   {
//     name: 'Дмитрий',
//     surname: 'Зайцев',
//     patronymic: 'Дмитриевич',
//     phone: '+7888999000',
//     uuid: '9',
//     email: 'dmitry@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Виктор',
//     surname: 'Лебедев',
//     patronymic: 'Викторович',
//     phone: '+77778881111',
//     uuid: '10',
//     email: 'viktor@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Михаил',
//     surname: 'Крылов',
//     patronymic: 'Михайлович',
//     phone: '+76667772222',
//     uuid: '11',
//     email: 'mikhail@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Евгений',
//     surname: 'Андреев',
//     patronymic: 'Евгеньевич',
//     phone: '+75556663333',
//     uuid: '12',
//     // email: 'evgeniy@mail.ru',
//     position: Roles.TECHNICIAN,
//   },
//   {
//     name: 'Николай',
//     surname: 'Молчанов',
//     patronymic: 'Николаевич',
//     phone: '+74445554444',
//     uuid: '13',
//     email: 'nikolay@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Григорий',
//     surname: 'Беляев',
//     patronymic: 'Григорьевич',
//     phone: '+73334445555',
//     uuid: '14',
//     email: 'grigoriy@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Даниил',
//     surname: 'Мельников',
//     patronymic: 'Даниилович',
//     phone: '+72223336666',
//     uuid: '15',
//     // email: 'daniil@mail.ru',
//     position: Roles.TECHNICIAN,
//   },
//   {
//     name: 'Артем',
//     surname: 'Кузьмин',
//     patronymic: 'Артемьевич',
//     phone: '+71112227777',
//     uuid: '16',
//     email: 'artem@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Игорь',
//     surname: 'Исаев',
//     patronymic: 'Игоревич',
//     phone: '+70001118888',
//     uuid: '17',
//     email: 'igor@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Станислав',
//     surname: 'Тарасов',
//     patronymic: 'Станиславович',
//     phone: '+68889991111',
//     uuid: '18',
//     // email: 'stanislav@mail.ru',
//     position: Roles.TECHNICIAN,
//   },
//   {
//     name: 'Максим',
//     surname: 'Антонов',
//     patronymic: 'Максимович',
//     phone: '+67778892222',
//     uuid: '19',
//     email: 'maxim@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
//   {
//     name: 'Олесь',
//     surname: 'Грачев',
//     patronymic: 'Олесьевич',
//     phone: '+66667773333',
//     uuid: '20',
//     email: 'oles@mail.ru',
//     position: Roles.ORGANIZATION_MEMBER,
//   },
// ];

const MembersTable: FC<MembersTableProps> = ({ organizationId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const employeesList = useAppSelector(selectEmployeesList());

  const [deletableMember, setDeletableMember] = useState<EmployeeDTO | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  useEffect(() => {
    organizationId && dispatch(getEmployeesListAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletableMember &&
      dispatch(
        deleteEmployeeAction({ organizationId, userUuid: deletableMember.uuid }, organizationId),
      ).then(() => {
        setDeletableMember(null);
        setIsDeleteLoading(false);
      });
  };

  const filterEmployeesList = useMemo(() => {
    return employeesList.filter(({ name, surname, patronymic }) =>
      `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, employeesList]);

  const handleCancelDeleteClick = () => {
    setDeletableMember(null);
  };

  const handleDeleteClick = (member: EmployeeDTO) => {
    setDeletableMember(member);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  // render методы
  const renderTableActions = () => (
    <HorizontalContainer space="xl">
      <Button
        label="Зарегистрировать"
        iconLeft={IconProfileAccepted as any}
        onClick={() => setIsCreateOpen(true)}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </HorizontalContainer>
  );

  return (
    <VerticalContainer space="3xl">
      <CreateMember isOpen={isCreateOpen} onClose={() => setIsCreateOpen(false)} />
      {renderTableActions()}
      <DeleteMember
        isDeleteLoading={isDeleteLoading}
        member={deletableMember}
        onClose={handleCancelDeleteClick}
        onDelete={handleDelete}
      />
      <TableCardWithBackground
        rows={filterEmployeesList}
        columns={{
          name: {
            key: 'name',
            title: 'ФИО',
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ name, surname, patronymic }) =>
              `${surname ? surname : ''} ${name ? name : ''} ${patronymic ? patronymic : ''}`,
          },
          position: {
            key: 'position',
            title: 'Должность',
            alignment: 'left',
            width: '25%',
            getItemLabel: ({ position }) => t(`roles.${position}`),
          },
          email: {
            key: 'email',
            title: 'Email',
            alignment: 'left',
            width: '28%',
            getItemLabel: ({ email }) => email || '',
          },
          phone: {
            key: 'phone',
            title: 'Телефон',
            alignment: 'left',
            width: '11%',
            getItemLabel: ({ phone }) => phone,
          },
          uuid: {
            key: 'uuid',
            title: '',
            alignment: 'left',
            width: '6%',
            renderCell: (data) => (
              <HorizontalContainer space="s">
                {data.position !== Roles.ORGANIZATION_OWNER && (
                  <Button
                    onlyIcon
                    iconLeft={IconTrash as any}
                    view="clear"
                    size="m"
                    onClick={() => handleDeleteClick(data)}
                  />
                )}
              </HorizontalContainer>
            ),
          },
        }}
      />
    </VerticalContainer>
  );
};

export default MembersTable;
