import { AppDispatch } from '../../app/store';
import { getRegistrationInfoThunk, registrationThunk } from './thunk';
import { RegistrationDTO } from '../../types/serverInterface/authDTO';

/**
 * Получение информации для регистрации
 */
export const getRegistrationInfoActions = (hash: string) => (dispatch: AppDispatch) =>
  dispatch(getRegistrationInfoThunk(hash));

/**
 * Регистрация
 */
export const registrationAction =
  (data: { data: RegistrationDTO; hash: string }) => (dispatch: AppDispatch) =>
    dispatch(registrationThunk(data));
