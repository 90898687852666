import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSearchSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33334 1C2.9401 1 1 2.9401 1 5.33333C1 7.72655 2.9401 9.66665 5.33334 9.66665C7.72658 9.66665 9.66668 7.72655 9.66668 5.33333C9.66668 2.9401 7.72658 1 5.33334 1ZM1.66667 5.33333C1.66667 3.30829 3.30829 1.66667 5.33334 1.66667C7.35839 1.66667 9.00001 3.30829 9.00001 5.33333C9.00001 7.35837 7.35839 8.99999 5.33334 8.99999C3.30829 8.99999 1.66667 7.35837 1.66667 5.33333Z"
    />
    <path d="M9.12464 8.65321C8.99446 8.52304 8.78341 8.52304 8.65323 8.65321C8.52305 8.78339 8.52305 8.99444 8.65323 9.12462L10.431 10.9024C10.5611 11.0325 10.7722 11.0325 10.9024 10.9024C11.0325 10.7722 11.0325 10.5611 10.9024 10.431L9.12464 8.65321Z" />
  </svg>
);

const IconSearchSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.06668 1C3.71614 1 1 3.71614 1 7.06668C1 10.4172 3.71614 13.1334 7.06668 13.1334C10.4172 13.1334 13.1334 10.4172 13.1334 7.06668C13.1334 3.71614 10.4172 1 7.06668 1ZM1.93333 7.06668C1.93333 4.23161 4.23161 1.93333 7.06668 1.93333C9.90174 1.93333 12.2 4.23161 12.2 7.06668C12.2 9.90174 9.90174 12.2 7.06668 12.2C4.23161 12.2 1.93333 9.90174 1.93333 7.06668Z"
    />
    <path d="M12.3745 11.7145C12.1922 11.5323 11.8968 11.5323 11.7145 11.7145C11.5323 11.8968 11.5323 12.1923 11.7145 12.3745L14.2033 14.8634C14.3856 15.0456 14.6811 15.0456 14.8633 14.8634C15.0456 14.6811 15.0456 14.3856 14.8633 14.2034L12.3745 11.7145Z" />
  </svg>
);

const IconSearchSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5333 1C5.26823 1 1 5.26821 1 10.5333C1 15.7984 5.26823 20.0666 10.5333 20.0666C15.7985 20.0666 20.0667 15.7984 20.0667 10.5333C20.0667 5.26821 15.7985 1 10.5333 1ZM2.46667 10.5333C2.46667 6.07823 6.07825 2.46666 10.5333 2.46666C14.9885 2.46666 18.6 6.07823 18.6 10.5333C18.6 14.9884 14.9885 18.6 10.5333 18.6C6.07825 18.6 2.46667 14.9884 2.46667 10.5333Z"
    />
    <path d="M18.8742 17.8371C18.5878 17.5507 18.1235 17.5507 17.8371 17.8371C17.5507 18.1234 17.5507 18.5878 17.8371 18.8742L21.7481 22.7852C22.0345 23.0716 22.4988 23.0716 22.7852 22.7852C23.0716 22.4988 23.0716 22.0345 22.7852 21.7481L18.8742 17.8371Z" />
  </svg>
);

export const IconSearch = createIcon({
  name: 'IconSearch',
  m: IconSearchSizeM,
  s: IconSearchSizeS,
  xs: IconSearchSizeXS,
});
