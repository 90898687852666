import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectTasteLocale } from '../../../../state/productBase/selectors';
import { editLocaleAction, getTasteLocaleByIdAction } from '../../../../state/productBase/actions';
import { LocaleNameList } from '../../../../components/locale/NameLocale/types';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { Loader } from '@consta/uikit/Loader';
import NameLocale from '../../../../components/locale/NameLocale';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента TasteLocale
 */
type TasteLocaleProps = {
  /**
   * Флаг открытия локализации вкуса
   */
  isOpen: boolean;
  /**
   * id вкуса
   */
  tasteId: number;
  /**
   * обработчик закрытия локализации вкуса
   */
  onClose: () => void;
};

/**
 * Компонент локализации вкуса
 */
const TasteLocale: FC<TasteLocaleProps> = ({ isOpen, tasteId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectTasteLocale());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getTasteLocaleByIdAction(tasteId));
  }, [dispatch, tasteId]);

  // Обработчики
  const handleSubmit = (data: LocaleNameList) => {
    locale &&
      dispatch(
        editLocaleAction({
          ...locale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() =>
        dispatch(getTasteLocaleByIdAction(tasteId)).then(() => {
          handleEditClose();
        }),
      );
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleClose = onClose;

  if (!locale) return <Loader />;

  return (
    <NameLocale
      isOpen={isOpen}
      isEdit={isEdit}
      locale={locale}
      modalTitle={t('productBaseConfig.taste.locale.modal.title')}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export default TasteLocale;
