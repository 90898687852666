import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectComponentLocale } from '../../../../state/productBase/selectors';
import {
  editLocaleAction,
  getComponentLocaleByIdAction,
} from '../../../../state/productBase/actions';
import { LocaleNameList } from '../../../../components/locale/NameLocale/types';
import { FieldNames } from '../../../../types/serverInterface/localeDTO';
import { Loader } from '@consta/uikit/Loader';
import NameLocale from '../../../../components/locale/NameLocale';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента ComponentLocale
 */
type ComponentLocaleProps = {
  /**
   * Флаг открытия локализации возможного компонента состава
   */
  isOpen: boolean;
  /**
   * id возможного компонента состава
   */
  componentId: number;
  /**
   * Обработчик закрытия локализации возможного компонента состава
   */
  onClose: () => void;
};

/**
 * Компонент локализации возможного компонента состава
 */
const ComponentLocale: FC<ComponentLocaleProps> = ({ isOpen, componentId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const locale = useAppSelector(selectComponentLocale());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(getComponentLocaleByIdAction(componentId));
  }, [dispatch, componentId]);

  // Обработчики
  const handleSubmit = (data: LocaleNameList) => {
    locale &&
      dispatch(
        editLocaleAction({
          ...locale,
          fields: [
            {
              entityFieldName: FieldNames.NAME,
              localeValues: data.map((value) => ({
                value: value.name,
                language: value.lang,
              })),
            },
          ],
        }),
      ).then(() =>
        dispatch(getComponentLocaleByIdAction(componentId)).then(() => handleEditClose()),
      );
  };

  const handleEditClick = () => {
    setIsEdit(true);
  };

  const handleEditClose = () => {
    setIsEdit(false);
  };

  const handleClose = onClose;

  if (!locale) return <Loader />;

  return (
    <NameLocale
      isOpen={isOpen}
      isEdit={isEdit}
      locale={locale}
      modalTitle={t('productBaseConfig.component.locale.modal.title')}
      onEditClick={handleEditClick}
      onEditClose={handleEditClose}
      onSubmit={handleSubmit}
      onClose={handleClose}
    />
  );
};

export default ComponentLocale;
