import * as Yup from 'yup';

/**
 * Схема валидации локализации названия
 */
export const editNameLocaleValidationScheme = Yup.array().of(
  Yup.object().shape({
    lang: Yup.string().required(),
    name: Yup.string().nullable(),
  }),
);
