import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconPhoneSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M6.67222 8.98889C5.1 8.18333 3.81111 6.9 3.01111 5.32778L4.12371 4.49804C4.44322 4.25977 4.59072 3.85451 4.49912 3.4666L4.09853 1.77018C3.99196 1.31884 3.58905 1 3.1253 1H1.55556C1.25 1 1 1.25 1 1.55556C1 6.77222 5.22778 11 10.4444 11C10.75 11 11 10.75 11 10.4444V8.87332C11 8.4102 10.682 8.00767 10.2315 7.90048L8.53545 7.49696C8.14653 7.40442 7.73987 7.55242 7.50139 7.87328L6.67222 8.98889Z" />
  </svg>
);

const IconPhoneSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M8.94111 12.1844C6.74 11.0567 4.93556 9.26 3.81556 7.05889L5.58371 5.74027C5.90322 5.50199 6.05072 5.09673 5.95912 4.70882L5.2652 1.77018C5.15862 1.31884 4.75572 1 4.29197 1H1.77778C1.35 1 1 1.35 1 1.77778C1 9.08111 6.91889 15 14.2222 15C14.65 15 15 14.65 15 14.2222V11.7067C15 11.2435 14.682 10.841 14.2315 10.7338L11.2932 10.0347C10.9043 9.9422 10.4976 10.0902 10.2592 10.4111L8.94111 12.1844Z" />
  </svg>
);

const IconPhoneSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M13.3444 17.9778C10.2 16.3667 7.62222 13.8 6.02222 10.6556L8.77371 8.6036C9.09322 8.36532 9.24072 7.96006 9.14912 7.57216L8.0152 2.77018C7.90862 2.31884 7.50572 2 7.04197 2H3.11111C2.5 2 2 2.5 2 3.11111C2 13.5444 10.4556 22 20.8889 22C21.5 22 22 21.5 22 20.8889V16.9567C22 16.4935 21.682 16.091 21.2315 15.9838L16.4299 14.8414C16.041 14.7489 15.6343 14.8969 15.3958 15.2177L13.3444 17.9778Z" />
  </svg>
);

export const IconPhone = createIcon({
  name: 'IconPhone',
  m: IconPhoneSizeM,
  s: IconPhoneSizeS,
  xs: IconPhoneSizeXS,
});
