import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { useNavigate } from 'react-router-dom';
import { selectCabinet } from '../../../state/user/selectors';
import { editUserAction, getCabinetAction } from '../../../state/user/actions';
import { useFormik } from 'formik';
import { editUserValidationScheme } from './editUserValidationScheme';
import { EditUserDTO } from '../../../types/serverInterface/cabinetDTO';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import styles from './UserForm.module.scss';

const UserForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectCabinet());

  useEffect(() => {
    dispatch(getCabinetAction());
  }, [dispatch]);

  const formik = useFormik({
    validationSchema: editUserValidationScheme,
    initialValues: {
      name: user?.name || '',
      surname: user?.surname || '',
      patronymic: user?.patronymic || '',
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: EditUserDTO) {
    dispatch(editUserAction(data)).then(() => navigate('/cabinet'));
  }

  const handleCancelClick = () => navigate('/cabinet');

  // if (!user) return null'

  return (
    <form className={styles.userForm} onSubmit={formik.handleSubmit}>
      <TextField
        label={t('user.form.name.input.label')}
        name="name"
        value={formik.values.name}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label={t('user.form.surname.input.label')}
        name="surname"
        value={formik.values.surname}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        label={t('user.form.patronymic.input.label')}
        name="patronymic"
        value={formik.values.patronymic}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <div className={styles.actions}>
        <Button
          className={styles.primary}
          label={t('user.form.save.button.label')}
          disabled={formik.values === formik.initialValues}
          onClick={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        />
        <Button
          className={styles.secondary}
          label={t('user.form.cancel.button.label')}
          view="ghost"
          onClick={handleCancelClick}
        />
      </div>
    </form>
  );
};

export default UserForm;
