import { FC, useEffect } from 'react';
import { Ingredient } from '../../../../../../../types/serverInterface/recipeDTO';
import styles from './RecipeIngredient.module.scss';
import { Combobox } from '@consta/uikit/Combobox';
import { TextField } from '@consta/uikit/TextField';
import { Switch } from '@consta/uikit/Switch';
import { useAppSelector } from '../../../../../../../app/hooks/store';
import {
  selectCellCategoryList,
  selectCellPurposeList,
  selectTasteList,
} from '../../../../../../../state/productBase/selectors';
import { TasteDTO } from '../../../../../../../types/serverInterface/tasteDTO';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента RecipeIngredientForm
 */
type RecipeIngredientProps = {
  /**
   * Ингредиент
   */
  ingredient: Ingredient;
  /**
   * Обработчик изменений ингридиента
   *
   * @param key ключ в ингредиенте
   * @param value значение
   */
  onChange: (key: keyof Ingredient) => (value: string | null) => void;
  /**
   * Обработчик изменений ингридиента (boolean)
   *
   * @param key ключ в ингредиенте
   * @param checked значение
   */
  onBooleanChange: (key: keyof Ingredient) => (checked: boolean) => void;
  /**
   * Обработчик изменений ингридиента (select)
   *
   * @param key ключ в ингредиенте
   * @param id значение
   */
  onSelectChange: (key: keyof Ingredient) => (id: number | null) => void;
  /**
   * Обработчик изменений ингридиента (вкус)
   *
   * @param idList список id вкусов
   */
  onTasteChange: (idList: number[]) => void;
};

/**
 * Форма ингредиента рецепта
 */
const RecipeIngredientForm: FC<RecipeIngredientProps> = ({
  ingredient,
  onChange,
  onBooleanChange,
  onSelectChange,
  onTasteChange,
}) => {
  const { t } = useTranslation();

  const cellCategoryList = useAppSelector(selectCellCategoryList());
  const cellPurposeList = useAppSelector(selectCellPurposeList());
  const tasteList = useAppSelector(selectTasteList());

  // Вспомогательные методы
  const getCellCategoryById = (id: number | undefined) => {
    return cellCategoryList.find((item) => item.id === id) || null;
  };

  const getCellPurposeById = (id: number | undefined) => {
    return cellPurposeList.find((item) => item.id === id) || null;
  };

  const getTastesById = (id: number[] | undefined): TasteDTO[] | null => {
    if (id && id.length > 0) {
      return tasteList.filter((item) => id.includes(item.id)) || null;
    }
    return null;
  };

  const isDisableTaste = !getCellPurposeById(ingredient?.cellPurposeId)?.tasted;

  useEffect(() => {
    if (isDisableTaste && ingredient.tastesId?.length) {
      onTasteChange([]);
    }
  }, [isDisableTaste, onTasteChange]);

  // Обработчики
  const handleChange =
    (key: keyof Ingredient) =>
    ({ value }: { value: string | null }) => {
      onChange(key)(value);
    };

  const handleBooleanChange =
    (key: keyof Ingredient) =>
    ({ checked }: { checked: boolean }) => {
      onBooleanChange(key)(!checked);
    };

  const handleSelectChange =
    (key: keyof Ingredient) =>
    ({ value }: { value: { id: number } | null }) => {
      onSelectChange(key)(value?.id || null);
    };

  const handleTasteChange = ({ value }: { value: TasteDTO[] | null }) => {
    const tastesIdList = value ? value.map(({ id }) => id) : [];

    onTasteChange(tastesIdList);
  };

  return (
    <div className={styles.ingredient}>
      <div className={styles.ingredientRow}>
        <Combobox
          items={cellPurposeList}
          placeholder={t('productBase.recipe.form.ingredients.purpose.select.placeholder')}
          value={getCellPurposeById(ingredient.cellPurposeId)}
          getItemLabel={(data) => data.name}
          getItemKey={(data) => data.id}
          onChange={handleSelectChange('cellPurposeId')}
        />
        <Combobox
          items={cellCategoryList}
          placeholder={t('productBase.recipe.form.ingredients.category.select.placeholder')}
          value={getCellCategoryById(ingredient?.cellCategoryId)}
          getItemLabel={(data) => data.name}
          getItemKey={(data) => data.id}
          onChange={handleSelectChange('cellCategoryId')}
        />
      </div>
      <Combobox
        disabled={isDisableTaste}
        items={tasteList}
        placeholder={t('productBase.recipe.form.ingredients.taste.select.placeholder')}
        multiple
        value={getTastesById(ingredient?.tastesId)}
        getItemLabel={(data) => data.name}
        getItemKey={(data) => data.id}
        onChange={handleTasteChange}
      />
      <div className={styles.ingredientRow}>
        <TextField
          type="number"
          placeholder={t('productBase.recipe.form.ingredients.qty.input.label')}
          width="full"
          value={String(ingredient.qty)}
          onChange={handleChange('qty')}
        />
      </div>
      <div className={styles.ingredientRow}>
        <div className={styles.maxValue}>
          <TextField
            type="number"
            label={t('productBase.recipe.form.ingredients.maxNumberPortion.input.label')}
            value={String(ingredient.maxNumberPortion)}
            onChange={handleChange('maxNumberPortion')}
          />
        </div>
        <div className={styles.requiredSwitch}>
          <Switch
            checked={!ingredient.required}
            label={t('productBase.recipe.form.ingredients.required.switch.label')}
            onChange={handleBooleanChange('required')}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipeIngredientForm;
