import { PromoCodeListFiltersReq } from '../../../../types/serverInterface/promoCodeDTO';
import { Pagination } from '../../../../types/types';
import { getPromoCodeListByExportAction } from '../../../../state/promoCode/actions';
import { useAppDispatch } from '../../../../app/hooks/store';

export const useExportFromClickBoard = ({
  organizationId,
  pagination,
  filters,
}: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
}) => {
  const dispatch = useAppDispatch();

  return {
    handleExportFromClickBoardClick: () => {
      dispatch(getPromoCodeListByExportAction(organizationId, filters, pagination)).then((res) => {
        const promoCodeList = res.data;

        const text = promoCodeList && promoCodeList.map(({ code }) => code).join(', ');

        text &&
          navigator.clipboard.writeText(text).then(
            function () {
              // TODO: в будущем заменить на notification
              console.log('Текст успешно скопирован в буфер обмена');
            },
            function (err) {
              console.error('Произошла ошибка при копировании текста: ', err);
            },
          );
      });
    },
  };
};
