import { FC } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useNavigate } from 'react-router-dom';
import styles from './ProductBaseConfigHome.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Домашняя страница конфигурации базы продуктов
 */
const ProductBaseConfigHome: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Обработчики
  const handleCellCategoryClick = () => {
    navigate('/productBaseConfig/cellCategory');
  };

  const handleCellPurposeClick = () => {
    navigate('/productBaseConfig/cellPurpose');
  };

  const handleTasteClick = () => {
    navigate('/productBaseConfig/taste');
  };

  const handleSportPitClick = () => {
    navigate('/productBaseConfig/sportPit');
  };

  const handleComponentClick = () => {
    navigate('/productBaseConfig/component');
  };

  // render методы
  const renderTagsButtons = () => (
    <div className={styles.buttonsContainer}>
      <Button
        label={t('productBaseConfig.homePage.category.button.label')}
        width="full"
        onClick={handleCellCategoryClick}
      />
      <Button
        label={t('productBaseConfig.homePage.purpose.button.label')}
        width="full"
        onClick={handleCellPurposeClick}
      />
      <Button
        label={t('productBaseConfig.homePage.taste.button.label')}
        width="full"
        onClick={handleTasteClick}
      />
      <Button
        label={t('productBaseConfig.homePage.sportPit.button.label')}
        width="full"
        onClick={handleSportPitClick}
      />
    </div>
  );

  const renderCompoundButton = () => (
    <div className={styles.buttonsContainer}>
      <Button
        label={t('productBaseConfig.homePage.components.button.label')}
        width="full"
        onClick={handleComponentClick}
      />
    </div>
  );

  return (
    <div className={styles.configHome}>
      {renderCompoundButton()}
      {renderTagsButtons()}
    </div>
  );
};

export default ProductBaseConfigHome;
