import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BrandDTO,
  CreateBrandDTO,
  EditBrandDTO,
  ProductFilters,
} from '../../types/serverInterface/brandDTO';
import { api } from '../../app/api';
import { LocaleDTO } from '../../types/serverInterface/localeDTO';
import {
  CreateProductLineDTO,
  EditProductLineDTO,
  ProductLineDTO,
} from '../../types/serverInterface/productLineDTO';
import {
  CellCategoryDTO,
  CreateCellCategoryDTO,
  EditCellCategoryDTO,
} from '../../types/serverInterface/cellCategoryDTO';
import {
  CellPurposeDTO,
  CreateCellPurposeDTO,
  EditCellPurposeDTO,
} from '../../types/serverInterface/cellPurpose';
import { CreateTasteDTO, EditTasteDTO, TasteDTO } from '../../types/serverInterface/tasteDTO';
import {
  CreateCellViewDTO,
  EditCellViewDTO,
  CellViewDTO,
} from '../../types/serverInterface/cellViewDTO';
import {
  ComponentDTO,
  CreateComponentDTO,
  EditComponentDTO,
} from '../../types/serverInterface/componentDTO';
import {
  CreateProductDTO,
  EditProductCalibrationDTO,
  EditProductCharacteristicsDTO,
  EditProductComponentDTO,
  EditProductDTO,
  ProductDetailsDTO,
  ProductListItemDTO,
} from '../../types/serverInterface/productDTO';
import {
  CreateRecipeDTO,
  EditRecipeBasic,
  Ingredient,
  RecipeDetailsDTO,
  RecipeDTO,
} from '../../types/serverInterface/recipeDTO';
import { ProductBaseItemDTO } from '../../types/serverInterface/promoCodeDTO';
import { getModifiedArrayForFormattingProductCalibration } from '../../helpers/getModifiedArrayForFormattingProductCalibration';
import {
  CreateSnackBrandDTO,
  CreateSnackBrandRes,
  CreateSnackProductDTO,
  CreateSnackProductRes,
  SnackBrandListDTO,
  SnackProductInfoDTO,
  SnackProductListDTO,
} from '../../types/serverInterface/SnackProductBaseDTO';

/**
 * Изменение локализации
 */
export const editLocaleThunk = createAsyncThunk<void, LocaleDTO>('editLocale', async (data) => {
  return await api.productBase.editLocale(data);
});

/**
 * Получения списка брендов
 */
export const getBrandListThunk = createAsyncThunk<BrandDTO[], ProductFilters>(
  'getBrandList',
  async (filters: ProductFilters) => {
    return await api.productBase.fetchBrandList(filters);
  },
);

/**
 * Создание бренда
 */
export const createBrandThunk = createAsyncThunk<void, CreateBrandDTO>(
  'createBrand',
  async (data) => {
    return await api.productBase.createBrand(data);
  },
);

/**
 * Изменение бренда
 */
export const editBrandThunk = createAsyncThunk<void, EditBrandDTO>('editBrand', async (data) => {
  return await api.productBase.editBrand(data);
});

/**
 * Удаление бренда
 */
export const deleteBrandThunk = createAsyncThunk<void, number>('deleteBrand', async (brandId) => {
  return await api.productBase.deleteBrand(brandId);
});

/**
 * Получение локализации бренда
 */
export const getBrandLocaleThunk = createAsyncThunk<LocaleDTO, number>(
  'getBrandLocale',
  async (brandId) => {
    return await api.productBase.getBrandLocale(brandId);
  },
);

/**
 * Получение списка линеек продукта в бренде
 */
export const getProductLineListThunk = createAsyncThunk<
  ProductLineDTO[],
  { brandId: number; filters: ProductFilters }
>('getProductLineList', async ({ brandId, filters }) => {
  return await api.productBase.fetchProductLineList(brandId, filters);
});

/**
 * Создание линейки продуктов
 */
export const createProductLineThunk = createAsyncThunk<void, CreateProductLineDTO>(
  'createProductLine',
  async (data) => {
    return await api.productBase.createProductLine(data);
  },
);

/**
 * Изменение линейки продукта
 */
export const editProductLineThunk = createAsyncThunk<void, EditProductLineDTO>(
  'editProductLine',
  async (data) => {
    return await api.productBase.editProductLine(data);
  },
);

/**
 * Удаление линейки продуктов
 */
export const deleteProductLineThunk = createAsyncThunk<void, number>(
  'deleteProductLine',
  async (productLineId) => {
    return await api.productBase.deleteProductLine(productLineId);
  },
);

/**
 * Получение локализации линейки продуктов
 */
export const getProductLineLocaleThunk = createAsyncThunk<LocaleDTO, number>(
  'getProductLineLocale',
  async (productLineId) => {
    return await api.productBase.getProductLineLocale(productLineId);
  },
);

/**
 * Получение списка категорий контейнера
 */
export const getCellCategoryListThunk = createAsyncThunk<CellCategoryDTO[]>(
  'getCellCategoryList',
  async () => {
    const data = await api.productBase.fetchCellCategoryList();
    return data.map((item) => ({ ...item, configMachine: JSON.parse(item.configMachine) }));
  },
);

/**
 * Получение категории контейнера
 *
 * @param cellCategoryId id категории контейнера
 */
export const getCellCategoryByIdThunk = createAsyncThunk<CellCategoryDTO, number>(
  'getCellCategoryById',
  async (cellCategoryId) => {
    const data = await api.productBase.fetchCellCategoryById(cellCategoryId);
    return { ...data, configMachine: JSON.parse(data.configMachine) };
  },
);

/**
 * Создание категории контейнера
 *
 * @param data данные формы созданя категории контейнера
 */
export const createCellCategoryThunk = createAsyncThunk<void, CreateCellCategoryDTO>(
  'createCellCategory',
  async (data) => {
    return await api.productBase.createCellCategory({
      ...data,
      configMachine: JSON.stringify(data.configMachine),
    });
  },
);

/**
 * Изменение категории контейнера
 *
 * @param data данные формы изменения категории контейнера
 * @param cellCategoryId id категории контейнера1
 */
export const editCellCategoryThunk = createAsyncThunk<
  void,
  { data: EditCellCategoryDTO; cellCategoryId: number }
>('editCellCategory', async ({ data, cellCategoryId }) => {
  return await api.productBase.editCellCategory(
    { ...data, configMachine: JSON.stringify(data.configMachine) },
    cellCategoryId,
  );
});

/**
 * Удаление категории контейнера
 *
 * @param cellCategoryId id категории контейнера
 */
export const deleteCellCategoryThunk = createAsyncThunk<void, number>(
  'deleteCellCategory',
  async (cellCategoryId) => {
    return await api.productBase.deleteCellCategory(cellCategoryId);
  },
);

/**
 * Получение локализации категории контейнера
 *
 * @param cellCategoryId id категории контейнера
 */
export const getCellCategoryLocaleByIdThunk = createAsyncThunk<LocaleDTO, number>(
  'getCellCategoryLocaleById',
  async (cellCategoryId) => {
    return api.productBase.getCellCategoryLocale(cellCategoryId);
  },
);

/**
 * Получение списка назначений контейнера
 */
export const getCellPurposeListThunk = createAsyncThunk<CellPurposeDTO[]>(
  'getCellPurposeList',
  async () => {
    const data = await api.productBase.fetchCellPurposeList();
    return data.map((item) => ({ ...item, configMachine: JSON.parse(item.configMachine) }));
  },
);

/**
 * Получение назначения контейнера по id
 *
 * @param cellPurposeId id назначения контейнера
 */
export const getCellPurposeByIdThunk = createAsyncThunk<CellPurposeDTO, number>(
  'getCellPurposeById',
  async (cellPurposeId) => {
    const data = await api.productBase.fetchCellPurposeById(cellPurposeId);
    return { ...data, configMachine: JSON.parse(data.configMachine) };
  },
);

/**
 * Создание назначения контейнера
 *
 * @param data данные формы создания назначения контейнера
 */
export const createCellPurposeThunk = createAsyncThunk<void, CreateCellPurposeDTO>(
  'createCellPurpose',
  async (data) => {
    return await api.productBase.createCellPurpose({
      ...data,
      configMachine: JSON.stringify(data.configMachine),
    });
  },
);

/**
 * Изменение назначения контейнера
 *
 * @param data данные формы изменнения назначения контейнера
 * @param cellPurposeId id назначения контейнера
 */
export const editCellPurposeThunk = createAsyncThunk<
  void,
  { data: EditCellPurposeDTO; cellPurposeId: number }
>('editCellPurpose', async ({ data, cellPurposeId }) => {
  return await api.productBase.editCellPurpose(
    {
      ...data,
      configMachine: JSON.stringify(data.configMachine),
    },
    cellPurposeId,
  );
});

/**
 * Удаление назначения контейнера
 *
 * @param cellPurposeId id назначения контейнера
 */
export const deleteCellPurposeThunk = createAsyncThunk<void, number>(
  'deleteCellPurpose',
  async (cellPurposeId) => {
    return await api.productBase.deleteCellPurpose(cellPurposeId);
  },
);

/**
 * Получение списка локализаций назначения контейнера
 *
 * @param cellPurposeId id назначения контейнера
 */
export const getCellPurposeLocaleByIdThunk = createAsyncThunk<LocaleDTO, number>(
  'getCellPurposeLocaleById',
  async (cellPurposeId) => {
    return api.productBase.fetchCellPurposeLocaleById(cellPurposeId);
  },
);

/**
 * Получение списка вкусов
 */
export const getTasteListThunk = createAsyncThunk<TasteDTO[]>('getTasteList', async () => {
  return await api.productBase.fetchTasteList();
});

/**
 * Получение вкуса по id
 *
 * @param tasteId id вкуса
 */
export const getTasteByIdThunk = createAsyncThunk<TasteDTO, number>(
  'getTasteById',
  async (tasteId: number) => {
    return await api.productBase.fetchTasteById(tasteId);
  },
);

/**
 * Создание вкуса
 *
 * @param data данные формы создания вкуса
 */
export const createTasteThunk = createAsyncThunk<void, CreateTasteDTO>(
  'createTaste',
  async (data) => {
    return await api.productBase.createTaste(data);
  },
);

/**
 * Изменение вкуса
 *
 * @param data данные формы изменения вкуса
 * @param tasteId id вкуса
 */
export const editTasteThunk = createAsyncThunk<void, { data: EditTasteDTO; tasteId: number }>(
  'editTaste',
  async ({ data, tasteId }) => {
    return await api.productBase.editTaste(data, tasteId);
  },
);

/**
 * Удаление вкуса
 *
 * @param tasteId id вкуса
 */
export const deleteTasteThunk = createAsyncThunk<void, number>('deleteTaste', async (tasteId) => {
  return await api.productBase.deleteTaste(tasteId);
});

/**
 * Получение локализации по id вкуса
 *
 * @param tasteId id вкуса
 */
export const getTasteLocaleByIdThunk = createAsyncThunk<LocaleDTO, number>(
  'getTasteLocaleById',
  async (tasteId) => {
    return await api.productBase.fetchTasteLocaleById(tasteId);
  },
);

/**
 * Получение списка видов спортивного питания
 */
export const getSportPitListThunk = createAsyncThunk<CellViewDTO[]>('getSportPitList', async () => {
  return await api.productBase.fetchSportPitList();
});

/**
 * Получение вида спортивного питания по id
 *
 * @param sportPitId id вида спортивного питания
 */
export const getSportPitByIdThunk = createAsyncThunk<CellViewDTO, number>(
  'getSportPitById',
  async (sportPitId) => {
    return await api.productBase.fetchSportPitById(sportPitId);
  },
);

/**
 * Создание вида спортивного питания
 *
 * @param data данные формы создания вида спортивного питания
 */
export const createSportPitThunk = createAsyncThunk<void, CreateCellViewDTO>(
  'createSportPit',
  async (data) => {
    return await api.productBase.createSportPit(data);
  },
);

/**
 * Изменение вида спортивного питания
 *
 * @param data данные формы изменения вида спортивного питания
 * @param sportPitId id вида спортивного питания
 */
export const editSportPitThunk = createAsyncThunk<
  void,
  { data: EditCellViewDTO; sportPitId: number }
>('editSportPit', async ({ data, sportPitId }) => {
  return await api.productBase.editSportPit(data, sportPitId);
});

/**
 * Удаление вида спортивного питания
 *
 * @param sportPitId id вида спортивного питания
 */
export const deleteSportPitThunk = createAsyncThunk<void, number>(
  'deleteSportPit',
  async (sportPitId) => {
    return await api.productBase.deleteSportPit(sportPitId);
  },
);

/**
 * Получение локализации вида спортивного питания
 *
 * @param sportPitId id вида спортивного питания
 */
export const getSportPitLocaleByIdThunk = createAsyncThunk<LocaleDTO, number>(
  'getSportPitLocaleById',
  async (sportPitId) => {
    return await api.productBase.fetchSportPitLocaleById(sportPitId);
  },
);

/**
 * Получение списка возможных компонентов состава
 */
export const getComponentListThunk = createAsyncThunk<ComponentDTO[]>(
  'getComponentList',
  async () => {
    return await api.productBase.fetchComponentList();
  },
);

/**
 * Получение локализации возможного компонента состава
 *
 * @param componentId id компонента состава
 */
export const getComponentLocaleByIdThunk = createAsyncThunk<LocaleDTO, number>(
  'getComponentLocaleById',
  async (componentId) => {
    return await api.productBase.fetchComponentLocaleById(componentId);
  },
);

/**
 * Создание возможного компонента состава
 *
 * @param data данные формы создания возможного компонента состава
 */
export const createComponentThunk = createAsyncThunk<void, CreateComponentDTO>(
  'createComponent',
  async (data) => {
    return api.productBase.createComponent(data);
  },
);

/**
 * Изменения возможного компонента состава
 *
 * @param data даннные формы изменения возможного компонента состава
 * @param componentId id возможного компонента состава
 */
export const editComponentThunk = createAsyncThunk<
  void,
  { data: EditComponentDTO; componentId: number }
>('editComponent', async ({ data, componentId }) => {
  return api.productBase.editComponent(data, componentId);
});

/**
 * Удаление возможного компонента состава
 *
 * @param componentId id возможного компонента состава
 */
export const deleteComponentThunk = createAsyncThunk<void, number>(
  'deleteComponent',
  async (componentId) => {
    return await api.productBase.deleteComponent(componentId);
  },
);

/**
 * Получение списка продуктов в линейке продуктов
 *
 * @param productLineId id линейки продуктов
 * @param organizationId id организации
 */
export const getProductListThunk = createAsyncThunk<
  ProductListItemDTO[],
  { productLineId: number; filters: ProductFilters }
>('getProductList', async ({ productLineId, filters }) => {
  return await api.productBase.fetchProductList(productLineId, filters);
});

/**
 * Получение детальной информации о продукте
 *
 * @param productId id продукта
 */
export const getProductByIdThunk = createAsyncThunk<ProductDetailsDTO, number>(
  'getProductById',
  async (productId) => {
    const data = await api.productBase.fetchProductById(productId);

    return {
      ...data,
      categoryConfigMachine: getModifiedArrayForFormattingProductCalibration(
        JSON.parse(data.categoryConfigMachine),
        data.characteristics.cellCategory?.name || '',
      ),
      purposeConfigMachine: getModifiedArrayForFormattingProductCalibration(
        JSON.parse(data.purposeConfigMachine),
        data.characteristics.cellCategory?.name || '',
      ),
      components: data.components.map((component) => ({ ...component, componentId: component.id })),
    };
  },
);

/**
 * Получение локализации продукта
 *
 * @param productId id продукта
 */
export const getProductLocaleByIdThunk = createAsyncThunk<LocaleDTO, number>(
  'getProductLocaleById',
  async (productId) => {
    return await api.productBase.fetchProductLocaleById(productId);
  },
);

/**
 * Создание продукта
 *
 * @param data данные формы создания продукта
 */
export const createProductThunk = createAsyncThunk<void, CreateProductDTO>(
  'createProduct',
  async (data) => {
    return await api.productBase.createProduct(data);
  },
);

/**
 * Изменение продукта
 *
 * @param data данные формы изменения продукта
 * @param productId id продукта
 */
export const editProductThunk = createAsyncThunk<void, { data: EditProductDTO; productId: number }>(
  'editProduct',
  async ({ data, productId }) => {
    return await api.productBase.editProduct(data, productId);
  },
);

/**
 * Удаление продукта
 *
 * @param productId id продукта
 */
export const deleteProductThunk = createAsyncThunk<void, number>(
  'deleteProduct',
  async (productId) => {
    return await api.productBase.deleteProduct(productId);
  },
);

/**
 * Изменения состава продукта
 *
 * @param data данные формы изменения состава продукта
 * @param productId id продукта
 */
export const editProductComponentThunk = createAsyncThunk<
  void,
  { data: EditProductComponentDTO; productId: number }
>('editProductComponent', async ({ data, productId }) => {
  return await api.productBase.editProductComponent(data, productId);
});

/**
 * Изменение характеристик (тэгов) продукта
 *
 * @param data данные формы именения характеристик продукта
 * @param productId id продукта
 */
export const editProductCharacteristicsThunk = createAsyncThunk<
  void,
  { data: EditProductCharacteristicsDTO; productId: number }
>('editProductCharacteristics', async ({ data, productId }) => {
  return await api.productBase.editProductCharacteristics(data, productId);
});

/**
 * Изменение калибровки родукта
 *
 * @param data данные формы изменения калибровки продукта
 * @param productId id продукта
 */
export const editProductCalibrationThunk = createAsyncThunk<
  void,
  { data: EditProductCalibrationDTO; productId: number }
>('editProductCalibration', async ({ data, productId }) => {
  return await api.productBase.editProductCalibration(
    {
      ...data,
      categoryConfigMachine: JSON.stringify(data.categoryConfigMachine),
      purposeConfigMachine: JSON.stringify(data.purposeConfigMachine),
    },
    productId,
  );
});

/**
 * Получение списка рецептов
 *
 * @param organizationId id организации
 */
export const getRecipeListThunk = createAsyncThunk<RecipeDTO[], number | null>(
  'getRecipeList',
  async (organizationId) => {
    return await api.productBase.fetchRecipeList(organizationId);
  },
);

/**
 * Получение детальной информации о рецепте
 *
 * @param recipeId id рецепта
 */
export const getRecipeByIdThunk = createAsyncThunk<RecipeDetailsDTO, number>(
  'getRecipeById',
  async (recipeId) => {
    return await api.productBase.fetchRecipeById(recipeId);
  },
);

/**
 * Получение локализации рецепта
 *
 * @param recipeId id рецепта
 */
export const getRecipeLocaleThunk = createAsyncThunk<LocaleDTO, number>(
  'getRecipeLocale',
  async (recipeId) => {
    return await api.productBase.fetchRecipeLocale(recipeId);
  },
);

/**
 * Создание рецепта
 *
 * @param data данные формы создания рецепта
 */
export const createRecipeThunk = createAsyncThunk<void, CreateRecipeDTO>(
  'createRecipe',
  async (data) => {
    return await api.productBase.createRecipe(data);
  },
);

/**
 * Изменение рецепта
 *
 * @param data данные формы изменения рецепта
 * @param recipeId id рецепта
 */
export const editRecipeThunk = createAsyncThunk<void, { data: EditRecipeBasic; recipeId: number }>(
  'editRecipe',
  async ({ data, recipeId }) => {
    return await api.productBase.editRecipe(data, recipeId);
  },
);

/**
 * Изменение ингредиентов рецепта
 *
 * @param data данные формы изменения ингредиентов рецепта
 * @param recipeId id рецепта
 */
export const editRecipeIngredientsThunk = createAsyncThunk<
  void,
  { data: Ingredient[]; recipeId: number }
>('editRecipeIngredients', async ({ data, recipeId }) => {
  return await api.productBase.editRecipeIngredients(data, recipeId);
});

/**
 * Удаление рецепта
 *
 * @param recipeId id рецепта
 */
export const deleteRecipeThunk = createAsyncThunk<void, number>(
  'deleteRecipe',
  async (recipeId) => {
    return await api.productBase.deleteRecipe(recipeId);
  },
);

/**
 * Получение списка брендов с фильтром по категориям и видом
 *
 * @param categoryListId список категорий для фильтра
 * @param viewsListId список видов для списка
 */
export const getBrandListByCategoryViewThunk = createAsyncThunk<
  ProductBaseItemDTO[],
  { categoryListId: number[] | null; viewsListId: number[] | null }
>('getBrandListByCategoryView', async ({ categoryListId, viewsListId }) => {
  return await api.productBase.getBrandListByCategoryView(categoryListId, viewsListId);
});

/**
 * Получение списка линеек продуктов с фильтром по категориям, видам и бренду
 *
 * @param categoryListId список категорий для фильтра
 * @param viewsListId список видов для списка
 * @param brandId id бренда
 */
export const getIngredientLineListByCategoryViewThunk = createAsyncThunk<
  ProductBaseItemDTO[],
  { categoryListId: number[] | null; viewsListId: number[] | null; brandId: number }
>('getIngredientLineListByCategoryView', async ({ categoryListId, viewsListId, brandId }) => {
  return await api.productBase.getIngredientLineListByCategoryView(
    categoryListId,
    viewsListId,
    brandId,
  );
});

/**
 * Получение списка продуктов с фильтром по категориям, видам и линейкам
 *
 * @param categoryListId
 * @param viewsListId
 * @param ingredientLineIds
 */
export const getIngredientListByCategoryViewThunk = createAsyncThunk<
  ProductBaseItemDTO[],
  { categoryListId: number[] | null; viewsListId: number[] | null; ingredientLineIds: number[] }
>('getIngredientListByCategoryView', async ({ categoryListId, viewsListId, ingredientLineIds }) => {
  return await api.productBase.getIngredientListByCategoryView(
    categoryListId,
    viewsListId,
    ingredientLineIds,
  );
});

/**
 * Получения списка брендов базы снеков
 *
 * @param organizationId id организации
 */
export const getSnackBrandListThunk = createAsyncThunk<SnackBrandListDTO, number>(
  'getSnackBrandList',
  async (organizationId) => {
    return await api.productBase.getSnackBrandList(organizationId);
  },
);

/**
 * Создание бренда в базе снеков
 *
 * @param data данные формы создания бренда
 * @param organizationId id организации
 */
export const createSnackBrandThunk = createAsyncThunk<
  CreateSnackBrandRes,
  { data: CreateSnackBrandDTO; organizationId: number }
>('createSnackBrand', async ({ data, organizationId }) => {
  return await api.productBase.createSnackBrand(data, organizationId);
});

/**
 * Получения списка продуктов по id бренда базы снеков
 *
 * @param brandId id бренда
 */
export const getSnackProductListThunk = createAsyncThunk<SnackProductListDTO, number>(
  'getSnackProductList',
  async (brandId: number) => {
    return await api.productBase.getSnackProductList(brandId);
  },
);

/**
 * Создание продукта в базе снеков
 *
 * @param data данные формы создания
 * @param brandId id бренда
 */
export const createSnackProductThunk = createAsyncThunk<
  CreateSnackProductRes,
  { brandId: number; data: CreateSnackProductDTO }
>('createSnackProduct', async ({ data, brandId }) => {
  return await api.productBase.createSnackProduct(data, brandId);
});

/**
 * Получение детальной информации о продукте базы снеков
 *
 * @param productId id продукта
 */
export const getSnackProductThunk = createAsyncThunk<SnackProductInfoDTO, number>(
  'getSnackProduct',
  async (productId) => {
    return await api.productBase.getSnackProduct(productId);
  },
);
