import { FC, useEffect, useState } from 'react';
import DefaultModal from '../../../../../../components/DefaultModal';
import styles from './MachineProductCalibrationModal.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks/store';
import { getIngredientCalibrationValueAction } from '../../../../../../state/machineControl/actions';
import { selectMachineIngredientCalibration } from '../../../../../../state/machineControl/selectors';
import CustomForm from '../../../../../../components/CustomForm/CustomForm';
import { transformValueByFormItem } from '../../../../../../components/CustomForm/helpers';
import { CustomFormType } from '../../../../../../types/serverInterface/customForm';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { EditMachineCalibrationsDTO } from '../../../../../../types/serverInterface/machineDTO';

/**
 * Свойства компонента MachineProductCalibrationModal
 */
type MachineProductCalibrationModalProps = {
  /**
   * Название категории ячейки
   */
  cellCategoryName: string;
  /**
   * Название ингредиента
   */
  ingredientName: string;
  /**
   * id ингредиента
   */
  ingredientId: number;
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Номер ячейки
   */
  cellNumber: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Калибровка
   */
  calibrations: EditMachineCalibrationsDTO;
  /**
   * Отправка
   *
   * @param purposeConfig конфигурация назначения ячейки
   * @param categoryConfig конфигурация категории ячейки
   */
  onSubmit: ({
    purposeConfig,
    categoryConfig,
  }: {
    categoryConfig: CustomFormType;
    purposeConfig: CustomFormType;
  }) => (ingredientId: number) => void;
};

/**
 * Модальное окно калибровки продукта
 */
const MachineProductCalibrationModal: FC<MachineProductCalibrationModalProps> = ({
  cellCategoryName,
  ingredientId,
  machineId,
  ingredientName,
  cellNumber,
  calibrations,
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector(selectMachineIngredientCalibration());

  const [categoryConfig, setCategoryConfig] = useState<CustomFormType>(
    state?.categoryConfigMachine || [],
  );
  const [purposeConfig, setPurposeConfig] = useState<CustomFormType>(
    state?.purposeConfigMachine || [],
  );

  useEffect(() => {
    setCategoryConfig(state?.categoryConfigMachine || []);
    setPurposeConfig(state?.purposeConfigMachine || []);
  }, [state]);

  useEffect(() => {
    const findConfig = calibrations.find((item) => item.ingredientId === ingredientId);
    if (!findConfig) {
      dispatch(getIngredientCalibrationValueAction(machineId, ingredientId, cellCategoryName));
    }

    if (findConfig) {
      setCategoryConfig(findConfig.categoryConfigMachine);

      setPurposeConfig(findConfig.purposeConfigMachine);
    }
  }, [dispatch, machineId, ingredientId, calibrations]);

  // Обработчики
  const handleCategoryConfigChange = (index: number) => (value: string | Date | boolean | null) => {
    const editedConfig = [...categoryConfig];
    editedConfig[index] = transformValueByFormItem(editedConfig[index])(value);

    setCategoryConfig(editedConfig);
  };

  const handlePurposeConfigChange = (index: number) => (value: string | Date | boolean | null) => {
    const editedConfig = [...purposeConfig];
    editedConfig[index] = transformValueByFormItem(editedConfig[index])(value);

    setPurposeConfig(editedConfig);
  };

  const handleCancelClick = () => {
    // setCategoryConfig([]);
    // setPurposeConfig([]);
    onClose();
  };

  const handleSubmitClick = () => {
    onSubmit({ purposeConfig, categoryConfig })(ingredientId);
    onClose();
  };

  // render методы
  const renderIngredientInfo = () => (
    <div className={styles.card}>
      <Text size="l" weight="semibold">
        Продукт
      </Text>
      <Text size="l">{ingredientName}</Text>
    </div>
  );

  const renderConfig = () => (
    <div className={styles.card}>
      <Text size="l" weight="semibold">
        Калибровка
      </Text>
      <div className={styles.config}>
        <CustomForm config={categoryConfig} onChange={handleCategoryConfigChange} />
        <CustomForm config={purposeConfig} onChange={handlePurposeConfigChange} />
      </div>
    </div>
  );

  const renderActions = () => (
    <>
      <Button label="Назад" view="clear" onClick={handleCancelClick} />
      <Button label="Сохранить" onClick={handleSubmitClick} />
    </>
  );

  return (
    <DefaultModal
      className={styles.MachineProductCalibrationModal}
      modalTitle={`Калибровка продукта №${cellNumber}`}
      onClose={onClose}
      renderActions={renderActions}
    >
      {renderIngredientInfo()}
      {renderConfig()}
    </DefaultModal>
  );
};

export default MachineProductCalibrationModal;
