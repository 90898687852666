import { FC } from 'react';
import { Text } from '@consta/uikit/Text';
import ContentCard from '../../../../components/ContentCard';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../components/VerticalContainer';
import { OrganizationBasicInfoProps } from './types';
import styles from './OrganizationBasic.module.scss';
import { useTranslation } from 'react-i18next';

/**
 * Форма с информацией об организации: имя и описание организации, кол-во сотрудников и автоматов.
 */
const OrganizationBasicInfo: FC<OrganizationBasicInfoProps> = ({ organization }) => {
  const { t } = useTranslation();

  //render методы
  const renderOrganizationDetails = () => (
    <ContentCard className={styles.fullWidthCard}>
      <VerticalContainer space={0}>
        <Text size="s" lineHeight="s" view="secondary">
          {t('organization.basic.info.organizationName.label')}
        </Text>
        <Text size="l" lineHeight="l" weight="medium">
          {organization?.name}
        </Text>
      </VerticalContainer>
      <VerticalContainer space={0}>
        <Text size="s" lineHeight="s" view="secondary">
          {t('organization.basic.info.description.label')}
        </Text>
        <Text size="l" lineHeight="l" weight="medium">
          {organization?.description}
        </Text>
      </VerticalContainer>
    </ContentCard>
  );

  const renderOrganizationStats = () => (
    <HorizontalContainer space="m">
      <ContentCard className={styles.halfWidthCard}>
        <VerticalContainer space="2xs">
          <Text size="m" lineHeight="m" view="secondary">
            {t('organization.basic.info.machines.label')}
          </Text>
          <Text size="l" lineHeight="l" weight="medium" className={styles.centeredText}>
            {organization?.qtyMachines}
          </Text>
        </VerticalContainer>
      </ContentCard>
      <ContentCard className={styles.halfWidthCard}>
        <VerticalContainer space="2xs">
          <Text size="m" lineHeight="m" view="secondary">
            {t('organization.basic.info.employees.label')}
          </Text>
          <Text size="l" lineHeight="l" weight="medium" className={styles.centeredText}>
            {organization?.qtyEmployees}
          </Text>
        </VerticalContainer>
      </ContentCard>
    </HorizontalContainer>
  );

  return (
    <VerticalContainer space="s" className={styles.organizationBasicInfo}>
      {renderOrganizationDetails()}
      {renderOrganizationStats()}
    </VerticalContainer>
  );
};

export default OrganizationBasicInfo;
