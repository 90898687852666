import { FC, ReactNode, useState } from 'react';
import { LocaleNameDescription, LocaleNameDescriptionList } from '../types';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import classNames from 'classnames';
import styles from './LocaleNameDescriptionInfo.module.scss';
import NoContent from '../../../NoContent';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента LocaleNameDescriptionInfo
 */
type LocaleNameDescriptionInfoProps = {
  /**
   * Локализация
   */
  locale: LocaleNameDescriptionList;
  /**
   * Чипсы для внедрения в страницы информации с чипсами
   */
  chips?: ReactNode;
};

/**
 * Общий компонент информации о локализации сущности с названием и описанием
 */
const LocaleNameDescriptionInfo: FC<LocaleNameDescriptionInfoProps> = ({ locale, chips }) => {
  const { t } = useTranslation();

  const [descriptionOpenIndex, setDescriptionOpenIndex] = useState<number | null>(null);

  const isNoContent = !locale.length;

  // Обработчики
  const handleDescriptionOpen = (index: number) => () => {
    setDescriptionOpenIndex(index);
  };

  // render методы
  const renderLocaleItem = (locale: LocaleNameDescription, index: number) =>
    (locale.name !== null || locale.description !== null) && (
      <div className={styles.rowContent} key={locale.lang}>
        <div className={styles.contentItem}>
          <Text view="ghost">{t('defaultLocale.info.lang')}</Text>
          <Text>{t(`lang.${locale.lang}`)}</Text>
        </div>
        <div className={styles.contentItem}>
          <Text view="ghost">{t('defaultLocale.info.name')}</Text>
          <Text>{locale?.name}</Text>
        </div>
        <div className={styles.contentItem}>
          <Text view="ghost">{t('defaultLocale.info.description')}</Text>
          <div className={styles.descriptionAction}>
            <Text
              className={classNames(
                styles.description,
                descriptionOpenIndex === index && styles.openDescription,
              )}
            >
              {locale.description}
            </Text>
            {descriptionOpenIndex !== index && locale.description && (
              <Text className={styles.openDescriptionText} onClick={handleDescriptionOpen(index)}>
                {t('defaultLocale.info.more')}
              </Text>
            )}
          </div>
        </div>
      </div>
    );

  const renderContent = () => (isNoContent ? <NoContent /> : locale?.map(renderLocaleItem));

  return (
    <>
      {chips}
      {renderContent()}
    </>
  );
};

export default LocaleNameDescriptionInfo;
