import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSaleMethodCardSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M23.2316 7.86322C22.8073 7.86322 22.4637 8.20685 22.4637 8.63116V17.5264C22.4637 18.3214 21.8168 18.9682 21.0223 18.9682H2.97768C2.18312 18.9682 1.53625 18.3214 1.53625 17.5264V6.47335C1.53625 5.67837 2.1827 5.0315 2.97768 5.0315H21.0223C21.8168 5.0315 22.4637 5.67837 22.4637 6.47335C22.4637 6.89766 22.8069 7.24129 23.2312 7.24129C23.6555 7.24129 23.9996 6.89766 23.9996 6.47335C23.9996 4.83182 22.6638 3.49609 21.0223 3.49609H2.97768C1.33577 3.49609 0 4.83187 0 6.47335V17.5268C0 19.1683 1.33577 20.504 2.97768 20.504H21.0223C22.6638 20.504 23.9995 19.1682 24 17.5268V8.63116C24 8.20681 23.6559 7.86322 23.2316 7.86322Z"
      fill="#707070"
    />
    <path
      d="M3.41638 15.4079H6.11741C6.54171 15.4079 6.88535 15.0643 6.88535 14.64C6.88535 14.2157 6.54171 13.8721 6.11741 13.8721H3.41638C2.99249 13.8721 2.64844 14.2157 2.64844 14.64C2.64844 15.0643 2.99203 15.4079 3.41638 15.4079Z"
      fill="#707070"
    />
    <path
      d="M10.9416 13.8721H8.2406C7.81671 13.8721 7.47266 14.2157 7.47266 14.64C7.47266 15.0643 7.81629 15.4079 8.2406 15.4079H10.9416C11.3659 15.4079 11.7096 15.0643 11.7096 14.64C11.7096 14.2157 11.3659 13.8721 10.9416 13.8721Z"
      fill="#707070"
    />
    <path
      d="M13.0613 13.8721C12.637 13.8721 12.293 14.2157 12.293 14.64C12.293 15.0643 12.637 15.4079 13.0613 15.4079H15.762C16.1859 15.4079 16.5303 15.0643 16.5303 14.64C16.5303 14.2157 16.1863 13.8721 15.762 13.8721H13.0613Z"
      fill="#707070"
    />
    <path
      d="M17.8812 13.8721C17.4569 13.8721 17.1133 14.2157 17.1133 14.64C17.1133 15.0643 17.4569 15.4079 17.8812 15.4079H20.5827C21.0066 15.4079 21.3506 15.0643 21.3506 14.64C21.3506 14.2157 21.007 13.8721 20.5827 13.8721H17.8812Z"
      fill="#707070"
    />
    <path
      d="M3.41638 17.7302H10.9384C11.3627 17.7302 11.7064 17.3866 11.7064 16.9623C11.7064 16.538 11.3627 16.1943 10.9384 16.1943H3.41638C2.99249 16.1943 2.64844 16.538 2.64844 16.9623C2.64844 17.3866 2.99203 17.7302 3.41638 17.7302Z"
      fill="#707070"
    />
    <path
      d="M4.66436 10.2839H6.17361C6.43404 10.2839 6.65365 10.504 6.65365 10.764C6.65365 11.1883 6.99766 11.5319 7.42159 11.5319C7.84552 11.5319 8.18953 11.1883 8.18953 10.764C8.18953 9.65244 7.28514 8.74805 6.17361 8.74805H4.66436C3.55283 8.74805 2.64844 9.65244 2.64844 10.764C2.64844 11.8755 3.55283 12.7799 4.66436 12.7799H6.17361C6.59792 12.7799 6.94155 12.4362 6.94155 12.0119C6.94155 11.5876 6.59792 11.244 6.17361 11.244H4.66436C4.40392 11.244 4.18432 11.0239 4.18432 10.764C4.18432 10.504 4.40434 10.2839 4.66436 10.2839Z"
      fill="#707070"
    />
  </svg>
);

export const IconSaleMethodCard = createIcon({
  name: 'IconSaleMethodCard',
  s: IconSaleMethodCardSizeL,
  m: IconSaleMethodCardSizeL,
  xs: IconSaleMethodCardSizeL,
});
