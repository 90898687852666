import * as Yup from 'yup';
import { CreateBrandDTO } from '../../../../types/serverInterface/brandDTO';

/**
 * Схема валидации для создания бренда
 */
export const createBrandValidationScheme: Yup.ObjectSchema<CreateBrandDTO> = Yup.object({
  name: Yup.string().required(),
  mediaKey: Yup.string().required(),
  organizationId: Yup.number().nullable(),
});
