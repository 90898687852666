import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSaleMethodRfIdSizeL = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_6864_69307)">
      <path
        d="M4.40204 19.5292C4.09693 19.224 3.60043 19.224 3.29527 19.5292C2.99012 19.8343 2.99012 20.3308 3.29527 20.6359C3.60043 20.941 4.09693 20.941 4.40204 20.6359C4.7072 20.3308 4.7072 19.8343 4.40204 19.5292Z"
        fill="#707070"
      />
      <path
        d="M6.61298 17.3153C6.30782 17.0101 5.81132 17.0101 5.50621 17.3153C5.20105 17.6204 5.20105 18.1169 5.50621 18.4221C5.81137 18.7272 6.30787 18.7272 6.61298 18.4221C6.91809 18.1169 6.91809 17.6204 6.61298 17.3153Z"
        fill="#707070"
      />
      <path
        d="M24 3.91319C24 2.86797 23.593 1.88533 22.8539 1.14625C21.3282 -0.37948 18.8457 -0.37948 17.32 1.14625L13.3822 5.08413C13.0705 4.93558 12.7266 4.85702 12.3701 4.85702C11.7418 4.85702 11.1522 5.10058 10.71 5.5428L5.55173 10.7011C4.82639 11.4265 4.67063 12.5076 5.085 13.3813L1.14609 17.3202C0.407016 18.0592 0 19.0419 0 20.0871C0 21.1323 0.407016 22.115 1.14609 22.8541C1.90894 23.6169 2.91098 23.9983 3.91303 23.9983C4.91508 23.9983 5.91712 23.6169 6.67997 22.8541L10.6188 18.9152C10.9285 19.0616 11.2705 19.1388 11.6254 19.1388C12.2595 19.1388 12.854 18.8937 13.299 18.4485L18.4573 13.2903C19.1787 12.5688 19.3311 11.4911 18.9155 10.6185L22.8539 6.68018C23.593 5.9411 24 4.95841 24 3.91319ZM5.5732 21.7473C4.65769 22.6627 3.16828 22.6627 2.25281 21.7473C1.80942 21.3038 1.5652 20.7143 1.5652 20.0871C1.5652 19.46 1.80942 18.8704 2.25286 18.427L6.096 14.5838L9.41639 17.9041L5.5732 21.7473ZM17.3506 12.1834L12.1924 17.3417C12.0428 17.4912 11.8415 17.5736 11.6255 17.5736C11.4152 17.5736 11.2202 17.4944 11.0766 17.3507L6.64941 12.9236C6.34945 12.6236 6.35363 12.1127 6.6585 11.8077L11.8167 6.64952C11.9633 6.50289 12.1598 6.42218 12.3701 6.42218C12.5804 6.42218 12.7769 6.50289 12.9234 6.64952L17.3506 11.0766C17.6558 11.3818 17.6557 11.8783 17.3506 12.1834ZM21.7471 5.57336L17.904 9.41655L14.5836 6.09621L18.4268 2.25307C19.3423 1.33769 20.8317 1.33769 21.7471 2.25307C22.1906 2.6965 22.4348 3.2861 22.4348 3.91319C22.4348 4.54033 22.1906 5.12993 21.7471 5.57336Z"
        fill="#707070"
      />
      <path
        d="M12.3745 8.30615L8.31641 12.3643L9.42316 13.471L13.4813 9.4129L12.3745 8.30615Z"
        fill="#707070"
      />
      <path
        d="M12.5564 12.5496L10.5273 14.5786L11.6341 15.6854L13.6632 13.6563L12.5564 12.5496Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_6864_69307">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconSaleMethodRfId = createIcon({
  name: 'IconSaleMethodRfId',
  s: IconSaleMethodRfIdSizeL,
  m: IconSaleMethodRfIdSizeL,
  xs: IconSaleMethodRfIdSizeL,
});
