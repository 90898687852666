import { FC } from 'react';
import { ScheduleByForm } from '../../../../types/serverInterface/promoCodeDTO';
import ScheduleCardByForm from './ScheduleCardByForm';

/**
 * Свойства компонента SchedulesListByForm
 */
type SchedulesListByFormProps = {
  /**
   * Список расписаний
   */
  list: ScheduleByForm[];
  /**
   * Обработчик нажатия на элемент расписания
   *
   * @param schedule Элемент расписания
   */
  onItemClick: (schedule: ScheduleByForm) => void;
  /**
   * Удаление расписания
   *
   * @param index index расписания
   */
  onDelete: (index: number) => void;
};

/**
 * Список расписаний для формы промокода
 */
const SchedulesListByForm: FC<SchedulesListByFormProps> = ({ list, onItemClick, onDelete }) => {
  return (
    <>
      {list?.map((item, index) => (
        <ScheduleCardByForm
          item={item}
          key={index}
          onItemClick={onItemClick}
          onDelete={() => onDelete(index)}
        />
      ))}
    </>
  );
};

export default SchedulesListByForm;
