import { FC } from 'react';
import DeleteModal from '../../../../components/DeleteModal';
import { useAppDispatch } from '../../../../app/hooks/store';
import { deleteOutletAction } from '../../../../state/machineControl/actions';
import { useTranslation } from 'react-i18next';
import { OutletFiltersDTO, OutletListItem } from '../../../../types/serverInterface/outletDTO';

/**
 * Свойства компонента DeleteOutlet
 */
type DeleteOutletProps = {
  /**
   * id организации
   */
  organizationId: number;
  /**
   * Фильтра
   */
  filters: OutletFiltersDTO;
  /**
   * Торговая точка
   */
  outlet: OutletListItem;
  /**
   * Обработчик закрытия удаления торговой точки
   */
  onClose: () => void;
};

/**
 * Удаление торговой точки
 */
const DeleteOutlet: FC<DeleteOutletProps> = ({ outlet, organizationId, filters, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { id, country, street, houseNumber, city } = outlet;

  const address = t('outlet.delete.modal.address', { country, street, houseNumber, city });

  // Обработчики
  const handleClose = onClose;

  const handleDelete = () => {
    dispatch(deleteOutletAction(id, organizationId, filters)).then(() => {
      onClose();
    });
  };

  return (
    <DeleteModal
      isOpen
      isDeleteLoading={false}
      title={t('outlet.delete.modal.title')}
      description={[
        t('outlet.delete.modal.description1', { address }),
        t('outlet.delete.modal.description2'),
      ]}
      withConfirmDeletion={false}
      onClose={handleClose}
      onDelete={handleDelete}
    />
  );
};

export default DeleteOutlet;
