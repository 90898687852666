import { FC, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import styles from './MachineFilling.module.scss';
import { getCellCategoryListAction } from '../../../../state/productBase/actions';
import { getMachineStorageInfoAction } from '../../../../state/machineControl/actions';
import MachineFillingInfo from './MachineFillingInfo';
import {
  selectMachineStorageInfo,
  selectNewStorage,
} from '../../../../state/machineControl/selectors';
import { getSortedGroup } from '../../../../components/Machine/MachineStorage/helpers';
import { SeparatedMachineStorageType } from '../../../../components/Machine/MachineStorage/types';
import { ProductGroup } from '../../../../types/serverInterface/storageDTO';
import MachineFillingEdit from './MachineFillingEdit';
import MachineRefill from './MachineRefill';
import { DisplayType, displayTypeValue } from '../../../App';
import classNames from 'classnames';

/**
 * Дефолтное значение склада автомата
 */
const initialStorage: SeparatedMachineStorageType = {
  cells: {
    [ProductGroup.COFFEE]: [],
    [ProductGroup.CONCENTRATE]: [],
    [ProductGroup.POWDER]: [],
  },
  cellWaters: [],
  cellCups: [],
  cellDisposables: [],
};

/**
 * Типы страниц склада автомата
 */
export enum MachineStoragePageType {
  /**
   * Информация о остатках
   */
  INFO = 'INFO',
  /**
   * Изменение наполнения
   */
  EDIT = 'EDIT',
  /**
   * Пополнение остатков
   */
  REFILL = 'REFILL',
}

/**
 * Свойства компонента MachineFilling
 */
type MachineFillingProps = {
  /**
   * id автомата
   */
  machineId: number;
  pageType: MachineStoragePageType;
  /**
   * Обработчик перехода на следующий шаг
   */
  editStorageNextStep: () => void;
  onIsShowTabsChange: (isShow: boolean) => void;
};

/**
 * Склад автомата
 */
const MachineFilling: FC<MachineFillingProps> = ({
  pageType,
  machineId,
  editStorageNextStep,
  onIsShowTabsChange,
}) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const dispatch = useAppDispatch();

  const { state: oldStorage } = useAppSelector(selectMachineStorageInfo());
  const newStorage = useAppSelector(selectNewStorage());

  // const [pageType, setPageType] = useState<MachineStoragePageType>(MachineStoragePageType.INFO);

  // const isShowTabs = pageType === MachineStoragePageType.INFO;
  //
  // useEffect(() => {
  //   !isShowTabs && onIsShowTabsChange(false);
  // }, [pageType, isShowTabs, onIsShowTabsChange]);

  const separatedMachineStorage = useMemo(() => {
    if (!oldStorage) return initialStorage;
    const storage = newStorage || oldStorage;

    const sortedCells = [...storage.cells];
    sortedCells.sort((a, b) => a.cellNumber - b.cellNumber);

    const separatedStorageInfo: SeparatedMachineStorageType = JSON.parse(
      JSON.stringify(initialStorage),
    );

    sortedCells.forEach((produceCell) => {
      separatedStorageInfo.cells[produceCell.group]?.push({
        info: produceCell,
        isHoverStatus: false,
        isActiveStatus: false,
      });
    });

    separatedStorageInfo.cellWaters?.push({
      info: storage.cellWaters[0],
      isHoverStatus: false,
      isActiveStatus: false,
    });

    storage.cellCups.forEach((cupCell) => {
      separatedStorageInfo.cellCups?.push({
        info: cupCell,
        isHoverStatus: false,
        isActiveStatus: false,
      });
    });

    storage.cellDisposables.forEach((disposableCell) => {
      separatedStorageInfo.cellDisposables?.push({
        info: disposableCell,
        isHoverStatus: false,
        isActiveStatus: false,
      });
    });

    return separatedStorageInfo;
  }, [oldStorage, newStorage]);

  const sortedGroup = useMemo(
    () => getSortedGroup(separatedMachineStorage),
    [separatedMachineStorage],
  );

  useEffect(() => {
    // TODO: подумать над правильным местом для этого запроса
    dispatch(getCellCategoryListAction());
    machineId && dispatch(getMachineStorageInfoAction(machineId));
  }, [dispatch, machineId]);

  // Обработчики
  const handlePageTypeChange = (newPageType: MachineStoragePageType) => {
    // setPageType(newPageType);
  };

  // render методы
  const renderPage = () => {
    switch (pageType) {
      case MachineStoragePageType.INFO:
        return (
          <MachineFillingInfo
            storage={separatedMachineStorage}
            sortedGroup={sortedGroup}
            onPageTypeChange={handlePageTypeChange}
            onIsShowTabsChange={onIsShowTabsChange}
          />
        );
      case MachineStoragePageType.EDIT:
        return (
          <MachineFillingEdit
            machineId={machineId}
            storage={separatedMachineStorage}
            sortedGroup={sortedGroup}
            onPageTypeChange={handlePageTypeChange}
            editStorageNextStep={editStorageNextStep}
            onIsShowTabsChange={onIsShowTabsChange}
          />
        );
      case MachineStoragePageType.REFILL:
        return (
          <MachineRefill
            machineId={machineId}
            storage={separatedMachineStorage}
            sortedGroup={sortedGroup}
            onPageTypeChange={handlePageTypeChange}
            onIsShowTabsChange={onIsShowTabsChange}
          />
        );
      default:
        return 'error';
    }
  };

  return (
    <div className={classNames(styles.MachineFilling, isLaptop && styles.isLaptop)}>
      {renderPage()}
    </div>
  );
};

export default MachineFilling;
