import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconLockSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M5.375 7.68837C5.375 7.34303 5.65482 7.06307 6 7.06307C6.34518 7.06307 6.625 7.34303 6.625 7.68837C6.625 8.03372 6.34518 8.31367 6 8.31367C5.65482 8.31367 5.375 8.03372 5.375 7.68837Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0.810059C4.41218 0.810059 3.125 2.09786 3.125 3.68644V4.24357C3.125 4.27699 3.12937 4.30939 3.13756 4.34023C2.41951 4.6042 1.85983 5.18376 1.62236 5.91498C1.5 6.29174 1.5 6.75728 1.5 7.68837C1.5 8.61946 1.5 9.085 1.62236 9.46177C1.86965 10.2232 2.46636 10.8202 3.22746 11.0676C3.60404 11.1901 4.06936 11.1901 4.99999 11.1901H7C7.93063 11.1901 8.39596 11.1901 8.77254 11.0676C9.53364 10.8202 10.1303 10.2232 10.3776 9.46177C10.5 9.085 10.5 8.61946 10.5 7.68837C10.5 6.75728 10.5 6.29174 10.3776 5.91498C10.1402 5.18376 9.58049 4.6042 8.86244 4.34023C8.87063 4.30939 8.875 4.27699 8.875 4.24357V3.68644C8.875 2.09786 7.58782 0.810059 6 0.810059ZM7 4.18669C7.48264 4.18669 7.84013 4.18669 8.125 4.20376V3.68644C8.125 2.51227 7.1736 1.56042 6 1.56042C4.8264 1.56042 3.875 2.51227 3.875 3.68644V4.20376C4.15987 4.18669 4.51736 4.18669 5 4.18669H7ZM4.625 7.68837C4.625 6.92861 5.24061 6.31271 6 6.31271C6.75939 6.31271 7.375 6.92861 7.375 7.68837C7.375 8.44813 6.75939 9.06403 6 9.06403C5.24061 9.06403 4.625 8.44813 4.625 7.68837Z"
    />
  </svg>
);

const IconLockSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M7.16667 10.2446C7.16667 9.78543 7.53976 9.41323 8 9.41323C8.46024 9.41323 8.83333 9.78543 8.83333 10.2446C8.83333 10.7037 8.46024 11.0759 8 11.0759C7.53976 11.0759 7.16667 10.7037 7.16667 10.2446Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.09998C5.88291 1.09998 4.16667 2.81208 4.16667 4.92407V5.66476C4.16667 5.70919 4.17249 5.75226 4.18342 5.79326C3.22602 6.14421 2.47978 6.91472 2.16314 7.88686C2 8.38776 2 9.00669 2 10.2446C2 11.4824 2 12.1013 2.16314 12.6022C2.49287 13.6146 3.28848 14.4083 4.30328 14.7372C4.80538 14.9 5.42581 14.9 6.66665 14.9H9.33333C10.5742 14.9 11.1946 14.9 11.6967 14.7372C12.7115 14.4083 13.5071 13.6146 13.8369 12.6022C14 12.1013 14 11.4824 14 10.2446C14 9.00669 14 8.38776 13.8369 7.88686C13.5202 6.91472 12.774 6.14421 11.8166 5.79326C11.8275 5.75226 11.8333 5.70919 11.8333 5.66476V4.92407C11.8333 2.81208 10.1171 1.09998 8 1.09998ZM9.33333 5.58913C9.97686 5.58913 10.4535 5.58913 10.8333 5.61183V4.92407C10.8333 3.36304 9.56481 2.09757 8 2.09757C6.43519 2.09757 5.16667 3.36304 5.16667 4.92407V5.61183C5.54649 5.58913 6.02314 5.58913 6.66667 5.58913H9.33333ZM6.16667 10.2446C6.16667 9.23447 6.98748 8.41564 8 8.41564C9.01252 8.41564 9.83333 9.23447 9.83333 10.2446C9.83333 11.2546 9.01252 12.0735 8 12.0735C6.98748 12.0735 6.16667 11.2546 6.16667 10.2446Z"
    />
  </svg>
);

const IconLockSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M10.75 15.37C10.75 14.6796 11.3096 14.12 12 14.12C12.6904 14.12 13.25 14.6796 13.25 15.37C13.25 16.0604 12.6904 16.62 12 16.62C11.3096 16.62 10.75 16.0604 10.75 15.37Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.62C8.82436 1.62 6.25 4.19436 6.25 7.37V8.48371C6.25 8.55052 6.25873 8.61528 6.27512 8.67693C4.83903 9.20462 3.71966 10.3632 3.24472 11.8249C3 12.5781 3 13.5087 3 15.37C3 17.2313 3 18.1619 3.24472 18.9151C3.73931 20.4373 4.93273 21.6307 6.45492 22.1253C7.20808 22.37 8.13871 22.37 9.99998 22.37H14C15.8613 22.37 16.7919 22.37 17.5451 22.1253C19.0673 21.6307 20.2607 20.4373 20.7553 18.9151C21 18.1619 21 17.2313 21 15.37C21 13.5087 21 12.5781 20.7553 11.8249C20.2803 10.3632 19.161 9.20462 17.7249 8.67693C17.7413 8.61528 17.75 8.55052 17.75 8.48371V7.37C17.75 4.19436 15.1756 1.62 12 1.62ZM14 8.37C14.9653 8.37 15.6803 8.37 16.25 8.40413V7.37C16.25 5.02279 14.3472 3.12 12 3.12C9.65279 3.12 7.75 5.02279 7.75 7.37V8.40413C8.31973 8.37 9.03471 8.37 10 8.37H14ZM9.25 15.37C9.25 13.8512 10.4812 12.62 12 12.62C13.5188 12.62 14.75 13.8512 14.75 15.37C14.75 16.8888 13.5188 18.12 12 18.12C10.4812 18.12 9.25 16.8888 9.25 15.37Z"
    />
  </svg>
);

export const IconLock = createIcon({
  name: 'IconLock',
  xs: IconLockSizeXS,
  s: IconLockSizeS,
  m: IconLockSizeM,
});
