import React, { FC, useState } from 'react';
import { CreateOrganizationFormProps, OrganizationFormSteps } from './types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../app/hooks/store';
import DefaultModal from '../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import OrganizationBasicForm from './OrganizationBasicForm';
import {
  CreateOrganizationBasic,
  CreateOrganizationDTO,
  ContactOrganization,
} from '../../../types/serverInterface/organizationDTO';
import {
  createOrganizationBasicInitialValue,
  createOrganizationContactsInitialValue,
} from './consts';
import OrganizationContactsForm from './OrganizationContactsForm';
import { createOrganizationAction } from '../../../state/organization/actions';
import styles from './CreateOrganizationForm.module.scss';

/**
 * Форма для создания организации и контактов организации.
 */
const CreateOrganizationForm: FC<CreateOrganizationFormProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [formStep, setFormStep] = useState<OrganizationFormSteps>(OrganizationFormSteps.BASE);
  const [basicFormState, setBasicFormState] = useState<CreateOrganizationBasic>(
    createOrganizationBasicInitialValue,
  );
  const [contactsFormState, setContactsFormState] = useState<ContactOrganization[]>(
    createOrganizationContactsInitialValue,
  );

  // Вспомогательные методы
  const getPrimaryButtonLabel = () => {
    switch (formStep) {
      case OrganizationFormSteps.BASE:
        return t('organization.create.basic.further.button.label');
      case OrganizationFormSteps.CONTACTS:
        return t('organization.create.contacts.save.button.label');
    }
  };

  const getModalTitle = () => {
    switch (formStep) {
      case OrganizationFormSteps.BASE:
        return t('organization.create.basic.modal.title');
      case OrganizationFormSteps.CONTACTS:
        return t('organization.create.contacts.modal.title');
    }
  };

  const getCancelButtonLabel = () => {
    switch (formStep) {
      case OrganizationFormSteps.BASE:
        return t('organization.create.basic.cancel.button.label');
      case OrganizationFormSteps.CONTACTS:
        return t('organization.create.contacts.back.button.label');
    }
  };

  // Обработчики
  const handleBasicChange = (key: keyof CreateOrganizationBasic) => (value: string | null) => {
    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleCancelButtonClick = () => {
    switch (formStep) {
      case OrganizationFormSteps.BASE:
        return onClose();
      case OrganizationFormSteps.CONTACTS:
        return setFormStep(OrganizationFormSteps.BASE);
    }
  };

  function handleSubmit() {
    const data: CreateOrganizationDTO = {
      ...basicFormState,
      contacts: contactsFormState,
    };
    setFormStep(OrganizationFormSteps.BASE);
    setBasicFormState(createOrganizationBasicInitialValue);
    setContactsFormState(createOrganizationContactsInitialValue);
    dispatch(createOrganizationAction(data));
    onClose();
  }

  const handlePrimaryButtonClick = () => {
    switch (formStep) {
      case OrganizationFormSteps.BASE:
        return setFormStep(OrganizationFormSteps.CONTACTS);
      case OrganizationFormSteps.CONTACTS:
        return handleSubmit();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleContactsChange =
    (index: number) => (key: keyof ContactOrganization) => (value: string | null) => {
      setContactsFormState((prevState) => {
        const newContacts = [...prevState];
        newContacts[index] = { ...newContacts[index], [key]: value || '' };
        return newContacts;
      });
    };

  const handleContactsDelete = (index: number) => {
    setContactsFormState((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleAddContact = () => {
    setContactsFormState((prevState) => [...prevState, { name: '', contact: '' }]);
  };

  //render методы
  const renderActions = () => (
    <>
      <Button
        label={getCancelButtonLabel()}
        onClick={handleCancelButtonClick}
        view="clear"
        size="l"
      />
      <Button
        label={getPrimaryButtonLabel()}
        onClick={handlePrimaryButtonClick}
        view="primary"
        size="l"
      />
    </>
  );

  const renderContent = () => {
    switch (formStep) {
      case OrganizationFormSteps.BASE:
        return <OrganizationBasicForm value={basicFormState} onChange={handleBasicChange} />;
      case OrganizationFormSteps.CONTACTS:
        return (
          <OrganizationContactsForm
            value={contactsFormState}
            onChange={handleContactsChange}
            onDelete={handleContactsDelete}
            onAdd={handleAddContact}
          />
        );
    }
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={getModalTitle()}
      onClose={handleClose}
      renderActions={renderActions}
      className={styles.createOrganizationForm}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default CreateOrganizationForm;
