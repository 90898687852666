import { AppDispatch } from '../../app/store';
import {
  editFileLinksUserThunk,
  editUserThunk,
  getCabinetThunk,
  getUserDocumentLinksThunk,
} from './thunk';
import { EditUserDTO } from '../../types/serverInterface/cabinetDTO';
import { EditFileLinkUserDTO } from '../../types/serverInterface/documentLink';

/**
 * Получение личного кабинета пользователя
 */
export const getCabinetAction = () => (dispatch: AppDispatch) => dispatch(getCabinetThunk());

/**
 * Изменение пользователя
 *
 * @param data данные формы изменения пользователя
 */
export const editUserAction = (data: EditUserDTO) => (dispatch: AppDispatch) =>
  dispatch(editUserThunk(data));

/**
 * Получение списка файлов пользователя
 *
 * @param userUuid uuid пользователя
 */
export const getUserDocumentLinksAction = (userUuid: string) => (dispatch: AppDispatch) =>
  dispatch(getUserDocumentLinksThunk(userUuid));

/**
 * Изменение списка файлов пользователя
 *
 * @param data новый список файлов пользователя
 */
export const editFileLinksUserAction = (data: EditFileLinkUserDTO) => (dispatch: AppDispatch) =>
  dispatch(editFileLinksUserThunk(data));
