import {
  BaseModelError,
  CellsModelError,
  CupCellsFieldError,
  DisposableCellsFieldError,
  MatrixModelError,
  ProductCellsFieldError,
  WaterCellsFieldError,
} from './types';
import {
  CreateBaseModel,
  CreateCellsModel,
  CreateCupModelCell,
  CreateDisposableModelCell,
  MachineModelGroup,
  CreateProductModelCell,
  CreateMatrixModel,
} from '../../../../types/serverInterface/machineModelDTO';
import { ProductGroup, ProductView } from '../../../../types/serverInterface/storageDTO';
import { initialFieldError } from '../../../../helpers/validateHelpers';
import { FieldError } from '../../../../types/types';

/**
 * Ошибка уникальности номера ячейки
 */
export const cellNumberUniqueFieldError: FieldError = {
  isError: true,
  errorKey: 'cellNumberUniqueFieldError',
  status: 'alert',
  label: 'machineControl.model.create.error.unique.cellNumber.label',
};

/**
 * Ошибка уникальности название расходника
 */
export const nameUniqueFieldError: FieldError = {
  isError: true,
  status: 'alert',
  label: 'machineControl.model.create.error.unique.disposableName.label',
};

/**
 * Ошибка уникальности объёма стакана
 */
export const cupValueUniqueFieldError: FieldError = {
  isError: true,
  status: 'alert',
  label: 'machineControl.model.create.error.unique.cupValue.label',
};

/**
 * Начальное значение ошибок ячеек с продуктом
 */
export const productInitialError: ProductCellsFieldError = {
  cellNumber: initialFieldError,
  maxVolume: initialFieldError,
  minVolume: initialFieldError,
  expirationTimer: initialFieldError,
  cellCategoryId: initialFieldError,
  cellPurposeId: initialFieldError,
  group: initialFieldError,
  view: initialFieldError,
};

/**
 * Начальное значение ошибок ячеек с водой
 */
export const waterInitialError: WaterCellsFieldError = {
  cellNumber: initialFieldError,
  isCount: initialFieldError,
  minVolume: initialFieldError,
  maxVolume: initialFieldError,
  expirationTimer: initialFieldError,
  filterValue: initialFieldError,
};

/**
 * Начальное значение ошибок ячеек со стаканами
 */
export const cupInitialError: CupCellsFieldError = {
  cellNumber: initialFieldError,
  isCount: initialFieldError,
  minVolume: initialFieldError,
  maxVolume: initialFieldError,
  cupVolume: initialFieldError,
};

/**
 * Начальное значение ошибок ячеек с расходником
 */
export const disposableInitialError: DisposableCellsFieldError = {
  cellNumber: initialFieldError,
  isCount: initialFieldError,
  minVolume: initialFieldError,
  name: initialFieldError,
};

/**
 * Начальные значения ошибок в формах ячеек модели автомата
 */
export const formCellsInitialError: CellsModelError = {
  cell: productInitialError,
  waterCell: waterInitialError,
  cupCell: cupInitialError,
  disposableCell: disposableInitialError,
};

/**
 * Начальные значения ошибок в форме базовой информации модели автомата
 */
export const formBasicInitialError: BaseModelError = {
  name: initialFieldError,
  qtyDosage: initialFieldError,
  groupModel: initialFieldError,
};

/**
 * Начальные значения ошибок в форме базовой информации модели автомата для снека
 */
export const formMatrixInitialError: MatrixModelError = {
  maxCells: initialFieldError,
  maxRows: initialFieldError,
  maxVolume: initialFieldError,
  minVolume: initialFieldError,
};

/**
 * Дефолтное значение продукта для формы ячеек модели автомата
 */
export const initialProduct: CreateProductModelCell = {
  cellNumber: 1,
  maxVolume: 2000,
  minVolume: 200,
  expirationTimer: 14,
  view: ProductView.PRIMARY,
  group: ProductGroup.POWDER,
  cellCategoryId: null,
  cellPurposeId: null,
};

/**
 * Дефолтное значение Стакана для формы ячеек модели автомата
 */
export const initialCup: CreateCupModelCell = {
  cellNumber: 1,
  cupVolume: 250,
  minVolume: 20,
  maxVolume: 200,
  isCount: true,
};

/**
 * Дефолтное значение расходника для формы ячеек модели автомата
 */
export const initialDisposable: CreateDisposableModelCell = {
  cellNumber: 1,
  name: 'Салфетка',
  minVolume: 20,
  isCount: true,
};

/**
 * Дефолтное значение формы ячеек модели автомата
 */
export const createCellsModelInitialValue: CreateCellsModel = {
  cells: [initialProduct],
  cellWaters: [
    {
      cellNumber: 2,
      filterValue: 5,
      expirationTimer: 5,
      maxVolume: 20,
      minVolume: 2,
      isCount: true,
    },
    {
      cellNumber: 3,
      filterValue: 5,
      expirationTimer: 5,
      maxVolume: 20,
      minVolume: 2,
      isCount: false,
    },
  ],
  cellCups: [initialCup],
  cellDisposables: [initialDisposable],
};

/**
 * Дефолтное значение формы базовой информации модели автомата
 */
export const createBasicModelInitialValue: CreateBaseModel = {
  name: '',
  groupModel: MachineModelGroup.DEFAULT,
  qtyDosage: 1,
};

/**
 * Дефолтное значение формы базовой информации модели автомата со снеками
 */
export const createMatrixModelInitialValue: CreateMatrixModel = {
  maxCells: null,
  maxRows: null,
  maxVolume: null,
  minVolume: null,
};
