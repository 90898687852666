import { FC, useEffect, useState } from 'react';
import DefaultModal from '../../../../../components/DefaultModal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../app/hooks/store';
import {
  CreateRecipeBasic,
  CreateRecipeDTO,
  Ingredient,
} from '../../../../../types/serverInterface/recipeDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import RecipeBasicForm from '../RecipeBasicForm';
import RecipeIngredientsForm from '../RecipeIngredientsFrom';
import {
  createRecipeAction,
  getCellCategoryListAction,
  getCellPurposeListAction,
  getTasteListAction,
} from '../../../../../state/productBase/actions';

/**
 * Свойства компонента CreateRecipe
 */
type CreateRecipeProps = {
  /**
   * Флаг открытия формы создания рецепта
   */
  isOpen: boolean;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Дефолтное значения формы базовой информации о рецепте
 */
const createRecipeBasicInitialValue: CreateRecipeBasic = {
  name: '',
  description: '',
  mediaKey: '',
  baseVolume: null,
  organizationId: null,
};

/**
 * Шаги формы создания рецепта
 */
enum Steps {
  BASE = 'BASE',
  INGREDIENTS = 'INGREDIENTS',
}

/**
 * Форма создания рецепта
 */
const CreateRecipe: FC<CreateRecipeProps> = ({ isOpen, organizationId, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [formStep, setFormStep] = useState<Steps>(Steps.BASE);
  const [basicFormState, setBasicFormState] = useState<CreateRecipeBasic>(
    createRecipeBasicInitialValue,
  );
  const [ingredientsFormState, setIngredientsFormState] = useState<Ingredient[][]>([]);

  useEffect(() => {
    dispatch(getCellCategoryListAction());
    dispatch(getCellPurposeListAction());
    dispatch(getTasteListAction());
  }, [dispatch]);

  // Вспомогательные методы
  const getPrimaryButtonLabel = () => {
    switch (formStep) {
      case Steps.BASE:
        return t('productBase.recipe.form.create.next.button.label');
      case Steps.INGREDIENTS:
        return t('productBase.recipe.form.create.save.button.label');
    }
  };

  const getCancelButtonLabel = () => {
    switch (formStep) {
      case Steps.BASE:
        return t('productBase.recipe.form.create.cancel.button.label');
      case Steps.INGREDIENTS:
        return t('productBase.recipe.form.create.back.button.label');
    }
  };

  // Обработчики
  const handleCancelButtonClick = () => {
    switch (formStep) {
      case Steps.BASE:
        return handleClose();
      case Steps.INGREDIENTS:
        setFormStep(Steps.BASE);
    }
  };

  const handlePrimaryButtonClick = () => {
    switch (formStep) {
      case Steps.BASE:
        return setFormStep(Steps.INGREDIENTS);
      case Steps.INGREDIENTS:
        return handleSubmit();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleBasicChange = (key: keyof CreateRecipeBasic) => (value: string | null) => {
    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleIngredientsFormChange = (data: Ingredient[][]) => {
    setIngredientsFormState(data);
  };

  const handleSubmit = () => {
    const ingredients = ingredientsFormState.reduce((acc, value, index) => {
      return acc.concat(value.map((item) => ({ ...item, actionSerialNumber: index })));
    }, []);
    const data: CreateRecipeDTO = { ...basicFormState, ingredients };
    dispatch(createRecipeAction(data, organizationId)).then(() => {
      setBasicFormState(createRecipeBasicInitialValue);
      setIngredientsFormState([]);
      setFormStep(Steps.BASE);
      handleClose();
    });
  };

  // render методы
  const renderActions = () => (
    <>
      <Button label={getCancelButtonLabel()} view="clear" onClick={handleCancelButtonClick} />
      <Button label={getPrimaryButtonLabel()} onClick={handlePrimaryButtonClick} />
    </>
  );

  const renderContent = () => {
    switch (formStep) {
      case Steps.BASE:
        return <RecipeBasicForm recipe={basicFormState} onChange={handleBasicChange} />;
      case Steps.INGREDIENTS:
        return (
          <RecipeIngredientsForm
            ingredients={ingredientsFormState}
            onChange={handleIngredientsFormChange}
          />
        );
    }
  };

  return (
    <DefaultModal
      modalTitle={t('productBase.recipe.form.create.modal.title')}
      isOpen={isOpen}
      renderActions={renderActions}
      onClose={handleClose}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default CreateRecipe;
