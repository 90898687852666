import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectProduct } from '../../../../state/productBase/selectors';
import { getProductByIdAction, getProductListAction } from '../../../../state/productBase/actions';
import { Loader } from '@consta/uikit/Loader';
import Chips from '../../../../components/Chips';
import DefaultModal from '../../../../components/DefaultModal';
import ProductBasicInfo from './ProductBasicInfo';
import NoContent from '../../../../components/NoContent';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import ProductCharacteristicsInfo from './ProductCharacteristicsInfo';
import ProductComponentsInfo from './ProductComponentsInfo';
import ProductCalibrationInfo from './ProductCalibrationInfo';
import { enumToArray } from '../../../../types/enums';
import EditProductForm from '../ProductForm/EditProductForm';
import { ProductDetailsDTO } from '../../../../types/serverInterface/productDTO';
import { useTranslation } from 'react-i18next';
import styles from './ProductInfo.module.scss';

/**
 * Шаги для информации о продукте
 */
export enum ProductInfoSteps {
  /**
   * Базовая информация
   */
  BASE = 'BASE',
  /**
   * Характеристики
   */
  CHARACTERISTICS = 'CHARACTERISTICS',
  /**
   * Состав
   */
  COMPONENTS = 'COMPONENTS',
  /**
   * Калибровка
   */
  CALIBRATION = 'CALIBRATION',
}

/**
 * Список шагов информации о продукте
 */
export const productInfoStepsList = enumToArray(ProductInfoSteps) as ProductInfoSteps[];

/**
 * Свойства компонента ProductInfo
 */
type ProductInfoProps = {
  /**
   * Флаг открытия информации о продукте
   */
  isOpen: boolean;
  /**
   * id продукта
   */
  productId: number;
  /**
   * id линейки продуктов
   */
  productLineId: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * Флаг возможноости редактирования
   */
  isAllowedEdit: boolean;
  /**
   * Обработчик закрытия страницы информации о продукте
   */
  onClose: () => void;
};

/**
 * Информация о продукте
 */
const ProductInfo: FC<ProductInfoProps> = ({
  isOpen,
  productId,
  productLineId,
  organizationId,
  isAllowedEdit,
  onClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [selectedStep, setSelectedStep] = useState<ProductInfoSteps>(ProductInfoSteps.BASE);
  const [isEdit, setIsEdit] = useState(false);

  const { state, isLoading, isReject } = useAppSelector(selectProduct());
  const product = state || ({} as ProductDetailsDTO);

  useEffect(() => {
    dispatch(getProductByIdAction(productId));
  }, [dispatch, productId]);

  // Обработчики
  const handleClose = () => {
    dispatch(getProductListAction(productLineId, { organizationId }));
    onClose();
  };

  const handleChipsClick = (step: ProductInfoSteps) => {
    setSelectedStep(step);
  };

  const handleCancelButtonClick = () => {
    handleClose();
  };

  const handleEditClick = () => setIsEdit(true);

  const handleEditClose = () => setIsEdit(false);

  // render методы
  const renderChips = () => (
    <div className={styles.chipsList}>
      {productInfoStepsList.map((name) => (
        <Chips
          key={name}
          label={t(`productBase.product.info.chips.label.${name}`)}
          name={name}
          isSelect={selectedStep === name}
          onClick={(name) => handleChipsClick(name as ProductInfoSteps)}
        />
      ))}
    </div>
  );

  const renderActions = () => (
    <>
      <Button
        label={t('productBase.product.info.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      {isAllowedEdit && (
        <Button label={t('productBase.product.info.edit.button.label')} onClick={handleEditClick} />
      )}
    </>
  );

  const renderInfo = () => {
    switch (selectedStep) {
      case ProductInfoSteps.BASE:
        return <ProductBasicInfo product={product} />;
      case ProductInfoSteps.CHARACTERISTICS:
        return <ProductCharacteristicsInfo product={product} />;
      case ProductInfoSteps.COMPONENTS:
        return <ProductComponentsInfo product={product} />;
      case ProductInfoSteps.CALIBRATION:
        return <ProductCalibrationInfo product={product} />;
    }
  };

  const renderInfoModalContent = () => {
    if (isLoading) return <Loader />;
    if (isReject) return <>Reject</>;
    if (!product) return <NoContent />;
    return (
      <>
        {renderChips()}
        {renderInfo()}
      </>
    );
  };

  const renderInfoModal = () => (
    <DefaultModal
      isOpen={isOpen}
      modalTitle={t('productBase.product.info.modal.title')}
      onClose={handleClose}
      renderActions={renderActions}
    >
      {renderInfoModalContent()}
    </DefaultModal>
  );

  const renderEdit = () => (
    <EditProductForm product={product} selectedStep={selectedStep} onClose={handleEditClose} />
  );

  return isAllowedEdit && isEdit ? renderEdit() : renderInfoModal();
};

export default ProductInfo;
