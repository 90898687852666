import { FC } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../components/VerticalContainer';
import styles from './SchedulesListByInfo.module.scss';
import { Schedule } from '../../../../types/serverInterface/promoCodeDTO';
import ScheduleCardByInfo from './ScheduleCardByInfo';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента SchedulesListByInfo
 */
type SchedulesListByInfoProps = {
  /**
   * Список расписаний
   */
  list: Schedule[];
};

/**
 * Список расписаний для страницы информации о промокоде
 */
const SchedulesListByInfo: FC<SchedulesListByInfoProps> = ({ list }) => {
  const { t } = useTranslation();

  return (
    <VerticalContainer className={styles.card} space="3xl">
      <Text size="2xl" weight="semibold">
        {t('promoCode.schedules.list.label')}
      </Text>
      {list?.length ? (
        <HorizontalContainer>
          {list?.map((item) => <ScheduleCardByInfo key={item.id} item={item} />)}
        </HorizontalContainer>
      ) : (
        <Text>В любое время</Text>
      )}
    </VerticalContainer>
  );
};

export default SchedulesListByInfo;
