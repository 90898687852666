import styles from './ClassicTableRows.module.scss';

import { ClassicTableRowsProps } from '../types';
import ClassicTableRow from './ClassicTableRow';

/**
 * Строки таблицы
 */
const ClassicTableRows = <T extends Record<string, any>, K extends keyof T>(
  props: ClassicTableRowsProps<T, K>,
) => {
  const { rows } = props;

  const renderRow = (row: T, rowIndex: number) => (
    <ClassicTableRow
      key={rowIndex}
      row={row}
      columns={props.columns}
      withCheckbox={props.withCheckbox}
      rowIndex={rowIndex}
    />
  );

  return <tbody className={styles.ClassicTableRows}>{rows.map(renderRow)}</tbody>;
};

export default ClassicTableRows;
