import { FC } from 'react';
import FilterButton from '../../filters/FilterButton';
import VerticalContainer from '../../VerticalContainer';
import { Radio } from '@consta/uikit/Radio';
import { useTranslation } from 'react-i18next';
import { enumToArray } from '../../../types/enums';
import { SalesFilters } from '../../../types/serverInterface/machineListDTO';

const saleTypesList = enumToArray(SalesFilters);

/**
 * Свойства компонента SalesFilter
 */
type SalesFilterProps = {
  /**
   * Размер
   */
  size?: 'm' | 'l';
  /**
   * Выбранный период продажи
   */
  selectedSaleType: SalesFilters;
  /**
   * Обработчик изменения периода продажи
   *
   * @param saleType обновлённый период продажи
   */
  onSaleTypeChange: (saleType: SalesFilters) => void;
};

/**
 * Фильтр по продажам
 */
const SalesFilter: FC<SalesFilterProps> = ({ size = 'm', selectedSaleType, onSaleTypeChange }) => {
  const { t } = useTranslation();

  const renderSalesFilter = () => (
    <VerticalContainer space="s">
      {saleTypesList.map((item) => (
        <Radio
          key={item}
          checked={selectedSaleType === item}
          label={t(`machineControl.machine.list.filter.sales.item.${item}`)}
          onClick={() => onSaleTypeChange(item)}
        />
      ))}
    </VerticalContainer>
  );

  return (
    <FilterButton
      size={size}
      label={t('machineControl.machine.list.filter.sales.filterButton.label')}
      modalChildren={renderSalesFilter}
    />
  );
};

export default SalesFilter;
