import { useEffect, useState } from 'react';

/**
 * Хук для создания задержки (debounce) значения.
 *
 * @template T
 * @param {T} value - Значение, которое нужно задержать.
 * @param {number} delay - Время задержки в миллисекундах.
 * @param {boolean} isNoChange - Не изменять значение
 * @returns {T} - Значение со временной задержкой.
 */
function useDebounce<T>(value: T, delay: number, isNoChange?: boolean): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      !isNoChange && setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, isNoChange]);

  return debouncedValue;
}

export default useDebounce;
