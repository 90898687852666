import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../app/api';
import {
  AccessTokenResponse,
  RegistrationDTO,
  RegistrationInfoDTO,
} from '../../types/serverInterface/authDTO';

/**
 * Метод получения токена
 */
export const getAccessTokenPKCEThunk = createAsyncThunk<AccessTokenResponse, string>(
  'getAccessTokenPKCE',
  async (code) => {
    return await api.auth.getAccessTokenPKCE(code);
  },
);

/**
 * Получение информации для регистрации
 */
export const getRegistrationInfoThunk = createAsyncThunk<RegistrationInfoDTO, string>(
  'getRegistrationInfo',
  async (hash) => {
    return await api.auth.fetchRegistrationInfo(hash);
  },
);

/**
 * Регистрация
 */
export const registrationThunk = createAsyncThunk<void, { data: RegistrationDTO; hash: string }>(
  'registration',
  async (data) => {
    return await api.auth.registration(data);
  },
);
