import { FC } from 'react';
import { useAppSelector } from '../../../../app/hooks/store';
import { selectCellViewById } from '../../../../state/productBase/selectors';
import SportPitForm from './SportPitForm';

/**
 * Свойсства компонента EditSportPit
 */
type EditSportPitProps = {
  /**
   * id вида спортивного питания
   */
  sportPitId: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Компонент изменения вида спортивного питания
 */
const EditSportPit: FC<EditSportPitProps> = ({ sportPitId, onClose }) => {
  const sportPit = useAppSelector(selectCellViewById(sportPitId));

  return (
    sportPit && (
      <SportPitForm
        type="edit"
        isOpen={!!sportPitId}
        sportPitId={sportPitId}
        sportPit={sportPit}
        onClose={onClose}
      />
    )
  );
};

export default EditSportPit;
