import { FC, useEffect, useMemo, useState } from 'react';
import styles from './PromoCodeProductForm.module.scss';
import { useTranslation } from 'react-i18next';
import VerticalContainer from '../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { Combobox } from '@consta/uikit/Combobox';
import { IconAdd } from '@consta/uikit/IconAdd';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectCellCategoryList, selectCellViewList } from '../../../state/productBase/selectors';
import {
  getCellCategoryListAction,
  getCellViewListAction,
} from '../../../state/productBase/actions';
import { IngredientLineId, ProductByForm } from '../../../types/serverInterface/promoCodeDTO';
import { CellCategoryDTO } from '../../../types/serverInterface/cellCategoryDTO';
import { CellViewDTO } from '../../../types/serverInterface/cellViewDTO';
import PromoCodeProductFormBrandItem from './PromoCodeProductFormBrandItem';

type PromoCodeProductFormProps = {
  product: ProductByForm;
  onCellCategoryChange: (ids: number[]) => void;
  onCellViewChange: (ids: number[]) => void;
  onBrandChange: (index: number) => (brandId: number | null) => void;
  onIngredientLineChange: (index: number) => (lineIds: number[] | null) => void;
  onIngredientChange: (index: number) => (ingredientIds: IngredientLineId[] | null) => void;
  onAddClick: () => void;
  onDeleteClick: (index: number) => () => void;
};

const PromoCodeProductForm: FC<PromoCodeProductFormProps> = ({
  product,
  onCellCategoryChange,
  onCellViewChange,
  onBrandChange,
  onIngredientLineChange,
  onIngredientChange,
  onDeleteClick,
  onAddClick,
}) => {
  const dispatch = useAppDispatch();

  const cellCategoryList = useAppSelector(selectCellCategoryList());
  const cellViewList = useAppSelector(selectCellViewList());

  const [isAllProduct, setIsAllProduct] = useState(false);

  const cellCategoryMapById = useMemo(() => {
    const map: Record<number, CellCategoryDTO> = {};

    cellCategoryList.forEach((cell) => {
      map[cell.id] = cell;
    });

    return map;
  }, [cellCategoryList]);

  const cellViewMapById = useMemo(() => {
    const map: Record<number, CellViewDTO> = {};

    cellViewList.forEach((cell) => {
      map[cell.id] = cell;
    });

    return map;
  }, [cellViewList]);

  const { brands, viewIds, categoryIds } = product;

  useEffect(() => {
    dispatch(getCellCategoryListAction());
    dispatch(getCellViewListAction());
  }, [dispatch]);

  useEffect(() => {
    // dispatch(getBrandListAction());
  }, [dispatch]);

  const { t } = useTranslation();

  // Вспомогательные методы
  const getCellCategoryValueById = (id: number) => cellCategoryMapById[id];

  const getCellViewValueById = (id: number) => cellViewMapById[id];

  return (
    <VerticalContainer className={styles.card} space="3xl">
      <Text size="2xl">Доступные продукты</Text>
      <Checkbox
        checked={isAllProduct}
        label="Все продукты"
        onChange={({ checked }) => {
          setIsAllProduct(checked);
        }}
      />
      <VerticalContainer className={styles.secondaryCard} space="l">
        <HorizontalContainer>
          <Combobox
            disabled={isAllProduct}
            placeholder="Категория"
            // selectAll
            multiple
            items={cellCategoryList}
            value={categoryIds?.map((id) => getCellCategoryValueById(id))}
            getItemLabel={({ name }) => name}
            getItemKey={({ id }) => id}
            onChange={({ value }) => onCellCategoryChange(value ? value?.map(({ id }) => id) : [])}
          />
          <Combobox
            disabled={isAllProduct}
            placeholder="Вид"
            multiple
            // selectAll
            items={cellViewList}
            value={viewIds?.map((id) => getCellViewValueById(id))}
            getItemLabel={({ name }) => name}
            getItemKey={({ id }) => id}
            onChange={({ value }) => onCellViewChange(value ? value?.map(({ id }) => id) : [])}
          />
        </HorizontalContainer>
        {brands?.map((brand, index) => (
          <PromoCodeProductFormBrandItem
            isAllProduct={isAllProduct}
            key={index}
            categoryIds={categoryIds}
            viewIds={viewIds}
            brand={brand}
            onBrandChange={onBrandChange(index)}
            onIngredientLineChange={onIngredientLineChange(index)}
            onIngredientChange={onIngredientChange(index)}
            onDeleteClick={onDeleteClick(index)}
          />
        ))}
        {/* TODO: требуется замена иконки */}
        <Button
          width="full"
          iconLeft={IconAdd as any}
          label="Добавить"
          view="ghost"
          onClick={onAddClick}
        />
      </VerticalContainer>
    </VerticalContainer>
  );
};

export default PromoCodeProductForm;
