import { RootState } from '../../app/store';

/**
 * Селектор получения информации для регистрации
 */
export const selectRegistrationInfo = () => (state: RootState) => state.auth.registrationInfo;

/**
 * Селектор получения флага загрузки информации для регистрации
 */
export const selectIsRegistrationInfoLoading = () => (state: RootState) =>
  state.auth.isRegistrationInfoLoading;
