import { Dispatch, FC, SetStateAction, useState } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MachineSettingsWater.module.scss';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { IconDropFilled } from '../../../../../assets/icon/iconDropFilled';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import {
  EditWaterCell,
  MachineSettingsCellWater,
} from '../../../../../types/serverInterface/machineDTO';
import { useAppDispatch } from '../../../../../app/hooks/store';
import {
  waterStorageTypes,
  WaterStorageTypes,
} from '../../../../../types/serverInterface/storageDTO';
import DefaultModal from '../../../../../components/DefaultModal';
import { Combobox } from '@consta/uikit/Combobox';
import { useTranslation } from 'react-i18next';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { FieldError } from '../../../../../types/types';
import { initialFieldError, requiredValidate } from '../../../../../helpers/validateHelpers';
import { editWaterCellAction } from '../../../../../state/machineControl/actions';

export type MachineSettingsWaterProps = {
  /**
   * Ячейки воды
   */
  cellWaters: MachineSettingsCellWater[];
  /**
   * id автомата
   */
  machineId: number;
};

type EditWaterCellError = Record<keyof EditWaterCell, FieldError>;

const initialErrors: EditWaterCellError = {
  maxVolume: initialFieldError,
  filterValue: initialFieldError,
  type: initialFieldError,
};

const validate = (
  waterCell: EditWaterCell,
  setError: Dispatch<SetStateAction<EditWaterCellError>>,
  setIsValid: (isValid: boolean) => void,
) => {
  const { filterValue, maxVolume, type } = waterCell;

  const errorMap: EditWaterCellError = {
    ...initialErrors,
    type: requiredValidate(type, setIsValid),
    filterValue:
      type === WaterStorageTypes.TAP
        ? requiredValidate(filterValue, setIsValid)
        : initialFieldError,
    maxVolume:
      type === WaterStorageTypes.BOTTLE
        ? requiredValidate(maxVolume, setIsValid)
        : initialFieldError,
  };

  setError(errorMap);
};

const MachineSettingsWater: FC<MachineSettingsWaterProps> = ({ cellWaters, machineId }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const isBottle =
    cellWaters.find(({ isActive }) => isActive)?.type === WaterStorageTypes.BOTTLE || false;

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [cellsFormError, setCellsFormError] = useState(initialErrors);
  const [formState, setFormState] = useState<EditWaterCell>(
    cellWaters.reduce(
      (acc, item) => {
        return {
          ...acc,
          type: item.isActive ? item.type : acc.type,
          maxVolume: item.type === WaterStorageTypes.BOTTLE ? item.maxVolume : acc.maxVolume,
          filterValue: item.type === WaterStorageTypes.TAP ? item.filterValue : acc.filterValue,
        };
      },
      {
        filterValue: 0,
        maxVolume: 0,
        type: WaterStorageTypes.TAP,
      } as EditWaterCell,
    ),
  );

  // Обработчики
  const handleEditOpenClick = () => {
    setIsEditOpen(true);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  const handleWaterSource = ({ value }: { value: string | null }) => {
    setFormState((prevState) => ({ ...prevState, type: value as WaterStorageTypes | null }));
  };

  const handleValueChange = ({ value }: { value: string | null }) => {
    setFormState((prevState) => ({
      ...prevState,
      filterValue:
        formState.type === WaterStorageTypes.TAP ? (value as number | null) : prevState.filterValue,
      maxVolume:
        formState.type === WaterStorageTypes.TAP ? prevState.maxVolume : (value as number | null),
    }));
  };

  const handleSubmit = () => {
    let isValid = true;

    const setIsValid = (newIsValid: boolean) => (isValid = isValid && newIsValid);

    validate(formState, setCellsFormError, setIsValid);

    isValid &&
      dispatch(
        editWaterCellAction(
          {
            ...formState,
            filterValue: formState?.filterValue && formState?.filterValue * 1000,
            maxVolume: formState?.maxVolume && formState?.maxVolume * 1000,
          },
          machineId,
        ),
      ).then(() => setIsEditOpen(false));
  };

  // render методы
  const renderModalAction = () => (
    <>
      <Button label="Назад" view="clear" onClick={handleEditClose} />
      <Button label="Сохранить" onClick={handleSubmit} />
    </>
  );

  const renderEditModal = () => (
    <DefaultModal
      className={styles.editModal}
      isOpen={isEditOpen}
      modalTitle="Вода"
      onClose={handleEditClose}
      renderActions={renderModalAction}
    >
      <Combobox
        label="Способ подключения воды"
        value={formState.type}
        items={waterStorageTypes}
        getItemLabel={(item) => t(`machineControl.storage.form.water.${item}`)}
        getItemKey={(item) => item}
        status={cellsFormError.type.status}
        onChange={handleWaterSource}
      />
      <TextField
        label={formState.type === WaterStorageTypes.TAP ? 'Ресурс касеты фильтра' : 'Объём'}
        width="full"
        type="number"
        rightSide={formState.type === WaterStorageTypes.TAP ? 'л' : 'л'}
        incrementButtons={false}
        status={
          formState.type === WaterStorageTypes.TAP
            ? cellsFormError.filterValue.status
            : cellsFormError.maxVolume.status
        }
        value={String(
          formState.type === WaterStorageTypes.TAP ? formState.filterValue : formState.maxVolume,
        )}
        onChange={handleValueChange}
      />
    </DefaultModal>
  );

  return (
    <ContentCard className={styles.MachineSettingsWater}>
      {renderEditModal()}
      <HorizontalContainer space="2xl" align="center">
        <IconDropFilled size="m" {...defaultIconProps} />
        <VerticalContainer space="2xs">
          <Text size="l" weight="medium">
            Способ подключения воды
          </Text>
          <Text size="m" weight="regular" view="secondary">
            {isBottle ? 'Бутылка' : 'Водопровод'}
          </Text>
        </VerticalContainer>
      </HorizontalContainer>
      <IconArrowRight {...defaultIconProps} size="l" onClick={handleEditOpenClick} />
    </ContentCard>
  );
};

export default MachineSettingsWater;
