import * as Yup from 'yup';
import { CreateTasteDTO } from '../../../../types/serverInterface/tasteDTO';

/**
 * Схема валидации создания вкуса
 */
export const createTasteValidationScheme: Yup.ObjectSchema<CreateTasteDTO> = Yup.object({
  name: Yup.string().required(),
  mediaKey: Yup.string().required(),
  hexCode: Yup.string().min(7).max(9).required(),
});
