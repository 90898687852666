import { FC } from 'react';
import { useAppSelector } from '../../../../app/hooks/store';
import { selectTasteById } from '../../../../state/productBase/selectors';
import TasteForm from './TasteForm';

type EditTasteProps = {
  tasteId: number;
  onClose: () => void;
};

/**
 * Компонент изменения вкуса
 *
 * @param tasteId id вкуса
 * @param onClose обработчик закрытия
 */
const EditTaste: FC<EditTasteProps> = ({ tasteId, onClose }) => {
  const taste = useAppSelector(selectTasteById(tasteId));

  return (
    taste && (
      <TasteForm type="edit" isOpen={!!tasteId} tasteId={tasteId} taste={taste} onClose={onClose} />
    )
  );
};

export default EditTaste;
