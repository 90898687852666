import { FC, useEffect, useState } from 'react';
import DefaultModal from '../../../../components/DefaultModal';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../app/hooks/store';
import {
  CreateBaseModel,
  CreateCellsModel,
  CreateCupModelCell,
  CreateDisposableModelCell,
  CreateMatrixModel,
  CreateProductModelCell,
  CreateWaterModelCell,
  MachineModelGroup,
} from '../../../../types/serverInterface/machineModelDTO';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import CreateModelBasicForm from './CreateModelBasicForm';
import CreateModelCellsForm from './CreateModelCellsForm';
import {
  getCellCategoryListAction,
  getCellPurposeListAction,
} from '../../../../state/productBase/actions';
import {
  BaseModelError,
  CellsModelError,
  CreateModelProps,
  CreateModelSteps,
  MatrixModelError,
} from './types';

import { validateBasic, validateCells, validateCellsNumbers, validateMatrix } from './helpers';
import {
  createBasicModelInitialValue,
  createCellsModelInitialValue,
  createMatrixModelInitialValue,
  formBasicInitialError,
  formCellsInitialError,
  formMatrixInitialError,
  initialCup,
  initialDisposable,
  initialProduct,
} from './consts';
import { createMachineModelAction } from '../../../../state/machineControl/actions';
import { initialFieldError } from '../../../../helpers/validateHelpers';
import CreateModelMatrixSchemeForm from './CreateModelMatrixSchemeForm';

/**
 * Форма создания модели автомата
 */
const CreateModel: FC<CreateModelProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [formStep, setFormStep] = useState<CreateModelSteps>(CreateModelSteps.BASE);

  const [basicFormState, setBasicFormState] = useState<CreateBaseModel>(
    createBasicModelInitialValue,
  );
  const [cellsFormState, setCellsFormState] = useState<CreateCellsModel>(
    createCellsModelInitialValue,
  );
  const [matrixFormState, setMatrixFormState] = useState<CreateMatrixModel>(
    createMatrixModelInitialValue,
  );

  const [basicFormError, setBasicFormError] = useState<BaseModelError>(formBasicInitialError);
  const [cellsFormError, setCellsFormError] = useState<CellsModelError>(formCellsInitialError);
  const [matrixFormError, setMatrixFormError] = useState<MatrixModelError>(formMatrixInitialError);

  const isMatrix = basicFormState.groupModel === MachineModelGroup.SNACK;

  useEffect(() => {
    dispatch(getCellCategoryListAction());
    dispatch(getCellPurposeListAction());
  }, [dispatch]);

  // Вспомогательные методы
  const getPrimaryButtonLabel = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return t('machineControl.model.create.actions.next.button.label');
      case CreateModelSteps.CELLS:
        return t('machineControl.model.create.actions.save.button.label');
    }
  };

  const getCancelButtonLabel = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return t('machineControl.model.create.actions.cancel.button.label');
      case CreateModelSteps.CELLS:
        return t('machineControl.model.create.actions.back.button.label');
    }
  };

  const recalculationCellNumbers = () => {
    setCellsFormState((prevState) => {
      const productCellNumberStart = 1;
      const waterCellNumberStart = productCellNumberStart + (prevState.cells?.length ?? 0);
      const cupCellNumberStart = waterCellNumberStart + (prevState.cellWaters?.length ?? 0);
      const disposableCellNumberStart = cupCellNumberStart + (prevState.cellWaters?.length ?? 0);

      const newState = {
        ...prevState,
        cells:
          prevState.cells &&
          prevState.cells.map((item, index) => ({
            ...item,
            cellNumber: productCellNumberStart + index,
          })),
        cellWaters:
          prevState.cellWaters &&
          prevState.cellWaters.map((item, index) => ({
            ...item,
            cellNumber: waterCellNumberStart + index,
          })),
        cellCups:
          prevState.cellCups &&
          prevState.cellCups.map((item, index) => ({
            ...item,
            cellNumber: cupCellNumberStart + index,
          })),
        cellDisposables:
          prevState.cellDisposables &&
          prevState.cellDisposables.map((item, index) => ({
            ...item,
            cellNumber: disposableCellNumberStart + index,
          })),
      };

      setCellsFormError((prevState) => validateCellsNumbers(newState, prevState));

      return newState;
    });
  };

  // Обработчики
  const handleCancelButtonClick = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return handleClose();
      case CreateModelSteps.CELLS:
        return setFormStep(CreateModelSteps.BASE);
    }
  };

  const handlePrimaryButtonClick = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return handleCellsStepClick();
      case CreateModelSteps.CELLS:
        if (isMatrix) {
          return handleSubmitMatrix();
        }
        return handleSubmitCells();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleBasicChange = (key: keyof CreateBaseModel) => (value: string | null) => {
    setBasicFormError((prevState) => ({ ...prevState, [key]: initialFieldError }));

    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleMatrixChange = (key: keyof CreateMatrixModel) => (value: string | null) => {
    setMatrixFormError((prevState) => ({ ...prevState, [key]: initialFieldError }));

    setMatrixFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleCellsStepClick = () => {
    if (validateBasic(basicFormState, setBasicFormError)) {
      setFormStep(CreateModelSteps.CELLS);
    }
  };

  const handleSubmitCells = () => {
    if (validateCells(cellsFormState, setCellsFormError)) {
      const updatedCellWaters =
        cellsFormState.cellWaters?.map((cell) => ({
          ...cell,
          filterValue: cell?.filterValue && cell?.filterValue * 1000,
          maxVolume: cell?.maxVolume && cell?.maxVolume * 1000,
          minVolume: cell?.minVolume && cell?.minVolume * 1000,
        })) ?? null;

      dispatch(
        createMachineModelAction({
          ...basicFormState,
          ...cellsFormState,
          cellWaters: updatedCellWaters,
          maxCells: null,
          maxRows: null,
          maxVolume: null,
          minVolume: null,
        }),
      ).then(handleClose);
    }
  };

  const handleSubmitMatrix = () => {
    if (validateMatrix(matrixFormState, setMatrixFormError)) {
      dispatch(
        createMachineModelAction({
          ...basicFormState,
          cells: null,
          cellWaters: null,
          cellCups: null,
          cellDisposables: null,
          maxCells: Number(matrixFormState.maxCells),
          maxRows: Number(matrixFormState.maxRows),
          maxVolume: Number(matrixFormState.maxVolume),
          minVolume: Number(matrixFormState.minVolume),
        }),
      ).then(handleClose);
    }
  };

  const handleProductChange =
    (index: number) => (key: keyof CreateProductModelCell) => (value: string | number | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        cell: {
          ...prevState.cell,
          [index]: { ...prevState.cell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const cells = prevState.cells ?? [];
        const updatedCells = [...cells];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cells: updatedCells };
      });
    };

  const handleAddProductClick = () => {
    setCellsFormState((prevState) => {
      const cells = prevState.cells ?? [];
      const lastCellNumber = cells.length > 0 ? cells[cells.length - 1].cellNumber : 0;
      return {
        ...prevState,
        cells: [...cells, { ...initialProduct, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleDeleteProductClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const cells = prevState.cells ?? [];
      const newState = {
        ...prevState,
        cells: [...cells.slice(0, index), ...cells.slice(index + 1)],
      };

      handleValidateCells(newState);

      return newState;
    });
  };

  const handleCopyProductClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const cells = prevState.cells ?? [];
      const copyProduct = cells[index] || initialProduct;
      const cellsListLength = cells.length;
      const lastCellNumber = cellsListLength > 0 ? cells[cellsListLength - 1].cellNumber : 0;

      setCellsFormError((prevErrorState) => ({
        ...prevErrorState,
        cell: {
          ...prevErrorState.cell,
          [cellsListLength]: { ...prevErrorState.cell?.[index] },
        },
      }));

      return {
        ...prevState,
        cells: [...cells, { ...copyProduct, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleWaterChange =
    (index: number) => (key: keyof CreateWaterModelCell) => (value: string | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        waterCell: {
          ...prevState.waterCell,
          [index]: { ...prevState.waterCell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const cellWaters = prevState.cellWaters ?? [];
        const updatedCells = [...cellWaters];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cellWaters: updatedCells };
      });
    };

  const handleCupChange =
    (index: number) => (key: keyof CreateCupModelCell) => (value: string | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        cupCell: {
          ...prevState.cupCell,
          [index]: { ...prevState.cupCell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const cellCups = prevState.cellCups ?? [];
        const updatedCells = [...cellCups];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cellCups: updatedCells };
      });
    };

  const handleAddCupClick = () => {
    setCellsFormState((prevState) => {
      const cellCups = prevState.cellCups ?? [];
      const lastCellNumber = cellCups.length > 0 ? cellCups[cellCups.length - 1].cellNumber : 0;
      return {
        ...prevState,
        cellCups: [...cellCups, { ...initialCup, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleDeleteCupClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const cellCups = prevState.cellCups ?? [];
      const newState = {
        ...prevState,
        cellCups: [...cellCups.slice(0, index), ...cellCups.slice(index + 1)],
      };

      handleValidateCells(newState);

      return newState;
    });
  };

  const handleCopyCupClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const cellCups = prevState.cellCups ?? [];
      const copyCup = cellCups[index] || initialCup;
      const cellsListLength = cellCups.length;
      const lastCellNumber = cellsListLength > 0 ? cellCups[cellsListLength - 1].cellNumber : 0;

      setCellsFormError((prevErrorState) => ({
        ...prevErrorState,
        cupCell: {
          ...prevErrorState.cupCell,
          [cellsListLength]: { ...prevErrorState.cupCell?.[index] },
        },
      }));

      return {
        ...prevState,
        cellCups: [...cellCups, { ...copyCup, cellNumber: lastCellNumber + 1 }],
      };
    });
  };

  const handleDisposableChange =
    (index: number) => (key: keyof CreateDisposableModelCell) => (value: string | null) => {
      setCellsFormError((prevState) => ({
        ...prevState,
        disposableCell: {
          ...prevState.disposableCell,
          [index]: { ...prevState.disposableCell?.[index], [key]: initialFieldError },
        },
      }));

      setCellsFormState((prevState) => {
        const cellDisposables = prevState.cellDisposables ?? [];
        const updatedCells = [...cellDisposables];
        updatedCells[index] = { ...updatedCells[index], [key]: value };
        return { ...prevState, cellDisposables: updatedCells };
      });
    };

  const handleAddDisposableClick = () => {
    setCellsFormState((prevState) => {
      const cellDisposables = prevState.cellDisposables ?? [];
      const lastCellNumber =
        cellDisposables.length > 0 ? cellDisposables[cellDisposables.length - 1].cellNumber : 0;
      return {
        ...prevState,
        cellDisposables: [
          ...cellDisposables,
          { ...initialDisposable, cellNumber: lastCellNumber + 1 },
        ],
      };
    });
  };

  const handleDeleteDisposableClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const cellDisposables = prevState.cellDisposables ?? [];

      const newState = {
        ...prevState,
        cellDisposables: [...cellDisposables.slice(0, index), ...cellDisposables.slice(index + 1)],
      };

      handleValidateCells(newState);

      return newState;
    });
  };

  const handleCopyDisposableClick = (index: number) => () => {
    setCellsFormState((prevState) => {
      const cellDisposables = prevState.cellDisposables ?? [];
      const copyDisposable = cellDisposables[index] || initialDisposable;
      const cellsListLength = cellDisposables.length;
      const lastCellNumber =
        cellsListLength > 0 ? cellDisposables[cellsListLength - 1].cellNumber : 0;

      setCellsFormError((prevErrorState) => ({
        ...prevErrorState,
        disposableCell: {
          ...prevErrorState.disposableCell,
          [cellsListLength]: { ...prevErrorState.disposableCell?.[index] },
        },
      }));

      return {
        ...prevState,
        cellDisposables: [
          ...cellDisposables,
          { ...copyDisposable, cellNumber: lastCellNumber + 1 },
        ],
      };
    });
  };

  const handleValidateBasic = () => {
    validateBasic(basicFormState, setBasicFormError);
  };

  const handleValidateCells = (cells?: CreateCellsModel) => {
    validateCells(cells || cellsFormState, setCellsFormError);
  };

  const handleValidateMatrix = (matrix?: CreateMatrixModel) => {
    validateMatrix(matrix || matrixFormState, setMatrixFormError);
  };

  const handleValidate = () => {
    if (formStep === CreateModelSteps.CELLS && !isMatrix) {
      return handleValidateCells();
    }

    if (formStep === CreateModelSteps.CELLS) {
      return handleValidateMatrix();
    }

    return handleValidateBasic();
  };

  // render методы
  const renderActions = () => (
    <>
      <Button label={getCancelButtonLabel()} view="clear" onClick={handleCancelButtonClick} />
      {formStep === CreateModelSteps.CELLS && !isMatrix && (
        <Button
          label={t('machineControl.model.create.actions.recalculate.button.label')}
          view="clear"
          onClick={recalculationCellNumbers}
        />
      )}
      <Button
        label={t('machineControl.model.create.actions.validate.button.label')}
        view="clear"
        onClick={handleValidate}
      />
      <Button label={getPrimaryButtonLabel()} onClick={handlePrimaryButtonClick} />
    </>
  );

  const renderContent = () => {
    switch (formStep) {
      case CreateModelSteps.BASE:
        return (
          <CreateModelBasicForm
            value={basicFormState}
            error={basicFormError}
            onChange={handleBasicChange}
          />
        );
      case CreateModelSteps.CELLS:
        return isMatrix ? (
          <CreateModelMatrixSchemeForm
            value={matrixFormState}
            error={matrixFormError}
            onChange={handleMatrixChange}
          />
        ) : (
          <CreateModelCellsForm
            value={cellsFormState}
            errors={cellsFormError}
            onProductChange={handleProductChange}
            onAddProductClick={handleAddProductClick}
            onDeleteProductClick={handleDeleteProductClick}
            onCopyProductClick={handleCopyProductClick}
            onWaterChange={handleWaterChange}
            onCupChange={handleCupChange}
            onAddCupClick={handleAddCupClick}
            onDeleteCupClick={handleDeleteCupClick}
            onCopyCupClick={handleCopyCupClick}
            onDisposableChange={handleDisposableChange}
            onAddDisposableClick={handleAddDisposableClick}
            onDeleteDisposableClick={handleDeleteDisposableClick}
            onCopyDisposableClick={handleCopyDisposableClick}
          />
        );
    }
  };

  return (
    <DefaultModal
      modalTitle={t('machineControl.model.create.modal.title')}
      isOpen={isOpen}
      renderActions={renderActions}
      onClose={handleClose}
    >
      {renderContent()}
    </DefaultModal>
  );
};

export default CreateModel;
