import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import Cabinet from './Cabinet';
import UserForm from './UserForm';
import UserDocs from './UserDocs';

/**
 * Роут личного кабинета
 */
const CabinetPage: FC = () => {
  return (
    <Routes>
      <Route path="/edit" element={<UserForm />} />
      <Route path="/:userUuid/docs" element={<UserDocs />} />
      <Route path="/" element={<Cabinet />} />
    </Routes>
  );
};

export default CabinetPage;
