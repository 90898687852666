/**
 * Фильтр избранных промокодов
 */
export enum SelectedPromoCodeFilterEnum {
  NULL = 'NULL',
  SELECTED = 'SELECTED',
  // NO_SELECTED = 'NO_SELECTED',
}

/**
 * Фильтр активных промокодов
 */
export enum ActivePromoCodeFilterEnum {
  NULL = 'NULL',
  ACTIVE = 'ACTIVE',
  NO_ACTIVE = 'NO_ACTIVE',
}

/**
 * Фильтр типа скидки промокода
 */
export enum DiscountTypePromoCodeFilterEnum {
  NULL = 'NULL',
  PERCENT = 'PERCENT',
  FIXED = 'FIXED',
  FREE = 'FREE',
}
