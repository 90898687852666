import { FC } from 'react';
import { useAppSelector } from '../../../../app/hooks/store';
import { selectMachineModelList } from '../../../../state/machineControl/selectors';
import DeleteModal from '../../../../components/DeleteModal';

/**
 * Свойства комопнента DeleteModel
 */
type DeleteModelProps = {
  /**
   * Флаг открытия модального окна
   */
  isOpen: boolean;
  /**
   * Флаг незавершённого процесса удаления
   */
  isDeleteLoading: boolean;
  /**
   * id модели автомата
   */
  modelId: number;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
  /**
   * Обработчик удаления
   */
  onDelete: () => void;
};

/**
 * Удаление модели автомата
 */
const DeleteModel: FC<DeleteModelProps> = ({
  isOpen,
  isDeleteLoading,
  modelId,
  onDelete,
  onClose,
}) => {
  const { state: modelList } = useAppSelector(selectMachineModelList());
  const modelName = modelList && modelList.find(({ id }) => id === modelId)?.name;

  return (
    <DeleteModal
      isOpen={isOpen}
      isDeleteLoading={isDeleteLoading}
      title={`Удаление модели ${modelName}`}
      description={`Вы уверены, что хотите удалить модель ${modelName}`}
      withConfirmDeletion={false}
      onClose={onClose}
      onDelete={onDelete}
    />
  );
};

export default DeleteModel;
