import { FC } from 'react';
import { OrganizationContactsFormProps } from './types';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VerticalContainer from '../../../../components/VerticalContainer';
import OrganizationContactsFormItem from './OrganizationContactsFormItem';
import { ContactOrganization } from '../../../../types/serverInterface/organizationDTO';

/**
 * Форма для создания контактов организации.
 */
const OrganizationContactsForm: FC<OrganizationContactsFormProps> = ({
  value,
  onChange,
  onDelete,
  onAdd,
}) => {
  const { t } = useTranslation();

  //Обработчики
  const handleItemChange =
    (index: number) =>
    (key: keyof ContactOrganization) =>
    ({ value }: { value: string | null }) => {
      onChange(index)(key)(value);
    };

  //render методы
  const renderContent = () =>
    value.map((contact, index) => (
      <VerticalContainer space="l" key={index}>
        <OrganizationContactsFormItem
          contact={contact}
          onChange={handleItemChange(index)}
          onDelete={() => onDelete(index)}
        />
      </VerticalContainer>
    ));

  return (
    <>
      {renderContent()}
      <Button
        label={t('organization.create.contacts.addContact.button.label')}
        size="l"
        view="ghost"
        width="full"
        onClick={onAdd}
      />
    </>
  );
};

export default OrganizationContactsForm;
