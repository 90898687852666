import { FC, memo } from 'react';
import { Roles } from '../../../types/serverInterface/cabinetDTO';
import { Navigate, Outlet } from 'react-router';
import { useRoleChecked } from '../../../helpers/roleChecked';

type PrivateRolesPageProps = {
  needRoles: Roles[];
  needOrganization?: boolean;
};

const PrivateRolesPage: FC<PrivateRolesPageProps> = memo(function PrivateRolesPage({
  needRoles,
  needOrganization,
}) {
  const isValidRole = useRoleChecked(needRoles, needOrganization);

  if (!isValidRole) return <Navigate to={'/errorPage'} />;
  return <Outlet />;
});

PrivateRolesPage.displayName = 'PrivateRolesPage';

export default PrivateRolesPage;
